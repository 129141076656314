/* eslint-disable-next-line */
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ConfigActions from '../../../reducers/configRedux'
import HelpCentre from '../../../sagas/Help/helpCentre'
import sso_auth from '../../../sagas/SSO/Auth'
import api from '../../../services/configApis'
import UrlParamsUtil from '../../../utils/UrlParamsUtil'
import LayoutActions from '../../../reducers/layoutRedux'

/**
 * Initializes the configuration for the app. will pull configuration parameters
 * from the api in order to configure Heap, GA, as well as legacy direct code things.
 * any CAS parameters
 * @param {any} props
 */
const ConfigInitializer = ({ updateState }) => {

    const dispatch = useDispatch()

    const lang = useSelector(state => state.layout.lang || 'en')
    const { linkConfig } = useSelector(state => state.config || {}, shallowEqual)

    const initDirectCode = (config, params) => {
        if (!config.appConfig.allowDirect) {
            localStorage.removeItem('va_direct_code');
        } else if (params.direct) {
            localStorage.setItem('va_direct_code', params.direct)
        }
    }

    const initHeapApp = (config) => {
        // check local storage for existing app id
        let heapAppId = localStorage.getItem('va_heap_app_id')

        // if the new value is different, update it
        if (heapAppId !== config.appConfig.heapApplicationId && config.appConfig.heapApplicationId) {
            heapAppId = config.appConfig.heapApplicationId
            localStorage.setItem('va_heap_app_id', heapAppId)
        }

        // now, do the actual loading of heap
        // eslint-disable-next-line no-unused-expressions, no-undef, no-sequences
        window.heap = window.heap || [], window.heap.load = function (e, t) { window.heap.appid = e, window.heap.config = t = t || {}; var r = document.createElement("script"); r.type = "text/javascript", r.async = !0, r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js"; var a = document.getElementsByTagName("script")[0]; a.parentNode.insertBefore(r, a); for (var n = function (e) { return function () { heap.push([e].concat(Array.prototype.slice.call(arguments, 0))) } }, p = ["addEventProperties", "addUserProperties", "clearEventProperties", "identify", "resetIdentity", "removeEventProperty", "setEventProperties", "track", "unsetEventProperty"], o = 0; o < p.length; o++)heap[p[o]] = n(p[o]) };
        if (heapAppId) {       
            window.heap.load(heapAppId, {
                disableTextCapture: true,
                rewrite: function(event) {
                    if (event.pageview_properties.path.includes("approved")|| event.pageview_properties.path.includes("declined")) {
                         event.pageview_properties.query = "";
                    }
                    return event;
            }
            })
            console.log('heap loaded')
        }
    }

    const initTaxYear = (config) => {
        var currentTaxYear = Number(localStorage.getItem('va_current_tax_year'))
        if (currentTaxYear !== config.appConfig.currentTaxYear || !currentTaxYear) {
            currentTaxYear = config.appConfig.currentTaxYear
            localStorage.setItem('va_current_tax_year', currentTaxYear)
        }

        var minTaxYear = Number(localStorage.getItem('va_min_tax_year'))
        if (minTaxYear !== config.appConfig.minTaxYear || !minTaxYear) {
            minTaxYear = config.appConfig.minTaxYear
            localStorage.setItem('va_min_tax_year', minTaxYear)
        }
    }

    const parseGAParams = (params) => {
        var ga = ''
        var uniqueGa = ''

        if (params._ga) {
            ga = params._ga;
            var s = params._ga.split('-')
            if (s && s.length > 1) {
                uniqueGa = s[1]
            }
        }
        return {
            ga,
            uniqueGa
        }
    }

    const identifyGA = (params) => {
        var gaParam = parseGAParams(params)

        if (localStorage.getItem('va_heap_app_id') && window.heap && typeof(window.heap.identify) === 'function' && gaParam.ga) {
            window.heap.identify(gaParam.uniqueGa, 'googleDeviceId');
            window.heap.addUserProperties({
                googleDeviceId: gaParam.ga
            })
        }
    }

    useEffect(() => {
        // get help center configuration by language selection
        let links = linkConfig[lang]

        // if we have configuration for this language, set it up here.
        if (links && links.help_centre) {
            HelpCentre.configure(links.help_centre)
        }
    }, [lang, linkConfig])

    useEffect(() => {
        async function init(params) {
            let attempts = 0;
            let maxAttempts = 3;
            let response = {};
            let success = false;

            try {
                // attempt to get app config up to three times
                while (!success && attempts < maxAttempts) {
                    try {
                        response = await api.create().getSystemConfig();
                        success = (response || {}).ok;
                    } catch (ex) {
                        console.log(ex)
                        success = false;
                    }

                    if (!success) {
                        attempts++;
                    }
                }

                // check for success, and initialize if possible
                if (success) {
                    var config = response.data;

                    initDirectCode(config, params);
                    initHeapApp(config);
                    identifyGA(params);
                    initTaxYear(config);

                    dispatch(ConfigActions.setLinkConfig(config.links.en, config.links.fr))
                    dispatch(ConfigActions.setCasConfig(config.cas))
                    dispatch(ConfigActions.setAppConfig(config.appConfig))
                    dispatch(ConfigActions.setFeatureFlags(config.featureflags))
                    dispatch(LayoutActions.setCurrentTaxYear(config.appConfig.currentTaxYear))
                    dispatch(LayoutActions.setMinTaxYear(config.appConfig.minTaxYear))

                    sso_auth.initAuthProvider(config.cas)
                }

            } catch (ex) {
                console.log(ex)
            } finally {
                // report success
                updateState(success)
            }
        }

        var params = UrlParamsUtil.getQueryParams(window.location.search)
        init(params)
    }, [])

    return null
}

ConfigInitializer.propTypes = {
    updateState: PropTypes.func.isRequired
}

export default ConfigInitializer;