import { push } from 'connected-react-router'
import { call, put, select } from 'redux-saga/effects'
import InterviewActions from '../reducers/interviewRedux'
import MessagesActions from '../reducers/messagesRedux'
import WorkflowActions from '../reducers/workflowRedux'

export const getInterview = state => state.interview
export const getAccessToken = state => state.auth.accessToken
export const getWorkflow = state => state.workflow

export function* loadInterview(api, action) {
    var accessToken = yield select(getAccessToken)

    var response = yield call(api.loadInterview, accessToken, action.taxYear);
    if (response && response.ok && response.data) {
        if (response.data.interview) {
            var interviewState = JSON.parse(response.data.interview)
            if (interviewState) {
                // VAI-419 if there is a nested interview (from pre-redux accounts), use that as
                // interview instead.
                if (interviewState.interview) interviewState = interviewState.interview;
                // use a different action with the same implementation to avoid re-saving this
                yield put(InterviewActions.setLoadedInterview(interviewState, action.taxYear, true, response.data.page))
            }
        } else {
            yield put(InterviewActions.setDefaultInterview(action.taxYear, true, response.data.postalCode))
        }
    } else {
    }
}

export function* saveInterview(api, workflowApi, action) {
    var taxYearModel = yield select(getInterview)
    var interview = taxYearModel[action.taxYear]
    var accessToken = yield select(getAccessToken)
    if (interview.isDefault || !accessToken) {
        return;
    }

    yield call(api.saveInterview, accessToken, interview.taxYear, interview.interview, action.isComplete, interview.page)
    if (action.isComplete) {
        var useMiniInterview = false;

        if (action.additionalYear) {
            var workflowState = yield call(workflowApi.getWorkflowState2, accessToken)
            if (workflowState && workflowState.ok) {
                yield put(WorkflowActions.storeCaseState(workflowState.data))
                yield put(MessagesActions.setUnreadMessagesCount(workflowState.data.unreadMessagesCount))
                if (workflowState.data.taxYears && workflowState.data.taxYears.length > 0) {
                    useMiniInterview = workflowState.data.taxYears[0].use_mini_interview
                }
            }

            if (useMiniInterview) {
                yield put(push('/dashboard/interview/' + action.additionalYear + '/0'))
            } else {
                yield put(push('/dashboard/interview/' + action.additionalYear + '/1'))
            }
        } else {
            yield put(WorkflowActions.updateCaseState())
            //yield put(LayoutActions.setDashboardTab('messages')) - Possible re-add for RTE-1728
            yield put(push('/dashboard'))
        }
    } else {
        yield put(WorkflowActions.updateCaseState())
    }
}