import React, { Component } from 'react';
import { connect } from 'react-redux'
import { replace, push } from 'connected-react-router'
import T from 'i18n-react';
import NavButton from './navButton'
import IconCheckButton from '../Common/iconCheckButton'
import HelpCentre from '../../sagas/Help/helpCentre'
import WorkflowNewReturnActions from '../../reducers/workflowNewReturnRedux'

import './style.css'
import './yearSelection.css'

class YearSelection extends Component {

    constructor(props) {
        super(props)

        this.state = {
            taxYears: {},
            createReturnInProgress: false
        }
    }

    componentWillMount() {
        let years = []
        // setup initial tax year selection
        for (var i = this.props.currentTaxYear; i >= this.props.minTaxYear; i--) {
            years[i] = ({ year: i, selected: this.props.currentTaxYear === i })
        }
        this.setState({ taxYears: years })
    }

    yearSelectionChanged(ev, value) {
        let years = {
            ...this.state.taxYears
        }

        years[ev.target.name].selected = value;

        this.setState({ taxYears: years });
    }

    isAnythingSelected() {
        for (var i = this.props.currentTaxYear; i >= this.props.minTaxYear; i--) {
            if (this.state.taxYears) {
                let yearButton = this.state.taxYears[i]
                if (yearButton.selected) return true
            }
        }

        return false;
    }

    onBackClicked(e) {
        this.props.replaceNavigation('/dashboard')
    }

    onContinueClicked(e) {
        let taxYears = [];

        for (var key in this.state.taxYears) {
            if (this.state.taxYears[key].selected === true) 
                taxYears.push(Number(key))
        }
        this.setState({ createReturnInProgress: true })

        this.props.startNewReturns(taxYears);
    }

    onLinkClicked() {
        HelpCentre.openHelpLink("OTE_FAQ/INeedOtherYearsPrepared")
    }

    render() {
        // oooo, stylish
        var isSmallLayout = this.props.layoutType === 'tablet' || this.props.layoutType === 'small_tablet' || this.props.layoutType === 'mobile';
        var rootStyle = isSmallLayout ? "mobile interviewContainer" : "interviewContainer";
        var containerStyle = this.props.layoutType === 'desktop' ? "horizontalFieldContainerTopAligned" : "iconCheckButtonMobileContainer"
        var marginValue = this.props.layoutType === 'mobile' ? 0 : 10

        var options = []
        for (var i = this.props.currentTaxYear; i >= this.props.minTaxYear; i--) {
            let option = this.state.taxYears[i]
            let labelKey = `Interview.YearSelection.${isSmallLayout ? 'currentTaxYearMobile' : 'currentTaxYear'}`
            let label = (i === this.props.currentTaxYear) ? labelKey : ` `
            let secondaryLabel = `${option.year}`
            options.push(<IconCheckButton key={option.year} name={option.year + ''} icon="fa-calendar"
                label={T.translate(label)} secondaryLabel={T.translate(secondaryLabel)}
                checked={option.selected} imageTopSpacing='15px' additionalClasses='taxYearSelection'
                onCheckChanged={(ev, value) => this.yearSelectionChanged(ev, value)} />)
        }

        return (
            <div className={rootStyle}>
                <div className="interviewContainerStyle">
                    <div className="interviewContentStyle" style={{minHeight: 0}}>
                        <div>
                            <div>
                                <h1>{T.translate("Interview.YearSelection.headerTitle", { SpouseFirstName: this.state.spouseFirstName })}</h1>
                                <p style={{ textAlign: 'center' }} >
                                    <T.span text='Interview.YearSelection.headerContent' className="headerText" />
                                </p>
                            </div>
                            <div style={{ margin: marginValue, textAlign: 'center' }}>
                                <div className={containerStyle} style={{ justifyContent: 'center' }} >
                                    {options}
                                </div>
                                {this.props.showOteContent &&
                                    <div style={{ marginTop: 15, marginBottom: 10 }}>
                                        <span className="learnMoreStyle" onClick={(ev) => this.onLinkClicked(ev)}>
                                            {T.translate('Interview.YearSelection.otherYearsLink')}
                                        </span>
                                    </div>
                                }
                            </div>
                            
                        </div>     
                    </div>
                </div>
                <div className="interviewBottomContainer">
                    <NavButton isEnabled={true} isBack={true} onClick={(e) => this.onBackClicked(e)} labelKey='Common.Back'></NavButton>
                    <NavButton isForward={true} onClick={(e) => this.onContinueClicked(e)} labelKey='Common.Continue'
                        isEnabled={this.isAnythingSelected()} inProgress={this.state.createReturnInProgress}></NavButton>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        lang: state.layout.lang,
        layoutType: state.layout.layoutType,
        currentTaxYear: state.config.appConfig.currentTaxYear,
        minTaxYear: state.config.appConfig.minTaxYear,
        showOteContent: state.workflow.is_direct_user === false
    };
}

const mapDispatchToProps = (dispatch) => ({
    replaceNavigation: (path) => dispatch(replace(path)),
    pushNavigation: (path) => dispatch(push(path)),
    startNewReturns: (taxYears) => dispatch(WorkflowNewReturnActions.startNewReturns(taxYears))
})

export default connect(mapStateToProps, mapDispatchToProps)(YearSelection)