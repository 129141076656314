import React, { useState, useEffect } from 'react';
import T from 'i18n-react';
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import '../style.css'
import AccordianControl from './accordianControl';
import ReturningOwnTaxes from './returningOwnTaxes';
import ReturningUpdate from './returningUpdate';

const UpdateAccount = () => {
    const layoutType = useSelector(state => state.layout.layoutType);

    const [isSmallLayout, setIsSmallLayout] = useState(false);    

    useEffect(() => {
        let isSm = layoutType === 'small_tablet' || layoutType === 'mobile'
        if (isSm !== isSmallLayout) {
            setIsSmallLayout(isSm)
        }
    }, [layoutType, setIsSmallLayout, isSmallLayout])


    return (
        <div className={classnames('reg-min-height', { "registerContentSmallStyle": isSmallLayout, "registerContentStyle": !isSmallLayout })}>
            <div className='reg-content rc-no-margin'>
                <div className='reg-inner-content reg-top-margin'>
                    <h1>{T.translate("AccountSetupStart.labels.PartialTitle")}</h1>
                </div>
                <AccordianControl labelKey="AccountSetupReturning.labels.BannerDIY">
                    <ReturningOwnTaxes />
                </AccordianControl>
                <AccordianControl labelKey="AccountSetupReturning.labels.BannerRTE">
                    <ReturningUpdate />
                </AccordianControl>
            </div>            
        </div>

    )
}

export default UpdateAccount
