import { put, select, call } from 'redux-saga/effects'
import WorkflowActions from '../reducers/workflowRedux'
import WorkflowNewReturnActions from '../reducers/workflowNewReturnRedux'
import MessagesActions from '../reducers/messagesRedux'
import { push } from 'connected-react-router'
import { getTaxYearState } from '../utils/CaseStateUtilities'
import { trackEvent, HeapEvents } from '../utils/AnalyticsUtil'

export const getAccessToken = state => state.auth.accessToken
export const getCaseState = state => state.workflow.case_state
export const getAssignment = state => state.workflowNewReturn

export function* createNewReturns(api, action) {
    var accessToken = yield select(getAccessToken)
    var assignment = yield select(getAssignment)
    var response = yield call(api.createNewCases, accessToken, action, assignment)
    if (response && response.ok) {        
        // this may return extra tax years if the user tried to not create an existing paid year
        if (!response.data.taxYears || response.data.taxYears.length === 0 ||
            action.taxYears.length > response.data.taxYears.length)
        {
            // something happened, we did not create all of the years we requested.
            yield put(push('/error'))
        }
        else
        {
            let caseState = yield select(getCaseState)
            let prevTaxYears = []
            if (caseState && caseState.taxYears) {
                prevTaxYears = caseState.taxYears.map(ty => ty.taxYear) || []
            }

            // track each tax year that was created
            for (let year of response.data.taxYears) {
                if (!prevTaxYears.includes(year)) {
                    trackEvent(HeapEvents.TICKET_CREATE, { taxYear: year })
                }
            }

            // load workflow state + messages, and take user to dashboard
            let workflowState = yield call(api.getWorkflowState2, accessToken)
            if (workflowState && workflowState.ok) {
                yield put(WorkflowActions.storeCaseState(workflowState.data))
                yield put(MessagesActions.setUnreadMessagesCount(workflowState.data.unreadMessagesCount))
            }

            caseState = yield select(getCaseState)
            let selectedTaxYear = response.data.taxYears[0] // sorted desc server-side

            let taxYearState = null;
            if (response.data && response.data.taxYears) {
                for (var i = 0; i < response.data.taxYears.length; ++i) {
                    taxYearState = getTaxYearState(response.data.taxYears[i], caseState)
                    if (!taxYearState || !taxYearState.interview_state || !taxYearState.interview_state.complete) {
                        selectedTaxYear = response.data.taxYears[i]
                        break;
                    }
                }
            }

            yield put(WorkflowNewReturnActions.setNextReturnAssignment(null, null))


            if (taxYearState && taxYearState.interview_state && taxYearState.interview_state.complete) {
                yield put(WorkflowActions.updateCaseState())
                yield put(push('/dashboard'))
            } else if (taxYearState !== null && taxYearState.use_mini_interview) {
                // for direct users, we're going to use the 'light' interview
                yield put(push('/dashboard/interview/' + selectedTaxYear + '/0'))
            } else {
                yield put(push('/dashboard/interview/' + selectedTaxYear + '/1'))
            }
        }
    }   
}
