import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    setInputScreenShowing: ['inputScreenShowing'],
    setMessages: ['messages'],
    addMessage: ['message'],
    setUnreadMessagesCount: ['unreadMessagesCount'],
    setMessageInProgress: ['messageInProgress'],
    setSaveContentPromptShowing: ['saveContentPromptShowing'],
    setMessageSendState: ['messageSendState'],
    getMessages: null,
    updateLastReadDate: null,
    resetMessages: null,
    sendInternalNoteNoPrinter: ['caseId', 'message'],
    setMessagesLoaded: null
})

export const MessagesTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    inputScreenShowing: false,
    unreadMessagesCount: 0,
    messagesLoaded: false,
    messages: [],
    messageInProgress: '',
    saveContentPromptShowing: false,
    messageSendState: ''
}

/* ------------- Reducers ------------- */
export const msgSetInputScreenShowing = (state, { inputScreenShowing }) =>
    Object.assign({}, state, { inputScreenShowing: inputScreenShowing })

export const msgSetMessages = (state, { messages }) => {
    var msgs = messages ? messages.slice(0) : []
    return Object.assign({}, state, { messages: msgs, messagesLoaded: true })
}

export const msgSetMessagesLoadedStatus = (state, { messagesLoaded }) => {
    return Object.assign({}, state, { messagesLoaded: messagesLoaded })
}

export const msgAddMessage = (state, { message }) => {
    var msgs = state.messages ? state.messages.slice(0) : []
    msgs.push(message)
    return Object.assign({}, state, { messages: msgs})
}

export const msgSetUnreadMessagesCount = (state, { unreadMessagesCount }) => 
    Object.assign({}, state, { unreadMessagesCount: unreadMessagesCount})

export const msgSetMessageInProgress = (state, { messageInProgress }) =>
    Object.assign({}, state, { messageInProgress: messageInProgress })

export const msgSetSaveContentPromptShowing = (state, { saveContentPromptShowing }) =>
    Object.assign({}, state, { saveContentPromptShowing: saveContentPromptShowing })

export const msgSetMessageSendState = (state, { messageSendState }) =>
    Object.assign({}, state, { messageSendState: messageSendState })

export const msgReset = (state) => INITIAL_STATE


/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_INPUT_SCREEN_SHOWING]: msgSetInputScreenShowing,
    [Types.ADD_MESSAGE]: msgAddMessage,
    [Types.SET_MESSAGES]: msgSetMessages,
    [Types.SET_MESSAGE_IN_PROGRESS]: msgSetMessageInProgress,
    [Types.SET_SAVE_CONTENT_PROMPT_SHOWING]: msgSetSaveContentPromptShowing,
    [Types.SET_MESSAGE_SEND_STATE]: msgSetMessageSendState,
    [Types.SET_UNREAD_MESSAGES_COUNT]: msgSetUnreadMessagesCount,
    [Types.RESET_MESSAGES]: msgReset,
    [Types.SET_MESSAGES_LOADED]: msgSetMessagesLoadedStatus
})
