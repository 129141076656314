// src/components/Interview/interview.js
import React, { Component } from 'react'
import './style.css'
import T from 'i18n-react'
import AuthorizeIconButton from './authorizeIconButton'

class AuthorizeChecklist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: this.props.lang,
            layoutType: this.props.layoutType,
            province: this.props.province,
            spouseProvince: this.props.spouseProvince,
            prepareForSpouse: this.props.prepareForSpouse,
            firstName: this.props.firstName,
            spouseFirstName: this.props.spouseFirstName,
            authorizeStateSelf: this.props.authorizeStateSelf,
            authorizeStateSpouse: this.props.authorizeStateSpouse,
            executeAuthStep: this.props.executeAuthStep
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }
        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
        if (this.state.spouseProvince !== newProps.spouseProvince) {
            this.setState({ spouseProvince: newProps.spouseProvince })
        }
        if (this.state.province !== newProps.province) {
            this.setState({ province: newProps.province })
        }
        if (this.state.prepareForSpouse !== newProps.prepareForSpouse) {
            this.setState({ prepareForSpouse: newProps.prepareForSpouse })
        }
        if (this.state.spouseFirstName !== newProps.spouseFirstName) {
            this.setState({ spouseFirstName: newProps.spouseFirstName })
        }
        if (this.state.firstName !== newProps.firstName) {
            this.setState({ firstName: newProps.firstName })
        }
        if (this.state.authorizeStateSelf !== newProps.authorizeStateSelf) {
            this.setState({ authorizeStateSelf: newProps.authorizeStateSelf })
        }
        if (this.state.authorizeStateSpouse !== newProps.authorizeStateSpouse) {
            this.setState({ authorizeStateSpouse: newProps.authorizeStateSpouse })
        }
        if (this.state.executeAuthStep !== newProps.executeAuthStep) {
            this.setState({ executeAuthStep: newProps.executeAuthStep })
        }
    }


    executeAuthStep(target, step) {
        if (this.state.executeAuthStep) {
            this.state.executeAuthStep(target, step)
        }
    }

    render() {
        var buttons = []
        buttons.push(
            <AuthorizeIconButton key='btn_self_cra' icon={this.state.authorizeStateSelf.choice === 'T1013' ? 'fa-file' : 'fa-laptop'}
                label={'Authorize.AuthCRA' + (this.state.authorizeStateSelf.cra_status === true ? 'Complete' : '')}
                isEnabled={this.state.authorizeStateSelf.cra_status !== true}
                labelContext={{ name: this.state.firstName }} layoutType={this.state.layoutType}
                isComplete={this.state.authorizeStateSelf.cra_status === true}
                onClick={() => this.executeAuthStep('self', 'cra')}
            />
        )
        if (this.state.province === 'QC') {
            var qcSelfEnabled = this.state.authorizeStateSelf.cra_status === true && this.state.authorizeStateSelf.rq_status !== true
            buttons.push(
                <AuthorizeIconButton key='btn_self_rq' icon={this.state.authorizeStateSelf.choice_rq === 'MR69' ? 'fa-file' : 'fa-laptop'}
                    label={'Authorize.AuthRQ' + (this.state.authorizeStateSelf.rq_status === true ? 'Complete' : '')} isEnabled={qcSelfEnabled}
                    labelContext={{ name: this.state.firstName }} layoutType={this.state.layoutType}
                    isComplete={this.state.authorizeStateSelf.rq_status === true}
                    onClick={() => this.executeAuthStep('self', 'rq')}
                />
            )
        }
        var selfComplete = this.state.authorizeStateSelf.cra_status === true && (this.state.province !== 'QC' || this.state.authorizeStateSelf.rq_status === true)
        if (this.state.prepareForSpouse) {
            buttons.push(
                <AuthorizeIconButton key='btn_spouse_cra' icon={this.state.authorizeStateSpouse.choice === 'T1013' ? 'fa-file' : 'fa-laptop'}
                    label={'Authorize.AuthCRA' + (this.state.authorizeStateSpouse.cra_status === true ? 'Complete' : '')} isEnabled={selfComplete && this.state.authorizeStateSpouse.cra_status !== true}
                    labelContext={{ name: this.state.spouseFirstName }} layoutType={this.state.layoutType}
                    isComplete={this.state.authorizeStateSpouse.cra_status === true}
                    onClick={() => this.executeAuthStep('spouse', 'cra')}
                />
            )
        }
        if (this.state.spouseProvince === 'QC') {
            var qcSpouseEnabled = selfComplete && this.state.authorizeStateSpouse.cra_status === true && this.state.authorizeStateSpouse.rq_status !== true
            buttons.push(
                <AuthorizeIconButton key='btn_spouse_rq' icon={this.state.authorizeStateSpouse.choice_rq === 'MR69' ? 'fa-file' : 'fa-laptop'}
                    label={'Authorize.AuthRQ' + (this.state.authorizeStateSpouse.rq_status === true ? 'Complete' : '')} isEnabled={qcSpouseEnabled}
                    labelContext={{ name: this.state.spouseFirstName }} layoutType={this.state.layoutType}
                    isComplete={this.state.authorizeStateSpouse.rq_status === true}
                    onClick={() => this.executeAuthStep('spouse', 'rq')}
                />
            )
        }

        var headerStyles = ['authSelectHeader']
        if (this.state.layoutType === 'mobile' && this.state.lang === 'en') {
            headerStyles.push('headerSquish')
        }
        return (
            <div className="authorizeMyAccountContainerStyle">
                <div className="authorizeContentStyle authorizeMinComponentHeight">
                    <h1 className={headerStyles.join(' ')}>{T.translate('Authorize.Steps')} </h1>
                    <span>{T.translate('Authorize.StepsMore')}</span>
                    <div className="authorizeChecklistButtonHolder">
                        {buttons}
                    </div>
                </div>
            </div>
        );
    }
}

export default AuthorizeChecklist