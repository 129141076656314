/* eslint-disable-next-line */
import { useState, useEffect } from 'react'
import { shallowEqual } from 'react-redux'

/**
 * Custom Hook that takes in a list of files to be uploadded, and handles concurrency/queueing for them.
 * @param {any} files
 */
export const useUploadQueue = (files) => {

    const maxUploads = 4

    const [fileQueue, setFileQueue] = useState([])
    const [areAllUploadsResolved, setAreAllUploadsResolved] = useState(false)
    const [didAnyUploadsSucceed, setDidAnyUploadsSucceed] = useState(false)

    /**
     * Goes through the queue and checks for resolved uploads, and triggers
     * the next item for upload when ready.
     * @param {any} updatedQueue
     */
    const checkCompletions = () => {
        let allResolved = fileQueue.every(f => f.isUploadComplete)
        setAreAllUploadsResolved(allResolved)

        let hasSuccesses = fileQueue.some(f => !f.error && f.isUploadComplete)
        setDidAnyUploadsSucceed(hasSuccesses)
    }

    /**
     * Resets all state variables, so the user can start over
     * */
    const resetQueue = () => {
        setFileQueue([])
        setAreAllUploadsResolved(false)
        setDidAnyUploadsSucceed(false)
    }

    /**
     * Processes completions and readies any pending uploads, then updates the queue
     * @param {any} updatedQueue a **COPY** of the file queue, already updated by the calling function
     * Assumption: the array passed is already a copy of the fileQueue state object, so it can be
     *             set directly using setFileQueue().
     */
    const processQueue = (updatedQueue) => {
        let uploadingCount = updatedQueue.filter(u => u.isReadyToUpload && !u.isUploadComplete).length
        let pendingCount = updatedQueue.filter(u => !u.isReadyToUpload).length

        while ((uploadingCount < maxUploads) && (pendingCount > 0)) {
            let next = updatedQueue.find(u => !u.isReadyToUpload)
            if (next) {
                next.isReadyToUpload = true
                uploadingCount++
            } else {
                //something went horribly wrong :(
                break;
            }
        }

        checkCompletions()

        setFileQueue(updatedQueue)
    }

    /**
     * This method will be passed down into all doxUploadItems, to enable them to 
     * notify us when the upload is fully complete.
     * @param {number} position
     */
    const onUploadCompleted = (position, error) => {
        let updatedQueue = [...fileQueue]
        let completed = updatedQueue.find(u => u.qPosition === position)
        if (completed) {
            completed.isUploadComplete = true
            completed.error = error
            processQueue(updatedQueue)
        }
    }

    /**
     * This method will be passed down into all doxUploadItems, to enable them to
     * request an upload do-over
     * @param {number} position
     */
    const onRetryUpload = position => {
        let updatedQueue = [...fileQueue]
        let retry = updatedQueue.find(u => u.qPosition === position)
        if (retry) {
            // requeue!
            retry.isReadyToUpload = false
            retry.isUploadComplete = false
            processQueue(updatedQueue)
        }
    }

    /* once the files are set, build a queue with the file data and some other trackable props */
    useEffect(() => {
        if (files) {
            let newQueue = []
            let count = 0

            files.forEach(f => {
                newQueue.push({
                    file: f,
                    qPosition: count,
                    isReadyToUpload: count < maxUploads,
                    isUploadComplete: false,
                    error: null
                })
                count++;
            })

            if (!shallowEqual(fileQueue, newQueue)) {
                setFileQueue(newQueue)
            }
        }
    }, [files])

    return { fileQueue, resetQueue, onUploadCompleted, onRetryUpload, areAllUploadsResolved, didAnyUploadsSucceed }
}

export default useUploadQueue