import React, { Component } from 'react';
import './footer.css';
import logo from '../../../images/logo.png'
import T from 'i18n-react';

class FooterTablet extends Component {

    componentDidMount() {
        this.forceUpdate()
    }

    handleAction(action) {
        if (this.props.buttonHandler && this.props.appInitialized) {
            this.props.buttonHandler(action)
        }
    }

    render() {
        var currentYear = new Date().getFullYear();

        var accountContent = []
        if (this.props.loginComplete) {
            accountContent.push(
                <div key="settingsLink" className="blockLinkTablet" onClick={(ev) => this.handleAction('settings')}>
                    <span>{T.translate('HeaderMenuBar.labels.AccountSettings')}</span>
                </div>
            )
            accountContent.push(
                <div key="logoutLink" className="blockLinkTablet" onClick={(ev) => this.handleAction('logout')}>
                    <span>{T.translate('BottomMenuBar.labels.SaveAndQuit')}</span>
                </div>
            )
        } else {
            var signinStyle = "blockLinkTablet"
            if (!this.props.appInitialized) {
                signinStyle += " uninitialized"
            }
            accountContent.push(
                <div key="loginLink" className={signinStyle} onClick={(ev) => this.handleAction('login')}>
                    <span>{T.translate('Common.SignIn')}</span>
                </div>
            )
        }
        return (
            <div className="footerOuter">
                {this.props.showOteContent &&
                    <div className="footerTopOuterTablet">
                        <div className="footerTopTablet">
                            <img src={logo} width="50" height="50" alt={T.translate('Home.hrbLogoAltText')}
                                style={{ cursor: 'pointer' }}
                                onClick={(ev) => this.handleAction('hrb')} />
                            <div className="footerTopSpacerTablet">
                                {accountContent}
                                <div className="blockLinkTablet" onClick={(ev) => this.handleAction('help')}><span>{T.translate('BottomMenuBar.labels.HelpCentre')}</span></div>
                                <div className="blockLinkTablet" onClick={(ev) => this.handleAction('support')}><span>{T.translate('BottomMenuBar.labels.Contact')}</span></div>
                                <div className="blockLinkTablet" onClick={(ev) => this.handleAction('survey')}><span>{T.translate('BottomMenuBar.labels.SendFeedback')}</span></div>
                            </div>
                        </div>
                    </div>
                }
                <div className="footerBottomTablet">
                    <div className="copyBlockTablet">
                        <span className="tiny white">{T.translate('BottomMenuBar.labels.CopyrightHRB', { TaxYear: currentYear })}</span>
                        <span className="tiny white">{T.translate('BottomMenuBar.labels.Reserved')}</span>
                    </div>
                    <div className="termsBlockTablet">
                        <div className="footerBottomLink" onClick={() => this.handleAction('terms')}>
                            <span className="notSoTiny white">{T.translate('BottomMenuBar.labels.TermsAndConditions')}</span>
                        </div>
                        <div><span className="notSoTiny white">&nbsp;|&nbsp;</span></div>
                        <div className="footerBottomLink" onClick={() => this.handleAction('privacy')}>
                            <span className="notSoTiny white">{T.translate('BottomMenuBar.labels.Privacy')}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}


export default FooterTablet