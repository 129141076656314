import { useState } from 'react'

/**
 * Hook to provide the drag and drop handlers that can be used on
 * any component.
 * @param {any} onFilesDropped The method to call when files are successfully dropped onto the component
 */
const useFileDragDrop = onFilesDropped => {

    const [dropDepth, setDropDepth] = useState(0)
    const [inDropZone, setInDropZone] = useState(false)

    const handleDragEnter = e => {
        e.preventDefault();
        e.stopPropagation();

        setDropDepth(dropDepth + 1)
    };

    const handleDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();

        let newDepth = dropDepth - 1

        if (newDepth <= 0) {
            setDropDepth(0)
            setInDropZone(false)
        } else {
            setDropDepth(newDepth)
        }
    };

    const handleDragOver = e => {
        e.preventDefault();
        e.stopPropagation();

        e.dataTransfer.dropEffect = 'copy'
        setInDropZone(true)
    };

    const handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();

        setInDropZone(false)
        if (onFilesDropped) {
            onFilesDropped(e.dataTransfer.files)
        }
    };

    return { handleDragEnter, handleDragLeave, handleDragOver, handleDrop, inDropZone }
}

export default useFileDragDrop