import T from 'i18n-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AuthActions from '../../reducers/authRedux';
import './style.css';

class ResetPasswordConfirmation extends Component {

    closeDialog(ev) {
        if (this.props.onCloseDialog) {
            this.props.onCloseDialog(ev);
        }
    }

    sendPasswordResetEmail(ev) {
       var pwResetLink = this.props.linkConfig[this.props.lang]['password_reset']
       this.props.setLogoutRedirectTarget(pwResetLink)
       this.props.logoutUser('reset')
       this.closeDialog({ value: 'PasswordReset' })
   }      

    render() {
        var buttonClassName = "defaultButtonStyle dialogButtonWidth"    
        var invertedButtonClassName = "invertedButtonStyle dialogButtonWidth"

        return (
            <div className="defaultStyles">                  
                <h1>{T.translate("Settings.labels.ResetPassword")}</h1>
                <p>{T.translate("Settings.labels.EmailWarning1")}</p>
                <div className='btngroup' style={{ alignSelf: 'flex-end' }}>
                    <div className={invertedButtonClassName} style={{ bottom: '5px', right: '180px', float: 'left' }} onClick={(ev) => this.closeDialog(ev)} >
                        <div style={{ width: 20 }} />
                        <span className="unselectableLabel"><T.span text={{ key: 'Settings.labels.Cancel' }} /></span>
                        <div style={{ width: 20 }} />
                    </div>&nbsp;&nbsp;
                    <div className={buttonClassName} style={{ bottom: '5px', right: '25px', float: 'right' }} onClick={(ev) => this.sendPasswordResetEmail(ev)} >
                        <div style={{ width: 20 }} />
                        <span className="unselectableLabel"><T.span text={{ key: 'Common.Continue' }} /></span>
                        <div style={{ width: 20 }} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        layoutType: state.layout.layoutType,
        lang: state.layout.lang,
        linkConfig: state.config.linkConfig
    }
}

const mapDispatchToProps = (dispatch) => ({
    logoutUser: (logoutType) => dispatch(AuthActions.logoutUser(logoutType)),
    setLogoutRedirectTarget: (redirectTarget) => dispatch(AuthActions.setLogoutRedirectTarget(redirectTarget))
})


export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordConfirmation)
