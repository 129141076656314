// src/components/Interview/interview.js
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { goBack } from 'connected-react-router'
import FontAwesome from 'react-fontawesome';
import '../Interview/style.css';
import T from 'i18n-react';
import HelpCentre from '../../sagas/Help/helpCentre'
import ConfirmIdUserSummary from './confirmIdUserSummary'
import ConfirmIdUserSummaryFooter from './confirmIdUserSummaryFooter'
import WorkflowActions from '../../reducers/workflowRedux'
import ConfirmIdentityActions from '../../reducers/confirmIdentityRedux'
import InterviewActions from '../../reducers/interviewRedux'
import LoadingSpinner from '../Loading/loadingSpinner'
import { HeapEvents, trackEvent } from '../../utils/AnalyticsUtil'

class ConfirmIdUser extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lang: this.props.lang,
            layoutType: this.props.layoutType,
            maskedSin: this.props.maskedSin,
            address: this.props.address,
            address2: this.props.address2,
            cityProvince: this.props.cityProvince,
            city: this.props.city,
            province: this.props.province,
            postalCode: this.props.postalCode,
            personalInfo: this.props.personalInfo,
            primary: this.props.primary,
            confirmSelf: this.props.confirmSelf,
            confirmSpouse: this.props.confirmSpouse,
            target: this.props.target,
            stage: this.props.stage,
            prepareForSpouse: this.props.prepareForSpouse,
            spouseUsingPrimaryAddress: this.props.spouseUsingPrimaryAddress,
            onConfirmIdentity: this.props.onConfirmIdentity,
            skipValidationChecked: false,
            isVir: this.props.isVir
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }

        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }

        if (this.state.personalInfo !== newProps.personalInfo) {
            this.setState({ personalInfo: newProps.personalInfo })
        }

        if (this.state.primary !== newProps.primary) {
            this.setState({ primary: newProps.primary })
        }

        if (this.state.maskedSin !== newProps.maskedSin) {
            this.setState({ maskedSin: newProps.maskedSin })
        }

        if (this.state.address !== newProps.address) {
            this.setState({ address: newProps.address })
        }

        if (this.state.address2 !== newProps.address2) {
            this.setState({ address2: newProps.address2 })
        }

        if (this.state.cityProvince !== newProps.cityProvince) {
            this.setState({ cityProvince: newProps.cityProvince })
        }

        if (this.state.city !== newProps.city) {
            this.setState({ city: newProps.city })
        }

        if (this.state.province !== newProps.province) {
            this.setState({ province: newProps.province })
        }

        if (this.state.postalCode !== newProps.postalCode) {
            this.setState({ postalCode: newProps.postalCode })
        }

        if (this.state.prepareForSpouse !== newProps.prepareForSpouse) {
            this.setState({ prepareForSpouse: newProps.prepareForSpouse })
        }

        if (this.state.spouseUsingPrimaryAddress !== newProps.spouseUsingPrimaryAddress) {
            this.setState({ spouseUsingPrimaryAddress: newProps.spouseUsingPrimaryAddress })
        }

        if (this.state.confirmSelf !== newProps.confirmSelf) {
            this.setState({ confirmSelf: newProps.confirmSelf })
        }

        if (this.state.confirmSpouse !== newProps.confirmSpouse) {
            this.setState({ confirmSpouse: newProps.confirmSpouse })
        }

        if (this.state.target !== newProps.target) {
            this.setState({ target: newProps.target })
        }

        if (this.state.stage !== newProps.stage) {
            this.setState({ stage: newProps.stage })
        }

        if (this.state.onConfirmIdentity !== newProps.onConfirmIdentity) {
            this.setState({ onConfirmIdentity: newProps.onConfirmIdentity })
        }
        if (this.state.isVir !== newProps.isVir) {
            this.setState({ isVir: newProps.isVir })
        }
    }

    componentDidMount() {
        trackEvent(HeapEvents.CLIENT_ID_FIRST_CHECK_VIEWPAGE);
        if (!this.props.personalInfo) {
            this.props.loadInterview(this.props.interviewTaxYear)
        }
    }

    onBackToDashClicked(e) {
        if (this.state.stage === 'summary' && this.state.prepareForSpouse) {
            this.props.setStage(this.state.target, 'start')
        } else {
            // head on back to the dashboard
            this.props.popNavigation()
        }
    }

    confirmIdentity(ev) {
        // do the thing
        if (this.state.onConfirmIdentity) {
            this.state.onConfirmIdentity(this.state.skipValidationChecked)
        }
    }

    handleSkipValidationChecked(checked) {
        this.setState({ skipValidationChecked: checked })
    }

    onEditPersonalInfo() {
        var addressData = this.state.personalInfo
        if (this.state.spouseUsingPrimaryAddress && this.state.target === 'spouse') {
            addressData = this.state.primary
        }
        this.props.initializeWorkingData(this.props.interviewTaxYear, 'edit', this.state.personalInfo, addressData)
    }

    onShowConfirmHelp() {
        HelpCentre.openHelpLink('ConfirmingYourIdentity/HowDoesConfirmIDWork')
    }

    render() {
        var backLabel = this.state.stage === 'summary' && this.state.prepareForSpouse ? 'ConfirmIdentity.Single.Back' : 'Common.ToDashboard'
        if (this.state.layoutType === 'mobile') {
            backLabel = 'Common.Back'
        }

        var canContinue = this.state.personalInfo && this.state.personalInfo.firstName && this.state.personalInfo.lastName
            && (this.state.address || this.state.address2)
            && this.state.city && this.state.province
            && this.state.personalInfo.sin && this.state.postalCode && this.state.personalInfo.dob

        return this.state.personalInfo == null ? (
            <div className="confirmContainerOuter">
                <div className="confirmCenteredContent">
                    <LoadingSpinner />
                </div>
            </div>
        ) : (
            <div className="confirmContainerOuter">
                <div className="confirmContainer">
                    <div className="confirmBackButtonStyle" onClick={(e) => this.onBackToDashClicked(e)}>
                        <FontAwesome className='fa-angle-left' name='fa-angle-left' size='2x' />
                        <h4>{T.translate(backLabel)}</h4>
                    </div>
                    <div className="mediumMargins confirmIdContainer">
                        <h1>{T.translate("ConfirmIdentity.Summary.Title", { FirstName: this.state.personalInfo.firstName })}</h1>
                        <div style={{ height: 20 }} />
                    </div>
                    <span className="confirmIdContainerSpan mediumMargins">
                        {T.translate("ConfirmIdentity.Single.Content")}
                        <span style={{ marginRight: '5px'}} />
                            <span className="learnMoreStyle" onClick={(ev) => this.onShowConfirmHelp(ev)}>{T.translate(`ConfirmIdentity.Single.HowDoesThisWork.${this.props.showOteContent ? 'Ote' : 'Direct'}`)}</span> 
                    </span>
                    {!this.state.isVir ? <span className="confirmIdContainerSpan mediumMargins topMargin"><b>{T.translate("ConfirmIdentity.Single.SubContent")}</b></span> : <span className = "topMargin"/>}
                    <ConfirmIdUserSummary lang={this.state.lang} layoutType={this.state.layoutType}
                        firstName={this.state.personalInfo.firstName} lastName={this.state.personalInfo.lastName}
                        middleName={this.state.personalInfo.middleName} 
                        maskedSin={this.state.maskedSin} dob={this.state.personalInfo.dob}
                        address={this.state.address} address2={this.state.address2} cityProvince={this.state.cityProvince}
                        postal={this.state.postalCode} city={this.state.city} province={this.state.province}
                        onEditPersonalInfo={() => this.onEditPersonalInfo()} isVir={this.state.isVir} isProduction={this.props.isProduction} 
                        skipValidationChecked={this.state.skipValidationChecked} onSkipValidationChecked={(checked) => this.handleSkipValidationChecked(checked)}
                    />
                </div>
                <div className={this.state.layoutType === 'mobile' ? 'confirmContainer' : 'confirmContainer footerBorder'} >
                    <ConfirmIdUserSummaryFooter
                            dataComplete={Boolean(canContinue)}
                            onContinue={(ev) => this.confirmIdentity()}
                            isVir={this.state.isVir} />
                </div>
            </div>
        );
    }
}

const maskSin = (sin) => {
    var last3 = ''
    if (sin.length > 3) {
        last3 = sin.substring(sin.length - 4);
    }
    if (last3) {
        return last3
    } else {
        return ''
    }
}

const formatAddress = (target, spouseUsingPrimaryAddress, pi, primary) => {
    var addr = ''
    if (pi != null) {
        var info = target === 'spouse' && spouseUsingPrimaryAddress ? primary : pi
        if (info.streetName && info.streetNumber) {
            if (info.aptNumber) {
                addr += info.aptNumber + ' - '
            }
            if (info.streetNumber) {
                addr += info.streetNumber + ' '
            }
            if (info.streetName) {
                addr += info.streetName
            }
        }
    }

    return addr
}
const formatAddress2 = (target, spouseUsingPrimaryAddress, pi, primary) => {
    var addr = ''
    if (pi != null) {
        var info = target === 'spouse' && spouseUsingPrimaryAddress ? primary : pi
        if (info.ruralRouteNumber) {
            addr += 'RR ' + info.ruralRouteNumber
        }
        if (!info.ruralRouteNumber && info.poBox) {
            addr += 'PO Box ' + info.poBox
        }
    }
    return addr
}

const formatCityProv = (target, spouseUsingPrimaryAddress, pi, primary) => {
    if (pi != null) {
        var info = target === 'spouse' && spouseUsingPrimaryAddress ? primary : pi
        if (info.city || info.provice) {
            return info.city + ', ' + info.province
        } else {
            return ''
        }
    }
    return ''
}

const formatPostalCode = (target, spouseUsingPrimaryAddress, pi, primary) => {
    if (pi != null) {
        var info = target === 'spouse' && spouseUsingPrimaryAddress ? primary : pi
        return info.postalCode
    }
    return ''
}

const getCity = (target, spouseUsingPrimaryAddress, pi, primary) => {
    if (pi != null) {
        var info = target === 'spouse' && spouseUsingPrimaryAddress ? primary : pi
        return info.city
    }
    return null;
}

const getProvince = (target, spouseUsingPrimaryAddress, pi, primary) => {
    if (pi != null) {
        var info = target === 'spouse' && spouseUsingPrimaryAddress ? primary : pi
        return info.province
    }
}


const mapStateToProps = (state, ownProps) => {
    var interviewTaxYear = ownProps.interviewTaxYear;

    var interview = state.interview[interviewTaxYear]

    var pi = null;
    var primary = null;
    var spouseUsingPrimaryAddress = false;

    if (interview != null) {
        pi = state.confirmIdentity.target === 'spouse' ? interview.interview.spouse : interview.interview.primary
        primary = interview.interview.primary
        spouseUsingPrimaryAddress = interview.interview.spouse.mailingAddressSameAsPrimary === 'Y'
    }
    return {
        layoutType: state.layout.layoutType,
        lang: state.layout.lang,
        maskedSin: pi ? maskSin(pi.sin) : '',
        taxYear: ownProps.taxYear,
        interviewTaxYear,        
        prepareForSpouse: ownProps.prepareForSpouse,
        address: formatAddress(state.confirmIdentity.target, spouseUsingPrimaryAddress, pi, primary),
        address2: formatAddress2(state.confirmIdentity.target, spouseUsingPrimaryAddress, pi, primary),
        cityProvince: formatCityProv(state.confirmIdentity.target, spouseUsingPrimaryAddress, pi, primary),
        postalCode: formatPostalCode(state.confirmIdentity.target, spouseUsingPrimaryAddress, pi, primary),
        city: getCity(state.confirmIdentity.target, spouseUsingPrimaryAddress, pi, primary),
        province: getProvince(state.confirmIdentity.target, spouseUsingPrimaryAddress, pi, primary),
        confirmSelf: state.confirmIdentity.self,
        confirmSpouse: state.confirmIdentity.spouse,
        stage: state.confirmIdentity.stage,
        personalInfo: pi,
        primary: primary,
        target: state.confirmIdentity.target,
        spouseUsingPrimaryAddress: spouseUsingPrimaryAddress,
        isProduction: state.config.appConfig.isProduction,
        showOteContent: state.workflow.is_direct_user === false
    }
}


const mapDispatchToProps = (dispatch) => ({
    popNavigation: () => dispatch(goBack()),
    setButtonState: (buttonName, buttonState) => dispatch(WorkflowActions.setButtonState(buttonName, buttonState)),
    initializeWorkingData: (taxYear, stage, personalInfo, addressData) => dispatch(ConfirmIdentityActions.initializeWorkingData(taxYear, stage, personalInfo, addressData)),
    setStage: (target, stage) => dispatch(ConfirmIdentityActions.setStage(target, stage)),
    loadInterview: (taxYear) => dispatch(InterviewActions.loadInterview(taxYear))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmIdUser)

