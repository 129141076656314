import React, { useState }  from 'react';
import PropTypes from 'prop-types'
import WorkflowSection from '../Section/WorkflowSection'
import T from 'i18n-react';
import '../Section/section.css'
import ConfirmWorkflowButton from '../Section/ConfirmWorkflowButton'
import { WorkflowButtonStates } from '../Section/WorkflowButtonStates'
import HelpLink from '../../Controls/helpLink'
import { useTicketType, ValidTicketTypes } from '../../../hooks/useTicketTypeHook';
import PlainButton from '../../Controls/plainButton'
import { trackEvent, HeapEvents } from '../../../utils/AnalyticsUtil' 

const DocumentUploadReturnSection = ({ currentYear, taxState, onWorkflowButtonAction }) => {
    const { ticketType } = useTicketType(taxState);
    const validTicketTypes = ValidTicketTypes;
    const [isLoading, setIsLoading] = useState(false);
    var helpLink = <HelpLink linkKey="WorkflowPanel.upload-docs.coming-up-link-text" linkId='OTE_FAQ/HowMuchWillThisCost' />;
    var isConfirmButtonState = taxState.workflow_state.upload_docs === 'Confirm';

    const onConfirmButtonAction = (action, taxYear, state) => {
        // if the user says they have more docs to upload, trigger the upload action
        //it is oposite for VDO and VIR
        if (isConfirmButtonState) {
            if (ticketType === validTicketTypes.VIR) {
                if (state === 'yes') {
                    // done uploading, mark the step complete                
                    onWorkflowButtonAction(action, taxYear, "no");
                } else {
                    //not done uploading
                    let contextData = { "ticketType": ticketType, "comingFrom": "dashboard" };
                    onWorkflowButtonAction('upload-docs', taxYear, contextData);
                }
            }
            else {
                if (state === 'no') {
                    // done uploading, mark the step complete                
                    onWorkflowButtonAction(action, taxYear, "no");
                    trackEvent(HeapEvents.DASHPG_STARTRETURN + '-' + taxYear);
                } else {
                    //has additional documents
                    let contextData = { "ticketType": ticketType, "comingFrom": "dashboard" };
                    onWorkflowButtonAction('upload-docs', taxYear, contextData);
                    trackEvent(HeapEvents.DASHPG_VIEWADDDOCS + '-' + taxYear);
                }
            }
        } else {
            if (state === 'yes') {
                let contextData = { "ticketType": ticketType, "comingFrom": "dashboard" };
                onWorkflowButtonAction('upload-docs', taxYear, contextData);
                trackEvent(HeapEvents.DASHPG_ADDDOCS + '-' + taxYear);
            } else {
                // otherwise mark the step complete                
                onWorkflowButtonAction(action, taxYear, state);
                trackEvent(HeapEvents.DASHPG_ALERTEXPERT + '-' + taxYear);
            }
        }
    }

    return (
        <WorkflowSection titleKey={ticketType === validTicketTypes.VIR ? 'WorkflowPanel.taxReturnTitleVIR' : (isConfirmButtonState || taxState.workflow_state.upload_docs === 'ConfirmWorking') || (taxState.workflow_state.upload_docs === 'Show' || taxState.workflow_state.upload_docs === 'Hide') ? 'WorkflowPanel.taxReturnTitleIncomplete' : 'WorkflowPanel.taxReturnTitle'}
            titleKeyProps={{ TaxYear: taxState.taxYear }}
            isAlternate={(currentYear - taxState.taxYear) % 2 === 1}
            comingUpKey="WorkflowPanel.upload-docs.coming-up"
            comingUpKeyProps={{ HelpLink: helpLink }}
            canClose={false}
            onClose={() => onWorkflowButtonAction('delete-return', taxState.taxYear)}
            ticketType={ticketType}>

            {(isConfirmButtonState || taxState.workflow_state.upload_docs === 'ConfirmWorking') &&
                <div>
                    <div onClick={() => onConfirmButtonAction('upload-docs-confirm', taxState.taxYear, 'yes')}>
                        <ConfirmWorkflowButton buttonKey="upload-docs" name="upload-docs" icon={'fa-cloud-upload'}
                            buttonState={WorkflowButtonStates.SHOW} ticketType={ticketType}
                            text={`WorkflowPanel.upload-docs.confirm-title.done-uploading.${ticketType.toLowerCase()}`} textProps={{ TaxYear: taxState.taxYear }}
                            isAlternate={(currentYear - taxState.taxYear) % 2 === 1} onFadeOut={() => { }} />
                    </div>
                    <div className='wf-upload-docs-bottom-container'>
                        <div className="wf-doc-upload-label-container">
                            {T.translate(`WorkflowPanel.upload-docs.subtext.rte_additional`)}
                        </div>
                        <div className="wf-doc-upload-button">
                            <PlainButton isDocUploadButton={true} inProgress={isLoading} isAlternate={(currentYear - taxState.taxYear) % 2 === 1} labelKey='WorkflowPanel.upload-docs.upload-done-button' onClick={() => {setIsLoading(true); onConfirmButtonAction('upload-docs-confirm', taxState.taxYear, 'no')}} />
                        </div>
                    </div>
                </div>
            }

            {(taxState.workflow_state.upload_docs === 'Show' || taxState.workflow_state.upload_docs === 'Hide') &&
                <div>
                    <div onClick={() => onConfirmButtonAction('upload-docs-confirm', taxState.taxYear, 'yes')}>
                        <ConfirmWorkflowButton buttonKey="upload-docs" name="upload-docs" icon={'fa-cloud-upload'}
                            buttonState={WorkflowButtonStates.SHOW} ticketType={ticketType}
                            text={`WorkflowPanel.upload-docs.confirm-title.upload`} textProps={{ TaxYear: taxState.taxYear }}
                            isAlternate={(currentYear - taxState.taxYear) % 2 === 1} onFadeOut={() => { }} />
                    </div>
                    <div className='wf-upload-docs-bottom-container'>
                        <div className="wf-doc-upload-label-container">
                            {T.translate(`WorkflowPanel.upload-docs.subtext.rte`)}
                        </div>
                        <div className="wf-doc-upload-button">
                            <PlainButton isDocUploadButton={true} inProgress={isLoading} isAlternate={(currentYear - taxState.taxYear) % 2 === 1} labelKey='WorkflowPanel.upload-docs.alert-button-text' onClick={() => {setIsLoading(true); onConfirmButtonAction('upload-docs-confirm', taxState.taxYear, 'no')}} />
                        </div>
                    </div>
                </div>
            }
        </WorkflowSection>
    );
};

DocumentUploadReturnSection.propTypes = {
    taxState: PropTypes.shape({
        taxYear: PropTypes.number.isRequired,
        coupled: PropTypes.bool.isRequired,
        primary_province: PropTypes.string,
        primary_firstName: PropTypes.string.isRequired,
        spouse_province: PropTypes.string,
        spouse_firstName: PropTypes.string,
        subTotal: PropTypes.number,
        tenderTotal: PropTypes.number,
        workflow_state: PropTypes.shape({
            upload_docs: PropTypes.string.isRequired
        }),
        interview_state: PropTypes.shape({
            complete: PropTypes.bool.isRequired,
            current_page: PropTypes.string.isRequired
        }).isRequired,
        showDirectUserContent: PropTypes.bool
    }),
    currentYear: PropTypes.number.isRequired,
    onWorkflowButtonAction: PropTypes.func.isRequired,
    isDirectUser: PropTypes.bool
}

export default DocumentUploadReturnSection;
