import T from 'i18n-react';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import ActivityMonitor from './components/App/activityMonitor';
import DisplayController from './components/App/displayController';
import AddLoadConfiguration from './components/App/Init/addLoadConfiguration';
import TimerController from './components/App/timerController';
import DocumentSigner from './components/Controls/documentSigner';
import DirectPage from './components/Direct/directPage';
import ErrorPage from './components/Error/errorPage';
import HomePage from './components/Home/homePage';
import LoadingPage from './components/Loading/loadingPage';
import LoginCallback from './components/Login/loginCallback';
import LoginLandingPage from './components/Login/loginLandingPage';
import OTCLoginCallback from './components/Login/otcCallback';
import OTCFailurePage from './components/Login/otcFailurePage';
import OTCRedemptionPage from './components/Login/otcRedemptionPage';
import RefreshLoginCallback from './components/Login/refreshLoginCallback';
import LogoutPage from './components/Logout/LogoutPage';
import RegistrationRouter from './components/Register/registrationRouter';
import AcceptVir from './components/VIR/acceptVir';
import WorkflowRouter from './components/Workflow/workflowRouter';
import PrivateRoute from './privateRoute';
import AuthActions from './reducers/authRedux';

const App = props => {

    const dispatch = useDispatch()
    const history = useHistory()

    const { dialogOpen, lang } = useSelector(state => state.layout)
    const { loginComplete, loggingOut } = useSelector(state => state.auth)

    useEffect(() => {
        window.document.title = T.translate("AppNameGeneric")
    }, [lang])

    useEffect(() => {
        var el = document.body
        if (dialogOpen) {
            if (!hasClass(el, 'dialogopen')) {
                addClass(el, 'dialogopen')
            }
        } else {
            if (hasClass(el, 'dialogopen')) {
                removeClass(el, 'dialogopen')
            }
        }
    }, [dialogOpen])

    const hasClass = (el, className) => {
        if (el.classList) {
            return el.classList.contains(className);
        } else {
            return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
        }
    }

    const addClass = (el, className) => {
        if (el.classList) {
            el.classList.add(className)
        }
        else if (!this.hasClass(el, className)) {
            el.className += " " + className;
        }
    }

    const removeClass = (el, className) => {
        if (el.classList)
            el.classList.remove(className)
        else if (this.hasClass(el, className)) {
            var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
            el.className = el.className.replace(reg, ' ');
        }
    }

    const handleLogout = (wasAuto) => {
        dispatch(AuthActions.logoutUser(wasAuto ? 'logout_auto' : 'logout'))
    }

    const goToHome = (evt) => {
        history.replace("/home")
    }

    return (
        <Fragment>
            <Switch>
                <Route exact path='/'>
                    <Fragment>
                        {loginComplete && <Redirect to='/dashboard' />}
                        {!loginComplete && loggingOut && <LoadingPage loaded={props.loaded}/>}
                        {!loginComplete && !loggingOut && <Redirect to='/home' />}
                    </Fragment>
                </Route>
                <Route path='/callback/refresh'><RefreshLoginCallback /></Route>
                <Route path='/callback'><LoginCallback /></Route>
                <Route path='/one_time'><OTCRedemptionPage /></Route>
                <Route path='/direct'><DirectPage loaded={props.loaded} /></Route>
                <Route path='/otcCallback'><OTCLoginCallback /></Route>
                <Route path='/otc_fail'><OTCFailurePage /></Route>
                <Route path='/logout/:type?'><LogoutPage loaded={props.loaded} /></Route>
                <Route path='/login'><LoginLandingPage /></Route>
                <Route path='/missing'><ErrorPage goBackCallback={goToHome} loaded={props.loaded} /></Route>
                <Route path='/home'><HomePage loaded={props.loaded} /></Route>
                <PrivateRoute path='/dashboard' loaded={props.loaded}><WorkflowRouter loaded={props.loaded} /></PrivateRoute>
                <Route path='/register/:fragment?'><RegistrationRouter loaded={props.loaded} /></Route>
                <PrivateRoute path='/vir/:taxYear/:vmId' loaded={props.loaded}><AcceptVir loaded={props.loaded} /></PrivateRoute>
                <Route path='*'><ErrorPage goBackCallback={goToHome} /></Route>
            </Switch>
            <ActivityMonitor key="activityMonitor" logoutHandler={handleLogout} />
            <DisplayController key="displayController" />
            <TimerController key="timerController" />
            <DocumentSigner key="documentSigner" />
        </Fragment>
    )
}

export default AddLoadConfiguration(withRouter(App));