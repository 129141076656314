import React, { Component } from 'react';
import { connect } from 'react-redux'
import '../Common/shared.css'
import './style.css';
import PlainButton from '../Controls/plainButton';

import T from 'i18n-react'

class AccountSetUpUserExistMessage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            layoutType: this.props.layoutType,
            lang: this.props.lang,
            firstName: this.props.firstName,
            signInUserExist: this.props.signInUserExist
        }
    }
    componentWillReceiveProps(newProps) {
        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }
        if (this.state.firstName !== newProps.firstName) {
            this.setState({ firstName: newProps.firstName })
        }
    }


    render() {
        return (
            <div className="reg-modal-content">
                <h1>{T.translate('AccountSetupLogin.labels.WelcomeBack', {firstName: this.state.firstName })} </h1>
                <p>{T.translate('AccountSetupLogin.labels.Label3')} </p>
                <p>{T.translate('AccountSetupLogin.labels.Label4')} </p>
                <div className="centerContainer mailingTopSpacing spacing_bottom">
                    <PlainButton labelKey="Common.SignIn" isEnable={true} onClick={(ev) => this.state.signInUserExist(ev)}/>
                </div>
            </div>
        )
    }

}
const mapStateToProps = (state) => {
    return {
        firstName: state.accountData.firstName,       
    }
}


export default connect(mapStateToProps)(AccountSetUpUserExistMessage);