import { Component } from 'react';
import { connect } from 'react-redux'

import MessagesActions from '../../reducers/messagesRedux'

class MessagesTimers extends Component {
    constructor(props) {
        super(props)

        this.messageUpdateInterval = null

        this.goGetMessages = this.queryCaseMessages.bind(this)
    }
    
    componentWillMount() {
        this.startMessageUpdateInterval()
    }

    componentWillUnmount() {
        this.clearMessageUpdateInterval();
    }


    clearMessageUpdateInterval() {
        if (this.messageUpdateInterval) {
            clearInterval(this.messageUpdateInterval)
            this.messageUpdateInterval = null
        }
    }

    startMessageUpdateInterval() {
        this.clearMessageUpdateInterval();
        if (!this.messageUpdateInterval) {
            setTimeout(this.goGetMessages, 10)
            this.messageUpdateInterval = setInterval(this.goGetMessages, 60 * 1000)
        }
    }

    queryCaseMessages() {
        this.props.getMessages()
    }

    render() {
        return null
    }
}

const mapDispatchToProps = (dispatch) => ({
    getMessages: () => dispatch(MessagesActions.getMessages())
})

export default connect(null, mapDispatchToProps)(MessagesTimers)