import React, { Component } from 'react';
import './shared.css';
import FontAwesome from 'react-fontawesome';
import InputMask from 'react-input-mask';
import T from 'i18n-react';
import { isValidPostalCode } from '../../utils/PostalCodeUtil'

class PostalCodeTextInput extends Component {
    constructor(props) {
        super(props)

        this.state = {
            labelText: this.props.labelText,
            validationFailedText: this.props.validationFailedText || 'Common.Errors.InvalidPostalCode',
            hasValidated: false,
            hasErrors: false,
            specificErrorText: null,
            value: this.props.value || "",
            useFrenchLocale: this.props.isFrench,
            renderErrorBorderOnly: this.props.renderErrorBorderOnly || false,
            autoComplete: this.props.autoComplete
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.labelText !== newProps.labelText) {
            this.setState({ labelText: newProps.labelText })
        }
        if (this.state.value !== newProps.value) {
            this.setState({ value: newProps.value })
        }
        if (this.state.renderErrorBorderOnly !== newProps.renderErrorBorderOnly) {
            this.setState({ renderErrorBorderOnly: Boolean(newProps.renderErrorBorderOnly) })
        }
        if (this.state.autoComplete !== newProps.autoComplete) {
            this.setState({ autoComplete: newProps.autoComplete })
        }
        if (this.state.hasErrors !== newProps.hasErrors && (newProps.hasErrors === true || newProps.hasErrors === false)) {
            this.setState({ hasErrors: newProps.hasErrors})
        }
        if (this.state.specificErrorText !== newProps.specificErrorText) {
            this.setState({ specificErrorText: newProps.specificErrorText })
        }
    }

    validateText(currText) {
        return isValidPostalCode(currText)
    }        

    handleTextChange(ev) {
        // once we've validated, validate on every change
        var state = {
            ...this.state,
            value: ev.target.value ? ev.target.value.toUpperCase() : ''
        }

        var isValid = this.validateText(state.value)
        if (this.state.hasValidated) {
            state.hasErrors = !isValid
        }

        this.setState(state);

        if (this.props.onChange) {
            this.props.onChange(ev, state.value, isValid);
        }
    }

    handleBlur() {
        if (!this.state.hasValidated && this.state.value) {
            this.setState({ hasValidated: true, hasErrors: !this.validateText(this.state.value) })
        }
    }

    handleKeyDown(e) {
        if (!!this.props.enterPressed && (e.key === "Enter" || e.key === "NumpadEnter")) {
            if (this.state.value) {
                let isValid = this.validateText(this.state.value)
                // force validation on keypress
                this.setState({ hasErrors: !isValid })
                // confirm that the user wants to continue, but tell the parent component whether or not it can actually do it.
                this.props.enterPressed(isValid)
            }
        }
    }

    render() {
        const placeholderText = "A1A 1A1"
        const mask = "a9a 9a9"

        var inputContent = (
            <div className="phoneInputContainer">
                <InputMask value={this.state.value} name={this.props.name}
                    className={this.state.hasErrors || this.state.renderErrorBorderOnly ? "phoneInputErrorStyle" : "phoneInputStyle"}
                    placeholder={placeholderText} mask={mask} maskChar="" autoComplete={this.state.autoComplete ? this.state.autoComplete : "off"}
                    onChange={(ev) => { this.handleTextChange(ev) }} onBlur={() => this.handleBlur()} disabled={this.props.disabled} onKeyDown={(e) => this.handleKeyDown(e)} />
            </div>
        )
        if (this.state.hasErrors || this.state.renderErrorBorderOnly) {
            let styleName = "red formLabelStyle"

            var valContent = null
            if (this.state.hasErrors) {
                valContent = (
                    <div className="validationErrorStyle">
                        <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#E70000', align: 'center' }} />
                        <span style={{ marginLeft: 10 }}>{T.translate(this.state.specificErrorText || this.state.validationFailedText)}</span>
                    </div>
                )
            }
            return (
                <div className="defaultErrorStyles">
                    <div className={styleName}>
                        {this.state.labelText}
                    </div>
                    {inputContent}
                    {valContent}
                </div>
            )
        } else {
            return (
                <div>
                    <div className="formLabelStyle">
                        {this.state.labelText}
                    </div>
                    {inputContent}
                    <div className="validationErrorStyle">
                       &nbsp;
                    </div>
                </div>
            )

        }
    }
}


export default PostalCodeTextInput;