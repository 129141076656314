import apisauce from 'apisauce'
import { addErrorHandler, getApiBaseUrl } from './serviceUtils'

/**
 * API for setting and retrieving interview data
 * @param {String} baseURL
 */
const create = (baseURL = getApiBaseUrl()) => {  // this will change for prod deployment
    // Create and configure an apisauce-based api object.
    const api = apisauce.create({
        baseURL,
        headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Content-Type': 'application/json' },
        timeout: 20000
    })

    addErrorHandler(api)

    const getAuthHeader = (accessToken) => {
        return {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        }
    }

    const getCompletedFileInfo = (accessToken, taxYear) => {
        return api.get('documents/completed?taxYear=' + taxYear, '', getAuthHeader(accessToken))
    }

    const getDocumentsForReview = (accessToken, taxYear) => {
        return api.get('documents/review?taxYear=' + taxYear, '', getAuthHeader(accessToken))
    }

    const getApprovalDocuments = (accessToken, taxYear) => {
        return api.get('documents/approval?taxYear=' + taxYear, '', getAuthHeader(accessToken))
    }

    const setFileViewed = (accessToken, fileId, taxYear) => {
        var payload = {
            fileId,
            taxYear
        }
        return api.get('documents/viewed', payload, getAuthHeader(accessToken))
    }

    const sendOneTimeSMS = (accessToken, targetRoute, taxYear) => {
        var payload = {
            TargetRoute: targetRoute,
            TaxYear: taxYear
        }
        return api.post('token', payload, getAuthHeader(accessToken))
    }

    const redeemOneTimeCode = (code) => {
        var payload = {
            Token: code
        }
        return api.post('token/redeem', payload)
    }

    const setFileWasUploaded = (accessToken, taxYear) => {
        var payload = {
            taxYear
        }
        return api.post('documents/uploaded', payload, getAuthHeader(accessToken))
    }

    const uploadstepcompleted = (accessToken, taxYear, isComplete) => {
        var payload = {
            taxYear,
            isComplete
        }
        return api.post('documents/upload', payload, getAuthHeader(accessToken))
    }

    const setApprovalComplete = (accessToken, taxYear) => {
        var payload = {
            taxYear
        }
        return api.post('documents/approval', payload, getAuthHeader(accessToken))
    }

    const sendReviewCompleteMessage = (accessToken, action) => {
        var payload = {
            accountId: action.accountId,
            caseId: action.caseId
        }
        return api.post('documents/reviewcompletemessage', payload, getAuthHeader(accessToken))
    }

    const sendReviewCompleteMessageWetSignature = (accessToken, action) => {
        var payload = {
            accountId: action.accountId,
            caseId: action.caseId
        }
        return api.post('documents/reviewcompletemessagewetsignature', payload, getAuthHeader(accessToken))
    }

    const getSuggestions = (accessToken, taxYear) => {
        return api.get('suggesteddocs/'+ taxYear, '', getAuthHeader(accessToken))
    }

    // Return back a collection of functions
    return {
        setFileViewed, sendOneTimeSMS, redeemOneTimeCode,
        getCompletedFileInfo, setFileWasUploaded, uploadstepcompleted,
        getDocumentsForReview, getApprovalDocuments, setApprovalComplete, sendReviewCompleteMessage, sendReviewCompleteMessageWetSignature,
        getSuggestions
    }
}

// let's return back our create method as the default.
export default {
    create
}