import React, { Component } from 'react';
import './style.css';
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';
import PlainButton from '../Controls/plainButton'

class UploaderDocsReminder extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            layoutType: this.props.layoutType,
            lang: this.props.lang,
            uploaderState: this.props.uploaderState,
            onOK: this.props.onOK,
            onCancel: this.props.onCancel,
            onDialogClose: this.props.onDialogClose,
            mobilePhone: this.props.mobilePhone
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.uploaderState !== newProps.uploaderState) {
            this.setState({ uploaderState: newProps.uploaderState })
        }

        if (this.state.onOK !== newProps.onOK) {
            this.setState({ onOK: newProps.onOK })
        }

        if (this.state.onCancel !== newProps.onCancel) {
            this.setState({ onCancel: newProps.onCancel })
        }

        if (this.state.onDialogClose !== newProps.onDialogClose) {
            this.setState({ onDialogClose: newProps.onDialogClose })
        }

        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }

        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }

        if (this.state.mobilePhone !== newProps.mobilePhone) {
            this.setState({ mobilePhone: newProps.mobilePhone })
        }
    }

    handleOKClick(ev) {
        if (this.state.onOK) {
            this.state.onOK(ev)
        }
    }

    handleCancelClick(ev) {
        if (this.state.onCancel) {
            this.state.onCancel(ev)
        } else if (this.state.onOK) {
            this.state.onOK(ev)
        }
    }

    handleDialogCloseClick(ev) {
        if (this.state.onDialogClose) {
            this.state.onDialogClose(ev)
        } else {
            this.handleCancelClick(ev)
        }
    }

    formatPhone() {
        if (this.state.mobilePhone && this.state.mobilePhone.length === 10) {
            return this.state.mobilePhone.substring(0, 3) + '-' + this.state.mobilePhone.substring(3, 6) + '-' + this.state.mobilePhone.substring(6)
        }
        return this.state.mobilePhone
    }

    render() {                
        var buttonLayout = ["dialogBottomSpacing", "dialogTopSpacing", "dialogButtonContainer"];

        return (
            <div className="defaultStyles">
                <div className="bodyContainer" >
                    <div className="dialogContentContainer leftSpacing rightSpacing centerAlign maxDialogWidth">
                        <div className='dialogContentContainer greenContent'>
                            <FontAwesome style={{ marginBottom: '20px' }} className='fa-lightbulb-o' name='fa-lightbulb-o' size='5x' />
                            <span className="dialogTitle uploadDialogTitle">
                                {T.translate('Uploader.Info.Title', { MobilePhone: this.formatPhone(this.state.mobilePhone) })}
                            </span>
                        </div>
                        <div className='bottomSpacing' />
                        <span>{T.translate('Uploader.Info.SubTitle')}</span>
                        <div className='bottomSpacing' />
                    </div>
                </div>
                <div className={buttonLayout.join(' ')}>
                    <div className="dialogButtonHolder">
                        <PlainButton labelKey='Common.Okay'
                            isEnabled={true} onClick={(ev) => { this.handleOKClick() }} />
                    </div>
                </div>
            </div>
        )
    }
}

export default UploaderDocsReminder;