import { put, select, call } from 'redux-saga/effects'
import { push, replace, goBack } from 'connected-react-router'
import WorkflowActions from '../reducers/workflowRedux'
import MessagesActions from '../reducers/messagesRedux'
import { determineIsDirectClient } from '../utils/CaseStateUtilities'

export const getAccessToken = state => state.auth.accessToken
export const getWorkflow = state => state.workflow
export const getCaseSettings = state => state.workflow.caseSettings
export const getConfirmIdState = state => state.confirmIdentity

export function* getCaseState(api, action) {
    var accessToken = yield select(getAccessToken)

    var response = yield call(api.getWorkflowState2, accessToken)
    if (response && response.ok) {
        yield put(WorkflowActions.storeCaseState(response.data))
        yield put(MessagesActions.setUnreadMessagesCount(response.data.unreadMessagesCount))
        let isDirect = determineIsDirectClient(response.data)
        yield put(WorkflowActions.setIsDirectUser(isDirect))

        if (action.routerAction === 'pop') {
            yield put(goBack());
        } else if (action.routerAction === 'push' && action.path) {
            yield put(push(action.path))
        } else if (action.routerAction === 'replace' && action.path) {
            yield put(replace(action.path))
        }
    }
}

export function* loadCaseSettings(api, action) {
    var accessToken = yield select(getAccessToken)

    var response = yield call(api.getCaseSettings, accessToken, action.taxYear)
    if (response && response.ok) {
        yield put(WorkflowActions.setCaseSettings(action.taxYear, response.data))
    } else {
        console.log('Failed to get workflow settings')
    }
}

export function* saveCaseSettings(api, action) {
    var accessToken = yield select(getAccessToken)

    var response = yield call(api.setCaseSettings, accessToken, action.taxYear, action.setting, action.value)
    if (response && response.ok) {
        // do nothing?
    }
}

export function* deleteCase(api, action) {
    var accessToken = yield select(getAccessToken)

    var response = yield call(api.deleteCase, accessToken, action.taxYear)
    if (response && response.ok) {
        yield put(WorkflowActions.updateCaseState())
        yield put(WorkflowActions.setCaseDeletedStatus(true))
    } else {
        yield put(WorkflowActions.setCaseDeletedStatus(false, response.status))
    }
}

export function* setLastReviewedPage(api, action) {
    var accessToken = yield select(getAccessToken)

    yield call(api.setLastReviewedPage, accessToken, action)
}

export function* notifyOnScheduleCallAttempt(api, action) {
    var accessToken = yield select(getAccessToken)
    if (accessToken) {
        yield call(api.notifyOnScheduleCallAttempt, accessToken, action.taxyear)
    }
}