import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import T from 'i18n-react';
import YesNoCheckButtons from '../Common/yesNoCheckButtons'
import ProductActions from '../../reducers/productsRedux'
import { HeapEvents, trackEvent } from '../../utils/AnalyticsUtil'

const PomSelection = ({ caseId, product, filer, taxYearState }) => {
    const dispatch = useDispatch();
    var isSaveInProgress = !useSelector(state => state.products.isSaved);

    const POMSelectionButtonChanged = (value) => {
        if (value === 'Y') {
            dispatch(ProductActions.savePOMSelection(caseId, product, filer, 'true'))
            trackEvent(HeapEvents.BUTTON_POM_ADD_ON)
        }
        else {
            dispatch(ProductActions.savePOMSelection(caseId, product, filer, 'false'));
            trackEvent(HeapEvents.BUTTON_POM_DECLINE);
        }
    }

    const onLearnMoreAboutPOMClicked = () => {
        window.open(T.translate('Common.POMMarkettingWebsite'))
        trackEvent(HeapEvents.BUTTON_POM_LEARN);
    };

    return (
        <div>
            <div className='pomSelectionTitle'>
                {T.translate('ReviewAndApprove.POMSection.Title')}
            </div>
            <div className='pom-learnMoreStyleDiv-review'>
                <span className='pom-learnMoreStyle' onClick={() => onLearnMoreAboutPOMClicked()}>
                    {T.translate('ReviewAndApprove.POMSection.Learn')}
                </span>
            </div>
            <div>
                <YesNoCheckButtons name="pomSelectionChange" value={filer === 'primary' ? (taxYearState.products_state.hasPrimaryOptedIn?.toLowerCase?.() === 'true' ? 'Y' : taxYearState.products_state.hasPrimaryOptedIn?.toLowerCase?.() === 'false' ? 'N' : null) : (taxYearState.products_state.hasSpouseOptedIn?.toLowerCase?.() === 'true' ? 'Y' : taxYearState.products_state.hasSpouseOptedIn?.toLowerCase?.() === 'false' ? 'N' : null)}
                    onStateChanged={(ev, value) => { POMSelectionButtonChanged(value) }} disabled={isSaveInProgress} />
            </div>
        </div>
    )
}

export default PomSelection