import apisauce from 'apisauce'
import { addErrorHandler, getApiBaseUrl } from './serviceUtils'
/**
 * API for retrieving direction information from google using apisauce
 * @param {String} baseURL
 */
const create = (baseURL = getApiBaseUrl()) => {  // this will change for prod deployment
    // Create and configure an apisauce-based api object.
    const api = apisauce.create({
        baseURL,
        headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Content-Type': 'application/json' },
        timeout: 60000
    })

    addErrorHandler(api);

    // Wrap api's addMonitor to allow the calling code to attach additional monitors in the future.
    // Only in __DEV__ and only if we've attached Reactotron to console (it isn't during unit tests).
    //if (__DEV__ && console.tron) {
    //  api.addMonitor(console.tron.apisauce)
    //}

    const getAuthHeader = (accessToken) => {
        return {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            } 
        }
    }

    const checkForExistingAccount = (email) => {
        var payload = {
            "email": email
        }

        return api.get('account', payload)
    }

    const createNewAccount = (accountId, accountData, creds, locale, accessToken) => {
        var payload = {
            FirstName: accountData.firstName,
            LastName: accountData.lastName,
            Email: accountData.email,
            PhoneNumber: accountData.mobilePhone,
            PhoneTypeId: accountData.phoneType,
            Password: creds ? creds.password.value : null,
            Question: creds ? creds.questionIndex + 1 : -1,
            Province: accountData.province,
            Answer: creds ? creds.answer : null,
            Hint: creds ? creds.hint : null,
            LanguagePreference: locale && locale === 'fr' ? "fr-CA" : "en-US",
            ContactConsent: accountData.consentChecked
        }
        if (accountId) {
            payload.AccountId = accountId
        }

        if (accessToken) {
            console.log('updating account via put')
            return api.put('account', JSON.stringify(payload), getAuthHeader(accessToken))
        } else {
            return api.post('account', JSON.stringify(payload), getAuthHeader(accessToken))
        }
    }

    const accountSignIn = (email, password) => {
        var payload = {
            "Email": email,
            "Password": password
        }
        return api.post('auth/signin', payload)
    }

    const accountSignOut = (accessToken) => {
        var payload = ''
        return api.post('auth/signout', payload, getAuthHeader(accessToken))
    }

    const refreshAccessToken = (accessToken) => {
        var payload = ''
        return api.post('auth/refreshtoken', payload, getAuthHeader(accessToken))
    }

    const createCase = (accessToken) => {
        // by not specifying a list of tax years, the server will create one for the current year
        var payload = {
            taxYears: [],
            directCode: localStorage.getItem('va_direct_code')
        }
        return api.post('case', payload, getAuthHeader(accessToken))
    }

    const createConversation = (accessToken) => {
        var payload = ''
        return api.post('account/conversation', payload, getAuthHeader(accessToken))
    }

    const saveLanguagePref = (accessToken, langPref) => {
        return api.post('account/lang?langPref='+langPref, null, getAuthHeader(accessToken))
    }

    const addvarole = (accessToken, email, password) => {
        var payload = {
            "Email": email,
            "Password": password
        }
        return api.post('auth/addvarole', payload, getAuthHeader(accessToken))
    }

  // Return back a collection of functions
  return {
      checkForExistingAccount, createNewAccount, accountSignIn, createCase, createConversation,
      saveLanguagePref, addvarole, accountSignOut, refreshAccessToken
  }
}

// let's return back our create method as the default.
export default {
  create
}