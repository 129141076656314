import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux'
import { Route, useLocation } from 'react-router-dom';
import auth from './sagas/SSO/Auth'
import LoadingPage from './components/Loading/loadingPage';
import AuthActions from './reducers/authRedux'

const PrivateRoute = props => {

    const location = useLocation()

    const {
        loginComplete,
        accessToken,
        finalizeLogout,
        requestLogin,
        children,
        loaded,
        ...rest
    } = props

    useEffect(() => {
        if (!auth.isAuthenticated()) {
            // request new login
            requestLogin(location.pathname + location.search)
        }
    }, [])

    return (
        <Route {...rest}>
            {accessToken && loginComplete 
                ? <Fragment>{children}</Fragment>
                : <LoadingPage loaded={loaded}/>
            }
        </Route>
    )
}

const mapStateToProps = (state) => ({
    accessToken: state.auth.accessToken,
    loginComplete: state.auth.loginComplete
})

const mapDispatchToProps = (dispatch) => ({
    finalizeLogout: () => dispatch(AuthActions.finalizeLogout()),
    requestLogin: (redirect_url) => dispatch(AuthActions.requestLogin(redirect_url)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);