import React from 'react';
import { PropTypes } from 'prop-types';
import T from 'i18n-react';
import classnames from 'classnames'
import './workflowbutton.css';
import FontAwesome from 'react-fontawesome';

const WorkflowButtonMaterial = (props) => (
    <div className={classnames({ 'workflow-button-complete': props.isComplete},'workflow-button-material', props.ticketType)} id={props.buttonKey} onClick={() => props.onClick(props.buttonKey)}>
        <div className='workflow-button-icon-container'>
            {props.icon &&
                <FontAwesome className={props.icon} name={props.icon} size="2x" />
            }
            {props.children}
        </div>
        <div className='workflow-button-material-label-container'>
            <h2><span className='unselectableLabel'>{T.translate(props.text, props.textProps)}</span></h2>
            {props.amount && <span className='workflow-payment-amount'>{T.translate(props.amountLabel)}{props.amount}</span>}
        </div>

        <div className='workflow-material-icon-container'>
            {props.showChevron === 'true' &&
                <FontAwesome className='fa-chevron-right' name='fa-chevron-right' />
            }
        </div>
    </div>
);

WorkflowButtonMaterial.propTypes = {
    text: PropTypes.string.isRequired,
    textProps: PropTypes.object,
    icon: PropTypes.string,
    children: PropTypes.node,
    buttonKey: PropTypes.string.isRequired,
    ticketType: PropTypes.string.isRequired
};

export default WorkflowButtonMaterial;