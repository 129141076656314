import { select, call } from 'redux-saga/effects'
//import SurveyActions from '../reducers/surveyRedux'

export const getSurvey = state => state.survey
export const getAccessToken = state => state.auth.accessToken

export function* createSurvey(api, action) {
    var accessToken = yield select(getAccessToken)
    var survey = yield select(getSurvey)
    yield call(api.setSurvey, accessToken, survey)    
}