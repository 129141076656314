import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    loadCompletedDocuments: ['taxYear'],
    setCompletedDocuments: ['taxYear', 'docs'],
    setCompletedDocumentViewed: ['fileId', 'taxYear'],
    loadDocumentsForReview: ['taxYear'],
    setDocumentsForReview: ['taxYear', 'docs'],
    setDocumentForReviewViewed: ['fileId', 'taxYear'],
    loadApprovalDocuments: ['taxYear'],
    setApprovalDocuments: ['taxYear', 'docs'],
    setApprovalDocumentViewed: ['fileId', 'taxYear'],
    filesReset: null,
    setFileViewed: ['fileType', 'taxYear']
})

export const FilesTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    completedDocuments: {
    },
    documentsForReview: {
    },
    approvalDocuments: {
    }
}

/* ------------- Reducers ------------- */
export const filesSetCompletedDocuments = (state, { taxYear, docs }) => {
    var completedDocs = { ...state.completedDocuments }
    completedDocs[taxYear] = docs;
    return Object.assign({}, state, { completedDocuments: completedDocs })
}

export const filesSetDocumentsForReview = (state, { taxYear, docs }) => {
    var docsForReview = { ...state.documentsForReview }
    docsForReview[taxYear] = docs;
    return Object.assign({}, state, { documentsForReview: docsForReview })
}

export const filesSetApprovalDocuments = (state, { taxYear, docs }) => {
    var approvalDocs = { ...state.approvalDocuments }
    approvalDocs[taxYear] = docs;
    return Object.assign({}, state, { approvalDocuments: approvalDocs })
}

export const filesResetState = (state) => INITIAL_STATE; 

export const filesSetFileViewed = (state, { fileType, taxYear }) => {
    var tax_year = Number(taxYear)

    var files = {
        ...state.approvalDocuments,
        [tax_year]: {
            ...state.approvalDocuments[tax_year],
            [fileType]: {
                ...state.approvalDocuments[tax_year][fileType],
                isViewed: true
            }
        }
    }

    return Object.assign({}, state, { approvalDocuments: files })     
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_COMPLETED_DOCUMENTS]: filesSetCompletedDocuments,
    [Types.SET_DOCUMENTS_FOR_REVIEW]: filesSetDocumentsForReview,
    [Types.SET_APPROVAL_DOCUMENTS]: filesSetApprovalDocuments,
    [Types.SET_FILE_VIEWED]: filesSetFileViewed,
    [Types.FILES_RESET]: filesResetState
})

