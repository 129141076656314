import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    savePOMSelection: ['caseId', 'productName', 'filerType', 'selection'],
    setAddOnProducts: ['addOnProductsList'],
    getAddOnProducts: ['caseId'],
    clearAddOnProducts: null,
    setSelectionSaved: ['isSaved']
})

export const ProductsTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    addOnProducts: [],
    isSaved: true
}

/* ------------- Reducers ------------- */
export const productSetAddOnProducts = (state, { addOnProductsList }) =>
    Object.assign({}, state, {
        addOnProducts: addOnProductsList
    })

export const productClearAddOnProducts = (state) => INITIAL_STATE

export const productSetSelectionSaved = (state, { isSaved }) => 
    Object.assign({}, state, { isSaved: isSaved })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_ADD_ON_PRODUCTS]: productSetAddOnProducts,
    [Types.CLEAR_ADD_ON_PRODUCTS]: productClearAddOnProducts,
    [Types.SET_SELECTION_SAVED]: productSetSelectionSaved
})