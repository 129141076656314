import apisauce from 'apisauce'
import { addErrorHandler, getApiBaseUrl } from './serviceUtils'

/**
 * API for managing workflow information
 * @param {String} baseURL
 */
const create = (baseURL = getApiBaseUrl()) => {  // this will change for prod deployment
    // Create and configure an apisauce-based api object.
    const api = apisauce.create({
        baseURL,
        headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Content-Type': 'application/json' },
        timeout: 120000   // TODO!
    })

    addErrorHandler(api);

    // Wrap api's addMonitor to allow the calling code to attach additional monitors in the future.
    // Only in __DEV__ and only if we've attached Reactotron to console (it isn't during unit tests).
    //if (__DEV__ && console.tron) {
    //  api.addMonitor(console.tron.apisauce)
    //}

    const getAuthHeader = (accessToken) => {
        return {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        }
    }

    const getWorkflowState = (accessToken) => {
        var payload = ''
        return api.get('casestate/workflowstate', payload, getAuthHeader(accessToken))
    }

    const setWorkflowState = (accessToken, workflowState) => {
        var payload = workflowState
        return api.post('casestate/workflowstate', payload, getAuthHeader(accessToken))
    }

    const getCaseState = (accessToken) => {
        var payload = ''
        return api.get('casestate/query', payload, getAuthHeader(accessToken))
    }

    const getCaseSettings = (accessToken, taxYear) => {
        var payload = {
            taxYear
        }
        return api.get('casestate/settings', payload, getAuthHeader(accessToken))
    }

    const setCaseSettings = (accessToken, taxYear, settingName, settingValue) => {
        var payload = {
            taxYear,
            settingName,
            settingValue
        }
        return api.post('casestate/settings', payload, getAuthHeader(accessToken))
    }

    const getWorkflowState2 = (accessToken, synchronize) => {
        var endpoint = 'casestate' + (synchronize ? '?synchronize=true' : '');
        return api.get(endpoint, '', getAuthHeader(accessToken))
    }

    const createNewCases = (accessToken, action, assignment) => {
        var payload = {
            taxYears: action.taxYears,
            directCode: localStorage.getItem('va_direct_code'),
            associateId: (assignment || {}).associateId,
            officeId: (assignment || {}).officeId
        }
        return api.post('case', payload, getAuthHeader(accessToken))
    }

    const deleteCase = (accessToken, taxYear) => {
        return api.delete('case/' + taxYear, '', getAuthHeader(accessToken))
    }

    const setLastReviewedPage = (accessToken, action) => {
        var payload = {
            caseId: action.caseId,
            pageNumber: action.pageNumber
        }

        return api.post('casestate/lastreviewed', payload, getAuthHeader(accessToken))
    }

    const notifyOnScheduleCallAttempt = (accessToken, taxYear) => {
        var payload = {
            taxYear: taxYear
        }

        return api.post('casestate/notifyonschedulecall', payload, getAuthHeader(accessToken));
    }

    const createDirectCode = (email, isNew) => {
        var payload = {
            email: email,
            isNew: !!isNew
        }

        return api.post('direct', payload);
    }

    const getDirectCodeConfig = () => {
        return api.get('direct')
    }

    // Return back a collection of functions
    return {
        getWorkflowState, setWorkflowState, getCaseState, getCaseSettings, setCaseSettings,
        getWorkflowState2, createNewCases, deleteCase, setLastReviewedPage,
        notifyOnScheduleCallAttempt, createDirectCode, getDirectCodeConfig
    }
}

// let's return back our create method as the default.
export default {
    create
}

