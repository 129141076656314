import React from 'react';
import { PropTypes } from 'prop-types'
import './sidebar.css'

const CircleIndicator = ({ indicatorValue }) => (
    <div className='messagesCircle'>
        <span>{indicatorValue}</span>
    </div>
);

CircleIndicator.propTypes = {    
    indicatorValue: PropTypes.number.isRequired
}

export default CircleIndicator;