import React, { Component } from 'react';
import T from 'i18n-react';

class HeaderLogo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lang: this.props.lang,
            darkText: this.props.darkText,
            width: this.props.width,
            height: this.props.height
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }
        if (this.state.darkText !== newProps.darkText) {
            this.setState({ darkText: newProps.darkText })
        }
        if (this.state.width !== newProps.width) {
            this.setState({ width: newProps.width })
        }
        if (this.state.height !== newProps.height) {
            this.setState({ height: newProps.height })
        }
    }

    handleClick(ev) {
        if (this.props.onClick) {
            this.props.onClick(ev)
        }
    }


    render() {
        var logoContent = null
        var textColor = this.state.darkText ? '##3F3F3F' : '#FFF'

        logoContent = (
            <svg style={{ cursor: 'pointer' }} width={this.state.width} height={this.state.height}
                alt={T.translate('Home.hrbLogoAltText')}
                onClick={(ev) => this.handleClick(ev)}
                viewBox="20 10 611 114" >
                <title>H&R Block Logo</title>
                <rect x="29.3" y="23.5" fill="#14AA40" width="107.2" height="107.2" />
                <path fill={textColor} d="M245.1,92.8c-3.7,0-8.8-2.1-8.8-7.1c0-2.9,1.9-5.1,3.7-6.3c0,0,8.8,9.9,10.7,11.9C249.8,92,247.5,92.8,245.1,92.8 M265.9,91.4c1.9-3.3,2.1-7.3,2.1-10.4v-8.4h-12v7.9l-12.6-14c-1.2-1.5-2-2.7-2-4.4c0-2.1,1.5-3.5,3.8-3.5c2.5,0,3.8,1.5,3.8,3.7v2.6h12.9v-3.4c0-8.6-7.1-13.8-17-13.8c-10.7,0-17.6,5.6-17.6,14.1c0,2.4,0.8,6.1,4.4,9.4c-4.8,2.2-9.8,7.1-9.8,14.8c0,10.7,8.4,17.9,23.3,17.9c5.1,0,9.9-1.4,13.3-3.7l2.4,2.6h15.1L265.9,91.4z"/>
                <path fill={textColor} d="M305.3,75.3c4,0,6.2-2.7,6.2-6.4c0-4-2.1-6.5-6.2-6.5h-8.2v13H305.3z M302,88.6h-4.9v14.2h-16.9V48.8h27c13.8,0,21.2,8.2,21.2,20c0,7.7-3.5,13.7-9.3,17l10.7,17.1h-19.7L302,88.6z"/>
                <polygon fill={textColor} points="166.6,48.8 183.5,48.8 183.5,68 197.4,68 197.4,48.8 214.3,48.8 214.3,102.8 197.4,102.8 197.4,83.4 183.5,83.4 183.5,102.8 166.6,102.8"/>
                <path fill={textColor} d="M383.8,90.4c2.7,0,4.2-2,4.2-4.6c0-2.4-1.7-4.5-4.3-4.5h-11.2v9.1H383.8z M380.5,69.9c2.7,0,4.1-1.9,4.1-4.5c0-2.3-1.6-4.2-4.1-4.2h-7.8v8.7H380.5z M356.5,48.8h28c10.1,0,16.1,5.9,16.1,14c0,5.5-2.6,10-7.1,12.1c6.5,1.5,10.4,5.9,10.4,12.6c0,9.1-6.6,15.4-19,15.4h-28.4V48.8z"/>
                <polygon fill={textColor} points="410.9,48.8 427.8,48.8 427.8,88.8 449.8,88.8 449.8,102.8 410.9,102.8"/>
                <path fill={textColor} d="M488.9,80.2v-8.8c0-6.3-3.2-9.7-8.9-9.7c-5.7,0-8.9,3.4-8.9,9.7v8.8c0,6.2,3.2,9.7,8.9,9.7C485.7,89.9,488.9,86.5,488.9,80.2 M454.2,79.2v-6.8c0-14.7,8.9-24.7,25.8-24.7c16.9,0,25.8,10.1,25.8,24.7v6.8c0,14.7-8.9,24.7-25.8,24.7C463.1,103.9,454.2,93.9,454.2,79.2"/>
                <path fill={textColor} d="M537.1,89.9c5.9,0,8.1-4.1,8.4-8h16c-0.4,12.2-8.9,22.1-24.1,22.1c-17.5,0-25.8-9.9-25.8-24.6v-7.1c0-14.7,8.3-24.6,25.8-24.6c15.2,0,23.7,9.9,24.1,22.1h-16c-0.3-4-2.5-8-8.4-8c-5.8,0-8.6,3.8-8.6,9.7v8.6C528.6,86.1,531.3,89.9,537.1,89.9"/>
                <polygon fill={textColor} points="570.8,48.8 587.7,48.8 587.7,67.6 588.8,67.6 599.7,48.8 618.7,48.8 604.5,73.1 620.3,102.8 600.2,102.8 589,80.3 587.7,80.3 587.7,102.8 570.8,102.8"/>
                <path fill={textColor} d="M626.5,51.8c0.3,0,0.5-0.2,0.5-0.4c0-0.2-0.2-0.4-0.5-0.4h-0.4v0.8H626.5z M626.4,52.7h-0.3v1H625V50h1.7c1,0,1.5,0.5,1.5,1.4c0,0.5-0.2,0.9-0.6,1.1l0.7,1.3H627L626.4,52.7z M630,52c0-2-1.3-3.5-3.5-3.5c-2.2,0-3.5,1.5-3.5,3.5c0,2,1.3,3.5,3.5,3.5C628.7,55.6,630,54,630,52 M622,52c0-2.4,1.7-4.4,4.5-4.4c2.8,0,4.5,2,4.5,4.4c0,2.4-1.7,4.3-4.5,4.3C623.7,56.4,622,54.4,622,52"/>
            </svg>
        )
        return (
            <div width={this.state.width} height={this.state.height}>
                {logoContent}
            </div>
        )
    }
}


export default HeaderLogo;