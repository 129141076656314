import React, { Component } from 'react';
import '../../Controls/progressModal.css';
import '../style.css'
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';
import PlainButton from '../../Controls/plainButton'
import { trackEvent } from '../../../utils/AnalyticsUtil'

class ConfirmingContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: this.props.lang,
            layoutType: this.props.layoutType,
            stage: this.props.stage,
            onDialogClose: this.props.onDialogClose
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.lang !== this.state.lang) {
            this.setState({ lang: newProps.lang })
        }

        if (newProps.layoutType !== this.state.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }

        if (newProps.stage !== this.state.stage) {
            this.setState({ stage: newProps.stage })
        }

        if (newProps.onDialogClose !== this.state.onDialogClose) {
            this.setState({ onDialogClose: newProps.onDialogClose })
        }
    }

    handleContinue(ev) {
        if (this.state.onDialogClose) {
            this.state.onDialogClose(ev);
        }
    }

    handleContinueIdentityConfirm(ev) {
        trackEvent('OteIdentityConfirmComplete')
        if (this.state.onDialogClose) {
            this.state.onDialogClose(ev);
        }
    }

    render() {
        var titleKey = null
        var faContent = null
        var bodyContent = []
        var containerClass = ["confirmRootContainer confirmModalWidth"]

        if (this.state.stage === 'confirming' || this.state.stage === 'confirming_docs') {
            var suffix = this.state.stage === 'confirming_docs' ? 'Docs' : ''
            titleKey = 'ConfirmIdentity.Confirming.Title' + suffix
            faContent = (<FontAwesome className='fa-spinner' name='spinner' pulse size='4x' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#14AA40', align: 'center' }} />)
            bodyContent.push(<span key='content1' className="confirmModalContainerSpan">{T.translate('ConfirmIdentity.Confirming.Content1' + suffix)}</span>)
            bodyContent.push(
                <span key='content2' className="confirmModalContainerSpan">
                    <b>{T.translate('ConfirmIdentity.Confirming.Content2')}</b>
                    <span>{T.translate('ConfirmIdentity.Confirming.Content2b')}</span>
                </span>
            )
            bodyContent.push(<div key='btmMargin' className='bottomMargin' />)
        } else if (this.state.stage === 'confirmed' || this.state.stage === 'confirmed_docs') {
            titleKey = 'ConfirmIdentity.Confirmed.Title'
            faContent = (<FontAwesome className='fa-check' name='check' size='4x' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#14AA40', align: 'center' }} />)
            bodyContent.push(<div key='spac' style={{ marginBottom: '40px' }} />)
            bodyContent.push(
                <div key='okbutton' className="confirmModalContainer">
                    <PlainButton labelKey="Common.Okay" onClick={(ev) => this.handleContinueIdentityConfirm(ev)} />
                </div>
            )
        } else if (this.state.stage === 'error' || this.state.stage === 'error_docs') {
            containerClass.push("error")
            titleKey = 'ConfirmIdentity.Error.Title'
            faContent = (<FontAwesome className='fa-exclamation' name='exclamation' size='4x' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#E70000', align: 'center' }} />)
            bodyContent.push(<span key='content2' className="confirmModalContainerSpan">{T.translate('ConfirmIdentity.Error.Content2')}</span>)
            bodyContent.push(<span key='content1' className="confirmModalContainerSpan" style={{marginTop: '0px'}}>{T.translate('ConfirmIdentity.Error.Content1')}</span>)
            bodyContent.push(
                <div key='okbutton' className="confirmModalContainer">
                    <PlainButton labelKey="Common.Okay" isError onClick={(ev) => this.handleContinue(ev)} />
                </div>
            )
        }

        return (
            <div className={containerClass.join(' ')}>
                <div className="confirmProgressSpinnerContainer">
                    {faContent}
                </div>
                <h2>{T.translate(titleKey)}</h2>
                {bodyContent}
            </div>
        );
    }
}

export default ConfirmingContent