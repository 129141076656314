/* eslint-disable-next-line */
import React, { useState, useEffect, useRef } from 'react';
import './style.css';
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';
import MessageEntry from './messageEntry';
import { useSelector } from 'react-redux';
import MessagesUnavailable from './messagesUnavailable';

const MessagesView = (props) => {
    const [dashboardTab, setDashboardTab] = useState(props.dashboardTab)
    const [unreadHeaderContent, setUnreadHeaderContent] = useState(null)
    const [messageContent, setMessageContent] = useState([])
    const [footerButtonStyle, setFooterButtonStyle] = useState('')
    const [hasPreviousBlockMessages, setHasPreviousBlockMessages] = useState(false)
    const [unreadMessagesCount] = useState(props.unreadMessagesCount)
    const [messagesLoaded] = useState(props.messagesLoaded);

    const enabled = useSelector(state => {
        if (state.workflow && state.workflow.case_state && state.workflow.case_state.taxYears) {
            if (state.workflow.case_state.taxYears.some(ty => ty.assigned_tax_pro)) {
                return true
            }
        }
        return false
    })

    useEffect(() => {
        props.updateLastReadMessages();
    }, [])

    useEffect(() => {
        if (dashboardTab !== props.dashboardTab) {
            setDashboardTab(props.dashboardTab)
        }
    }, [props.dashboardTab, dashboardTab])


    const showMessageInputScreen = () => {
        if (enabled && props.showInputScreenHandler) {
            props.showInputScreenHandler(true)
        }
    }

    const contentIsScrolling = () => {
        if (props.loaded) {
            var id = "messagesScrollParent"
            var div = document.getElementById(id);
            if (div) {
                return div.scrollHeight > div.clientHeight;
            }
        }
        return false
    }

    const AlwaysScrollToBottom = () => {
        const delayRef = useRef()
        const elementRef = useRef();
        useEffect(() => {
            if(messagesLoaded) {
                delayRef.current = setTimeout(() => {
                    if(elementRef.current) {
                        elementRef.current.scrollIntoView({ block: "end", behavior: "smooth" })
                    }
                }, 1000)
            }
            return () => {
                clearTimeout(elementRef.current)
            }
        });
        return <div ref={elementRef} />;
    };

    useEffect(() => {
        // let's calculate the unread messages count based on current data
        if (unreadMessagesCount === 1) {
            setUnreadHeaderContent(<T.span text={{ key: 'Messages.Labels.HeaderUnread', UnreadMessagesCount: unreadMessagesCount }} />)
        } else if (unreadMessagesCount > 1) {
            setUnreadHeaderContent(<T.span text={{ key: 'Messages.Labels.HeaderUnreadPlural', UnreadMessagesCount: unreadMessagesCount }} />)
        }

        var hbm = false
        var mc = [];
        for (var i = 0; i < props.messages.length; ++i) {
            var message = props.messages[i]
            if (message.isBlockComment) {
                hbm = true
            }
            mc.push(
                <MessageEntry key={'message_' + i} messageText={message.commentText} lang={props.lang}
                    isNew={message.isBlockComment && (message.isNew)}
                    isBlockMessage={message.isBlockComment} labelText={props.initials}
                    timestamp={message.createdDate} />)
        }
        setMessageContent(mc)
        if (hasPreviousBlockMessages !== hbm) {
            setHasPreviousBlockMessages(hbm)
        }
    }, [props.messages, props.unreadMessagesCount, props.lang])

    useEffect(() => {
        var fbs = "messagesFooterButton"
        if (props.layoutType !== 'mobile') {
            fbs += contentIsScrolling() ? " scrollSizing" : " normalSizing"
        } else {
            fbs += " mobileSizing"
        }
        if (!enabled) {
            fbs += ' fbs-disable'
        }
        if (fbs !== footerButtonStyle) {
            setFooterButtonStyle(fbs)
        }
    }, [props.layoutType, enabled])

    return (
        <div className={["messageViewMain", props.visible ? "showing" : "hiding"].join(' ')}>
            <div className={["messageViewHeader"].join(' ')}>
                <div className={["messageViewHeaderInner", "messagesLeftMargin"].join(' ')}>
                    <span className="messagesDefaultHeaderStyle">{T.translate("Messages.Labels.Header")}</span>
                    {unreadHeaderContent}
                </div>
            </div>
            {!enabled &&
                <MessagesUnavailable hasPrevious={hasPreviousBlockMessages}
                    showDivider={(props.messages || []).length > 0} layoutType={props.layoutType} />
            }
            <div className="messageViewContent" id="messagesScrollParent">
                <div className="messageViewScrollContent">
                    {messageContent}
                    <AlwaysScrollToBottom />
                </div>

            </div>
            <div className="messageViewFooter">
                <div className={footerButtonStyle} onClick={showMessageInputScreen}>
                    <div className="messageFooterButtonContent">
                        <FontAwesome className='fa-pencil' name='pencil' size='2x' />
                    </div>
                    <span className="unselectableLabel">{T.translate('Messages.Labels.MessageTaxPro.direct')}</span>
                </div>
            </div>
        </div>
    );
}


export default MessagesView;
