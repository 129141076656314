import { Component } from 'react';
import { connect } from 'react-redux'
import WorkflowActions from '../../../reducers/workflowRedux'

class DashboardTimers extends Component {
    constructor(props) {
        super(props)

        this.updateCaseState = this.queryCaseState.bind(this)

        this.state = {
            loginComplete: this.props.loginComplete
        }

        this.caseStateInterval = null
    }

    componentWillReceiveProps(newProps) {
        if (this.state.loginComplete !== newProps.loginComplete) {
            if (newProps.loginComplete) {
                this.startCaseUpdateInterval(true)
            } else {
                this.clearCaseStateInterval()
            }
            this.setState({ loginComplete: newProps.loginComplete })
        }
    }

    componentDidMount() {
        this.startCaseUpdateInterval(this.state.loginComplete)
    }

    startCaseUpdateInterval(loginComplete) {
        this.clearCaseStateInterval();
        if (loginComplete) {
            this.caseStateInterval = setInterval(this.updateCaseState, process.env.REACT_APP_DASHBOARD_TIMER_MINUTES * 60 * 1000)
        }
    }

    clearCaseStateInterval() {
        if (this.caseStateInterval) {
            clearInterval(this.caseStateInterval)
            this.caseStateInterval = null
        }
    }

    componentWillUnmount() {
        this.clearCaseStateInterval()
    }

    queryCaseState() {
        this.props.updateCaseState()
    }
    
    render() {
        return null
    }
}


const mapStateToProps = (state) => {
    return {
        loginComplete: state.auth.loginComplete
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateCaseState: () => dispatch(WorkflowActions.updateCaseState())
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTimers)
