/* eslint-disable-next-line */
import { useEffect, useRef, useState } from 'react'

export const useTimer = (callback, seconds, startImmediately = true) => {

    const intervalRef = useRef();
    const [isRunning, setIsRunning] = useState(false)

    useEffect(() => {
        if (startImmediately) start()
        return () => stop()
    }, [])

    const start = () => {
        stop()
        setIsRunning(true)
        intervalRef.current = setInterval(callback, seconds * 1000)
    }

    const stop = () => {
        if (intervalRef.current) {
            setIsRunning(false)
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    }

    
    return [start, stop, isRunning];
}