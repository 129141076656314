import { push } from 'connected-react-router';
import T from 'i18n-react';
import { PropTypes } from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import OuterContainer from '../Controls/outerContainer';
import PlainButton from '../Controls/plainButton';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import './errorPage.css';
import classnames from 'classnames';

const ErrorPage = ({ titleKey, subtitleKey, tryAgainCallback, goBackCallback, shouldShowTryAgain, shouldShowgoBack = true, layoutType, loaded }) => {
    return (
        <div className={classnames('body', layoutType)}>
            <OuterContainer sizeToContent={false} centerContent={true} loaded={loaded}>
                <div className='errorContainer'>
                    <PriorityHighIcon style={{ color: "#B54040", fontSize: 80, marginBottom: 60 }} />
                    <h1>{T.translate(titleKey ?? "error-general.title")}</h1>
                    <p>{T.translate(subtitleKey ?? "error-general.subtitle")}</p>
                    <div className='error-back-btn-container'>
                        { shouldShowgoBack && 
                            <PlainButton labelKey='error-general.back' onClick={(ev) => goBackCallback(ev)} inverted={shouldShowTryAgain} />
                        }
                        {shouldShowTryAgain &&                                    
                            <Fragment>
                                <div style={{ width: '8px', height: '8px' }} />
                                <PlainButton labelKey='error-general.tryAgain' onClick={(ev) => tryAgainCallback(ev)} />
                            </Fragment>
                        }
                    </div>
                </div>
            </OuterContainer>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        lang: state.layout.lang,
        layoutType: state.layout.layoutType
    }
}

const mapDispatchToProps = (dispatch) => ({
    pushNavigation: (path) => dispatch(push(path)),
})

ErrorPage.propTypes = {
    titleKey: PropTypes.string,
    subtitleKey: PropTypes.string,
    tryAgainCallback: PropTypes.func,
    goBackCallback: PropTypes.func.isRequired,
    shouldShowTryAgain: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
