import { put, call, select } from 'redux-saga/effects'
import { replace } from 'connected-react-router'

import ApproveDraftsActions from '../reducers/approveDraftsRedux'
import WorkflowActions from '../reducers/workflowRedux'
import LayoutActions from '../reducers/layoutRedux'
import MessagesActions from '../reducers/messagesRedux'
import { trackEvent, HeapEvents } from '../utils/AnalyticsUtil'

const getAccessToken = state => state.auth.accessToken


export function* resetApprovalState(action) {
    if (action.payload.location.pathname === '/dashboard') {
        yield put(ApproveDraftsActions.approveReset())
    }
}

export function* setApprovalComplete(api, workflowApi, action) {
    var accessToken = yield select(getAccessToken)

    var response = yield call(api.setApprovalComplete, accessToken, action.taxYear)
    if (response && response.ok) {
        trackEvent(HeapEvents.APPROVAL_TICKET, { taxYear: action.taxYear })

        var caseState = yield call(workflowApi.getWorkflowState2, accessToken)
        if (caseState && caseState.ok) {
            yield put(WorkflowActions.storeCaseState(caseState.data))
            yield put(MessagesActions.setUnreadMessagesCount(caseState.data.unreadMessagesCount))
        }

        yield put(LayoutActions.setDashboardTab('dashboard'))
        yield put(replace('/dashboard'))
    }
}