import { useState } from 'react';
import api from '../../../../services/Api'
import { useSelector } from 'react-redux'
import { fileTypeWillOpen } from '../../Shared/resources/DoxFileTypes';
import { DoxFileDownloadStatus } from '../services/doxExplorerConsts';
import { useUnauthorizedContext } from '../../Shared/hooks/useUnauthorizedContextHook';
import { ApiPaths } from '../../Shared/services/ApiPaths';
import fileDownloadUtil from '../services/FileDownloadUtil';
import { browserShouldOpenInTab } from '../../Shared/services/Util';

export const useFileDownload = () => {
    const [loadingFiles, setLoadingFiles] = useState([]);
    const handleUnauthorized = useUnauthorizedContext()
    const accessToken = useSelector(state => state.auth.accessToken)

    const handleFileAction = async (action, documentId, fileNamePart, fileType, callback = () => { }) => {
        if (loadingFiles.includes(documentId)) {
            callback(documentId, DoxFileDownloadStatus.IN_PROGRESS)
            return
        }

        let newTab = false
        setLoadingFiles([...loadingFiles, documentId])

        try {
            var response = await new api([], handleUnauthorized).get(accessToken, `${ApiPaths.CDM}/${documentId}`)
            if (response && response.status === 200 && response.data) {
                // get the filename
                let fileName = fileNamePart + fileType

                // parse binary response data into fancy blob
                let fileLink = fileDownloadUtil.makeBlobLink(response.data.fileContents, fileType);

                // do something with it
                if (action === 'print') {
                    if (navigator.userAgent.toLowerCase().indexOf('firefox') === -1) {
                        // non-firefox can directly print
                        printFile(fileLink, newTab);
                    } else {
                        // otherwise, just show in new tab
                        fileDownloadUtil.downloadFile(fileLink, fileName, true)
                    }
                } else {
                    let fileOpens = fileTypeWillOpen(fileType);
                    let openInTab = browserShouldOpenInTab();
                    fileDownloadUtil.downloadFile(fileLink, fileName, action === 'view' && fileOpens && openInTab)
                }

                callback(documentId, DoxFileDownloadStatus.COMPLETE)
            } else if (response && response.status === 404) {
                callback(documentId, DoxFileDownloadStatus.FAIL_NO_SUCH_FILE)
            } else {
                callback(documentId, DoxFileDownloadStatus.FAIL_TRANSIENT)
            }
        } catch (error) {
            console.log(error)
            callback(documentId, DoxFileDownloadStatus.FAIL_TRANSIENT)
        } finally {
            setLoadingFiles(loadingFiles.filter(x => x.documentId !== documentId))
        }
    }  

    const printFile = (pdfLink, newTab) => {
        if (newTab) {
            let myWindow = window.open();
            myWindow.location.href = pdfLink
        }
        else {
            let iframe = window.frames['file-print']

            // javascript IFRAME magic! (ew.)
            if (!iframe) {
                iframe = document.createElement('iframe')
                iframe.id = 'file-print'
                iframe.style.visibility = 'hidden'
                iframe.onload = function () {
                    setTimeout(function () {
                        iframe.focus();
                        iframe.contentWindow.print();
                    }, 1);
                };
                document.body.appendChild(iframe)
            }

            iframe.src = pdfLink;
        }
    }


    return [loadingFiles, handleFileAction]
}