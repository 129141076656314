export const trackEvent = (eventName, metaData) => {
    try {
        if (!metaData){
            window.heap.track(eventName)
        }
        else {
            window.heap.track(eventName, metaData)
        }
    }
    catch (e) {
        console.log('Failed to send event to Analytics API')
        console.log(e)
    }
}

export const identifyUser = (identifier) => {
    try {
        if (localStorage.getItem('va_heap_app_id') && window.heap && identifier) {
            window.heap.identify(identifier, 'uuid')
        }
    } catch (e) {
        console.log('Failed to identify user for Analytics API')
        console.log(e)
    }
}

export const HeapEvents = {
    ACCOUNT_CREATE_MIGRATE: 'AccountCreated-migrated',
    ACCOUNT_CREATE_DIRECT: 'AccountCreated-direct',
    ACCOUNT_CREATE: 'AccountCreated',
    TICKET_CREATE: 'TicketCreated',
    AUTHORIZE_PRIMARY_CRA_MYACCOUNT: 'AuthorizedCRA-Primary',
    AUTHORIZE_PRIMARY_RQ_MYACCOUNT: 'AuthorizedRQ-Primary',
    AUTHORIZE_PRIMARY_CRA_EAF: 'AuthorizedEAF-Primary',
    AUTHORIZE_PRIMARY_RQ_MR69: 'AuthorizedMR69-Primary',
    AUTHORIZE_SPOUSE_CRA_MYACCOUNT: 'AuthorizedCRA-Spouse',
    AUTHORIZE_SPOUSE_RQ_MYACCOUNT: 'AuthorizedRQ-Spouse',
    AUTHORIZE_SPOUSE_CRA_EAF: 'AuthorizedEAF-Spouse',
    AUTHORIZE_SPOUSE_RQ_MR69: 'AuthorizedMR69-Spouse',
    CONFIRM_PRIMARY_BASIC: 'ConfirmIdentityBasic-Primary',
    CONFIRM_SPOUSE_BASIC: 'ConfirmIdentityBasic-Spouse',
    CONFIRM_PRIMARY_SELFIE: 'ConfirmIdentitySelfie-Primary',
    CONFIRM_SPOUSE_SELFIE: 'ConfirmIdentitySelfie-Spouse',
    CONFIRM_PRIMARY_FAILED: 'ConfirmIdentityFailed-Primary',
    CONFIRM_SPOUSE_FAILED: 'ConfirmIdentityFailed-Spouse',
    APPROVAL_TICKET: 'ApproveTicket',
    DELETE_TP_FILE_FAILED: 'RTE_deleteTPfile_error',
    RENAME_TP_FILE_FAILED: 'RTE_renameTPfile_error',
    REVIEW_SEND_MESSAGE: 'RTE_ReviewWorkflow_SendAMessage',
    REVIEW_SIGN_AND_AUTHORIZE: 'RTE_ReviewWorkflow_SignAndAuthorize',
    PRIMARY_PAYMENT_LINK: 'Primary-Payment-Link',
    BUTTON_CREATE_ACCOUNT: 'btn-create-account',
    BUTTON_POM_LEARN: 'btn-pom-learn',
    BUTTON_POM_ASK_EXPERT: 'btn-pom-ask-expert',
    BUTTON_POM_ADD_ON: 'btn-pom-add-on',
    BUTTON_POM_DECLINE: 'btn-pom-decline',
    BUTTON_START_NEW_TAXYEAR: 'btn-start-new-taxyear',
    CLIENT_ID_FIRST_CHECK_VIEWPAGE: 'viewpg_clientid_1st-check',
    BUTTON_CONFIRMID_CONTINUE: 'btn-clientid-1st-check',
    CLIENTID_FAIL: 'ClientID_Fail',
    CLIENT_ID_QR_CODE: 'Client_ID_QRcode',
    CLIENT_ID_SECOND_CHECK: 'client_id_2nd-check',
    BTN_UTP_CONTINUE: 'btn-utp-continue',
    VIEW_PAGE_UTP: 'viewpg-utp',
    TAX_SCENARIO_EMPLOYMENT_INCOME: 'tax-scenario-btn-Employment-income',
    TAX_SCENARIO_SELF_EMPLOYED: 'tax-scenario-btn-Self-employed',
    TAX_SCENARIO_RETIREMENT: 'tax-scenario-btn-Retirement',
    TAX_SCENARIO_BUSINESS_OWNER: 'tax-scenario-btn-Business-owner',
    TAX_SCENARIO_WORK_EXPENSES: 'tax-scenario-btn-Work-Expenses',
    TAX_SCENARIO_DISABILITY: 'tax-scenario-btn-Disability',
    TAX_SCENARIO_MEDICAL: 'tax-scenario-btn-Medical',
    TAX_SCENARIO_BENEFITS_EI: 'tax-scenario-btn-Benefits/EI',
    TAX_SCENARIO_INVESTMENTS: 'tax-scenario-btn-Investments',
    TAX_SCENARIO_RRSP: 'tax-scenario-btn-RRSP',
    TAX_SCENARIO_RENTAL_INCOME: 'tax-scenario-btn-Rental-Income',
    TAX_SCENARIO_FOREIGN: 'tax-scenario-btn-Foreign',
    TAX_SCENARIO_DEPENDANTS: 'tax-scenario-btn-Dependants',
    TAX_SCENARIO_PROPERTY_SALE: 'tax-scenario-btn-Property-Sale',
    TAX_SCENARIO_SPOUSE_SUPPORT: 'tax-scenario-btn-Spouse-support',
    TAX_SCENARIO_MOVING: 'tax-scenario-btn-Moving',
    TAX_SCENARIO_TUITION: 'tax-scenario-btn-Tuition',
    TAX_SCENARIO_DONATION: 'tax-scenario-btn-Donations',
    TAX_SCENARIO_FIRE_RESCUE: 'tax-scenario-btn-Fire-and-Rescue',
    TAX_SCENARIO_FARMING: 'tax-scenario-btn-Farming',
    TAX_SCENARIO_EMPLOYMENT_INCOME_SPOUSE: 'tax-scenario-btn-Employment-income-spouse',
    TAX_SCENARIO_SELF_EMPLOYED_SPOUSE: 'tax-scenario-btn-Self-employed-spouse',
    TAX_SCENARIO_RETIREMENT_SPOUSE: 'tax-scenario-btn-Retirement-spouse',
    TAX_SCENARIO_BUSINESS_OWNER_SPOUSE: 'tax-scenario-btn-Business-owner-spouse',
    TAX_SCENARIO_WORK_EXPENSES_SPOUSE: 'tax-scenario-btn-Work-Expenses-spouse',
    TAX_SCENARIO_DISABILITY_SPOUSE: 'tax-scenario-btn-Disability-spouse',
    TAX_SCENARIO_MEDICAL_SPOUSE: 'tax-scenario-btn-Medical-spouse',
    TAX_SCENARIO_BENEFITS_EI_SPOUSE: 'tax-scenario-btn-Benefits/EI-spouse',
    TAX_SCENARIO_INVESTMENTS_SPOUSE: 'tax-scenario-btn-Investments-spouse',
    TAX_SCENARIO_RRSP_SPOUSE: 'tax-scenario-btn-RRSP-spouse',
    TAX_SCENARIO_RENTAL_INCOME_SPOUSE: 'tax-scenario-btn-Rental-Income-spouse',
    TAX_SCENARIO_FOREIGN_SPOUSE: 'tax-scenario-btn-Foreign-spouse',
    TAX_SCENARIO_DEPENDANTS_SPOUSE: 'tax-scenario-btn-Dependants-spouse',
    TAX_SCENARIO_PROPERTY_SALE_SPOUSE: 'tax-scenario-btn-Property-Sale-spouse',
    TAX_SCENARIO_SPOUSE_SUPPORT_SPOUSE: 'tax-scenario-btn-Spouse-support-spouse',
    TAX_SCENARIO_MOVING_SPOUSE: 'tax-scenario-btn-Moving-spouse',
    TAX_SCENARIO_TUITION_SPOUSE: 'tax-scenario-btn-Tuition-spouse',
    TAX_SCENARIO_DONATION_SPOUSE: 'tax-scenario-btn-Donations-spouse',
    TAX_SCENARIO_FIRE_RESCUE_SPOUSE: 'tax-scenario-btn-Fire-and-Rescue-spouse',
    TAX_SCENARIO_FARMING_SPOUSE: 'tax-scenario-btn-Farming-spouse',
    DASHPG_ADDDOCS: 'DashPg-AddDocs',
    DASHPG_ALERTEXPERT: 'DashPg-AlertExpert',
    DASHPG_VIEWADDDOCS: 'DashPg-ViewAddDocs',
    DASHPG_STARTRETURN: 'DashPg-StartReturn',
    DASHPG_DOWNLOAD_RETURN: 'DashPg-Download-Return',
    DOC_SUGGESTION_T4 : 'doc-suggestion-T4',
    DOC_SUGGESTION_T4AndRL : 'doc-suggestion_T4AndRL',
    DOC_SUGGESTION_SelfEmployedIncome : 'doc-suggestion-SelfEmployedIncome',
    DOC_SUGGESTION_Retirement : 'doc-suggestion-Retirement',
    DOC_SUGGESTION_BusinessIncome : 'doc-suggestion-BusinessIncome',
    DOC_SUGGESTION_WorkExpense : 'doc-suggestion-WorkExpense',
    DOC_SUGGESTION_Disability : 'doc-suggestion-Disability',
    DOC_SUGGESTION_MedicalExpense : 'doc-suggestion-MedicalExpense',
    DOC_SUGGESTION_Benefits : 'doc-suggestion-Benefits',
    DOC_SUGGESTION_Investment : 'doc-suggestion-Investment',
    DOC_SUGGESTION_Rrsp : 'doc-suggestion-Rrsp',
    DOC_SUGGESTION_RentalIncome : 'doc-suggestion-RentalIncome',
    DOC_SUGGESTION_ForeignIncome : 'doc-suggestion-ForeignIncome',
    DOC_SUGGESTION_Dependents : 'doc-suggestion-Dependents',
    DOC_SUGGESTION_PropertyBuySell : 'doc-suggestion-PropertyBuySell',
    DOC_SUGGESTION_SpouseSupport : 'doc-suggestion-SpouseSupport',
    DOC_SUGGESTION_Moving : 'doc-suggestion-Moving',
    DOC_SUGGESTION_Tuition : 'doc-suggestion-Tuition',
    DOC_SUGGESTION_Donations : 'doc-suggestion-Donations',
    DOC_SUGGESTION_FireAndRescue : 'doc-suggestion-FireAndRescue',
    DOC_SUGGESTION_FarmAndFish : 'doc-suggestion-FarmAndFish',
    BTN_SEND_MESSAGE: 'btn-send-message',
    DOCS_BTN_ADD_LATER:'Your-Docs-btn-add-later',
    DOCS_BTN_READY:'Your-Docs-btn-ready',
    INTERVIEW_DONE: 'TY-Interview-Done-btn'
}