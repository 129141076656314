import React, { useState } from 'react'
import PropTypes from 'prop-types'
import '../dox_explorer.css'
import { FileExplorerFileTableConfig } from '../services/DoxExplorerTableConfig'
import DoxExplorerFilesTableHeader from './doxExplorerFilesTableHeader'
import { useFileSearch } from '../../Shared/hooks/useFileSearchHook'
import DoxExplorerFilesTableRow from './doxExplorerFilesTableRow'
import Skeleton from '../../../Controls/Skeleton/skeleton'
import DoxExplorerTableMessage from './doxExplorerTableMessage'
import DoxExplorerDeleteModal from './doxExplorerDeleteModal'
import DoxExplorerRenameModal from './doxExplorerRenameModal'
import { useFileDownload } from '../hooks/useFileDownloadHook'
import { DoxFileOperations, DoxFileOperationStatus } from '../services/doxExplorerConsts'
import DoxExplorerGenericErrorMessage from './doxExplorerGenericErrorMessage'
import { isNeededFile } from '../../Shared/resources/DoxFileTypes'
import { trackEvent, HeapEvents } from '../../../../utils/AnalyticsUtil'
import DoxNoFilesIcon from '../graphics/doxNoFilesIcon'

const DoxExplorerFilesTable = (props) => {
    const [operatingFile, setOperatingFile] = useState(null)
    const [isDeleting, setIsDeleting] = useState(false)
    const [operationError, setOperationError] = useState(null)
    const [isRenaming, setIsRenaming] = useState(false)
    const [downloadResults, setDownloadResults] = useState({})

    const [sortColumn, sortDirection, updateSort, status, fileList, loaded, deleteFile, renameFile]
        = useFileSearch(props.tax_year, props.sortOverride)

    const [loadingFiles, handleFileAction] = useFileDownload();
    const noFilesLabel = 'Dox.DocumentExplorer.NoFilesMessage.' + props.layoutType;

    const handleFileOperation = (operation, documentId) => {
        var file = (fileList || []).find(x => x.documentId === documentId);
        if (file) {
            if (operation === DoxFileOperations.DELETE || operation === DoxFileOperations.RENAME) {
                if (file.captureMethod.toLowerCase() === "signed" || file.captureMethod.toLowerCase() === "generated" || isNeededFile(file)) {
                    // first off, all the signed and needed files cannot be deleted or renamed
                    handleDownloadResult(documentId, operation === DoxFileOperations.DELETE
                        ? DoxFileOperationStatus.FAIL_DELETE_HRB_FILE
                        : DoxFileOperationStatus.FAIL_RENAME_HRB_FILE)
                    trackEvent(operation === DoxFileOperations.DELETE
                        ? HeapEvents.DELETE_TP_FILE_FAILED
                        : HeapEvents.RENAME_TP_FILE_FAILED)
                } else if (file.uploaderIsBlock) {
                    // secondly, a client cannot delete or rename files uploaded by HRB.
                    handleDownloadResult(documentId, operation === DoxFileOperations.DELETE
                        ? DoxFileOperationStatus.FAIL_DELETE_HRB_FILE
                        : DoxFileOperationStatus.FAIL_RENAME_HRB_FILE)
                    trackEvent(operation === DoxFileOperations.DELETE
                        ? HeapEvents.DELETE_TP_FILE_FAILED
                        : HeapEvents.RENAME_TP_FILE_FAILED)
                } else {
                    setOperatingFile(file)
                    if (operation === DoxFileOperations.DELETE) {
                        setIsDeleting(true);
                    } else {
                        setIsRenaming(true);
                    }
                }
            } else if (operation === DoxFileOperations.VIEW) {
                setDownloadResults(Object.assign({}, downloadResults, { [documentId]: null }))
                props.onShowNotification('Dox.DocumentExplorer.Preparing')
                handleFileAction(operation, documentId, file.fileName, file.fileType, handleDownloadResult)
            } else {
                alert(`handling ${operation} on ${documentId}`)
            }
        }
    }

    const handleDownloadResult = (documentId, statusCode) => {
        setDownloadResults(Object.assign({}, downloadResults, { [documentId]: statusCode }))
    }

    const handleCancelFileOperation = () => {
        setOperationError(null);
        setIsDeleting(false)
        setIsRenaming(false);
        setOperatingFile(null)
        if(props.loadExistingFiles) {props.loadExistingFiles()};
    }

    const handleDeleteFile = async (documentId) => {
        setOperationError(null)
        var response = await deleteFile(documentId);
        if (response) {
            handleCancelFileOperation()
        } else {
            setOperationError('fail')
        }
    }

    const handleRenameFile = async (documentId, newFileName) => {
        setOperationError(null)
        var response = await renameFile(documentId, newFileName);
        if (response) {
            handleCancelFileOperation()
        } else {
            setOperationError('fail')
        }
    }

    const handleUpdateSort = (sortColumn, sortDirection) => {
        updateSort(sortColumn, sortDirection)
        if (props.onSelectSort) {
            props.onSelectSort(sortColumn);
        }
    }

    return (
        <div id="pii-exclude" className='doxe-files-table'>
            <DoxExplorerFilesTableHeader tableConfig={FileExplorerFileTableConfig} sortColumn={sortColumn}
                sortDirection={sortDirection} onSort={handleUpdateSort} />
            {!loaded &&
                <div className='dox-skeleton'>
                    <Skeleton />
                </div>
            }
            {loaded && fileList.map(f =>
                <DoxExplorerFilesTableRow key={`doxe-fr-${f.documentId}`} file={f}
                    layoutType={props.layoutType} sortColumn={sortColumn} isDownloading={loadingFiles.includes(f.documentId)}
                    onFileOperation={handleFileOperation} sortDirection={sortDirection} errorStatus={downloadResults[f.documentId]} />
            )}
            {loaded && !fileList.length && status &&
                <div>
                    {props.isDoxCombined ?
                        <div className='dox-no-files'>
                            <DoxNoFilesIcon />
                            <DoxExplorerTableMessage labelKey={noFilesLabel} />
                        </div> : <DoxExplorerTableMessage labelKey='Dox.DocumentExplorer.NoDocs' />}
                </div>
            }
            {loaded && !fileList.length && !status &&
                <DoxExplorerGenericErrorMessage />
            }
            {isDeleting && operatingFile &&
                <DoxExplorerDeleteModal layoutType={props.layoutType} isOpen={isDeleting} file={operatingFile}
                    onCancel={handleCancelFileOperation} onDeleteFile={handleDeleteFile} deleteError={operationError} />
            }
            {isRenaming && operatingFile &&
                <DoxExplorerRenameModal layoutType={props.layoutType} isOpen={isRenaming} file={operatingFile}
                    onCancel={handleCancelFileOperation} onRenameFile={handleRenameFile} renameError={operationError}
                    fileList={fileList} />
            }
        </div>
    )
}

DoxExplorerFilesTable.propTypes = {
    layoutType: PropTypes.string.isRequired,
    tax_year: PropTypes.number,
    sortOverride: PropTypes.string,
    onSelectSort: PropTypes.func,
    onShowNotification: PropTypes.func.isRequired
}

export default DoxExplorerFilesTable;