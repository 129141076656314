import { buffers, eventChannel, END } from 'redux-saga';

export function createConfirmIdUploadChannel(api, accessToken, target, idType, isFront, imageData) {
    return eventChannel(emitter => {
        const onProgress = (progressEvent) => {
            if (progressEvent.lengthComputable) {
                const progress = (progressEvent.loaded / progressEvent.total) * 100;
                emitter({ progress })
            }
        }

        api.uploadFile(accessToken, target, idType, isFront, imageData, onProgress).then((response) => {
            if (response.ok) {
                emitter({ success: true })
                emitter(END)
            } else {
                emitter({ err: new Error('Upload Failed') })
                emitter(END)
            }
        })

        return () => {
        }

    }, buffers.sliding(2))
}