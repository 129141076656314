import React, { Fragment, useState, useEffect }  from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames'
import T from 'i18n-react';
import './sidebar.css'

const AssignedTaxProBlock = ({ layoutType, assigned_tax_pros, scheduleCallClick }) => {
    const [pros, setPros] = useState([])
    const [headerKey, setHeaderKey] = useState(null)

    useEffect(() => {
        var ps = []
        if (assigned_tax_pros) {
            ps = assigned_tax_pros.filter(tp => Boolean(tp.assigned_tax_pro))
        }

        if (ps.length > 1) {
            var proName = ps[0].assigned_tax_pro.name;
            var differentps = ps.some(tp => tp.assigned_tax_pro.name !== proName)
            if (!differentps) {
                ps = ps.slice(0, 1);
            }
        }

        if (ps.length === 0) {
            ps.push({ assigned_tax_pro: { name: T.translate('Dashboard.labels.Unassigned') } })
        }
        setPros(ps)
    }, [assigned_tax_pros])

    useEffect(() => {
        var hk = pros.length > 1 ? (layoutType === 'tablet' ? 'Dashboard.labels.YourTaxProsTablet' : 'Dashboard.labels.YourTaxPros')
            : (layoutType === 'tablet' ? 'Dashboard.labels.YourTaxProTablet' : 'Dashboard.labels.YourTaxPro');
        if (hk !== headerKey) {
            setHeaderKey(hk)
        }
    }, [pros, layoutType, headerKey, setHeaderKey])
    
    return (
        <div className={classNames('wf-wb-taxpro-container', { 'wf-wb-multi': pros.length > 1 })}>
            {layoutType === 'mobile' &&
                <div className='wf-wb-hr' />
            }
            <h3>{T.translate(headerKey)}</h3>
            {pros.map((x) => (
                <Fragment key={'pro_' + x.taxYear}>
                    {pros.length > 1 &&
                        <span>{ T.translate(layoutType === 'tablet' ? 'Dashboard.labels.TaxProRowTablet' : 'Dashboard.labels.TaxProRow', { TaxYear: x.taxYear, TaxProName: x.assigned_tax_pro.name }) }</span>
                    }
                    {pros.length === 1 &&
                        <span>{x.assigned_tax_pro.name}</span>
                    }
                    {pros.length === 1 && x.assigned_tax_pro.officeAddress &&
                        <Fragment>
                            <br/>
                            <span>{x.assigned_tax_pro.officeAddress}</span>
                        </Fragment>
                    }
                    {pros.length === 1 && x.assigned_tax_pro.officeAddress && x.assigned_tax_pro.officeCity &&
                        <span>{x.assigned_tax_pro.officeCity}</span>
                    }
                    {pros.length === 1 && x.assigned_tax_pro.officePhone && layoutType === 'desktop' &&
                        <Fragment>
                            <br />
                            <span>{x.assigned_tax_pro.officePhone}</span>
                        </Fragment>
                    }
                    {pros.length === 1 && x.assigned_tax_pro.officePhone && layoutType !== 'desktop' &&
                        <Fragment>
                            <a href={'tel:' + (x.assigned_tax_pro.officePhone || '').replace(/[^0-9.]/g, '')}>
                                <span>{x.assigned_tax_pro.officePhone}</span>
                            </a>
                        </Fragment>
                    }
                    {x.assigned_tax_pro.calendar_link &&
                        <a href={x.assigned_tax_pro.calendar_link} target="_blank" rel="noopener noreferrer"
                            onClick={() => scheduleCallClick(x.taxYear)}>
                        {T.translate('Dashboard.labels.CalendarLink', { TaxProName: x.assigned_tax_pro.name })}
                        </a>
                    }
                </Fragment>
            ))}            
        </div>

    )
}

AssignedTaxProBlock.propTypes = {
    assigned_tax_pros: PropTypes.arrayOf(PropTypes.shape({
        taxYear: PropTypes.number.isRequired,
        assigned_tax_pro: PropTypes.shape({
            name: PropTypes.string.isRequired,
            calendar_link: PropTypes.string
        })
    })).isRequired,
    layoutType: PropTypes.string.isRequired,
    scheduleCallClick: PropTypes.func.isRequired
}

export default AssignedTaxProBlock;


/*
 * : (
        <div className='wf-wb-taxpro-container'>
            <h3>{T.translate(layoutType === 'tablet' ? 'Dashboard.labels.YourTaxProsTablet' :'Dashboard.labels.YourTaxPros')}</h3>
            <div className='wf-wb-taxpro-list'>
                    {pros.map(x => <span key={'pro_' + x.taxYear}>
                        {T.translate(layoutType === 'tablet' ? 'Dashboard.labels.TaxProRowTablet' : 'Dashboard.labels.TaxProRow', { TaxYear: x.taxYear, TaxProName: x.assigned_tax_pro.name })}
                    </span>)}
            </div>
            {layoutType === 'mobile' &&
                <div className='wf-wb-hr' />
            }
        </div>
    )*/