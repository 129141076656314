/* eslint-disable-next-line */
import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux'
import auth from '../../sagas/SSO/Auth'
import LoadingPage from '../Loading/loadingPage';

const OTCLoginCallback = () => {

    const config = useSelector(state => state.config.CASConfig || {}, shallowEqual)

    useEffect(() => {
        auth.initAuthProvider(config)
        auth.handleAuthentication().then(() => { })
    }, [])

    return (<LoadingPage />)
}

export default OTCLoginCallback