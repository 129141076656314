import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import IconLinkButton from '../../Controls/iconLinkButton'
import PlainButton from '../../Controls/plainButton'
import './workflowpanel.css'
import classNames from 'classnames';
import T from 'i18n-react';

const CreateNewReturnSection = ({ onClick, inProgress, virCustomer }) => (
    <Fragment>
        <div className={classNames('wf-wp-createNewReturnDesc-block')}>
            <span>{T.translate(`WorkflowPanel.createNewReturnDescription`)}</span>
        </div>
        <div className='wf-wp-create-container'>
            {virCustomer && <IconLinkButton isLink={true} icon='addToPhotosIcon rotate-270' labelKey='WorkflowPanel.createNewReturnVIR' onClick={onClick} inProgress={inProgress} />}
            {!virCustomer && <PlainButton labelKey='WorkflowPanel.createNewReturn' onClick={onClick} inProgress={inProgress} />}
        </div>
    </Fragment>
);

CreateNewReturnSection.propTypes = {
    onClick: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired
}

export default CreateNewReturnSection;