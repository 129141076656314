import classnames from 'classnames'
import { bool, date, number, shape, string } from 'prop-types'
import React from "react"
import DoxButton from "../../Shared/components/DoxButton"
import EmDash from "../../Shared/components/EmDash"
import { useTranslationContext } from "../../Shared/hooks/useTranslationContextHook"
import { ErrorTypes } from "../../Shared/resources/Enums"
import DoxCheckMark from "../graphics/doxCheckMark"
import { useFileUpload } from '../hooks/useFileUploadHook'
import DoxProgressBar from "./doxProgressBar"

const DoxUploadItemMobile = ({ queuedFile, cancel, layoutType }) => {

    // gah, just do this once here
    const fileName = ((queuedFile || {}).file || {}).name

    const translateString = useTranslationContext()

    const {
        percentUploaded, isUploadComplete, uploadError, renameFileAs,
        handleCancelClick, handleRetryClick, handleKeepBothClick, handleReplaceClick } = useFileUpload(queuedFile, cancel, layoutType)


    const shouldShowInlineCancelButton = () => !uploadError

    const shouldShowProgressBar = () => !uploadError || uploadError === ErrorTypes.UNKNOWN_ERROR

    const shouldShowRetry = () => uploadError === ErrorTypes.UNKNOWN_ERROR

    const shouldShowConflictResolutionOptions = () => uploadError === ErrorTypes.FILE_EXISTS

    return (
        <div className='dox-upload-item'>
            <div className='hrb-columns'>
                <div className='hrb-column hrb-is-9'>
                    <p className={classnames('filename', { 'error': uploadError })}>
                        {renameFileAs ? renameFileAs : (fileName ? fileName : <EmDash />)}
                    </p>
                </div>
            </div>
            {uploadError &&
                <div className='hrb-columns'>
                    <div className='hrb-column hrb-is-12'>
                        <p className='error'>{translateString(`Dox.DocumentUploader.ValidationErrors.${uploadError}`)}</p>
                    </div>
                </div>
            }
            {shouldShowProgressBar() &&
                <div className='hrb-columns dox-upload-progress'>
                    <div className='hrb-column hrb-is-9'>
                        <DoxProgressBar percent={percentUploaded} hasError={!!uploadError} showPercentage />
                    </div>
                    <div className='hrb-column hrb-is-3'>
                        {shouldShowInlineCancelButton() && !isUploadComplete &&
                            <DoxButton
                                elementId={`cancel-upload-${fileName}`}
                                buttonStyles='link-style no-underline force-100'
                                labelKey='Dox.DocumentUploader.CTAs.Cancel'
                                onClick={handleCancelClick} />
                        }
                        {shouldShowInlineCancelButton() && isUploadComplete &&
                            <div className='checkmark'>
                                <DoxCheckMark />
                            </div>
                        }
                    </div>
                </div>
            }
            {shouldShowRetry() &&
                <div className='hrb-columns conflict-resolution'>
                    <div className='hrb-column hrb-is-3'><p /></div>
                    <div className='hrb-column hrb-is-6 centered'>
                        <DoxButton
                            elementId={`retry-upload-${fileName}`}
                        buttonStyles='link-style no-underline force-100'
                            labelKey='Dox.DocumentUploader.CTAs.Retry'
                            onClick={handleRetryClick} />
                    </div>
                    <div className='hrb-column hrb-is-3'>
                        <DoxButton
                            elementId={`cancel-upload-${fileName}`}
                        buttonStyles='link-style no-underline force-100'
                            labelKey='Dox.DocumentUploader.CTAs.Cancel'
                            onClick={handleCancelClick} />
                    </div>
                </div>
            }
            {shouldShowConflictResolutionOptions() &&
                <div className='hrb-columns conflict-resolution'>
                    <div className='hrb-column flex-start'>
                        <DoxButton
                            elementId={`keepboth-upload-${fileName}`}
                        buttonStyles='link-style no-underline force-100'
                            labelKey='Dox.DocumentUploader.CTAs.KeepBoth'
                            onClick={handleKeepBothClick} />
                    </div>
                    <div className='hrb-column hrb-is-fill centered'>
                        <DoxButton
                        elementId={`replace-upload-${fileName} force-100`}
                            buttonStyles='link-style no-underline'
                            labelKey='Dox.DocumentUploader.CTAs.Replace'
                            onClick={handleReplaceClick} />
                    </div>
                    <div className='hrb-column flex-end'>
                        <DoxButton
                        elementId={`cancel-upload-${fileName} force-100`}
                            buttonStyles='link-style no-underline'
                            labelKey='Dox.DocumentUploader.CTAs.Cancel'
                            onClick={handleCancelClick} />
                    </div>
                </div>
            }
        </div>
    )
}

DoxUploadItemMobile.propTypes = {
    queuedFile: shape({
        file: shape({
            lastModified: number,
            lastModifiedDate: date,
            name: string,
            size: number,
            type: string
        }),
        qPosition: number,
        readyToUpload: bool,
        isUploadComplete: bool
    }).isRequired,
    cancel: bool,
    layoutType: string
}

export default DoxUploadItemMobile