import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import '../Documents/style.css'
import './style.css';
import T from 'i18n-react';

class ConfirmIconButton extends Component {
    constructor(props) {
        super(props)
        this.state = {     
            hover: false,            
            icon: this.props.icon,
            label: this.props.label,
            size: this.props.size || '3x',
            isEnabled: Boolean(this.props.isEnabled),
            isChecked: Boolean(this.props.isChecked),
            isSelected: Boolean(this.props.isSelected),
            layoutType: this.props.layoutType,
            baseStyle: this.props.baseStyle || 'defaultDocumentsButtonStyle',
            firstName: this.props.firstName,
            spouseFirstName: this.props.spouseFirstName
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.icon !== newProps.icon) {
            this.setState({icon: newProps.icon})
        }

        if (this.state.label !== newProps.label) {
            this.setState({ label: newProps.label })
        }

        if (this.state.size !== newProps.size && newProps.size) {
            this.setState({ size: newProps.size })
        }

        if (this.state.isEnabled !== newProps.isEnabled) {
            this.setState({ isEnabled: newProps.isEnabled })
        }

        if (this.state.isChecked !== newProps.isChecked) {
            this.setState({ isChecked: Boolean(newProps.isChecked) })
        }

        if (this.state.isSelected !== newProps.isSelected) {
            this.setState({ isSelected: Boolean(newProps.isSelected) })
        }

        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }

        if (newProps.baseStyle && this.state.baseStyle !== newProps.baseStyle) {
            this.setState({ baseStyle: newProps.baseStyle })
        }

        if (this.state.firstName !== newProps.firstName) {
            this.setState({ firstName: newProps.firstName })
        }

        if (this.state.spouseFirstName !== newProps.spouseFirstName) {
            this.setState({ spouseFirstName: newProps.spouseFirstName })
        }
    }

    onMouseOver(event) {
        if (!this.state.hover) {
            this.setState({ hover: true })
        }
    }

    onMouseLeave(event) {
        if (this.state.hover) {
            this.setState({ hover: false })
        }
    }

    onButtonClick(event) {
        // raise the event for listeners
        if (this.props.onClick && !this.state.isChecked) {
            this.props.onClick(event);
        }
    }   

    render() {
        var iconColor = '#14AA40'
        var textColor = '#3F3F3F'
        var iconName = this.state.icon
        var hoverState = Boolean(this.state.hover || this.state.isSelected)
        var buttonClassName = this.state.baseStyle + " defaultDocumentButtonWidth unselectableLabel"
        if (this.state.layoutType === 'mobile') {
            buttonClassName += ' defaultDocumentButtonHeightMobile'
        } else if (this.state.layoutType === 'tablet' || this.state.layoutType === 'small_tablet') {
            buttonClassName += ' defaultDocumentButtonHeightTablet'
        } else {
            buttonClassName += ' defaultDocumentButtonHeight'
        }
        if (this.state.isChecked) {
            buttonClassName += " confirmButtonChecked"
            iconName = 'fa-check'
            iconColor = "#FFF"
            textColor = "#FFF"
        } else if (this.state.isEnabled) {
            buttonClassName += hoverState ? " documentsHover" : " documentsNoHover"
            iconColor = hoverState ? '#FFF' : '#14AA40'
            textColor = hoverState ? '#FFF' : '#3F3F3F'
        } else {
            buttonClassName += " documentsDisabled"
            iconColor = '#3F3F3F'
        }


        return (              
            <div onMouseOver={(event) => { this.onMouseOver(event) }}
                onMouseLeave={(event) => { this.onMouseLeave(event) }}
                className={buttonClassName}
                onClick={(event) => { this.onButtonClick(event) }} >
                <div style={{width: '50px', marginLeft: '25px'}}>
                    <FontAwesome
                        className={iconName}
                        name={iconName}
                        size={this.state.size}
                        style={{ paddingLeft: "0px", paddingRight: "0px", color: iconColor }}
                    />
                    </div>
                <div style={{ color: textColor, marginLeft: "20px", textAlign: 'left' }} >{T.translate(this.state.label, { FirstName: this.state.firstName, SpouseFirstName: this.state.spouseFirstName })}</div>
            </div>
        )       
    } 
}

export default ConfirmIconButton;