import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useLayoutStyle = () => {

    const layoutType = useSelector(state => state.layout.layoutType)
    const [rootStyle, setRootStyle] = useState('desktop')

    useEffect(() => {
        let style =
            (layoutType === 'tablet' || layoutType === 'small_tablet' ? 'tablet' :
            (layoutType === 'mobile' ? 'mobile' : 'desktop'))
        setRootStyle(style)
    }, [layoutType])

    return { layoutType, rootStyle }
}

export default useLayoutStyle