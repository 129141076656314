import React from 'react'
import { string, func } from 'prop-types'
import classnames from 'classnames'
import DoxFileIcon from '../graphics/doxFileIcon';
import useFileDragDrop from '../hooks/useFileDragDropHook';
import { AcceptedFileExts } from '../../Shared/resources/DoxFileTypes';
import { useTranslationContext } from '../../Shared/hooks/useTranslationContextHook';
import DoxScanIcon from '../graphics/doxScanIcon';

const DoxFileSelector = ({ onFilesSelected, layoutType }) => {

    const translateString = useTranslationContext()

    const fileList = [] // todo: will we ever want to append to this list?
    const acceptedFileTypes = AcceptedFileExts.join(',')

    const updateUploadFileList = files => {
        let newFileList = [...fileList]

        if (files && files.length > 0) {
            const existingFileNames = fileList.map(f => f.name)

            for (let i = 0; i < files.length; i++) {
                if (!existingFileNames.includes(files[i].name)) {
                    newFileList.push(files[i])
                }
            }

            onFilesSelected(newFileList)
        }
    }

    const handleFilesSelected = e => {
        updateUploadFileList(e.target.files)
    }

    const { handleDragEnter, handleDragLeave, handleDragOver, handleDrop, inDropZone } = useFileDragDrop(updateUploadFileList)

    return (
        <div className='dox-file-selector-panel'
            onDrop={e => handleDrop(e)}
            onDragOver={e => handleDragOver(e)}
            onDragEnter={e => handleDragEnter(e)}
            onDragLeave={e => handleDragLeave(e)}>
            {layoutType === 'desktop' &&
                <div className={classnames('drop-zone', { 'active': inDropZone })}>
                    <DoxFileIcon />
                    <label htmlFor='dox-file-upload'>
                        <span>{translateString(
                            'Dox.DocumentUploader.SelectFilesLabel.Desktop',
                            { BrowseDeviceLink: <span className='dox-link bold inline'>{translateString('Dox.DocumentUploader.BrowseDeviceLink.Desktop')}</span> })}
                        </span>
                        <input id='dox-file-upload' type="file" onChange={handleFilesSelected} accept={acceptedFileTypes} multiple />
                    </label>
                    <span className='acceptedFileInfo'>{translateString('Dox.DocumentUploader.AcceptedFileInfo')}</span>
                </div>
            }
            {layoutType !== 'desktop' &&
                <div className='mobileDocUploadZone'>
                    <label htmlFor='dox-file-upload'>
                        <div>
                            <DoxFileIcon />
                            <DoxScanIcon />
                        </div>
                        <span>{translateString(
                            'Dox.DocumentUploader.SelectFilesLabel.Mobile',
                            { BrowseDeviceLink: <span className='dox-link bold inline'>{translateString('Dox.DocumentUploader.BrowseDeviceLink.Mobile')}</span> })}
                        </span>
                        <input id='dox-file-upload' type="file" onChange={handleFilesSelected} accept={acceptedFileTypes} multiple />
                    </label>
                    <span className='acceptedFileInfo'>{translateString('Dox.DocumentUploader.AcceptedFileInfo')}</span>
                </div>
            }
        </div>
    );
}

DoxFileSelector.propTypes = {
    layoutType: string.isRequired,
    onFilesSelected: func.isRequired
}

export default DoxFileSelector