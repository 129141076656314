import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux'
import { useHistory } from 'react-router-dom'
import T from 'i18n-react';
import './dox.css';
import DocumentIconButton from '../Documents/documentIconButton';

const Documents = () => {
    const history = useHistory();

    const { lang, layoutType } = useSelector(state => state.layout, shallowEqual)
    const { linkConfig } = useSelector(state => state.config.linkConfig, shallowEqual)

    const taxYears = useSelector(state => state.workflow && state.workflow.case_state && state.workflow.case_state.taxYears
        ? state.workflow.case_state.taxYears : [], shallowEqual)

    const [checklistLink, setChecklistLink] = useState(null)

    useEffect(() => {
        let cll = null
        if (linkConfig && lang && linkConfig[lang]) {
            cll = linkConfig[lang]['checklist_link']
        }
        if (!cll) {
            cll = T.translate('Documents.ChecklistLink')
        }
        if (cll !== checklistLink) {
            setChecklistLink(cll)
        }
    }, [lang, setChecklistLink, checklistLink, linkConfig])

    const handleUpload = (taxYear, ticketType) => {
        let comingFrom = 'documents';
        history.push(`/dashboard/uploader/${taxYear}/${comingFrom}`)
    }

    const handleView = () => {
        history.push('/dashboard/documents')
    }

    return (
        <div className="tabContentDocuments">
            <div className="documentsRootStyle">
                <div className={layoutType === 'mobile' ? "documentsMainStyleMobile" : (layoutType === 'desktop' ? "documentsMainStyle" : "documentsMainStyleTablet")}>
                    <h1>{T.translate('Documents.Title')}</h1>
                    <div style={{ height: '20px' }} />
                    <h3>{T.translate('Documents.UploadSubTitle')}</h3>
                    <div className="documentsSpacingContainer">
                        <span>{T.translate('Documents.UploadDesc')}</span>
                    </div>
                    {taxYears.map(x =>
                        <DocumentIconButton key={'upload_' + x.taxYear} icon="fa-cloud-upload"
                            label='Documents.UploadButtonLabel' layoutType={layoutType}
                            labelContext={{ TaxYear: x.taxYear }}
                            onClick={(ev) => handleUpload(x.taxYear, x.ticket_type)} />
                    )}
                    <div style={{ height: '30px' }} />

                    <h3>
                        {T.translate('Documents.SubTitle')}
                    </h3>
                    <div className="documentsSpacingContainer">
                        <span>{T.translate('Documents.ManageDesc')}</span>
                    </div>
                    <DocumentIconButton icon="fa-file"
                        label="Documents.ViewButtonLabel" layoutType={layoutType}
                        onClick={handleView} />
                </div>
            </div>
        </div>
    )
}

export default Documents

