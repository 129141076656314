import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    setEmail: ['email'],
    setPassword: ['password'],
    setErrorMessages: ['errorMessageStr1', 'errorMessageStr2'],
    setRemainingAttempts: ['loginAttemptsRemaining', 'isLocked'],
    requestLogin: null,
    loginReset: null,
    otcSignIn: ['one_time_code']
})

export const LoginTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    email: '',
    password: '',
    errorMessageStr1: '',
    errorMessageStr2: '',
    loginAttemptsRemaining: -1,
    isLocked: false
}

/* ------------- Reducers ------------- */
export const loginSetEmail = (state, { email }) =>
    Object.assign({}, state, { email: email })

export const loginSetPassword = (state, { password }) =>
    Object.assign({}, state, { password: password })

export const loginSetErrorMessages = (state, { errorMessageStr1, errorMessageStr2 }) =>
    Object.assign({}, state, { errorMessageStr1: errorMessageStr1, errorMessageStr2: errorMessageStr2 })

export const loginSetRemainingAttempts = (state, { loginAttemptsRemaining, isLocked }) =>
    Object.assign({}, state, { loginAttemptsRemaining: loginAttemptsRemaining, isLocked: isLocked })

export const loginRequestLogin = (state) => 
    Object.assign({}, state, { errorMessageStr1: '', errorMessageStr2: '' })

export const loginResetState = () => INITIAL_STATE


/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_EMAIL]: loginSetEmail,
    [Types.SET_PASSWORD]: loginSetPassword,
    [Types.SET_ERROR_MESSAGES]: loginSetErrorMessages,
    [Types.SET_REMAINING_ATTEMPTS]: loginSetRemainingAttempts,
    [Types.REQUEST_LOGIN]: loginRequestLogin,
    [Types.LOGIN_RESET]: loginResetState
})