/* eslint-disable-next-line */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux' 
import { useUnauthorizedContext } from './useUnauthorizedContextHook';
import doxExplorerCDMServices from '../services/doxExplorerCDMServices';

export const useFileSearch = (tax_year, sortOverride) => {
    const handleUnauthorized = useUnauthorizedContext()
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState(false)
    const [fileList, setFileList] = useState([])
    const [status, setStatus] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [lastUpdated, setLastUpdated] = useState(null)
    const [taxYear, setTaxYear] = useState(null)

    const accessToken = useSelector(state => state.auth.accessToken)

    const updateSort = (col, dir) => {
        if (sortColumn !== col) {
            setSortColumn(col)
        }
        if (sortDirection !== dir) {
            setSortDirection(dir)
        }
    }
    
    const deleteFile = async (documentId) => {
        if (await doxExplorerCDMServices.deleteFile(handleUnauthorized, accessToken, documentId)) {
            let fl = fileList.filter(x => x.documentId !== documentId)
            setFileList(fl)
            return true
        } else {
            return false
        }
    }

    const renameFile = async (documentId, newFileName) => {
        var file = fileList.find(x => x.documentId === documentId)
        if (file) {
            var metaDataRequest = {
                FileName: newFileName,
                ClientDescription: '',
                DocumentType: file.documentType || '',
                DocumentStateus: file.documentStatus
            }
        
            if (file && await doxExplorerCDMServices.updateFileMetadata(handleUnauthorized, accessToken, documentId, metaDataRequest)) {
                file.fileName = newFileName
                file.modifiedTs = new Date().toISOString()

                setFileList(doxExplorerCDMServices.sortFileList(fileList, sortColumn, sortDirection))
                return true
            }
        }
        return false
    }

    useEffect(() => {
        if (sortOverride) {
            if (sortOverride !== sortColumn) {
                setSortColumn(sortOverride)
            }
            if (sortDirection) {
                setSortDirection(false);
            }
        }
    }, [sortOverride])

    useEffect(() => {
        async function retrieveData() {
            var { status: st, fileList: fl } =
                await doxExplorerCDMServices.getSortedFileList(handleUnauthorized, accessToken, taxYear, sortColumn, sortDirection);
            setLastUpdated(new Date().getTime())
            setFileList(fl)
            setStatus(st)
            setLoaded(true)
        }

        if (taxYear) {
            if (!lastUpdated || new Date().getTime() - lastUpdated > 120000) {
                setLoaded(false)
                retrieveData(taxYear, sortColumn);
            } else {
                setFileList(doxExplorerCDMServices.sortFileList(fileList, sortColumn, sortDirection))
            }
        }
    }, [taxYear, sortColumn, sortDirection])

    useEffect(() => {
        if (taxYear !== tax_year) {
            setLastUpdated(null)
            setTaxYear(tax_year)
        }
    }, [tax_year])

    return [ sortColumn, sortDirection, updateSort, status, fileList, loaded, deleteFile, renameFile ]
}