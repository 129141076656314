import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import './rm.css'
import FontAwesome from 'react-fontawesome';
import LoginActions from '../../../reducers/loginRedux'
import BaseModal from '../../Modal/BaseModal';
import AccountSetUpUserExistMessage from '../accountSetUpUserExistMessage';

const RegisterExistingLogin = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const layoutType = useSelector(state => state.layout.layoutType)

    const loginUser = () => {
        dispatch(LoginActions.requestLogin())
        history.replace('/')
    }

    const resetRegistration = () => {
        history.replace('/register')
    }

    return (
        <div className='rm-outer'>
            <div className="rm-inner">
                <FontAwesome className='fa-spinner fa-default-style' name='spinner' pulse size='3x' />                           
            </div>
            <BaseModal show={true} onClose={resetRegistration} layoutType={layoutType}>
                <AccountSetUpUserExistMessage signInUserExist={loginUser} />
            </BaseModal>
        </div>
    )
}

export default RegisterExistingLogin
