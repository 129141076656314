/* eslint-disable-next-line */
import classnames from 'classnames';
import { detect } from 'detect-browser';
import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import BottomMenuBar from '../Common/Footer/bottommenubar';
import HeaderMenuBar from '../Common/Header/headermenubar';
import './style.css';

const OuterContainer = props => {

    const browser = detect()
    const match = useRouteMatch()

    const { children, sizeToContent, centerContent, loaded } = props

    const { layoutType } = useSelector(state => state.layout || {})
    const { loginComplete } = useSelector(state => state.auth || {}, shallowEqual)

    const handlePageShow = e => {
        if (e.persisted) {
            window.location.reload()
        }
    }

    const handleBeforeUnload = () => {
        // hack to force the window to scroll to the top on unload
        // so that the spinner is always in the right place.
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        window.addEventListener('onpageshow', handlePageShow)
        window.addEventListener('onbeforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('onpageshow', handlePageShow)
            window.removeEventListener('onbeforeunload', handleBeforeUnload)
        }
    }, [])

    const renderWrappedContent = () => {
        let isDashboardPage = match && match.path === '/dashboard' && match.isExact

        if (layoutType === 'desktop') {
            return renderForDesktop()
        } else if (['tablet', 'small_tablet'].includes(layoutType)) {
            return renderForTablet(isDashboardPage)
        } else {
            return renderForMobile(isDashboardPage)
        }
    }

    const renderForDesktop = () => {
        let layoutContent = []

        const containerStyle = classnames('AppContentContainer',
            { 'AppContentContainerFitContentDesktopLayout': sizeToContent },
            { 'AppContentContainerDesktopLayout': !sizeToContent },
            { 'AppContentContainerCenteredDesktopLayout': centerContent })

        layoutContent.push(<div key='spacer' className="headerSpacer" />)

        layoutContent.push(
            <div className={sizeToContent ? 'AppCenterContainerFitContent' : 'AppCenterContainer'} key='container'>
                <div className={containerStyle}>
                    {children}
                </div>
            </div>
        )

        return layoutContent
    }

    const renderForTablet = (isDashboardPage) => {
        let layoutContent = []

        if (loginComplete && isDashboardPage) {
            layoutContent.push(<div key='spacer' className="headerSpacerOther" />)
        } else {
            layoutContent.push(<div key='spacer' className="headerSpacer" />)
        }

        layoutContent.push(
            <div className="AppContentContainer AppContentContainerOtherLayout" key='container'>
                {children}
            </div>
        )

        return layoutContent
    }

    const renderForMobile = (isDashboardPage) => {
        let layoutContent = []

        if (loginComplete && isDashboardPage) {
                layoutContent.push(<div key='spacer' className="headerSpacerOther" />)
        } else {
                layoutContent.push(<div key='spacer' className="headerSpacer" />)
        }

        layoutContent.push(
            <div className="AppContentContainer AppContentContainerOtherLayout" key='container'>
                {children}
            </div>
        )

        return layoutContent
    }

    return (
        <div className={classnames('AppContainerStyle', { 'ie_style': browser && browser.name === 'ie' })}>
            <div className={classnames('AppLayoutStyle', { 'AppLayoutFullWidth': layoutType === 'mobile' })}>
                <HeaderMenuBar loaded={loaded} />
                {renderWrappedContent()}
                <BottomMenuBar />
            </div>
        </div>
    )
}

export default OuterContainer