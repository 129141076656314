import React from 'react';
import PropTypes from 'prop-types'
import './doxModal.css'
import classNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';

const DoxModal = ({ layoutType, closeOnBgClick, onClose, children, show}) => {

    const handleScroll = (ev) => {
        ev.preventDefault();
    }

    const handleClose = (ev) => {
        onClose(ev);
    }

    const handleBackgroundClick = () => {
        if (closeOnBgClick && layoutType !== 'desktop') {
            onClose();
        }
    }

    const containerClick = (ev) => {
        ev.stopPropagation()
    }
  
    return (
        <div>
            {show &&
                <div className='dox-modal-background' onScroll={handleScroll}
                    onTouchMove={handleScroll} onClick={handleBackgroundClick}>
                    <div className={classNames('dox-modal-container', layoutType)} onClick={containerClick}>
                        <div className='dox-modal-header'>
                            <div className="dox-modal-exit-button" onClick={handleClose}>
                                <CloseIcon />
                            </div>
                        </div>
                        <div className="dox-modal-content">
                            {children}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

DoxModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    layoutType: PropTypes.string.isRequired,
    closeOnBgClick: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
}

export default DoxModal;