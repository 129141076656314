import apisauce from 'apisauce'
import { getApiBaseUrl } from './serviceUtils'

/**
 * API for managing workflow information
 * @param {String} baseURL
 */
const create = (baseURL = getApiBaseUrl()) => {  // this will change for prod deployment
    // Create and configure an apisauce-based api object.
    const api = apisauce.create({
        baseURL,
        headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Content-Type': 'application/json' },
        timeout: 20000
    })

    // Wrap api's addMonitor to allow the calling code to attach additional monitors in the future.
    // Only in __DEV__ and only if we've attached Reactotron to console (it isn't during unit tests).
    //if (__DEV__ && console.tron) {
    //  api.addMonitor(console.tron.apisauce)
    //}

    const getAuthHeader = (accessToken) => {
        return {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        }
    }

    const setSurvey = (accessToken, survey) => {
        var payload = survey
        return api.post('survey', payload, getAuthHeader(accessToken))
    }       

    // Return back a collection of functions
    return {
        setSurvey
    }
}

// let's return back our create method as the default.
export default {
    create
}

