import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import momen from 'moment'
import moment from 'moment-timezone'
import T from 'i18n-react';

const DateTime = (props) => {

    const { value, fallbackValue, dateAndTime, includeSeconds, noOffset } = props

    const [momentDate, setMomentDate] = useState(null)
    const [momentTime, setMomentTime] = useState(null)
    const [datePrecursor, setDatePrecursor] = useState('')
    const [timePrecursor, setTimePrecursor] = useState('')

    const lang = useSelector(state => state.layout.lang);

    const hasIsoOffset = (dateString) => {
        // used to check whether the date string we receive has any kind of offset identified.
        // This will match on any '+00:00', or 'Z' offset identifiers

        // Regex modified from here: https://rgxdb.com/r/144C2KWS
        return /(?:Z|([+-])(\d{2}):?(\d{2}))$/.test(dateString)
    }

    useEffect(() => {
        setDatePrecursor(T.translate('DatePrecursor'))
        setTimePrecursor(T.translate('TimePrecursor'))
    }, [lang])

    useEffect(() => {
        if (value) {

            moment.updateLocale('fr-ca', momen.localeData()._config)

            var displayDate = '';
            let adjustedValue = value

            if (adjustedValue.toString().includes('T00:00:00')) {
                // no time to worry about, go ahead and make a date
                displayDate = new Date(adjustedValue)
            } else if (noOffset === true) {
                // create Date object with passed in ISO string
                displayDate = new Date(adjustedValue)            
            } else if (!hasIsoOffset(adjustedValue)) {
                // time was specified, but no offset, so add one
                // note: '+00:00' is equivalent to 'Z'
                displayDate = new Date(adjustedValue + '+00:00')
            } else {
                // create Date object with passed in ISO string
                displayDate = new Date(adjustedValue)
            }

            var momentTimeZone = moment.tz(displayDate, moment.tz.guess());

            if (momentTimeZone.isValid()) {
                const dateLocale = T.translate('DateLocale')
                const dateFormat = T.translate('DateFormat')
                const timeFormat = T.translate(includeSeconds ? 'TimeFormatWithSeconds' : 'TimeFormat')

                setMomentDate(momentTimeZone.locale(dateLocale).format(dateFormat))
                setMomentTime(momentTimeZone.locale(dateLocale).format(timeFormat))
            }
        } else {
            setMomentDate(null)
            setMomentTime(null);
        }
    }, [value, lang, includeSeconds, noOffset])

    return value ? (
        <span>
            {`${datePrecursor}${momentDate}`}
            {dateAndTime && `${timePrecursor}${momentTime}`}
        </span>
    ) : (
            <span>{fallbackValue}</span>
        )
}

DateTime.propTypes = {
    lang: PropTypes.string,
    value: PropTypes.string,
    fallbackValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    dateAndTime: PropTypes.bool,
    includeSeconds: PropTypes.bool
};


export default DateTime
