import React, { Component } from 'react';
import '../../Common/shared.css'
import '../style.css';

import T from 'i18n-react';
import TaxYearForm from '../taxYearForm'
import InterviewTitleProgress from '../../Common/interviewTitleProgress'

class InterviewPage10 extends Component {
    constructor(props) {
        super(props)

        var taxYearInfo = this.props.taxYearInfo || {
            employedChecked: false,
            selfEmployedChecked: false,
            retiredChecked: false,
            businessOwnerChecked: false,
            workExpensesChecked: false,
            disabilityChecked: false,
            medicalExpensesChecked: false,
            benefitsEIChecked: false,
            investmentIncomeChecked: false,
            rRSPChecked: false,
            rentalChecked: false,
            foreignIncomeChecked: false,
            dependentsChecked: false,
            propertySaleChecked: false,
            spouseSupportChecked: false,
            movingExpensesChecked: false,
            tuitionChecked: false,
            donationsChecked: false,
            fireRescueChecked: false,
            farmingChecked: false
        }

        this.state = {
            spouseFirstName: this.props.spouseFirstName,
            taxYearInfo,
            lang: this.props.lang,
            layoutType: this.props.layoutType
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.taxYearInfo !== this.state.taxYearInfo) {
            this.setState({
                taxYearInfo: newProps.taxYearInfo
            })
        }
        if (newProps.spouseFirstName !== this.state.spouseFirstName) {
            this.setState({ spouseFirstName: newProps.spouseFirstName })
        }
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }
        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
    }

    onTaxYearInfoChanged(taxYearInfo) {
        var newTaxYearInfo = {
            employedChecked: taxYearInfo.employedChecked,
            selfEmployedChecked: taxYearInfo.selfEmployedChecked,
            retiredChecked: taxYearInfo.retiredChecked,
            businessOwnerChecked: taxYearInfo.businessOwnerChecked,
            workExpensesChecked: taxYearInfo.workExpensesChecked,
            disabilityChecked: taxYearInfo.disabilityChecked,
            medicalExpensesChecked: taxYearInfo.medicalExpensesChecked,
            benefitsEIChecked: taxYearInfo.benefitsEIChecked,
            investmentIncomeChecked: taxYearInfo.investmentIncomeChecked,
            rRSPChecked: taxYearInfo.rRSPChecked,
            rentalChecked: taxYearInfo.rentalChecked,
            foreignIncomeChecked: taxYearInfo.foreignIncomeChecked,
            dependentsChecked: taxYearInfo.dependentsChecked,
            propertySaleChecked: taxYearInfo.propertySaleChecked,
            spouseSupportChecked: taxYearInfo.spouseSupportChecked,
            movingExpensesChecked: taxYearInfo.movingExpensesChecked,
            tuitionChecked: taxYearInfo.tuitionChecked,
            donationsChecked: taxYearInfo.donationsChecked,
            fireRescueChecked: taxYearInfo.fireRescueChecked,
            farmingChecked: taxYearInfo.farmingChecked
            }
        this.setState({ taxYearInfo: newTaxYearInfo })
        if (this.props.onTaxYearInfoChanged) {
            this.props.onTaxYearInfoChanged(newTaxYearInfo)
        }
    }

    render() {
        var titleNextContent = 'Interview.Page10.nextTitle1'
        if (this.props.isCompany && this.props.showPricingGlobal) {
            titleNextContent = 'Interview.Page10.nextTitle2'
        }

        var percentage = '84'
        if (this.props.spouseTaxes === 'Y' && this.props.dependants === 'Y') { percentage = '86' }

        return (
            <div>
                <div>
                    <div>
                        <InterviewTitleProgress taxYear={this.props.taxYear} percentage={percentage} title="Interview.Page10.headerTitle" nextTitle={titleNextContent} firstName={this.props.firstName} />
                    </div>  
                    <div className="interview-page10-top-margin">
                        <p>
                            <T.span text={{ key: 'Interview.Page10.headerContent0', SpouseFirstName: this.props.firstName }} className="headerText" />                    
                        </p>
                    </div>
                </div>
                <TaxYearForm 
                    taxYear={this.props.taxYear} 
                    taxYearInfo={this.state.taxYearInfo} 
                    labelKey={'Interview.Page10'}
                    layoutType={this.state.layoutType} 
                    isReturningClient={this.props.isReturningClient}
                    spouseFirstName={this.props.firstName} 
                    onTaxYearInfoChanged={(taxYearInfo) => this.onTaxYearInfoChanged(taxYearInfo)} 
                    sectionHeader1={"Interview.Page9&10Common.ScenarioHeader1"} 
                    sectionHeader2={"Interview.Page9&10Common.ScenarioHeader2"}
                    sectionHeader3={"Interview.Page9&10Common.ScenarioHeader3"} 
                    sectionHeader4={"Interview.Page9&10Common.ScenarioHeader4"}
                    sectionHeader5={"Interview.Page9&10Common.ScenarioHeader5"}
                />
            </div>           
        )
    }
}

export default InterviewPage10;