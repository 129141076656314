import React, { Component } from 'react';
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import Sidebar from './Sidebar/Sidebar'
import WorkflowContent from './workflowContent'
import '../Dashboard/style.css';
import './workflow.css';

class Workflow extends Component {
    render() {
        return (
            <div className={classNames('workflow_main_container', this.props.layoutType)}>
                <Sidebar />
                <div className='workflow-content-container'>
                    <WorkflowContent dashboardTab={this.props.dashboardTab} />
                </div>
            </div>
        )
    }
}

Workflow.propTypes = {
    layoutType: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    dashboardTab: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
    return {
        layoutType: state.layout.layoutType,
        lang: state.layout.lang,
        dashboardTab: state.layout.dashboardTab
    }
}


export default connect(mapStateToProps)(Workflow)
