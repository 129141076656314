import classnames from 'classnames'
import { bool, date, number, shape, string } from 'prop-types'
import React, { Fragment } from 'react'
import * as FontAwesome from 'react-fontawesome'
import DoxButton from '../../Shared/components/DoxButton'
import EmDash from '../../Shared/components/EmDash'
import { useTranslationContext } from '../../Shared/hooks/useTranslationContextHook'
import { ErrorTypes } from '../../Shared/resources/Enums'
import DoxCheckMark from '../graphics/doxCheckMark'
import { useFileUpload } from '../hooks/useFileUploadHook'
import DoxProgressBar from './doxProgressBar'

const DoxUploadItem = ({ queuedFile, cancel, layoutType }) => {

    // gah, just do this once here
    const fileName = ((queuedFile || {}).file || {}).name

    const translateString = useTranslationContext()

    const {
        percentUploaded, isUploadComplete, uploadError, renameFileAs,
        handleCancelClick, handleRetryClick, handleKeepBothClick, handleReplaceClick } = useFileUpload(queuedFile, cancel, layoutType)

    const shouldShowProgressBar = () => !uploadError || uploadError === ErrorTypes.UNKNOWN_ERROR

    const shouldShowConflictResolutionOptions = () => uploadError === ErrorTypes.FILE_EXISTS

    return (
        <div className='dox-upload-item'>
            <div className='hrb-columns wrap'>
                <div className='hrb-column hrb-is-4'>
                    <p className={classnames('filename', { 'error': uploadError })}>
                        {renameFileAs ? renameFileAs : (fileName ? fileName : <EmDash />)}
                    </p>
                </div>

                <div className='hrb-column hrb-is-fill flex-end'>
                    {shouldShowProgressBar() &&
                        <DoxProgressBar percent={percentUploaded} hasError={!!uploadError || percentUploaded === 0} showPercentage />
                    }
                </div>
                {shouldShowConflictResolutionOptions() &&
                    <Fragment>
                        <div className='hrb-column hrb-is-fill'></div>
                        <div className='hrb-column'>
                            <DoxButton
                                elementId={`keepboth-upload-${fileName}`}
                                buttonStyles='link-style no-underline force-100'
                                labelKey='Dox.DocumentUploader.CTAs.KeepBoth'
                                onClick={handleKeepBothClick} />
                        </div>
                        <div className='hrb-column'>
                            <DoxButton
                                elementId={`replace-upload-${fileName}`}
                                buttonStyles='link-style no-underline force-100'
                                labelKey='Dox.DocumentUploader.CTAs.Replace'
                                onClick={handleReplaceClick} />
                        </div>
                        <div className='hrb-column'>
                            <DoxButton
                                elementId={`cancel-upload-${fileName}`}
                                buttonStyles='link-style no-underline force-100'
                                labelKey='Dox.DocumentUploader.CTAs.Cancel'
                                onClick={handleCancelClick} />
                            </div>
                    </Fragment>
                }

                {uploadError === ErrorTypes.UNKNOWN_ERROR &&
                    <div className='hrb-column'>
                        <DoxButton
                            elementId={`retry-upload-${fileName}`}
                            buttonStyles='link-style no-underline force-100'
                            labelKey='Dox.DocumentUploader.CTAs.Retry'
                            onClick={handleRetryClick} />
                    </div>
                }

                {(!uploadError || uploadError === ErrorTypes.UNKNOWN_ERROR) &&
                    <div className='hrb-column centered'>
                    {isUploadComplete && !uploadError ?
                        <div className='checkmark'>
                            <DoxCheckMark />
                        </div>
                        :
                        (percentUploaded >= 100 && !isUploadComplete ?
                            <div className='checkmark'>
                                <FontAwesome className='fa-spinner' name='spinner' pulse />
                            </div>
                            :
                            <DoxButton
                                elementId={`cancel-upload-${fileName}`}
                                buttonStyles='link-style no-underline force-100'
                                labelKey='Dox.DocumentUploader.CTAs.Cancel'
                                onClick={handleCancelClick} />
                        )}
                    </div>
                }
            </div>
            <div className='hrb-columns'>
                {uploadError &&
                    <div className='hrb-column hrb-is-6'>
                        <p className='error'>{translateString(`Dox.DocumentUploader.ValidationErrors.${uploadError}`)}</p>
                    </div>
                }
            </div>
        </div>
    )
}

DoxUploadItem.propTypes = {
    queuedFile: shape({
        file: shape({
            lastModified: number,
            lastModifiedDate: date,
            name: string,
            size: number,
            type: string
        }),
        qPosition: number,
        readyToUpload: bool,
        isUploadComplete: bool
    }).isRequired,
    cancel: bool,
    layoutType: string
}

export default DoxUploadItem