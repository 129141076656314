import React, { Component } from 'react';
import PropTypes from 'prop-types'
import './BaseModal.css'
import classNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';

class BaseModal extends Component {

  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      show: this.props.show,
      hideHeader: this.props.hideHeader,
      isNarrow: this.props.isNarrow,
        onClose: this.props.onClose      
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.state.show !== newProps.show) {

      // show/hide
      this.setState({show: newProps.show});

      // if we're hiding, determine whether or not we want to fade out.
      if (!newProps.show) {
        // call close function
        if (this.props.onClose) {
          this.props.onClose();
        }
      } else {

      }
    }
    }

    handleScroll = (ev) => {
        ev.preventDefault();
    }

    handleClose = (ev) => {
        if (this.props.onClose) {
            this.props.onClose(ev);
        }
    }

  render() {
    let headerClass = classNames('base-modal-header', this.props.layoutType || '', {'base-modal-hide-header': this.props.hideHeader});
    let containerClass = classNames('base-modal-container', {'base-modal-narrow': this.props.isNarrow});
    return (
        <div>
            {this.state.show &&
            <div className="base-modal-background" onScroll={this.handleScroll} onTouchMove={this.handleScroll}>
                <div className={containerClass}>
                    <div className={headerClass}>
                        <div className="base-modal-exit-button" onClick={this.handleClose}>
                            <CloseIcon />
                        </div>
                    </div>
                    <div className="base-modal-content">
                        {this.props.children}
                    </div>
                </div>
          </div>
          }
        </div>
    )
  };
}

BaseModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    hideHeader: PropTypes.bool,
    isNarrow: PropTypes.bool,
    layoutType: PropTypes.string.isRequired
}

export default BaseModal;