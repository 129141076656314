import React from 'react';
import FontAwesome from 'react-fontawesome';
import HeaderLogo from './headerLogo'
import T from 'i18n-react';

const HeaderDesktop = props => {

    const handleButtonClick = (action) => {
        if (props.buttonHandler && props.appInitialized) {
            props.buttonHandler(action)
        }
    }

    const handleLangClick = (lang) => {
        if (props.langHandler) {
            props.langHandler(lang)
        }
    }

    var headerButtons = [];
    var headerStyle = "headerButtonStyle headerButtonMarginDesktop"
    if (!props.appInitialized) {
        headerStyle += " uninitialized"
    }

    if (props.loginComplete) {
        if (props.pathname && !props.pathname.startsWith('/register')) {
            headerButtons.push(
                <div className="headerButtonStyle headerButtonMarginDesktop" key="settingsButton"
                    onClick={(ev) => { handleButtonClick('settings') }}>
                    <FontAwesome className='fa-user' name='fa-user' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#14AA40', align: 'center' }} />
                    <span className="headerButtonTextStyle"><T.span text={{ key: 'HeaderMenuBar.labels.AccountSettings' }} /></span>
                </div>
            )
        }
        headerButtons.push(
            <div className="headerButtonStyle headerButtonMarginDesktop" key="logoutButton"
                onClick={(ev) => { handleButtonClick('logout') }}>
                <FontAwesome className='fa-sign-out' name='fa-sign-out' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#14AA40', align: 'center' }} />
                <span className="headerButtonTextStyle">{T.translate('HeaderMenuBar.labels.SignOut')}</span>
            </div>
        )
    } else {
        headerButtons.push(
            <div className={headerStyle} key="loginButton"
                onClick={(ev) => { handleButtonClick('login') }}>
                <FontAwesome className='fa-user' name='fa-user' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', align: 'center' }} />
                <span className="headerButtonTextStyle">{T.translate('Common.SignIn')}</span>
            </div>
        )
    }

    var enLangButtonStyle = 'languageButton'
    var frLangButtonStyle = 'languageButton'
    if (props.lang === 'fr') {
        enLangButtonStyle += ' unselected'
        frLangButtonStyle += ' selected'
    } else {
        enLangButtonStyle += ' selected'
        frLangButtonStyle += ' unselected'
    }

    return (
        <div className="headerStyleDefault">
            <div className="headerDesktopContainer">
                <div className="headerDesktopInnerContainer">
                    <div className="headerLeft desktopMarginLeft">
                        <HeaderLogo height='28px' width='161px' darkText onClick={() => handleButtonClick('home')} />
                        {props.showOteContent &&
                            <h1 className="headerAppName desktopNameLeftMargin">{T.translate('AppName')}{T.translate('AppTag')}</h1>
                        }
                    </div>
                    <div className="headerRight desktopMarginRight">
                        {props.loaded ? headerButtons : ""}
                        <div style={{ width: 20 }} />
                        <div className={enLangButtonStyle}
                            onClick={() => handleLangClick('en')}>
                            <span>En</span>
                        </div>
                        <div className={frLangButtonStyle}
                            onClick={() => handleLangClick('fr')}>
                            <span>Fr</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HeaderDesktop;