import React, { useState } from 'react';
import classnames from 'classnames';
import './../../../Common/shared.css';
import './../../../Common/iconCheckButton.css';
import './style.css'
import infoIcon from '../../../../images/TaxScenarios/info.svg'
import selectedIcon from '../../../../images/TaxScenarios/selectedIcon.svg'

const TaxScenarioTile = (props) => {
  const [checked, setChecked] = useState(props.checked);

  const onCheck = () => {
    const isChecked = !checked;
    setChecked(isChecked);
    if (props.onCheckChanged) {
      const target = { name: props.name };
      const value = isChecked;
      props.onCheckChanged({ target }, value);
    }
  };

  const showMobileInfoTooltip = (event) => {
    event.stopPropagation();
    const tooltip = document.querySelector('.scenario-tooltip');
    tooltip.classList.toggle('show-tooltip');
  }
 
  return (
    <div className=''>
        <div className={checked ? 'scenario-icon-checked' : 'scenario-icon'} onClick={onCheck}>
        <div className='scenario-icon-inner'>
          <div>
            <div className='info-icon' onClick={showMobileInfoTooltip}>
              <img src={infoIcon}/>
            </div>
            <span className={classnames('scenario-tooltip', {'scenario-tooltip-left' : props.leftTooltip}, {'scenario-tooltip-large' : props.largeTooltip}, {'scenario-mobile-tooltip-left' : props.mobileLeftTooltip})}>{props.tooltipText}</span>
          </div>
          <div>
            <img src={checked ? selectedIcon : props.ScenarioImg} className='scenario-img' />
          </div>
          <div className='scenario-icon-label'>
            <span>{props.label}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaxScenarioTile;