import apisauce from 'apisauce'
import { addErrorHandler, getApiBaseUrl } from './serviceUtils'

/**
 * API for managing workflow information
 * @param {String} baseURL
 */
const create = (baseURL = getApiBaseUrl()) => {  // this will change for prod deployment
    // Create and configure an apisauce-based api object.
    const api = apisauce.create({
        baseURL,
        headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Content-Type': 'application/json' },
        timeout: 10 * 60 * 1000   // this should be long as the Trulioo call may take a while
    })

    addErrorHandler(api)

    const getAuthHeader = (accessToken) => {
        return {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        }
    }

    const confirmIdentity = (accessToken, target, extraAddresses, skipValidation) => {
        var payload;
        if (typeof skipValidation === "boolean") {
            payload = {
                target: target,
                extraAddresses: extraAddresses,
                skipValidation
            }
        } else {
                payload = {
                    target: target,
                    extraAddresses: extraAddresses
                }
            }
        return api.post('confirmidentity', payload, getAuthHeader(accessToken))
    }

    const confirmIdentityDocuments = (accessToken, target, imageType) => {
        var payload = {
            target: target,
            imageType: imageType
        }
        return api.post('confirmidentity/confirmation', payload, getAuthHeader(accessToken))
    }

    const getConfirmDocumentStatus = (accessToken, target) => {
        var payload = {
            target: target,
            extraAddresses: []
        }
        return api.post('confirmidentity/getconfirmation', payload, getAuthHeader(accessToken))
    }

    const uploadFile = (accessToken, target, idType, isFront, imageData, progressFn) => {
        var payload = {
            target: target,
            imageType: idType,
            isFront: isFront,
            imageData: imageData
        }
        var authHeader = getAuthHeader(accessToken)
        var progressInfo = {
            ...authHeader,
            onUploadProgress: value => progressFn(value)
        }
        return api.post('confirmidentity/confirmation/image', payload, progressInfo)
    }

    const getConfirmIdState = (accessToken) => {
        return api.get('confirmidentity', '', getAuthHeader(accessToken))
    }

    const getDocVUrl = (isSpouse, accessToken) => {       

        return api.get(`confirmidentity/confirmation/${isSpouse}/getUrl`, '', getAuthHeader(accessToken))
    }

    const getDocVStatus = (verificationSessionId, accessToken) => {
        return api.get(`confirmidentity/confirmation/${verificationSessionId}/getStatus`, '', getAuthHeader(accessToken))
    }

    const completeQrCodeVerification = (result, isSpouse, isCoupled, accessToken) => {
        var payload = {
            result: result,
            isCoupled: isCoupled,
            isSpouse: isSpouse
        }
        return api.post('confirmidentity/confirmation/completeIdVerification', payload, getAuthHeader(accessToken))
    }

    // Return back a collection of functions
    return {
        confirmIdentity, uploadFile, confirmIdentityDocuments, getConfirmIdState, getConfirmDocumentStatus, getDocVUrl, getDocVStatus, completeQrCodeVerification
    }
}

// let's return back our create method as the default.
export default {
    create
}

