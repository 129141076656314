import React, { Component } from 'react';
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
//import BaseModal from '../Modal/BaseModal';
import T from 'i18n-react';
import HelpCentre from '../../sagas/Help/helpCentre'
import { getTaxYearState } from '../../utils/CaseStateUtilities'
import '../AuthorizeHRB/style.css';
import './style.css'
import WorkflowActions from '../../reducers/workflowRedux'
import DocumentActions from '../../reducers/documentsRedux'
import ApproveDraftsActions from '../../reducers/approveDraftsRedux'
import PlainButton from '../Controls/plainButton'
import { setTimeout } from 'timers-browserify';
import UploaderModal from '../Documents/uploaderModal'
import { trackEvent } from '../../utils/AnalyticsUtil'

class ReviewAltPage3 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stage: props.stage,
            fileInViewer: null,
            missingFiles: false,
            missingFilesErrorVisible: false,
            processing: false,
            documentsApproved: false,
            uploadClicked: false,
            taxYear: this.props.taxYear
        }
    }

    componentWillMount() {
        if (!this.props.taxYearState) {
            this.props.replaceNavigation('/missing')
        }
    }

    componentDidMount() {
        var workflowState = this.props.workflow.case_state.taxYears.find(x => x.taxYear === Number(this.props.taxYear)).workflow_state        

        this.props.setLastReviewedPage(workflowState.case_id, 3, this.state.taxYear)
    }

    missingFile = (docs) => {
        if (!docs) {
            return true;
        }
        if (!docs.primaryFederal || !docs.primaryFederal.fileId) {
            return true;
        }
        if (this.props.taxYearState.coupled && (!docs.spouseFederal || !docs.spouseFederal.fileId)) {
            return true;
        }
        if (this.props.taxYearState.primary_province === 'QC' && (!docs.primaryQuebec || !docs.primaryQuebec.fileId)) {
            return true;
        }
        if (this.props.taxYearState.coupled && this.props.taxYearState.spouse_province === 'QC'
            && (!docs.spouseQuebec || !docs.spouseQuebec.fileId)) {
            return true;
        }
        return false;
    }   

    componentWillReceiveProps(newProps) {
        if (this.state.stage !== newProps.stage) {
            window.scrollTo(0, 0)
            this.setState({ stage: newProps.stage }) 
            // if we're changing the signing stage, go and do the signing
            if (newProps.stage === 'approveSpouse') this.goSignThings(newProps)
        }
        if (this.state.signedDocuments !== newProps.signedDocuments) {
            this.setState({ signedDocuments: newProps.signedDocuments })
        }
        if (this.state.signingState !== newProps.signingState) {
            this.setState({ signingState: newProps.signingState })
        }
        if (this.state.signingError !== newProps.signingError) {
            this.setState({ signingError: newProps.signingError })
            // VAI-476 show 
            if (newProps.signingError === 'filenotfound') this.setState({ missingFilesErrorVisible: true })
        }
        if (this.state.postLoginComplete !== newProps.postLoginComplete) {
            this.setState({ postLoginComplete: newProps.postLoginComplete })
            // VAI-458 copied code from componentDidMount to here (just in case)
            setTimeout(this.goSignThings(newProps), 100)
        }          
    }  

   

    setApprovalComplete() {
        this.setState({ processing: true })

        trackEvent('OteApprovalComplete')

        this.props.setApprovalComplete(this.props.taxYear);
    }

    onApproveClicked(e) {
        this.setApprovalComplete()
        this.props.sendReviewCompleteMessageWetSignature(this.props.case_state.taxYears.find(x => x.taxYear === Number(this.props.taxYear)).workflow_state.case_id)
        this.props.pushNavigation('/dashboard')
    }

    onFinishThisLaterClicked(e) {
        this.props.pushNavigation('/dashboard')
    }

    getLink(fileType) {
        var file = { ...this.props.approvalDocs[fileType] }
        return (file ? file.shareLink : null);
    }   

    onViewClicked(fileType) {
        var file = this.props.taxYearDocs[fileType]
        if (file && file.fileId) { 
            this.setState({ fileInViewer: file.fileId })
            this.props.setBoxDialogStates(false, false, true);
            this.props.setDocumentForReviewViewed(file.fileId, this.props.taxYear);
        } else {
            // show the box folder view
            this.setState({ fileInViewer: null })
            this.props.setBoxDialogStates(false, false, true);
        }

        var documents_reviewed = this.state.documentsReviewed
        documents_reviewed.forEach((document) => {
            if (document.type === fileType) {
                document.reviewed = true
            }
        })
        this.setState({ documentsReviewed: documents_reviewed })

    }    

    onMessageMyProClicked() {
        this.props.setInputScreenShowing(true)
        this.props.setDashboardTab('messages')
        this.props.pushNavigation('/dashboard')
    }

    handleBoxDialogClose() {
        this.props.setBoxDialogStates(false, false, false)
    }

    openChecklist() {
        HelpCentre.openHelpLink('ReviewingYourReturn/WhatShouldILookFor')
    }

    handleUploadClick(taxYear) {
        var boxFolderId = null
        var tax_year = Number(taxYear)

        for (var i = 0; i < this.props.case_state.taxYears.length; ++i) {
            if (this.props.case_state.taxYears[i].taxYear === tax_year) {
                boxFolderId = this.props.case_state.taxYears[i].boxFolderId
                break;
            }
        }
        this.setState({ selectedBoxFolderId: boxFolderId, uploaderTaxYear: taxYear, uploadClicked: true })
        this.props.setUploaderState(true, 'start');

    }

    handleUploadStateChange = (state) => {
        this.props.setUploaderState(true, state)
    }

    handleUploaderClose = (ev) => {
        this.props.setUploaderState(false, 'start')

        if (this.props.fileWasUploaded) {
            this.props.setFinishedUploading(this.state.uploaderTaxYear)
        }
    }

    handleFileUploaded = (ev) => {
        if (ev && ev.name === 'image.jpg' && (this.props.layoutType === 'mobile' || this.props.layoutType === 'tablet')) {
            this.props.setFileToBeRenamed(ev.id)
        }
        this.props.setFileWasUploaded(this.state.uploaderTaxYear)
    }

    render() {
       
        return (
             <div>                                                   
                <div className="authorizeContentStyle">
                    <h1>{T.translate('ReviewAndApproveAlternate.TitleUpload.AllReturns')}</h1>
                    <T.span text={{ key: 'ReviewAndApproveAlternate.HeaderUpload.All' }} />
                    <div style={{ height: 20 }} />
                </div>
                <div className="authorizeContentStyle" style={{ marginTop: 10 }}>
                    <PlainButton inverted={false} onClick={(ev) => this.handleUploadClick(this.state.taxYear, ev)} labelKey='ReviewAndApproveAlternate.UploadSignedForms' isEnabled={true} inProgress={this.state.processing} />
                </div>                                          

                <div style={{ margin: '120px' }} />

                <div className="authorizeContentStyle" style={{ marginBottom: 15 }}>
                    <T.span text={{ key : 'ReviewAndApproveAlternate.LastMessagePage3' }} />
                </div>  

                <div className="reviewAltBottomContainer" style={{ marginTop: 10 }}>
                    <PlainButton inverted={true} onClick={(e) => this.onFinishThisLaterClicked(e)} labelKey='ReviewAndApproveAlternate.FinishLater' isEnabled={true} />
                    <div style={{width: '20px', height: '10px'}} />
                    <PlainButton inverted={false} onClick={(e) => this.onApproveClicked(e)}
                        labelKey={'Common.Done'}
                        isEnabled={Boolean(this.state.uploadClicked)}
                        inProgress={this.state.processing}
                    />
                </div>
                <UploaderModal uploaderShowing={this.props.uploaderState.uploaderShowing} lang={this.props.lang}
                    layoutType={this.props.layoutType} uploaderState={this.props.uploaderState.uploaderState}
                    folderId={this.state.selectedBoxFolderId}
                    mobilePhone={this.props.mobilePhone}
                    dialogCloseHandler={this.handleUploaderClose}
                    fileUploadedHandler={this.handleFileUploaded}
                    uploadStateChangeHandler={this.handleUploadStateChange}
                />                         
            </div>
        ) 
    }
}

const mapStateToProps = (state, ownProps) => {    
    return {
        accountId: state.accountData.accountId,
        lang: state.layout.lang,
        layoutType: state.layout.layoutType,
        boxFolderId: state.documents.userBoxFolderId,
        taxYearDocs: state.files.documentsForReview[Number(ownProps.taxYear)],        
        taxYearState: getTaxYearState(Number(ownProps.taxYear), state.workflow.case_state),
        approvalDocs: state.files.approvalDocuments[Number(ownProps.taxYear)],
        signedDocuments: state.signing.signedDocuments,
        signingState: state.signing.signingState,
        signingError: state.signing.signingError,
        stage: state.approveDrafts.stage,
        workflow: state.workflow,
        documentsForReview: state.files.documentsForReview,
        case_state: state.workflow.case_state,
        mobilePhone: state.accountData.mobilePhone,
        uploaderState: {
            uploaderShowing: state.documents.uploaderShowing,
            viewerShowing: state.documents.viewerShowing,
            fileShowing: state.documents.fileShowing,
            uploaderState: state.documents.uploaderState
        },
        fileWasUploaded: Boolean(state.documents.fileWasUploaded)
    };
}

const mapDispatchToProps = (dispatch) => ({
    setApprovalComplete: (taxYear) => dispatch(ApproveDraftsActions.setApprovalComplete(taxYear)),
    pushNavigation: (path) => dispatch(push(path)),
    setButtonState: (buttonName, buttonState) => dispatch(WorkflowActions.setButtonState(buttonName, buttonState)),
    setBoxDialogStates: (uploaderShowing, viewerShowing, fileShowing) => dispatch(DocumentActions.setBoxDialogStates(uploaderShowing, viewerShowing, fileShowing)),
    setApprovalStage: (stage) => dispatch(ApproveDraftsActions.setApprovalStage(stage)),
    setLastReviewedPage: (caseId, pageNumber, taxYear) => dispatch(WorkflowActions.setLastReviewedPage(caseId, pageNumber, taxYear)),
    setUploaderState: (uploaderShowing, uploaderState) => dispatch(DocumentActions.setUploaderState(uploaderShowing, uploaderState)),
    setFileWasUploaded: (taxYear) => dispatch(DocumentActions.setFileWasUploaded(taxYear)),
    sendReviewCompleteMessageWetSignature: (caseId) => dispatch(DocumentActions.sendReviewCompleteMessageWetSignature(caseId)),
    setCaseSetting: (taxYear, setting, value) => dispatch(WorkflowActions.setCaseSetting(taxYear, setting, value)),
    setFinishedUploading: (taxYear) => dispatch(DocumentActions.setFinishedUploading(taxYear))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReviewAltPage3)