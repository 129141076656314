import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { push } from 'connected-react-router'
import './workflowpanel.css'
import LayoutActions from '../../../reducers/layoutRedux'
import DocumentsActions from '../../../reducers/documentsRedux'
import WorkflowActions from '../../../reducers/workflowRedux'
import PaymentActions from '../../../reducers/paymentRedux'
import ApproveDraftsActions from '../../../reducers/approveDraftsRedux'
import SigningActions from '../../../reducers/signingRedux'
import AuthorizeCRAActions from '../../../reducers/authorizeCRARedux'
import ProductActions from '../../../reducers/productsRedux'
import TaxYearSection from '../TaxYearSection/TaxYearSection'
import ProfileSection from '../ProfileSection/ProfileSection'
import POMSection from '../POM/POMSection';
import CreateNewReturnSection from './CreateNewReturnSection'
import AssignedTaxProBlock from '../Sidebar/AssignedTaxProBlock'
import UploaderModal from '../../Documents/uploaderModal'
import DeleteReturnModal from './DeleteReturnModal'
import LoadingSpinner from '../../Loading/loadingSpinner'
import AdditionalDocumentsSection from '../AdditionalDocuments/AdditionalDocumentsSection'
import BaseModal from '../../Modal/BaseModal'
import InfoDialogContent from '../../Messages/infoDialogContent'
import { ValidTicketTypes } from '../../../hooks/useTicketTypeHook';
import {
    getTaxYearState,
    getMostRecentPrimaryInterviewTaxYearState,
    getConfirmAuthorizeState,
    getAssignedTaxPros
} from '../../../utils/CaseStateUtilities'
import { HeapEvents, trackEvent } from '../../../utils/AnalyticsUtil'
import DashboardHeader from './DashboardHeader';


class WorkflowPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            createReturnInProgress: false,
            uploaderTaxYear: null,
            selectedBoxFolderId: null,
            fileWasUploaded: false,
            taxYearToDelete: 0,
            showDeleteTaxYearModal: false,
            deletingCase: false
        }
    }

    componentWillMount() {
        if (this.props.signingState === 'in_progress' || this.props.signingState === 'complete') {
            // this will be 'in-progress' if the user hits back from esignature, or 'complete' 
            // if there were no errors.  In either case, we're done with it so clean up
            this.props.signingStateReset()
        }
        // As a client can only be assigned to one office at a time for an active case(s), so pick first
        var activeCase = this.props.case_state.taxYears[0];
        this.props.getAddOnProducts(activeCase?.workflow_state?.case_id);
    }

    onStartNewReturn = () => {
        this.props.pushNavigation('/dashboard/interview/year_selection')
    }

    handleWorkflowButton = (workflowAction, taxYear, contextData) => {
        switch (workflowAction || '') {
            case 'interview':
                this.props.pushNavigation('/dashboard/interview/' + taxYear + '/' + (contextData || '1'));
                break;
            case 'download':
                this.props.pushNavigation('/dashboard/completed_returns/' + taxYear);
                trackEvent(HeapEvents.DASHPG_DOWNLOAD_RETURN + '-' + taxYear);
                break;
            case 'confirm':
                this.props.pushNavigation('/dashboard/confirm_id');
                break;
            case 'authorize':
                this.props.authorizeReset();
                this.props.pushNavigation('/dashboard/authorize');
                break;
            case 'review-and-approve':
                var lastreviewedpage = this.props.case_state.taxYears.find(x => x.taxYear === taxYear).workflow_state.lastreviewedpage
                if (lastreviewedpage === 0)
                    lastreviewedpage = 1

                this.props.approveReset();
                this.props.signingStateDocumentsReset();

                this.props.review_and_approve_paper_signature
                    ? this.props.pushNavigation('/dashboard/reviewalt/' + taxYear + '/' + lastreviewedpage)
                    : this.props.pushNavigation('/dashboard/review/' + taxYear);
                break;
            case 'upload-docs-confirm':
                var state = contextData;
                if (state === 'no') {
                    this.props.setUploadStepToWorking(taxYear)
                    this.props.setUploadStepCompleted(taxYear, true);
                }
                break;
            case 'upload-docs':
                var comingFrom = contextData.comingFrom;
                this.props.pushNavigation(`/dashboard/uploader/${taxYear}/${comingFrom}?source=dashboard`)

                break;
            case 'payment':
                trackEvent(HeapEvents.PRIMARY_PAYMENT_LINK);
                window.open(contextData, "_self");
                break;
            case 'delete-return':
                this.props.setCaseDeletedStatus(null, null)
                this.setState({ deletingCase: false, taxYearToDelete: taxYear, showDeleteTaxYearModal: true });
                break;
            case 'additional_docs':
                this.props.signingStateDocumentsReset();
                this.props.signAdditionalForm(taxYear, contextData.document_type, contextData.is_spouse, '/dashboard', contextData.spouseFirstName, contextData.primaryFirstName);
                break;
            default:
                break;
        }

        this.props.updateCaseState();
    }

    fadeOut = () => {}

    handleUploaderClose = (ev) => {
        // determine whether we need to show the reminder
        var boxUploaderShowing = this.props.uploaderState.uploaderState === 'box_uploader' ||
            (this.props.uploaderState.uploaderState === 'start' && this.props.layoutType !== 'desktop')

        if (boxUploaderShowing && !this.props.haveViewedReminder && this.props.fileWasUploaded) {
            this.props.setUploaderState(true, 'docs_reminder')
            this.props.setCaseSetting(this.state.uploaderTaxYear, 'UploadReminderViewed', 'true')
        } else {
            this.props.setUploaderState(false, 'start')
        }
        if (this.props.fileWasUploaded) {
            this.props.setFinishedUploading(this.state.uploaderTaxYear)
            if (this.props.uploadButtonState === 'Show') {
                this.props.setButtonState('upload_docs', 'Confirm')
            }
        }
    }

    handleUploadStateChange = (state) => {
        this.props.setUploaderState(true, state)
    }

    handleFileUploaded = (ev) => {
        if (ev && ev.name === 'image.jpg' && (this.props.layoutType === 'mobile' || this.props.layoutType === 'tablet')) {
            this.props.setFileToBeRenamed(ev.id)
        }
        this.props.setFileWasUploaded(this.state.uploaderTaxYear)
    }

    deleteTaxYear = (taxYear) => {
        this.setState({ deletingCase: true })
        this.props.deleteCase(taxYear);
    }

    handleScheduleCallClick = (taxYear) => {
        this.props.scheduleCallAttempt(taxYear);
    }

    render() {
        if (!this.props.case_state) {
            return <LoadingSpinner />
        } else {
            var primaryInterviewTaxYearState = getMostRecentPrimaryInterviewTaxYearState(this.props.case_state)
            var currentTaxYearState = getTaxYearState(this.props.currentTaxYear, this.props.case_state)

            var showCreateNewReturnSection = currentTaxYearState === null
                || (!currentTaxYearState.interview_state.complete && currentTaxYearState.interview_state.current_page === '1' && currentTaxYearState.interview_state.completed_date === null)
                || (this.props.case_state != null && this.props.case_state.taxYears !== null ? this.props.case_state.taxYears.length < (this.props.currentTaxYear - 2019) : false)
            
                var showConfirm = this.props.confirmAuthorizeState ? this.props.confirmAuthorizeState.primaryShowConfirm || this.props.confirmAuthorizeState.spouseShowConfirm : false;
            var showAuthorize = this.props.confirmAuthorizeState
                ? this.props.confirmAuthorizeState.primaryShowAuthorize
                || this.props.confirmAuthorizeState.primaryAuthorizeAddedQC
                || this.props.confirmAuthorizeState.spouseShowAuthorize
                : false;
            //if @ any year customer had VIR tax - it is VIR customer
            var virCustomer = this.props.case_state.taxYears?.filter(o => o.ticket_type.toUpperCase() === 'VIR')?.length > 0;
            var showSecondaryButton = this.props.case_state.taxYears?.filter(o=> o.interview_state.complete===true)?.length >= 1;

            return (
                <div className='workflow-panel-container'>                    
                    <DashboardHeader 
                        firstName={this.props.firstName} 
                        firstLogin={this.props.firstLogin} 
                        showSecondaryButton={showSecondaryButton} 
                        showCreateNewReturnSection={showCreateNewReturnSection}
                        onClick={this.onStartNewReturn} 
                        inProgress={this.state.createReturnInProgress} 
                        virCustomer={virCustomer} 
                    />
                    
                    {primaryInterviewTaxYearState !== null &&
                        <ProfileSection confirmAuthorizeState={this.props.confirmAuthorizeState}
                            onWorkflowButtonAction={this.handleWorkflowButton}
                            onFadeOut={this.fadeOut}
                            ticketType={virCustomer ? ValidTicketTypes.VIR : ValidTicketTypes.VDO} />
                    }
                    {this.props.case_state.taxYears?.map((x) => (
                        <Fragment key={'ty' + x.taxYear}>
                            <TaxYearSection securityComplete={!showConfirm && !showAuthorize}
                                taxState={x} lang={this.props.lang}
                                currentYear={this.props.currentTaxYear}
                                onWorkflowButtonAction={this.handleWorkflowButton}
                                paymentInfo={this.props.paymentInfo}
                            />
                            <AdditionalDocumentsSection lang={this.props.lang}
                                securityComplete={!showConfirm && !showAuthorize}
                                taxState={x} currentYear={this.props.currentTaxYear}
                                onWorkflowButtonAction={this.handleWorkflowButton}
                                layoutType={this.props.layoutType}
                            />
                        </Fragment>
                    ))}
                    <POMSection layoutType={this.props.layoutType}
                        firstName={this.props.firstName} addOnProducts={this.props.addOnProducts}
                    />
                    {this.props.layoutType === 'mobile' &&
                        <AssignedTaxProBlock layoutType={this.props.layoutType}
                            assigned_tax_pros={this.props.assigned_tax_pros} scheduleCallClick={this.handleScheduleCallClick} />
                    }
                    <UploaderModal uploaderShowing={this.props.uploaderState.uploaderShowing} lang={this.props.lang}
                        layoutType={this.props.layoutType} uploaderState={this.props.uploaderState.uploaderState}
                        folderId={this.state.selectedBoxFolderId}
                        mobilePhone={this.props.mobilePhone}
                        dialogCloseHandler={this.handleUploaderClose}
                        fileUploadedHandler={this.handleFileUploaded}
                        uploadStateChangeHandler={this.handleUploadStateChange}
                    />
                    <DeleteReturnModal taxYear={this.state.taxYearToDelete}
                        visible={this.state.showDeleteTaxYearModal && (this.props.case_delete_status == null || this.props.case_delete_status.status == null)}
                        inProgress={this.state.deletingCase}
                        onCancel={() => this.setState({ showDeleteTaxYearModal: false })}
                        onDelete={this.deleteTaxYear}
                        lang={this.props.lang} layoutType={this.props.layoutType}
                    />
                    <BaseModal show={Boolean(this.props.isAdditional) && Boolean(this.props.signingError)} onClose={() => { this.props.signingStateReset(); }} layoutType={this.props.layoutType} >
                        <InfoDialogContent
                            titleKey='ReviewAndApprove.MissingDocsError.Title'
                            labelKeys={['ReviewAndApprove.MissingDocsError.Label', 'ReviewAndApprove.MissingDocsError.SubLabel']}
                            onOK={() => { this.props.signingStateReset(); }}
                            onCancel={() => { this.props.signingStateReset(); }}
                            onCloseDialog={() => { this.props.signingStateReset(); }}
                            isWarning faIconName='fa-exclamation'
                        />
                    </BaseModal>
                    {(showCreateNewReturnSection && virCustomer) &&
                        (<CreateNewReturnSection onClick={this.onStartNewReturn} inProgress={this.state.createReturnInProgress} virCustomer={virCustomer} />)
                    }
                </div>
            )
        }
    }
}

WorkflowPanel.propTypes = {
    assigned_tax_pros: PropTypes.arrayOf(PropTypes.shape({
        taxYear: PropTypes.number.isRequired,
        assigned_tax_pro: PropTypes.shape({
            name: PropTypes.string.isRequired,
            calendar_link: PropTypes.string
        })
    })).isRequired,
    case_delete_status: PropTypes.object,
    case_state: PropTypes.shape({
        authorize: PropTypes.shape({
            self: PropTypes.shape({
                choice: PropTypes.string.isRequired,
                cra: PropTypes.string.isRequired,
                rq: PropTypes.string.isRequired
            }).isRequired,
            spouse: PropTypes.shape({
                choice: PropTypes.string.isRequired,
                cra: PropTypes.string.isRequired,
                rq: PropTypes.string.isRequired
            }).isRequired
        }),
        confirm_id: PropTypes.shape({
            self: PropTypes.string.isRequired,
            spouse: PropTypes.string.isRequired
        }),
        currentTaxYear: PropTypes.number,
        taxYears: PropTypes.arrayOf(PropTypes.shape({
            taxYear: PropTypes.number.isRequired,
            ticketState: PropTypes.string,
            coupled: PropTypes.bool.isRequired,
            primary_province: PropTypes.string,
            primary_firstName: PropTypes.string.isRequired,
            spouse_province: PropTypes.string,
            spouse_firstName: PropTypes.string,
            interview_state: PropTypes.shape({
                complete: PropTypes.bool.isRequired,
                current_page: PropTypes.string.isRequired
            }).isRequired,
            subTotal: PropTypes.number,
            tenderTotal: PropTypes.number
        })),
        unreadMessagesCount: PropTypes.number.isRequired
    }).isRequired,
    confirmAuthorizeState: PropTypes.object.isRequired,
    firstName: PropTypes.string.isRequired,
    hasCurrentYearReturn: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    layoutType: PropTypes.string.isRequired,
    mobilePhone: PropTypes.string.isRequired,
    paymentStatus: PropTypes.string,
    uploaderState: PropTypes.shape({
        uploaderShowing: PropTypes.bool.isRequired,
        viewerShowing: PropTypes.bool.isRequired,
        fileShowing: PropTypes.bool.isRequired,
        uploaderState: PropTypes.string
    })
}

const hasCurrentYearReturn = (caseState, currentYear) => {
    if (caseState.taxYears) {
        for (var i = 0; i < caseState.length; ++i) {
            if (caseState[i].taxYear === currentYear) {
                return true;
            }
        }
    }

    return false;
}

const mapStateToProps = (state) => {
    return {
        layoutType: state.layout.layoutType,
        lang: state.layout.lang,
        firstLogin: state.auth.firstLogin,
        case_state: state.workflow.case_state,
        firstName: state.accountData.firstName,
        mobilePhone: state.accountData.mobilePhone,
        hasCurrentYearReturn: hasCurrentYearReturn(state.workflow.case_state, state.layout.currentTaxYear),
        currentTaxYear: state.layout.currentTaxYear,
        uploaderState: {
            uploaderShowing: state.documents.uploaderShowing,
            viewerShowing: state.documents.viewerShowing,
            fileShowing: state.documents.fileShowing,
            uploaderState: state.documents.uploaderState
        },
        haveViewedReminder: Boolean(state.workflow.caseSettings && state.workflow.caseSettings.UploadReminderViewed === 'true'),
        fileWasUploaded: Boolean(state.documents.fileWasUploaded),
        confirmAuthorizeState: getConfirmAuthorizeState(state.workflow.case_state),
        case_delete_status: state.workflow.case_delete_status,
        assigned_tax_pros: getAssignedTaxPros(state.workflow.case_state, state.layout.currentTaxYear),
        paymentStatus: state.payment.paymentStatus,
        paymentInfo: state.payment.paymentInfo,
        lastReviewedPage: state.workflow.last_reviewed_page,
        review_and_approve_paper_signature: state.config.appConfig.reviewandapprovepapersignature,
        isAdditional: state.signing.isAdditional,
        signingError: state.signing.signingError,
        signingState: state.signing.signingState,
        uploadButtonState: state.workflow.upload_docs,
        addOnProducts: state.products.addOnProducts
    }
}

const mapDispatchToProps = (dispatch) => ({
    setDashboardTab: (tab) => dispatch(LayoutActions.setDashboardTab(tab)),
    pushNavigation: (path) => dispatch(push(path)),
    setUploaderState: (uploaderShowing, state) => dispatch(DocumentsActions.setUploaderState(uploaderShowing, state)),
    setFileWasUploaded: (taxYear) => dispatch(DocumentsActions.setFileWasUploaded(taxYear)),
    setFileToBeRenamed: (fileId) => dispatch(DocumentsActions.setFileToBeRenamed(fileId)),
    setCaseSetting: (taxYear, setting, value) => dispatch(WorkflowActions.setCaseSetting(taxYear, setting, value)),
    setUploadStepCompleted: (taxYear, isComplete) => dispatch(DocumentsActions.setUploadStepCompleted(taxYear, isComplete)),
    showPaymentDialog: (showDialog, taxYear) => dispatch(PaymentActions.showPaymentDialog(showDialog, taxYear)),
    deleteCase: (taxYear) => dispatch(WorkflowActions.deleteCase(taxYear)),
    setCaseDeletedStatus: (status, errorCode) => dispatch(WorkflowActions.setCaseDeletedStatus(status, errorCode)),
    updateCaseState: () => dispatch(WorkflowActions.updateCaseState()),
    approveReset: () => dispatch(ApproveDraftsActions.approveReset()),
    signingStateDocumentsReset: () => dispatch(SigningActions.signingStateDocumentsReset()),
    signingStateReset: () => dispatch(SigningActions.signingStateReset()),
    authorizeReset: () => dispatch(AuthorizeCRAActions.authorizeReset()),
    scheduleCallAttempt: (taxYear) => dispatch(WorkflowActions.scheduleCallAttempt(taxYear)),
    signAdditionalForm: (taxYear, formName, isSpouse, completedRoute, spouseFirstName, primaryFirstName) => dispatch(SigningActions.signAdditionalForm(taxYear, formName, isSpouse, completedRoute, spouseFirstName, primaryFirstName)),
    setUploadStepToWorking: (taxYear) => dispatch(WorkflowActions.setUploadStepToWorking(taxYear)),
    setFinishedUploading: (taxYear) => dispatch(DocumentsActions.setFinishedUploading(taxYear)),
    setButtonState: (buttonName, buttonState) => dispatch(WorkflowActions.setButtonState(buttonName, buttonState)),
    getAddOnProducts: (caseId) =>  { caseId != null ? dispatch(ProductActions.getAddOnProducts(caseId)) : dispatch(ProductActions.clearAddOnProducts())},
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPanel)
