import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types'
import './style.css';
import T from 'i18n-react';
import PlainButton from '../Controls/plainButton';
import LoadingSpinner from '../Loading/loadingSpinner'
import HelpCentre from '../../sagas/Help/helpCentre'
import AuthorizeCRAMyAccount_RQ_EN from '../../resources/Authorize with My Account_RQ_EN.pdf'
import AuthorizeCRAMyAccount_RQ_FR from '../../resources/Authorize with My Account_RQ_FR.pdf';
class AuthorizeMyAccountRq extends Component {
    componentWillMount() {
        if (this.props.craBusinessNumber == null) {
            this.props.loadAuthorizationConfig()
        }
    }

    handleClick(ev) {
        HelpCentre.openHelpLink("AuthorizingHRBlock/HowToAuthorizeHRBInRQMyAccount")
    }

    jumpToMessage() {
        if (this.props.onJumpToMessages) {
            this.props.onJumpToMessages();
        }
    }

    onLinkClick() {
        if (this.props.craLinkClicked) {
            this.props.craLinkClicked(true)
        }
        window.open(this.props.myAccountLinkRq, '_blank');
    }

    openCRARQLink(){           
        if (this.props.lang.toUpperCase() === 'EN') {
            window.open(AuthorizeCRAMyAccount_RQ_EN);
        } else {
            window.open(AuthorizeCRAMyAccount_RQ_FR);
        }
    }

    render() {
        var rememberAlign = ''
        if (this.props.layoutType === 'desktop') {
            rememberAlign = 'authorizeLeftAlign'
        } else {
            rememberAlign = 'authorizeRightAlign'
        }

        var title1to5content = null
        var title5content = null
        if (this.props.layoutType === 'mobile') {
            title5content = (
                <span>
                    <T.span className="newlineText" text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle5' }} />
                    <br /><T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle5Info.a', AddrPart: this.props.rqConfig.repstreetno || '' }} />
                    <br /><T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle5Info.b', AddrPart: this.props.rqConfig.repstreetname || '' }} />
                    {this.props.rqConfig.repaptno &&
                        <Fragment>
                        <br /><T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle5Info.z', AddrPart: this.props.rqConfig.repaptno || '' }} />
                        </Fragment>
                    }
                    <br /><T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle5Info.c', AddrPart: this.props.rqConfig.repcity || '' }} />
                    <br /><T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle5Info.d', AddrPart: this.props.rqConfig.reppostal || '' }} />
                    <br /><T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle5Info.e', AddrPart: this.props.rqConfig.repprovince || 'QC' }} />
                    <br /><T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle5Info.f' }} />
                </span>
            )
            title1to5content = (
                <div className="authorizeListStyle">
                    <span>
                        <T.span className="newlineText" text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle1' }} />
                        <T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle1b', businessName: this.props.rqConfig.businessname }} />
                    </span>
                    <span>
                        <T.span className="newlineText" text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle2' }} />
                        <T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle2b'}} />
                    </span>
                    <span>
                        <T.span className="newlineText" text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle3' }} />
                        <T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle3b', businessNumber: this.props.rqConfig.neq  }} />
                    </span>
                    <span>
                        <T.span className="newlineText" text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle4' }} />
                        <T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle4b' }} />
                    </span>
                    <span>
                        {title5content}
                    </span>
                </div>
            )
        } else {
            title5content = (

                <div style={{ display: '-webkit-box', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <div><T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle5' }} /></div>

                    <div style={{ marginLeft: '-225px' }}>
                        <br /><T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle5Info.a', AddrPart: this.props.rqConfig.repstreetno || '' }} />
                        <br /><T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle5Info.b', AddrPart: this.props.rqConfig.repstreetname || '' }} />
                        {this.props.rqConfig.repaptno &&
                            <Fragment>
                                <br /><T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle5Info.z', AddrPart: this.props.rqConfig.repaptno || '' }} />
                            </Fragment>
                        }
                        <br /><T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle5Info.c', AddrPart: this.props.rqConfig.repcity || '' }} />
                        <br /><T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle5Info.d', AddrPart: this.props.rqConfig.reppostal || '' }} />
                        <br /><T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle5Info.e', AddrPart: this.props.rqConfig.repprovince || 'QC' }} />
                        <br /><T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle5Info.f' }} />
                    </div>
                </div>
            )
            title1to5content = (
                <div style={{ marginBottom: "20px" }}>
                    <ul className="innerAuthorizeListItem">
                        <li><T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle1' }} />
                            <span>&nbsp;</span>
                            <T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle1b', businessName: this.props.rqConfig.businessname }} />
                        </li>
                        <li><T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle2' }} />
                            <span>&nbsp;</span>
                            <T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle2b' }} />
                        </li>
                        <li><T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle3' }} />
                            <span>&nbsp;</span>
                            <T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle3b', businessNumber: this.props.rqConfig.neq }} />
                            <span className="authLearnMoreMargin" />
                        </li>
                        <li><T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle4' }} />
                            <span>&nbsp;</span>
                            <T.span text={{ key: 'Authorize.MyAccountRq.Step5Info.InfoTitle4b' }} />
                        </li>

                        <li>{title5content}</li>
                    </ul>
                </div>
            )
        }

        var numberControlOffset = this.props.rqConfig.repaptno ? 17 : 0;
        var spacerValue = '20px'
        if (this.props.layoutType === 'tablet' || this.props.layoutType === 'small_tablet') {
            spacerValue = '40px'
        }
        return this.props.craBusinessNumber == null ? (
            <div className="authorizeMyAccountContainerStyle">
                <div className="authorizeContentStyle">
                    <LoadingSpinner />
                </div>
            </div>
        ) : (
            <div className="authorizeMyAccountContainerStyle">
                <div className="authorizeContentStyle">
                    <h1>{T.translate('Authorize.MyAccountRq.Title', { FirstName: this.props.firstName })} </h1>
                    <div className="authorizeContentSquishy">
                        <T.span text={{ key: this.props.isSpouse ? 'Authorize.MyAccountRq.SubTitleSpouse' : 'Authorize.MyAccountRq.SubTitle', FirstName: this.props.firstName }} />
                    </div>
                    <div className="authorizeProgressList" style={this.props.lang === "fr" ? { marginTop: '5px' } : {}}>
                        <div className="authorizeProgressContainer">
                            <svg width="60px" height="790">
                                <rect fill="#14AA40" x="29" y="30" width="3" height="580" />
                                <circle cx="30" cy="30" r="16" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y="37" fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">1</text>
                                <circle cx="30" cy="85" r="16" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y="91" fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">2</text>
                                <circle cx="30" cy="145" r="16" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y="151" fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">3</text>
                                <circle cx="30" cy="185" r="16" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y="191" fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">4</text>
                                <circle cx="30" cy="225" r="16" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y="231" fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">5</text>
                                <circle cx="30" cy={492 + numberControlOffset} r="16" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y={498 + numberControlOffset} fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">6</text>
                                <circle cx="30" cy={540 + numberControlOffset} r="16" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y={546 + numberControlOffset} fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">7</text>
                                <circle cx="30" cy={597 + numberControlOffset} r="16" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y={603 + numberControlOffset} fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">8</text>
                            </svg>
                        </div>
                        <div className="authorizeListStyle">
                            <svg className="authorizeMobileList" width="60px" height="60px">
                                <circle cx="30" cy="30" r="20" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y="37" fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">1</text>
                            </svg>
                            <div className="authorizeListItem" style={this.props.layoutType === 'desktop' ? { marginBottom: "30px" } : {}}>
                                <span>
                                    <T.span text={{ key: this.props.isSpouse ? 'Authorize.MyAccountRq.Step1Spouse' : 'Authorize.MyAccountRq.Step1', FirstName: this.props.firstName }} />
                                </span>
                            </div>
                            <svg className="authorizeMobileList" width="60px" height="60px">
                                <circle cx="30" cy="30" r="20" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y="37" fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">2</text>
                            </svg>
                            <div className="authorizeListItem" style={this.props.layoutType === 'desktop' ? { marginBottom: "30px" } : {}}>
                                <T.span text={{ key: 'Authorize.MyAccountRq.Step2' }} />
                            </div>
                            <svg className="authorizeMobileList" width="60px" height="60px">
                                <circle cx="30" cy="30" r="20" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y="37" fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">3</text>
                            </svg>
                                <div className="authorizeListItem" style={this.props.layoutType === 'desktop' ? { marginBottom: "20px" } : {}}>
                                <T.span text={{ key: 'Authorize.MyAccountRq.Step3' }} />
                            </div>
                            <svg className="authorizeMobileList" width="60px" height="60px">
                                <circle cx="30" cy="30" r="20" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y="37" fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">4</text>
                            </svg>
                                <div className="authorizeListItem" style={this.props.layoutType === 'desktop' ? { marginBottom: "20px" } : {}}>
                                <T.span text={{ key: 'Authorize.MyAccountRq.Step4' }} />
                            </div>
                            <svg className="authorizeMobileList" width="60px" height="60px">
                                <circle cx="30" cy="30" r="20" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y="37" fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">5</text>
                                </svg>
                            <div className="authorizeListItem" style={this.props.layoutType === 'desktop' ? { marginBottom: "20px" } : {}}>
                                <T.span text={{ key: 'Authorize.MyAccountRq.Step5' }} />
                                </div>
                                {title1to5content}
                            <svg className="authorizeMobileList" width="60px" height="60px">
                                <circle cx="30" cy="30" r="20" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y="37" fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">6</text>
                                </svg>
                                <div className="authorizeListItem" style={this.props.layoutType === 'desktop' ? { marginBottom: "20px" } : {}}>
                                <T.span text={{ key: 'Authorize.MyAccountRq.Step6' }} />
                            </div>
                            <svg className="authorizeMobileList" width="60px" height="60px">
                                <circle cx="30" cy="30" r="20" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y="37" fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">7</text>
                            </svg>
                                <div className="authorizeListItem" style={this.props.layoutType === 'desktop' ? { marginBottom: "20px" } : {}}>
                                <T.span text={{ key: 'Authorize.MyAccountRq.Step7' }} />
                            </div>
                            <svg className="authorizeMobileList" width="60px" height="60px">
                                <circle cx="30" cy="30" r="20" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y="37" fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">8</text>
                            </svg>
                                <div className="authorizeListItem" style={this.props.layoutType === 'desktop' ? { marginBottom: "20px" } : {}}>
                                <T.span text={{ key: 'Authorize.MyAccountRq.Step8' }} />
                            </div>
                            <div style={{ marginTop: spacerValue }} />
                            <span className="rememberSpanStyle">
                                <T.span text={{ key: 'Authorize.MyAccountRq.InfoMore' }} />
                                <a className="authorizeLinkStyle" onClick={() => this.openCRARQLink()} rel="noopener noreferrer" download>{T.translate('Authorize.MyAccountRq.InfoMoreDoc')}</a>
                            </span>

                            <div style={{ marginTop: '10px' }} />

                            <div className="plainButtonWide" style={this.props.layoutType === 'mobile' ? { marginTop: '15px', marginBottom: '15px' } : { marginTop:'10px', fontSize: '16px !important', width: '600px !important' }}>
                                    <PlainButton class="button" labelKey="Authorize.MyAccountRq.Step1Link" isEnabled={true} onClick={() => this.onLinkClick()} extraClassName={this.props.craLinkVisited ? "isVisitedStyleWide" : "craButtonStyleWide"} />
                            </div>
                            <div style={{ marginBottom: '10px' }} />

                        </div>

                    </div>
                    <div style={this.props.layoutType === 'mobile' ? { width: '94%', height: '10px', borderBottom: '1px solid #000', marginBottom: '20px' } : {}} />
                </div>

                <div className={["authBottomMargin authorizeWideStyle", rememberAlign].join(' ')}>
                    <span className="authorizeFinePrint">
                        <span className="authorizeFinePrint">{T.translate('Authorize.MyAccountRq.Warning1.Ote')}</span>
                        <span className="learnMoreStyle" onClick={() => this.jumpToMessage()}>{T.translate('Authorize.MyAccountRq.Warning2.Ote')}</span>
                    </span>
                </div>
            </div>
        );
    }
}

AuthorizeMyAccountRq.propTypes = {
    lang: PropTypes.string.isRequired,
    layoutType: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    isSpouse: PropTypes.bool.isRequired,
    province: PropTypes.string.isRequired,
    craGroupId: PropTypes.string,
    craRepName: PropTypes.string,
    craBusinessNumber: PropTypes.string,
    craBusinessName: PropTypes.string,
    myAccountLink: PropTypes.string,    
    loadAuthorizationConfig: PropTypes.func.isRequired,
    rqConfig: PropTypes.object.isRequired
}

export default AuthorizeMyAccountRq;