import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import './authIconButton.css';
import T from 'i18n-react';

class AuthorizeIconButton extends Component {
    constructor(props) {
        super(props)
        this.state = {     
            hover: false,            
            icon: this.props.icon,
            label: this.props.label,
            labelContext: this.props.labelContext || {},
            size: this.props.size || '2x',
            isEnabled: Boolean(this.props.isEnabled),
            isComplete: Boolean(this.props.isComplete),
            layoutType: this.props.layoutType,
            baseStyle: this.props.baseStyle || 'defaultAuthorizeButtonStyle'
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.icon !== newProps.icon) {
            this.setState({icon: newProps.icon})
        }

        if (this.state.label !== newProps.label) {
            this.setState({ label: newProps.label })
        }

        if (this.state.labelContext && this.state.labelContext !== newProps.labelContext) {
            this.setState({ labelContext: newProps.labelContext })
        }

        if (this.state.size !== newProps.size && newProps.size) {
            this.setState({ size: newProps.size })
        }

        if (this.state.isEnabled !== Boolean(newProps.isEnabled)) {
            this.setState({ isEnabled: newProps.isEnabled })
        }

        if (this.state.isComplete !== Boolean(newProps.isComplete)) {
            this.setState({ isComplete: newProps.isComplete })
        }

        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }

        if (newProps.baseStyle && this.state.baseStyle !== newProps.baseStyle) {
            this.setState({ baseStyle: newProps.baseStyle })
        }
    }

    onMouseOver(event) {
        if (!this.state.hover) {
            this.setState({ hover: true })
        }
    }

    onMouseLeave(event) {
        if (this.state.hover) {
            this.setState({ hover: false })
        }
    }

    onButtonClick(event) {
        // raise the event for listeners
        if (this.state.isEnabled && !this.state.isComplete) {
            if (this.props.onClick) {
                this.props.onClick(event);
            }
        }
    }   

    render() {
        var iconColor = '#14AA40'
        var buttonClassName = this.state.baseStyle + " defaultAuthorizeButtonWidth unselectableLabel"
        if (this.state.layoutType === 'mobile') {
            buttonClassName += ' defaultAuthButtonHeightMobile'
        } else if (this.state.layoutType === 'tablet' || this.state.layoutType === 'small_tablet') {
            buttonClassName += ' defaultAuthButtonHeightTablet'
        } else {
            buttonClassName += ' defaultAuthButtonHeight'
        }
        if (this.state.isComplete) {
            buttonClassName += " authComplete"
            iconColor = '#FFF'
        } else if (this.state.isEnabled) {
            buttonClassName += this.state.hover ? " authHover" : " authNoHover"
            iconColor = this.state.hover ? '#FFF' : '#14AA40'
        } else {
            buttonClassName += " authDisabled"
            iconColor = '#A9A9A9'
        }

        var icon = this.state.isComplete  ? 'fa-check' : this.state.icon

        return (              
            <div onMouseOver={(event) => { this.onMouseOver(event) }}
                onMouseLeave={(event) => { this.onMouseLeave(event) }}
                className={buttonClassName}
                onClick={(event) => { this.onButtonClick(event) }} >
                <div className="authIconButtonLeft">
                    <FontAwesome
                        className={icon}
                        name={icon}
                        size={this.state.size}
                        style={{ paddingLeft: "0px", paddingRight: "0px", color: iconColor }}
                    />
                    </div>
                <div className="authIconButtonRight" >{T.translate(this.state.label, this.state.labelContext)}</div>
            </div>
        )       
    } 
}

export default AuthorizeIconButton;