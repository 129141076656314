import { createReducer, createActions } from 'reduxsauce'

/**
 * Set access token can be called at any point during the login process, other
 * components will not be notified about a user status change until userDidLogin
 * is called.  This should be called after logging in a user for both registration
 * and login.
 *
 * To log out a user, call logout user.  Other components can hook this in sagas to
 * perform clean-up, the auth saga will do the same and clean up the auth state after
 * the other components have had a change to use the access token
 */

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    requestLogin: ['redirect_url'],
    handleLoginCallback: null,
    setUserRoles: ['userRoles'],
    setLoginCallbackStatus: ['status'],
    finalizeLogin: null,
    doPostLoginSteps: null,
    setLoginComplete: ['complete'],
    setAccessToken: ['accessToken', 'expiryTime'],
    setFirstLogin: ['firstLogin'],
    setLastActivityTime: ['lastActivityTime'],
    // logout actions
    logoutUser: ['logoutType'],
    startPreLogoutAction: ['actionname'],
    donePreLogoutAction: ['actionname'],
    finalizeLogout: ['complete'],
    setLoggingOut: null,
    setLogoutRedirectTarget: ['redirectTarget'],
    // One Time Code
    setOneTimeTargetRoute: ['targetRoute'],
    setOneTimeRedemptionStatus: ['status'],
    handleOTCRedemption: null,
    handleOTCLoginCallback: null,
    // silent token refresh
    refreshAccessToken: null,
    handleRefreshTokenCallback: null,
    // reset
    authReset: null,
    // deprecated
    setIsReturningDirectUser: ['isReturningDirectUser']
})

export const AuthTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    requestLogin: null,
    accessToken: null,
    expiryTime: null,
    lastActivityTime: null,
    loggingOut: false,
    logoutType: null,
    firstLogin: false, 
    loginComplete: false,
    preLogoutActions: [],
    preLogoutComplete: false,
    oneTimeCodeRedemptionStatus: null,
    oneTimeTargetRoute: null,
    userRoles: [],
    loginCallbackStatus: null,
    logoutRedirectTarget: null
}

/* ------------- Reducers ------------- */
export const authSetLoginComplete = (state, { complete }) =>
    Object.assign({}, state, { loginComplete: complete })

export const authSetAccessToken = (state, { accessToken, expiryTime }) =>
    Object.assign({}, state, {
        accessToken: accessToken,
        expiryTime: expiryTime
    })

// don't reset the access token here, leave it so that other components
// have a chance to finish any logout clean-up
export const authLogoutUser = (state, { logoutType }) =>
    Object.assign({}, state, {
        loginComplete: false,
        logoutType: logoutType, 
        loggingOut: true
    })

export const authSetLastActivityTime = (state, { lastActivityTime }) =>
    Object.assign({}, state, { lastActivityTime: lastActivityTime })

export const authSetFirstLogin = (state, { firstLogin }) =>
    Object.assign({}, state, { firstLogin: firstLogin })

export const authStartPreLogoutAction = (state, { actionname }) => {
    let actions = { ...state.preLogoutActions }
    actions[actionname] = 'started'
    return Object.assign({}, state, { preLogoutActions: actions })
}

export const authDonePreLogoutAction = (state, { actionname }) => {
    let actions = { ...state.preLogoutActions }
    actions[actionname] = 'completed'
    return Object.assign({}, state, { preLogoutActions: actions })
}

export const authFinalizeLogout = (state, { complete }) =>
    Object.assign({}, state, { preLogoutComplete: complete, preLogoutActions: [] })

export const authSetOneTimeRedemptionStatus = (state, { status }) =>
    Object.assign({}, state, { oneTimeCodeRedemptionStatus: status })

export const authResetState = (state) =>
    Object.assign({}, INITIAL_STATE, {
        logoutType: state.logoutType,
        logoutRedirectTarget: state.logoutRedirectTarget,
        signingOut: state.signingOut
    })

export const authSetUserRoles = (state, { userRoles }) => 
    Object.assign({}, state, { userRoles: userRoles })

export const authProcessLoginCallback = (state) => 
    Object.assign({}, state, { loginCallbackStatus: 'in_progress' })

export const authSetLoginCallbackStatus = (state, { status }) =>
    Object.assign({}, state, { loginCallbackStatus: status })

export const authSetLogoutRedirectTarget = (state, { redirectTarget }) =>
    Object.assign({}, state, { logoutRedirectTarget: redirectTarget })

export const authSetOneTimeTargetRoute = (state, { targetRoute }) =>
    Object.assign({}, state, { oneTimeTargetRoute: targetRoute })

export const authSetLoggingOut = (state, isLoggingOut) =>
    Object.assign({}, state, { loggingOut: isLoggingOut })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.LOGOUT_USER]: authLogoutUser,
    [Types.SET_LAST_ACTIVITY_TIME]: authSetLastActivityTime,
    [Types.SET_FIRST_LOGIN]: authSetFirstLogin,
    [Types.AUTH_RESET]: authResetState,
    [Types.SET_ACCESS_TOKEN]: authSetAccessToken,
    [Types.SET_LOGIN_COMPLETE]: authSetLoginComplete,
    [Types.START_PRE_LOGOUT_ACTION]: authStartPreLogoutAction,
    [Types.DONE_PRE_LOGOUT_ACTION]: authDonePreLogoutAction,
    [Types.SET_ONE_TIME_REDEMPTION_STATUS]: authSetOneTimeRedemptionStatus,
    [Types.FINALIZE_LOGOUT]: authFinalizeLogout,
    [Types.SET_USER_ROLES]: authSetUserRoles,
    [Types.HANDLE_LOGIN_CALLBACK]: authProcessLoginCallback,
    [Types.SET_LOGIN_CALLBACK_STATUS]: authSetLoginCallbackStatus,
    [Types.SET_LOGOUT_REDIRECT_TARGET]: authSetLogoutRedirectTarget,
    [Types.SET_ONE_TIME_TARGET_ROUTE]: authSetOneTimeTargetRoute,
    [Types.SET_LOGGING_OUT]: authSetLoggingOut
})