import React, { Component, Fragment } from 'react';
import './style.css';
import T from 'i18n-react';
import AuthorizeSelectCtrl from './authorizeSelectCtrl'
import AuthorizeRqSelectCtrl from './authorizeRqSelectCtrl'

class AuthorizeSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: this.props.lang,
            layoutType: this.props.layoutType,
            province: this.props.province,
            provinceSpouse: this.props.provinceSpouse,
            prepareForSpouse: this.props.prepareForSpouse,
            firstName: this.props.firstName,
            spouseFirstName: this.props.spouseFirstName,
            authChoiceSelf: this.props.authChoiceSelf,
            authChoiceSpouse: this.props.authChoiceSpouse,
            authChoiceSelfRQ: this.props.authChoiceSelfRQ,
            authChoiceSpouseRQ: this.props.authChoiceSpouseRQ,
            selectOption: this.props.selectOption,
            isVir: this.props.isVir
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }
        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
        if (this.state.provinceSpouse !== newProps.provinceSpouse) {
            this.setState({ provinceSpouse: newProps.provinceSpouse })
        }
        if (this.state.province !== newProps.province) {
            this.setState({ province: newProps.province })
        }
        if (this.state.prepareForSpouse !== newProps.prepareForSpouse) {
            this.setState({ prepareForSpouse: newProps.prepareForSpouse })
        }
        if (this.state.spouseFirstName !== newProps.spouseFirstName) {
            this.setState({ spouseFirstName: newProps.spouseFirstName })
        }
        if (this.state.firstName !== newProps.firstName) {
            this.setState({ firstName: newProps.firstName })
        }
        if (this.state.authChoiceSelf !== newProps.authChoiceSelf) {
            this.setState({ authChoiceSelf: newProps.authChoiceSelf })
        }
        if (this.state.authChoiceSelfRQ !== newProps.authChoiceSelfRQ) {
            this.setState({ authChoiceSelfRQ: newProps.authChoiceSelfRQ })
        }
        if (this.state.authChoiceSpouse !== newProps.authChoiceSpouse) {
            this.setState({ authChoiceSpouse: newProps.authChoiceSpouse })
        }
        if (this.state.authChoiceSpouseRQ !== newProps.authChoiceSpouseRQ) {
            this.setState({ authChoiceSpouseRQ: newProps.authChoiceSpouseRQ })
        }
        if (this.state.selectOption !== newProps.selectOption) {
            this.setState({ selectOption: newProps.selectOption })
        }
        if (this.state.isVir !== newProps.isVir) {
            this.setState({ isVir: newProps.isVir })
        }
    }


    selectOption(target, opt) {
        if (this.state.selectOption) {
            this.state.selectOption(target, opt)
        }
    }

    render() {
        var isQC = this.state.province === 'QC' || this.state.spouseProvince === ''
        var labelPrefix = 'Authorize.' + (isQC ? 'QC' : 'ROC') + '.';

        return (
            <div className="authorizeMyAccountContainerStyle">
                <div className="authorizeContentStyle authorizeHeaderStyle">
                    <div className='authSelectHeader authorize-HRB-header'>
                        {T.translate('Authorize.SelectAuthTitle', { FirstName: this.state.firstName })}
                    </div>
                    <div className='authorize-welcome-content'>{T.translate(labelPrefix + 'WelcomeContent')}</div>
                    <AuthorizeSelectCtrl
                        lang={this.state.lang}
                        firstName={this.state.firstName} 
                        spouseFirstName={this.state.spouseFirstName}
                        prepareForSpouse={this.state.prepareForSpouse} 
                        authChoiceSpouse={this.state.authChoiceSpouse}
                        authChoiceSelf={this.state.authChoiceSelf}
                        selectOption={(target, opt) => this.selectOption(target, opt)}
                    />
                    {isQC &&
                        <AuthorizeRqSelectCtrl />
                    }
                </div>
            </div>
        );
    }
}

export default AuthorizeSelect;