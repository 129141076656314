import React, { Component } from 'react';
import T from 'i18n-react';
import './style.css';
import HelpCentre from '../../sagas/Help/helpCentre'
import { connect } from 'react-redux';

class LearnMore extends Component {
    constructor(props) {
        super(props)

        this.state = {
            linkId: this.props.linkId,
            baseClassName: this.props.baseClassName
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.linkId !== newProps.linkId) {
            this.setState({ linkId: newProps.linkId })
        }
        if (this.state.baseClassName !== newProps.baseClassName) {
            this.setState({ baseClassName: newProps.baseClassName })
        }
    }

    handleClick(ev) {
        HelpCentre.openHelpLink(this.state.linkId)
    }

    render() {
        var classNames = []
        if (this.state.baseClassName) {
            classNames.push(this.state.baseClassName)
        }
        if (this.state.linkId && this.props.showLinks) {
            classNames.push('learnMoreStyle')
            classNames.push('learnMoreDefaultMargin')
        } else {
            classNames.push('hidden')
        }

        var spanClassNames = []
        if (this.state.baseClassName) {
            spanClassNames.push(this.state.baseClassName)
        }
        spanClassNames.push('unselectableLabel')
        return (
            <span className={classNames.join(' ')} onClick={(ev) => this.handleClick(ev)}>
                <span className={spanClassNames.join(' ')}>{T.translate('Common.LearnMore')}</span>
            </span>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.layout.lang,
        showLinks: !state.workflow.is_direct_user
    };
};

export default connect(mapStateToProps)(LearnMore);