// src/components/Settings/settings.js
import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.css';
import ValidatingTextInput from '../Common/validatingTextInput';
import BaseModal from '../Modal/BaseModal';
import FontAwesome from 'react-fontawesome';
import PhoneTextInput from '../Common/phoneTextInput';
import ProvinceDropDown from '../Controls/DropDowns/provinceDropDown';
import SecurityQuestionsDropDown from '../Controls/DropDowns/securityQuestionsDropDown';
import T from 'i18n-react';
import { getAccountInfo, saveAccountInfo } from './settingsApis';
import AccountDataActions from '../../reducers/accountDataRedux'
import ResetPasswordConfirmation from './resetPasswordConfirmation'
import PhoneTypeToggle from '../Common/phoneTypeToggle'

class Settings extends Component {
    constructor(props) {
        super(props);
        var stateObj = this.createStateFromProps(props)
        stateObj.dataComplete = true
        stateObj.securityQuestionChanged = false
        stateObj.savingChanges = false
        stateObj.resetPasswordDialogOpen = false

        stateObj.accountId = this.props.accountId
        stateObj.email = this.props.email
        stateObj.layoutType = this.props.layoutType
        stateObj.accessToken = this.props.accessToken

        this.state = stateObj   
        //find out what language this thing is supposed to be
    }

    createStateFromProps(props) {
        let state = {
            ...this.state
        }        

        state.firstName = ''
        state.lastName = ''
        state.email = ''            
        state.province = ''
        state.phoneNumber = {
            value: '',
            isValid: true
        }
        state.phoneType = 1
        state.securityQuestionIndex = -1
        state.securityAnswer = ''
        state.securityHint = ''
        state.currentLanguage = props.lang
        
        return state;
    }

    componentWillReceiveProps(newProps) {
        if (newProps.accountId !== this.state.accountId) {
            this.setState({ accountId: newProps.accountId })
        }
        if (newProps.email !== this.state.email) {
            this.setState({ email: newProps.email })
        }
        if (newProps.layoutType !== this.state.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
        if (newProps.lang !== this.state.lang) {
            this.setState({ lang: newProps.lang })
        }
        if (newProps.accessToken !== this.state.accessToken) {
            this.setState({ accessToken: newProps.accessToken })
        }
        if (this.state.firstName !== newProps.firstName) {
            this.setState({ firstName: newProps.firstName })
        }
    }

    handleBackToHRBlockClick(event) {
        window.open("http://www.hrblock.ca", "_self")
    }   

    closeDialog(ev) {
        if (this.props.onCloseDialog) {
            this.props.onCloseDialog(ev);
        }
    }

    closeResetPasswordDialog(ev) {
        this.setState({ resetPasswordDialogOpen: false })
        if (ev.value === 'PasswordReset') {
            this.closeDialog(ev);
        }
    }

    findArrayOrdinal(array, value) {
        for (var i = 0; i < array.length; i++) {
            if (array[i] === value)
                return i
        }
        return -1
    }

    saveAccountChanges(ev) {
        if (this.state.dataComplete) {
            // Save changes to account info in VA and/or DIY
            var accountData = {
                ...this.state
            }
            //Clean up accountData before saving.
            accountData.firstName = accountData.firstName.trim();
            accountData.lastName = accountData.lastName.trim();

            this.setState({ savingChanges: true })

            accountData.email = this.state.email
            accountData.securityQuestion = this.state.securityQuestionIndex + 1


            saveAccountInfo(this.state.accessToken, this.state.accountId, accountData)
                .then((response) => {
                    if (response.status === 202) {
                        this.setState({ savingChanges: false, securityQuestionIndex: -1 })   

                        this.props.setFirstName(accountData.firstName)
                        if (accountData.phoneNumber && accountData.phoneNumber.value) {
                            this.props.setMobilePhone(accountData.phoneNumber.value)
                        }

                        if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) {
                            this.closeDialog(document.createEvent('CustomEvent'))
                        }
                        else {
                            this.closeDialog(new CustomEvent('closingAccountSettings', { value: 'closing' }))
                        }
                    } else {
                        console.log('unable to save data for account settings')
                    }
                }).catch((error) => {
                    this.setState({ savingChanges: false })
                    var event = new CustomEvent('closingAccountSettings', { value: 'closing' })
                    this.closeDialog(event)
                    if (navigator.userAgent.indexOf("MSIE") !== -1) {
                        this.closeDialog(document.createEvent('CustomEvent'))
                    } else {
                        this.closeDialog(new CustomEvent('closingAccountSettings', { value: 'closing' }))
                    }
                })
        }       
    }

    isValidEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape
        return re.test(email)
    }

    validateState(state) {
        var valid = false
        if (state.securityQuestionChanged) {
            valid = this.hasOnlyValidChars(state.firstName) && this.hasOnlyValidChars(state.lastName)
                && state.phoneNumber.isValid && state.phoneNumber.value
                && state.province !== null && state.securityQuestionIndex >= 0 && state.securityHint !== '' && state.securityAnswer !== ''
                && state.securityHint.toLowerCase() !== state.securityAnswer.toLowerCase();
        }
        else {
            valid = this.hasOnlyValidChars(state.firstName) && this.hasOnlyValidChars(state.lastName)
                && state.phoneNumber.isValid && state.phoneNumber.value &&
                state.province !== null && state.securityQuestionIndex >= 0;
        }        
        return valid;
    }

    hasOnlyValidChars(value) {
        var re = /^[A-Za-zÀ-ÖØ-öø-ÿ ’'\-\.]+$/  // eslint-disable-line no-useless-escape 
        return re.test(value)
    }

    handleFirstNameChange = (event) => {
        var state = {
            ...this.state,
            firstName: event.target.value
        }
        state.dataComplete = this.validateState(state);
        this.setState(state);
    };

    handleLastNameChange = (event) => {
        var state = {
            ...this.state,
            lastName: event.target.value
        }
        state.dataComplete = this.validateState(state);
        this.setState(state);
    };    

    handlePhoneNumberChange = (event, value, isValid) => {
        var phone = {
            value: value,
            isValid: isValid
        }
        var state = {
            ...this.state,
            phoneNumber: phone
        }
        state.dataComplete = this.validateState(state);
        this.setState(state);
    };

    handlePhoneTypeChange = (event, value) => {
        var state = {
            ...this.state,
            phoneType: Number(event.target.value)
        }
        this.setState(state)
    }

    onProvinceChanged(ev, value) {
        var state = {
            ...this.state,
            province: value
        }
        state.dataComplete = this.validateState(state);
        this.setState(state);
    }

    onSecurityQuestionChanged(ev, value) {
        var state = {            
            ...this.state,
            securityQuestionIndex: value
        }

        state.securityQuestionChanged = true
        state.securityHint = ''
        state.securityAnswer = ''
        state.dataComplete = this.validateState(state);
        this.setState(state);        
    }    

    handleAnswerChange(event) {
        var state = {
            ...this.state,
            securityAnswer: event.target.value
        }
        state.dataComplete = this.validateState(state);
        this.setState(state);
    }

    handleHintChange(event) {
        var state = {
            ...this.state,
            securityHint: event.target.value
        }
        state.dataComplete = this.validateState(state);
        this.setState(state);
    }

    resetPassword(ev) {
        this.setState({ resetPasswordDialogOpen: true});
    }
              
    componentWillMount()
    {          
        //Retrieve data from API
        getAccountInfo(this.state.accessToken, this.state.accountId)
            .then((response) => {
                if (response.status === 200) {
                    var state = {
                        ...this.state
                    }
                    state.firstName = response.data.firstName;                    
                    state.lastName = response.data.lastName; 
                    state.phoneNumber.value = response.data.phoneNumber;  
                    state.phoneNumber.isValid = true;
                    state.phoneType = response.data.phoneType;
                    state.province = response.data.province;
                    state.securityQuestionIndex = response.data.securityQuestion - 1;
                    state.securityAnswer = response.data.securityAnswer;
                    state.securityHint = response.data.securityHint;
                    this.setState(state);
                    this.setState({ dataComplete: this.validateState(this.state) });
                } else {
                    console.log('unable to retrieve data for account settings')
                }
            }).catch((error) => {
                console.log("settings.js/getAccountInfo/ error received:  accountinfo() fail");
            })
    }

    render() {
        var invertedButtonClassName = "invertedButtonStyleAccountSettings dialogButtonWidthAccountSettings"
        var invertedButtonClassNameMobile = "invertedButtonStyleAccountSettingsMobile dialogButtonWidthAccountSettings"
        var buttonClassNameWide = "defaultButtonStyleAccountSettings dialogButtonWidthFullAccountSettings"
        var layoutType = this.state.layoutType

        var dialogContent = (<div>
            <BaseModal show={this.state.resetPasswordDialogOpen} onClose={() => { this.closeResetPasswordDialog({ value: '' }) }} layoutType={this.props.layoutType}>
                <ResetPasswordConfirmation onCloseDialog={(ev) => this.closeResetPasswordDialog(ev)} email={this.state.email} />
            </BaseModal>
        </div>)

        return (
            <div className="defaultStyles">     
                <form name="settings-form" autoComplete="on">
                {(() => {
                    switch (layoutType) {
                        case 'desktop':
                            return (<div className='desktop settingsDesktopContainer'><div>                                
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                    <h1 className="centerAlign">{T.translate("Settings.labels.Settings")}</h1>
                                </div>
                                <p>{T.translate("Settings.labels.HeaderMessage")}</p>
                                <div>
                                    <ValidatingTextInput labelText={T.translate("AccountSetupStart.labels.FirstName")}
                                        autoComplete="given-name"
                                        value={this.state.firstName} placeholderText="" defaultText={this.state.firstName}
                                        validationFailedText={T.translate("AccountSetupStart.labels.NameValidationError")}
                                        validator={(text) => { return this.hasOnlyValidChars(text) }} 
                                        onChange={this.handleFirstNameChange} />
                                    <ValidatingTextInput labelText={T.translate("AccountSetupStart.labels.LastName")}
                                        autoComplete="family-name" value={this.state.lastName} placeholderText=""
                                        defaultText={this.state.lastName} validationFailedText={T.translate("AccountSetupStart.labels.NameValidationError")}
                                        validator={(text) => { return this.hasOnlyValidChars(text) }} onChange={this.handleLastNameChange} />
                                    <div className="settingsPhoneHolder">
                                        <div className="phoneHolder">
                                            <PhoneTextInput labelText="AccountSetupStart.labels.MobilePhone"
                                                autoComplete="tel-national mobile"
                                                value={this.state.phoneNumber.value}
                                                validationFailedText="AccountSetupStart.labels.InvalidPhone"
                                                onChange={this.handlePhoneNumberChange} />
                                        </div>
                                        <div className="phoneTypeHolder">
                                            <PhoneTypeToggle name="PhoneType" value={this.state.phoneType} onStateChanged={this.handlePhoneTypeChange} />
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: 0, marginTop: 20 }}>
                                        <div className="formLabelStyle">
                                            {T.translate("AccountSetupStart.labels.Province")}
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: 0 }}>
                                        <ProvinceDropDown name="province" value={this.state.province} onValueChanged={(ev, value) => this.onProvinceChanged(ev, value)} />
                                    </div>
                                    <br/>
                                    <div className={buttonClassNameWide} style={{ marginLeft: 0, marginBottom: 10 }} onClick={(ev) => this.resetPassword(ev)} >
                                        <div style={{ width: 20 }} />
                                        <span className="unselectableLabel"><T.span text={{ key: 'Settings.labels.ResetPassword' }} /></span>
                                        <div style={{ width: 20 }} />
                                    </div>
                                    <div style={{ marginLeft: 0, marginTop: 18, marginBottom: 20 }}>
                                        <div className="formLabelStyle">
                                            {T.translate("Settings.labels.ChooseSecQuestion")}
                                        </div>
                                        <SecurityQuestionsDropDown name="question" value={this.state.securityQuestionIndex} onValueChanged={(ev, value) => this.onSecurityQuestionChanged(ev, value)} />
                                    </div>

                                    {this.state.securityQuestionChanged ? (<div>
                                        <ValidatingTextInput value={this.state.securityAnswer} autoComplete="off"
                                            labelText={T.translate("Settings.labels.Answer")} placeholderText=""
                                            validationFailedText={T.translate("AccountSetupCredentials.labels.FieldCannotBeEmpty")}
                                            validator={(text) => { return Boolean(text) }} onChange={(ev) => { this.handleAnswerChange(ev) }} />
                                        <ValidatingTextInput value={this.state.securityHint} autoComplete="off"
                                            labelText={T.translate("Settings.labels.Hint")} placeholderText="" validationFailedText={T.translate("AccountSetupCredentials.labels.FieldCannotBeEmpty")}
                                            validator={(value) => { return Boolean(value) }} onChange={(ev) => { this.handleHintChange(ev) }} />
                                    </div>) : (<div></div>)}

                                    <div className='btngroup' style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                        <div className={invertedButtonClassName} style={{ bottom: '5px' }} onClick={(ev) => this.closeDialog(ev)} >
                                            <div style={{ width: 20 }} />
                                            <span className="unselectableLabel"><T.span text={{ key: 'Settings.labels.Cancel' }} /></span>
                                            <div style={{ width: 20 }} />
                                        </div>&nbsp;&nbsp;
                            <div className={this.state.dataComplete ? "continueButtonStyleAccountSettings" : "continueButtonStyleAccountSettingsDisabled"} style={{ bottom: '5px', right: '25px' }} onClick={(ev) => this.saveAccountChanges(ev)} >
                                            <div style={{ width: 20 }} />

                                            {this.state.savingChanges ? (<FontAwesome className='fa-spinner' name='spinner' pulse size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#ffffff', align: 'center', paddingTop: "2px", paddingBottom: "2px", marginLeft: "35px", marginRight: "35px" }} />)
                                                : (<span className="unselectableLabel"><T.span text={{ key: 'Settings.labels.SaveChanges' }} /></span>)}

                                            <div style={{ width: 20 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                                {dialogContent}
                            </div>)
                        case 'small_tablet':
                        case 'tablet':
                            return (<div className='tablet'><div>                                
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                    <h1 className="centerAlign">{T.translate("Settings.labels.Settings")}</h1>
                                </div>
                                <p style={{ marginLeft: "20px" }} >{T.translate("Settings.labels.HeaderMessage")}</p>
                                <div style={{ marginLeft: 20 }} >
                                    <ValidatingTextInput labelText={T.translate("AccountSetupStart.labels.FirstName")}
                                        autoComplete="give-name"
                                        value={this.state.firstName} placeholderText=""
                                        defaultText={this.state.firstName}
                                        validationFailedText={T.translate("AccountSetupStart.labels.NameValidationError")}
                                        validator={(text) => { return this.hasOnlyValidChars(text) }} onChange={this.handleFirstNameChange} />
                                    <ValidatingTextInput labelText={T.translate("AccountSetupStart.labels.LastName")} value={this.state.lastName} placeholderText="" defaultText={this.state.lastName} validationFailedText={T.translate("AccountSetupStart.labels.NameValidationError")}
                                        validator={(text) => { return this.hasOnlyValidChars(text) }} onChange={this.handleLastNameChange} />
                                    <div className="settingsPhoneHolder">
                                        <div className="phoneHolder">
                                            <PhoneTextInput labelText="AccountSetupStart.labels.MobilePhone"
                                                autoComplete="tel-national mobile"
                                                value={this.state.phoneNumber.value}
                                                validationFailedText="AccountSetupStart.labels.InvalidPhone"
                                                onChange={this.handlePhoneNumberChange} />
                                        </div>
                                        <div className="phoneTypeHolder">
                                            <PhoneTypeToggle name="PhoneType" value={this.state.phoneType} onStateChanged={this.handlePhoneTypeChange} />
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: 0, marginTop: 20 }}>
                                        <div className="formLabelStyle">
                                            {T.translate("AccountSetupStart.labels.Province")}
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: 0 }}>
                                        <ProvinceDropDown name="province" value={this.state.province} onValueChanged={(ev, value) => this.onProvinceChanged(ev, value)} />
                                    </div>
                                    <br/>
                                    <div className={buttonClassNameWide} style={{ marginLeft: 0, marginBottom: 10 }} onClick={(ev) => this.resetPassword(ev)} >
                                        <div style={{ width: 20 }} />
                                        <span className="unselectableLabel"><T.span text={{ key: 'Settings.labels.ResetPassword' }} /></span>
                                        <div style={{ width: 20 }} />
                                    </div>
                                    <div style={{ marginLeft: 0, marginTop: 18, marginBottom: 20 }}>
                                        <div className="formLabelStyle">
                                            {T.translate("Settings.labels.ChooseSecQuestion")}
                                        </div>
                                        <SecurityQuestionsDropDown name="question" value={this.state.securityQuestionIndex} onValueChanged={(ev, value) => this.onSecurityQuestionChanged(ev, value)} />
                                    </div>

                                    {this.state.securityQuestionChanged ? (<div>
                                        <ValidatingTextInput value={this.state.securityAnswer} autoComplete="off" labelText={T.translate("Settings.labels.Answer")} placeholderText="" validationFailedText={T.translate("AccountSetupCredentials.labels.FieldCannotBeEmpty")}
                                            validator={(text) => { return Boolean(text) }} onChange={(ev) => { this.handleAnswerChange(ev) }} />
                                        <ValidatingTextInput value={this.state.securityHint} autoComplete="off" labelText={T.translate("Settings.labels.Hint")} placeholderText="" validationFailedText={T.translate("AccountSetupCredentials.labels.FieldCannotBeEmpty")}
                                            validator={(text) => { return Boolean(text) }} onChange={(ev) => { this.handleHintChange(ev) }} />
                                    </div>) : (<div></div>)}

                                    <div className='btngroup' style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                        <div className={invertedButtonClassName} style={{ bottom: '5px' }} onClick={(ev) => this.closeDialog(ev)} >
                                            <div style={{ width: 20 }} />
                                            <span className="unselectableLabel"><T.span text={{ key: 'Settings.labels.Cancel' }} /></span>
                                            <div style={{ width: 20 }} />
                                        </div>&nbsp;&nbsp;
                            <div className={this.state.dataComplete ? "continueButtonStyleAccountSettings" : "continueButtonStyleAccountSettingsDisabled"} style={{ bottom: '5px', right: '25px' }} onClick={(ev) => this.saveAccountChanges(ev)} >
                                            <div style={{ width: 20 }} />

                                            {this.state.savingChanges ? (<FontAwesome className='fa-spinner' name='spinner' pulse size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#ffffff', align: 'center', paddingTop: "2px", paddingBottom: "2px", marginLeft: "35px", marginRight: "35px" }} />)
                                                : (<span className="unselectableLabel"><T.span text={{ key: 'Settings.labels.SaveChanges' }} /></span>)}

                                            <div style={{ width: 20 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                                {dialogContent}
                            </div>)
                        default:
                            return (<div className='mobile'><div>
                                <div className="closeButtonContainer" onClick={(ev) => { this.closeDialog(ev) }} >
                                    <span>&nbsp;</span>
                                    <FontAwesome style={{ marginRight: '-15px' }} className='fa-close' name='close' size='2x' />
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                    <h1 className="centerAlign">{T.translate("Settings.labels.Settings")}</h1>
                                </div>
                                <p style={{ marginLeft: "20px" }} >{T.translate("Settings.labels.HeaderMessage")}</p>
                                <div style={{ marginLeft: 20 }} >
                                    <ValidatingTextInput labelText={T.translate("AccountSetupStart.labels.FirstName")}
                                        autoComplete="give-name"
                                        value={this.state.firstName} placeholderText="" defaultText={this.state.firstName}
                                        validationFailedText={T.translate("AccountSetupStart.labels.NameValidationError")}
                                        validator={(text) => { return this.hasOnlyValidChars(text) }} onChange={this.handleFirstNameChange} />
                                    <ValidatingTextInput labelText={T.translate("AccountSetupStart.labels.LastName")}
                                        autoComplete="family-name" value={this.state.lastName} placeholderText=""
                                        defaultText={this.state.lastName}
                                        validationFailedText={T.translate("AccountSetupStart.labels.NameValidationError")}
                                        validator={(text) => { return this.hasOnlyValidChars(text) }} onChange={this.handleLastNameChange} />
                                    <div className="settingsPhoneHolder">
                                        <div className="phoneHolder">
                                            <PhoneTextInput labelText="AccountSetupStart.labels.MobilePhone" value={this.state.phoneNumber.value}
                                                validationFailedText="AccountSetupStart.labels.InvalidPhone" autoComplete="tel-national mobile"
                                                onChange={this.handlePhoneNumberChange} />
                                        </div>
                                        <div className="phoneTypeHolder">
                                            <PhoneTypeToggle name="PhoneType" value={this.state.phoneType} onStateChanged={this.handlePhoneTypeChange} />
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: 0, marginTop: 20 }}>
                                        <div className="formLabelStyle">
                                            {T.translate("AccountSetupStart.labels.Province")}
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: 0 }}>
                                        <ProvinceDropDown name="province" value={this.state.province} onValueChanged={(ev, value) => this.onProvinceChanged(ev, value)} />
                                    </div>
                                    <br/>
                                    <div className={buttonClassNameWide} style={{ marginLeft: 0, marginBottom: 10 }} onClick={(ev) => this.resetPassword(ev)} >
                                        <div style={{ width: 20 }} />
                                        <span className="unselectableLabel"><T.span text={{ key: 'Settings.labels.ResetPassword' }} /></span>
                                        <div style={{ width: 20 }} />
                                    </div>
                                    <div style={{ marginLeft: 0, marginTop: 18, marginBottom: 20 }}>
                                        <div className="formLabelStyle">
                                            {T.translate("Settings.labels.ChooseSecQuestion")}
                                        </div>
                                        <SecurityQuestionsDropDown name="question" value={this.state.securityQuestionIndex} onValueChanged={(ev, value) => this.onSecurityQuestionChanged(ev, value)} />
                                    </div>

                                    {this.state.securityQuestionChanged ? (<div>
                                        <ValidatingTextInput value={this.state.securityAnswer} autoComplete="off"
                                            labelText={T.translate("Settings.labels.Answer")} placeholderText="" validationFailedText={T.translate("AccountSetupCredentials.labels.FieldCannotBeEmpty")}
                                            validator={(text) => { return Boolean(text) }} onChange={(ev) => { this.handleAnswerChange(ev) }} />
                                        <ValidatingTextInput value={this.state.securityHint} autoComplete="off"
                                            labelText={T.translate("Settings.labels.Hint")} placeholderText="" validationFailedText={T.translate("AccountSetupCredentials.labels.FieldCannotBeEmpty")}
                                            validator={(text) => { return Boolean(text) }} onChange={(ev) => { this.handleHintChange(ev) }} />
                                    </div>) : (<div></div>)}

                                    <div className='btngroup'>                                        
                                        <div className={this.state.dataComplete ? "continueButtonStyleAccountSettingsMobile" : "continueButtonStyleAccountSettingsDisabledMobile"} style={{ bottom: '5px', right: '25px' }} onClick={(ev) => this.saveAccountChanges(ev)} >
                                            <div style={{ width: 20 }} />

                                            {this.state.savingChanges ? (<FontAwesome className='fa-spinner' name='spinner' pulse size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#ffffff', align: 'center', paddingTop: "2px", paddingBottom: "2px", marginLeft: "35px", marginRight: "35px" }} />)
                                                : (<span className="unselectableLabel"><T.span text={{ key: 'Settings.labels.SaveChanges' }} /></span>)}                                        
                                            
                                            <div style={{ width: 20 }} />
                                        </div>
                                        <div className={invertedButtonClassNameMobile} style={{ bottom: '5px' }} onClick={(ev) => this.closeDialog(ev)} >
                                            <div style={{ width: 20 }} />
                                            <span className="unselectableLabel"><T.span text={{ key: 'Settings.labels.Cancel' }} /></span>
                                            <div style={{ width: 20 }} />
                                        </div>
                                    </div>
                                </div>
                                <div ref="test"></div>
                            </div>
                                {dialogContent}
                            </div>)
                    }
                    })()} 
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accountId: state.accountData.accountId,
        email: state.accountData.email,
        layoutType: state.layout.layoutType,
        lang: state.layout.lang,
        accessToken: state.auth.accessToken
    }
}

const mapDispatchToProps = (dispatch) => ({
    setFirstName: (firstName) => dispatch(AccountDataActions.setFirstName(firstName)),
    setMobilePhone: (mobilePhone) => dispatch(AccountDataActions.setMobilePhone(mobilePhone))
})

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
