import React, { Component } from 'react';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { withRouter } from "react-router-dom";
import T from 'i18n-react';

class Navigation extends Component {

    backClick(e) {
        this.props.onClick();
    }

    render() { 
        return (
            <div className='level'>
                <div className="interview-page-back-link level-item" onClick={(ev) => this.backClick(ev)}>                    
                    <span className='level-item'>
                        {`${this.props.showArrow ? '< ' : ''}${T.translate(this.props.labelKey)}`}
                    </span>
                </div>
            </div>
        )
    }
}

withRouter(Navigation)

const mapStateToProps = (state) => {
    return {
        lang: state.layout.lang,
    };
};

const mapDispatchToProps = (dispatch) => ({
    goBack: () => dispatch(goBack())
})

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)