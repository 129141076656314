import React, { Component } from 'react';
import DashboardTimers from './Timers/dashboardTimers'
import ConnectionTimers from '../Common/Header/connectionTimers'
import AuthTimers from '../Login/authTimers'

class TimerController extends Component {
    render() {
        var ctrlrs = []
        ctrlrs.push(<AuthTimers key='auth' />)
        ctrlrs.push(<DashboardTimers key='dashboard' />)
        ctrlrs.push(<ConnectionTimers key='connection' />)
        return (
            <div style={{ display: 'none' }}>
                {ctrlrs}
            </div>
        );
    }
}

export default TimerController;