import React, { Fragment } from 'react';
import PropTypes from 'prop-types'
import WorkflowSection from '../Section/WorkflowSection'
import T from 'i18n-react';
import '../Section/section.css'
import WorkflowButton from '../Section/WorkflowButton'
import { WorkflowButtonStates } from '../Section/WorkflowButtonStates'
import { useTicketType, ValidTicketTypes } from '../../../hooks/useTicketTypeHook';
import MessageLink from '../../Controls/messageLink';
import WorkflowButtonMaterial from '../Section/WorkflowButtonMaterial';

const DownloadReturnSection = ({ currentYear, taxState, onWorkflowButtonAction, isDirectUser }) => {
    const validTicketTypes = ValidTicketTypes;
    var showSubtext = true
    let { ticketType } = useTicketType(taxState)
    let keySuffix = ticketType === validTicketTypes.VIR ? 'vir' : ticketType.toLowerCase() === validTicketTypes.RTE ? 'rte'
        : isDirectUser ? 'direct' : 'ote';
    let titleKey = ticketType === validTicketTypes.VIR ? 'WorkflowPanel.taxReturnTitleVIR'
        : ticketType.toLowerCase() === validTicketTypes.RTE ? 'WorkflowPanel.taxReturnTitleRTE' : 'WorkflowPanel.taxReturnTitle'
    var messageLink = <MessageLink linkKey='WorkflowPanel.download.message-link' />;

    return (
        <WorkflowSection titleKey={titleKey}
            titleKeyProps={{ TaxYear: taxState.taxYear }} canClose={false}
            isAlternate={(currentYear - taxState.taxYear) % 2 === 1} ticketType={taxState.ticket_type}>

            {ticketType.toLowerCase() === validTicketTypes.RTE
            ? <WorkflowButtonMaterial
                buttonKey="download"
                text={`WorkflowPanel.download.title.${keySuffix}`}
                textProps={{ TaxYear: taxState.taxYear }}
                icon="fa-download"
                showChevron = 'true'
                ticketType={ticketType}
                onClick={() => onWorkflowButtonAction('download', taxState.taxYear)} />

            : <WorkflowButton
                buttonKey="download"
                name="download"
                buttonState={WorkflowButtonStates.SHOW}
                icon="fa-download"
                text={`WorkflowPanel.download.title.${keySuffix}`}
                textProps={{ TaxYear: taxState.taxYear }}
                ticketType={ticketType}
                onClick={() => onWorkflowButtonAction('download', taxState.taxYear)}
                onFadeOut={() => { }} />}
            {showSubtext &&
                <Fragment>
                    <div className="wf-section-label-container">
                        {T.translate(`WorkflowPanel.download.subtext.${keySuffix}`)}
                    </div>
                    <div className="wf-section-label-container wf-section-label-extra-spacing">
                        {T.translate(`WorkflowPanel.download.subtext2.${keySuffix}`, { MessageLink: messageLink })}
                    </div>
                </Fragment>
            }
        </WorkflowSection>
    );
};

DownloadReturnSection.propTypes = {
    taxState: PropTypes.shape({
        taxYear: PropTypes.number.isRequired,
        coupled: PropTypes.bool.isRequired,
        primary_province: PropTypes.string,
        primary_firstName: PropTypes.string.isRequired,
        spouse_province: PropTypes.string,
        spouse_firstName: PropTypes.string,
        subTotal: PropTypes.number,
        tenderTotal: PropTypes.number
    }),
    currentYear: PropTypes.number.isRequired,
    onWorkflowButtonAction: PropTypes.func.isRequired,
    isDirectUser: PropTypes.bool
}

export default DownloadReturnSection;

