/* eslint-disable-next-line */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Navigation from '../Common/navigation'
import T from 'i18n-react';
import './style.css'
import PostalCodeTextInput from '../Common/postalCodeTextInput'
import LoadingSpinner from '../Loading/loadingSpinner';
import PlainButton from '../Controls/plainButton';
import AssignApi from '../../services/assignApis'
import WorkflowActions from '../../reducers/workflowRedux'
import WorkflowNewReturnActions from '../../reducers/workflowNewReturnRedux'
import { isValidPostalCode } from '../../utils/PostalCodeUtil'
import classnames from 'classnames'
import AssignConfirm from './assignConfirm';

const AssignIntercept = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false)
    const [saveError, setSaveError] = useState(null)
    const [postalCode, setPostalCode] = useState('')
    const [validPostalCode, setValidPostalCode] = useState(false)

    const [readyToAssign, setReadyToAssign] = useState(false)
    const [associateInfo, setAssociateInfo] = useState(null)
    const [officeInfo, setOfficeInfo] = useState(null)

    const accessToken = useSelector(state => state.auth.accessToken)
    const layoutType = useSelector(state => state.layout.layoutType)

    const onPreviousClick = () => {
        history.replace('/dashboard');
    }

    const hasValue = (value) => {
        if (value) {
            return value.length !== 0;
        }
        return false;
    }

    const handlePostalCodeChange = (ev, newValue, isValid) => {
        setSaveError(null)
        setPostalCode(newValue)
        setValidPostalCode(hasValue(newValue) && isValid)
    }

    const handleContinue = async () => {
        if (readyToAssign) {
            setSaving(true)
            var response = await AssignApi.create().assignTickets(accessToken, associateInfo.id, officeInfo.id)
            if (response && response.ok) {
                if (!response.data.isSuccess) {
                    // there are no open tickets assigned to this tax pro, let's store the result
                    dispatch(WorkflowNewReturnActions.setNextReturnAssignment(associateInfo.id, officeInfo.id))
                }
                dispatch(WorkflowActions.updateCaseState('pop'))
            } else {
                setSaveError("UnexpectedFailure")
                setSaving(false);
            }
        } else {
            dispatch(WorkflowNewReturnActions.resetNewReturnState())
            await handleSavePostalCode();
        }
    }

    const handleSavePostalCode = async () => {
        setSaving(true)

        try {
            var response = await AssignApi.create().setPostalCode(accessToken, postalCode);
            if (response && response.ok) {
                if (!response.data.isSuccess || response.data.assignmentStatus === "UnexpectedFailure") {
                    setSaveError(response.data.assignmentStatus)
                } else if (response.data.assignmentStatus === "InvalidPostalCode") {
                    setValidPostalCode(false)
                    setSaveError(response.data.assignmentStatus);
                } else if (response.data.assignmentStatus === "NoTaxProFound") {
                    setValidPostalCode(false)
                    setSaveError(response.data.assignmentStatus)
                } else {
                    if (response.data.officeId) {
                        setOfficeInfo({
                            id: response.data.officeId,
                            displayName: response.data.officeName,
                            address: response.data.officeStreetAddress,
                            city: response.data.officeCity,
                            phone: response.data.officePhone,
                            isFranchise: response.data.IsFranchise
                        })
                    }
                    if (response.data.associateId) {
                        setAssociateInfo({
                            id: response.data.associateId,
                            name: response.data.associateName
                        })
                    }
                    setReadyToAssign(true);                    
                }                
            } else {
                setSaveError("UnexpectedFailure")
            }
            setSaving(false)
        } catch (ex) {
            console.log(ex)
            setSaveError("UnexpectedFailure")
            setSaving(false)
        }
    }

    const handleReset = () => {
        setPostalCode('')
        setValidPostalCode(false)
        setReadyToAssign(false);
    }

    const handleEnterPressed = isValidPostalCode => {
        if (isValidPostalCode) {
            handleContinue()
        }
    }

    useEffect(() => {
        async function retrievePostalCode() {
            var response = await AssignApi.create().getExistingPostalCode(accessToken)
            if (response.ok && response.data) {
                handlePostalCodeChange(null, response.data.postalCode, isValidPostalCode(response.data.postalCode))
            }
            setLoading(false);
        }

        retrievePostalCode();
    }, [])

    return (
        <div className={classnames('ai-main', layoutType)}>
            <div className='ai-top'>
                <div className='ai-back-link'>
                    <Navigation onClick={onPreviousClick} labelKey='Interview.Page1.backLink' />
                </div>
                {loading && 
                    <div className='ai-spinner'>
                        <LoadingSpinner />
                    </div>
                }
                {!loading && !readyToAssign &&
                    <div className='ai-content'>
                        <h1>{T.translate('Assign.Title')}</h1>
                        <p>{T.translate('Assign.Content')}</p>
                        <div className='ai-postal'>
                        <PostalCodeTextInput name="postalCode" autoComplete="postal-code"
                            labelText={T.translate('Interview.Page2.fieldLabel7')}
                            value={postalCode}
                            onChange={handlePostalCodeChange}
                            disabled={saving}
                            hasErrors={!!saveError}
                            specificErrorText={saveError === 'UnexpectedFailure' ? 'Assign.UnexpectedError' : saveError === 'NoTaxProFound' ? 'Assign.NoTaxPro' : null}
                            validationFailedText="Common.Errors.InvalidPostalCode"
                            mainDivAdditionalClass="fixedWidthTextInput"
                            enterPressed={isValidPostalCode => handleEnterPressed(isValidPostalCode)}
                            />
                        </div>
                    </div>
                }
                {!loading && readyToAssign &&
                    <AssignConfirm associateInfo={associateInfo}
                        officeInfo={officeInfo} onReset={handleReset}
                        layoutType={layoutType}
                    />
                }
            </div>
            <div className='ai-continue-holder'>
                <PlainButton labelKey='Common.Continue' isEnabled={validPostalCode}
                    onClick={handleContinue} inProgress={saving} tabIndex={0} />
            </div>            
        </div>
    )
}

export default AssignIntercept