import React, { Component } from 'react';
import '../Common/shared.css';
import '../Interview/style.css'
import '../ConfirmIdentity/style.css'
import T from 'i18n-react';
import ValidatingTextInput from '../Common/validatingTextInput'
import NumberInput from '../Common/numberInput'
import PostalCodeTextInput from '../Common/postalCodeTextInput'
import ProvinceDropDown from '../Controls/DropDowns/provinceDropDown'
 
class AddAddressForm extends Component
{
    constructor(props)
    {
        super(props)
 
        var address = this.props.address || {
            aptNumber: '',
            streetName: '',
            streetNumber: '',
            postalCode: '',
            poBox: '',
            ruralRouteNumber: '',
            city: '',
            province: ''
        }
 
        this.state = {
            address,
            lang: this.props.lang,
            validate: this.props.validate
        }
    }
 
    componentWillReceiveProps(newProps)
    {
        if (this.state.validate !== newProps.validate) {
            this.setState({ validate: newProps.validate })
        }
        if (this.state.address !== newProps.address) {
            this.setState({ address: newProps.address })
        }
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }
    }
 
    onAddressPropertyChange = (event, value) => {
        if (this.props.onAddressPropertyChange) {
            this.props.onAddressPropertyChange(event.target.name, value)
        }
    }
 
    showError(fieldName) {
        return this.state.validate && Boolean(!this.state.address[fieldName])
    }
 
    showAddressError() {
        var validAddress = (Boolean(this.state.address.streetNumber) && Boolean(this.state.address.streetName))
            || Boolean(this.state.address.poBox) || Boolean(this.state.address.ruralRouteNumber)
        return this.state.validate && !validAddress
    }
 
    render() {
 
        var provHeaderStyles = ['formLabelStyle']
        if (!this.state.address.province && this.state.validate) {
            provHeaderStyles.push('red')
        }
 
        return (
            <div className="confirmFullWidth">
                <form name="add_address_form" autoComplete="on">
                    <div className="confirmHorizontalFieldContainerCenterAligned">
                        <div className="shortInputWidth rightMargin">
                            <ValidatingTextInput name="streetNumber" autoComplete="off"
                                labelText={T.translate('Interview.Page2.fieldLabel1')}
                                value={this.state.address.streetNumber} heapIgnore="true"
                                renderErrorBorderOnly={this.showAddressError()}
                                onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)} />
                        </div>
                        <div className="longInputWidth leftMargin rightMargin" style={{ flex: 1 }}>
                            <ValidatingTextInput name="streetName" autoComplete="off"
                                labelText={T.translate('Interview.Page2.fieldLabel2')}
                                value={this.state.address.streetName} heapIgnore="true"
                                renderErrorBorderOnly={this.showAddressError()}
                                onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)} />
                        </div>
                        <div className="shortInputWidth leftMargin">
                            <ValidatingTextInput name="aptNumber" autoComplete="off"
                                labelText={T.translate('Interview.Page2.fieldLabel0')}
                                value={this.state.address.aptNumber} heapIgnore="true"
                                renderErrorBorderOnly={this.showAddressError()}
                                onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)} />
                        </div>
                    </div>
                    <div className="confirmHorizontalFieldContainerCenterAligned">
                        <div className="defaultInputWidth rightMargin">
                            <NumberInput name="poBox" autoComplete="off"
                                labelText={T.translate('Interview.Page2.fieldLabel3')}
                                value={this.state.address.poBox} heapIgnore="true"
                                onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)}
                                validationFailedText={T.translate(this.state.labelKey + ".validationFailedLabel0")}
                                renderErrorBorderOnly={this.showAddressError()}
                                validator={(textValue) => { return this.validateNumberField(textValue) }} />
                        </div>
                        <div className="defaultInputWidth leftMargin">
                            <ValidatingTextInput name="ruralRouteNumber" helpCentreId="OTE_FAQ/OTEFieldRuralRouteNumber"
                                labelText={T.translate('Interview.Page2.fieldLabel4')} heapIgnore="true"
                                value={this.state.address.ruralRouteNumber} autoComplete="off"
                                onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)}
                                renderErrorBorderOnly={this.showAddressError()}
                                mainDivAdditionalClass="fixedWidthTextInput" />
                        </div>
                    </div>
                    <div className="confirmHorizontalFieldContainerCenterAligned">
                        <div className="defaultInputWidth rightMargin">
                            <ValidatingTextInput name="city" autoComplete="address-level2"
                                labelText={T.translate('Interview.Page2.fieldLabel5')}
                                value={this.state.address.city} heapIgnore="true"
                                onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)}
                                renderErrorBorderOnly={this.showError('city')}
                                mainDivAdditionalClass="fixedWidthTextInput" />
                        </div>
                        <div className="validatingTextInputStyle defaultInputWidth leftMargin rightMargin">
                            <div>
                                <div className={provHeaderStyles.join(' ')}>{T.translate('Interview.Page2.fieldLabel6')}</div>
                                <ProvinceDropDown name="province" options={this.provinceOptions}
                                    value={this.state.address.province}
                                    onValueChanged={(ev, value) => this.onAddressPropertyChange(ev, value)}
                                    missingRequiredField={this.showError('province')}
                                />
                                <div className="validationErrorStyle">&nbsp;</div>
                            </div>
                        </div>
                        <div className="validatingTextInputStyle defaultInputWidth leftMargin">
                            <PostalCodeTextInput name="postalCode" autoComplete="postal-code"
                                labelText={T.translate('Interview.Page2.fieldLabel7')}
                                value={this.state.address.postalCode}
                                onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)}
                                validationFailedText="Common.Errors.InvalidPostalCode"
                                renderErrorBorderOnly={this.showError('postalCode')}
                                mainDivAdditionalClass="fixedWidthTextInput" />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
 
export default AddAddressForm;