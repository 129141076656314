import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    setRating: ['rating'],
    setNotes: ['notes'],
    setAllowContact: ['allow_contact'],
    submitSurvey: null,
    reset: null
})

export const SurveyTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    taxYear: Number(localStorage.getItem('va_current_tax_year')),
    rating: null,
    notes: '',
    allow_contact: false
}

/* ------------- Reducers ------------- */
export const surveySetRating = (state, { rating }) =>
    Object.assign({}, state, { rating: rating })

export const surveySetNotes= (state, { notes }) =>
    Object.assign({}, state, { notes: notes })

export const surveySetAllowContact = (state, { allow_contact }) =>
    Object.assign({}, state, { allow_contact: allow_contact })

export const surveyReset = (state) => INITIAL_STATE 


/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_RATING]: surveySetRating,
    [Types.SET_NOTES]: surveySetNotes,
    [Types.SET_ALLOW_CONTACT]: surveySetAllowContact,
    [Types.RESET]: surveyReset
})
