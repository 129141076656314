import { detect } from 'detect-browser';

export const getRandomHexString = (length) => {
    var letters = '0123456789ABCDEF';
    var hex = '';
    for (var i = 0; i < length; i++) {
        hex += letters[Math.floor(Math.random() * 16)];
    }
    return hex;
}

/** utility method used to determine whether not a viewable file should attempt to open in a new tab */
export const browserShouldOpenInTab = () => {
    let browser = detect();

    if (browser && browser.os === 'iOS' && (browser.name === 'safari' || browser.name === 'ios')) {
        return false;
    }
    return true;
}