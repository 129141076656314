export const hasOnlyValidChars = (value) => {
    var re = /^[A-Za-zÀ-ÖØ-öø-ÿ ’'\-\.]+$/   // eslint-disable-line no-useless-escape
    return re.test(value)
}

export const isValidEmail = (email) => {
    var reChars = /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~.@]+$/  // eslint-disable-line no-useless-escape
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape
    if (re.test(email)) {
        return reChars.test(email)
    }
    return false
}

const extractDigits = (data) => {
    if (!data) {
        return []
    }
    try {
        const matches = data.match(/\d/g)
        if (matches !== null) {
            return matches.map(Number)
        }
    } catch (ex) {
        console.log(ex)
    }

    return []
}

export const isValidPhone = (data) => {
    let digits = extractDigits(data)
    return Array.isArray(digits) && digits.length === 10
}

export const hintHasError = (hint, answer) => {
    if (!hint) {
        return true;
    }
    return !hint.localeCompare(answer, ["en", "fr"], { sensitivity: 'base' })
}

export const hintHasSpecialCharacters = (hint) => {
    var re = /[^\w\s]/gi;
    return re.test(hint.trim());
}
