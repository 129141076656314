import React, { Component, Fragment } from 'react';
import './style.css';
import T from 'i18n-react';
import ConfirmMissingDataLink from './confirmMissingDataLink'
import CheckBox from '../Common/checkbox'

class ConfirmIdUserSummary extends Component {
    constructor(props) {
        super(props)

        this.months = {
            en: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            fr: ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"]
        }

        this.state = {
            lang: this.props.lang,
            layoutType: this.props.layoutType,
            firstName: this.props.firstName,
            middleName: this.props.middleName,
            lastName: this.props.lastName,
            maskedSin: this.props.maskedSin,
            dob: this.props.dob,
            address: this.props.address,
            address2: this.props.address2,
            cityProvince: this.props.cityProvince,
            postal: this.props.postal,
            city: this.props.city,
            province: this.props.province,
            onEditPersonalInfo: this.props.onEditPersonalInfo,
            isVir: this.props.isVir,
            isProduction: this.props.isProduction,
            skipValidationChecked: this.props.skipValidationChecked,
            onSkipValidationChecked: this.props.onSkipValidationChecked
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }

        if (this.state.middleName !== newProps.middleName) {
            this.setState({ middleName: newProps.middleName })
        }

        if (this.state.firstName !== newProps.firstName) {
            this.setState({ firstName: newProps.firstName })
        }

        if (this.state.lastName !== newProps.lastName) {
            this.setState({ lastName: newProps.lastName })
        }

        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }

        if (this.state.maskedSin !== newProps.maskedSin) {
            this.setState({ maskedSin: newProps.maskedSin })
        }

        if (this.state.dob !== newProps.dob) {
            this.setState({ dob: newProps.dob })
        }

        if (this.state.address !== newProps.address) {
            this.setState({ address: newProps.address })
        }

        if (this.state.address2 !== newProps.address2) {
            this.setState({ address2: newProps.address2 })
        }

        if (this.state.cityProvince !== newProps.cityProvince) {
            this.setState({ cityProvince: newProps.cityProvince })
        }

        if (this.state.city !== newProps.city) {
            this.setState({ city: newProps.city })
        }

        if (this.state.province !== newProps.province) {
            this.setState({ province: newProps.province })
        }

        if (this.state.postal !== newProps.postal) {
            this.setState({ postal: newProps.postal })
        }

        if (this.state.onEditPersonalInfo !== newProps.onEditPersonalInfo) {
            this.setState({ onEditPersonalInfo: newProps.onEditPersonalInfo })
        }

        if (this.state.isVir !== newProps.isVir) {
            this.setState({ isVir: newProps.isVir })
        }

        if (this.state.isProduction !== newProps.isProduction) {
            this.setState({ isProduction: newProps.isProduction })
        }

        if (this.state.skipValidationChecked !== newProps.skipValidationChecked) {
            this.setState({ skipValidationChecked: newProps.skipValidationChecked })
        }

        if (this.state.onSkipValidationChecked !== newProps.onSkipValidationChecked) {
            this.setState({ onSkipValidationChecked: newProps.onSkipValidationChecked })
        }
    }

    editSummary() {
        if (this.state.onEditPersonalInfo) {
            this.state.onEditPersonalInfo();
        }
    }

    formatDob(dob) {
        // safely format even bad data here
        var day = ''
        var month = ''
        var year = ''

        if (dob) {
            var bits = dob.split('-')
            if (bits) {
                if (bits.length > 2) {
                    day = Number(bits[2])
                }
                if (bits.length > 1) {
                    var m = Number(bits[1] - 1)
                    month = this.months[this.state.lang][m]
                }
                if (bits.length > 0) {
                    year = Number(bits[0])
                }
            }
        }

        if (this.state.lang === 'fr') {
            return (day ? day + ' ' : '') + (month ? month + ' ' : '') + year
        } else {
            var x = (month ? month + ' ' : '') + day
            return (x && year ? x + ', ' : x) + year
        }
    }

    handleSkipValidationCheck(ev) {
        if (Boolean(ev.checked) !== this.state.skipValidationChecked) {
            this.state.onSkipValidationChecked(Boolean(ev.checked));
        }
    }

    render() {       
        var givenNames = this.state.firstName
        if (this.state.middleName) {
            givenNames += ' ' + this.state.middleName
        }
        var dob = this.formatDob(this.state.dob)

        var givenNamesContent = givenNames || (<ConfirmMissingDataLink field="givenNames" editSummary={() => this.editSummary()} />)
        var lastNameContent = this.state.lastName || (<ConfirmMissingDataLink field="lastName" editSummary={() => this.editSummary()} />)
        var dobContent = dob || (<ConfirmMissingDataLink field="dob" editSummary={() => this.editSummary()} />)
        var sinContent = this.state.maskedSin ? (
            <span>
                <span style={{ marginRight: '12px' }}>- - -</span>
                <span style={{ marginRight: '8px' }}>- - -</span>
                <span>{this.state.maskedSin ? this.state.maskedSin : "- - -"}</span>
            </span>)
            : (<ConfirmMissingDataLink field="sin" editSummary={() => this.editSummary()} />)
        var addressContent = this.state.address || this.state.address2 ?
            [(<span key='addr1'>{this.state.address}</span>), (<span key='addr2'>{this.state.address2}</span>)]
            : (<ConfirmMissingDataLink key='mdlink' field="address" editSummary={() => this.editSummary()} />)
        var cityContent = (this.state.city && this.state.province) ? this.state.cityProvince : (<ConfirmMissingDataLink field="city" editSummary={() => this.editSummary()} />)
        var postalContent = this.state.postal || (<ConfirmMissingDataLink field="postal" editSummary={() => this.editSummary()} />)
        
        return (            
            <div id="pii-exclude" className="confirmSummaryContainer topMargin bottomMargin mediumMargins">
                <div className="confirmSummaryRow">
                    <div className="confirmDiv confirmSummaryHeader headerWidth">
                        {T.translate("ConfirmIdentity.Summary.Given")}
                    </div>
                    <div className="confirmDiv confirmSummaryData dataWidth">
                        {givenNamesContent}
                    </div>
                </div>
                <div className="confirmSummaryRow">
                    <div className="confirmDiv confirmSummaryHeader headerWidth">
                        {T.translate("Interview.Page1.fieldLabel2")}
                    </div>
                    <div className="confirmDiv confirmSummaryData dataWidth">
                        {lastNameContent}
                    </div>
                </div>
                <div className="confirmSummaryRow">
                    <div className="confirmDiv confirmSummaryHeader headerWidth">
                        {T.translate("ConfirmIdentity.Summary.DateOfBirth")}
                    </div>
                    <div className="confirmDiv confirmSummaryData dataWidth">
                        {dobContent}
                    </div>
                </div>
                <div className="confirmSummaryRow">
                    <div className="confirmDiv confirmSummaryHeader headerWidth">
                        {T.translate("ConfirmIdentity.Summary.SIN")}
                    </div>
                    <div className="confirmDiv confirmSummaryData dataWidth">
                        {sinContent}
                    </div>
                </div>
                <div className="confirmSummaryRow">
                    <div className="confirmDiv confirmSummaryHeader headerWidth">
                        {T.translate("ConfirmIdentity.Summary.Address")}
                    </div>
                    <div className="confirmDiv confirmSummaryData dataWidth">
                        {addressContent}
                    </div>
                </div>
                <div className="confirmSummaryRow">
                    <div className="confirmDiv confirmSummaryHeader headerWidth">
                        {T.translate("ConfirmIdentity.Summary.CityProv")}
                    </div>
                    <div className="confirmDiv confirmSummaryData dataWidth">
                        {cityContent}
                    </div>
                </div>
                <div className="confirmSummaryRow">
                    <div className="confirmDiv confirmSummaryHeader headerWidth">
                        {T.translate("Interview.Page2.fieldLabel7")}
                    </div>
                    <div className="confirmDiv confirmSummaryData dataWidth">
                        {postalContent}
                    </div>
                </div>

                {!this.state.isVir ? <div className={this.state.isProduction ? "confirmSummaryLastRowProd" : "confirmSummaryLastRow"}>
                    <div className="confirmDiv headerWidth confirmSkipValidation whiteBackground">
                        {!this.state.isProduction &&
                            <Fragment>
                                <CheckBox isChecked={this.state.skipValidationChecked} onCheckChanged={(ev) => this.handleSkipValidationCheck(ev)} />
                                <span className="confirmSummaryFooterConsentSpan">Skip validation</span>
                            </Fragment>
                        }
                    </div>
                    <div className="confirmDiv confirmSummaryData dataWidth confirmMakeChanges whiteBackground">
                        <span> {T.translate('ConfirmIdentity.Summary.Change1')} </span>
                        <span className="linkText" onClick={(ev) => this.editSummary(ev)}>{T.translate('ConfirmIdentity.Summary.Change2')}</span>
                    </div>
                </div> : <div />}
            </div>
        );
    }
}

export default ConfirmIdUserSummary