import React, { Component } from 'react';
import './style.css';
import T from 'i18n-react';
import OnlineFormIcon from '../../images/OnlineFormIcon.svg';
import MyCRAIcon from '../../images/MyCRAIcon.svg';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'
import { Fragment } from 'react';
import classnames from 'classnames'

class AuthorizeSelectCtrl extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: this.props.lang,
            prepareForSpouse: this.props.prepareForSpouse,
            firstName: this.props.firstName,
            spouseFirstName: this.props.spouseFirstName,
            selectOption: this.props.selectOption,
            authChoiceSpouse: this.props.authChoiceSpouse,
            authChoiceSelf: this.props.authChoiceSelf
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }
        if (this.state.spouseFirstName !== newProps.spouseFirstName) {
            this.setState({ spouseFirstName: newProps.spouseFirstName })
        }
        if (this.state.firstName !== newProps.firstName) {
            this.setState({ firstName: newProps.firstName })
        }
        if (this.state.prepareForSpouse !== newProps.prepareForSpouse) {
            this.setState({ prepareForSpouse: newProps.prepareForSpouse })
        }
        if (this.state.selectOption !== newProps.selectOption) {
            this.setState({ selectOption: newProps.selectOption })
        }
        if (this.state.authChoiceSpouse !== newProps.authChoiceSpouse) {
            this.setState({ authChoiceSpouse: newProps.authChoiceSpouse })
        }
        if (this.state.authChoiceSelf !== newProps.authChoiceSelf) {
            this.setState({ authChoiceSelf: newProps.authChoiceSelf })
        }
    }

    selectOption(target, option) {
        if (this.state.selectOption) {
            this.state.selectOption(target, option)
        }
    }

    render() {
        return (
            <Fragment>
                <div className='auth-selection-card-wrapper'>
                    <Card elevation={1} className='auth-card'>
                        <CardContent className={classnames('auth-card-content', this.state.lang === 'fr' ? this.state.prepareForSpouse ? 'auth-card-content-fr-s' : 'auth-card-content-fr' : '')}>
                            <div className="authorizeSelectCtrlOptionContentHeader">
                                <img src={OnlineFormIcon} />
                                <div className='auth-type-header-wrapper'>
                                    <div className='auth-type-header'>
                                        {T.translate('Authorize.OnlineForm')}
                                    </div>
                                    <div className='auth-type-effort'>
                                        {T.translate('Authorize.OnlineFormEffort')}
                                    </div>
                                </div>
                            </div>
                            <div className='auth-selection-content-wrapper'>
                                <div className='auth-selection-subtext'>
                                    {T.translate('Authorize.OptionFormsSubtext')}
                                </div>
                                <div className='auth-select-radio-buttons'>
                                    <div className='auth-selection-component-wrapper'>
                                        <input type='radio' id='OnlineSelectionPrimaryRadio' checked={this.state.authChoiceSelf === 'T1013'} className='auth-select-radio' onChange={() => this.selectOption('self', 'T1013')} />
                                        <label htmlFor='OnlineSelectionPrimaryRadio' className='auth-selection-label'>{this.state.firstName}</label>
                                    </div>
                                    {this.state.prepareForSpouse &&
                                        <div className='auth-selection-component-wrapper'>
                                            <input type='radio' id='OnlineSelectionSpouseRadio' checked={this.state.authChoiceSpouse === 'T1013'} className='auth-select-radio' onChange={() => this.selectOption('spouse', 'T1013')} />
                                            <label htmlFor='OnlineSelectionSpouseRadio' className='auth-selection-label'>{this.state.spouseFirstName}</label>
                                        </div>}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    <div className='auth-card-or'>
                        {T.translate('Authorize.OptionsOr')}
                    </div>
                    <Card elevation={1} className='auth-card'>
                        <CardContent className={classnames('auth-card-content', this.state.lang === 'fr' ? this.state.prepareForSpouse ? 'auth-card-content-fr-s' : 'auth-card-content-fr' : '')}>
                            <div className="authorizeSelectCtrlOptionContentHeader">
                                <img src={MyCRAIcon} />
                                <div className='auth-type-header-wrapper'>
                                    <div className='auth-type-header'>
                                        {T.translate('Authorize.CRATitle')}
                                    </div>
                                    <div className='auth-type-effort'>
                                        {T.translate('Authorize.CRAEffort')}
                                    </div>
                                </div>
                            </div>
                            <div className='auth-selection-content-wrapper'>
                                <div className='auth-selection-subtext'>
                                    {T.translate('Authorize.OptionCRASubtext')}
                                </div>
                                <div className='auth-select-radio-buttons'>
                                    <div className='auth-selection-component-wrapper'>
                                        <input type='radio' id='MyCRASelectionPrimaryRadio' checked={this.state.authChoiceSelf === 'MyCRA'} className='auth-select-radio' onChange={() => this.selectOption('self', 'MyCRA')} />
                                        <label htmlFor='MyCRASelectionPrimaryRadio' className='auth-selection-label'>{this.state.firstName}</label>
                                    </div>
                                    {this.state.prepareForSpouse &&
                                        <div className='auth-selection-component-wrapper'>
                                            <input type='radio' id='MyCRASelectionSpouseRadio' checked={this.state.authChoiceSpouse === 'MyCRA'} className='auth-select-radio' onChange={() => this.selectOption('spouse', 'MyCRA')} />
                                            <label htmlFor='MyCRASelectionSpouseRadio' className='auth-selection-label'>{this.state.spouseFirstName}</label>
                                        </div>}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Fragment>
        );
    }
}

export default AuthorizeSelectCtrl