import React, { Component } from 'react';
import { connect } from 'react-redux'
import '../Interview/style.css'
import './style.css';
import HelpCentre from '../../sagas/Help/helpCentre'
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';
import PlainButton from '../Controls/plainButton'

import UpdatePersonalInfoForm from '../Common/updatePersonalInfoForm'
import ConfirmIdentityActions from '../../reducers/confirmIdentityRedux'

class ConfirmIdEditPersonalInfo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lang: this.props.lang,
            layoutType: this.props.layoutType,
            workingData: this.props.workingData,
            saving: this.props.saving
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }

        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }

        if (this.state.workingData !== newProps.workingData) {
            this.setState({ workingData: newProps.workingData })
        }       

        if (this.state.saving !== newProps.saving) {
            this.setState({ saving: Boolean(newProps.saving) })
        }       
    }

    onPersonalInfoChanged(fieldName, value) {
        this.props.updateWorkingData(fieldName, value)
    }

    handleCancel() {
        if (!this.state.saving) {
            this.props.initializeWorkingData(this.state.workingData.taxYear, 'summary', {}, {});
        }
    }

    isValidPostalCode(data) {
        if (!data) {
            return true;
        }
        if (data.match(/[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/)) {
            return true
        }
        return false
    }

    validateData() {
        return Boolean(this.state.workingData.firstName) && Boolean(this.state.workingData.lastName) 
            && Boolean(this.state.workingData.dob) && Boolean(this.state.workingData.sin)
            && Boolean(this.state.workingData.city) && Boolean(this.state.workingData.province)
            && Boolean(this.state.workingData.postalCode) && ((Boolean(this.state.workingData.streetNumber)
                && Boolean(this.state.workingData.streetName)) || Boolean(this.state.workingData.poBox)
                || Boolean(this.state.workingData.ruralRouteNumber)) && this.state.workingData.postalCode
    }

    handleContinueClick() {
        var validated = this.validateData()
        if (!validated) {
            this.props.validateWorkingData();
        } else {
            if (this.isValidPostalCode(this.state.workingData.postalCode)) {
                this.props.saveWorkingData();
            }
        }
    }

    showAddressHelp() {
        HelpCentre.openHelpLink('OTE_FAQ/HowShouldIEnterMyAddress')
    }

    render() {
        var validationError = null
        var isValidData = this.validateData()
        if (!isValidData) {
            validationError = (
                <div className="validationErrorStyle confirmValidateErrorStyle alignMeRight">
                    <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#E70000', align: 'center' }} />
                    <span style={{ marginLeft: 10 }}>{T.translate('ConfirmIdentity.Error.MissingRequiredField')}</span>
                </div>
            )
        }

        var inverted = this.state.layoutType === 'mobile'

        return (
            <div className="confirmContainerOuter">
                <div className="confirmContainer">       
                    <div className="confirmBackButtonStyle" onClick={(e) => this.handleCancel()}>
                        <FontAwesome className='fa-angle-left' name='fa-angle-left' size='2x' />
                        <h4>{T.translate('ConfirmIdentity.Edit.BackLabel')}</h4>
                    </div>
                    <div>
                        <h1 className="noTopMargin">{T.translate("ConfirmIdentity.Edit.Title")}</h1>
                        <div style={{ height: 20 }} />
                    </div>
                    <div className="confirmIdHelpHolder bottomMargin">
                        <span className="learnMoreStyle" onClick={() => this.showAddressHelp()}>{T.translate(`Common.AddressHelp.${this.props.showOteContent ? 'Ote' : 'Direct'}`)}</span>
                    </div>
                    <div className="confirmSummaryFooterConsentRow">
                        <UpdatePersonalInfoForm validate
                            personalInfo={this.state.workingData}
                            onPersonalInfoChanged={(fieldName, value) => this.onPersonalInfoChanged(fieldName, value)}
                        />
                    </div>
                </div>
                <div className="confirmSummaryFooterContainer">
                    {validationError}
                    <div className="confirmSummaryFooterContinueRow topMargin">
                        <div className="confirmEditFooterButtonContainer">
                            <PlainButton labelKey='Logout.labels.Cancel' inverted={inverted} isEnabled={!this.state.saving} onClick={(ev) => this.handleCancel(ev)} />                    
                        </div>
                        <div className="confirmEditFooterButtonContainer">
                            <PlainButton labelKey='ConfirmIdentity.Edit.ButtonLabel' inProgress={this.state.saving} isEnabled={Boolean(isValidData)} onClick={(ev) => this.handleContinueClick(ev)} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        layoutType: state.layout.layoutType,
        lang: state.layout.lang,
        workingData: state.confirmIdentity.workingData,
        interviewTaxYear: ownProps.interviewTaxYear,
        showOteContent: state.workflow.is_direct_user === false
    }
}


const mapDispatchToProps = (dispatch) => ({
    initializeWorkingData: (taxYear, stage, workingData, addressData) => dispatch(ConfirmIdentityActions.initializeWorkingData(taxYear, stage, workingData, addressData)),
    updateWorkingData: (fieldName, value) => dispatch(ConfirmIdentityActions.updateWorkingData(fieldName, value)),
    validateWorkingData: () => dispatch(ConfirmIdentityActions.validateWorkingData()),
    saveWorkingData: () => dispatch(ConfirmIdentityActions.saveWorkingData())
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmIdEditPersonalInfo)
