import React, { Component } from 'react';
import '../../Common/shared.css'
import '../style.css';

import T from 'i18n-react';
import PersonalInfoForm from '../../Common/personalInfoForm'
import YesNoQuestion from '../../Common/yesNoQuestion'
import HighlightedSection from '../../Interview/highlightedSection'
import HelpCentre from '../../../sagas/Help/helpCentre'
import InterviewTitleProgress from '../../Common/interviewTitleProgress'
import { connect } from 'react-redux';


class InterviewPage1 extends Component {
    constructor(props) {
        super(props)

        var primary = this.props.primary || {
            firstName: '',
            middleName: '',
            lastName: '',
            dob: '',
            sin: '',
            nameChange: ''
        }

        this.state = {
            primary,
            missingRequiredField: this.props.missingRequiredField,
            layoutType: this.props.layoutType,
            lang: this.props.lang,
            taxYear: this.props.taxYear,
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.primary !== newProps.primary) {
            this.setState({primary: newProps.primary})
        }
        if (this.state.missingRequiredField !== newProps.missingRequiredField) {
            this.setState({missingRequiredField: newProps.missingRequiredField})
        }
        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }
        if (this.state.taxYear !== newProps.taxYear) {
            this.setState({ taxYear: newProps.taxYear})
        }

    }

    helpClick() {
        HelpCentre.openHelpLink('FAQs/WhatShouldIDoIfMyOrMySpousesNameChanged')
    }

    onPersonalInfoChanged(personalInfo) {
        var primary = {
            ...this.primary,
            firstName: personalInfo.firstName,
            middleName: personalInfo.middleName,
            lastName: personalInfo.lastName,
            dob: personalInfo.dob,
            sin: personalInfo.sin,
            nameChange: personalInfo.nameChange
        }
        this.setState({ primary })
        if (this.props.onPrimaryChanged) {
            this.props.onPrimaryChanged(primary)
        }
    }
   
    validateNameField(textValue) {
        return !textValue || textValue.match(/^[a-zA-Z\u00C0-\u017F\'\.\-\s]+$/)   // eslint-disable-line no-useless-escape
    }

    onNameChangeButtonChanged(ev, value) {
        if (ev) {
            var primary = {
                ...this.state.primary,
                nameChange: value
            }
            this.setState({ primary });
            if (this.props.onPrimaryChanged) {
                this.props.onPrimaryChanged(primary)
            }
        }
    }

    render() {        

        let missingNameChange = this.state.missingRequiredField && !this.state.primary.nameChange

        var content =
            <div>
                <InterviewTitleProgress taxYear={this.props.taxYear} percentage="0" title="Interview.Page1.headerTitle" nextTitle="Interview.Page1.nextTitle" />
            </div>

        var nameChange =<div>
            <YesNoQuestion name="nameChange" value={this.state.primary.nameChange} isReturningClient={this.props.isReturningClient}
                autoWidth="true" labelText={T.translate('Interview.Page1.fieldLabel6', { TaxYear: this.props.taxYear })} isRequired={true}
                learnMoreId="FAQs/WhatShouldIDoIfMyOrMySpousesNameChanged" centerAlignThis={true}
                missingRequiredField={missingNameChange} onAnswerChanged={(ev, value) => { this.onNameChangeButtonChanged(ev, value) }} />
            {this.state.primary.nameChange === 'Y' && <br />}
            {this.state.primary.nameChange === 'Y' && T.translate('Interview.Page1.NameChangeText') + ' '}
            {this.state.primary.nameChange === 'Y' && this.props.showLinks && <span className="learnMoreStyle unselectableLabel" onClick={() => this.helpClick()}>{T.translate('Interview.Page1.NameChangeHowToLink')}</span>}
         </div>

        if (this.props.isReturningClient) {
            content =
                <div>
                    <div>
                        <h1>{T.translate('ExistingInterview.Page1.headerTitle', { TaxYear: this.props.taxYear })}</h1>
                    </div>
                    <HighlightedSection additionalClasses="interview-green-header-text interview-green-overflow-margin" isReturningClient={this.props.isReturningClient} margin={20} >
                        <T.text text="ExistingInterview.Page1.headerText" />
                    </HighlightedSection >
                </div>

            nameChange =
                <HighlightedSection isReturningClient={this.props.isReturningClient} additionalClasses="interview-green-header-text interview-dialogue-header-text interview-green-overflow-margin" margin={20}>
                    <YesNoQuestion name="nameChange" value={this.state.primary.nameChange} isReturningClient={this.props.isReturningClient}
                        autoWidth="true" centerAlignThis={true} labelText={T.translate('Interview.Page1.fieldLabel6', { TaxYear: this.props.taxYear })} isRequired={true}
                        missingRequiredField={missingNameChange} onAnswerChanged={(ev, value) => { this.onNameChangeButtonChanged(ev, value) }} />
                    {this.state.primary.nameChange === 'Y' && <br />}
                    {this.state.primary.nameChange === 'Y' && T.translate('Interview.Page1.NameChangeText') + ' '}
                    {(this.state.primary.nameChange === 'Y' && this.props.showLinks) && <span className="learnMoreStyle unselectableLabel" onClick={() => this.helpClick()}>{T.translate('Interview.Page1.NameChangeHowToLink')}</span> }
                </HighlightedSection >
        }


        return (
            <div className="validatingTextInputStyle">
                {content}
                <PersonalInfoForm simpleVersion={false} personalInfo={this.state.primary} page={'page1'}
                    lang={this.state.lang} allRequiredFields
                    validateMinAge labelKey="Interview.Page1" missingRequiredField={this.state.missingRequiredField}
                    onPersonalInfoChanged={(personalInfo) => this.onPersonalInfoChanged(personalInfo)} />
                <div className="horizontalFieldContainerCenterAligned largeTopMargin">
                    {nameChange}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.layout.lang,
        showLinks: !state.workflow.is_direct_user
    };
};

export default connect(mapStateToProps)(InterviewPage1);