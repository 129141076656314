import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    setStep: ['step'],
    setImageIndex: ['imageIndex'],
    setStepWithMessage: ['step', 'progressMessageKey'],
    setError: ['error'],
    setProgressMessageKey: ['progressMessageKey'],
    setErrorMessageKey: ['errorMessageKey'],
    setTryAgainAllowed: ['tryAgainAllowed'],
    setConsentChecked: ['consentChecked'],
    setCredStatePassword: ['password'],
    setCredStateHint: ['hint'],
    setCredStateAnswer: ['answer'],
    setCredStateQuestionIndex: ['questionIndex', 'question'],
    setCredStateTermsAccepted: ['termsAccepted'],    
    resetRegisterState: null
})

export const RegisterTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    step: 'start',
    imageIndex: 0,
    error: null,
    progressMessageKey: null,
    errorMessageKey: 'AccountSetupProgress.errorMessages.FailLookup',
    tryAgainAllowed: true,
    consentChecked: false,   
    credentialState: {
        password: {
            value: '',
            isValid: false
        },
        hint: '',
        answer: '',
        question: null,
        questionIndex: -1,
        termsAccepted: false
    }
}

/* ------------- Reducers ------------- */

export const registerSetStep = (state, { step }) => 
    Object.assign({}, state, { step: step })

export const registerSetImageIndex = (state, { imageIndex }) =>
    Object.assign({}, state, { imageIndex: imageIndex })

export const registerSetStepWithMessage = (state, { step, progressMessageKey }) => 
    Object.assign({}, state, { step: step, progressMessageKey: progressMessageKey })

export const registerSetError = (state, { error }) =>
    Object.assign({}, state, { error: error })

export const registerSetProgressMessageKey = (state, { progressMessageKey }) =>
    Object.assign({}, state, { progressMessageKey: progressMessageKey })

export const registerSetErrorMessagekey = (state, { errorMessageKey }) =>
    Object.assign({}, state, { errorMessageKey: errorMessageKey })

export const registerSetTryAgainAllowed = (state, { tryAgainAllowed }) =>
    Object.assign({}, state, { tryAgainAllowed: tryAgainAllowed })

export const registerSetConsentChecked = (state, { consentChecked }) =>
    Object.assign({}, state, { consentChecked: consentChecked })

export const registerSetCredStatePassword = (state, { password }) =>
    Object.assign({}, state, { credentialState: { ...state.credentialState, password: password } })

export const registerSetCredStateHint = (state, { hint }) =>
    Object.assign({}, state, { credentialState: { ...state.credentialState, hint: hint } })

export const registerSetCredStateAnswer = (state, { answer }) =>
    Object.assign({}, state, { credentialState: { ...state.credentialState, answer: answer } })

export const registerSetCredStateQuestionIndex = (state, { questionIndex, question }) =>
    Object.assign({}, state, { credentialState: { ...state.credentialState, questionIndex: questionIndex, question: question }})

export const registerSetCredStateTermsAccepted = (state, { termsAccepted }) =>
    Object.assign({}, state, { credentialState: { ...state.credentialState, termsAccepted: termsAccepted } })

export const registerResetState = (state) => INITIAL_STATE 
 
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_STEP]: registerSetStep,
    [Types.SET_IMAGE_INDEX]: registerSetImageIndex,
    [Types.SET_STEP_WITH_MESSAGE]: registerSetStepWithMessage,
    [Types.SET_ERROR]: registerSetError,
    [Types.SET_PROGRESS_MESSAGE_KEY]: registerSetProgressMessageKey,
    [Types.SET_ERROR_MESSAGE_KEY]: registerSetErrorMessagekey,
    [Types.SET_TRY_AGAIN_ALLOWED]: registerSetTryAgainAllowed,
    [Types.SET_CONSENT_CHECKED]: registerSetConsentChecked,
    [Types.SET_CRED_STATE_PASSWORD]: registerSetCredStatePassword,
    [Types.SET_CRED_STATE_HINT]: registerSetCredStateHint,
    [Types.SET_CRED_STATE_ANSWER]: registerSetCredStateAnswer,
    [Types.SET_CRED_STATE_QUESTION_INDEX]: registerSetCredStateQuestionIndex,
    [Types.SET_CRED_STATE_TERMS_ACCEPTED]: registerSetCredStateTermsAccepted,    
    [Types.RESET_REGISTER_STATE]: registerResetState
})
