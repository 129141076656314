import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import './style.css';
import MessagesView from './messagesView'
import MessagesInputScreen from './messageInputScreen'
import MessagesActions from '../../reducers/messagesRedux'
import LoadingSpinner from '../Loading/loadingSpinner'
import MessagesTimers from './messagesTimers'

const Messages = () => {
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    const [rootStyles, setRootStyles] = useState(["messageMainBody"])

    const {
        inputScreenShowing,
        messages,
        messagesLoaded,
        messageInProgress,
        saveContentPromptShowing,
        messageSendState
    } = useSelector(state => state.messages, shallowEqual);
    const dashboardTab = useSelector(state => state.layout.dashboardTab)
    const lang = useSelector(state => state.layout.lang)
    const layoutType = useSelector(state => state.layout.layoutType)
    const unreadMessagesCount = useSelector(state => state.messages.unreadMessagesCount)

    const getInitials = (firstName, lastName) => {
        var initials = ""
        if (firstName) {
            initials += firstName.substring(0, 1)
        }
        if (lastName) {
            initials += lastName.substring(0, 1)
        }
        return initials
    }

    const initials = useSelector(state => getInitials(state.accountData.firstName, state.accountData.lastName))

    useEffect(() => {
        if (!loaded && messages && messagesLoaded) {
            setLoaded(true)
        }
    }, [messages, loaded, messagesLoaded])

    const showMessageInputScreen = (show) => {
        dispatch(MessagesActions.setInputScreenShowing(show))
    }

    const updateLastReadMessages = () => {
        dispatch(MessagesActions.setMessagesLoaded(false))
        dispatch(MessagesActions.updateLastReadDate())
    }

    useEffect(() => {
        var lt = layoutType
        if (layoutType === 'small_tablet') {
            lt = 'tablet'
        }

        if (!rootStyles.includes(lt)) {
            setRootStyles(["messageMainBody", lt])
        }
    }, [layoutType, rootStyles])


    return (
        <div className="tabContentMessages">
            {loaded &&
                <div className={rootStyles.join(' ')}>
                    <MessagesView visible={!inputScreenShowing}
                        messages={messages}
                        showInputScreenHandler={showMessageInputScreen}
                        initials={initials} dashboardTab={dashboardTab}
                        layoutType={layoutType} lang={lang}
                        updateLastReadMessages={updateLastReadMessages}
                        messagesLoaded={messagesLoaded} loaded={loaded}
                        unreadMessagesCount={unreadMessagesCount}
                    />
                    <MessagesInputScreen visible={inputScreenShowing}
                        message={messageInProgress} layoutType={layoutType}
                        messageSendState={messageSendState} lang={lang}
                        showInputScreenHandler={showMessageInputScreen}
                        saveContentPromptShowing={saveContentPromptShowing} />
                </div>
            }
            {!loaded &&
                <LoadingSpinner />
            }
            <MessagesTimers />
        </div>
    )
}



export default Messages
