import React, { Component } from 'react';
import '../../Controls/progressModal.css';
import '../style.css'
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome'
import PlainButton from '../../Controls/plainButton'

class AddressCheckContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: this.props.lang,
            layoutType: this.props.layoutType,
            stage: this.props.stage,
            onAddAddress: this.props.onAddAddress,
            onConfirmIdentity: this.props.onConfirmIdentity
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.lang !== this.state.lang) {
            this.setState({ lang: newProps.lang })
        }

        if (newProps.layoutType !== this.state.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }

        if (newProps.stage !== this.state.stage) {
            this.setState({ stage: newProps.stage })
        }

        if (newProps.onAddAddress !== this.state.onAddAddress) {
            this.setState({ onAddAddress: newProps.onAddAddress })
        }

        if (newProps.onConfirmIdentity !== this.state.onConfirmIdentity) {
            this.setState({ onConfirmIdentity: newProps.onConfirmIdentity })
        }
    }

    handleAddAddress() {
        if (this.state.onAddAddress) {
            this.state.onAddAddress();
        }
    }

    handleConfirmIdentity() {
        if (this.state.onConfirmIdentity) {
            this.state.onConfirmIdentity();
        }
    }

    render() {
        var titleKey = null
        var faContent = null
        var bodyContent = []
        var containerClass = ["confirmRootContainer confirmModalWidth"]
        if (this.state.layoutType === 'mobile') {
            containerClass.push('mobile')
        }
        var spacerHeight = this.state.layoutType === 'mobile' ? '20px' : '40px'

        if (this.state.stage === 'addressCheck') {
            titleKey = 'ConfirmIdentity.AddressCheck.Title'
            faContent = (<FontAwesome className='fa-home' name='home' size='4x' />)
            bodyContent.push(<span key='content1' className="confirmModalContainerSpan">{T.translate('ConfirmIdentity.AddressCheck.SubTitle')}</span>)
            bodyContent.push(<div key='space1' style={{ height: spacerHeight }} />)
            bodyContent.push(
                <div key='okbutton' className="confirmModalContainer">
                    <PlainButton labelKey="Common.Yes" inverted onClick={(ev) => this.handleAddAddress(ev)} />
                    <div style={{ width: '30px', height: '10px' }} />
                    <PlainButton labelKey="Common.No" onClick={(ev) => this.handleConfirmIdentity(ev)} />
                </div>
            )
        }

        return (
            <div className={containerClass.join(' ')}>
                <div className="confirmProgressSpinnerContainer greenContent">
                    {faContent}
                </div>
                <h2>{T.translate(titleKey)}</h2>
                {bodyContent}
            </div>
        );
    }
}

export default AddressCheckContent