import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/fr'
import './shared.css';
import T from 'i18n-react';
import { connect } from 'react-redux'

class TimeFromLastDate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lastModifiedDate: this.props.lastModifiedDate,
            ticketType: this.props.ticketType
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.lastModifiedDate !== newProps.lastModifiedDate) {
            this.setState({ lastModifiedDate: newProps.lastModifiedDate})
        }
    }

    render() {
        let content = null
        if (this.state.lastModifiedDate) {
            var lastModified = new Date(this.state.lastModifiedDate) // assume UTC is coming back from the server already
            if (Object.prototype.toString.call(lastModified) !== '[object Date]') {
                content = null;
            } else {
                var currentdate = moment.utc(new Date()).toDate()
             
                // get total seconds between the times
                var delta = Math.abs(currentdate - lastModified) / 1000;

                // calculate (and subtract) whole days
                var days = Math.floor(delta / 86400);
                delta -= days * 86400;

                // calculate (and subtract) whole hours
                var hours = Math.floor(delta / 3600) % 24;
                delta -= hours * 3600;

                // calculate (and subtract) whole minutes
                var minutes = Math.floor(delta / 60) % 60;
                delta -= minutes * 60;

                if (days > 0) {
                    var stringTime = '';
                    if (this.props.lang === 'fr') {
                        stringTime = moment(lastModified).locale(this.props.lang).format('D MMMM YYYY')
                    } else {
                        stringTime = moment(lastModified).locale(this.props.lang).format('MMM D, YYYY')
                    }

                        content = T.translate("TimeSinceLast.Date", { date: stringTime });
                        
                } else if (hours > 0) {
                    content = T.translate("TimeSinceLast.Hours", { hours: hours });
                } else {
                    content = T.translate("TimeSinceLast.Minutes", { minutes: minutes > 0 ? minutes : 1 });
                } 
            }
        }
        return (
            <div className={this.state.ticketType !== 'RTE' ? 'timeSinceLastModified' : 'timeSinceLastModifiedRTE'}>
                {content}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.layout.lang
    }
}

export default connect(mapStateToProps, null)(TimeFromLastDate)