import { push } from 'connected-react-router';
import T from 'i18n-react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import PlainButton from '../Controls/plainButton';
import CheckmarkIcon from '@mui/icons-material/CheckCircleOutline';
import './acceptedPage.css';
import classnames from 'classnames';
import DateTime from '../Controls/dateTime';
import PaymentActions from '../../reducers/paymentRedux'
import LayoutActions from '../../reducers/layoutRedux';

const AcceptedPage = props => {

    const { layoutType } = props
    const [transactionId, setTransactionId] = useState();
    const [transactionDate, setTransactionDate] = useState();
    const [approvalCode, setApprovalCode] = useState();
    const [transactionAmount, setTransactionAmount] = useState();
    const [transactionDollars, setTransactionDollars] = useState();
    const [transactionCents, setTransactionCents] = useState('00');
    const [transactionEmail, setTransactionEmail] = useState();
    const search = useLocation().search;

    const onBackToDashClicked = () => {
        props.setDashboardTab('dashboard')
        props.pushNavigation('/dashboard')
    }

    useEffect(() => {

        const hasTrnId = new URLSearchParams(search).has('trnId');
        const hasTrnDate = new URLSearchParams(search).has('trnDate');
        const hasAuthCode = new URLSearchParams(search).has('authCode');
        const hasTrnAmount = new URLSearchParams(search).has('trnAmount');
        const hasTrnEmailAddress = new URLSearchParams(search).has('trnEmailAddress');

        setTransactionId(new URLSearchParams(search).get('trnId'));
        setTransactionDate(new URLSearchParams(search).get('trnDate'))
        setApprovalCode(new URLSearchParams(search).get('authCode'))
        setTransactionAmount(new URLSearchParams(search).get('trnAmount'))
        setTransactionEmail(new URLSearchParams(search).get('trnEmailAddress'))

        if (transactionAmount && (transactionAmount.includes('.'))) {
            var dollars = transactionAmount.split('.')[0];
            var cents = transactionAmount.split('.')[1];
            setTransactionDollars(dollars);
            setTransactionCents(cents);
        }

        if (transactionId && transactionDate && approvalCode && transactionAmount && transactionEmail) {
            props.completePayment(window.location.href)
            window.history.replaceState(null, 'H&R Block Canada', '/dashboard/approved')
        }
        else if (!hasTrnId || !hasTrnDate || !hasAuthCode || !hasTrnAmount || !hasTrnEmailAddress) {
            props.setDashboardTab('dashboard')
            props.pushNavigation('/dashboard')
        }

    }, [transactionId, transactionDate, approvalCode, transactionAmount, transactionEmail])      
        
    return (
        <div className='approvedContainer'>
            <div className={classnames('body', layoutType)}>
                <div className='errorContainer'>
                    <h1>{T.translate("Payment.AcceptedPage.Title")}</h1>
                    <CheckmarkIcon style={{ color: "#007C10", fontSize: 88, marginBottom: 10, marginTop: 30 }} />
                    <div className='content-align'>
                        <h3>{T.translate("Payment.AcceptedPage.TransactionIdLabel")} {transactionId}</h3>
                        <h3>{T.translate("Payment.AcceptedPage.TransactionDateLabel")} <DateTime value={transactionDate} fallbackValue='-' /></h3>
                        <h3>{T.translate("Payment.AcceptedPage.TransactionApprovalCodeLabel")}{approvalCode}</h3>
                        <div style={{ marginBottom: 10 }} />
                        <p>{T.translate("Payment.AcceptedPage.ThankYouMessage", { AmountPaid: (transactionDollars + T.translate("CurrencyDelimiter") + transactionCents) })}</p>
                        <p>{T.translate("Payment.AcceptedPage.ReceivedMessage")}</p>
                        <p>{T.translate("Payment.AcceptedPage.EmailMessage", { EmailAddress: transactionEmail })}</p>
                    </div>
                    <div className='error-back-btn-container' >
                        <PlainButton labelKey='Payment.AcceptedPage.ButtonText' onClick={() => onBackToDashClicked()} />
                    </div>
                </div>
            </div>
        </div>
    );   
}

const mapStateToProps = (state) => {
    return {
        lang: state.layout.lang,
        layoutType: state.layout.layoutType
    }
}

const mapDispatchToProps = (dispatch) => ({
    completePayment: (url) => dispatch(PaymentActions.completePayment(url)),
    pushNavigation: (path) => dispatch(push(path)),
    setDashboardTab: (tab) => dispatch(LayoutActions.setDashboardTab(tab))
});

export default connect(mapStateToProps, mapDispatchToProps)(AcceptedPage)
