import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import '../dox_explorer.css'
import DoxModal from '../../Shared/components/doxModal'
import { useTranslationContext } from '../../Shared/hooks/useTranslationContextHook'
import DoxButton from '../../Shared/components/DoxButton'

const DoxExplorerDeleteModal = ({ file, isOpen, onCancel, onDeleteFile, deleteError, layoutType }) => {
    const translateString = useTranslationContext();
    const [working, setWorking] = useState(false)
    const [errorLabelKey, setErrorLabelKey] = useState(null)

    const handleCancel = () => {
        onCancel()
    }

    const handleDeleteFile = () => {
        setWorking(true);
        onDeleteFile(file.documentId)
    }

    useEffect(() => {
        var lk = null
        if (deleteError === 'fail') {
            lk = 'Dox.DocumentExplorer.GenericFail'
        }
        if (lk !== errorLabelKey) {
            setErrorLabelKey(lk)
            if (lk) {
                setWorking(false)
            }
        }
    }, [deleteError, errorLabelKey])

    return (
        <DoxModal show={isOpen} onClose={handleCancel} layoutType={layoutType}>
            {file &&
                <div className='doxe-deleteModal'>
                    <div>{translateString('Dox.DocumentExplorer.DeletePrompt', { FileName: file.fileName + file.fileType })}</div>
                    {errorLabelKey && 
                        <div className='dox-error-text'>{translateString(errorLabelKey)}</div>
                    }
                    <div className='doxe-modal-btns'>
                        <div className='dox-link' onClick={handleCancel}>{translateString('Common.Cancel')}</div>
                    <DoxButton labelKey='Dox.DocumentExplorer.ConfirmDelete' onClick={handleDeleteFile}
                        loading={working} buttonStyles='primary' elementId='doxe-delete'/>
                    </div>
                </div>
            }
        </DoxModal>
    )
}

DoxExplorerDeleteModal.propTypes = {
    layoutType: PropTypes.string.isRequired,
    file: PropTypes.shape({
                documentId: PropTypes.string.isRequired,
        fileType: PropTypes.string.isRequired,
        fileName: PropTypes.string.isRequired,
        modifiedTs: PropTypes.string.isRequired
    }),
    onCancel: PropTypes.func.isRequired,
    onDeleteFile: PropTypes.func.isRequired,
    deleteError: PropTypes.string
}   

export default DoxExplorerDeleteModal;