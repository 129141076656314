import axios from 'axios';
import { getApiBaseUrl } from '../../services/serviceUtils'

export const getAccountInfo = (accessToken, accountId) => {
    var apiBaseUrl = getApiBaseUrl();

    var axiosOpts = {
        method: 'GET',
        url: apiBaseUrl + 'account/accountinfo',
        data: '',
        headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        },
        json: true
    }

    return axios(axiosOpts);
}

export const saveAccountInfo = (accessToken, accountId, accountData) => {
    var apiBaseUrl = getApiBaseUrl();

    var payload = {
        "AccountId": accountId,
        "LanguagePreference": accountData.currentLanguage,
        "Email": accountData.email,
        "FirstName": accountData.firstName,
        "LastName": accountData.lastName,
        "PhoneNumber": accountData.phoneNumber.value,
        "PhoneTypeId": accountData.phoneType,
        "Province": accountData.province,
        "Question": accountData.securityQuestion,
        "Answer": accountData.securityAnswer,
        "Hint": accountData.securityHint
    }

    var axiosOpts = {
        method: 'POST',
        url: apiBaseUrl + 'account/accountinfo',
        data: payload,
        headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        },
        json: true
    }

    return axios(axiosOpts);
}

export const saveAccountInfoLight = (accessToken, accountId, accountData, lang) => {
    var apiBaseUrl = getApiBaseUrl();

    var payload = {
        "AccountId": accountId,
        "LanguagePreference": lang,
        "Email": accountData.email,
        "FirstName": accountData.firstName,
        "LastName": accountData.lastName,
        "PhoneNumber": accountData.mobilePhone,
        "PhoneTypeId": accountData.phoneType,
        "Province": accountData.province,
        "Question": null,
        "Answer": null,
        "Hint": null
    }

    var axiosOpts = {
        method: 'POST',
        url: apiBaseUrl + 'account/accountinfo',
        data: payload,
        headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        },
        json: true
    }

    return axios(axiosOpts);
}