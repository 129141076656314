import { createStore, applyMiddleware, compose } from 'redux'
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

export const history = createBrowserHistory();

// creates the store
export default (rootReducer, rootSaga) => {
    /* ------------- Redux Configuration ------------- */

    const middleware = []
    const enhancers = []


    /* --------------- Router Middleware ----------------*/    
    const rtrMiddleware = routerMiddleware(history)
    middleware.push(rtrMiddleware)

    /* --------------- Saga Middleware --------------- */

    const sagaMonitor = null
    const sagaMiddleware = createSagaMiddleware({ sagaMonitor })
    middleware.push(sagaMiddleware)

    /*-- Only show the logger in dev/test & ignore activity and window events --*/
    if (process.env.REACT_APP_ENV === 'DEV' || process.env.REACT_APP_ENV === 'TEST') {
        let logger = createLogger({
            predicate: (getState, action) => !['SET_LAST_ACTIVITY_TIME', 'SET_WINDOW_ACTIVE'].includes(action.type)
        });
        middleware.push(logger)
    }

    /* ------------- Assemble Middleware ------------- */

    enhancers.push(applyMiddleware(...middleware))    

    /* --- integrate redux-persist to save state to session storage ---- */
    const persistConfig = {
        key: 'vdo-root',
        storage: storageSession,
        blacklist: ['workflow']
    }

    const persistedReducer = persistReducer(persistConfig, rootReducer)
    const routerReducer = connectRouter(history)(persistedReducer)

    const store = createStore(routerReducer, compose(...enhancers))
    const persistor = persistStore(store)
    

    // kick off root saga
    sagaMiddleware.run(rootSaga)

    return { store, persistor, history }
}