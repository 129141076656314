import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    setStage: ['target', 'stage'],
    initializeWorkingData: ['taxYear', 'stage', 'personalInfo', 'addressData'],
    updateWorkingData: ['fieldName', 'value'],
    validateWorkingData: null,
    saveWorkingData: null,
    confirmIdentity: ['target', 'skipValidation'],
    setConsentChecked: ['target', 'consented'],
    setConfirmationResult: ['target', 'stage', 'confirmationSucceeded'],
    confirmIdentityReset: null,
    updateWorkingAddress: ['addr'],
    addExtraConfirmAddress: ['address'],
    // VAI-753 if the state is reset, clear the self / spouse data
    resetConfirmationResult: ['target'],
    setUploadChoice: ['uploadChoice'],
    setIdTypeChoice: ['idTypeChoice', 'isFront'],
    processFileUpload: ['file'],
    fileUploadError: ['error'],
    setFileDataToUpload: ['fileData'],
    beginFileUpload: null,
    setFileUploadProgressValue: ['uploadProgressValue'],
    completeFileUpload: ['uploadState'],
    confirmIdentityDocs: null,
    setInitialState: ['self', 'spouse'],
    getConfirmIdState: null,
    completeQrCodeVerification: ['data', 'isSpouse', 'isCoupled']
})

export const ConfirmIdentityTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    target: 'self',
    stage: 'start',
    uploadChoice: null,
    idTypeChoice: null,
    idTypePrevious: null,
    isFront: true,
    fileToBeUploaded: null,
    fileUploadError: null,    
    uploadProgressValue: 0,
    fileUploadState: null, 
    uploadedImageData: {
        front: null,
        front_uploaded: false,
        back: null,
        back_uploaded: false,
        selfie: null,
        selfie_uploaded: false
    },
    self: {
        initialState: null,
        consentChecked: false,
        identityConfirmed: false,
        confirmationFailed: false
    },
    spouse: {
        initialState: null,
        consentChecked: false,
        identityConfirmed: false,
        confirmationFailed: false
    },
    workingData: {
        taxYear: null,
        validated: false,
        firstName: '',
        middleName: '',
        lastName: '',
        dob: '',
        sin: '',
        streetNumber: '',
        streetName: '',
        aptNumber: '',
        poBox: '',
        ruralRouteNumber: '',
        city: '',
        province: '',
        postalCode: ''
    },    
    workingAddress: {
        validated: false,
        streetNumber: '',
        streetName: '',
        aptNumber: '',
        poBox: '',
        ruralRouteNumber: '',
        city: '',
        province: '',
        postalCode: ''
    },
    extraAddresses: []
}

/* ------------- Reducers ------------- */

export const confirmReset = (state) => INITIAL_STATE

export const confirmConfirmIdentity = (state, { target }) =>
    Object.assign({}, state, { stage: 'confirming' })

export const confirmSetStage = (state, { target, stage }) => {
    var resetUploadData = {
        uploadChoice: null,
        idTypeChoice: null,
        idTypePrevious: null,
        isFront: true,
        fileToBeUploaded: null,
        fileUploadError: null,
        uploadProgressValue: 0,
        fileUploadState: null
    }

    var result = Object.assign({}, state, { target: target, stage: stage, fileUploadState: null })
    if (target !== state.target) {
        result = Object.assign({}, result, resetUploadData);
    }
    return result
}

export const confirmSetConsentChecked = (state, { target, consented }) => {
    if (target === 'spouse') {
        var sp = { ...state.spouse, consentChecked: consented }
        return Object.assign({}, state, { spouse: sp })
    } else {
        var s = { ...state.self, consentChecked: consented }
        return Object.assign({}, state, { self: s })
    }
}

export const confirmInitWorkingData = (state, {taxYear, stage, personalInfo, addressData }) => {
    var workingData = {
        ...state.workingData,
        taxYear: taxYear,
        firstName: personalInfo.firstName,
        middleName: personalInfo.middleName,
        lastName: personalInfo.lastName,
        dob: personalInfo.dob,
        sin: personalInfo.sin,
        streetNumber: addressData.streetNumber,
        streetName: addressData.streetName,
        aptNumber: addressData.aptNumber,
        poBox: addressData.poBox,
        ruralRouteNumber: addressData.ruralRouteNumber,
        city: addressData.city,
        province: addressData.province,
        postalCode: addressData.postalCode
    }
    return Object.assign({}, state, { stage: stage, workingData: workingData, extraAddresses: [] })
}

export const confirmUpdateWorkingData = (state, { fieldName, value }) => {
    var workingData = {
        ...state.workingData
    }
    workingData[fieldName] = value
    return Object.assign({}, state, { workingData: workingData })
}

export const confirmValidateWorkingData = (state) => {
    var workingData = {
        ...state.workingData,
        validated: true
    }
    return Object.assign({}, state, { workingData: workingData })
}

export const confirmSaveWorkingData = (state) =>
    Object.assign({}, state, { stage: 'saving' })

export const confirmSetConfirmationResult = (state, { target, stage, confirmationSucceeded }) => {    
    if (target === 'spouse') {
        var sp = { ...state.spouse, identityConfirmed: Boolean(confirmationSucceeded), confirmationFailed: Boolean(!confirmationSucceeded), initialState: confirmationSucceeded ? null : 'failed' }
        return Object.assign({}, state, { stage: stage, spouse: sp, uploadedImageData: {
            front: null,
            front_uploaded: false,
            back: null,
            back_uploaded: false,
            selfie: null,
            selfie_uploaded: false
        } })
    } else {
        var s = { ...state.self, identityConfirmed: Boolean(confirmationSucceeded), confirmationFailed: Boolean(!confirmationSucceeded), initialState: confirmationSucceeded ? null : 'failed' }
        return Object.assign({}, state, {
            stage: stage, self: s,  uploadedImageData: {
                    front: null,
                    front_uploaded: false,
                    back: null,
                    back_uploaded: false,
                    selfie: null,
                    selfie_uploaded: false
                } })
    }
}

export const confirmResetConfirmationResult = (state, { target }) => {
    var stage = state.stage === 'complete' || state.stage === 'failed' ? 'start' : state.stage
    if (target === 'spouse') {
        return Object.assign({}, state, {
            stage,
            spouse: {
                identityConfirmed: false,
                confirmationFailed: false
            }
        })
    } else {
        return Object.assign({}, state, {
            stage,
            self: {
                identityConfirmed: false,
                confirmationFailed: false
            }
        })
    }
}

export const confirmAddExtraAddress = (state, { address }) => {
    if (address) {
        var addrs = [...state.extraAddresses]
        addrs.push(address)
        return Object.assign({}, state, { extraAddresses: addrs })
    } else {
        return Object.assign({}, state, { extraAddresses: [] })
    }
}

export const confirmUpdateWorkingAddress = (state, { addr }) => {
    if (addr) {
        return Object.assign({}, state, { workingAddress: addr })
    } else {
        return Object.assign({}, state, {
            workingAddress: {
                validated: false,
                streetNumber: '',
                streetName: '',
                aptNumber: '',
                poBox: '',
                ruralRouteNumber: '',
                city: '',
                province: '',
                postalCode: ''
            }
        })
    }
}

export const confirmSetUploadChoice = (state, { uploadChoice }) => 
    Object.assign({}, state, { uploadChoice: uploadChoice })

export const confirmSetIdChoiceType = (state, { idTypeChoice, isFront }) => {
    var uid = null
    var fileToBeUploaded = state.fileToBeUploaded
    if (idTypeChoice !== 'selfie' && state.idTypeChoice !== idTypeChoice) {
        uid = {
            front: null,
            back: null,
            selfie: null
        }
        fileToBeUploaded = null
    } else {
        uid = {
            ...state.uploadedImageData
        }
    }
    return Object.assign({}, state, { idTypeChoice: idTypeChoice, isFront, uploadedImageData: uid, fileToBeUploaded: fileToBeUploaded })
}

export const confirmProcessFileUpload = (state, { file }) => {
    if (file == null) {
        var uid = {
            ...state.uploadedImageData
        }
        if (state.stage === 'upload_id_1') {
            uid.front = null
            uid.front_uploaded = false
        } else if (state.stage === 'upload_id_2') {
            uid.back = null
            uid.back_uploaded = false
        } else if (state.stage === 'upload_selfie') {
            uid.selfie = null
            uid.selfie_uploaded = false
        }

        return Object.assign({}, state, { uploadedImageData: uid, fileToBeUploaded: null, fileUploadError: null })
    } else {
        return Object.assign({}, state, { fileToBeUploaded: file, fileUploadError: file != null ? null : state.processFileError, uploadProgressValue: 0 })
    }
}

export const confirmFileUploadError = (state, { error }) => 
    Object.assign({}, state, { fileUploadError: error, fileToBeUploaded: null, uploadProgressValue: 0})

export const confirmSetFileDataToUpload = (state, { fileData }) => {
    var uid = {
        ...state.uploadedImageData
    }
    if (state.stage === 'upload_id_1') {
        uid.front = fileData
        uid.front_uploaded = false
    } else if (state.stage === 'upload_id_2') {
        uid.back = fileData
        uid.back_uploaded = false
    } else if (state.stage === 'upload_selfie') {
        uid.selfie = fileData
        uid.selfie_uploaded = false
    }
    return Object.assign({}, state, { uploadedImageData: uid })
}

export const confirmBeginFileUpload = (state) =>
    Object.assign({}, state, { uploadProgressValue: 0, fileUploadState: 'uploading' })

export const confirmCompleteFileUpload = (state, { uploadState }) => {
    if (uploadState === 'failed') {
        return Object.assign({}, state, { uploadProgressValue: -1, fileUploadState: uploadState })
    } else {
        var uid = {
            ...state.uploadedImageData
        }
        if (state.stage === 'upload_id_1') {
            uid.front_uploaded = true
        } else if (state.stage === 'upload_id_2') {
            uid.back_uploaded = true
        } else if (state.stage === 'upload_selfie') {
            uid.selfie_uploaded = true
        }
        return Object.assign({}, state, { uploadProgressValue: 0, fileUploadState: uploadState, fileToBeUploaded: null, uploadedImageData: uid })
    }
}

export const confirmSetUploadProgressValue = (state, { uploadProgressValue }) =>
    Object.assign({}, state, { uploadProgressValue: state.uploadProgressValue === -1 ? -1 : uploadProgressValue })

export const confirmSetInitialState = (state, { self, spouse }) => {
    var selfState = {
        ...state.self,
        initialState: self,
        identityConfirmed: self === 'verified'
    }
    var spouseState = {
        ...state.spouse,
        initialState: spouse,
        identityConfirmed: spouse === 'verified'
    }
    return Object.assign({}, state, { self: selfState, spouse: spouseState })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_STAGE]: confirmSetStage,
    [Types.CONFIRM_IDENTITY]: confirmConfirmIdentity,
    [Types.SET_CONSENT_CHECKED]: confirmSetConsentChecked,
    [Types.INITIALIZE_WORKING_DATA]: confirmInitWorkingData,
    [Types.UPDATE_WORKING_DATA]: confirmUpdateWorkingData,
    [Types.VALIDATE_WORKING_DATA]: confirmValidateWorkingData,
    [Types.SAVE_WORKING_DATA]: confirmSaveWorkingData,
    [Types.SET_CONFIRMATION_RESULT]: confirmSetConfirmationResult,
    [Types.CONFIRM_IDENTITY_RESET]: confirmReset,
    [Types.RESET_CONFIRMATION_RESULT]: confirmResetConfirmationResult,
    [Types.ADD_EXTRA_CONFIRM_ADDRESS]: confirmAddExtraAddress,
    [Types.UPDATE_WORKING_ADDRESS]: confirmUpdateWorkingAddress,
    [Types.SET_UPLOAD_CHOICE]: confirmSetUploadChoice,
    [Types.SET_ID_TYPE_CHOICE]: confirmSetIdChoiceType,
    [Types.PROCESS_FILE_UPLOAD]: confirmProcessFileUpload,
    [Types.SET_FILE_DATA_TO_UPLOAD]: confirmSetFileDataToUpload,
    [Types.BEGIN_FILE_UPLOAD]: confirmBeginFileUpload,
    [Types.COMPLETE_FILE_UPLOAD]: confirmCompleteFileUpload,
    [Types.SET_FILE_UPLOAD_PROGRESS_VALUE]: confirmSetUploadProgressValue,
    [Types.FILE_UPLOAD_ERROR]: confirmFileUploadError,
    [Types.SET_INITIAL_STATE]: confirmSetInitialState
})

