import React from 'react';
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import T from 'i18n-react';
import './style.css';
import HelpCentre from '../../sagas/Help/helpCentre'
import { connect } from 'react-redux';

const HelpLink = (props) => {
    return props.showLinks ? (
                <span className={classNames({ [`props.baseClassName || ''`]: true }, 'learnMoreStyle', 'learnMoreDefaultMargin')}
                    onClick={(ev) => HelpCentre.openHelpLink(props.linkId)}>
                    <span className={classNames({ [`props.baseClassName || ''`]: true }, 'unselectableLabel')}>
                        {T.translate(props.linkKey)}
                    </span>
                </span>
    ) : null
}

HelpLink.propTypes = {
    linkId: PropTypes.string.isRequired,
    linkKey: PropTypes.string.isRequired,
    baseClassName: PropTypes.string
}

const mapStateToProps = (state) => {
    return {
        lang: state.layout.lang,
        showLinks: !state.workflow.is_direct_user
    };
};

export default connect(mapStateToProps)(HelpLink);