import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import '../dox_explorer.css'
import doxExplorerCDMServices from '../../Shared/services/doxExplorerCDMServices'

const DoxExplorerFilesTableSizeCell = (props) => {
    const [sizeStr, setSizeStr] = useState('-')

    useEffect(() => {
        let ss = doxExplorerCDMServices.formatFileSize(props.fileSize)
        if (ss !== sizeStr) {
            setSizeStr(ss)
        }
    }, [props.fileSize, sizeStr, setSizeStr])

    return (
        <div className={classnames('doxe-files-table-cell hrb-column', `hrb-is-${props.width}`)}>
            <span>{sizeStr}</span>
        </div>
    )
}

DoxExplorerFilesTableSizeCell.propTypes = {    
    fileSize: PropTypes.number,
    width: PropTypes.number.isRequired
}   

export default DoxExplorerFilesTableSizeCell;