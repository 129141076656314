import HrbCasAuth from 'hrb-cas-auth-js';

class Auth {
    constructor() {
        this.authProvider = null
    }

    initAuthProvider = (authConfig) => {
        let casDomain = authConfig.domain
        let casClientId = authConfig.clientId
        let clientOrigin = authConfig.baseUrl

        this.authProvider = new HrbCasAuth({
            namespace: 'ca.hrblock.cas',
            domain: casDomain,
            issuer: casDomain + '/oidc',
            clientId: casClientId,
            clientOrigin: clientOrigin,
            loginCallbackUrl: clientOrigin + '/callback',
            logoutCallbackUrl: clientOrigin + '/logout',
            otcLoginCallbackUrl: clientOrigin + '/otcCallback',
            otcClientId: 'OneTimeCode',
            responseType: 'id_token token',
            scope: 'openid profile profile_hrbAuth',
            useSessionStorage: true,
            timeout: 60000
        })
        this.refreshAuthProvider = new HrbCasAuth({
            namespace: 'ca.hrblock.cas',
            domain: casDomain,
            issuer: casDomain + '/oidc',
            clientId: casClientId,
            clientOrigin: clientOrigin,
            loginCallbackUrl: clientOrigin + '/callback/refresh',
            logoutCallbackUrl: clientOrigin + '/logout',
            responseType: 'id_token token',
            scope: 'openid profile profile_hrbAuth',
            useSessionStorage: true,
            timeout: 60000
        })
    }

    login = (language) => {
        localStorage.removeItem("va_login_redirect");
        this.authProvider.login(language);
    };

    loginWithReturnUrl = (returnUrl, language) => {

        localStorage.setItem("va_login_redirect", returnUrl);
        this.authProvider.login(language);
    }

    silentAuthentication = (callback) => {
        this.refreshAuthProvider.authenticateSilently((result, err) => {
            if (result) {
                console.debug(result);
            } else if (err) {
                const options = callback();
                console.log(err);
                this.authProvider.login(options.language);
            }
        });
    };

    otcAuthentication = (code, userName) => {
        return new Promise((resolve, reject) => {
            this.authProvider.authenticateOtc((result, err) => {
                if (result) {
                    resolve(result);
                } else if (err) {
                    reject(err);
                }
            }, code, userName);
        });
    };

    passUserNameToCas = (userName, lang) => {
        return new Promise((resolve, reject) => {
            this.authProvider.passUserName((result, err) => {
                if (result) {
                    console.debug(result);
                    resolve(result);
                } else if (err) {
                    reject(err);
                }
            }, userName);
        });
    };

    handleAuthentication = (cb) => {
        return new Promise((resolve, reject) => {
            this.authProvider.handleAuthenticationResponse((authResult, err) => {
                if (authResult) {
                    let payload = authResult.idTokenPayload;
                    resolve(payload);
                } else if (err) {
                    reject(err);
                }
                resolve();
            });
        });
    };

    logout = () => {
        this.authProvider.logout();
    };

    getAccessToken = () => {
        const accessToken = this.authProvider.getToken();
        if (!accessToken) {
            throw new Error('No access token found');
        }
        return accessToken;
    };

    getTokenTimeToLive = () => {
        return this.authProvider.getTokenTimeToLive();
    }

    getProfile = () => {
        if (this.authProvider && this.isAuthenticated()) {
            return this.authProvider.getUserProfile();
        }
        return {};
    };

    isAuthenticated = () => {
        return this.authProvider.isAuthenticated();
    };
}

export default new Auth();