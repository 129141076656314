import React from 'react';
import './style.css';
import T from 'i18n-react';
import classnames from 'classnames'

const ConfirmIdentityButton = ({ labelKey, isEnabled, onClick }) => {
  var content = <span>{T.translate(labelKey)}</span>;

  const handleClick = (ev) => {
    if (onClick) {
      onClick(ev);
    }
  };

  return (
    <div className={classnames('continueButtonConfirmIdentity', {'disabled': isEnabled === false })} onClick={isEnabled ? handleClick : null}>
      <div className='continueButtonText'>
        {content}
      </div>
    </div>
  );
};

export default ConfirmIdentityButton;