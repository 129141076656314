import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import '../dox_explorer.css'
import { useTranslationContext } from '../../Shared/hooks/useTranslationContextHook'

const DoxExplorerTitleBreadCrumb = (props) => {
    const translateString = useTranslationContext()

    const handleBackToRoot = () => {
        props.onBackToRoot()
    }

    return (
        <div className={classnames('doxe-breadcrumbs hrb-column', {
            'hrb-is-11': props.layoutType === 'mobile' || props.layoutType === 'small_tablet',
            'hrb-is-9': props.layoutType === 'desktop' || props.layoutType === 'tablet'
        })}>
            {props.isDoxCombined && <div>
                <div className='hrb-column hrb-is-12 doxCombinedTitle'>
                    <span>{translateString("Dox.DocumentExplorer.DoxCombined")}</span>
                </div>
                <div className='hrb-column hrb-is-12 doxCombinedSubTitle'>
                    <span>{props.tax_year}</span>
                </div>
            </div>}

            {!props.tax_year && !props.isDoxCombined &&
                <span>{translateString("Dox.DocumentExplorer.AllYears")}</span>
            }
            {props.tax_year && !props.isDoxCombined &&
                <Fragment>
                    <div className='dox-link' onClick={handleBackToRoot}>{translateString("Dox.DocumentExplorer.AllYears")}</div>
                    <span>&gt;</span>
                    <span>{props.tax_year}</span>
                </Fragment>
            }
        </div>
    )
}

DoxExplorerTitleBreadCrumb.propTypes = {
    tax_year: PropTypes.number,
    onBackToRoot: PropTypes.func.isRequired,
    layoutType: PropTypes.string.isRequired
}

export default DoxExplorerTitleBreadCrumb;