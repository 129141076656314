import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    setDashboardTab: ['tab'],
    setLayoutType: ['layoutType'],
    setDictLanguage: ['lang'],
    setLanguage: ['lang'],
    setApplicationInitialized: ['initialized'],
    setDialogOpen: ['dialogOpen'],
    setWindowActive: ['isActive'],
    setCurrentTaxYear: ['currentTaxYear'],
    setMinTaxYear: ['minTaxYear']
})

export const LayoutTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    dashboardTab: 'dashboard',
    layoutType: 'desktop',
    lang: 'en',
    applicationInitialized: false,
    dialogOpen: false,
    windowActive: true,
    currentTaxYear: Number(localStorage.getItem('va_current_tax_year')),
    minTaxYear: Number(localStorage.getItem('va_min_tax_year'))
}

/* ------------- Reducers ------------- */

export const layoutSetDashboardTab = (state, { tab }) =>
    Object.assign({}, state, { dashboardTab: tab })

export const layoutSetLayoutType = (state, { layoutType }) =>
    Object.assign({}, state, { layoutType: layoutType })

export const layoutSetLanguage = (state, { lang }) => {
    // IMPORTANT: This should ONLY be getting called by the 'updateLanguageTexts' 
    // in LayoutSagas.
    //
    // The assumption with this action is that the setI18nLang will have
    // been dispatched to set the language texts for the react-i18n library FIRST,
    // as setting lang here will trigger the re-render for the application, and we
    // need the correct dictionary in place before we do that.
    // 
    // If you, like me, hate this solution, please feel encouraged to make some time
    // to refactor out all of the T.translate calls in this app to <T.span>s or else
    // incorporate the <HrbTranslate> component that exists in other HRB apps (preferred).
   
    if (lang === 'fr') {
        return Object.assign({}, state, { lang: 'fr' })
    } else {
        return Object.assign({}, state, { lang: 'en'})
    }
}

export const layoutSetAppInitialized = (state, { initialized }) =>
    Object.assign({}, state, { applicationInitialized: initialized })

export const layoutSetApplicationInitialized = (state, { initialized }) =>
    Object.assign({}, state, { applicationInitialized: initialized })

export const layoutSetDialogOpen = (state, { dialogOpen }) =>
    Object.assign({}, state, { dialogOpen: dialogOpen })

export const layoutSetWindowActive = (state, { isActive }) =>
    Object.assign({}, state, { windowActive: isActive })

export const layoutSetCurrentTaxYear = (state, { currentTaxYear }) =>
    Object.assign({}, state, { currentTaxYear: currentTaxYear })

export const layoutSetMinTaxYear = (state, { minTaxYear }) =>
    Object.assign({}, state, { minTaxYear: minTaxYear })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_DASHBOARD_TAB]: layoutSetDashboardTab,
    [Types.SET_LAYOUT_TYPE]: layoutSetLayoutType,
    [Types.SET_LANGUAGE]: layoutSetLanguage,
    [Types.SET_APPLICATION_INITIALIZED]: layoutSetAppInitialized,
    [Types.SET_DIALOG_OPEN]: layoutSetDialogOpen,
    [Types.SET_WINDOW_ACTIVE]: layoutSetWindowActive,
    [Types.SET_CURRENT_TAX_YEAR]: layoutSetCurrentTaxYear,
    [Types.SET_MIN_TAX_YEAR]: layoutSetMinTaxYear
})