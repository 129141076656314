import { select, put, call } from 'redux-saga/effects'
import WorkflowActions from '../reducers/workflowRedux'
import ProductActions from '../reducers/productsRedux'

export const getAccessToken = state => state.auth.accessToken

export function* getAddOnProducts(api, action) {
    var accessToken = yield select(getAccessToken)
    var response = yield call(api.getEligibleAddOnProducts, accessToken, action);
    if(response && response.ok)
    {
        yield put(ProductActions.setAddOnProducts(response.data))
    }
}

export function* savePOMSelection(api, workflowApi, action) {
    yield put(ProductActions.setSelectionSaved(false))
    var accessToken = yield select(getAccessToken)
    var saveResponse = yield call(api.savePOMSelection, accessToken, action);
    var caseState = yield call(workflowApi.getWorkflowState2, accessToken)
    if (caseState && caseState.ok) {
        yield put(WorkflowActions.storeCaseState(caseState.data))
        if (saveResponse && saveResponse.ok) {
            yield put(ProductActions.setSelectionSaved(true))
        }
    }
}


