import React, { Component } from 'react';
import { connect } from 'react-redux'
import ProgressModal from './progressModal';
import AuthActions from '../../reducers/authRedux'

class DocumentSigner extends Component {
    constructor(props) {
        super(props);

        this.continueTimer = null
        this.continueDate = null

        this.state = {
            signingState: props.signingState,
            forms: props.forms,
            isSpouse: props.isSpouse,
            spouseFirstName: props.spouseFirstName,
            signingUri: props.signingUri,
            canContinue: false,
            primaryFirstName: props.primaryFirstName,
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.signingState !== this.state.signingState) {
            if (newProps.signingState === 'in_progress') {
                // reset the last activity time to give us the full 20 mins
                this.props.setLastActivityTime(new Date().getTime())
                this.setState({ canContinue: false })
                this.startTimer();
            }
            this.setState({ signingState: newProps.signingState })
        }

        if (newProps.forms !== this.state.forms) {
            this.setState({ forms: newProps.forms })
        }

        if (newProps.isSpouse !== this.state.isSpouse) {
            this.setState({ isSpouse: newProps.isSpouse })
        }

        if (newProps.spouseFirstName !== this.state.spouseFirstName) {
            this.setState({ spouseFirstName: newProps.spouseFirstName })
        }

        if (newProps.primaryFirstName !== this.state.primaryFirstName) {
            this.setState({ primaryFirstName: newProps.primaryFirstName })
        }

        if (newProps.signingUri !== this.state.signingUri) {
            this.setState({ signingUri: newProps.signingUri })
            if (newProps.signingUri && this.state.canContinue) {
                window.location.href = newProps.signingUri
            }
        }
    }

    startTimer() {
        this.continueTimer = setTimeout(() => {
            if (this.state.signingUri) {
                window.location.href = this.state.signingUri
            } else {
                this.setState({ canContinue: true })
            }

        }, 5 * 1000);
    }
    
    render() {
        var visible = this.state.signingState === 'in_progress'
        var forms = []
        var titleKey = ''
        var subTitleKey = ''

        if (this.state.forms) {
            forms = this.state.forms.map(x => x.toUpperCase()).sort()
        }

        var key = forms.join('')

        titleKey = 'ESignatureLabels.' + (this.state.isSpouse ? 'Spouse.' : 'Self.') + 'Title' + key
        subTitleKey = 'ESignatureLabels.' + (this.state.isSpouse ? 'Spouse.' : 'Self.') + 'SubTitle' + key

        var params = {}
        if (this.props.isAdditional) {
            titleKey = 'ESignatureLabels.Additional.Title'

            var formName = "Form"
            if (forms && forms.length > 0) {
                formName = forms[0]
                // this is a terrible hack but we can fix it later
                if (formName === "POMW") {
                    formName = this.props.lang === "fr" ? "Tranquillité d'esprit – Accord et contrat" : "Peace of Mind – Waiver"
                } else if (formName === "POMA" || formName === "POMQCA") {
                    formName = this.props.lang === "fr" ? "Tranquillité d'esprit – Accord et contrat" : "Peace of Mind – Agreement and Contract"
                } else if (formName === 'T1032W' || formName === 'T1032T' || formName === 'T1032R') {
                    formName = 'T1032'
                }
            }

            params = {
                FormName: formName
            }

            titleKey = 'ESignatureLabels.' + (this.state.isSpouse ? 'Spouse.' : 'Self.') + 'TitleAdditional';
            subTitleKey = 'ESignatureLabels.' + (this.state.isSpouse ? 'Spouse.' : 'Self.') + 'SubTitleAdditional';           
        }

        return (
            <div style={{ width: '0px', height: '0px' }}>
                <ProgressModal visible={visible} titleKey={titleKey} subTitleKey={subTitleKey}
                    layoutType={this.props.layoutType} spouseName={this.props.spouseFirstName}
                    primaryName={this.props.primaryFirstName}
                    params={params}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.layout.lang,
        layoutType: state.layout.layoutType,
        signingState: state.signing.signingState,
        forms: state.signing.forms, 
        isSpouse: state.signing.isSpouse,
        spouseFirstName: state.signing.spouseFirstName,
        signingUri: state.signing.signingUri,
        isAdditional: state.signing.isAdditional,
        primaryFirstName: state.signing.primaryFirstName
    }
}

const mapDispatchToProps = (dispatch) => ({
    setLastActivityTime: (lastActivityTime) => dispatch(AuthActions.setLastActivityTime(lastActivityTime))
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSigner)
