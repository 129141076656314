import React, { Component } from 'react';
import './footer.css';
import logo from '../../../images/logo.png'
import T from 'i18n-react';

class FooterDesktop extends Component {

    componentDidMount() {
        this.forceUpdate()
    }

    handleAction(action) {
        if (this.props.buttonHandler && this.props.appInitialized) {
            this.props.buttonHandler(action)
        }
    }

    render() {
        var accountContent = []
        if (this.props.loginComplete) {
            accountContent.push(
                <div key="settingsLink" className="overlinedBlockLink" onClick={(ev) => this.handleAction('settings')}>
                    <span>{T.translate('BottomMenuBar.labels.UpdateAccount')}</span>
                </div>
            )
            accountContent.push(
                <div key="logoutLink" className="overlinedBlockLink" onClick={(ev) => this.handleAction('logout')}>
                    <span>{T.translate('BottomMenuBar.labels.SaveAndQuit')}</span>
                </div>
            )
        } else {
            var signinStyle = "overlinedBlockLink"
            if (!this.props.appInitialized) {
                signinStyle += "uninitialized"
            }
            accountContent.push(
                <div key="loginLink" className={signinStyle} onClick={(ev) => this.handleAction('login')}>
                    <span>{T.translate('Common.SignIn')}</span>
                </div>
            )
        }

        var currentYear = new Date().getFullYear();

        return (
            <div className="footerOuter">
                {this.props.showOteContent &&
                    <div className="footerTopOuter">
                        <div className="footerTop">
                            <img src={logo} width="90" height="90" alt={T.translate('Home.hrbLogoAltText')}
                                style={{ cursor: 'pointer' }}
                                onClick={(ev) => this.handleAction('hrb')} />
                            <div className="footerTopMiddle">
                                <div className="overlinedBlock">
                                    <p>{T.translate('BottomMenuBar.labels.MyAccount')}</p>
                                    {accountContent}
                                </div>
                                <div className="overlinedBlock marginSmall">
                                    <p>{T.translate('BottomMenuBar.labels.Support')}</p>
                                    <div className="overlinedBlockLink" onClick={() => this.handleAction('help')}><span>{T.translate('BottomMenuBar.labels.HelpCentre')}</span></div>
                                    <div className="overlinedBlockLink" onClick={() => this.handleAction('support')}><span>{T.translate('BottomMenuBar.labels.Contact')}</span></div>
                                </div>
                            </div>
                            <div className="overlinedBlock">
                                <p>{T.translate('BottomMenuBar.labels.SendFeedback')}</p>
                                <div className="overlinedBlockLink" onClick={() => this.handleAction('survey')}><span>{T.translate('BottomMenuBar.labels.Improve')}</span></div>
                            </div>
                        </div>
                    </div>
                }
                <div className="footerBottomOuter">
                    <div className="footerBottom">
                        <div className="copyBlock">
                            <span className="small white">{T.translate('BottomMenuBar.labels.CopyrightHRB', { TaxYear: currentYear })}</span>
                            <span className="tiny white">{T.translate('BottomMenuBar.labels.Reserved')}</span>
                        </div>
                        <div className="termsBlock">
                            <div className="footerBottomLink" onClick={() => this.handleAction('terms')}>
                                <p className="small white">{T.translate('BottomMenuBar.labels.TermsAndConditions')}</p>
                            </div>
                            <div><p className="small white">&nbsp;|&nbsp;</p></div>
                            <div className="footerBottomLink" onClick={() => this.handleAction('privacy')}>
                                <p className="small white">{T.translate('BottomMenuBar.labels.Privacy')}</p>
                            </div>
                        </div>
                    </div>       
                </div>
            </div>
        );
    }

}


export default FooterDesktop;