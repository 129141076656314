import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import HeaderLogo from './headerLogo';
import T from 'i18n-react';
import HeaderTabControl from './headerTabControl'

class HeaderTablet extends Component {  

    handleButtonClick(action) {
        if (this.props.buttonHandler && this.props.appInitialized) {
            this.props.buttonHandler(action)
        }
    }

    handleLangClick(lang) {
        if (this.props.langHandler) {
            this.props.langHandler(lang)
        }
    }

    renderMainBar() {
        var headerButtons = [];
        if (this.props.loginComplete) {
            headerButtons.push(
                <div className="headerButtonStyle headerButtonMarginMobile" key="logoutButton"
                    onClick={() => { this.handleButtonClick('logout') }}>
                    <FontAwesome className='fa-sign-out' name='fa-sign-out' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#14AA40', align: 'center' }} />
                    <span className="headerButtonTextStyle">{T.translate('HeaderMenuBar.labels.SignOut')}</span>
                </div>
            )
        } else {
            var headerStyle = "headerButtonStyle headerButtonMarginMobile"
            if (!this.props.appInitialized) {
                headerStyle += " uninitialized"
            }
            headerButtons.push(
                <div className={headerStyle} key="loginButton"
                    onClick={(ev) => { this.handleButtonClick('login') }}>
                    <FontAwesome className='fa-user' name='fa-user' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', align: 'center' }} />
                    <span className="headerButtonTextStyle">{T.translate('Common.SignIn')}</span>
                </div>
            )
        }

        var enLangButtonStyle = 'languageButton'
        var frLangButtonStyle = 'languageButton'
        if (this.props.lang === 'fr') {
            enLangButtonStyle += ' unselected'
            frLangButtonStyle += ' selected'
        } else {
            enLangButtonStyle += ' selected'
            frLangButtonStyle += ' unselected'
        }

        var headerContent = null
        if (this.props.showOteContent && (this.props.layoutType === 'tablet' || this.props.lang !== 'fr')) {
            headerContent = (<h1 className="headerAppName desktopNameLeftMargin">{T.translate('AppName')}{T.translate('AppTag')}</h1>)
        }
        return (
            <div className="headerContainer">
                <div className="headerLeft mobileMarginLeft">
                    <HeaderLogo height='28px' width='161px' darkText onClick={() => this.handleButtonClick('home')} />
                    {headerContent}
                </div>
                <div className="headerRight mobileMarginRight">
                    {this.props.loaded ? headerButtons : ""}
                    <div style={{ width: 20 }} />
                    <div className={enLangButtonStyle}
                        onClick={(ev) => this.handleLangClick('en')}>
                        <span>En</span>
                    </div>
                    <div className={frLangButtonStyle}
                        onClick={(ev) => this.handleLangClick('fr')}>
                        <span>Fr</span>
                    </div>
                </div>
            </div>           
        )
    }

    render() {
        // VAI-252 only show if we are on the dashboard page.
        if (this.props.signedIn && this.props.rootPage ==='dashboard') {
            return (
                <div className="headerStyleExtended">
                    <div className="headerContainerExtended">
                        {this.renderMainBar()}
                        <HeaderTabControl lang={this.props.lang} />
                    </div>
                </div>
            )
        } else {
            return (
                <div className="headerStyleDefault">
                    {this.renderMainBar()}
                </div>
            )
        }
    }
}

export default HeaderTablet;