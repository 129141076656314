class StoreStorage {
    constructor() {
        this.store = null;
        this.persistor = null;
        this.history = null;
    }

    configure(s, p, h) {
        this.store = s;
        this.persistor = p;
        this.history = h;
    }

    getStore() {
        return this.store;
    }

    getHistory() {
        return this.history;
    }

    getPersistor() {
        return this.persistor;
    }
}

const storeStorage = new StoreStorage();
export default storeStorage;
