import React, { Component } from 'react';
import '../Common/shared.css'
import './style.css';
import T from 'i18n-react';
import PlainButton from '../Controls/plainButton'

class SaveContentPrompt extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isBrowserBack: this.props.isBrowserBack,
            onContinueNavigation: this.props.continueNavigation,
            onCancelNavigation: this.props.cancelNavigation,
            layoutType: this.props.layoutType
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.isBrowserBack !== newProps.isBrowserBack) {
            this.setState({ isBrowserBack: newProps.isBrowserBack })
        }

        if (this.state.onContinueNavigation !== newProps.continueNavigation) {
            this.setState({ onContinueNavigation: newProps.continueNavigation })
        }

        if (this.state.onCancelNavigation !== newProps.cancelNavigation) {
            this.setState({ onCancelNavigation: newProps.cancelNavigation })
        }

        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
    }

    continueNavigation() {
        if (this.state.onContinueNavigation) {
            this.state.onContinueNavigation();
        }
    }

    cancelNavigation() {
        if (this.state.onCancelNavigation) {
            this.state.onCancelNavigation();
        }
    }
    render() {
        var buttonLayout = ["dialogBottomSpacing", "dialogTopSpacing"];
        if (this.state.layoutType === 'mobile' || this.state.layoutType === 'small_tablet') {
            buttonLayout.push("dialogButtonContainerMobile")
        } else {
            buttonLayout.push("dialogButtonContainer")
        }

        return (
            <div className="defaultStyles">
                <div className="bodyContainer" >
                    <div className="dialogContentContainer blackContent largeLeftSpacing largeRightSpacing dialogTopSpacing largeBottomSpacing">
                        <span className="dialogTitle">
                            {T.translate("Messages.Labels.CancelTitle")}
                        </span>
                        <span className="dialogContent dialogTopSpacing">{T.translate("Messages.Labels.CancelContent")}</span>
                    </div>
                </div>
                <div className={buttonLayout.join(' ')}>
                    <div className="dialogButtonHolder dialogBottomSpacing">
                        <PlainButton inverted labelKey="Messages.Labels.CancelCancel"
                            isEnabled={true} onClick={(ev) => { this.cancelNavigation() }} />
                    </div>
                    <div className="dialogButtonHolder dialogBottomSpacing">
                        <PlainButton inverted labelKey="Messages.Labels.CancelConfirm"
                            isEnabled={true} onClick={(ev) => { this.continueNavigation() }} />
                    </div>
                </div>
            </div>
        )
    }

    /*
    labelKey: this.props.labelKey || 'Common.Continue',
            isEnabled: !(this.props.isEnabled === false),
            onClick: this.props.onClick*/



}

export default SaveContentPrompt;