import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab, Typography, Box, Collapse, IconButton } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import './dox-suggestion-panel.css'
import classnames from 'classnames'
import Scenario1 from '../../../../images/TaxScenarios/Scenario1.svg';
import Scenario2 from '../../../../images/TaxScenarios/Scenario2.svg';
import Scenario3 from '../../../../images/TaxScenarios/Scenario3.svg';
import Scenario4 from '../../../../images/TaxScenarios/Scenario4.svg';
import Scenario5 from '../../../../images/TaxScenarios/Scenario5.svg';
import Scenario6 from '../../../../images/TaxScenarios/Scenario6.svg';
import Scenario7 from '../../../../images/TaxScenarios/Scenario7.svg';
import Scenario8 from '../../../../images/TaxScenarios/Scenario8.svg';
import Scenario9 from '../../../../images/TaxScenarios/Scenario9.svg';
import Scenario10 from '../../../../images/TaxScenarios/Scenario10.svg';
import Scenario11 from '../../../../images/TaxScenarios/Scenario11.svg';
import Scenario12 from '../../../../images/TaxScenarios/Scenario12.svg';
import Scenario13 from '../../../../images/TaxScenarios/Scenario13.svg';
import Scenario14 from '../../../../images/TaxScenarios/Scenario14.svg';
import Scenario15 from '../../../../images/TaxScenarios/Scenario15.svg';
import Scenario16 from '../../../../images/TaxScenarios/Scenario16.svg';
import Scenario17 from '../../../../images/TaxScenarios/Scenario17.svg';
import Scenario18 from '../../../../images/TaxScenarios/Scenario18.svg';
import Scenario19 from '../../../../images/TaxScenarios/Scenario19.svg';
import Scenario20 from '../../../../images/TaxScenarios/Scenario20.svg';
import InfoIcon from '../../../../images/TaxScenarios/info-icon-filled.svg';
import DocumentActions from '../../../../reducers/documentsRedux';
import { HeapEvents, trackEvent } from '../../../../utils/AnalyticsUtil'

const DoxSuggestionPanel = (props) => {
    const { layoutType, taxYear, onTranslateString } = props
    const dispatch = useDispatch();
    const [collapseValue, setCollapseValue] = React.useState(0);
    const [expanded, setExpanded] = useState(layoutType !== 'mobile' ? true : false);
    const docSuggestions = useSelector(state => state.documents.suggestedDocs);

    useEffect(() => {
        dispatch(DocumentActions.getSuggestions(taxYear));
    }, []);

    const getIconPath = (taxScenario) => {
        switch (taxScenario) {
            case 'T4': return Scenario1;
            case 'T4AndRL': return Scenario1;
            case 'SelfEmployedIncome': return Scenario2;
            case 'Retirement': return Scenario3;
            case 'BusinessIncome': return Scenario4;
            case 'WorkExpense': return Scenario5;
            case 'Disability': return Scenario6;
            case 'MedicalExpense': return Scenario7;
            case 'Benefits': return Scenario8;
            case 'Investment': return Scenario9;
            case 'Rrsp': return Scenario10;
            case 'RentalIncome': return Scenario11;
            case 'ForeignIncome': return Scenario12;
            case 'Dependents': return Scenario13;
            case 'PropertyBuySell': return Scenario14;
            case 'SpouseSupport': return Scenario15;
            case 'Moving': return Scenario16;
            case 'Tuition': return Scenario17;
            case 'Donations': return Scenario18;
            case 'FireAndRescue': return Scenario19;
            case 'FarmAndFish': return Scenario20;
        }
    }

    function TabContent({ value }) {
        const infoScenarios = ['Benefits', 'SelfEmployedIncome', 'BusinessIncome', 'Retirement', 'Investment', 'FarmAndFish', 'FireAndRescue', 'Dependents', 'SpouseSupport', 'RentalIncome', 'Tuition', 'Donations', 'MedicalExpense', 'Rrsp', 'Moving', 'ForeignIncome']
        return (
            <>
                {docSuggestions.map((suggestion, index) => (
                    <Collapse in={value === index} timeout="auto" unmountOnExit key={index}>
                        <div className='dox-suggestions-wrapper' >
                            <Typography className='dox-suggestions-Subheader' paragraph>{onTranslateString("Dox.DocumentSuggestions.Subheader")}</Typography>
                            <ul className={layoutType === 'desktop' ? 'dox-suggestions-ul-desktop' : 'dox-suggestions-ul'} >
                                {suggestion.suggestedDocuments.map((document, docIndex) => (
                                    <li key={docIndex} className='dox-suggestions-li' >{onTranslateString("Dox.DocumentSuggestions.TaxScenarioTabs." + suggestion.taxScenario + "." + document)}</li>
                                ))}
                            </ul>
                            {infoScenarios.includes(suggestion.taxScenario) &&
                                <div className='dox-suggestions-info-container-wrapper' >
                                    <div className='dox-suggestions-info-icon'>
                                        <img src={InfoIcon} />
                                    </div>
                                    <div>
                                        <Typography paragraph className='dox-suggestions-info-text'>{onTranslateString("Dox.DocumentSuggestions.TaxScenarioTabs." + suggestion.taxScenario + ".Info")}</Typography>
                                    </div>
                                </div>
                            }
                        </div>
                    </Collapse>
                ))}
            </>
        );
    }

    const handleTabChange = (event, newValue) => {
        setCollapseValue(newValue);

        const HeapEventsList = [
            { condition: docSuggestions[newValue].taxScenario === 'T4', event: HeapEvents.DOC_SUGGESTION_T4 },
            { condition: docSuggestions[newValue].taxScenario === 'T4AndRL', event: HeapEvents.DOC_SUGGESTION_T4AndRL },
            { condition: docSuggestions[newValue].taxScenario === 'SelfEmployedIncome', event: HeapEvents.DOC_SUGGESTION_SelfEmployedIncome },
            { condition: docSuggestions[newValue].taxScenario === 'Retirement', event: HeapEvents.DOC_SUGGESTION_Retirement },
            { condition: docSuggestions[newValue].taxScenario === 'BusinessIncome', event: HeapEvents.DOC_SUGGESTION_BusinessIncome },
            { condition: docSuggestions[newValue].taxScenario === 'WorkExpense', event: HeapEvents.DOC_SUGGESTION_WorkExpense },
            { condition: docSuggestions[newValue].taxScenario === 'Disability', event: HeapEvents.DOC_SUGGESTION_Disability },
            { condition: docSuggestions[newValue].taxScenario === 'MedicalExpense', event: HeapEvents.DOC_SUGGESTION_MedicalExpense },
            { condition: docSuggestions[newValue].taxScenario === 'Benefits', event: HeapEvents.DOC_SUGGESTION_Benefits },
            { condition: docSuggestions[newValue].taxScenario === 'Investment', event: HeapEvents.DOC_SUGGESTION_Investment },
            { condition: docSuggestions[newValue].taxScenario === 'Rrsp', event: HeapEvents.DOC_SUGGESTION_Rrsp },
            { condition: docSuggestions[newValue].taxScenario === 'RentalIncome', event: HeapEvents.DOC_SUGGESTION_RentalIncome },
            { condition: docSuggestions[newValue].taxScenario === 'ForeignIncome', event: HeapEvents.DOC_SUGGESTION_ForeignIncome },
            { condition: docSuggestions[newValue].taxScenario === 'Dependents', event: HeapEvents.DOC_SUGGESTION_Dependents },
            { condition: docSuggestions[newValue].taxScenario === 'PropertyBuySell', event: HeapEvents.DOC_SUGGESTION_PropertyBuySell },
            { condition: docSuggestions[newValue].taxScenario === 'SpouseSupport', event: HeapEvents.DOC_SUGGESTION_SpouseSupport },
            { condition: docSuggestions[newValue].taxScenario === 'Moving', event: HeapEvents.DOC_SUGGESTION_Moving },
            { condition: docSuggestions[newValue].taxScenario === 'Tuition', event: HeapEvents.DOC_SUGGESTION_Tuition },
            { condition: docSuggestions[newValue].taxScenario === 'Donations', event: HeapEvents.DOC_SUGGESTION_Donations },
            { condition: docSuggestions[newValue].taxScenario === 'FireAndRescue', event: HeapEvents.DOC_SUGGESTION_FireAndRescue },
            { condition: docSuggestions[newValue].taxScenario === 'FarmAndFish', event: HeapEvents.DOC_SUGGESTION_FarmAndFish }
        ];

        HeapEventsList.forEach(({ condition, event }) => {
            if (condition) {
                trackEvent(event);
            }
        });
    };

    const handleCollapseToggle = () => {
        setExpanded(!expanded);
    };

    return (
        <div className='dox-suggestions-panel'>
            <Box className={classnames('dox-suggestions-title', { 'dox-suggestions-title-expanded': expanded })} onClick={handleCollapseToggle} >
                <Typography className='dox-suggestions-title-text'>{expanded ? onTranslateString("Dox.DocumentSuggestions.TitleExpanded") : onTranslateString("Dox.DocumentSuggestions.TitleCollapsed")}</Typography>
                <IconButton >
                    <ExpandMoreIcon className={expanded ? 'dox-suggestions-chevron chevron-inverted' : 'dox-suggestions-chevron'} />
                </IconButton>
            </Box>
            {expanded &&
                <Box bgcolor="#E9F3F8" p={2} className='dox-suggestions-inner-panel'>
                    <Collapse in={expanded}>
                        <Box p={2} className='dox-suggestions-box-panel'>
                            <div className='dox-suggestions-tabs-panel'>
                                <Tabs
                                    value={collapseValue}
                                    onChange={handleTabChange}
                                    TabScrollButtonProps={{ style: { color: '#0186C9' } }}
                                    textColor='inherit'
                                    TabIndicatorProps={{ style: { backgroundColor: "#0186C9", height: "2px" } }}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    {docSuggestions.map((suggestion, index) => (
                                        <Tab className='dox-suggestion-tab'
                                            key={index}
                                            label={onTranslateString("Dox.DocumentSuggestions.TaxScenarioTabs." + suggestion.taxScenario + ".TaxScenario")}
                                            icon={<img src={getIconPath(suggestion.taxScenario)} className={collapseValue !== index ? 'dox-suggestion-scenario-img' : 'dox-suggestion-scenario-img-active'} />}
                                        />
                                    ))}
                                </Tabs>
                            </div>
                            <TabContent value={collapseValue} />
                        </Box>
                    </Collapse>
                </Box>
            }
        </div>
    );
}

export default DoxSuggestionPanel;