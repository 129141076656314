import React from 'react';
import { PropTypes } from 'prop-types';
import T from 'i18n-react';
import classnames from 'classnames'
import './workflowbutton.css';
import FontAwesome from 'react-fontawesome';
import { WorkflowButtonStates } from '../Section/WorkflowButtonStates'

const ConfirmWorkflowButton = (props) => {
    return (
        <div className={classnames('workflow-button-confirm', props.ticketType)}>
            {props.isAlternate ?
                <div className='workflow-button-icon-container icon-display-reset'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
                        <path d="M23.3333 4.13122H10C8.16666 4.13122 6.68333 5.63122 6.68333 7.46455L6.66666 34.1312C6.66666 35.9646 8.15 37.4646 9.98333 37.4646H30C31.8333 37.4646 33.3333 35.9646 33.3333 34.1312V14.1312L23.3333 4.13122ZM30 34.1312H10V7.46455H21.6667V15.7979H30V34.1312ZM13.3333 25.8146L15.6833 28.1646L18.3333 25.5312V32.4646H21.6667V25.5312L24.3167 28.1812L26.6667 25.8146L20.0167 19.1312L13.3333 25.8146Z" fill="#1497A2" />
                    </svg>
                </div> :
                <div className='workflow-button-icon-container icon-display-reset'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
                        <path d="M23.3333 4.13122H10C8.16666 4.13122 6.68333 5.63122 6.68333 7.46455L6.66666 34.1312C6.66666 35.9646 8.15 37.4646 9.98333 37.4646H30C31.8333 37.4646 33.3333 35.9646 33.3333 34.1312V14.1312L23.3333 4.13122ZM30 34.1312H10V7.46455H21.6667V15.7979H30V34.1312ZM13.3333 25.8146L15.6833 28.1646L18.3333 25.5312V32.4646H21.6667V25.5312L24.3167 28.1812L26.6667 25.8146L20.0167 19.1312L13.3333 25.8146Z" fill="#007500" />
                    </svg>
                </div>
            }
            <div className='workflow-button-confirm-label-container'>
                <h2><div className='unselectableLabel'>{T.translate(props.text, props.textProps)}</div></h2>
            </div>
            {props.isAlternate ?
                <div className='doc-upload-chevron'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20" fill="none">
                        <path d="M0.686279 2.25541L2.5719 0.369789L12 9.79788L2.5719 19.226L0.686279 17.3403L8.22875 9.79788L0.686279 2.25541Z" fill="#1497A2" />
                    </svg>
                </div> :
                <div className='doc-upload-chevron'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20" fill="none">
                        <path d="M0.686279 2.25541L2.5719 0.369789L12 9.79788L2.5719 19.226L0.686279 17.3403L8.22875 9.79788L0.686279 2.25541Z" fill="#007500" />
                    </svg>
                </div>
            }
            {props.buttonState !== WorkflowButtonStates.SHOW &&
                <div className='cwb-spinner-holder'>
                    <FontAwesome className='fa-spinner' name='fa-spinner' pulse size="2x" />
                </div>
            }
        </div>
    )
}

ConfirmWorkflowButton.propTypes = {
    icon: PropTypes.string,
    ticketType: PropTypes.string
};

export default ConfirmWorkflowButton;
