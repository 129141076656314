// src/index.js
import './polyfills'
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux'
import createStore from './reducers'
import storeStorage from './reducers/storeStorage'
import './index.css';
import './images/font-awesome-4.7.0/css/font-awesome.min.css';
import whyDidYouRender from '@welldone-software/why-did-you-render'
import App from './App'
import { PersistGate } from 'redux-persist/integration/react'
import { ConnectedRouter } from 'connected-react-router'

let { store, persistor, history } = createStore();
storeStorage.configure(store, persistor, history);

whyDidYouRender(React);

window.onpageshow = function (ev) {
    if (ev.persisted) {
        window.location.reload()
    }
}

render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
)