import React, { Component } from 'react';
import '../Common/shared.css';
import T from 'i18n-react';
import ValidatingTextInput from '../Common/validatingTextInput'
import GenderDropDown from '../Controls/DropDowns/genderDropDown'
import SINTextInput from '../Common/sinTextInput'
import DateTextInput from '../Common/dateTextInput'
import RelationDropDown from '../Controls/DropDowns/relationDropDown'
import FontAwesome from 'react-fontawesome';

class PersonalInfoForm extends Component
{
    constructor(props)
    {
        super(props)

        var personalInfo = this.props.personalInfo || {
            firstName: '',
            middleName: '',
            lastName: '',
            gender: '',
            dob: '',
            sin: ''
        }

        this.state = {
            personalInfo,
            simpleVersion: Boolean(this.props.simpleVersion),
            dependantVersion: Boolean(this.props.dependantVersion),
            labelKey: this.props.labelKey,
            missingRequiredField: this.props.missingRequiredField,
            lang: this.props.lang,
            allRequiredFields: this.props.allRequiredFields
        }
    }

    componentWillReceiveProps(newProps)
    {
        if (this.state.missingRequiredField !== newProps.missingRequiredField)
        {
            this.setState({ missingRequiredField: newProps.missingRequiredField })
        }
        if (this.state.personalInfo !== newProps.personalInfo)
        {
            this.setState({ personalInfo: newProps.personalInfo })
        }
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }
        if (this.state.allRequiredFields !== newProps.allRequiredFields) {
            this.setState({ allRequiredFields: newProps.allRequiredFields })
        }
    }

    handleTextChange = (ev) =>
    {
        let personalInfo = {
            ...this.state.personalInfo
        }
        personalInfo[ev.target.name] = ev.target.value;
        this.setState({ personalInfo });

        if (this.props.onPersonalInfoChanged)
        {
            this.props.onPersonalInfoChanged(personalInfo)
        }
    }

    handleValueChange = (event, value) =>
    {
        let personalInfo = {
            ...this.state.personalInfo
        }
        personalInfo[event.target.name] = value;
        this.setState({ personalInfo });

        if (this.props.onPersonalInfoChanged)
        {
            this.props.onPersonalInfoChanged(personalInfo)
        }
    };

    onDependantPropertyChanged(ev, value) {
        if (this.props.onDependantPropertyChanged) {
            this.props.onDependantPropertyChanged(ev, value);
        }
    }

    validateNameField(textValue)
    {
        return !textValue || textValue.match(/^[a-zA-Z\u00C0-\u017F\'\.\-\s]+$/)   // eslint-disable-line no-useless-escape
    }

    validateDateField(textValue) {
        return !textValue // eslint-disable-line no-useless-escape
    }

    render() {
        var mainDivClassName = ""  //'validatingTextInputStyle fixedWidthTextInput';
        var nonSimpleContent1 = null;
        
        if (!this.state.simpleVersion && !this.state.dependantVersion)
        {
            nonSimpleContent1 = (
                <ValidatingTextInput name="middleName" autoComplete="additional-name"
                    labelText={T.translate(this.state.labelKey + ".fieldLabel1")}
                    value={this.state.personalInfo.middleName} heapIgnore="true"
                    onChange={(ev) => this.handleTextChange(ev)}
                    validationFailedText={T.translate("Common.Errors.InvalidNameChars")}
                    validator={(textValue) => { return this.validateNameField(textValue) }}
                    mainDivAdditionalClass="fixedWidthTextInput"
                />
            )
        }

        var simpleContent = null;
        if (this.state.simpleVersion)
        {
            simpleContent = (                
                <div class="zeroInputWidth leftMargin"></div>
            )
        }

        var genderContent = null;
        if (this.state.dependantVersion)
        {
            var missing = this.state.allRequiredFields && this.state.missingRequiredField && !this.state.personalInfo.gender
            var genderValidationContent = []
            if (missing) {
                genderValidationContent.push(
                    <FontAwesome key='gvicon' className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{
                        textShadow: '0 1px 0 rgba(0, 0, 0, 0)',
                        color: '#E70000',
                        align: 'center'
                    }} />
                )
                genderValidationContent.push(<span key='gvtext' style={{ marginLeft: 10 }}>{T.translate('Common.Errors.MissingRequiredField')}</span>)
            }

            var labelClass = 'formLabelStyle'
            if (this.state.allRequiredFields && this.state.missingRequiredField && !this.state.personalInfo.gender) {
                labelClass += ' red'
            }
            
            let genderClass = this.state.simpleVersion ? 'zeroInputWidth' : 'validatingTextInputStyle rightMargin shorterInputWidth';
            genderContent = (
                <div className={genderClass}>            
                    <div className={mainDivClassName + ' verticalStretchStyle'}>
                        <div className={labelClass}>{T.translate(this.state.labelKey + ".fieldLabel4") + (!this.state.dependantVersion ? '*' : '')}</div>
                        <GenderDropDown name='gender' lang={this.state.lang} value={this.state.personalInfo.gender}
                            isRequired={this.state.allRequiredFields} missingRequiredField={this.state.missingRequiredField}
                            onValueChanged={(ev, value) => this.handleValueChange(ev, value)} />
                        <div className="validationErrorStyle">
                            {genderValidationContent}
                        </div>
                    </div>
                </div>
            )
        }

        var sinContent = null;
        if (!this.state.dependantVersion)
        {
            sinContent = (
                <SINTextInput name="sin" size="lg" value={this.state.personalInfo.sin} 
                    labelText={T.translate(this.state.labelKey + ".fieldLabel5") + (this.state.allRequiredFields ? '*' : '')}
                    onChange={(ev) => this.handleTextChange(ev)} missingRequiredField={this.state.missingRequiredField && !this.state.personalInfo.sin}
                    isRequired={true} learnMoreId="FAQs/WhatIsASIN"
                />
            )
        }

        var firstNameContent = null;
        if (this.state.dependantVersion)
        {
            firstNameContent = (
                <ValidatingTextInput name="firstName" autoComplete="off"
                    labelText={T.translate(this.state.labelKey + ".fieldLabel0")}
                    value={this.state.personalInfo.firstName} heapIgnore="true"
                    onChange={(ev) => this.handleTextChange(ev)}
                    validationFailedText={T.translate("Common.Errors.InvalidNameChars")}
                    validator={(textValue) => { return this.validateNameField(textValue) }}
                    mainDivAdditionalClass="fixedWidthTextInput"
                />)
        }
        else
        {
            firstNameContent = (<ValidatingTextInput name="firstName" autoComplete="given-name"
                labelText={T.translate(this.state.labelKey + ".fieldLabel0")}
                value={this.state.personalInfo.firstName} heapIgnore="true"
                onChange={(ev) => this.handleTextChange(ev)}
                validationFailedText={T.translate("Common.Errors.InvalidNameChars")}
                validator={(textValue) => { return this.validateNameField(textValue) }}
                mainDivAdditionalClass="fixedWidthTextInput" isRequired
                missingRequiredField={this.state.missingRequiredField && !this.state.personalInfo.firstName}
            />)
        }

        let depRelationshipContent = this.state.dependantVersion ? 
            <div>
                <div className="formLabelStyle">{T.translate("Interview.Page8.fieldLabel0")}</div>
                <RelationDropDown name="relationship" lang={this.state.lang} value={this.state.personalInfo.relationship} onValueChanged={(ev, value) => { this.onDependantPropertyChanged(ev, value) }} />
                <div className="validationErrorStyle">
                    &nbsp;
                </div>
            </div> : null;

        var lastFieldContent = this.state.dependantVersion ? depRelationshipContent : sinContent;
      
        let optFieldClass = this.state.simpleVersion || this.state.dependantVersion ? 'zeroInputWidth' : 'leftMargin rightMargin defaultInputWidth';

        // VAI-252 hack: for some reason the styling (which should render the same for spouse and dependant) doesn't want
        //               to work when there is a validation error showing. For now, use different classes to specify different
        //               max-width's
        let nameClass = 'defaultInputWidth';
        if (this.state.dependantVersion) nameClass = 'dep ' + nameClass;
        else if (this.state.simpleVersion) nameClass = 'simple ' + nameClass;
              

        return (
            <div>
                <form name="personal_info_form" autoComplete="on">
                    <div className="horizontalFieldContainerTopAligned">
                        <div className={nameClass + ' rightMargin'}>
                            {firstNameContent}
                        </div> 
                        <div className={optFieldClass}>
                            {nonSimpleContent1}
                        </div>
                        <div className={nameClass + ' leftMargin'}>
                            <ValidatingTextInput name="lastName" autoComplete="family-name"
                                labelText={T.translate(this.state.labelKey + ".fieldLabel2")}
                                value={this.state.personalInfo.lastName} heapIgnore="true"
                                onChange={(ev) => this.handleTextChange(ev)}
                                isRequired={Boolean(this.state.allRequiredFields)}
                                missingRequiredField={this.state.missingRequiredField && !this.state.personalInfo.lastName}
                                validationFailedText={T.translate("Common.Errors.InvalidNameChars")}
                                validator={(textValue) => { return this.validateNameField(textValue) }}
                                mainDivAdditionalClass="fixedWidthTextInput"
                                learnMoreId={this.state.dependantVersion ? null : "FAQs/WhatShouldIDoIfMyOrMySpousesNameChanged"}
                            />
                        </div>
                    </div>
                    <div className="horizontalFieldContainerTopAligned">
                        <div className='defaultDobInputWidth rightMargin'>
                            <DateTextInput name="dob" size="lg" validateMinAge={this.props.validateMinAge}
                                labelText={T.translate(this.state.labelKey + ".fieldLabel3") + (this.state.allRequiredFields ? '*' : '')}
                                value={this.state.personalInfo.dob} heapIgnore="true"
                                birthDate={true} autoComplete="bday"
                                onChange={(ev, value) => this.handleValueChange(ev, value)}
                                missingRequiredField={this.state.missingRequiredField}
                                isRequired={true} />
                        </div>
                        {simpleContent}
                        {genderContent}
                        <div className={optFieldClass + ' longerInputWidth'}>
                            {lastFieldContent}
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default PersonalInfoForm;