import React, { Component } from 'react';
import './shared.css';
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';
import LearnMore from '../Controls/learnMore'

class ValidatingTextInput extends Component
{
    constructor(props) {
        super(props)

        this.state = {
            labelText: this.props.labelText,
            placeholderText: this.props.placeholderText,
            validationFailedText: this.props.validationFailedText,
            validator: this.props.validator,
            hasValidated: false,
            hasErrors: false,
            errorType: null,
            value: this.props.value || "",
            mainDivAdditionalClass: this.props.mainDivAdditionalClass,
            name: this.props.name,
            isRequired: Boolean(this.props.isRequired),
            validationRequired: Boolean(this.props.validationRequired),  // indicates we must validate for empty on blur
            missingRequiredField: this.props.missingRequiredField,
            isNumber: this.props.isNumber,
            maxLength: this.props.maxLength || 1024,
            renderErrorStyle: this.props.renderErrorStyle || false,
            renderErrorBorderOnly: this.props.renderErrorBorderOnly || false,
            learnMoreId: this.props.learnMoreId,
            autoComplete: this.props.autoComplete,
        }
    }

    componentWillReceiveProps(newProps)
    {
        // only checking some of the properties that might change
        if (this.state.labelText !== newProps.labelText) {
            this.setState({ labelText: newProps.labelText })
        }

        if (this.state.value !== newProps.value) {
            this.setState({value: newProps.value})
        }

        if (this.state.validationFailedText !== newProps.validationFailedText)
            this.setState({validationFailedText: newProps.validationFailedText})

        if (this.state.missingRequiredField !== newProps.missingRequiredField) {
            this.setState({ missingRequiredField: newProps.missingRequiredField })
            if (newProps.missingRequiredField) {
                this.handleBlur()
            }
        } else if (newProps.missingRequiredField && this.state.isRequired) {
            this.handleBlur()
        }     

        if (this.state.isNumber !== newProps.isNumber) {
            this.setState({ isNumber: newProps.isNumber })
        }

        if (newProps.maxLength && this.state.maxLength !== newProps.maxLength) {
            this.setState({maxLength: newProps.maxLength})
        }

        if (newProps.validationRequired !== this.state.validationRequired) {
            this.setState({validationRequired: this.props.validationRequired})
        }

        if (newProps.renderErrorStyle !== this.state.renderErrorStyle) {
            this.setState({renderErrorStyle: newProps.renderErrorStyle})
        }

        if (newProps.renderErrorBorderOnly !== this.state.renderErrorBorderOnly) {
            this.setState({ renderErrorBorderOnly: Boolean(newProps.renderErrorBorderOnly) })
        }

        if (newProps.learnMoreId !== this.state.learnMoreId) {
            this.setState({ learnMoreId: newProps.learnMoreId })
        }

        if (newProps.autoComplete !== this.state.autoComplete) {
            this.setState({ autoComplete: newProps.autoComplete })
        }
    }

    validateText(currText) {
        if ((this.state.isRequired || this.state.validationRequired) && !Boolean(currText)) {
            return { status: false, errorType: 'required' }
        }
        if (this.state.validator && !this.state.validator(currText)) {
            return { status: false, errorType: 'validatory' }
        }
        return { status: true }
    }

    handleTextChange(ev) {
        const value = ev.target.value;
        
        const newState = {
            ...this.state,
            value: value 
        };
        
        if (this.state.hasValidated) {
            const validation = this.validateText(value);
            newState.hasErrors = !validation.status;
            newState.errorType = validation.errorType;
        }

        this.setState(newState, () => {
            if (this.props.onChange) {
                this.props.onChange(ev, value);
            }
        });
    }

    handleBlur() {
        var validation = false;
        if (!this.state.hasValidated && (this.state.value || this.state.isRequired || this.state.validationRequired)) {
            validation = this.validateText(this.state.value)

            this.setState({ hasValidated: true, hasErrors: !validation.status, errorType: validation.errorType })
        }  
        else if ((!this.state.value) && (this.state.isRequired)) {
            validation = this.validateText(this.state.value)

            this.setState({ hasValidated: true, hasErrors: !validation.status, errorType: validation.errorType })
        }

        if (this.state.value) {
            var state = {
                ...this.state,
                value: this.state.value.trim()
            }
            this.setState(state)
            var event = { target: { value: state.value, name: state.name } }
            this.handleTextChange(event)

        }

        if (this.props.onBlur) {
            this.props.onBlur()
        }
    }

    onKeyDown(ev) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(ev);
        }
    }

    render() {
        var mainDivClassName = "validatingTextInputStyle"

        var topTextStyle = null
        if (this.props.extraTopSpacing) {
            topTextStyle = {
                height: '36px'
            }
        }

        var spacerSpanStyle = this.state.learnMoreId ? "span_spacer_right" : "hidden"

        if (this.state.hasErrors || this.state.renderErrorStyle || this.state.renderErrorBorderOnly) {
            var valErrors = this.state.renderErrorBorderOnly ? (
                <div className="validationErrorStyle">
                    &nbsp;
                    </div>
                ) : (
                <div className="validationErrorStyle">
                    <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#E70000', align: 'center' }} />
                    <span style={{ marginLeft: 10 }}>{this.state.errorType === 'required' ? T.translate('Common.Errors.MissingRequiredField') : this.state.validationFailedText}</span>
                </div>
            )
            return (
                <div className="defaultErrorStyles validatingTextInputStyle">
                    <div className="red formLabelStyle" style={topTextStyle}>
                        {this.state.labelText}{this.state.isRequired ? "*" : ""}
                        <span className={spacerSpanStyle} />
                        <LearnMore linkId={this.state.learnMoreId} />
                    </div>
                    {this.state.autoComplete &&
                        <input type={this.state.isNumber ? "tel" : "text"} name={this.state.name}
                            value={this.state.value} onBlur={(ev) => { this.handleBlur() }}
                            onChange={(ev) => { this.handleTextChange(ev) }} maxLength={this.state.maxLength}
                            onKeyDown={(ev) => { this.onKeyDown(ev) }} heap-ignore="true"
                            autoComplete={this.state.autoComplete} placeholder={this.props.placeholderText} />
                    }
                    {!this.state.autoComplete && 
                        <input type={this.state.isNumber ? "tel" : "text"} name={this.state.name}
                            value={this.state.value} onBlur={(ev) => { this.handleBlur() }}
                            heap-ignore="true"
                            onChange={(ev) => { this.handleTextChange(ev) }} maxLength={this.state.maxLength}
                            onKeyDown={(ev) => { this.onKeyDown(ev) }} placeholder={this.props.placeholderText} />
                    }

                    {valErrors}
                </div>
            )
        } else {
            return (
                <div className={mainDivClassName}>
                    <div className="formLabelStyle" style={topTextStyle}>
                        {this.state.labelText}{this.state.isRequired ? "*" : ""}
                        <span className={spacerSpanStyle} />
                        <LearnMore linkId={this.state.learnMoreId} />
                    </div>
                    {this.state.autoComplete &&
                        <input type={this.state.isNumber ? "tel" : "text"} name={this.state.name}
                            value={this.state.value} onBlur={(ev) => { this.handleBlur() }}
                            heap-ignore="true" 
                            onChange={(ev) => { this.handleTextChange(ev) }} maxLength={this.state.maxLength}
                            onKeyDown={(ev) => { this.onKeyDown(ev) }}
                            autoComplete={this.state.autoComplete} placeholder={this.props.placeholderText}
                        />
                    }
                    {!this.state.autoComplete &&
                        <input type={this.state.isNumber ? "tel" : "text"} name={this.state.name}
                            heap-ignore="true"
                            value={this.state.value} onBlur={(ev) => { this.handleBlur() }}
                            onChange={(ev) => { this.handleTextChange(ev) }} maxLength={this.state.maxLength}
                            onKeyDown={(ev) => { this.onKeyDown(ev) }} placeholder={this.props.placeholderText}
                        />
                    }
                    <div className="validationErrorStyle">
                        &nbsp;
                    </div>
                </div>
            )
            
        }
    }
}


export default ValidatingTextInput;