import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types'
import T from 'i18n-react';
import './section.css'
import SectionLabel from './SectionLabel'
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux';

const WorkflowSection = (props) => (
    <div className={classNames('wf-section-container', { 'wf-section-alternate': props.isAlternate }, props.ticketType.toLowerCase())} >
            <div className='wf-section-header'>
                <div className='wf-section-title'>
                    <div>
                        {T.translate(props.titleKey, props.titleKeyProps)}
                    </div>
                    {props.canClose && props.showOteContent &&
                        <div className="wf-section-close-container" onClick={props.onClose}>
                            <CloseIcon color='inherit' />
                        </div>
                    }
                </div>
                <div className={classNames('wf-section-top-link', { 'wf-section-hide': props.topLinkKey })}
                    onClick={props.topLinkClick} >
                    {T.translate(props.topLinkKey || '')}
                </div>
            </div>
            {props.children}
            {props.comingUpKey && props.showOteContent &&
                <SectionLabel titleKey={props.comingUpTitleKey || 'WorkflowPanel.comingUp'} contentKey={props.comingUpKey} contentProps={props.comingUpKeyProps} />
            }
        </div>
    );

WorkflowSection.propTypes = {
    isAlternate: PropTypes.bool,
    canClose: PropTypes.bool.isRequired,
    titleKey: PropTypes.string.isRequired,
    titleKeyProps: PropTypes.object,
    topLinkKey: PropTypes.string,
    topLinkClick: PropTypes.func,
    comingUpTitleKey: PropTypes.string,
    comingUpKey: PropTypes.string,
    comingUpKeyProps: PropTypes.object,
    children: PropTypes.node,
    onClose: PropTypes.func,
    ticketType: PropTypes.string
};

const mapStateToProps = state => ({
    lang: state.layout.lang,
    showOteContent: !state.workflow.is_direct_user
});

export default connect(mapStateToProps)(WorkflowSection);