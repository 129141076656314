import React, { Component } from 'react';
import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import SigningActions from '../../reducers/signingRedux'
import LoadingSpinner from '../Loading/loadingSpinner'
import '../App/style.css'

class ESignatureCallback extends Component {
    constructor(props) {
        super(props)

        this.state = {
            location: props.location,
            signingCompleteRoute: props.signingCompleteRoute,
            loginCallbackStatus: props.loginCallbackStatus
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.state.location !== newProps.location) {
            this.setState({ location: newProps.location })
        }
        if (this.state.signingCompleteRoute !== newProps.signingCompleteRoute) {
            this.setState({ signingCompleteRoute: newProps.signingCompleteRoute })
        }
        if (this.state.signingCallbackStatus !== newProps.signingCallbackStatus) {
            this.setState({ signingCallbackStatus: newProps.signingCallbackStatus })
            if (newProps.signingCallbackStatus === 'complete' || newProps.signingCallbackStatus === 'failed') {
                this.props.replaceNav(this.state.signingCompleteRoute)
            }
        }
    }

    UNSAFE_componentWillMount() {
        this.props.processDocumentCallback('in_progress')
    }

    render() {
        return (
            <div className='callBackLoadingContainer'>
                <LoadingSpinner />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        location: state.router.location,
        signingCompleteRoute: state.signing.signingCompleteRoute,
        signingCallbackStatus: state.signing.signingCallbackStatus,
    }
}

const mapDispatchToProps = (dispatch) => ({
    replaceNav: (route) => dispatch(replace(route)),
    processDocumentCallback: (status) => dispatch(SigningActions.processDocumentCallback(status))
})

export default connect(mapStateToProps, mapDispatchToProps)(ESignatureCallback)