import React, { Component } from 'react';
import { connect } from 'react-redux'
import { replace, push } from 'connected-react-router'
import BaseModal from '../Modal/BaseModal';
import T from 'i18n-react';
import { getTaxYearState } from '../../utils/CaseStateUtilities'
import SendUsMessage from '../../components/Controls/SendUsMessage.js'
import '../Interview/highlightedSection.css'

import '../AuthorizeHRB/style.css';
import './style.css'

import LayoutActions from '../../reducers/layoutRedux'
import WorkflowActions from '../../reducers/workflowRedux'
import DocumentActions from '../../reducers/documentsRedux'
import MessagesActions from '../../reducers/messagesRedux'
import ApproveDraftsActions from '../../reducers/approveDraftsRedux'
import SigningActions from '../../reducers/signingRedux'
import FilesActions from '../../reducers/filesRedux'
import InfoDialogContent from '../Messages/infoDialogContent'
import ReturnLink from './returnLink';
import PlainButton from '../Controls/plainButton'
import LoadingSpinner from '../Loading/loadingSpinner'
import { setTimeout } from 'timers-browserify';

// TODO Removed box wrapper

class reviewAltPage2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stage: props.stage,
            fileInViewer: null,
            missingFiles: false,
            missingFilesErrorVisible: false,
            processing: false,
            taxYear: this.props.taxYear
        }
    }

    componentDidMount() {
        if (!this.props.taxYearState) {
            this.props.replaceNavigation('/missing')
        }
        if (this.missingFile(this.props.taxYearDocs)) {
            this.props.loadDocumentsForReview(this.props.taxYear);
        }
        if (this.missingFile(this.props.approvalDocs)) {
            this.props.loadApprovalDocuments(this.props.taxYear);
        }

        var workflowState = this.props.workflow.case_state.taxYears.find(x => x.taxYear === Number(this.props.taxYear)).workflow_state

        this.props.setLastReviewedPage(workflowState.case_id, 2, this.state.taxYear)

        if (this.props.approvalDocs && this.props.approvalDocs.primaryFederal === null && this.props.approvalDocs.primaryQuebec === null && this.props.approvalDocs.spouseFederal === null && this.props.approvalDocs.spouseQuebec === null) {
            this.setState({ missingFilesErrorVisible: true })
        }
        else {
            this.setState({ missingFilesErrorVisible: false })
        }
    }

    missingFile = (docs) => {
        if (!docs) {
            return true;
        }
        if (!docs.primaryFederal || !docs.primaryFederal.fileId) {
            return true;
        }
        if (this.props.taxYearState.coupled && (!docs.spouseFederal || !docs.spouseFederal.fileId)) {
            return true;
        }
        if (this.props.taxYearState.primary_province === 'QC' && (!docs.primaryQuebec || !docs.primaryQuebec.fileId)) {
            return true;
        }
        if (this.props.taxYearState.coupled && this.props.taxYearState.spouse_province === 'QC'
            && (!docs.spouseQuebec || !docs.spouseQuebec.fileId)) {
            return true;
        }
        return false;
    }   

    componentWillReceiveProps(newProps) {
        if (this.state.stage !== newProps.stage) {
            window.scrollTo(0, 0)
            this.setState({ stage: newProps.stage }) 
            // if we're changing the signing stage, go and do the signing
            if (newProps.stage === 'approveSpouse') this.goSignThings(newProps)
        }
        if (this.state.signedDocuments !== newProps.signedDocuments) {
            this.setState({ signedDocuments: newProps.signedDocuments })
        }
        if (this.state.signingState !== newProps.signingState) {
            this.setState({ signingState: newProps.signingState })
        }
        if (this.state.signingError !== newProps.signingError) {
            this.setState({ signingError: newProps.signingError })
            // VAI-476 show 
            if (newProps.signingError === 'filenotfound') this.setState({ missingFilesErrorVisible: true })
        }
        if (this.state.postLoginComplete !== newProps.postLoginComplete) {
            this.setState({ postLoginComplete: newProps.postLoginComplete })
            // VAI-458 copied code from componentDidMount to here (just in case)
            setTimeout(this.goSignThings(newProps), 100)
        }
    }

    getLink(fileType) {
        var file = { ...this.props.approvalDocs[fileType] }
        return (file ? file.shareLink : null);
    }

    // Local event handlers 
    onBackToDashClicked(e) {
        this.props.replaceNavigation('/dashboard')
    }

    onViewClicked(fileType) {
        var file = this.props.approvalDocs[fileType]
        if (file && file.fileId) { 
            this.setState({ fileInViewer: file.fileId })
            this.props.setBoxDialogStates(false, false, true);
            this.props.setDocumentForReviewViewed(file.fileId, this.props.taxYear);
        } else {
            // show the box folder view
            this.setState({ fileInViewer: null })
            this.props.setBoxDialogStates(false, false, true);
        }
    }

    onDownloadClicked(fileType) {
        this.props.setApprovalForms(this.props.approvalDocs)
        var file = this.props.approvalDocs[fileType]
        if (file && file.fileId) { 
            this.props.setDocumentForReviewViewed(file.fileId, this.props.taxYear);
            this.props.setFileViewed(fileType, this.props.taxYear);
        }
    }

    continueStep() {
        this.props.pushNavigation('/dashboard/reviewalt/' + this.state.taxYear + '/3')
    }

    finishThisLater() {
        this.props.setInputScreenShowing(true)
        this.props.setDashboardTab('dashboard')
        this.props.pushNavigation('/dashboard')
    }

    handleBoxDialogClose() {
        this.props.setBoxDialogStates(false, false, false)
    }

    handleNoPrinterClick(ev) {
        var workflowState = this.props.workflow.case_state.taxYears.find(x => x.taxYear === Number(this.props.taxYear)).workflow_state
        var message = T.translate('ReviewAndApproveAlternate.NoPrinterInternalNote', {PrimaryFirstName: this.props.accountData.firstName})
        this.props.sendInternalNoteNoPrinter(workflowState.case_id, message)
        this.props.setLastReviewedPage(workflowState.case_id, 3, this.state.taxYear)

    }

    render() {
        // determine the root styling.
        let bodyStyle = 'reviewContainerAutoHeight';
        bodyStyle += (this.props.layoutType === 'tablet' || this.props.layoutType === 'small_tablet' ? ' tablet' :
            (this.props.layoutType === 'mobile' ? ' mobile' : ' desktop'));
        var languageRegion = this.props.taxYearState.primary_province === 'QC' ? 'ReviewAndApprove.AltPage2.Quebec' : 'ReviewAndApprove.AltPage2.Common'

        var previewLinks = [
            <ReturnLink key="primaryFederal"
                titleKey={languageRegion + '.Consent.CRA'}
                layoutType={this.props.layoutType}
                firstName={this.props.taxYearState.primary_firstName}
                file={this.props.approvalDocs ? this.props.approvalDocs ? this.props.approvalDocs.primaryFederal : null : null}
                onViewClicked={() => this.onViewClicked('primaryFederal')}
                onDownloadClicked={() => this.onDownloadClicked('primaryFederal')}
                withoutView={true}/>
        ]

        if (this.props.taxYearState.primary_province === 'QC' && this.props.approvalDocs !== null) {
            previewLinks.push(
                <ReturnLink key="primaryQuebec"
                    titleKey={languageRegion + '.Consent.RQ'}
                    layoutType={this.props.layoutType}
                    firstName={this.props.taxYearState.primary_firstName}
                    file={this.props.taxYearDocs ? this.props.approvalDocs ? this.props.approvalDocs.primaryQuebec : null : null}
                    onViewClicked={() => this.onViewClicked('primaryQuebec')}
                    onDownloadClicked={() => this.onDownloadClicked('primaryQuebec')}
                    withoutView={true}
                />
            )
        }

        if (this.props.taxYearState.coupled) {
            previewLinks.push(
                <ReturnLink key="spouseFederal"
                    titleKey= { languageRegion + '.Consent.CRA' }
                    layoutType={this.props.layoutType}
                    firstName={this.props.taxYearState.spouse_firstName}
                    file={this.props.taxYearDocs ? this.props.approvalDocs ? this.props.approvalDocs.spouseFederal : null: null}
                    onViewClicked={() => this.onViewClicked('spouseFederal')}
                    onDownloadClicked={() => this.onDownloadClicked('spouseFederal')}
                    withoutView={true}
                />
            )
            if (this.props.taxYearState.coupled && this.props.taxYearState.spouse_province === 'QC' && this.props.approvalDocs !== null)
                previewLinks.push(
                    <ReturnLink key="spouseQuebec"
                        titleKey={languageRegion + '.Consent.RQ'}
                        layoutType={this.props.layoutType}
                        firstName={this.props.taxYearState.spouse_firstName}
                        file={this.props.taxYearDocs ? this.props.approvalDocs ? this.props.approvalDocs.spouseQuebec : null : null}
                        onViewClicked={() => this.onViewClicked('spouseQuebec')}
                        onDownloadClicked={() => this.onDownloadClicked('spouseQuebec')}
                        withoutView={true}
                    />                    
                )
        }

        // hack for single return on mobile view (center it!)
        var linkStyle = null;
        if (this.props.layoutType === 'mobile' && !this.props.taxYearState.coupled && this.props.taxYearState.primary_province !== 'QC') {
            linkStyle = { justifyContent: 'center', minWidth: 0 }
        }

        var isViewed = this.props.approvalDocs ? [this.props.approvalDocs['primaryFederal'], this.props.approvalDocs['primaryQuebec'], this.props.approvalDocs['spouseFederal'], this.props.approvalDocs['spouseQuebec']] : []
        var viewed = false
        if (isViewed.length > 0) {
            var filteredView = isViewed.filter(x => x !== null)
                filteredView = filteredView.filter(x => x.fileId !== null)
            if (filteredView.length > 0) {                
                viewed = filteredView.every(x => x.isViewed) && filteredView.length === previewLinks.length;                
            }
        }
        var taxProFirstName;
        if (this.props.taxYearState.assigned_tax_pro && this.props.taxYearState.assigned_tax_pro.name) {
             taxProFirstName = this.props.taxYearState.assigned_tax_pro.name.split(' ')[0]
            }
        var preMessage = T.translate('ReviewAndApproveAlternate.NoPrinterMessageLine1', { TaxProFirstName: taxProFirstName }) + '\r\r'+ 
            T.translate('ReviewAndApproveAlternate.NoPrinterMessageLine2') + '\r\r' + T.translate('ReviewAndApproveAlternate.NoPrinterMessageLine3')        

        // spit out some stuff
        return this.props.taxYearDocs ? (
             <div className={bodyStyle}>
                <div className="authorizeContainerStyle">                    
                    <div className="authorizeContentStyle">
                        <h1>{T.translate(languageRegion + '.Header')}</h1>
                        <T.span text={{ key: languageRegion + '.Description'}}  />
                        <div style={{ height: 20 }} />
                    </div>
                    <div className="authorizeContentStyle">
                        <h2>{T.translate(languageRegion + '.PrintForm.Header')}</h2>
                        <T.span text={{
                            key: languageRegion + '.PrintForm.Description', message: <SendUsMessage style={{ fontSize: 12 }} baseClassName='reviewSpanNoMargin authorizeContentStyleSmallFont' linkId="AuthorizingHRBlock/WhatAreT183andTP1000" />
                        }} />
                        <div style={{ height: 10 }} />
                    </div>
                    <div className="previewWrapperContent" style={{marginTop: 0}}>
                        <div className="previewHorizontalContent" style={linkStyle}>
                        {previewLinks.length > 2 ? [previewLinks[0],previewLinks[1]] : previewLinks}
                        </div>
                        {previewLinks.length > 2 ?
                            <div className="previewHorizontalContent">
                                {previewLinks.length > 3 ? [previewLinks[2],previewLinks[3]] : previewLinks[2]}
                            </div> : null
                        }
                    </div>
                    <div className="authorizeContentStyle">
                        <T.span style={{ fontSize: 14 }} text={{
                            key: languageRegion + '.PrintForm.DescriptionBottom', message:
                                <SendUsMessage style={{ fontSize: 12 }} baseClassName='reviewSpanNoMargin authorizeContentStyleSmallFont' linkId="AuthorizingHRBlock/WhatAreT183andTP1000"
                                    message={preMessage} onClick={(ev) => this.handleNoPrinterClick(ev)}/>
                        }} />
                        <div style={{ height: 20 }} />
                    </div>
                    <div className="authorizeContentStyle highlightedSection highlight" style={{ width: '70%', alignSelf: 'center', paddingTop: '10px', paddingBottom: '12px'}}>
                        <h2>{T.translate( languageRegion + '.Sign.Title')}</h2>
                        <T.span style={{marginLeft:'10px', marginRight: '10px'}} text={{
                            key: languageRegion + '.Sign.Description'}} />
                    </div>
                    <div style={{ height: 20 }} />
                </div>
                <div className="reviewAltBottomContainer" style={{ marginTop: 10 }}>
                    <PlainButton inverted={true} onClick={(e) => this.finishThisLater(e)} labelKey={languageRegion + '.Buttons.Back'} isEnabled={true} />
                    <div style={{width: '20px', height: '10px'}} />
                    <PlainButton inverted={false} onClick={(e) => this.continueStep(e)}
                        labelKey={languageRegion + '.Buttons.Forward'}
                        isEnabled={viewed}
                        inProgress={this.state.processing}
                    />
                </div>
                <BaseModal show={this.state.missingFilesErrorVisible} onClose={() => this.setState({ missingFilesErrorVisible: false })} layoutType={this.props.layoutType} >
                    <InfoDialogContent
                        titleKey='ReviewAndApprove.MissingDocsError.Title'
                        labelKeys={['ReviewAndApprove.MissingDocsError.Label','ReviewAndApprove.MissingDocsError.SubLabel']}
                        onOK={() => { this.setState({ missingFilesErrorVisible: false }) }}
                        onCancel={() => { this.setState({ missingFilesErrorVisible: false }) }}
                        onCloseDialog={() => { this.setState({ missingFilesErrorVisible: false }) }}
                        isWarning={true}
                        faIconName='fa-exclamation'
                    />
                </BaseModal>
            </div>
        ) : (
            <div className='review_spinner_container'>
                <LoadingSpinner />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        lang: state.layout.lang,
        layoutType: state.layout.layoutType,
        uploaderShowing: state.documents.uploaderShowing,
        viewerShowing: state.documents.viewerShowing,
        fileShowing: state.documents.fileShowing,
        taxYearDocs: state.files.documentsForReview[Number(ownProps.taxYear)],        
        taxYearState: getTaxYearState(Number(ownProps.taxYear), state.workflow.case_state),
        approvalDocs: state.files.approvalDocuments[ownProps.taxYear],
        signedDocuments: state.signing.signedDocuments,
        signingState: state.signing.signingState,
        signingError: state.signing.signingError,
        stage: state.approveDrafts.stage,
        workflow: state.workflow,
        accountData: state.accountData
    };
}

const mapDispatchToProps = (dispatch) => ({
    loadDocumentsForReview: (taxYear) => dispatch(FilesActions.loadDocumentsForReview(taxYear)),
    loadApprovalDocuments: (taxYear) => dispatch(FilesActions.loadApprovalDocuments(taxYear)),
    replaceNavigation: (path) => dispatch(replace(path)),
    pushNavigation: (path) => dispatch(push(path)),
    setButtonState: (buttonName, buttonState) => dispatch(WorkflowActions.setButtonState(buttonName, buttonState)),
    setBoxDialogStates: (uploaderShowing, viewerShowing, fileShowing) => dispatch(
        DocumentActions.setBoxDialogStates(uploaderShowing, viewerShowing, fileShowing)),
    setInputScreenShowing: (showing) => dispatch(MessagesActions.setInputScreenShowing(showing)),
    setDashboardTab: (tab) => dispatch(LayoutActions.setDashboardTab(tab)),
    setDocumentForReviewViewed: (fileId, taxYear) => dispatch(FilesActions.setDocumentForReviewViewed(fileId, taxYear)),
    setButtonStates: (buttons) => dispatch(WorkflowActions.setButtonStates(buttons)),
    setCaseState: (state) => dispatch(WorkflowActions.setCaseState(state)),
    approveSelf: (isquebec) => dispatch(ApproveDraftsActions.approveSelf(isquebec)),
    approveSpouse: (isquebec) => dispatch(ApproveDraftsActions.approveSpouse(isquebec)),
    signExternalForm: (taxYear, formNames, formUrls, isSpouse, completedRoute, spouseFirstName, primaryFirstName) => dispatch(
        SigningActions.signExternalForm(taxYear, formNames, formUrls, isSpouse, completedRoute, spouseFirstName, primaryFirstName)),
    signingStateReset: () => dispatch(SigningActions.signingStateReset()),
    setApprovalStage: (stage) => dispatch(ApproveDraftsActions.setApprovalStage(stage)),
    setFileViewed: (file, taxYear) => dispatch(FilesActions.setFileViewed(file, taxYear)),
    setApprovalForms: (files) => dispatch(DocumentActions.setApprovalForms(files)),
    setLastReviewedPage: (caseId, pageNumber, taxYear) => dispatch(WorkflowActions.setLastReviewedPage(caseId, pageNumber, taxYear)),
    sendInternalNoteNoPrinter: (caseId, message) => dispatch(MessagesActions.sendInternalNoteNoPrinter(caseId, message))
})

export default connect(mapStateToProps, mapDispatchToProps)(reviewAltPage2)