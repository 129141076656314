import React from 'react';
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import classnames from 'classnames'
import './dox.css';
import T from 'i18n-react';
import Navigation from '../Common/navigation'
import DoxExplorer from './Explorer/doxExplorer';
import LayoutActions from '../../reducers/layoutRedux'
import AuthActions from '../../reducers/authRedux'
import useLayoutStyle from '../../hooks/useRootStyleHook';

const DocumentManagementPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { rootStyle } = useLayoutStyle()

    const backToSource = () => {
        // head on back to the dashboard or docs tab
        dispatch(LayoutActions.setDashboardTab('documents'))
        history.replace('/dashboard')
    }

    const translateString = (key, params) => {
        return T.translate(key, params)
    }

    const handleUnauthorizedRequest = (err) => {
        window.sessionStorage.setItem('ote.logouttype', 'error')
        dispatch(AuthActions.logoutUser())
    }

    return (
        <div className={classnames('dox-container', rootStyle)}>
            <div className="interview-back-link">
                <Navigation onClick={backToSource} labelKey='Dox.BackToDocs' showArrow />
            </div>
            <div className='dox-title'>                    
                <h1>{T.translate("Documents.ViewButtonLabel")}</h1>
            </div>
            <div className='dox-content'>
                <DoxExplorer onTranslateString={translateString} onUnauthorizedRequest={handleUnauthorizedRequest} isDoxCombined={false}/>
            </div>            
        </div>
    );
}

export default DocumentManagementPage