import React, { useState, useEffect } from 'react';
import T from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux'
import '../style.css'
import { hintHasError, hintHasSpecialCharacters } from '../services/registrationUtils';
import RegisterActions from '../../../reducers/registerRedux';
import AuthActions from '../../../reducers/authRedux'
import ValidatingTextInput from '../../Common/validatingTextInput';
import ArrowButton from '../../Controls/arrowButton';
import PasswordTextInput from '../../Common/passwordTextInput';
import SecurityQuestionDropDown from '../../Controls/DropDowns/securityQuestionsDropDown';
import CheckBox from '../../Common/checkbox';
import { HeapEvents, trackEvent } from '../../../utils/AnalyticsUtil'

const CreateCredentials = () => {
    const dispatch = useDispatch();

    const { lang, layoutType } = useSelector(state => state.layout)
    const { password, answer, hint, termsAccepted} = useSelector(state => state.register.credentialState);
    const consentChecked = useSelector(state => state.register.consentChecked);
    const questionIndex = useSelector(state => state.register.credentialState.questionIndex);
    const question = useSelector(state => state.register.credentialState.question);
    const [dataComplete, setDataComplete] = useState(false);
    const [isSmallLayout, setIsSmallLayout] = useState(false);
    const [privacyLink, setPrivacyLink] = useState(null)
    const [showHintError, setShowHintError] = useState(false)
    const [showInvalidHintError, setShowInvalidHintError] = useState(false)

    useEffect(() => {
        let isSm = layoutType === 'small_tablet' || layoutType === 'mobile'
        if (isSm !== isSmallLayout) {
            setIsSmallLayout(isSm)
        }
    }, [layoutType, setIsSmallLayout, isSmallLayout])

    useEffect(() => {
        setDataComplete(answer && hint && password.value && questionIndex >= 0 && question
            && !hintHasError(hint, answer) && !hintHasSpecialCharacters(hint) && password.isValid && termsAccepted && consentChecked)
    }, [answer, hint, password, questionIndex, question, termsAccepted, consentChecked])

    useEffect(() => {
        setPrivacyLink(<a href={T.translate('Common.PrivacyLink')} target="_blank" rel="noopener noreferrer"><b>{T.translate('Common.PrivacyPolicy')}</b></a>)
    }, [lang])

    const handlePasswordChange = (ev, isValid) => {
        dispatch(RegisterActions.setCredStatePassword({ value: ev.target.value, isValid }))
    }

    const handleAnswerChange = (ev) => {
        dispatch(RegisterActions.setCredStateAnswer(ev.target.value.trim()))
    }

    const handleHintChange = (ev) => {
        hintHasSpecialCharacters(ev.target.value) ? setShowInvalidHintError(true) : setShowInvalidHintError(false);
        hintHasError(ev.target.value, answer) ? setShowHintError(true) : setShowHintError(false);
        dispatch(RegisterActions.setCredStateHint(ev.target.value.trim()))
    }

    const handleQuestionChange = (ev, value) => {
        dispatch(RegisterActions.setCredStateQuestionIndex(value, ev.label))
    };

    const handleTermsCheck = (ev) => {
        dispatch(RegisterActions.setCredStateTermsAccepted(ev.checked))        
    }

    const handleConsentCheck = (ev) => {
        dispatch(RegisterActions.setConsentChecked(ev.checked))
    }

    const handleContinue = () => {
        trackEvent(HeapEvents.BUTTON_CREATE_ACCOUNT);
        dispatch(AuthActions.setFirstLogin(true))
        dispatch(RegisterActions.setStepWithMessage('creating_account', 'AccountSetupProgress.statusMessages.Creating'))
    }

    return (
        <div className={isSmallLayout ? "registerContentSmallStyle" : "registerContentStyle"}>
            <div className='reg-content'>
                <div className='rs-form-container'>
                    <form name="credentials-form" autoComplete="on">
                        <div style={{ marginLeft: 10, marginRight: 10 }}>
                            <h1>
                                {T.translate("AccountSetupCredentials.labels.Protect")}
                            </h1>
                            <div style={{ marginLeft: 0, marginRight: 0, marginTop: 15, marginBottom: 20 }}>
                                <PasswordTextInput isForCreation value={password ? password.value : ''} onChange={handlePasswordChange} />
                            </div>
                            <div style={{ marginLeft: 0, marginRight: 0, marginTop: 18, marginBottom: 20 }}>
                                <div className="formLabelStyle">
                                    {T.translate("AccountSetupCredentials.labels.PleaseChooseSecQues")}
                                </div>
                                <SecurityQuestionDropDown name="question" lang={lang} value={questionIndex} onValueChanged={handleQuestionChange} />
                            </div>
                            <ValidatingTextInput maxLength={100} validationRequired={true} heap-ignore="true"
                                labelText={T.translate("AccountSetupCredentials.labels.Answer")}
                                placeholderText="" value={answer} autoComplete="off"
                                validationFailedText={T.translate("AccountSetupCredentials.labels.FieldCannotBeEmpty")}
                                validator={(text) => { return Boolean(text) }} onChange={handleAnswerChange} />
                            <ValidatingTextInput maxLength={100} validationRequired={true} heap-ignore="true"
                                renderErrorStyle={(showHintError && hintHasError(hint, answer)) || (showInvalidHintError && hintHasSpecialCharacters(hint))}
                                labelText={T.translate("AccountSetupCredentials.labels.Hint")}
                                placeholderText="" value={hint} autoComplete="off"
                                validationFailedText={T.translate(hint 
                                    ? showInvalidHintError ? "AccountSetupCredentials.labels.InvalidHint" : "AccountSetupCredentials.labels.HintCannotMatch" 
                                    : "AccountSetupCredentials.labels.FieldCannotBeEmpty")}
                                onChange={handleHintChange} />
                            <div className="horizontalCenterAlign" style={{ marginTop: '10px' }}>
                                <CheckBox isChecked={termsAccepted} onCheckChanged={handleTermsCheck} />
                                <span style={{ marginLeft: 10 }}>
                                    <span>{T.translate('AccountSetupLogin.labels.AcceptTerms1')}</span>
                                    <a href={T.translate('Common.TermsLink')} target="_blank" rel="noopener noreferrer"><b>{T.translate('AccountSetupLogin.labels.AcceptTerms2')}</b></a>
                                    <span>{T.translate('AccountSetupLogin.labels.AcceptTerms3')}</span>
                                </span>
                            </div>
                            <div className="consentCheckboxStyle">
                                <CheckBox isChecked={consentChecked} onCheckChanged={handleConsentCheck} />
                                <span style={{ marginLeft: 10 }}>
                                    <span>{T.translate('AccountSetupStart.labels.PrivacyPolicy', { PrivacyLink: privacyLink })}</span>
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='reg-continue'>
                <ArrowButton isEnabled={dataComplete} labelKey='Common.Continue' onClick={handleContinue} />
            </div>
        </div>

    )
}

export default CreateCredentials
