
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useUnauthorizedContext } from '../Dox/Shared/hooks/useUnauthorizedContextHook';
import ErrorPage from '../Error/errorPage';
import LoadingPage from '../Loading/loadingPage';
import virServices, { VirStatus } from './services/virServices';
import AuthActions from '../../reducers/authRedux';
import LayoutActions from '../../reducers/layoutRedux'
import WorkflowActions from '../../reducers/workflowRedux'


const AcceptVir = props => {
    const handleUnauthorized = useUnauthorizedContext();
    const [isError, setIsError] = useState(false);

    const [accepting, setIsAccepting] = useState(true);

    const accessToken = useSelector(state => state.auth.accessToken);
    const lang = useSelector(state => state.layout.lang);

    const langQuery = new URLSearchParams(window.location.search).get('lang');

    const match = useRouteMatch();
    const history = useHistory();
    
    const dispatch = useDispatch();

    useEffect(() => {
        if(langQuery && langQuery !== lang)
        {
            dispatch(LayoutActions.setDictLanguage(langQuery));
        }
    }, []);

    useEffect(() => {
        (async () => {await handleAccept()})();
    }, []);

    const handleAccept = async () => {
        setIsError(false);
        setIsAccepting(true);
        try {
            var virStatus = await virServices.getVirValid(handleUnauthorized, accessToken, match.params);
            if (virStatus === VirStatus.FailedId)
            {
                history.replace("/dashboard/confirm_id");
            }
            else
            {
                var status = await virServices.updateVirAccepted(handleUnauthorized, accessToken, langQuery, match.params);
                if (status) {
                    dispatch(AuthActions.finalizeLogin());
                    dispatch(WorkflowActions.updateCaseState())
                    history.replace("/dashboard");
                }
            }
        } catch (err) {
            setIsError(true);
        } finally {
            setIsAccepting(false);
        }
    }

    if (isError) {
        return (
        <ErrorPage
            titleKey="VIR.AcceptErrorTitle"
            subtitleKey="VIR.AcceptErrorSubTitle"
            tryAgainCallback={handleAccept}
            goBackCallback={() => setIsError(false)}            
            shouldShowTryAgain={true}
            shouldShowgoBack={false}
            loaded={props.loaded} />
        )
    }

    return (
        accepting
            ? <LoadingPage 
                messageKeys={accepting ? ["VIR.AcceptInProgress1", "VIR.AcceptInProgress2", "VIR.AcceptInProgress3"] : []}
                loaded={props.loaded} />
            : null
    );
}

export default AcceptVir;