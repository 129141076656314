import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    authorizeSelf: ['authType'],
    authorizeSpouse: ['authType'],
    authorizeSelfRQ: ['authType'],
    authorizeSpouseRQ: ['authType'],
    setAuthorizeStage: ['stage'],
    setAuthorizeConfig: ['isSpouse', 'craConfig', 'rqConfig'],
    authorizeReset: null,
    setAuthorizeCraLinkClicked: ['craLinkIsClicked'],
    completeCraStep: ['target'],
    completeRqStep: ['target'],
    setAuthorizeState: ['target', 'authState'],
    authorizeLogoutReset: null,
    loadAuthorizeState: null,
    loadAuthorizationConfig: ['coupled', 'primaryProvince', 'spouseProvince'],
    resetAuthorizeConfig: null,
    authorizeComplete: null
})

export const AuthorizeCRATypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    stage: 'init',
    craLinkIsClicked: false,
    authorizeStateSelf: {
        choice: null,
        choice_rq: null,
        cra_status: false,
        rq_status: false
    },
    authorizeStateSpouse: {
        choice: null,
        choice_rq: null,
        cra_status: false,
        rq_status: false
    },
    authorizeConfigSelf: {
        craConfig: {},
        rqConfig: {}
    },
    authorizeConfigSpouse: {
        craConfig: {},
        rqConfig: {}
    }
}

/* ------------- Reducers ------------- */

export const authorizeAuthorizeLogoutReset = (state) => INITIAL_STATE

export const authorizeReset = (state) => 
    Object.assign({}, INITIAL_STATE, { authorizeConfigSelf: state.authorizeConfigSelf, authorizeConfigSpouse: state.authorizeConfigSpouse })

export const authorizeSetSelfAuthChoice = (state, { authType }) => {
    var authSelf = {
        ...state.authorizeStateSelf,
        choice: authType,
        cra_status: false
    }

    return Object.assign({}, state, { authorizeStateSelf: authSelf })
}

export const authorizeSetSpouseAuthChoice = (state, { authType }) => {
    var authSpouse = {
        ...state.authorizeStateSpouse,
        choice: authType,
        cra_status: false
    }

    return Object.assign({}, state, { authorizeStateSpouse: authSpouse })
}

export const authorizeSetSelfRQAuthChoice = (state, { authType }) => {
    var authSelf = {
        ...state.authorizeStateSelf,
        choice_rq: authType,
        rq_status: false
    }

    return Object.assign({}, state, { authorizeStateSelf: authSelf })
}

export const authorizeSetSpouseRQAuthChoice = (state, { authType }) => {
    var authSpouse = {
        ...state.authorizeStateSpouse,
        choice_rq: authType,
        rq_status: false
    }

    return Object.assign({}, state, { authorizeStateSpouse: authSpouse })
}

export const authorizeCompleteCraStep = (state, { target }) => {
    if (target === 'spouse') {        
        var authSpouse = {
            ...state.authorizeStateSpouse,
            cra_status: true
        }

        return Object.assign({}, state, { authorizeStateSpouse: authSpouse })
    } else {
        var authSelf = {
            ...state.authorizeStateSelf,
            cra_status: true
        }

        return Object.assign({}, state, { authorizeStateSelf: authSelf })
    }
}

export const authorizeCompleteRqStep = (state, { target }) => {
    if (target === 'spouse') {
        var authSpouse = {
            ...state.authorizeStateSpouse,
            rq_status: true
        }

        return Object.assign({}, state, { authorizeStateSpouse: authSpouse })
    } else {
        var authSelf = {
            ...state.authorizeStateSelf,
            rq_status: true
        }

        return Object.assign({}, state, { authorizeStateSelf: authSelf })
    }
}

export const authorizeSetAuthorizeStage = (state, { stage }) =>
    Object.assign({}, state, { stage: stage })

export const authorizeSetAuthorizeConfig = (state, { isSpouse, craConfig, rqConfig }) => {
    if (isSpouse) {
        return Object.assign({}, state, { authorizeConfigSpouse: { craConfig: craConfig, rqConfig: rqConfig } })
    } else {
        return Object.assign({}, state, { authorizeConfigSelf: { craConfig: craConfig, rqConfig: rqConfig } })
    }
}

export const authorizeResetAuthorizeConfig = (state) =>
    Object.assign({}, state, {
        authorizeConfigSelf: {
            craConfig: {},
            rqConfig: {}
        },
        authorizeConfigSpouse: {
            craConfig: {},
            rqConfig: {}
        }
    });


export const authorizeSetCraLinkIsClicked = (state, { craLinkIsClicked }) =>
    Object.assign({}, state, { craLinkIsClicked: craLinkIsClicked })

export const authorizeSetAuthorizeState = (state, { target, authState }) => {
    if (target === 'self') {
        return Object.assign({}, state, { authorizeStateSelf: authState })
    } else if (target === 'spouse') {
        return Object.assign({}, state, { authorizeStateSpouse: authState })
    }
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.AUTHORIZE_SELF]: authorizeSetSelfAuthChoice,
    [Types.AUTHORIZE_SPOUSE]: authorizeSetSpouseAuthChoice,
    [Types.AUTHORIZE_SELF_RQ]: authorizeSetSelfRQAuthChoice,
    [Types.AUTHORIZE_SPOUSE_RQ]: authorizeSetSpouseRQAuthChoice,
    [Types.SET_AUTHORIZE_STAGE]: authorizeSetAuthorizeStage,
    [Types.SET_AUTHORIZE_CONFIG]: authorizeSetAuthorizeConfig,
    [Types.RESET_AUTHORIZE_CONFIG]: authorizeResetAuthorizeConfig,
    [Types.AUTHORIZE_RESET]: authorizeReset,
    [Types.SET_AUTHORIZE_CRA_LINK_CLICKED]: authorizeSetCraLinkIsClicked,
    [Types.COMPLETE_CRA_STEP]: authorizeCompleteCraStep,
    [Types.COMPLETE_RQ_STEP]: authorizeCompleteRqStep,
    [Types.SET_AUTHORIZE_STATE]: authorizeSetAuthorizeState,
    [Types.AUTHORIZE_LOGOUT_RESET]: authorizeAuthorizeLogoutReset
})

