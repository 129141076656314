export const ErrorTypes = {
    INVALID_FILE_TYPE: 'InvalidFileType',
    FILE_TOO_BIG: 'WrongSize',
    CANCELLED: 'Cancelled',
    UNKNOWN_ERROR: 'UnknownError',
    FILE_EXISTS: 'FileExists',
    FAIL_NO_SUCH_FILE: 'FailNoSushFile',
    FAIL_TRANSIENT: 'Fail_Transient'
}

export const CDMFileOperationStatus = {
    DOWNLOAD_COMPLETE: 'Download_Complete'
}