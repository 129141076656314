import apisauce from 'apisauce'
import { getApiBaseUrl } from './serviceUtils'

/**
 * API for retrieving user account info using apisauce
 * @param {String} baseURL
 */
const create = (baseURL = getApiBaseUrl()) => {  // this will change for prod deployment
    // Create and configure an apisauce-based api object.
    const api = apisauce.create({
        baseURL,
        headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Content-Type': 'application/json' },
        timeout: 20000
    })

    // Wrap api's addMonitor to allow the calling code to attach additional monitors in the future.
    // Only in __DEV__ and only if we've attached Reactotron to console (it isn't during unit tests).
    //if (__DEV__ && console.tron) {
    //  api.addMonitor(console.tron.apisauce)
    //}

    const getAuthHeader = (accessToken) => {
        return {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        }
    }

    const getQuestionAndHint = (accessToken, resetPasswordCode) => {
        var payload = {
            "PasswordCode": resetPasswordCode
        }
        return api.get('auth/getquestion', payload)
    }

    const sendResetPasswordEmail = (email, question_id, answer) => {
        var payload = {
            "Email": email,
            "Answer": answer,
            "QuestionId": question_id,
            "AuthToken": '',
            "NewPassword": ''
        }
        return api.post('auth/sendResetPasswordEmail', payload)
    }

    const resetPassword = (authToken, newPassword) => {
        var payload = {
            "Email": '',
            "Answer": '',
            "QuestionId": 0,
            "AuthToken": authToken,
            "NewPassword": newPassword
        }
        return api.post('auth/resetPassword', payload)
    }

    const resetVerifyAuthToken = (authToken) => {
        var payload = {
            "AuthToken": authToken
        }
        return api.post('auth/verifyAuthToken', payload)
    }
    const finalizeSignIn = (accessToken) => {
        var payload = ''
        return api.get('account/authinfo', payload, getAuthHeader(accessToken))
    }

    // Return back a collection of functions
    return {
        getQuestionAndHint, sendResetPasswordEmail, resetPassword, resetVerifyAuthToken, finalizeSignIn
    }
}

// let's return back our create method as the default.
export default {
    create
}

