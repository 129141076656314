import T from 'i18n-react';

class HelpCentre {
    constructor() {
        this.linkName = 'Common.HelpLinkBase'
    }

    configure = (linkConfig) => {
        this.linkConfig = linkConfig
    }

    openHelpLink = (linkId) => {
        if (linkId && !linkId.includes(".htm")) {
            linkId = linkId + '.htm'
        }
        if (this.linkConfig) {
            window.open(this.linkConfig +  linkId, '_blank')
        } else {
            window.open(T.translate(this.linkName) + linkId, '_blank')
        }
    };

}

export default new HelpCentre();