/* eslint-disable-next-line */
import classnames from 'classnames'
import { func, string } from 'prop-types'
import React, { useState, useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TranslationContextProvider } from '../Shared/hooks/useTranslationContextHook'
import { UnauthorizedContextProvider } from '../Shared/hooks/useUnauthorizedContextHook'
import DoxFileSelector from "./components/doxFileSelector"
import DoxUploadList from "./components/doxUploadList"
import './dox-uploader.css'
import { useContext } from 'react'
import doxExplorerCDMServices from '../Shared/services/doxExplorerCDMServices'
import DoxButton from '../Shared/components/DoxButton'
import useUploadQueue from './hooks/useUploadQueueHook'
import LayoutActions from '../../../reducers/layoutRedux';
import DoxExplorer from '../Explorer/doxExplorer';
import T from 'i18n-react';
import AuthActions from '../../../reducers/authRedux';
import DocumentsActions from '../../../reducers/documentsRedux';
import DoxSuggestionPanel from './components/DoxSuggestionPanel';
import { HeapEvents, trackEvent } from '../../../utils/AnalyticsUtil'

export const DoxUploaderContext = React.createContext()

export const useDoxUploaderContext = () => useContext(DoxUploaderContext)

const DoxUploader = props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { layoutType, taxYear, comingFrom, onTranslateString, onHandleUnauthorizedUser, accessToken, onUploadsContinue, source } = props
    // file selector
    const [showingFileSelector, setShowingFileSelector] = useState(true)
    const [selectedFiles, setSelectedFiles] = useState([])

    // uploader
    const [existingFiles, setExistingFiles] = useState(null)
    // handle the concurrent queue
    const { fileQueue, onUploadCompleted, onRetryUpload, areAllUploadsResolved, didAnyUploadsSucceed } = useUploadQueue(selectedFiles)

    //Get tax year state from redux store
    const currentTaxYear = useSelector(state => state.workflow && state.workflow.case_state && state.workflow.case_state.taxYears
        ? state.workflow.case_state.taxYears.find((x => x.taxYear.toString() === taxYear)) : [], shallowEqual);

    const documentUploadStepStatus = currentTaxYear?.workflow_state?.upload_docs?.includes("Confirm") ? 'InProgress' : 'NotStarted';
    const isDocumentWorkflowTaskComplete = currentTaxYear?.workflow_state?.upload_docs === "Complete";
    const backLabel = documentUploadStepStatus === 'NotStarted' ? comingFrom === 'documents'
        ? 'Dox.BackToDocs' : 'Common.ToDashboard' : 'Dox.DocumentUploader.CTAs.WorkflowStepState.InProgress.Secondary';


    const loadExistingFiles = async () => {
        let files = []
        try {
            var { status: st, fileList: fl } =
                await doxExplorerCDMServices.getFileList(onHandleUnauthorizedUser, accessToken, taxYear)
            if (st) {
                files = fl
            }
        } catch (ex) {
            console.log(ex)
        } finally {
            setExistingFiles(files)
        }
    }

    /**
     * handler that fires after files have been selected, either by D&D or file input.
     * @param {File[]} files
     */
    const handleFilesSelectedForUpload = files => {
        setSelectedFiles(files)
        setShowingFileSelector(false)
    }

    const handleNextClicked = () => {
        setShowingFileSelector(true);
        setExistingFiles(null)
        setSelectedFiles([])
        loadExistingFiles()
    }

    const handleDoneClicked = e => {
        dispatch(DocumentsActions.setUploadStepCompleted(taxYear, true));
        dispatch(LayoutActions.setDashboardTab('dashboard'));
        trackEvent(HeapEvents.DOCS_BTN_READY);
        history.push('/dashboard')
    }

    /* on load, fetch pre-existing tax year files (used for file duplicate detection) */
    useEffect(() => {
        loadExistingFiles()
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (didAnyUploadsSucceed) {
            onUploadsContinue();
        }
    }, [didAnyUploadsSucceed])

    const value = {
        taxYear,
        accessToken,
        fileQueue,
        existingFiles,
        onUploadCompleted,
        onRetryUpload
    }

    const translateString = (key, params) => {
        return T.translate(key, params)
    }

    const handleUnauthorizedRequest = (err) => {
        window.sessionStorage.setItem('ote.logouttype', 'error')
        dispatch(AuthActions.logoutUser())
    }

    const backToSourceOnClick = () => {
        // As per design "Add More Later" CTA comes when document upload task is in progress
        if (documentUploadStepStatus === 'InProgress') { trackEvent(HeapEvents.DOCS_BTN_ADD_LATER); }
        
        // Head on back to the dashboard or docs tab keeping status Continue
        if (source) {
            dispatch(LayoutActions.setDashboardTab(source))
        }
        history.push('/dashboard')
    }

    return (
        <UnauthorizedContextProvider onHandleUnauthorizedUser={onHandleUnauthorizedUser}>
            <TranslationContextProvider onTranslateString={onTranslateString}>
                {showingFileSelector && <div className='dox-title no-border'>
                    <h1 className='docUploaderTitle'>{onTranslateString("Dox.DocumentUploader.Title", { TaxYear: taxYear })}</h1>
                    {!isDocumentWorkflowTaskComplete &&
                        <p className='docUploaderSubTitle'>{onTranslateString('Dox.DocumentUploader.SubTitle.Content')}</p>
                    }
                </div>}
                {showingFileSelector && !isDocumentWorkflowTaskComplete &&
                    <DoxSuggestionPanel layoutType={layoutType} taxYear={taxYear} onTranslateString={translateString} />
                }
                {!showingFileSelector && <div className='dox-title no-border'>
                    <h1>{onTranslateString("Dox.DocumentUploader.UploadInProgress", { TaxYear: taxYear })}</h1>
                </div>}
                <DoxUploaderContext.Provider value={value}>
                    <div className='dox-content doxCombined'>
                        {showingFileSelector &&
                            <DoxFileSelector layoutType={layoutType} onFilesSelected={handleFilesSelectedForUpload} />
                        }
                        {!showingFileSelector &&
                            <DoxUploadList layoutType={layoutType} />
                        }
                        {showingFileSelector &&
                            <DoxExplorer onTranslateString={translateString} onUnauthorizedRequest={handleUnauthorizedRequest}
                                isDoxCombined={true} loadExistingFiles={loadExistingFiles} />
                        }
                    </div>
                </DoxUploaderContext.Provider>
                {showingFileSelector && !isDocumentWorkflowTaskComplete && <div className='dox-upload-footer hrb-columns'>
                    <div className='hrb-column hrb-is-6 footerMessageBlock'>
                        <div className={classnames('hrb-column',
                            { 'hrb-is-12': ['desktop', 'tablet'].includes(layoutType) },
                            { 'hrb-is-2 justifyContentCenter': !['desktop', 'tablet'].includes(layoutType) },
                        )}>
                            <span className='footerMessageTitle'>
                                <b>{onTranslateString('Dox.DocumentUploader.FooterInfoMessage.WorkflowStepState.' + documentUploadStepStatus + '.Title')}</b>
                            </span>
                        </div>
                        <div className={classnames('hrb-column',
                            { 'hrb-is-12': ['desktop', 'tablet'].includes(layoutType) },
                            { 'hrb-is-2 justifyContentCenter': !['desktop', 'tablet'].includes(layoutType) },
                        )}>
                            <span className='footerMessageSubTitle'>
                                {onTranslateString('Dox.DocumentUploader.FooterInfoMessage.WorkflowStepState.' + documentUploadStepStatus + '.SubTitle')}
                            </span>
                        </div>
                    </div>
                    <div className='hrb-column hrb-is-6 footerCTABlock'>
                        <div className={classnames('hrb-column footerCTASecondary',
                            { 'hrb-is-6': ['desktop', 'tablet'].includes(layoutType) },
                            { 'hrb-is-2 justifyContentCenter': !['desktop', 'tablet'].includes(layoutType) },
                        )}>
                            <DoxButton
                                elementId=''
                                buttonStyles='secondary stretch'
                                onClick={() => backToSourceOnClick()}>
                                {onTranslateString(backLabel)}
                            </DoxButton>
                        </div>
                        <div className={classnames('hrb-column footerCTAPrimary',
                            { 'hrb-is-6': ['desktop', 'tablet'].includes(layoutType) },
                            { 'hrb-is-2 justifyContentCenter': !['desktop', 'tablet'].includes(layoutType) },
                        )}>
                            <DoxButton
                                elementId='next-confirm-uploads'
                                buttonStyles='primary stretch'
                                onClick={() => handleDoneClicked()}>
                                {onTranslateString('Dox.DocumentUploader.CTAs.WorkflowStepState.' + documentUploadStepStatus + '.Primary')}
                            </DoxButton>
                        </div>
                    </div>
                </div>}

                {!showingFileSelector && <div className='dox-upload-footer hrb-columns uploadInProgress'>
                    <div className={classnames('hrb-column',
                        { 'hrb-is-2': ['desktop', 'tablet'].includes(layoutType) },
                        { 'hrb-is-6': !['desktop', 'tablet'].includes(layoutType) },
                    )}>
                        <DoxButton
                            elementId='next-confirm-uploads'
                            buttonStyles='primary stretch'
                            disabled={!areAllUploadsResolved}
                            onClick={() => handleNextClicked()}>
                            {onTranslateString('Dox.DocumentUploader.CTAs.Next')}
                        </DoxButton>
                    </div>
                </div>}
            </TranslationContextProvider>
        </UnauthorizedContextProvider>
    )
}

DoxUploader.propTypes = {
    layoutType: string.isRequired,
    taxYear: string.isRequired,
    onTranslateString: func.isRequired,
    onHandleUnauthorizedUser: func.isRequired,
    onUploadsContinue: func.isRequired,
    accessToken: string,
    comingFrom: string
}

export default DoxUploader
