import api from '../../../services/Api'

const ApiPaths = {
    VIR: 'vir'
};

const VirStatus = {
    Loading: "Loading",
    Valid: "Valid",
    Invalid: "Invalid",
    InvalidRegister: "InvalidRegister",
    FailedId: "FailedId",
}

/* Service class used to perform api calls for messaging */
class VirServices {

    /**
    * Is the VIR params valid
    * @param {Function} handleUnauthorized
    * @param {string} accessToken
    * @param {string} params
    */
    async getVirValid(handleUnauthorized, accessToken, params) {
        let response = await new api([], handleUnauthorized).get(accessToken, `${ApiPaths.VIR}?taxYear=${params.taxYear}&vmId=${params.vmId}`)
        if (response && response.status === 200) {
            if (response.data) {
                return VirStatus.Valid;
            } else {
                return VirStatus.FailedId;
            }
        } else if (response && response.status === 400) {
            return VirStatus.Invalid;
        }

        return VirStatus.InvalidRegister;
    }

    /**
    * Accept the VIR
    * @param {Function} handleUnauthorized
    * @param {string} accessToken
    * @param {string} lang
    * @param {string} params
    */
    async updateVirAccepted(handleUnauthorized, accessToken, lang, params) {
        let response = await new api([], handleUnauthorized).put(accessToken, `${ApiPaths.VIR}?taxYear=${params.taxYear}&vmId=${params.vmId}&lang=${lang}`)
        if (response && response.status === 200) {
            return true;
        } else if (response && response.status === 400) {
            console.log(response.data)
            throw new Error(response.data);
        } else if (response && response.status === 500) {
            console.log(response.data)
            throw new Error(response.data);
        }

        return false;
    }
}

const virServices = new VirServices();
export default virServices;
export { VirStatus };