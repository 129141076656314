import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    approveReset: null,
    setApprovalStage: ['stage'],
    setApprovalComplete: ['taxYear']
})

export const ApproveDraftsTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    stage: 'approveSelf'
}

/* ------------- Reducers ------------- */

export const reviewReset = (state) => INITIAL_STATE

export const reviewApprovePrimary = (state, { isquebec }) =>
    Object.assign({}, state, {
        primaryFederalApproved: true,
        primaryQuebecApproved: isquebec,
    })

export const reviewApproveSpouse = (state, { isquebec }) =>
    Object.assign({}, state, {
        primaryFederalApproved: true,
        primaryQuebecApproved: isquebec,
    })

export const reviewSetApprovalStage = (state, { stage }) =>
    Object.assign({}, state, { stage: stage })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.APPROVE_RESET]: reviewReset,
    [Types.SET_APPROVAL_STAGE]: reviewSetApprovalStage,
})