import apisauce from 'apisauce'
import { addErrorHandler, getApiBaseUrl } from './serviceUtils'

/**
 * API for setting and retrieving interview data
 * @param {String} baseURL
 */
const create = (baseURL = getApiBaseUrl()) => {  // this will change for prod deployment
    // Create and configure an apisauce-based api object.
    const api = apisauce.create({
        baseURL,
        headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Content-Type': 'application/json' },
        timeout: 120000
    })

    addErrorHandler(api)

    const getAuthHeader = (accessToken) => {
        return {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        }
    }

    const loadInterview = (accessToken, taxYear) => {
        var payload = ''
        return api.get('interview?taxYear=' + taxYear, payload, getAuthHeader(accessToken))
    }

    const saveInterview = (accessToken, taxYear, interview, isComplete, page) => {
        var payload = {
            taxYear: taxYear,
            currentPage: page,
            interview: JSON.stringify(interview)
        }
        var url = 'interview'
        if (isComplete != null) {
            url += '?isComplete=' + (isComplete ? 'true' : 'false')
        }
        return api.post(url, payload, getAuthHeader(accessToken))
    }

   
    // Return back a collection of functions
    return {
        loadInterview, saveInterview
    }
}

// let's return back our create method as the default.
export default {
    create
}