import React from 'react';
import T from 'i18n-react';
import './acc.css'
import FontAwesome from 'react-fontawesome';

const AccordianBanner = ({ labelKey, isOpen, toggleOpen }) => {
    return (
        <div className='acc-banner' onClick={toggleOpen}>
            <div className='acc-banner-text'>
                {T.translate(labelKey)}
            </div>
            <div className='acc-toggle'>
                <FontAwesome className={isOpen ? 'fa-angle-up' : 'fa-angle-down'} name={isOpen ? 'fa-angle-up' : 'fa-angle-down'} size='2x' />
            </div>
        </div>
    )
}

export default AccordianBanner
