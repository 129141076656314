import React from 'react'
import PropTypes from 'prop-types'
import '../dox_explorer.css'
import DoxExplorerFilesTableColumnHeader from './doxExplorerFilesTableColumnHeader'

const DoxExplorerFilesTableHeader = (props) => {

    const handleSortChange = (columnId) => {
        let isCurrentSort = columnId === props.sortColumn
            || (!props.sortColumn && props.tableConfig.find(tc => tc.columnId === columnId).isDefaultSort)
        props.onSort(columnId, isCurrentSort ? !props.sortDirection : false)
    }

    return (
        <div className='doxe-files-table-row doxe-header-row hrb-columns'>
            {props.tableConfig.map(tc =>
                <DoxExplorerFilesTableColumnHeader key={`doxe-col-${tc.columnId}`} labelKey={tc.labelKey} columnId={tc.columnId}
                    isSelected={(props.sortColumn === tc.columnId) || (!props.sortColumn && tc.isDefaultSort)}
                    isReversed={props.sortDirection} width={tc.width || 2} isStatic={!tc.isSortable}
                    onToggle={handleSortChange} />
            )}
        </div>
    )
}

DoxExplorerFilesTableHeader.propTypes = {
    tableConfig: PropTypes.arrayOf(PropTypes.shape({
        columnId: PropTypes.string.isRequired,
        labelKey: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        isDefaultSort: PropTypes.bool.isRequired,
        isSortable: PropTypes.bool.isRequired
    })),
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.bool,
    onSort: PropTypes.func.isRequired
}   

export default DoxExplorerFilesTableHeader;