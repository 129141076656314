import React, { Component } from 'react';
import T from 'i18n-react';
import './style.css';
import LayoutActions from '../../reducers/layoutRedux'
import MessagesActions from '../../reducers/messagesRedux'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'

class SendUsMessage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            linkId: this.props.linkId,
            baseClassName: this.props.baseClassName,
            style: this.props.style,
            message: this.props.message,
            onClick: this.props.onClick
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.linkId !== newProps.linkId) {
            this.setState({ linkId: newProps.linkId })
        }
        if (this.state.baseClassName !== newProps.baseClassName) {
            this.setState({ baseClassName: newProps.baseClassName })
        }
        if (this.state.onClick !== newProps.onClick) {
            this.setState({ onClick: newProps.onClick })
        }

    }

    handleClick(ev) {
        this.props.setInputScreenShowing(true)
        this.props.setMessageInProgress(this.state.message)
        this.state.onClick(ev)
        this.props.setDashboardTab('messages')
        this.props.pushNavigation('/dashboard')
    }

    render() {
        var classNames = []
        if (this.state.baseClassName) {
            classNames.push(this.state.baseClassName)
        }
        if (this.state.linkId) {
            classNames.push('sendUsMessageStyle')
            classNames.push('sendUsMessageDefaultMargin')
        } else {
            classNames.push('hidden')
        }

        var spanClassNames = []
        if (this.state.baseClassName) {
            spanClassNames.push(this.state.baseClassName)
        }
        spanClassNames.push('unselectableLabel')
        return (
            <span className={classNames.join(' ')} onClick={(ev) => this.handleClick(ev)}>
                <span className={spanClassNames.join(' ')} style={this.props.style}>{T.translate(`Messages.Labels.MessageTaxPro.${this.props.isDirectUser ? 'direct' : 'ote'}`)}</span>
            </span>
        )
    }
}

const mapStateToProps = state => ({
    lang: state.layout.lang,
    isDirectUser: state.workflow.is_direct_user
});

const mapDispatchToProps = (dispatch) => ({
    setInputScreenShowing: (showing) => dispatch(MessagesActions.setInputScreenShowing(showing)),
    setDashboardTab: (tab) => dispatch(LayoutActions.setDashboardTab(tab)),
    pushNavigation: (path) => dispatch(push(path)),
    setMessageInProgress: (messageInProgress) => dispatch(MessagesActions.setMessageInProgress(messageInProgress)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SendUsMessage);