import React, { Component } from 'react';
import '../Common/shared.css'
import './style.css';
import T from 'i18n-react';
import PlainButton from '../Controls/plainButton'
import classnames from 'classnames'

class LogoutCTA extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showSignoutDialog: this.props.showSignoutDialog,
        }
    }

    cancelSignOut() {
        this.setState({ showSignoutDialog: false })
    }

    onCloseDialog(ev) {
        if (this.props.closeSignOutDialog) {
            this.props.closeSignOutDialog(ev);
        }
    }

    onConfirmSignOut(ev) {
        if (this.props.proceedSignOut) {
            this.props.proceedSignOut(ev);
        }
    }
    render() {
        return (
            <div className={classnames('defaultStyles', { 'mobile': this.props.layoutType === 'mobile' })}>
                <div className="bodyContainer" >                    
                    <div className="dialogContentContainer leftSpacing rightSpacing leftContentAlign">
                        <span className="signOutTitle">
                            {T.translate("Logout.labels.SignOut")}
                        </span>
                        <div style={{ marginTop: '20px', marginBottom: 30 }}>
                            <span>{T.translate("Logout.labels.ConfirmSignOut1")}</span>
                            <div style={{ marginTop: '20px' }} />
                            <span>{T.translate("Logout.labels.ConfirmSignOut2")}</span>
                        </div>
                    </div>
                </div>
                <div className="inactivityButtonContainer">
                    <PlainButton inverted onClick={(ev) => { this.onCloseDialog(ev) }} 
                        labelKey='Logout.labels.Cancel' />
                    <div style={{ width: '8px', height: '8px' }} />
                    <PlainButton onClick={(ev) => this.onConfirmSignOut(ev)} 
                        labelKey="Logout.labels.SignOut" />
                </div>
            </div>
        )
    }
}

export default LogoutCTA;