/* eslint-disable-next-line */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useUnauthorizedContext } from '../../Shared/hooks/useUnauthorizedContextHook';
import { ErrorTypes } from '../../Shared/resources/Enums';
import doxExplorerCDMServices from '../../Shared/services/doxExplorerCDMServices';
import doxUploaderCDMService from '../../Shared/services/doxUploaderCDMService';
import { getRandomHexString } from '../../Shared/services/Util';
import { useDoxUploaderContext } from '../doxUploader';
import useDuplicateDetection from './useDuplicateDetectionHook';

/**
 * Hook to handle the uploading of a file for a given tax year.
 * @param {any} fileUpload
 * @param {any} taxYear
 */
export const useFileUpload = (fileUpload, cancel, layoutType) => {
    const CancelToken = axios.CancelToken;
    const isReadyToUpload = Boolean((fileUpload || {}).isReadyToUpload)

    const errorsWithMessaging = [ErrorTypes.FILE_TOO_BIG, ErrorTypes.INVALID_FILE_TYPE, ErrorTypes.UNKNOWN_ERROR]

    const handleUnauthorized = useUnauthorizedContext()
    const { accessToken, taxYear, existingFiles, onUploadCompleted, onRetryUpload } = useDoxUploaderContext()
    const { hasCheckedForDupes, duplicateFileId, suffixedFileName } = useDuplicateDetection(fileUpload.file, existingFiles)

    // if this is null, the file will be saved with its original filename.
    const [renameFileAs, setRenameFileAs] = useState(null)
    const [hasResolvedConflicts, setHasResolvedConflicts] = useState(false)

    const [percentUploaded, setPercentUploaded] = useState(0)
    const [isUploadComplete, setIsUploadComplete] = useState(false)
    const [uploadError, setUploadError] = useState(null)

    const [cancelSource, setCancelSource] = useState(null)
    const [cancelUpload, setCancelUpload] = useState(false)

    // handlers
    const handleCancelClick = () => {
        setCancelUpload(true)
    }

    const handleRetryClick = () => {
        setUploadError(null)
        setHasResolvedConflicts(false)

        // handle requeueing
        if (onRetryUpload) {
            resetUpload()
            setTimeout(() => {
                onRetryUpload(fileUpload.qPosition)
                setHasResolvedConflicts(true)
            }, 150)
        }
    }

    const handleKeepBothClick = () => {
        setUploadError(null)
        setRenameFileAs(suffixedFileName)
        setHasResolvedConflicts(true)
    }

    const handleReplaceClick = async () => {
        setUploadError(null)
        setHasResolvedConflicts(false)

        // handle requeueing
        if (onRetryUpload) {
            resetUpload()
            onRetryUpload(fileUpload.qPosition)
        }

        setHasResolvedConflicts(true)
    }

    const checkIfAppleCameraUpload = () => {
        if (fileUpload.file && fileUpload.file.name === 'image.jpg' && (layoutType === 'mobile' || layoutType === 'tablet')) {
            setRenameFileAs(`image(${getRandomHexString(4)}).jpg`)
        }
    }

    const resetUpload = () => {
        setPercentUploaded(0)
        setIsUploadComplete(false)
        setUploadError(null)
        setCancelUpload(false)
        setCancelSource(null)
        setHasResolvedConflicts(false)
    }

    const onUploadProgress = e => {
        if (uploadError && uploadError === ErrorTypes.CANCELLED) {
            return // bail! we've cancelled the upload, stop processing these.
        }
        if (e.type === 'progress' && e.lengthComputable) {
            let percentage = (100 * e.loaded) / e.total
            setPercentUploaded(percentage)
        }
    }

    useEffect(() => {
        // cancel the upload when the user clicks cancel
        if (cancelUpload) {
            if (cancelSource) {
                // upload is underway already (and has not already failed)
                cancelSource.cancel(ErrorTypes.CANCELLED);
            } 
            if (uploadError) {
                // the failed upload exception has already been caught
                // so set the state values it would normally have set there.
                setUploadError(ErrorTypes.CANCELLED)
                onUploadCompleted(fileUpload.qPosition, ErrorTypes.CANCELLED)
            }
        }
    }, [cancelUpload, cancelSource])

    useEffect(() => {
        // this will handle external cancellations from the parent component
        if (cancel !== cancelUpload) {
            setCancelUpload(cancel)
        }
    }, [cancel])

    useEffect(() => {
        if (hasCheckedForDupes) {
            if (duplicateFileId) {
                setUploadError(ErrorTypes.FILE_EXISTS)
            } else {
                // note to future me/dev meddling in this code:
                // When choosing 'camera' for uploads in iOS, the filename will always
                // be 'image.jpg'. We need unique names, APPLE. Get with the program.
                checkIfAppleCameraUpload()
                // okay, NOW we're ready to go
                setHasResolvedConflicts(true)
            }
        }
    }, [hasCheckedForDupes])

    useEffect(() => {
        if (isUploadComplete && onUploadCompleted) {
            // notify the queueing mechanism that this upload is finished
            // so it can start a new one, if needed.
            onUploadCompleted(fileUpload.qPosition, uploadError)
        }
    }, [isUploadComplete])

    useEffect(() => {
        async function uploadFile(file) {
            try {
                // cancellation token
                let source = CancelToken.source()
                setCancelSource(source)

                // perform the upload
                let response = await doxUploaderCDMService
                    .uploadFile(handleUnauthorized, accessToken, taxYear, file, onUploadProgress, source.token, renameFileAs)

                // On error, inspect the inner exception, as it
                // holds allll the secretzzz. otherwise, assume success.
                if (response && response.status === 500) {
                    setUploadError(ErrorTypes.UNKNOWN_ERROR);
                } else if (response && response.status === 400) {
                    let expectedError = errorsWithMessaging.includes(response.data.errorCode)
                    if(expectedError){
                        setUploadError(response.data.errorCode);
                    }
                    else if(!expectedError){
                        let errorBody = response.data;
                        let firstError = errorBody.errors[""][0];
                        if(firstError.includes("length limit")) {
                            setUploadError(ErrorTypes.FILE_TOO_BIG)
                        }
                    }
                    else
                    {
                        setUploadError(ErrorTypes.UNKNOWN_ERROR)
                    }
                } else if (response && response.status !== 201) {
                    // unexpected error (e.g. 502)
                    setUploadError(ErrorTypes.UNKNOWN_ERROR)
                } else if (duplicateFileId && (renameFileAs === null || renameFileAs === '')) {
                    // delete file
                    await doxExplorerCDMServices.deleteFile(handleUnauthorized, accessToken, duplicateFileId)
                }
            } catch (ex) {
                if (axios.isCancel(ex)) {
                    setUploadError(ErrorTypes.CANCELLED)
                } else {
                    setUploadError(ErrorTypes.UNKNOWN_ERROR)
                }
            } finally {
                // update this here regardless, as even on error, we want to mark it as complete
                setIsUploadComplete(true)
            }
        }

        if (isReadyToUpload && !isUploadComplete && hasResolvedConflicts) {
            uploadFile(fileUpload.file)
        }
    }, [isReadyToUpload, hasResolvedConflicts])

    return {
        percentUploaded,
        isUploadComplete,
        uploadError,
        isDuplicate: !!duplicateFileId,
        renameFileAs,
        hasResolvedConflicts,
        handleCancelClick,
        handleRetryClick,
        handleKeepBothClick,
        handleReplaceClick
    }
}