import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    setInterview: ['interview', 'taxYear', 'isLoaded'],
    setLoadedInterview: ['interview', 'taxYear', 'isLoaded', 'page', 'postalCode'],
    setInterviewPage: ['taxYear', 'page', 'isComplete', 'additionalYear'],
    resetInterview: null,
    setDependantIndex: ['taxYear', 'dependantIndex'],
    setMissingRequiredField: ['taxYear', 'missingRequiredField'],
    setMissingRequiredSexField: ['taxYear', 'missingRequiredSexField'],
    loadInterview: ['taxYear'],
    setDefaultInterview: ['taxYear', 'isLoaded', 'defaultPostalCode']
})

export const InterviewTypes = Types
export default Creators

export const InterviewTemplate = {
    taxYear: null,
    interview: {
        complex: {
            t2checked: false,
            usTaxChecked: false,
            bankruptcyChecked: false,
            agriChecked: false,
            nonResidentChecked: false,
            selfEmploymentChecked: false,
            under18Checked: false
        },
        primary: {
            firstName: '',
            middleName: '',
            lastName: '',
            gender: '',
            dob: '',
            sin: '',
            nameChange: '',
            aptNumber: '',
            streetNumber: '',
            streetName: '',
            poBox: '',
            ruralRouteNumber: '',
            city: '',
            province: '',
            postalCode: '',
            inCareOf: '',
            telephone: '',
            mailingAddressChange: '',
            residenceProvince: '',
            nisgaLands: '',
            taichoLands: '',
            yukonLands: '',
            nflLands: '',
            provinceTerritoryChange: '',
            homeAndMailingSame: '',
            currentResidenceProvince: '',
            immigrateCanada: '',
            canadianCitizenship: '',
            authorizeCraElectionsCanada: '',
            maritalStatus: '',
            maritalStatusChange: '',
            previousMaritalStatus: '',
            maritalStatusChangeDate: '',
            spouseLiveApart: '',
            spouseTaxes: '',
            dependants: ''
        },
        spouse: {
            firstName: '',
            middleName: '',
            lastName: '',
            gender: '',
            dob: '',
            sin: '',
            nameChange: '',
            aptNumber: '',
            streetNumber: '',
            streetName: '',
            poBox: '',
            ruralRouteNumber: '',
            city: '',
            province: '',
            postalCode: '',
            inCareOf: '',
            telephone: '',
            mailingAddressSameAsPrimary: '',
            homeAndMailingSame: '',
            mailingAddressChange: '',
            residenceProvince: '',
            nisgaLands: '',
            taichoLands: '',
            yukonLands: '',
            nflLands: '',
            provinceTerritoryChange: '',
            currentResidenceProvince: '',
            immigrateCanada: '',
            canadianCitizenship: '',
            authorizeCraElectionsCanada: ''
        },
        dependants: [
            {
                firstName: '',
                lastName: '',
                dob: '',
                gender: '',
                relationship: '',
                livesWith: '',
                infirm: '',
                hasIncome: ''
            }
        ],
        taxYear: {
            employedChecked: false,
            selfEmployedChecked: false,
            retiredChecked: false,
            businessOwnerChecked: false,
            workExpensesChecked: false,
            disabilityChecked: false,
            medicalExpensesChecked: false,
            benefitsEIChecked: false,
            investmentIncomeChecked: false,
            rRSPChecked: false,
            rentalChecked: false,
            foreignIncomeChecked: false,
            dependentsChecked: false,
            propertySaleChecked: false,
            spouseSupportChecked: false,
            movingExpensesChecked: false,
            tuitionChecked: false,
            donationsChecked: false,
            fireRescueChecked: false,
            farmingChecked: false
        },
        spouseTaxYear: {
            employedChecked: false,
            selfEmployedChecked: false,
            retiredChecked: false,
            businessOwnerChecked: false,
            workExpensesChecked: false,
            disabilityChecked: false,
            medicalExpensesChecked: false,
            benefitsEIChecked: false,
            investmentIncomeChecked: false,
            rRSPChecked: false,
            rentalChecked: false,
            foreignIncomeChecked: false,
            dependentsChecked: false,
            propertySaleChecked: false,
            spouseSupportChecked: false,
            movingExpensesChecked: false,
            tuitionChecked: false,
            donationsChecked: false,
            fireRescueChecked: false,
            farmingChecked: false
        },
        notes: ''
    },
    isLoaded: false,
    isDefault: true,
    page: '1',
    missingRequiredField: false,
    missingRequiredSexField: false,
    dependantIndex: 0,
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
}

/* ------------- Reducers ------------- */
export const interviewSetDefaultInterview = (state, { taxYear, isLoaded, defaultPostalCode }) => {

    var interview = interviewCloneInterview(InterviewTemplate.interview)
    interview.primary.postalCode = defaultPostalCode
    var mergeObj = {
        [taxYear]: {
            ...InterviewTemplate,
            interview: interview,
            taxYear: taxYear,
            isLoaded: isLoaded,
            page: '1'
        }
    }
    return Object.assign({}, state, mergeObj)
}

export const interviewSetPage = (state, { taxYear, page, additionalYear }) => {

    let taxYearInterview = state[taxYear] || InterviewTemplate

    var mergeObj = {
        [taxYear]: {
            ...taxYearInterview,
            interview: interviewCloneInterview(taxYearInterview.interview),
            page: page
        }
    }
    return Object.assign({}, state, mergeObj)
}

export const interviewCloneInterview = (interview) => {
    // IMPORTANT: This only clones the nested 'interview' object, not the entire taxYear model.
    var deps = []
    if (interview.dependants && Array.isArray(interview.dependants)) {
        deps = interview.dependants.slice()
    } else {
        deps = []
    }
    var i = {
        ...interview,
        complex: { ...interview.complex },
        primary: { ...interview.primary },
        spouse: { ...interview.spouse },
        dependants: deps,
        taxYear: { ...interview.taxYear },
        spouseTaxYear: { ...interview.spouseTaxYear }
    }
    return i
}

export const interviewSetInterview = (state, { interview, taxYear, isLoaded, page, postalCode }) => {

    let taxYearInterview = state[taxYear] || InterviewTemplate

    var mergeObj = {
        [taxYear]: {
            ...taxYearInterview,
            interview: interviewCloneInterview(interview),
            taxYear: taxYear,
            isLoaded: isLoaded,
            isDefault: interview === InterviewTemplate.interview,
            page: page || taxYearInterview.page,
            postalCode: postalCode
        }
    }
    return Object.assign({}, state, mergeObj)
}

export const interviewResetInterview = () => INITIAL_STATE

export const interviewSetDependantIndex = (state, { taxYear, dependantIndex }) => {

    let taxYearInterview = state[taxYear] || InterviewTemplate

    var mergeObj = {
        [taxYear]: {
            ...taxYearInterview,
            interview: interviewCloneInterview(taxYearInterview.interview),
            dependantIndex: dependantIndex
        }
    }
    return Object.assign({}, state, mergeObj)
}   

export const interviewSetMissingRequiredField = (state, { taxYear, missingRequiredField }) => {

    let taxYearInterview = state[taxYear] || InterviewTemplate

    var mergeObj = {
        [taxYear]: {
            ...taxYearInterview,
            interview: interviewCloneInterview(taxYearInterview.interview),
            missingRequiredField: missingRequiredField
        }
    }
    return Object.assign({}, state, mergeObj)
}

export const interviewSetMissingRequiredSexField = (state, { taxYear, missingRequiredSexField }) => {

    let taxYearInterview = state[taxYear] || InterviewTemplate

    var mergeObj = {
        [taxYear]: {
            ...taxYearInterview,
            interview: interviewCloneInterview(taxYearInterview.interview),
            missingRequiredSexField: missingRequiredSexField
        }
    }
    return Object.assign({}, state, mergeObj)
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_INTERVIEW_PAGE]: interviewSetPage,
    [Types.SET_DEFAULT_INTERVIEW]: interviewSetDefaultInterview,
    [Types.SET_INTERVIEW]: interviewSetInterview,
    [Types.SET_LOADED_INTERVIEW]: interviewSetInterview,
    [Types.RESET_INTERVIEW]: interviewResetInterview,
    [Types.SET_DEPENDANT_INDEX]: interviewSetDependantIndex,
    [Types.SET_MISSING_REQUIRED_FIELD]: interviewSetMissingRequiredField,
    [Types.SET_MISSING_REQUIRED_SEX_FIELD]: interviewSetMissingRequiredSexField
})
