import React from 'react';
import PropTypes from 'prop-types'
import WorkflowSection from '../Section/WorkflowSection'
import '../Section/section.css'
import T from 'i18n-react';
import { useTicketType } from '../../../hooks/useTicketTypeHook'
import WorkflowButtonMaterial from '../Section/WorkflowButtonMaterial';


const PaymentReturnSection = ({ lang, taxState, currentYear, paymentInfo, onWorkflowButtonAction }) => {

    var primaryFirstName = taxState.primary_firstName;
    var spouseFirstName = taxState.coupled ? taxState.spouse_firstName : "";
    const { ticketType } = useTicketType(taxState);

    return (
        <WorkflowSection titleKey='WorkflowPanel.payment.title' isAlternate={(currentYear - taxState.taxYear) % 2 === 1}
            ticketType={taxState.ticket_type} titleKeyProps={{ TaxYear: taxState.taxYear }} canClose={false}>
            {paymentInfo?.map((paymentRecord, index) => {
                var FirstName = "";
                var buttonKey = "";
                if (paymentRecord.filer === "primary") {
                    FirstName = primaryFirstName;
                    buttonKey = "primary-payment-link";
                }
                else if (paymentRecord.filer === "spouse") {
                    FirstName = spouseFirstName;
                    buttonKey = "spouse-payment-link";
                }

                var amountLabel = paymentRecord.isPaid === false ?
                    "WorkflowPanel.payment.button-content.amount-due" :
                    "WorkflowPanel.payment.button-content.amount-paid"

                var buttonLabel = paymentRecord.isPaid === false ?
                    "WorkflowPanel.payment.button-content.due-title" :
                    "WorkflowPanel.payment.button-content.paid-title"

                var iconName = paymentRecord.isPaid === false ? "fa-credit-card" : "fa-regular fa-check"

                var langQueryParam = "&trnLanguage=" + (lang === "en" ? lang : "fre");
                var approvedCallback = "&approvedPage=" + window.location.origin + "/dashboard/approved";
                var declinedCallback = "&declinedPage=" + window.location.origin + "/dashboard/declined";
                var paymentLink = paymentRecord.paymentLink !== null ?
                    paymentRecord.paymentLink + langQueryParam + approvedCallback + declinedCallback : window.location.href

                var amount = paymentRecord.amount !== null ? paymentRecord.amount.toFixed(2) : 0.0.toFixed(2);
                amount = lang === "en" ? "$" + amount : amount.replace('.', ',') + "$"

                return (
                    <WorkflowButtonMaterial buttonKey={buttonKey} icon={iconName} isComplete={paymentRecord.isPaid}
                        text={buttonLabel} ticketType={ticketType} showChevron={!paymentRecord.isPaid ? 'true' : 'false'}
                        textProps={{ FirstName: FirstName }} key={buttonKey + '_' + index}
                        onClick={() => {!paymentRecord.isPaid && onWorkflowButtonAction('payment', taxState.taxYear, paymentLink)}}
                        amountLabel={amountLabel} amount={amount} />
                )
            })

            }
            <div className="wf-section-label-container">
                {T.translate('WorkflowPanel.payment.subtext')}
            </div>
        </WorkflowSection>
    )
}

PaymentReturnSection.propTypes = {
    currentYear: PropTypes.number.isRequired,
    lang: PropTypes.string.isRequired,
    taxState: PropTypes.shape({
        taxYear: PropTypes.number.isRequired,
        coupled: PropTypes.bool.isRequired,
        primary_province: PropTypes.string,
        primary_firstName: PropTypes.string.isRequired,
        spouse_province: PropTypes.string,
        spouse_firstName: PropTypes.string,
        subTotal: PropTypes.number,
        tenderTotal: PropTypes.number
    }),
    paymentInfo: PropTypes.arrayOf(PropTypes.shape({
        filer: PropTypes.string.isRequired,
        paymentLink: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
        isPaid: PropTypes.bool.isRequired,
    }))
}

export default PaymentReturnSection;

