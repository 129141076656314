import axios from 'axios';
import { getApiBaseUrl } from './serviceUtils';

class Api {
    constructor(allowedErrors, handleUnauthorized) {
        let service = axios.create({});
        service.interceptors.response.use(this.handleSuccess, this.handleError);
        this.service = service;
        this.baseUrl = getApiBaseUrl();
        this.allowedErrors = (Array.isArray(allowedErrors) ? allowedErrors : [allowedErrors]) || []
        this.unauthorizedFn = handleUnauthorized
    }

    getApiBaseUrl = () => {
        if (process.env.REACT_APP_ENV === 'DEV') {
            return process.env.REACT_APP_VA_API_URL
        } else {
            return document.location.protocol + '//' + document.location.host + '/api/'
        }
    }

    handleSuccess = (response) => {
        return response;
    };

    handleError = (error) => {
        // we need throw here, or else cancellations and network issues won't catch properly.
        if (!error.response) {
            throw(error)
        }

        // the below code will handle the http errors.
        var status = error && error.response ? error.response.status : 0
        if (!(this.allowedErrors || []).includes(status)) {
            switch (status) {
                case 401:
                    if (this.unauthorizedFn) {
                        this.unauthorizedFn(error)
                    }
                    break;
                default: break;
            }
        }

        return error.response
    };

    getAuthHeader = (accessToken) => {
        return {
            Authorization: 'Bearer ' + accessToken
        };
    }

    getFullPath(path) {
        return `${this.baseUrl}${path}`
    }

    get(accessToken, path) {
        return this.service.request({
            method: 'GET',
            headers: this.getAuthHeader(accessToken),
            url: this.getFullPath(path),
            responseType: 'json'
        });
    }

    post(accessToken, path, payload, config) {
        let baseConfig = {
            method: 'POST',
            headers: this.getAuthHeader(accessToken),
            url: this.getFullPath(path),
            responseType: 'json',
            data: payload
        }

        if (config) {
            return this.service.request(Object.assign({}, baseConfig, config));
        } else {
            return this.service.request(baseConfig);
        }
    }

    put(accessToken, path, payload) {
        return this.service.request({
            method: 'PUT',
            headers: this.getAuthHeader(accessToken),
            url: this.getFullPath(path),
            responseType: 'json',
            data: payload
        });
    }

    delete(accessToken, path) {
        return this.service.request({
            method: 'DELETE',
            headers: this.getAuthHeader(accessToken),
            url: this.getFullPath(path),
            responseType: 'json'
        });
    }
}

export default Api;