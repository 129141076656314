import React, { Component } from 'react'
import '../Controls/progressModal.css'
import './style.css'
import BaseModal from '../Modal/BaseModal'
import ConfirmingContent from './Dialogs/confirmingContent'
import AddressCheckContent from './Dialogs/addressCheckContent'
import CancelConfirm from './Dialogs/cancelConfirm'

class ProgressModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: this.props.lang,
            layoutType: this.props.layoutType,
            stage: this.props.stage,
            onDialogClose: this.props.onDialogClose,
            onAddAddress: this.props.onAddAddress,
            onConfirmIdentity: this.props.onConfirmIdentity,
            onAddressCancel: this.props.onAddressCancel,
            onCancelClose: this.props.onCancelClose
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.lang !== this.state.lang) {
            this.setState({ lang: newProps.lang })
        }

        if (newProps.layoutType !== this.state.layoutType) {
            this.setState({ layoutType: newProps.layoutType})
        }

        if (newProps.stage !== this.state.stage) {
            this.setState({ stage: newProps.stage })
        }

        if (newProps.onDialogClose !== this.state.onDialogClose) {
            this.setState({ onDialogClose: newProps.onDialogClose })
        }

        if (newProps.onAddAddress !== this.state.onAddAddress) {
            this.setState({ onAddAddress: newProps.onAddAddress })
        }

        if (newProps.onConfirmIdentity !== this.state.onConfirmIdentity) {
            this.setState({ onConfirmIdentity: newProps.onConfirmIdentity })
        }

        if (newProps.onAddressCancel !== this.state.onAddressCancel) {
            this.setState({ onAddressCancel: newProps.onAddressCancel })
        }

        if (newProps.onCancelClose !== this.state.onCancelClose) {
            this.setState({ onCancelClose: newProps.onCancelClose })
        }        
    }

    handleContinue(ev) {
        if (this.state.onDialogClose) {
            this.state.onDialogClose(ev);
        }
    }

    onAddAddress() {
        if (this.state.onAddAddress) {
            this.state.onAddAddress()
        }
    }

    onConfirmIdentity() {
        if (this.state.onConfirmIdentity) {
            this.state.onConfirmIdentity()
        }
    }

    handleAddressCancel() {
        if (this.state.onAddressCancel) {
            this.state.onAddressCancel()
        }
    }

    onCancelClose() {
        if (this.state.onCancelClose) {
            this.state.onCancelClose()
        }
    }

    render() {
        // error indicates an unsuccessful validation but not a rejection, probably a server error
        var visible = this.state.stage === 'confirming' || this.state.stage === 'confirmed'
            || this.state.stage === 'error' || this.state.stage === 'addressCheck' || this.state.stage === 'confirmCancel'
            || this.state.stage === 'confirming_docs' || this.state.stage === 'confirmed_docs'
            || this.state.stage === 'error_docs'
        
        var content = null;
        if (this.state.stage === 'confirming' || this.state.stage === 'confirmed' || this.state.stage === 'error'
            || this.state.stage === 'confirming_docs' || this.state.stage === 'confirmed_docs' || this.state.stage === 'error_docs') {
            content = (
                <ConfirmingContent lang={this.state.lang} stage={this.state.stage}
                    onDialogClose={this.state.onDialogClose} layoutType={this.state.layoutType} />
            )
        } else if (this.state.stage === 'addressCheck') {
            content = (
                <AddressCheckContent lang={this.state.lang} stage={this.state.stage}
                    onAddAddress={this.state.onAddAddress} onConfirmIdentity={this.state.onConfirmIdentity}
                    layoutType={this.state.layoutType} />
            )
        } else if (this.state.stage === 'confirmCancel') {
            content = (
                <CancelConfirm lang={this.state.lang} stage={this.state.stage} layoutType={this.state.layoutType}
                    onCancel={() => this.handleAddressCancel()} onClose={() => this.onCancelClose()} />
            )
        }

        return (
            <div className='confirmModalWidth'>
                <BaseModal show={visible} hideHeader={true} layoutType={this.props.layoutType}>
                    {content}
                </BaseModal>
            </div>
        );
    }
}

export default ProgressModal

/*                <Dialog
                    open={visible}
                    modal={true}
                    autoScrollBodyContent={false}
                    contentStyle={{
                        maxWidth: '90%',
                        width: '500px',
                        paddingTop: 0
                    }}
                    bodyStyle={{
                        margin: 0,
                        paddingTop: 12,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingBottom: 0
                    }}
                    onRequestClose={() => { }}>
                    <div style={{ margin: '10px' }}>
                        {content}
                    </div>
                </Dialog>
*/