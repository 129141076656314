
import React, { Component } from 'react';
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import HenryLogo from '../../Common/henryLogo'
import './sidebar.css';
import LayoutActions from '../../../reducers/layoutRedux'
import WorkflowActions from '../../../reducers/workflowRedux'
import SidebarButton from './SidebarButton'
import CircleIndicatorSidebarButton from './CircleIndicatorSidebarButton'
import AssignedTaxProBlock from './AssignedTaxProBlock'
import PrivacyBlock from './PrivacyBlock'
import TabletSidebar from './TabletSidebar'
import { getAssignedTaxPros } from '../../../utils/CaseStateUtilities'

class Sidebar extends Component {
    handleHomeClick = () => {
        this.props.setDashboardTab('dashboard')
    }

    handleSidebarButtonClick = (propertyName) => {
        this.props.setDashboardTab(propertyName || 'dashboard')
    }

    handleScheduleCallClick = (taxYear) => {
        this.props.scheduleCallAttempt(taxYear);
    }


    render() {
        return this.props.layoutType === 'desktop' ? (
            <div className='workflow-sidebar-container'>
                <div className='workflow-sidebar-main'>
                    <div className='logo-holder'>
                        <HenryLogo width="220px" onClick={this.handleHomeClick}
                            lang={this.props.lang} darkText={false} showProductTitle />
                    </div>
                    <SidebarButton
                        propertyName="dashboard"
                        text='Dashboard.labels.Dashboard'
                        isSelected={this.props.dashboardTab === 'dashboard'}
                        isLast={false}
                        onClick={this.handleSidebarButtonClick}
                    />
                    <CircleIndicatorSidebarButton
                        propertyName="messages"
                        text='Dashboard.labels.Messages'
                        isSelected={this.props.dashboardTab === 'messages'}
                        onClick={this.handleSidebarButtonClick}
                        isLast={false}
                        showIndicator={this.props.unreadMessagesCount > 0 && (this.props.dashboardTab !== 'messages' || this.props.showingMessageInput)}
                        indicatorValue={this.props.unreadMessagesCount}
                    />
                    <SidebarButton
                        propertyName="documents"
                        text='Dashboard.labels.Documents'
                        isSelected={this.props.dashboardTab === 'documents'}
                        isLast={true}
                        onClick={this.handleSidebarButtonClick}
                    />
                    <AssignedTaxProBlock assigned_tax_pros={this.props.assigned_tax_pros}
                        layoutType={this.props.layoutType} scheduleCallClick={this.handleScheduleCallClick}/>
                </div>
                <PrivacyBlock />
            </div>
        ) : (
            <TabletSidebar handleHomeClick={this.handleHomeClick} assigned_tax_pros={this.props.assigned_tax_pros}
                layoutType={this.props.layoutType} scheduleCallClick={this.handleScheduleCallClick} />
        )
    }
}

Sidebar.propTypes = {
    layoutType: PropTypes.string.isRequired,
    dashboardTab: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    unreadMessagesCount: PropTypes.number,
    assigned_tax_pros: PropTypes.arrayOf(PropTypes.object)
}


const mapStateToProps = (state) => {
    return {
        layoutType: state.layout.layoutType,
        dashboardTab: state.layout.dashboardTab,
        lang: state.layout.lang,
        unreadMessagesCount: state.messages.unreadMessagesCount,
        showingMessageInput: state.messages.inputScreenShowing,
        assigned_tax_pros: getAssignedTaxPros(state.workflow.case_state, state.layout.currentTaxYear)
    }
}

const mapDispatchToProps = (dispatch) => ({
    setDashboardTab: (tab) => dispatch(LayoutActions.setDashboardTab(tab)),
    scheduleCallAttempt: (taxYear) => dispatch(WorkflowActions.scheduleCallAttempt(taxYear))
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
