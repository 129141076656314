import React, { Component, Fragment } from 'react';
import './shared.css'

import T from 'i18n-react';
import ValidatingTextInput from '../Common/validatingTextInput'
import YesNoQuestion from '../Common/yesNoQuestion'
import PhoneTextInput from '../Common/phoneTextInput'
import ProvinceDropDown from '../Controls/DropDowns/provinceDropDown'
import PostalCodeTextInput from '../Common/postalCodeTextInput'
import NumberInput from '../Common/numberInput'
import HighlightedSection from '../Interview/highlightedSection'
import HelpLink from '../Controls/helpLink'
import FontAwesome from 'react-fontawesome';

class MailingAddressForm extends Component {
    constructor(props) {
        super(props)

        var address = this.props.address || {
            aptNumber: '',
            streetNumber: '',
            streetName: '',
            poBox: '',
            ruralRouteNumber: '',
            city: '',
            province: '',
            postalCode: '',
            inCareOf: '',
            telephone: '',
            mailingAddressChange: ''
        }

        this.state = {
            address,
            labelKey: this.props.labelKey,
            spouseFirstName: this.props.spouseFirstName,
            hideTopSet: this.props.hideTopSet,
            layoutType: this.props.layoutType,
            isReturningClient: this.props.isReturningClient,
            missingRequiredField: this.props.missingRequiredField
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.address !== this.state.address) {
            this.setState({
                address: newProps.address
            });
        }
        if (newProps.spouseFirstName !== this.state.spouseFirstName) {
            this.setState({ spouseFirstName: newProps.spouseFirstName });
        }
        if (newProps.hideTopSet !== this.state.hideTopSet) {
            this.setState({ hideTopSet: newProps.hideTopSet });
        }
        if (newProps.layoutType !== this.state.layoutType) {
            this.setState({ layoutType: newProps.layoutType });
        }
        if (newProps.isReturningClient !== this.state.isReturningClient) {
            this.setState({ isReturningClient: newProps.isReturningClient });
        }
        if (newProps.missingRequiredField !== this.state.missingRequiredField) {
            this.setState({ missingRequiredField: newProps.missingRequiredField })
        }
    }

    onAddressPropertyChange = (event, value) => {
        let address = {
            ...this.state.address
        }
        address[event.target.name] = value;
        this.setState({ address });

        if (this.props.onAddressChanged) {
            this.props.onAddressChanged(address);
        }
    }

    validateCityField(textValue) {
        return !textValue || textValue.match(/^[a-zA-Z\u00C0-\u017F\'\.\-\s]+$/)   // eslint-disable-line no-useless-escape
    }

    validateAlphaNumericField(textValue) {
        return !textValue || textValue.match(/^[a-zA-Z0-9\-\s]+$/)   // eslint-disable-line no-useless-escape
    }

    extractDigits(data) {
        if (!data) {
            return []
        }

        try {
            const matches = data.match(/\d/g)
            if (matches != null) {
                return matches.map(Number)
            }
        } catch (ex) {
            console.log("phoneTextInput.js/ExtractDigits/ data.match fail - " + ex.message);
        }

        return []
    }

    isValidPhone(data) {
        let digits = this.extractDigits(data)
        return Array.isArray(digits) && digits.length === 10
    }

    handleProvinceChange(ev, value) {
        var address = {
            ...this.state.address,
            province: value
        }

        this.setState({ address });
        if (this.props.onAddressChanged) {
            this.props.onAddressChanged(address);
        }
    }

    render() {
        var extraSpaceValue = false; //this.state.currentLanguage === 'fr' ? true : false;     
        let missingCity = this.state.missingRequiredField && !this.state.address.city
        // this is stupid but we don't want to override the internal state if we don't have an error 
        // so this makes sure it's either true or undefined.
        var postalCodeMissing = this.props.requirePostalCode && this.props.missingRequiredField && !this.state.address.postalCode
        var phoneHasErrors = this.props.missingRequiredField && !this.isValidPhone(this.state.address.telephone)
        var postalCodeHasErrors;
        if (postalCodeMissing) {
            postalCodeHasErrors = postalCodeMissing
        }
        
        let validationContent = (this.state.missingRequiredField && !this.state.address.province) ? (
            <div className="validationErrorStyle">
                <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#E70000', align: 'center' }} />
                <span style={{ marginLeft: 10 }}>{T.translate('Common.Errors.SelectAnAnswer')}</span>
            </div>
        ) : null;

        return (

            <div>     
                <form name="mailing_address_form" autoComplete="on">
                { this.state.hideTopSet === 'Y' ?
                    <div></div> :
                    <div>                       
                        <div className="horizontalFieldContainerTopAligned">
                            <div className="shortInputWidth rightMargin">
                                <ValidatingTextInput name="streetNumber" autoComplete="off"
                                    labelText={T.translate(this.state.labelKey + ".fieldLabel1")}
                                    value={this.state.address.streetNumber} heapIgnore="true"
                                    onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)} />
                            </div>
                            <div className="longInputWidth leftMargin rightMargin" style={{ flex: 1 }}>
                                <ValidatingTextInput name="streetName" autoComplete="off"
                                    labelText={T.translate(this.state.labelKey + ".fieldLabel2")}
                                    value={this.state.address.streetName} heapIgnore="true"
                                    onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)} />
                            </div>
                            <div className="shortInputWidth leftMargin">
                                <ValidatingTextInput name="aptNumber" autoComplete="off"
                                    labelText={T.translate(this.state.labelKey + ".fieldLabel0")}
                                    value={this.state.address.aptNumber} heapIgnore="true"
                                    onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)} />
                            </div>
                        </div>
                        <div className="horizontalFieldContainerTopAligned">
                            <div className="defaultInputWidth rightMargin">
                                <NumberInput name="poBox" autoComplete="off" heapIgnore="true"
                                    labelText={T.translate(this.state.labelKey + ".fieldLabel3")}
                                    value={this.state.address.poBox} extraTopSpacing={extraSpaceValue}
                                    onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)}
                                    validationFailedText={T.translate(this.state.labelKey + ".validationFailedLabel0")}
                                    validator={(textValue) => { return this.validateNumberField(textValue) }} />
                            </div>
                            <div className="defaultInputWidth leftMargin">
                                <ValidatingTextInput name="ruralRouteNumber" autoComplete="off"
                                    labelText={T.translate(this.state.labelKey + ".fieldLabel4")}
                                    value={this.state.address.ruralRouteNumber} heapIgnore="true"
                                    onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)}
                                    mainDivAdditionalClass="fixedWidthTextInput" extraTopSpacing={extraSpaceValue} />
                            </div>
                        </div>
                        <div className="horizontalFieldContainerTopAligned">
                            <div className="defaultInputWidth rightMargin">
                                <ValidatingTextInput name="city" autoComplete="address-level1"
                                    labelText={T.translate(this.state.labelKey + ".fieldLabel5")}
                                    value={this.state.address.city} heapIgnore="true" isRequired
                                    validationFailedText={T.translate("Common.Errors.InvalidNameChars")}
                                    validator={(textValue) => { return this.validateCityField(textValue) }}
                                    onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)}
                                    mainDivAdditionalClass="fixedWidthTextInput" missingRequiredField={missingCity}/>
                            </div>
                            <div className="validatingTextInputStyle defaultInputWidth leftMargin rightMargin">
                                <div>
                                        <div className={(this.state.missingRequiredField && !this.state.address.province) ? 'formLabelErrorStyle' : 'formLabelStyle' }>{T.translate(this.state.labelKey + ".fieldLabel6") + (this.props.requirePostalCode ? "*" : "")}</div>
                                        <ProvinceDropDown missingRequiredField={this.state.missingRequiredField && !this.state.address.province} name="province" options={this.provinceOptions} value={this.state.address.province} onValueChanged={(ev, value) => this.onAddressPropertyChange(ev, value)} />
                                        {validationContent}
                                </div>
                            </div>
                            <div className="validatingTextInputStyle defaultInputWidth leftMargin">
                                    <PostalCodeTextInput name="postalCode" autoComplete="postal-code"
                                        labelText={T.translate(this.state.labelKey + ".fieldLabel7") + (this.props.requirePostalCode ? "*" : "")}
                                        value={this.state.address.postalCode}
                                        onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)}
                                        validationFailedText="Common.Errors.MissingRequiredPostalCode"
                                        mainDivAdditionalClass="fixedWidthTextInput red"
                                        hasErrors={postalCodeHasErrors}
                                        specificErrorText={postalCodeMissing ? 'Common.Errors.MissingRequiredPostalCode' : null} />
                            </div>
                        </div>
                        <div className="horizontalFieldContainerTopAligned">
                            <div className="longInputWidth">
                                <ValidatingTextInput name="inCareOf" heapIgnore="true"
                                    labelText={T.translate(this.state.labelKey + ".fieldLabel8")}
                                    value={this.state.address.inCareOf}
                                    learnMoreId="FAQs/WhatDoIEnterInTheFieldInCareOf"
                                    onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)} />
                            </div>
                        </div>
                    </div>
                }
                <div>                
                    <div className="horizontalFieldContainer">
                        <div className="xlongInputWidth">
                                <PhoneTextInput name="telephone" autoComplete="tel-national"
                                    labelText={this.state.labelKey + ".fieldLabel9"}
                                    value={this.state.address.telephone}
                                    onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)}
                                    validationFailedText={"Common.Errors.RequiredPhone"}
                                    mainDivAdditionalClass="fixedWidthTextInput"
                                    isInline="true" isRequired={true} hasErrors={phoneHasErrors} missingRequiredField={this.state.missingRequiredField && !this.state.address.telephone} />
                        </div>
                    </div>
                        <div className="horizontalFieldContainer mailingTopSpacing">
                            <HighlightedSection isReturningClient={this.state.isReturningClient} margin={20} additionalClasses='mailingAddressSectionPadding'>
                                <YesNoQuestion name="mailingAddressChange" value={this.state.address.mailingAddressChange}
                                    autoWidth="true" labelText={T.translate(this.state.labelKey + ".fieldLabel10", { SpouseFirstName: this.state.spouseFirstName, TaxYear: this.props.taxYear })}
                                    onAnswerChanged={(ev, value) => { this.onAddressPropertyChange(ev, value) }}
                                    centerAlignThis={true} isRequired={true} missingRequiredField={this.state.missingRequiredField && !this.state.address.mailingAddressChange}
                                    layoutType={this.state.layoutType} />                                    
                                {this.state.address.mailingAddressChange === 'Y' &&
                                    <Fragment>
                                        <div className="mailingBottomSpacing" />
                                        <div className='mailingAddressChangeText'>
                                        {T.translate(this.state.labelKey + '.mailingAddressText', {
                                            SpouseFirstName: this.state.spouseFirstName,
                                            Link: <HelpLink linkId='OTE_FAQ/OTEHowdoIupdatemyaddresswithCRA' linkKey={this.state.labelKey + '.mailingAddressLink'} />
                                            })}
                                        </div>
                                    </Fragment>
                                }
                            </HighlightedSection>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default MailingAddressForm;