import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ConfigInitializer from './configInitializer';
import LangInitializer from './langInitializer';

/**
 * Initialization and configuration for the app
 * @param {any} props
 */
const AppInitializer = ({ onLoaded }) => {

    const [langInitialized, setLangInitialized] = useState(false);
    const [configInitialized, setConfigInitialized] = useState(false);

    useEffect(() => {
        if (configInitialized && langInitialized) {
            onLoaded();
        }
    }, [langInitialized, configInitialized, onLoaded])

    return (
        <div>
            <LangInitializer updateState={setLangInitialized} />
            <ConfigInitializer updateState={setConfigInitialized} />
        </div>
    )
}

AppInitializer.propTypes = {
    onLoaded: PropTypes.func.isRequired
}

export default AppInitializer;