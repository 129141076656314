import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import '../dox_explorer.css'
import DoxExplorerFileOperationsMenu from './doxExplorerFileOperationsMenu'
import FontAwesome from 'react-fontawesome';

const DoxExplorerFilesTableManageCell = (props) => {
    const handleFileOperation = (operation, documentId) => {
        props.onFileOperation(operation, documentId)
    }

    return (
        <div className={classnames('doxe-files-table-cell hrb-column doxe-centered', `hrb-is-${props.width}`)}>
            {props.isDownloading &&
                <div className='doxe-manage'>
                    <FontAwesome className='fa-spinner' name='spinner' pulse />
                </div>
            }
            {!props.isDownloading &&
                <DoxExplorerFileOperationsMenu documentId={props.documentId} onFileOperation={handleFileOperation} />
            }
        </div>
    )
}

DoxExplorerFilesTableManageCell.propTypes = {    
    width: PropTypes.number.isRequired,
    documentId: PropTypes.string.isRequired,
    onFileOperation: PropTypes.func.isRequired,
    isDownloading: PropTypes.bool.isRequired
}   

export default DoxExplorerFilesTableManageCell;