import React, { Component } from 'react';
import './style.css';
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';

class PlainButton extends Component {
    constructor(props) {
        super(props)

        this.state = {
            labelKey: this.props.labelKey || 'Common.Continue',
            isEnabled: !(this.props.isEnabled === false),
            onClick: this.props.onClick,
            inverted: this.props.inverted === true,
            inProgress: this.props.inProgress === true,
            isComplete: this.props.isComplete === true,
            isError: this.props.isError === true,
            isWarning: this.props.isWarning === true,
            tabIndex: this.props.tabIndex,
            extraClassName: this.props.extraClassName,
            labelArgs: this.props.labelArgs || {},
            isDocUploadButton: this.props.isDocUploadButton,
            isAlternate: this.props.isAlternate
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.labelKey !== newProps.labelKey) {
            this.setState({ labelKey: newProps.labelKey })
        }

        if (this.state.labelArgs !== newProps.labelArgs) {
            this.setState({ labelArgs: newProps.labelArgs })
        }

        if (this.state.isEnabled !== newProps.isEnabled) {
            this.setState({ isEnabled: !(newProps.isEnabled === false) })
        }

        if (this.state.onClick !== newProps.onClick) {
            this.setState({ onClick: newProps.onClick })
        }

        if (this.state.inverted !== newProps.inverted) {
            this.setState({ inverted: newProps.inverted === true })
        }

        if (this.state.inProgress !== newProps.inProgress) {
            this.setState({ inProgress: newProps.inProgress === true })
        }

        if (this.state.isWarning !== newProps.isWarning) {
            this.setState({ isWarning: newProps.isWarning === true })
        }

        if (this.state.isComplete !== newProps.isComplete) {
            this.setState({ isComplete: newProps.isComplete === true })
        }

        if (this.state.isComplete !== newProps.isComplete) {
            this.setState({ isComplete: newProps.isComplete === true })
        }

        if (this.state.isError !== newProps.isError) {
            this.setState({ isError: newProps.isError === true })
        }

        if (this.state.tabIndex !== newProps.tabIndex) {
            this.setState({ tabIndex: newProps.tabIndex })
        }

        if (this.state.extraClassName !== newProps.extraClassName) {
            this.setState({ extraClassName: newProps.extraClassName })
        }
    }

    handleClick(ev) {
        if (this.state.isEnabled && !this.state.inProgress && this.state.onClick) {
            this.state.onClick(ev)
        }
    }

    handleKeyDown(ev) {
        if (ev.keyCode === 13) {
            if (this.state.isEnabled && !this.state.inProgress && this.state.onClick) {
                this.state.onClick(ev)
            }
        }
    }

    render() {
        var buttonStyle = [this.state.isEnabled ? "plainButtonStyle" : (this.state.inverted ? "invertedPlainButtonDisabledStyle" : "plainButtonDisabledStyle")]
        if (this.state.isError) {
            buttonStyle.push(this.state.inverted ? "errorStyleInverted" : "errorStyle")
        } else if (this.state.isWarning) {
            buttonStyle.push(this.state.inverted ? "warningStyleInverted" : "warningStyle")
        } else if (this.state.inProgress || this.state.isComplete) {
            buttonStyle.push(this.state.inverted ? "inProgressInverted" : this.state.isAlternate ? "inProgressAlternate" : "inProgress")
        } else if (this.state.isDocUploadButton) {
            buttonStyle.push(this.state.isAlternate ? "docUploadAlternate" : "docUpload")
        } else {
            buttonStyle.push(this.state.inverted ? "inverted" : "normal")
        }

        if (this.state.extraClassName) {
            buttonStyle.push(this.state.extraClassName)
        }

        var content = null
        if (this.state.isEnabled && this.state.inProgress) {
            content = (<FontAwesome className='fa-spinner' name='spinner' pulse size='lg' />)
        } else if (this.state.isComplete && this.state.isEnabled) {
            content = (<FontAwesome className='fa-check' name='check' size='lg' />)
        } else {
            content = (<span className="unselectableLabel plainButtonSpan">{T.translate(this.state.labelKey, this.state.labelArgs)}</span>)
        }
        return (
            <div tabIndex={this.state.tabIndex} className={buttonStyle.join(' ')} onClick={(ev) => this.handleClick(ev)} onKeyDown={(ev) => this.handleKeyDown(ev)} >
                <div className='PlainbuttonContainer'>
                    {content}
                </div>
            </div>
        )
    }
}

export default PlainButton;