import { put, select, call, take } from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import ConfirmIdentityActions from '../reducers/confirmIdentityRedux'
import InterviewActions from '../reducers/interviewRedux'
import WorkflowActions from '../reducers/workflowRedux'
import { createConfirmIdUploadChannel } from './confirmIdUploadChannel'
import { push } from 'connected-react-router'
import { set, get } from 'idb-keyval'
import { trackEvent, HeapEvents } from '../utils/AnalyticsUtil'

const getAccessToken = state => state.auth.accessToken
const getConfirmIdentityState = state => state.confirmIdentity
const getInterview = state => state.interview

export function* confirmIdentity(api, action) {
    var accessToken = yield select(getAccessToken)
    var confirm = yield select(getConfirmIdentityState)
    var response = yield call(api.confirmIdentity, accessToken, confirm.target, confirm.extraAddresses, action.skipValidation)
    if (response.ok) {
        yield put(WorkflowActions.updateCaseState())
        yield put(ConfirmIdentityActions.addExtraConfirmAddress())
        yield put(ConfirmIdentityActions.setConfirmationResult(confirm.target, 'confirmed', true))
        trackEvent(confirm.target === 'spouse' ? HeapEvents.CONFIRM_SPOUSE_BASIC : HeapEvents.CONFIRM_PRIMARY_BASIC)
    } else if (response && response.status === 400) {
        yield put(ConfirmIdentityActions.addExtraConfirmAddress())
        yield put(ConfirmIdentityActions.setConfirmationResult(confirm.target, 'failed', false))
    } else {
        yield put(ConfirmIdentityActions.setStage(confirm.target, 'error'))
    }
}

export function* updateInterviewFromWorkingData(api, action) {
    var accessToken = yield select(getAccessToken)
    var confirm = yield select(getConfirmIdentityState)
    var taxYear = confirm.workingData.taxYear
    var interviewBase = yield select(getInterview)
    var interview = interviewBase[taxYear].interview

    var pi = confirm.target === 'spouse' ? interview.spouse : interview.primary
    var addrData = confirm.target !== 'spouse' || interview.spouse.mailingAddressSameAsPrimary === 'Y' ? interview.primary : interview.spouse
    var stopUsingPrimaryAddress = null
    var addressChange = false
    if (addrData.aptNumber !== confirm.workingData.aptNumber || addrData.streetNumber !== confirm.workingData.streetNumber
        || addrData.streetName !== confirm.workingData.streetName || addrData.poBox !== confirm.workingData.poBox
        || addrData.ruralRouteNumber !== confirm.workingData.ruralRouteNumber || addrData.city !== confirm.workingData.city
        || addrData.province !== confirm.workingData.province || addrData.postalCode !== confirm.workingData.postalCode) {

        addressChange = true

        if (confirm.target === 'spouse' && interview.spouse.mailingAddressSameAsPrimary === 'Y') {
            // we're using the same address as our spouse, if we make a change, just 'uncheck' that button and set our data
            stopUsingPrimaryAddress = true;
        } 
    }
    pi = {
        ...pi,
        firstName: confirm.workingData.firstName,
        middleName: confirm.workingData.middleName,
        lastName: confirm.workingData.lastName,
        sin: confirm.workingData.sin,
        dob: confirm.workingData.dob
    }
    if (addressChange) {
        pi.aptNumber = confirm.workingData.aptNumber
        pi.streetNumber= confirm.workingData.streetNumber 
        pi.streetName= confirm.workingData.streetName
        pi.poBox= confirm.workingData.poBox
        pi.ruralRouteNumber= confirm.workingData.ruralRouteNumber
        pi.city= confirm.workingData.city
        pi.province= confirm.workingData.province
        pi.postalCode= confirm.workingData.postalCode
    }
    if (stopUsingPrimaryAddress === true) {
        pi.mailingAddressSameAsPrimary = 'N'
    }
    var iview = {
        ...interview,
    }
    iview[confirm.target === 'spouse' ? 'spouse' : 'primary'] = pi

    yield put(InterviewActions.setInterview(iview, taxYear, true))

    yield call(api.saveInterview, accessToken, taxYear, iview, true)

    var stage = interview.primary.spouseTaxes === 'Y' ? 'summary' : 'start'
    yield put(ConfirmIdentityActions.setStage(confirm.target, stage))
}

export function* processImageForUpload(action) {
    if (!action.file) {
        return
    }

    var confirmIdentityState = yield select(getConfirmIdentityState)
    var resizedImage = null
    var file = confirmIdentityState.fileToBeUploaded
    if (!file) {
        return
    }

    

    var result = yield call(readFile, file)
    if (result) {
        resizedImage = result
    }
    if (resizedImage) {
        var keyName = confirmIdentityState.stage === 'upload_id_1' ? 'front' :
            (confirmIdentityState.stage === 'upload_id_2' ? 'back' : 'selfie')
        yield call(set, keyName, resizedImage)
        yield put(ConfirmIdentityActions.setFileDataToUpload(keyName))
    } else {
        yield put(ConfirmIdentityActions.fileUploadError('process_file'))
    }
}



function readFile(file) {
    return new Promise((resolve, reject) => {
        var fr = new FileReader();
        fr.onload = () => {
            resolve(fr.result)
        };
        fr.onerror = (err) => {
            fr.abort()
            reject(err)
        }
        fr.readAsDataURL(file);
    });
}

export function* beginFileUpload(api, action) {
    var accessToken = yield select(getAccessToken)
    var confirmState = yield select(getConfirmIdentityState)

    var fileDataToBeUploadedKey = null
    if (confirmState.stage === 'upload_id_1') {
        fileDataToBeUploadedKey = confirmState.uploadedImageData.front
    } else if (confirmState.stage === 'upload_id_2') {
        fileDataToBeUploadedKey = confirmState.uploadedImageData.back
    } else if (confirmState.stage === 'upload_selfie') {
        fileDataToBeUploadedKey = confirmState.uploadedImageData.selfie
    }
    var fileDataToBeUploaded = null
    if (fileDataToBeUploadedKey) {
        fileDataToBeUploaded = yield call(get, fileDataToBeUploadedKey)
    }

    var idType = confirmState.stage === 'upload_selfie' ? 'selfie' : confirmState.idTypeChoice
    if (fileDataToBeUploaded) {
        const channel = yield call(createConfirmIdUploadChannel, api, accessToken, confirmState.target,
            idType, confirmState.isFront, fileDataToBeUploaded)

        while (true) {
            const { progress = 0, err, success } = yield take(channel)
            if (err) {
                yield put(ConfirmIdentityActions.completeFileUpload('failed'))
                return
            }
            if (success) {
                yield put(ConfirmIdentityActions.completeFileUpload('success'))
                return
            }

            yield put(ConfirmIdentityActions.setFileUploadProgressValue(progress))
        }
    } else {
        yield put(ConfirmIdentityActions.completeFileUpload('failed'))
    }
}

export function* confirmIdentityDocs(api, action) {
    var accessToken = yield select(getAccessToken)
    var confirm = yield select(getConfirmIdentityState)
    // this request will now return immediately if starting the confirm succeeded, on a bad 
    // response we can fail immediately
    var response = yield call(api.confirmIdentityDocuments, accessToken, confirm.target, confirm.idTypeChoice)
    if (response.ok) {
        // loop until this finishes
        var confirmationResult = null;
        const delayValue = 10 * 1000
        var errorCount = 0;
        var attemptCount = 0;

        while (confirmationResult == null) {
            yield delay(delayValue)
            var resp = yield call(api.getConfirmDocumentStatus, accessToken, confirm.target)
            if (resp.ok) {
                if (resp.status === 204) {
                    // do nothing, just loop
                    attemptCount++
                } else if (resp.status === 200) {
                    confirmationResult = resp.data.confirmation_status
                    if (confirmationResult === 'verified') {
                        yield put(WorkflowActions.updateCaseState())
                        yield put(ConfirmIdentityActions.setConfirmationResult(confirm.target, 'confirmed_docs', true))
                        trackEvent(confirm.target === 'spouse' ? HeapEvents.CONFIRM_SPOUSE_SELFIE : HeapEvents.CONFIRM_PRIMARY_SELFIE)
                    } else {
                        yield put(ConfirmIdentityActions.setConfirmationResult(confirm.target, 'failed_final', false))
                        yield put(WorkflowActions.setButtonState('confirm_id', 'Complete'))
                        trackEvent(confirm.target === 'spouse' ? HeapEvents.CONFIRM_SPOUSE_FAILED : HeapEvents.CONFIRM_PRIMARY_FAILED)
                    }
                }
            } else {
                errorCount++
            }
            if (errorCount >= 2 || attemptCount > 120) {
                yield put(ConfirmIdentityActions.setStage(confirm.target, 'error_docs'))
            }
        }
    } else {
        yield put(ConfirmIdentityActions.setStage(confirm.target, 'error_docs'))
    }      
}

export function* getConfirmIdState(api, action) {
    // gets our starting point for confirm id.  If you've already failed phase 1 you don't get to retry
    // for coupled we should always start at the coupled page
    var accessToken = yield select(getAccessToken)
    var response = yield call(api.getConfirmIdState, accessToken)
    if (response.ok && response.data) {
        var self = null, spouse = null

        if ((response.data.self && response.data.self === 'failed') || (response.data.spouse && response.data.spouse === 'failed')) {
            yield put(ConfirmIdentityActions.setStage('self', 'failed_final'))
            yield put(push('/dashboard/confirm_id'))
            return
        }

        if (response.data.self && (response.data.self === 'not_verified' || response.data.self === 's1_not_verified')) {
            self = 'failed'
        } else if (response.data.self && response.data.self === 'verified') {
            self = 'verified'
        } else {
            self = 'start'
        }
        if (response.data.spouse && (response.data.spouse === 'not_verified' || response.data.spouse === 's1_not_verified')) {
            spouse = 'failed'
        } else if (response.data.spouse && response.data.spouse === 'verified') {
            spouse = 'verified'
        } else {
            spouse = 'start'
        }
        yield put(ConfirmIdentityActions.setInitialState(self, spouse))
    }
    
}

export function* completeQrCodeVerification(api, action) {
    var accessToken = yield select(getAccessToken)
    var response = yield call(api.completeQrCodeVerification, action.data, action.isSpouse, action.isCoupled, accessToken)
    if (response && response.ok) {
        var target = 'self'
        if (action.isSpouse === true) { target = 'spouse' }
        yield put(ConfirmIdentityActions.getConfirmIdState())      
        yield put(WorkflowActions.updateCaseState())
        yield put(ConfirmIdentityActions.setConfirmationResult(target, 'confirmed', true))
    }
}
