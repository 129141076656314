import React, { Component } from 'react';
import './shared.css';
import FontAwesome from 'react-fontawesome';

class SimpleLabelledCheckBox extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isChecked: this.props.isChecked,
            label: this.props.label
        }
    }

    onCheck(ev) {
        const isChecked = !this.state.isChecked
        this.setState({ isChecked: isChecked })
        if (this.props.click) {
            this.props.click(ev, isChecked);
        }
        if (this.props.onCheck) {
            const target = { name: this.props.name }
            const value = isChecked
            this.props.onCheck({ target }, value)
        }
    }

    render() {
        const containerDivStyle = { marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }

        let content = null
        if (this.state.isChecked) {
            content = (<FontAwesome className='fa-check' name='check' size='2x' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#14AA40', align: 'center' }} />)
        }
        return (
            <div style={containerDivStyle}>
                <div className="checkboxStyle" onClick={(ev) => this.onCheck(ev)}>
                    {content}
                </div>
                <div style={{ marginLeft: 10 }}>
                    <span className="checkboxTextStyle">{this.props.label}</span>
                </div>
            </div>
        )
    }
}


export default SimpleLabelledCheckBox;