import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import '../dox_explorer.css'
import DateTime from '../../../Controls/dateTime'

const DoxExplorerFilesTableModifiedCell = (props) => {
    return (
        <div className={classnames('doxe-files-table-cell hrb-column', `hrb-is-${props.width}`)}>
            <DateTime value={props.modifiedTs} fallbackValue='-' dateAndTime />
        </div>
    )
}

DoxExplorerFilesTableModifiedCell.propTypes = {    
    modifiedTs: PropTypes.string.isRequired,    
    width: PropTypes.number.isRequired
}   

export default DoxExplorerFilesTableModifiedCell;