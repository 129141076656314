import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    CompletedStates, isCustomerActionsStateOrBefore, isVirWaitingToFile, isReviewTask, WorkflowTasks
} from '../../../utils/WorkflowTasks';
import '../Section/section.css';
import DocumentUploadReturnSection from './DocumentUploadReturnSection';
import DownloadReturnSection from './DownloadReturnSection';
import InProgressBlankStateReturnSection from './InProgressBlankStateReturnSection';
import InterviewReturnSection from './InterviewReturnSection';
import NewReturnBlankStateReturnSection from './NewReturnBlankStateReturnSection';
import ReviewAndApproveReturnSection from './ReviewAndApproveReturnSection';
import PaymentSection from '../Payment/PaymentSection'


const TaxYearSection = ({ currentYear, taxState, lang, onWorkflowButtonAction, securityComplete, isDirectUser, paymentInfo }) => {
    const [tasks, setTasks] = useState([])
    const [hasReviewTask, setHasReviewTask] = useState(false)
    const [isCompleted, setIsCompleted] = useState(false)
    const [isCustomerActionsOrBefore, setIsCustomerActionsOrBefore] = useState(false);
    const [isVirWaitingToFileState, setIsVirWaitingToFileState] = useState(false)
    const [isPaymentState, setIsPaymentState] = useState(false);
    const [hasAdditionalDocs, setHasAdditionalDocs] = useState(false);
    const [isDocUploadComplete, setIsDocUploadComplete] = useState(false);
    const assigmentState = taxState?.assigned_tax_pro;
    const currentPage = taxState?.interview_state?.current_page;
    const isDirect = taxState?.is_direct;
    const previouslyCompleted = taxState?.interview_state.completed_date !== null && !taxState.interview_state.complete;

    useEffect(() => {
        var ts = ((taxState ? taxState.ticketTasks : null) || []).filter(x => x.target === '1' && !x.isCompleted)
        setTasks(ts);
        setHasReviewTask(ts.filter(x => isReviewTask(x.taskId)).length > 0)
        setIsCompleted(taxState && CompletedStates.includes(taxState.ticketState))
        setIsDocUploadComplete(((taxState ? taxState.ticketTasks : null) || []).filter(x => x.taskId === WorkflowTasks.DOCS_UPLOADED && x.isCompleted).length > 0)
        setIsCustomerActionsOrBefore(isCustomerActionsStateOrBefore(taxState.ticketState))
        setIsVirWaitingToFileState(isVirWaitingToFile(taxState.ticketState))
        setIsPaymentState(taxState.ticketState.startsWith("RTE_PAYMENT"));
        setHasAdditionalDocs(taxState.additional_docs && Array.isArray(taxState.additional_docs) && taxState.additional_docs.length > 0)
    }, [taxState])

    // Interview tile should'nt be displayed at first load of dashboard after registration/before starting the interview
    if (assigmentState === null) {
        return null;
    }    

    return (
        <Fragment>
            {
                (tasks.filter(x => x.taskId === WorkflowTasks.INTERVIEW_COMPLETED).length > 0) && ((isDirect && currentPage !== "1") || !isDirect || previouslyCompleted) ?
                <InterviewReturnSection taxState={taxState} currentYear={currentYear} onWorkflowButtonAction={onWorkflowButtonAction} />:''
            }
            {
                tasks.filter(x => x.taskId === WorkflowTasks.DOCS_UPLOADED).length > 0 &&
                <DocumentUploadReturnSection taxState={taxState} currentYear={currentYear}
                    onWorkflowButtonAction={onWorkflowButtonAction} isDirectUser={isDirectUser} />
            }
            {
                !isCompleted && isDocUploadComplete && !securityComplete &&
                <NewReturnBlankStateReturnSection taxState={taxState} currentYear={currentYear} securityComplete={securityComplete} onWorkflowButtonAction={onWorkflowButtonAction} isDirectUser={isDirectUser} />
            }
            {
                !isCompleted && tasks.length === 0 && !isCustomerActionsOrBefore && !hasAdditionalDocs && (!isPaymentState || paymentInfo == null) && securityComplete &&
                <InProgressBlankStateReturnSection taxState={taxState} currentYear={currentYear} isWaitingToFile={isVirWaitingToFileState} />
            }
            {
                hasReviewTask &&
                <ReviewAndApproveReturnSection taxState={taxState} currentYear={currentYear} securityComplete={securityComplete} onWorkflowButtonAction={onWorkflowButtonAction} isDirectUser={isDirectUser} />
            }
            {
                isPaymentState &&
                <PaymentSection lang={lang} currentYear={currentYear} securityComplete={securityComplete}
                    taxState={taxState} paymentInfo={paymentInfo} onWorkflowButtonAction={onWorkflowButtonAction}
                />
            }
            {
                isCompleted &&
                <DownloadReturnSection taxState={taxState} currentYear={currentYear} onWorkflowButtonAction={onWorkflowButtonAction} isDirectUser={isDirectUser} />
            }
        </Fragment>
    )
}

TaxYearSection.propTypes = {
    taxState: PropTypes.shape({
        taxYear: PropTypes.number.isRequired,
        ticketState: PropTypes.string.isRequired,
        ticketTasks: PropTypes.arrayOf(PropTypes.object),
        coupled: PropTypes.bool.isRequired,
        primary_province: PropTypes.string,
        primary_firstName: PropTypes.string.isRequired,
        spouse_province: PropTypes.string,
        spouse_firstName: PropTypes.string,
        subTotal: PropTypes.number,
        tenderTotal: PropTypes.number
    }),
    securityComplete: PropTypes.bool.isRequired,
    currentYear: PropTypes.number.isRequired,
    onWorkflowButtonAction: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    isDirectUser: PropTypes.bool
}

const mapStateToProps = state => ({
    lang: state.layout.lang,
    isDirectUser: state.workflow.is_direct_user,
});

export default connect(mapStateToProps)(TaxYearSection);
