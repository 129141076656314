import React from 'react'
import { string } from 'prop-types'

const DotDotDotIcon = ({ color }) => {
    return (
        <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.00065 13.6668C1.55862 13.6668 1.1347 13.4912 0.82214 13.1787C0.509579 12.8661 0.333984 12.4422 0.333984 12.0002C0.333984 11.5581 0.509579 11.1342 0.82214 10.8217C1.1347 10.5091 1.55862 10.3335 2.00065 10.3335C2.44268 10.3335 2.8666 10.5091 3.17916 10.8217C3.49172 11.1342 3.66732 11.5581 3.66732 12.0002C3.66732 12.4422 3.49172 12.8661 3.17916 13.1787C2.8666 13.4912 2.44268 13.6668 2.00065 13.6668ZM2.00065 8.66683C1.55862 8.66683 1.1347 8.49123 0.82214 8.17867C0.509579 7.86611 0.333984 7.44219 0.333984 7.00016C0.333984 6.55814 0.509579 6.13421 0.82214 5.82165C1.1347 5.50909 1.55862 5.3335 2.00065 5.3335C2.44268 5.3335 2.8666 5.50909 3.17916 5.82165C3.49172 6.13421 3.66732 6.55814 3.66732 7.00016C3.66732 7.44219 3.49172 7.86611 3.17916 8.17867C2.8666 8.49123 2.44268 8.66683 2.00065 8.66683ZM2.00065 3.66683C1.55862 3.66683 1.1347 3.49123 0.82214 3.17867C0.509579 2.86611 0.333984 2.44219 0.333984 2.00016C0.333984 1.55814 0.509579 1.13421 0.82214 0.821651C1.1347 0.509091 1.55862 0.333496 2.00065 0.333496C2.44268 0.333496 2.8666 0.509091 3.17916 0.821651C3.49172 1.13421 3.66732 1.55814 3.66732 2.00016C3.66732 2.44219 3.49172 2.86611 3.17916 3.17867C2.8666 3.49123 2.44268 3.66683 2.00065 3.66683Z" fill={color} />
        </svg>
    )
}

DotDotDotIcon.propTypes = {
    color: string
}

export default DotDotDotIcon


