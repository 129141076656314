import React from 'react';
import PropTypes from 'prop-types'
import WorkflowSection from '../Section/WorkflowSection'
import '../Section/section.css'
import T from 'i18n-react';
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import LayoutActions from '../../../reducers/layoutRedux';

const InProgressBlankStateReturnSection = ({ currentYear, taxState, isWaitingToFile }) => {
    const dispatch = useDispatch();

    return (
        <WorkflowSection
            titleKey={taxState.is_vir ? "WorkflowPanel.taxReturnTitleVIR" : "WorkflowPanel.taxReturnTitle"}
            titleKeyProps={{ TaxYear: taxState.taxYear }}
            isVIR={taxState.is_vir}
            isAlternate={(currentYear - taxState.taxYear) % 2 === 1}
            comingUpKey='WorkflowPanel.blank.in-progress.coming-up' canClose={false}
            ticketType={taxState.ticket_type}>
            <div className='wf-upload-docs-complete-container'>
                <div className='wf-upload-docs-complete-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26">
                        <path d="M16.3574 8.84277L10.9375 14.2676L8.59863 11.9678C8.14087 11.51 7.39941 11.51 6.94141 11.9678C6.4834 12.4255 6.48364 13.167 6.94141 13.625L10.0664 16.75C10.3369 16.9824 10.6348 17.0947 10.9375 17.0947C11.2402 17.0947 11.5371 16.9803 11.7661 16.7514L18.0161 10.5014C18.4739 10.0437 18.4739 9.3022 18.0161 8.84419C17.5583 8.38618 16.8164 8.38867 16.3574 8.84277ZM12.5 0.297852C5.5957 0.297852 0 5.89356 0 12.7979C0 19.7021 5.5957 25.2979 12.5 25.2979C19.4043 25.2979 25 19.7021 25 12.7979C25 5.89356 19.4043 0.297852 12.5 0.297852ZM12.5 22.9541C6.89941 22.9541 2.34375 18.3979 2.34375 12.7979C2.34375 7.19775 6.89941 2.6416 12.5 2.6416C18.1006 2.6416 22.6562 7.19775 22.6562 12.7979C22.6562 18.3979 18.1006 22.9541 12.5 22.9541Z" fill="black" />
                        <defs>
                            <clipPath id="clip0_743_12151">
                                <rect width="25" height="25" fill="white" transform="translate(0 0.297852)" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="wf-section-label-container wf-section-label-extra-spacing wf-section-label-container-remove-spacing">
                    {isWaitingToFile ?
                        T.translate(`WorkflowPanel.upload-docs.subtext.completed_vir`) :
                        T.translate(`WorkflowPanel.upload-docs.subtext.completed`, { documents: <Link to='' onClick={() => dispatch(LayoutActions.setDashboardTab('documents'))}><b>documents</b></Link> })}
                </div>
            </div>
        </WorkflowSection>
    )
}

InProgressBlankStateReturnSection.propTypes = {
    taxState: PropTypes.shape({
        taxYear: PropTypes.number.isRequired
    }),
    currentYear: PropTypes.number.isRequired,
    isWaitingToFile: PropTypes.bool
}

export default InProgressBlankStateReturnSection;

