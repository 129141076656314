import React, { Component } from 'react';
import { connect } from 'react-redux'
import { replace, push } from 'connected-react-router'
import { withRouter } from 'react-router-dom'
import FontAwesome from 'react-fontawesome';
import BaseModal from '../Modal/BaseModal';
import T from 'i18n-react';
import LearnMore from '../Controls/learnMore'
import HelpCentre from '../../sagas/Help/helpCentre'
import { HeapEvents, trackEvent } from '../../utils/AnalyticsUtil'
import classnames from 'classnames'
import { getTaxYearState } from '../../utils/CaseStateUtilities'
import './style.css'

import LayoutActions from '../../reducers/layoutRedux'
import WorkflowActions from '../../reducers/workflowRedux'
import DocumentActions from '../../reducers/documentsRedux'
import MessagesActions from '../../reducers/messagesRedux'
import ApproveDraftsActions from '../../reducers/approveDraftsRedux'
import SigningActions from '../../reducers/signingRedux'
import FilesActions from '../../reducers/filesRedux'
import InfoDialogContent from '../Messages/infoDialogContent'
import ReturnLink from './returnLink';
import PomSelection from './PomSelection';
import PlainButton from '../Controls/plainButton'
import LoadingSpinner from '../Loading/loadingSpinner'
import { setTimeout } from 'timers-browserify';
import fileDownloadUtil from '../Dox/Explorer/services/FileDownloadUtil';
import { CDMFileOperationStatus, ErrorTypes } from '../Dox/Shared/resources/Enums';
import doxExplorerCDMServices from '../Dox/Shared/services/doxExplorerCDMServices';
import { fileTypeWillOpen } from '../Dox/Shared/resources/DoxFileTypes';
import { browserShouldOpenInTab } from '../Dox/Shared/services/Util';

// TODO : Removed BoxWrapper
class ReviewDrafts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stage: props.stage,
            fileInViewer: null,
            missingFiles: false,
            missingFilesErrorVisible: false,
            isAprroveBtnClicked: false,
            processing: false
        }
    }

    componentWillMount() {
        if (!this.props.taxYearState) {
            this.props.replaceNavigation('/missing')
        }

        var completedSigning = this.checkSigningState(true);
        if (!completedSigning || this.missingFile(this.props.taxYearDocs)) {
            this.props.setDocumentsForReview(this.props.taxYear, null)
            this.props.loadDocumentsForReview(this.props.taxYear);
        }
        if (!completedSigning || this.missingFile(this.props.approvalDocs)) {
            this.props.setApprovalDocuments(this.props.taxYear, null)
            this.props.loadApprovalDocuments(this.props.taxYear);
        }

    }

    missingFile = (docs) => {
        if (!docs) {
            return true;
        }
        if (!docs.primaryFederal || !docs.primaryFederal.fileId) {
            return true;
        }
        if (this.props.taxYearState.coupled && (!docs.spouseFederal || !docs.spouseFederal.fileId)) {
            return true;
        }
        if (this.props.taxYearState.primary_province === 'QC' && (!docs.primaryQuebec || !docs.primaryQuebec.fileId)) {
            return true;
        }
        if (this.props.taxYearState.coupled && this.props.taxYearState.spouse_province === 'QC'
            && (!docs.spouseQuebec || !docs.spouseQuebec.fileId)) {
            return true;
        }
        if (this.props.taxYearState.ticket_type === 'RTE' && (!docs.primaryFederalTaxStory || (this.props.taxYearState.coupled && !docs.spouseFederalTaxStory))) {
            return true;
        }
        if (this.props.taxYearState.ticket_type === 'RTE' && this.props.taxYearState.primary_province === 'QC' && (!docs.primaryQuebecTaxStory || (this.props.taxYearState.coupled && !docs.spouseQuebecTaxStory))) {
            return true;
        }
        return false;
    }

    componentWillReceiveProps(newProps) {
        if (this.state.stage !== newProps.stage) {
            window.scrollTo(0, 0)
            this.setState({ stage: newProps.stage })
            // if we're changing the signing stage, go and do the signing
            if (newProps.stage === 'approveSpouse') this.goSignThings(newProps)
        }
        if (this.state.signedDocuments !== newProps.signedDocuments) {
            this.setState({ signedDocuments: newProps.signedDocuments })
        }
        if (this.state.signingState !== newProps.signingState) {
            this.setState({ signingState: newProps.signingState })
        }
        if (this.state.signingError !== newProps.signingError) {
            this.setState({ signingError: newProps.signingError })
            // VAI-476 show 
            if (newProps.signingError === 'filenotfound') this.setState({ missingFilesErrorVisible: true })
        }
        if (this.state.postLoginComplete !== newProps.postLoginComplete) {
            this.setState({ postLoginComplete: newProps.postLoginComplete })
            // VAI-458 copied code from componentDidMount to here (just in case)
            setTimeout(this.goSignThings(newProps), 100)
        }
    }

    // ESignature Things
    checkSigningState(fromPageLoad) {
        if (this.props.signingState === 'complete' || this.props.signingState === 'failed') {
            if (this.state.stage === 'approveSelf' || this.state.stage === 'approveSpouse') {
                // reset signing state
                this.props.signingStateReset()
                // if signing was successful, continue on to see if we want to approve spouse
                if (this.props.signingState === 'complete') {
                    trackEvent(this.state.stage === 'approveSpouse' ? 'OTEApproveCompleteSpouse' : 'OTEApproveCompletePrimary', null)
                    if (this.state.stage === 'approveSelf' && this.props.taxYearState.coupled) {
                        this.props.setApprovalStage('approveSpouse')
                    } else {
                        this.setApprovalComplete()
                    }
                }
            }
            return true
        }

        if (fromPageLoad && this.props.signingState === 'in_progress') {
            this.props.signingStateReset()
            return true
        }
        return false
    }

    goSignThings(props) {
        props = props || this.props;

        var docs = [];
        var urls = [];

        if (props.stage === 'approveSelf') {
            //if (!props.signedDocuments.self.T183) {
            docs.push('T183');
            urls.push(this.getLink('primaryFederal'));
            //}
            if (props.taxYearState.primary_province === 'QC') {
                docs.push('TP1000');
                urls.push(this.getLink('primaryQuebec'));
            }
        } else if (props.stage === 'approveSpouse' && props.taxYearState.coupled) {
            //if (!props.signedDocuments.spouse.T183) {
            docs.push('T183');
            urls.push(this.getLink('spouseFederal'));
            //}
            if (props.taxYearState.spouse_province === 'QC') {
                docs.push('TP1000');
                urls.push(this.getLink('spouseQuebec'));
            }
        }

        if (docs.length > 0) {
            // we still have docs to sign, so go sign them
            this.props.signExternalForm(props.taxYear, docs, urls, (props.stage === 'approveSpouse'), '/dashboard/review/' + props.taxYear, props.taxYearState.spouse_firstName, props.taxYearState.primary_firstName)
        } else if (props.stage === 'approveSelf' && props.taxYearState.coupled) {
            // If we have nothing to sign, but our spouse does, update signing stage
            this.props.setApprovalStage('approveSpouse')
        } else {
            // we have nothing left to sign! W00t!
            this.setApprovalComplete()
        }
    }

    setApprovalComplete() {
        this.setState({ processing: true })

        this.props.setApprovalComplete(this.props.taxYear);
    }

    getLink(fileType) {
        var file = { ...this.props.approvalDocs[fileType] }
        return (file ? file.fileId : null);
    }

    // Local event handlers 
    onBackToDashClicked(e) {
        this.props.replaceNavigation('/dashboard')
    }

    async handleOpenFileById(action, fileId) {

        try {
            //perform the download    
            let response = await doxExplorerCDMServices.downloadFile(this.props.accessToken, fileId);

            if (response && response.status === 200 && response.data) {
                // get the file name and clean it. Not all files have extra chars, 
                // but some might, so strip them here.
                let cleanFileName = (response.data.fileName || 'unknown').replace(/[/\\?%*:|"<>]/g, '')
                let fileNameParts = cleanFileName.split('.')
                // find file type
                let fileType = ''
                if (fileNameParts.length > 1) {
                    fileType = '.' + fileNameParts[fileNameParts.length - 1]
                }

                // parse binary response data into fancy blob
                let fileLink = fileDownloadUtil.makeBlobLink(response.data.fileContents, fileType);

                // deal with browser opening in a new tab or download file
                let fileOpens = fileTypeWillOpen(fileType);
                let openInTab = browserShouldOpenInTab();
                fileDownloadUtil.downloadFile(fileLink, cleanFileName, action === 'view' && fileOpens && openInTab)

                return CDMFileOperationStatus.DOWNLOAD_COMPLETE;
            } else if (response && response.status === 404) {
                return ErrorTypes.FAIL_NO_SUCH_FILE;
            } else {
                return ErrorTypes.FAIL_TRANSIENT;
            }
        } catch (error) {
            return ErrorTypes.FAIL_TRANSIENT;
        }
    }

    onViewClicked(fileType) {
        var file = this.props.taxYearDocs[fileType]
        if (file && file.fileId) {
            this.setState({ fileInViewer: file.fileId })
            this.props.setBoxDialogStates(false, false, true);
            this.props.setDocumentForReviewViewed(file.fileId, this.props.taxYear);
            this.handleOpenFileById('view', file.fileId);
        } else {
            // show the box folder view
            this.setState({ fileInViewer: null })
            this.props.setBoxDialogStates(false, false, true);
        }
    }

    onDownloadClicked(fileType) {
        var file = this.props.taxYearDocs[fileType]
        if (file && file.fileId) {
            this.props.setDocumentForReviewViewed(file.fileId, this.props.taxYear);
            this.handleOpenFileById('download', file.fileId);
        }
    }

    onApproveClicked() {
        if (this.props.taxYearState.ticket_type === 'RTE') {
            if (!(this.props.taxYearState.products_state.isPrimaryEligible && this.props.taxYearState.products_state.hasPrimaryOptedIn == null) && !(this.props.taxYearState.products_state.isSpouseEligible && this.props.taxYearState.products_state.hasSpouseOptedIn == null)) {
                this.goSignThings()
                trackEvent(HeapEvents.REVIEW_SIGN_AND_AUTHORIZE);
            }
            this.setState({ isAprroveBtnClicked: true })
        }
        else {
            this.goSignThings()
        }
    }

    onMessageMyProClicked() {
        this.props.setInputScreenShowing(true)
        this.props.setDashboardTab('messages')
        this.props.pushNavigation('/dashboard')
        trackEvent(HeapEvents.REVIEW_SEND_MESSAGE);
    }

    handleBoxDialogClose() {
        this.props.setBoxDialogStates(false, false, false)
    }

    openChecklist() {
        HelpCentre.openHelpLink('ReviewingYourReturn/WhatShouldILookFor')
    }

    render() {
        // determine the root styling.
        let bodyStyle = 'reviewContainerAutoHeight';
        bodyStyle += (this.props.layoutType === 'tablet' || this.props.layoutType === 'small_tablet' ? ' tablet' :
            (this.props.layoutType === 'mobile' ? ' mobile' : ' desktop'));

        // determine which strings to display
        var key = this.props.taxYearState.coupled ? 'Coupled' : 'Single'
        if (this.props.taxYearState.primary_province === 'QC') key = key + 'QC'
        var previewLinks=[]
        // create the preview links
        var checklistLink = (<span className="reviewSpanNoMargin learnMoreStyle" onClick={() => this.openChecklist()}>{T.translate('ReviewAndApprove.Checklist')}</span>)
        if (this.props.taxYearState.ticket_type === 'RTE') {
            previewLinks = [
                <ReturnLink key="primaryFederalTaxStory"
                    titleKey="ReviewAndApprove.FederalStory"
                    layoutType={this.props.layoutType}
                    firstName={this.props.taxYearState.primary_firstName}
                    file={this.props.taxYearDocs ? this.props.taxYearDocs.primaryFederalTaxStory : null}
                    onViewClicked={() => this.onViewClicked('primaryFederalTaxStory')}
                    onDownloadClicked={() => this.onDownloadClicked('primaryFederalTaxStory')}
                    ticketType={this.props.taxYearState.ticket_type}
                    filerStoryType="primaryFederal" />
            ]

            if (this.props.taxYearState.primary_province === 'QC') {
                previewLinks.push(
                    <ReturnLink key="primaryQuebecTaxStory"
                        titleKey="ReviewAndApprove.QuebecStory"
                        layoutType={this.props.layoutType}
                        firstName={this.props.taxYearState.primary_firstName}
                        file={this.props.taxYearDocs ? this.props.taxYearDocs.primaryQuebecTaxStory : null}
                        onViewClicked={() => this.onViewClicked('primaryQuebecTaxStory')}
                        onDownloadClicked={() => this.onDownloadClicked('primaryQuebecTaxStory')}
                        ticketType={this.props.taxYearState.ticket_type}
                        filerStoryType="primaryQuebec"
                    />
                )
            }
        } else {
            previewLinks = [
                <ReturnLink key="primaryFederal"
                    titleKey="ReviewAndApprove.Federal"
                    layoutType={this.props.layoutType}
                    firstName={this.props.taxYearState.primary_firstName}
                    file={this.props.taxYearDocs ? this.props.taxYearDocs.primaryFederal : null}
                    onViewClicked={() => this.onViewClicked('primaryFederal')}
                    onDownloadClicked={() => this.onDownloadClicked('primaryFederal')} />
            ]

            if (this.props.taxYearState.primary_province === 'QC') {
                previewLinks.push(
                    <ReturnLink key="primaryQuebec"
                        titleKey="ReviewAndApprove.Quebec"
                        layoutType={this.props.layoutType}
                        firstName={this.props.taxYearState.primary_firstName}
                        file={this.props.taxYearDocs ? this.props.taxYearDocs.primaryQuebec : null}
                        onViewClicked={() => this.onViewClicked('primaryQuebec')}
                        onDownloadClicked={() => this.onDownloadClicked('primaryQuebec')}
                    />
                )
            }
        }

        if (this.props.taxYearState.coupled) {
            if (this.props.taxYearState.ticket_type === 'RTE') {
                previewLinks.push(
                    <ReturnLink key="spouseFederalTaxStory"
                        titleKey="ReviewAndApprove.FederalStory"
                        layoutType={this.props.layoutType}
                        firstName={this.props.taxYearState.spouse_firstName}
                        file={this.props.taxYearDocs ? this.props.taxYearDocs.spouseFederalTaxStory : null}
                        onViewClicked={() => this.onViewClicked('spouseFederalTaxStory')}
                        onDownloadClicked={() => this.onDownloadClicked('spouseFederalTaxStory')}
                        ticketType={this.props.taxYearState.ticket_type}
                        filerStoryType="spouseFederal"
                    />
                )

                if (this.props.taxYearState.coupled && this.props.taxYearState.spouse_province === 'QC')
                    previewLinks.push(
                        <ReturnLink key="spouseQuebecTaxStory"
                            titleKey="ReviewAndApprove.QuebecStory"
                            layoutType={this.props.layoutType}
                            firstName={this.props.taxYearState.spouse_firstName}
                            file={this.props.taxYearDocs ? this.props.taxYearDocs.spouseQuebecTaxStory : null}
                            onViewClicked={() => this.onViewClicked('spouseQuebecTaxStory')}
                            onDownloadClicked={() => this.onDownloadClicked('spouseQuebecTaxStory')}
                            ticketType={this.props.taxYearState.ticket_type}
                            filerStoryType="spouseQuebec"
                        />
                    )
            } else {
                previewLinks.push(
                    <ReturnLink key="spouseFederal"
                        titleKey="ReviewAndApprove.Federal"
                        layoutType={this.props.layoutType}
                        firstName={this.props.taxYearState.spouse_firstName}
                        file={this.props.taxYearDocs ? this.props.taxYearDocs.spouseFederal : null}
                        onViewClicked={() => this.onViewClicked('spouseFederal')}
                        onDownloadClicked={() => this.onDownloadClicked('spouseFederal')}
                    />
                )

                if (this.props.taxYearState.coupled && this.props.taxYearState.spouse_province === 'QC')
                    previewLinks.push(
                        <ReturnLink key="spouseQuebec"
                            titleKey="ReviewAndApprove.Quebec"
                            layoutType={this.props.layoutType}
                            firstName={this.props.taxYearState.spouse_firstName}
                            file={this.props.taxYearDocs ? this.props.taxYearDocs.spouseQuebec : null}
                            onViewClicked={() => this.onViewClicked('spouseQuebec')}
                            onDownloadClicked={() => this.onDownloadClicked('spouseQuebec')}
                        />
                    )
            }
        }

        // hack for single return on mobile view (center it!)
        var linkStyle = null;
        if (this.props.layoutType === 'mobile' && !this.props.taxYearState.coupled && this.props.taxYearState.primary_province !== 'QC') {
            linkStyle = { justifyContent: 'center', minWidth: 0 }
        }

        // spit out some stuff
        return this.props.taxYearDocs && this.props.taxYearState.ticket_type !== 'RTE' ? (
            <div className={bodyStyle}>
                <div className="authorizeContainerStyle">
                    <div className="authorizeBackContainer">
                        <div className="authorizeBackButtonStyle" onClick={(e) => this.onBackToDashClicked(e)}>
                            <FontAwesome className='fa-angle-left' name='fa-angle-left' size='2x' />
                            <h4>{T.translate('Common.ToDashboard')}</h4>
                        </div>
                    </div>
                    <div className="authorizeContentStyle">
                        <h1>{T.translate('ReviewAndApprove.TitleReview.' + key + `.${this.props.showOteContent ? 'Ote' : 'Direct'}`)}</h1>
                        <T.span text={{ key: 'ReviewAndApprove.HeaderReview.' + key + `.${this.props.showOteContent ? 'Ote' : 'Direct'}`, checklist: checklistLink }} />
                        <div style={{ height: 20 }} />
                    </div>
                    <div className="previewWrapperContent">
                        <div className="previewHorizontalContent" style={linkStyle}>
                            {previewLinks.length > 2 ? [previewLinks[0], previewLinks[1]] : previewLinks}
                        </div>
                        {previewLinks.length > 2 ?
                            <div className="previewHorizontalContent">
                                {previewLinks.length > 3 ? [previewLinks[2], previewLinks[3]] : previewLinks[2]}
                            </div> : null
                        }
                    </div>
                    <div className="authorizeContentStyle">
                        <div style={{ height: 20 }} />
                        <h2>{T.translate('ReviewAndApprove.TitleApprove.' + key)}</h2>
                        <div style={{ height: 10 }} />
                        <T.span text={'ReviewAndApprove.Instructions.' + key + `.${this.props.showOteContent ? 'Ote' : 'Direct'}`} />
                        <div style={{ height: 20 }} />
                        <T.span text={{
                            key: 'ReviewAndApprove.Questions.' + key + `.${this.props.showOteContent ? 'Ote' : 'Direct'}`, learn_more: <LearnMore baseClassName='reviewSpanNoMargin' linkId="AuthorizingHRBlock/WhatAreT183andTP1000" />
                        }} />
                        <div style={{ height: 20 }} />
                    </div>
                </div>
                <div className="reviewBottomContainer" style={{ marginTop: 10 }}>
                    <PlainButton inverted={true} onClick={(e) => this.onMessageMyProClicked(e)} labelKey={this.props.showOteContent ? 'ReviewAndApprove.MessagePro.Ote' : 'ReviewAndApprove.MessagePro.Direct'} isEnabled={true} />
                    <div style={{ width: '20px', height: '10px' }} />
                    <PlainButton inverted={false} onClick={(e) => this.onApproveClicked(e)}
                        labelKey={'ReviewAndApprove.Approve.' + key}
                        isEnabled={Boolean(this.props.approvalDocs)}
                        inProgress={this.state.processing}
                    />
                </div>
                <BaseModal show={this.state.missingFilesErrorVisible} onClose={() => this.setState({ missingFilesErrorVisible: false })} layoutType={this.props.layoutType} >
                    <InfoDialogContent
                        titleKey='ReviewAndApprove.MissingDocsError.Title'
                        labelKeys={['ReviewAndApprove.MissingDocsError.Label', 'ReviewAndApprove.MissingDocsError.SubLabel']}
                        onOK={() => { this.setState({ missingFilesErrorVisible: false }) }}
                        onCancel={() => { this.setState({ missingFilesErrorVisible: false }) }}
                        onCloseDialog={() => { this.setState({ missingFilesErrorVisible: false }) }}
                        isWarning={true}
                        faIconName='fa-exclamation'
                    />
                </BaseModal>
            </div>
        ) : this.props.taxYearDocs && this.props.taxYearState.ticket_type === 'RTE' ? (
            <div className={bodyStyle}>
                <div className="authorizeContentStyleRte">
                    <div className="authorizeBackContainer">
                        <div className="authorizeBackButtonStyle" onClick={(e) => this.onBackToDashClicked(e)}>
                            <FontAwesome className='fa-angle-left' name='fa-angle-left' size='2x' />
                            <h4>{T.translate('Common.ToDashboard')}</h4>
                        </div>
                    </div>
                    <div style={{ height: 20 }} />
                    <div className={classnames('reviewCompletedContentStyleRte reviewTaxStory', this.props.layoutType)}>
                        <h1>{T.translate('ReviewAndApprove.TitleReview.Rte')}</h1>
                        <div style={{ height: 20 }} />
                    </div>
                    <div className={classnames('reviewCompletedContentStyleRte reviewTaxStory', this.props.layoutType)}>
                        <T.span text={{ key: 'ReviewAndApprove.HeaderReview.Rte' }} />
                    </div>

                    <div style={{ paddingBottom: '3%' }}>
                        <div className={classnames('wrapperRte', this.props.layoutType)}>
                            <div className="previewWrapperContent" style={{flexWrap:'nowrap'}}>
                                <div className={this.props.taxYearState.coupled ? 'previewSectionWrapper' : ''}>
                                    <div className="previewSection">
                                        {this.props.taxYearState.coupled &&
                                            <div className='filerTitle'>{this.props.taxYearState.primary_firstName}</div>}
                                        <div className="previewHorizontalContentRTE" style={linkStyle}>
                                            {previewLinks.length > 2
                                                ? [previewLinks[0], previewLinks[1]]
                                                : this.props.taxYearState.coupled ? previewLinks[0] : previewLinks}
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', marginBottom: '5%' }} >
                                        {this.props.taxYearState.ticket_type === 'RTE' && this.props.taxYearState.products_state.isPrimaryEligible &&
                                            <PomSelection taxYearState={this.props.taxYearState} caseId={this.props.taxYearState.workflow_state.case_id} product='POM' filer='primary' isAprroveBtnClicked={this.state.isAprroveBtnClicked} />}
                                    </div>
                                    { this.state.isAprroveBtnClicked && this.props.taxYearState.products_state.isPrimaryEligible &&  this.props.taxYearState.products_state.hasPrimaryOptedIn===null &&
                                    <div className="validationErrorStyle">
                                        <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#E70000', align: 'center' }} />
                                        <span style={{ marginLeft: 10 }}>{T.translate('ReviewAndApprove.POMSection.ReqValidationErrorMsg')}</span>
                                    </div> }
                                </div>
                                {this.props.taxYearState.coupled && <div className="previewSection">
                                    <div className="dotted_line" style={this.props.taxYearState.ticket_type === 'RTE' && this.props.taxYearState.products_state.isPrimaryEligible ? this.state.isPomErrorVisible ? { height: '90%' } : { height: '85%' } : { height: '70%' }}></div>
                                </div>}
                                <div className={this.props.taxYearState.coupled ? 'previewSectionWrapper' : ''}>
                                    { // This code snippet is for Coupled Non-Quebec returns only
                                        previewLinks.length === 2 && this.props.taxYearState.coupled &&
                                        <div className="previewSection">
                                            <div className='filerTitle'>{this.props.taxYearState.spouse_firstName}</div>
                                            <div className="previewHorizontalContentRTE">
                                                {previewLinks[1]}
                                            </div>
                                        </div>
                                    }
                                    {previewLinks.length > 2 ?
                                        <div className="previewSection">
                                            <div className='filerTitle'>{this.props.taxYearState.spouse_firstName}</div>

                                            <div className="previewHorizontalContentRTE">
                                                {previewLinks.length > 3 ? [previewLinks[2], previewLinks[3]] : previewLinks[2]}
                                            </div>
                                        </div> : null
                                    }
                                    <div style={{ width: '100%', marginBottom: '5%' }} >
                                        {this.props.taxYearState.ticket_type === 'RTE' && this.props.taxYearState.coupled && this.props.taxYearState.products_state.isSpouseEligible &&
                                            <PomSelection taxYearState={this.props.taxYearState} caseId={this.props.taxYearState.workflow_state.case_id} product='POM' filer='spouse' isAprroveBtnClicked={this.state.isAprroveBtnClicked} />}
                                    </div>
                                    { this.state.isAprroveBtnClicked && this.props.taxYearState.products_state.isSpouseEligible &&  this.props.taxYearState.products_state.hasSpouseOptedIn===null &&
                                    <div className="validationErrorStyle">
                                        <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#E70000', align: 'center' }} />
                                        <span style={{ marginLeft: 10 }}>{T.translate('ReviewAndApprove.POMSection.ReqValidationErrorMsg')}</span>
                                    </div> }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="authorizeContentStyle">
                        <T.span text={'ReviewAndApprove.Instructions.Rte' + `.${this.props.taxYearState.primary_province === 'QC' ? 'QC' : 'NonQC'}`} style={{ textAlign: 'left' }} />
                        <div style={{ height: 20 }} />
                    </div>
                </div>

                <div className="reviewBottomContainerCompletedRte" style={{ marginTop: 10 }}>
                    <PlainButton inverted={true} onClick={(e) => this.onMessageMyProClicked(e)} labelKey='ReviewAndApprove.MessagePro.RTE' isEnabled={true} />
                    <div style={{ width: '20px', height: '10px' }} />
                    <PlainButton inverted={false} onClick={(e) => this.onApproveClicked(e)}
                        labelKey={'ReviewAndApprove.Approve.' + key}
                        isEnabled={Boolean(this.props.approvalDocs)}
                        inProgress={this.state.processing}
                    />
                </div>
                <div style={{ height: 20 }} />
                <BaseModal show={this.state.missingFilesErrorVisible} onClose={() => this.setState({ missingFilesErrorVisible: false })} layoutType={this.props.layoutType} >
                    <InfoDialogContent
                        titleKey='ReviewAndApprove.MissingDocsError.Title'
                        labelKeys={['ReviewAndApprove.MissingDocsError.Label', 'ReviewAndApprove.MissingDocsError.SubLabel']}
                        onOK={() => { this.setState({ missingFilesErrorVisible: false }) }}
                        onCancel={() => { this.setState({ missingFilesErrorVisible: false }) }}
                        onCloseDialog={() => { this.setState({ missingFilesErrorVisible: false }) }}
                        isWarning={true}
                        faIconName='fa-exclamation'
                    />
                </BaseModal>
            </div>
        )
            :
            (
                <div className='review_spinner_container'>
                    <LoadingSpinner />
                </div>
            )
    }
}

const mapStateToProps = (state, ownProps) => {
    var taxYear = ''
    if (ownProps && ownProps.match && ownProps.match && ownProps.match.params) {
        taxYear = ownProps.match.params.taxYear
    }
    taxYear = Number(taxYear || state.config.appConfig.currentTaxYear)

    return {
        lang: state.layout.lang,
        layoutType: state.layout.layoutType,
        uploaderShowing: state.documents.uploaderShowing,
        viewerShowing: state.documents.viewerShowing,
        fileShowing: state.documents.fileShowing,
        taxYear,
        taxYearDocs: state.files.documentsForReview[taxYear],
        taxYearState: getTaxYearState(taxYear, state.workflow.case_state),
        approvalDocs: state.files.approvalDocuments[taxYear],
        signedDocuments: state.signing.signedDocuments,
        signingState: state.signing.signingState,
        signingError: state.signing.signingError,
        stage: state.approveDrafts.stage,
        showOteContent: state.workflow.is_direct_user === false,
        accessToken: state.auth.accessToken
    };
}

const mapDispatchToProps = (dispatch) => ({
    loadDocumentsForReview: (taxYear) => dispatch(FilesActions.loadDocumentsForReview(taxYear)),
    setDocumentsForReview: (taxYear, docs) => dispatch(FilesActions.setDocumentsForReview(taxYear, docs)),
    loadApprovalDocuments: (taxYear) => dispatch(FilesActions.loadApprovalDocuments(taxYear)),
    setApprovalDocuments: (taxYear, docs) => dispatch(FilesActions.setApprovalDocuments(taxYear, docs)),
    setApprovalComplete: (taxYear) => dispatch(ApproveDraftsActions.setApprovalComplete(taxYear)),
    replaceNavigation: (path) => dispatch(replace(path)),
    pushNavigation: (path) => dispatch(push(path)),
    setButtonState: (buttonName, buttonState) => dispatch(WorkflowActions.setButtonState(buttonName, buttonState)),
    setBoxDialogStates: (uploaderShowing, viewerShowing, fileShowing) => dispatch(DocumentActions.setBoxDialogStates(uploaderShowing, viewerShowing, fileShowing)),
    setInputScreenShowing: (showing) => dispatch(MessagesActions.setInputScreenShowing(showing)),
    setDashboardTab: (tab) => dispatch(LayoutActions.setDashboardTab(tab)),
    setDocumentForReviewViewed: (fileId, taxYear) => dispatch(FilesActions.setDocumentForReviewViewed(fileId, taxYear)),
    setButtonStates: (buttons) => dispatch(WorkflowActions.setButtonStates(buttons)),
    setCaseState: (state) => dispatch(WorkflowActions.setCaseState(state)),
    approveSelf: (isquebec) => dispatch(ApproveDraftsActions.approveSelf(isquebec)),
    approveSpouse: (isquebec) => dispatch(ApproveDraftsActions.approveSpouse(isquebec)),
    signExternalForm: (taxYear, formNames, formUrls, isSpouse, completedRoute, spouseFirstName, primaryFirstName) => dispatch(SigningActions.signExternalForm(taxYear, formNames, formUrls, isSpouse, completedRoute, spouseFirstName, primaryFirstName)),
    signingStateReset: () => dispatch(SigningActions.signingStateReset()),
    setApprovalStage: (stage) => dispatch(ApproveDraftsActions.setApprovalStage(stage))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviewDrafts))