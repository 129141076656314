import { put, select, call } from 'redux-saga/effects'
import MessagesActions from '../reducers/messagesRedux'

export const getMessages = state => state.messages
export const getAuth = state => state.auth
export const getLayout = state => state.layout
export const getPathName = state => state.router.location.pathname

export function* updateLastReadDate(api, action) {
    var auth = yield select(getAuth)
    yield call(api.setMessagesLastReadDate, auth.accessToken)
    yield put(MessagesActions.setUnreadMessagesCount(0))
}

export function* sendCurrentMessage(api, action) {
    var auth = yield select(getAuth)
    var messages = yield select(getMessages)

    if (messages.messageSendState === 'sending' || messages.messageSendState === 'retry') {
        var inProgressMessage = messages.messageInProgress
        var msgSendState = messages.messageSendState

        var response = yield call(api.sendMessage, auth.accessToken, inProgressMessage)
        if (response.ok) {
            yield put(MessagesActions.addMessage({
                commentText: inProgressMessage,
                isBlockComment: false,
                createdDate: new Date().getTime(),
                isNew: false // my own messages are never new
            }))
            yield put(MessagesActions.setMessageInProgress(''))
            yield put(MessagesActions.setMessageSendState(msgSendState === 'retry' ? 'retry_success' : 'complete'))
        } else {
            yield put(MessagesActions.setMessageSendState(msgSendState === 'retry' ? 'failed' : 'error'))
        }
    }   
}

export function* queryCaseMessages(api) {
    var auth = yield select(getAuth)
    if (!auth.accessToken) {
        return
    }

    var response = yield call(api.getCaseMessages, auth.accessToken)
    if (response.ok && response.data) {
        yield put(MessagesActions.setMessages(response.data.comments))
    }
}

export function* resetInputScreenVisibility(action) {
    if ((action.type === '@@router/LOCATION_CHANGE' && !action.payload.location.pathname.startsWith('/dashboard')) ||
        (action.type === 'SET_DASHBOARD_TAB' && action.tab !== 'messages')) {
        yield put(MessagesActions.setInputScreenShowing(false))
    }
}

export function* resetUnreadMessageCount(action) {
    var messages = yield select(getMessages)
    var layout = yield select(getLayout)
    var currentTab = layout.dashboardTab;
    var inputScreenShowing = messages.inputScreenShowing;

    if (action.type === 'SET_INPUT_SCREEN_SHOWING' && action.inputScreenShowing) {
        inputScreenShowing = true;
    } else if (action.type === 'SET_DASHBOARD_TAB' && action.tab === 'messages') {
        currentTab = 'messages'
    }

    if (currentTab === 'messages' && !inputScreenShowing) {
        yield put (MessagesActions.setUnreadMessagesCount(0))
    }
}

export function* sendInternalNoteNoPrinter(api, action) {
    var auth = yield select(getAuth)

    yield call(api.sendInternalNoteNoPrinter, auth.accessToken, action)    
}