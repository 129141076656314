import React from 'react';
import WelcomeBlock from './welcomeBlock';
import CreateNewReturnSection from './CreateNewReturnSection';
import PlainSecondaryButton from '../../Controls/plainSecondaryButton';
import './workflowpanel.css';

const DashboardHeader = ({
  firstName,
  firstLogin,
  showSecondaryButton,
  showCreateNewReturnSection,
  onClick,
  inProgress,
  virCustomer,
}) => {
  return (
    <div className={showSecondaryButton && showCreateNewReturnSection ? 'wf-wp-secondary-container' : ''}>
      <WelcomeBlock firstName={firstName} firstLogin={firstLogin} />
      {showSecondaryButton && showCreateNewReturnSection ? (
        <PlainSecondaryButton
          labelKey='WorkflowPanel.createNewReturn'
          onClick={onClick}
          inProgress={inProgress}
        />
      ) : showCreateNewReturnSection && !virCustomer ? (
        <CreateNewReturnSection
          onClick={onClick}
          inProgress={inProgress}
          virCustomer={virCustomer}
        />
      ) : null}
    </div>
  );
};

export default DashboardHeader;
