import classnames from 'classnames';
import { push } from 'connected-react-router';
import T from 'i18n-react';
import React, { useEffect } from 'react';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import headerImg from '../../images/landing.png';
import logoImg from '../../images/logo_small.png';
import LoginActions from '../../reducers/loginRedux';
import HenryLogo from '../Common/henryLogo';
import OuterContainer from '../Controls/outerContainer';
import PlainButton from '../Controls/plainButton';
import './style.css';

const HomePage = props => {

    const { layoutType, pushNavigation, requestLogin, lang, loaded } = props

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const onSignIn = () => {
        requestLogin()
    }

    const onContinue = () => {
        pushNavigation('/register')
    }

    const buttonContainerStyles = classnames('buttonContainer',
        { 'edgeMargin containerRightAlign bottomSpacing': layoutType !== 'mobile' },
        { 'containerCenterAlign lgBottomSpacing': layoutType === 'mobile' })

    const topButtonContainerStyles = classnames('buttonContainer lgBottomSpacing topSpacing',
        { 'hidden': layoutType !== 'mobile' },
        { 'containerCenterAlign': layoutType === 'mobile' })

    const overlayTextStyle = classnames('white',
        { 'hidden': layoutType !== 'desktop' })

    const overlayInfoStyle = classnames('white smallLeftSpacing smallPadding squishy',
        { 'hidden': layoutType !== 'mobile' })

    const footerTextStyle = classnames('topSpacing small',
        { 'hidden': layoutType !== 'mobile' })

    const bodyInfoStyle = classnames('centerAlign',
        { 'hidden': layoutType === 'mobile' },
        { 'titleMaxWidth squishy': layoutType !== 'mobile' })

    const logoContentContainer = classnames('logoContentContainer',
        { 'smallBottomSpacing': layoutType === 'mobile' },
        { 'logoTopSpacing': layoutType !== 'mobile' })

    const stepContainerStyle = classnames('stepContainer',
        { 'lgBottomSpacing': layoutType === 'mobile' })

    const stepTextStyle = classnames('centerAlign maxWidth',
        { 'stepHeight': layoutType !== 'mobile' })

    return (
        <OuterContainer loaded={loaded}>
            <div className={layoutType === 'mobile' ? "contentDivMobile" : "contentDiv"}>
                <div style={{ backgroundImage: `url('${headerImg}')` }}>
                    <div id="imageOverlay">
                        <div className="overlayTextStyle">
                            <p style={{ marginRight: 20, minHeight: layoutType === 'mobile' ? '5px' : '50px' }}>
                                <span className={overlayTextStyle}>{T.translate('Home.loginFlavour')}&nbsp;
                                    <span style={{ color: '#FFF', fontWeight: 700, cursor: 'pointer', textDecoration: 'underline' }} onClick={() => onSignIn()}>{T.translate('Common.SignIn')}</span>
                                </span>
                            </p>
                        </div>
                        <div className={logoContentContainer}>
                            <div className="overlayLogoStyle">
                                {layoutType !== 'mobile' ?
                                    <div className='desktop-logo'><HenryLogo width="300px" lang={lang} darkText={false} /></div> :
                                    <img style={{ marginLeft: 12, marginBottom: 5, marginRight: 10 }} width={60} height={60} src={logoImg} alt={T.translate('Home.hrbLogoAltText')} />
                                }
                            </div>
                            <h1 className={overlayInfoStyle}>{T.translate('Home.headerLabel')}</h1>
                        </div>
                    </div>
                </div>
                <div className="contentContainer">
                    <div className="contentContainerInner">
                        <div className="titleContainer">
                            <h1 className={bodyInfoStyle}>{T.translate('Home.headerLabel')}</h1>
                        </div>
                        <div className={layoutType === 'mobile' ? "stepsContainerMobile" : "stepsContainer"}>
                            <div className={topButtonContainerStyles}>
                                <PlainButton labelKey="Home.getStarted" isEnabled={true} onClick={() => onContinue()} />
                            </div>
                            <div className={stepContainerStyle}>
                                <FontAwesome className='fa-home' name='fa-home' size='5x' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#14AA40', align: 'top' }} />
                                <p className={stepTextStyle}>{T.translate('Home.step1Label')}</p>
                                <div className="stepTitleContainer">
                                    <h3>{T.translate('Home.step1Title')}</h3>
                                </div>
                            </div>
                            <div className={stepContainerStyle}>
                                <svg height="80px" viewBox="11 11 31 27">
                                    <g>
                                        <path fill="#14AA40" d="M41.2,25c-0.4,0-0.8-0.1-1.2-0.2c0.1-0.1,0.1-0.1,0.2-0.1c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3c-0.7-0.7-1.9-0.7-2.6,0c-0.7,0.7-0.7,1.9,0,2.6l0,0c-0.2,0.1-0.4,0.1-0.6,0.2c-0.7-3.5-3.9-6.8-9.5-6.8c-1.3,0-2.6,0.2-4.1,0.5c-1-1.1-2.5-1.7-4.4-1.7s-3.5,0.6-3.6,0.6c-0.2,0.1-0.3,0.2-0.4,0.4c0,0.2,0,0.4,0.2,0.5c1.2,1.2,1.9,2.9,2.1,3.6c-0.6,1-0.9,2-1,2.5l-2,0.5c-0.3,0.1-0.4,0.3-0.4,0.6v4.1c0,0.3,0.3,0.6,0.6,0.6c2.5,0.1,4.2,0.7,5,1.8c0.8,1.2,0.3,2.7,0.3,2.7c-0.1,0.2,0,0.4,0.1,0.5c0.1,0.2,0.3,0.2,0.5,0.2h3.4c0.2,0,0.4-0.1,0.5-0.2l1-1.4c0.5,0.1,1.4,0.2,2.4,0.2c0.6,0,1.1-0.1,1.6-0.2l0.4,1.2c0.1,0.2,0.3,0.4,0.5,0.4h4.1c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.1-0.5c0,0-0.4-2.1,0.4-4.1c1.3-1.3,1.9-2.9,1.9-4.5v-0.1c0.7-0.2,1.2-0.3,1.6-0.5c0.6,0.3,1.4,0.6,2.4,0.6l0,0c0.3,0,0.6-0.2,0.6-0.6C41.8,25.3,41.5,25,41.2,25z M30,28.3c0,1.3-0.9,2.4-2.2,2.7v0.7c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5V31c-1.3-0.3-2.2-1.4-2.2-2.7c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5c0,0.7,0.5,1.4,1.1,1.6v-3.3c-1.3-0.3-2.2-1.4-2.2-2.7c0-1.3,0.9-2.4,2.2-2.7v-0.7c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v0.7c1.3,0.3,2.2,1.4,2.2,2.7c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5c0-0.7-0.5-1.4-1.1-1.6v3.3C29,25.9,30,27,30,28.3z M39.3,23.8c0,0-0.2,0.1-0.6,0.4c-0.2-0.2-0.3-0.3-0.3-0.3l0,0c-0.3-0.3-0.3-0.7,0-1c0.1-0.1,0.3-0.2,0.5-0.2s0.4,0.1,0.5,0.2C39.5,23.1,39.5,23.5,39.3,23.8z" />
                                        <path fill="#14AA40" d="M26.6,25.5v-3.2c-0.7,0.2-1.1,0.9-1.1,1.6C25.5,24.6,26,25.3,26.6,25.5z" />
                                        <path fill="#14AA40" d="M27.8,26.7v3.2c0.7-0.2,1.1-0.9,1.1-1.6S28.4,27,27.8,26.7z" />
                                        <path fill="#14AA40" d="M27.4,16.8c2.5,0,4.7,0.6,6.3,1.6l0,0c0.3,0.2,0.7,0.4,1,0.7c0.4-0.7,0.6-1.5,0.6-2.3c0-2.6-2.1-4.6-4.6-4.6c-2.6,0-4.6,2.1-4.6,4.6v0.1C26.5,16.9,26.9,16.8,27.4,16.8C27.3,16.8,27.4,16.8,27.4,16.8z" />
                                    </g>
                                </svg>
                                <p className={stepTextStyle}>{T.translate('Home.step2Label')}</p>
                                <div className="stepTitleContainer">
                                    <h3>{T.translate('Home.step2Title')}</h3>
                                </div>
                            </div>
                            <div className={stepContainerStyle}>
                                <FontAwesome className='fa-clock-o' name='fa-clock-o' size='5x' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#14AA40', align: 'top' }} />
                                <p className={stepTextStyle}>{T.translate('Home.step3Label')}</p>
                                <div className="stepTitleContainer">
                                    <h3>{T.translate('Home.step3Title')}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={buttonContainerStyles}>
                        <PlainButton labelKey="Home.getStarted" isEnabled={true} onClick={() => onContinue()} />
                        <span className={footerTextStyle}>{T.translate('Home.loginFlavour')}&nbsp;
                            <b><span style={{ color: '#14AA40', cursor: 'pointer' }} onClick={() => onSignIn()}>{T.translate('Common.SignIn')}</span></b>
                        </span>
                    </div>
                </div>
            </div>
        </OuterContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        layoutType: state.layout.layoutType,
        lang: state.layout.lang
    }
}

const mapDispatchToProps = (dispatch) => ({
    pushNavigation: (path) => dispatch(push(path)),
    requestLogin: () => dispatch(LoginActions.requestLogin())
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)