import React, { useState } from 'react';
import '../../style.css';
import T from 'i18n-react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, IconButton, List, ListItem, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useAccordionStyles = makeStyles((theme) => ({
    accordion: {
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
        '&::before': {
            display: 'none',
        },
    },
    accordionSummary: {
        flexDirection: 'row-reverse',
        cursor: 'pointer',
        padding: 0,
        margin: '-10px 0 -25px 0',
    },
    arrowIcon: {
        color: 'var(--general-product-colours-functional-green, #007500) !important'
    },
    summaryText: {
        color: 'var(--general-product-colours-functional-green, #007500) !important',
        fontFamily: 'Roboto',
        fontSize: '12px !important',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        padding: '0px !important',
        margin: '0 0 0 -12px',
    },
    listItem: {
        padding: 0,
        listStyleType: 'disc',
        display: 'list-item',
        marginLeft: '25px',
        lineHeight: 1
    },
    listItemText: {
        lineHeight: '1 !important',
    },
}));

const PriceTierAccordion = ({PricingDetails}) => {
    const classes = useAccordionStyles();
    const [expandedAccordion, setExpandedAccordion] = useState(false);
    const handleAccordionChange = () => {
        setExpandedAccordion(!expandedAccordion);
    };

    return (
        <div className="price-tier-accordion-wrapper price-right-margin">
        <Accordion classes={{ root: classes.accordion }} expanded={expandedAccordion} onChange={handleAccordionChange} >
            <AccordionSummary classes={{ root: classes.accordionSummary }} expandIcon={<ExpandMoreIcon className={classes.arrowIcon} />}>
                <Typography  className={classes.summaryText}>
                    <IconButton edge="start" color="inherit" aria-label="menu">
                    </IconButton>
                    {expandedAccordion ? <T.span text='Interview.Page10a.HideDetails' /> : <T.span text='Interview.Page10a.SeeDetails' />}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List>
                    {PricingDetails.map((item, index) => (
                        <ListItem key={index} className={classes.listItem}>
                            <ListItemText primary={<Typography variant="body1" className={classes.listItemText}><T.span text={item} className="price-tier-details" /></Typography>} />
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
        </Accordion>
        </div>
    )
}

export default PriceTierAccordion;