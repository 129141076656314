import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import './header.css'
import BaseModal from '../../Modal/BaseModal'
import LayoutActions from '../../../reducers/layoutRedux'
import AuthActions from '../../../reducers/authRedux'
import LoginActions from '../../../reducers/loginRedux'
import Settings from '../../Settings/settings'
import HeaderDesktop from './headerDesktop'
import HeaderTablet from './headerTablet'
import HeaderMobile from './headerMobile'
import LogoutCTA from '../../Logout/LogoutCTA';

const HeaderMenuBar = props => {
    const stateLang = useSelector(state => state.layout.lang);
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
    const [signOutDialogOpen, setSignOutDialogOpen] = useState(false);

    useEffect(() => {
        if (!stateLang) {
            innerSetLanguage("en");
        }
    }, [])

    const handleSettingsClick = () => {
        props.setDialogOpen(true)
        setSettingsDialogOpen(true);
    }

    const closeDialog = (ev) => {
        props.setDialogOpen(false)
        setSettingsDialogOpen(false);
    }

    const closeSignOutDialog = (ev) => {
        props.setDialogOpen(false)
        setSignOutDialogOpen(false);
    }

    const proceedSignOut = (ev) => {
        props.setDialogOpen(false)
        setSignOutDialogOpen(false);
        props.logoutUser('logout')
    }

    const actionHandler = (action) => {
        switch (action) {
            case 'login': {
                props.requestLogin()
                break;
            }
            case 'logout': {
                props.setDialogOpen(true)
                setSignOutDialogOpen(true);
                break;
            }
            case 'home': {
                if (props.loginComplete) {
                    props.setDashboardTab('dashboard')
                    props.pushNavigation('/dashboard')
                } else {
                    props.pushNavigation('/home')
                }
                break;
            }
            case 'settings': {
                handleSettingsClick()
                break;
            }
            default: {
                break;
            }
        }
    }

    const innerSetLanguage = (lang) => {
        if (stateLang !== lang) {
            props.setDictLanguage(lang);
        }
    }

    var layoutType = props.layoutType
    var header = null
    var rootPage =  props.location.pathname === '/dashboard' ? 'dashboard' : ''

    if (layoutType === 'tablet' || layoutType === 'small_tablet') {
        header = (
            <HeaderTablet lang={props.lang} langHandler={(lang) => innerSetLanguage(lang)}
                buttonHandler={(action) => actionHandler(action)} loginComplete={props.loginComplete}
                rootPage={rootPage} layoutType={props.layoutType} appInitialized={props.appInitialized}
                showOteContent={props.showOteContent} pathname={props.location.pathname}
                loaded={props.loaded}
            />
        )
    } else if (layoutType === 'mobile') {
        header = (
            <HeaderMobile lang={props.lang} langHandler={(lang) => innerSetLanguage(lang)}
                buttonHandler={(action) => actionHandler(action)} loginComplete={props.loginComplete}
                rootPage={rootPage} layoutType={props.layoutType} appInitialized={props.appInitialized}
                loaded={props.loaded}
            />
        )
    } else {
        header = (<HeaderDesktop
            lang={props.lang} langHandler={(lang) => innerSetLanguage(lang)}
            buttonHandler={(action) => actionHandler(action)} loginComplete={props.loginComplete}
            rootPage={rootPage} layoutType={props.layoutType} appInitialized={props.appInitialized}
            showOteContent={props.showOteContent} pathname={props.location.pathname}
            loaded={props.loaded}
        />
        )
    }

    return (
        <div>
            {header}
            <div>
                <BaseModal show={settingsDialogOpen} layoutType={props.layoutType} onClose={() => {
                    closeDialog()
                }}>
                    <Settings onCloseDialog={(ev) => closeDialog(ev)} />
                </BaseModal>

                <BaseModal show={signOutDialogOpen} layoutType={props.layoutType} onClose={(event) => {
                    setSignOutDialogOpen(false)
                }}>
                    <LogoutCTA proceedSignOut={(ev) => proceedSignOut(ev)}
                        closeSignOutDialog={(ev) => closeSignOutDialog(ev)}
                        layoutType={props.layoutType}
                    />
                </BaseModal>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        layoutType: state.layout.layoutType,
        location: state.router.location,
        dashboardTab: state.layout.dashboardTab,
        lang: state.layout.lang,
        loginComplete: state.auth.loginComplete,
        appInitialized: state.layout.applicationInitialized,
        showOteContent: state.workflow.is_direct_user === false
    }
}

const mapDispatchToProps = (dispatch) => ({
    setDictLanguage: (lang) => dispatch(LayoutActions.setDictLanguage(lang)),
    setDashboardTab: (tab) => dispatch(LayoutActions.setDashboardTab(tab)),
    logoutUser: (signOutType) => dispatch(AuthActions.logoutUser(signOutType)),
    requestLogin: () => dispatch(LoginActions.requestLogin()),
    pushNavigation: (path) => dispatch(push(path)),
    setDialogOpen: (dialogOpen) => dispatch(LayoutActions.setDialogOpen(dialogOpen))
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenuBar)