import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import './shared.css'

import T from 'i18n-react';
import YesNoQuestion from '../Common/yesNoQuestion'
import ProvinceDropDown from '../Controls/DropDowns/provinceDropDown'
import LearnMore from '../Controls/learnMore'
import HighlightedSection from '../Interview/highlightedSection'
import GenderDropDown from '../Controls/DropDowns/genderDropDown'

class ResidenceInfoForm extends Component {
    constructor(props) {
        super(props)

        var residence = {
            ...this.props.residence
        }

        this.state = {
            residence,
            labelKey: this.props.labelKey,
            spouseFirstName: this.props.spouseFirstName,
            missingRequiredField: this.props.missingRequiredField,
            layoutType: this.props.layoutType,
            isReturningClient: this.props.isReturningClient,            
            missingRequiredSexField: this.props.missingRequiredSexField
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.residence !== this.state.residence) {
            this.setState({
                residence: newProps.residence
            })
        }

        if (newProps.spouseFirstName !== this.state.spouseFirstName) {
            this.setState({spouseFirstName: newProps.spouseFirstName})
        }

        if (newProps.missingRequiredField !== this.state.missingRequiredField) {
            this.setState({missingRequiredField: newProps.missingRequiredField})
        }

        if (newProps.layoutType !== this.state.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
        if (newProps.isReturningClient !== this.state.isReturningClient) {
            this.setState({ isReturningClient: newProps.isReturningClient })
        }

        if (newProps.missingRequiredSexField !== this.state.missingRequiredSexField) {
            this.setState({missingRequiredSexField: newProps.missingRequiredSexField})
        }
    }

    onResidencePropertyChanged = (event, value) => {
        let residence = {
            ...this.state.residence
        }
        residence[event.target.name] = value;
        this.setState({ residence });

        if (this.props.onResidenceChanged) {
            this.props.onResidenceChanged(residence)
        }
    };

    validateNameField(textValue) {
        return !textValue || textValue.match(/^[a-zA-Z\u00C0-\u017F\'\.\-\s]+$/)   // eslint-disable-line no-useless-escape
    }

    onYesNoButtonChange(ev, value) {
        var residence = {
            ...this.state.residence
        }

        if ((ev.target.name === 'canadianCitizenship') && (value === 'N'))
        { residence['authorizeCraElectionsCanada'] = '' }

        residence[ev.target.name] = value
        this.setState({ residence })
        if (this.props.onResidenceChanged) {
            this.props.onResidenceChanged(residence)
        }
    }

    handleProvinceChange(ev, prov) {
        var residence = {
            ...this.state.residence
        }
        residence[ev.target.name] = prov
        this.setState({ residence })

        //VAI-199 ensure first nations/inuit lands questions get reset
        if (ev.target.name === 'residenceProvince') {  // VAI-371 - only for the province of residence on dec 31
            if (prov !== 'BC' && residence.nisgaLands === 'Y') residence.nisgaLands = 'N'
            if (prov !== 'NT' && residence.taichoLands === 'Y') residence.taichoLands = 'N'
            if (prov !== 'YT' && residence.yukonLands === 'Y') residence.yukonLands = 'N'
            if (prov !== 'NL' && residence.nflLands === 'Y') residence.nflLands = 'N'
            if (prov !== 'QC' && residence.gender !== null) residence.gender = null
        }

        if (this.props.onResidenceChanged) {
            this.props.onResidenceChanged(residence)
        }
    }

    render() {
        let translationProps = {
            SpouseFirstName: this.state.spouseFirstName,
            TaxYear: this.props.taxYear
        }

        let bcContent = this.state.residence.residenceProvince === 'BC' ? (
            <div className="horizontalFieldContainerTopAligned largeTopMargin">
                <YesNoQuestion name="nisgaLands" value={this.state.residence.nisgaLands}
                    layoutType={this.state.layoutType}
                    labelText={T.translate(this.state.labelKey + ".fieldLabel1", translationProps)}
                    onAnswerChanged={(ev, value) => { this.onYesNoButtonChange(ev, value) }} />
            </div>
        ) : null;

        let nwtContent = this.state.residence.residenceProvince === 'NT' ? (
            <div className="horizontalFieldContainerTopAligned largeTopMargin">
                <YesNoQuestion name="taichoLands" value={this.state.residence.taichoLands}
                    layoutType={this.state.layoutType}                    
                    labelText={T.translate(this.state.labelKey + ".fieldLabel2", translationProps)}
                    onAnswerChanged={(ev, value) => { this.onYesNoButtonChange(ev, value) }} />
            </div>
        ) : null;

        let ykContent = this.state.residence.residenceProvince === 'YT' ? (
            <div className="horizontalFieldContainerTopAligned largeTopMargin">
                <YesNoQuestion name="yukonLands" value={this.state.residence.yukonLands}
                    layoutType={this.state.layoutType} learnMoreId="OTE_FAQ/OTEYukonFirstNationtaxcredit"
                    labelText={T.translate(this.state.labelKey + ".fieldLabel3", translationProps)}
                    onAnswerChanged={(ev, value) => { this.onYesNoButtonChange(ev, value) }} />
            </div>
        ) : null;

        let nflContent = this.state.residence.residenceProvince === 'NL' ? (
            <div className="horizontalFieldContainerTopAligned largeTopMargin">
                <YesNoQuestion name="nflLands" value={this.state.residence.nflLands}
                    layoutType={this.state.layoutType}
                    labelText={T.translate(this.state.labelKey + ".fieldLabel10", translationProps)}
                    onAnswerChanged={(ev, value) => { this.onYesNoButtonChange(ev, value) }} />
            </div>
        ) : null;
        
        let homeMailingSameContent = this.state.residence.homeAndMailingSame === 'N' ? (
            <div className="horizontalFieldContainerTopAligned largeTopMargin">
                <div className="fixed inlineLabelStyle">
                    <div className="formLabelStyleNoMargin">{T.translate(this.state.labelKey + ".fieldLabel6", translationProps)}</div>
                </div>
                <div className="defaultInputWidth">
                    <ProvinceDropDown name='currentResidenceProvince' value={this.state.residence.currentResidenceProvince} onValueChanged={(ev, value) => this.handleProvinceChange(ev, value)} />
                </div>
            </div>
        ) : null;

        let validationContent = (this.state.missingRequiredField && !this.state.residence.residenceProvince) ? (
            <div className="validationErrorStyle">
                <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#E70000', align: 'center' }} />
                <span style={{ marginLeft: 10 }}>{T.translate('Common.Errors.SelectAnAnswer')}</span>
            </div>
        ) : null;

        let canadianCitizenshipContent = this.state.residence.canadianCitizenship === 'Y' ? (
            <div className="horizontalFieldContainerTopAligned largeTopMargin"> 
                <YesNoQuestion name="authorizeCraElectionsCanada" value={this.state.residence.authorizeCraElectionsCanada}
                    layoutType={this.state.layoutType} isRequired={true} missingRequiredField={this.state.missingRequiredField && !this.state.residence.authorizeCraElectionsCanada && (this.state.residence.canadianCitizenship === 'Y')}
                    learnMoreId="Other/SharingYourInfoWithElectionsCanada"
                    labelText={T.translate(this.state.labelKey + ".fieldLabel9", translationProps)}
                    onAnswerChanged={(ev, value) => { this.onYesNoButtonChange(ev, value) }}/>
            </div>
        ) : null;

        let genderValidationContent = (this.state.missingRequiredSexField && this.state.residence.residenceProvince === 'QC' && !this.state.residence.gender) ? (
            <div className="validationErrorStyle">
                <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#E70000', align: 'center' }} />
                <span style={{ marginLeft: 10 }}>{T.translate('Common.Errors.SelectAnAnswer')}</span>
            </div>
        ) : null;
        
        let qcContent = this.state.residence.residenceProvince === 'QC' ? (
            <div className="horizontalFieldContainerTopAligned largeTopMargin">
            <div className="fixed inlineLabelStyle">
                <div className="formLabelStyleNoMargin">
                    <span>
                        {T.translate(this.state.labelKey + ".fieldLabel11", translationProps)}*
                        <span style={{marginRight: '10px'}} />
                    </span>
                </div>
            </div>
            <div className="defaultInputWidth">
                <GenderDropDown name='gender' lang={this.state.lang} value={this.state.residence.gender}
                isRequired={true} missingRequiredField={this.state.missingRequiredSexField}
                onValueChanged={(ev, value) => this.onResidencePropertyChanged(ev, value)} />
                {genderValidationContent}
             </div>
         </div>
        ) : null;

        return (
            <div className="residence-info-margin">
            <HighlightedSection isReturningClient={this.state.isReturningClient} additionalClasses="interview-green-overflow-margin" margin={20}>
                <div className="horizontalFieldContainerTopAligned largeTopMargin">
                    <div className="fixed inlineLabelStyle">
                        <div className="formLabelStyleNoMargin">
                            <span>
                                {T.translate(this.state.labelKey + ".fieldLabel0", translationProps)}*
                                <span style={{marginRight: '10px'}} />
                                <LearnMore linkId="Other/WhatProvinceDidYouLiveInOnDec31" />
                            </span>
                        </div>
                    </div>
                    <div tabIndex="0" className="defaultInputWidth">
                            <ProvinceDropDown missingRequiredField={this.state.missingRequiredField && !this.state.residence.residenceProvince} name='residenceProvince' value={this.state.residence.residenceProvince} onValueChanged={(ev, value) => this.handleProvinceChange(ev, value)} />
                        {validationContent}
                    </div>
                </div>
                {bcContent}
                {nwtContent}
                {ykContent}
                {nflContent}
                {qcContent}
                <div className="horizontalFieldContainerTopAligned largeTopMargin">
                    <YesNoQuestion name="provinceTerritoryChange" value={this.state.residence.provinceTerritoryChange}
                            layoutType={this.state.layoutType} isRequired={true} missingRequiredField={this.state.missingRequiredField && !this.state.residence.provinceTerritoryChange}
                        labelText={T.translate(this.state.labelKey + ".fieldLabel4", translationProps)}
                        onAnswerChanged={(ev, value) => { this.onYesNoButtonChange(ev, value) }} />
                </div>
                <div className="horizontalFieldContainerTopAligned largeTopMargin">
                    <YesNoQuestion name="homeAndMailingSame" value={this.state.residence.homeAndMailingSame}
                        layoutType={this.state.layoutType}
                        learnMoreId="OTE_FAQ/Whatthedifferencebetweenhomeaddressmailingaddress"
                        labelText={T.translate(this.state.labelKey + ".fieldLabel5", translationProps)}
                        onAnswerChanged={(ev, value) => { this.onYesNoButtonChange(ev, value) }} />
                </div>
                {homeMailingSameContent}
                <div className="horizontalFieldContainerTopAligned largeTopMargin">
                    <YesNoQuestion name="immigrateCanada" value={this.state.residence.immigrateCanada}
                            layoutType={this.state.layoutType} isRequired={true} missingRequiredField={this.state.missingRequiredField && !this.state.residence.immigrateCanada}
                        learnMoreId="OTE_FAQ/OTEDidyouImmigrateToCanadaBecomeResidentInTY"
                        labelText={T.translate(this.state.labelKey + ".fieldLabel7", translationProps)}
                        onAnswerChanged={(ev, value) => { this.onYesNoButtonChange(ev, value) }} />
                </div>
                <div className="horizontalFieldContainerTopAligned largeTopMargin">
                    <YesNoQuestion name="canadianCitizenship" value={this.state.residence.canadianCitizenship}
                            layoutType={this.state.layoutType} isRequired={true} missingRequiredField={this.state.missingRequiredField && !this.state.residence.canadianCitizenship}
                        learnMoreId="Other/AreYouACanadianCitizen"
                        labelText={T.translate(this.state.labelKey + ".fieldLabel8", translationProps)}
                        onAnswerChanged={(ev, value) => { this.onYesNoButtonChange(ev, value) }} />
                </div>                
                    {canadianCitizenshipContent}                
</HighlightedSection>
            </div>
        )
    }
}

export default ResidenceInfoForm;