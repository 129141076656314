import React, { Component } from 'react';
import '../Common/shared.css'
import T from 'i18n-react';
import TaxScenarioTile from './Pages/controls/TaxScenarioTile'
import HighlightedSection from '../Interview/highlightedSection'
import { connect } from 'react-redux';
import Scenario1 from '../../images/TaxScenarios/Scenario1.svg'
import Scenario2 from '../../images/TaxScenarios/Scenario2.svg'
import Scenario3 from '../../images/TaxScenarios/Scenario3.svg'
import Scenario4 from '../../images/TaxScenarios/Scenario4.svg'
import Scenario5 from '../../images/TaxScenarios/Scenario5.svg'
import Scenario6 from '../../images/TaxScenarios/Scenario6.svg'
import Scenario7 from '../../images/TaxScenarios/Scenario7.svg'
import Scenario8 from '../../images/TaxScenarios/Scenario8.svg'
import Scenario9 from '../../images/TaxScenarios/Scenario9.svg'
import Scenario10 from '../../images/TaxScenarios/Scenario10.svg'
import Scenario11 from '../../images/TaxScenarios/Scenario11.svg'
import Scenario12 from '../../images/TaxScenarios/Scenario12.svg'
import Scenario13 from '../../images/TaxScenarios/Scenario13.svg'
import Scenario14 from '../../images/TaxScenarios/Scenario14.svg'
import Scenario15 from '../../images/TaxScenarios/Scenario15.svg'
import Scenario16 from '../../images/TaxScenarios/Scenario16.svg'
import Scenario17 from '../../images/TaxScenarios/Scenario17.svg'
import Scenario18 from '../../images/TaxScenarios/Scenario18.svg'
import Scenario19 from '../../images/TaxScenarios/Scenario19.svg'
import Scenario20 from '../../images/TaxScenarios/Scenario20.svg'

class TaxYearForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            taxYearInfo: this.props.taxYearInfo || {
                employedChecked: false,
                selfEmployedChecked: false,
                retiredChecked: false,
                businessOwnerChecked: false,
                workExpensesChecked: false,
                disabilityChecked: false,
                medicalExpensesChecked: false,
                benefitsEIChecked: false,
                investmentIncomeChecked: false,
                rRSPChecked: false,
                rentalChecked: false,
                foreignIncomeChecked: false,
                dependentsChecked: false,
                propertySaleChecked: false,
                spouseSupportChecked: false,
                movingExpensesChecked: false,
                tuitionChecked: false,
                donationsChecked: false,
                fireRescueChecked: false,
                farmingChecked: false
            },
            labelKey: this.props.labelKey,
            layoutType: this.props.layoutType,
            isReturningClient: this.props.isReturningClient,
            taxYear: this.props.taxYear
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.taxYear !== this.state.taxYear) {
            this.setState({
                taxYear: newProps.taxYear
            })
        }
        if (newProps.layoutType !== this.state.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
    }

    handleTaxYearPropertyChanged = (event, value) => {
        let taxYearInfo = {
            ...this.state.taxYearInfo
        }
        taxYearInfo[event.target.name] = value;
        this.setState({ taxYearInfo });

        if (this.props.onTaxYearInfoChanged) {
            this.props.onTaxYearInfoChanged(taxYearInfo)
        }
    };

    render() {

        return (
            <HighlightedSection {...this.props}>
                <div style={{ margin: 0 }}>
                    {/* Income section */}
                    <div className='horizontalFieldContainerTopAligned taxscenario-header'>
                        <T.text tag='p' text={this.props.sectionHeader1} />
                    </div>
                    <div className='horizontalFieldContainerTopAligned taxscenario-icons'>
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel1')}
                            name="employedChecked" checked={this.state.taxYearInfo.employedChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario1} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip1')} />
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel2')}
                            name="selfEmployedChecked" checked={this.state.taxYearInfo.selfEmployedChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario2} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip2')}
                            mobileLeftTooltip={true} />
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel3')}
                            name="retiredChecked" checked={this.state.taxYearInfo.retiredChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario3} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip3')}
                            largeTooltip={true} />
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel4')}
                            name="businessOwnerChecked" checked={this.state.taxYearInfo.businessOwnerChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario4} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip4')}
                            leftTooltip={true} />
                    </div>
                    {/* Expenses, benefits, & credits section */}
                    <div className='horizontalFieldContainerTopAligned taxscenario-header'>
                        <T.text tag='p' text={this.props.sectionHeader2} />
                    </div>
                    <div className='horizontalFieldContainerTopAligned taxscenario-icons'>
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel5')}
                            name="workExpensesChecked" checked={this.state.taxYearInfo.workExpensesChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario5} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip5')} />
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel6')}
                            name="disabilityChecked" checked={this.state.taxYearInfo.disabilityChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario6} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip6')}
                            mobileLeftTooltip={true} />
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel7')}
                            name="medicalExpensesChecked" checked={this.state.taxYearInfo.medicalExpensesChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario7} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip7')} />
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel8')}
                            name="benefitsEIChecked" checked={this.state.taxYearInfo.benefitsEIChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario8} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip8')}
                            leftTooltip={true} />
                    </div>
                    {/* Investments, rental & foreign income section */}
                    <div className='horizontalFieldContainerTopAligned taxscenario-header'>
                        <T.text tag='p' text={this.props.sectionHeader3} />
                    </div>
                    <div className='horizontalFieldContainerTopAligned taxscenario-icons'>
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel9')}
                            name="investmentIncomeChecked" checked={this.state.taxYearInfo.investmentIncomeChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario9} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip9')} />
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel10')}
                            name="rRSPChecked" checked={this.state.taxYearInfo.rRSPChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario10} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip10')}
                            mobileLeftTooltip={true} />
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel11')}
                            name="rentalChecked" checked={this.state.taxYearInfo.rentalChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario11} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip11')} />
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel12')}
                            name="foreignIncomeChecked" checked={this.state.taxYearInfo.foreignIncomeChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario12} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip12')}
                            leftTooltip={true} />
                    </div>
                    {/* Home & dependants section */}
                    <div className='horizontalFieldContainerTopAligned taxscenario-header'>
                        <T.text tag='p' text={this.props.sectionHeader4} />
                    </div>
                    <div className='horizontalFieldContainerTopAligned taxscenario-icons'>
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel13')}
                            name="dependentsChecked" checked={this.state.taxYearInfo.dependentsChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario13} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip13')} />
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel14')}
                            name="propertySaleChecked" checked={this.state.taxYearInfo.propertySaleChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario14} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip14')}
                            mobileLeftTooltip={true} />
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel15')}
                            name="spouseSupportChecked" checked={this.state.taxYearInfo.spouseSupportChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario15} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip15')} />
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel16')}
                            name="movingExpensesChecked" checked={this.state.taxYearInfo.movingExpensesChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario16} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip16')}
                            leftTooltip={true} />
                    </div>
                    {/* Other section */}
                    <div className='horizontalFieldContainerTopAligned taxscenario-header'>
                        <T.text tag='p' text={this.props.sectionHeader5} />
                    </div>
                    <div className='horizontalFieldContainerTopAligned taxscenario-icons'>
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel17')}
                            name="tuitionChecked" checked={this.state.taxYearInfo.tuitionChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario17} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip17')} />
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel18')}
                            name="donationsChecked" checked={this.state.taxYearInfo.donationsChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario18} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip18')}
                            mobileLeftTooltip={true} />
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel19')}
                            name="fireRescueChecked" checked={this.state.taxYearInfo.fireRescueChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario19} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip19')} />
                        <TaxScenarioTile label={T.translate('Interview.Page9&10Common.scenarioLabel20')}
                            name="farmingChecked" checked={this.state.taxYearInfo.farmingChecked}
                            onCheckChanged={(ev, value) => this.handleTaxYearPropertyChanged(ev, value)}
                            ScenarioImg={Scenario20} tooltipText={T.translate('Interview.Page9&10Common.scenarioTooltip20')}
                            leftTooltip={true} />
                    </div>
                </div>
            </HighlightedSection>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.layout.lang,
        showLinks: !state.workflow.is_direct_user
    }
};

export default connect(mapStateToProps)(TaxYearForm);       