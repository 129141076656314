/* eslint-disable-next-line */
import React, { Component, Fragment } from 'react';
import './style.css';
import 'react-toastify/dist/ReactToastify.css';
import FontAwesome from 'react-fontawesome';
import WorkflowAPI from '../../services/workflowApis'
import T from 'i18n-react';
import ValidatingTextInput from '../Common/validatingTextInput'
import SimpleLabelledCheckBox from '../Common/simpleLabelledCheckBox'
import PlainButton from '../Controls/plainButton'
import { ToastContainer, toast, Slide } from 'react-toastify'
import OuterContainer from '../Controls/outerContainer';

class DirectPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            working: false,
            directLink: null,
            email: '',
            new_customer: false,
            show_new_customer: false,
            is_valid: false,
            directConfig: {
                show_new_customer_option: false,
                can_skip_confirm_id: true,
                can_skip_authorize: true
            }
        }
    }

    UNSAFE_componentWillMount()
    {
        window.scrollTo(0, 0);
        WorkflowAPI.create().getDirectCodeConfig().then((response) => {
            if (response && response.ok && response.data) {
                var config = response.data;
                var showNew = config.show_new_customer_option && (config.can_skip_confirm_id || config.can_skip_authorize)
                this.setState({ directConfig: config, show_new_customer: showNew, loading: false })
            } else {
                this.setState({ loading: false })
            }
        }, (error) => {
            this.setState({ loading: false })
        });
    }

    copyToClipboard () {
        const el = document.createElement('textarea');
        el.value = this.state.directLink;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toast.success(T.translate('ToastLabels.CopiedToClipboard'));
    };

    isValidEmail(email) {
        var reChars = /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~.@]+$/  // eslint-disable-line no-useless-escape
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape
        if (re.test(email)) {
            if (reChars.test(email)) {
                if (email.toLowerCase().includes("@hrblock.ca")) {
                    return true
                }
            }
        }
        return false
    }

    handleEmailChange = (event) => {
        this.setState({ ...this.state, email: event.target.value, is_valid: Boolean(event.target.value) && this.isValidEmail(event.target.value) })
    };

    handleCheckboxCheck = (t, val) => {
        this.setState({ ...this.state, new_customer: val })
    }

    handleGenerateClick() {
        if (!this.state.is_valid) {
            return;
        }
        this.setState({ working: true })

        var newCustomer = this.state.new_customer && this.state.show_new_customer;

        WorkflowAPI.create().createDirectCode(this.state.email, newCustomer).then((response) => {
            this.setState({ working: false, is_valid: false })
            this.setState({ directLink: response.data.directLink })
        }, (error) => {
            this.setState({ working: false })
        })
    }

    render() {
        var titleTextKeyBase = "Direct.Title"
        var message1 = T.translate("Direct.Subtitle")
        var message2 = T.translate("Direct.Subtitle2")
        var messageLink = T.translate("Direct.DirectSubtitle")
        var messageNew1 = T.translate("Direct.DirectSubtitleNew1")
        var messageNew2 = T.translate("Direct.DirectSubtitleNew2")
        var linkMessage = T.translate("Direct.DirectLink", { Email: this.state.email })

        var iconContent = null
        if (this.state.layoutType !== 'mobile') {
            iconContent = (<FontAwesome className='fa-key' name='fa-key' size='2x' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#14AA40', align: 'top' }} />)
        }
        return (
            <OuterContainer sizeToContent>
                {this.state.working || this.state.loading ?
                    <div style={{ display: 'flex', minHeight: 300, flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                        <div >
                            <FontAwesome className='fa-spinner' name='spinner' pulse size='3x' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#14AA40' }} />
                        </div>
                    </div>
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: '100%', justifyContent: 'left' }}>
                        <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, fontSize: '24px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            {iconContent}
                            <span style={{ marginLeft: iconContent != null ? 10 : 0, fontWeight: 'bold' }}>
                                {T.translate(titleTextKeyBase)}
                            </span>
                        </div>
                        <br />

                        <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'stretch', marginRight: '20px' }}>
                            {this.state.directLink == null &&
                                <p style={{ marginTop: 0, marginBottom: 10 }}>
                                    {message1}
                                </p>
                            }
                            {this.state.directLink != null && !this.state.new_customer &&
                                <p style={{ marginTop: 0, marginBottom: 10 }}>
                                    {messageLink}
                                </p>
                            }
                            {this.state.directLink != null && this.state.new_customer &&
                                <p style={{ marginTop: 0, marginBottom: 10 }}>
                                    {messageNew1}
                                </p>
                            }
                            {this.state.directLink == null &&
                                <p style={{ marginTop: 10, marginBottom: 0 }}>
                                    {message2}
                                </p>
                            }
                            {this.state.directLink != null && this.state.new_customer &&
                                <p style={{ marginTop: 10, marginBottom: 0, paddingLeft: '40px', paddingRight: '40px' }}>
                                    {messageNew2}
                                </p>
                            }
                        </div>
                        <br />
                        {!this.state.directLink &&
                            <Fragment>
                                <div style={{ marginLeft: 20, marginBottom: 10, display: 'flex', flexDirection: 'column', maxWidth: '50%' }}>
                                    <ValidatingTextInput labelText={T.translate("Direct.Email")}
                                        autoComplete="email" heapIgnore="true"
                                        value={this.state.email} placeholderText={T.translate('Direct.Placeholder')} defaultText={this.state.email} validationFailedText={T.translate("Direct.InvalidEmail")}
                                        validator={(text) => { return this.isValidEmail(text) }} onChange={this.handleEmailChange} />
                                </div>
                                {this.state.show_new_customer &&
                                    <div style={{ marginLeft: 20, marginBottom: 20, display: 'flex', flexDirection: 'column' }}>
                                        <SimpleLabelledCheckBox isChecked={this.state.new_customer}
                                            label={T.translate('Direct.NewCustomerLabel')}
                                            onCheck={this.handleCheckboxCheck}
                                        />
                                    </div>
                                }
                                <div style={{ marginLeft: 20, marginBottom: 10, alignSelf: 'flex-start' }} >
                                    <PlainButton onClick={(ev) => this.handleGenerateClick(ev)} labelKey='Direct.GenerateLink' isEnabled={Boolean(this.state.is_valid)} />
                                </div>
                            </Fragment>
                        }
                        {this.state.directLink &&
                            <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'stretch', marginRight: '20px' }}>
                                <div style={{ marginTop: 10, marginBottom: 0, marginLeft: 0, paddingLeft: 10, paddingTop: 8, paddingBottom: 8, borderLeft: '3px solid #14AA40' }}>
                                    <span>
                                        <span style={{ marginRight: 10 }}>{this.state.directLink}</span>
                                        <span style={{ cursor: 'pointer' }} onClick={() => this.copyToClipboard()}>
                                            <FontAwesome className='fa-copy' name='fa-copy' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#14AA40', align: 'top' }} />
                                        </span>
                                        <span style={{ marginLeft: 20 }}>{linkMessage}</span>
                                    </span>
                                </div>
                                <p style={{ marginTop: 30 }}>
                                    <a style={{ cursor: 'pointer' }} onClick={() => this.setState({ ...this.state, directLink: null, email: '', new_customer: false })}>{T.translate('Direct.Reset')}</a>
                                </p>
                            </div>
                        }
                        <div style={{ marginBottom: '20px' }} />
                        <ToastContainer position="top-center"
                            toastClassName='direct-black-toast'
                            transition={Slide}
                            autoClose={2000}
                            hideProgressBar
                            newestOnTop
                            closeOnClick
                            rtl={false}
                            pauseOnVisibilityChange
                            draggable
                            pauseOnHover />
                    </div>
                }
            </OuterContainer>
        )
    }
}

export default DirectPage;
