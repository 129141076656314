import React from 'react';
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';
import './sidebar.css'

const PrivacyBlock = () => (
    <div className='wf-wb-privacy-container'>
        <FontAwesome className="fa-lock" name="fa-lock" size="2x" style={{ color: "#ffffff" }} />
        <span>{T.translate('Dashboard.labels.SecurityMessage')}</span>
    </div>
);

export default PrivacyBlock;
