import api from '../../../../services/Api'
import { ApiPaths } from './ApiPaths'

/* Service class used to perform api calls for messaging */
class DoxExplorerCDMServices {

    formatFileSize(fileSizeBytes) {
        var ss = '-'
        if (fileSizeBytes) {
            if (fileSizeBytes < 1024) {
                ss = `${fileSizeBytes} bytes`
            } else if (fileSizeBytes < 1024 * 1024) {
                ss = `${Math.round(fileSizeBytes / 1024)} KB`
            } else {
                ss = `${Math.round(fileSizeBytes / (1024 * 1024))} MB`
            }
        }
        return ss;
    }

    getTruncatedFileName(fileName, fileType, isMobile) {
        var nameLen = (fileName || '').length
        var extLen = (fileType || '').length
        var maxLen = isMobile ? 28 : 38;
        if (nameLen + extLen >= maxLen) {
            return (fileName || '').substring(0, maxLen - extLen - 3) + '...' + (fileType || '');
        } else {
            return `${fileName || ''}${fileType || ''}`
        }
    }

    getLabelKeyForUploader(uploaderIsBlock) {
        return uploaderIsBlock === true
            ? 'Dox.DocumentExplorer.Block'
            : (uploaderIsBlock === false
                ? 'Dox.DocumentExplorer.You'
                : null
            )
    }

    /**
    * Retrieves the specified file data
     * @param {Function} handleUnauthorized
     * @param {string} accessToken
     * @param {string|number} taxYear
    */
    async getFileList(handleUnauthorized, accessToken, taxYear) {
        let fileList = []

        let status = true;
        let pageIndex = 1;

        while (pageIndex && status) {
            let response = await new api([], handleUnauthorized).get(accessToken, `${ApiPaths.CDM}?taxYear=${taxYear}&page=${pageIndex}`)
            if (response) {
                if (response.status === 200) {
                    fileList = fileList.concat(response.data.items || [])
                    if (response.data.hasMoreItems) {
                        ++pageIndex
                    } else {
                        break;
                    }
                } else if (response.status === 204) {
                    break;
                } else {
                    status = false
                }
            } else {
                status = false
            }
        }

        return { status, fileList }
    }

    /**
     * Retrieves the specified file data, then sorts it by the given params
     * @param {Function} handleUnauthorized
     * @param {string} accessToken
     * @param {string|number} taxYear
     * @param {string} sortColumn
     * @param {boolean} sortDirection
     */
    async getSortedFileList(handleUnauthorized, accessToken, taxYear, sortColumn, sortDirection) {
        let { status, fileList } = await this.getFileList(handleUnauthorized, accessToken, taxYear, sortColumn, sortDirection)

        if (status) {
            fileList = this.sortFileList(fileList, sortColumn, sortDirection);
        }

        return { status, fileList }
    }

    sortFileList = (file_list, sortColumn, sortDirection) => {
        let fileList = file_list;
        if (sortColumn === 'name') {
            fileList = fileList.sort((a, b) => (a.fileName || '').localeCompare(b.fileName))
        } else if (!sortColumn || sortColumn === 'modified') {
            fileList = fileList.sort((a, b) => -(new Date(a.modifiedTs).getTime() - new Date(b.modifiedTs).getTime()));
        } else if (sortColumn === 'size') {
            fileList = fileList.sort((a, b) => -((a.fileSize || 0) - (b.fileSize || 0)))
        } else if (sortColumn === 'uploader') {
            fileList = fileList.sort((a, b) => (a.uploaderName || '').localeCompare(b.uploaderName || ''))
        }

        if (sortDirection) {
            fileList = fileList.reverse();
        }
        return [...fileList];
    }

    /**
     * Retrieves the specified file data
     * @param {any} accessToken
     * @param {any} documentId
     */
    async deleteFile(handleUnauthorized, accessToken, documentId) {
        if (!documentId) {
            return false
        }
        let response = await new api([], handleUnauthorized).delete(accessToken, `${ApiPaths.CDM}/${documentId}`)
        if (response && response.status === 200 && response.data.isSuccessful) {
            return true
        } else {
            return false
        }
    }

    /**
     * Retrieves the specified file data
     * @param {any} accessToken
     * @param {any} documentId
     * @param 
     */
    async updateFileMetadata(handleUnauthorized, accessToken, documentId, request) {
        if (!documentId) {
            return false
        }
        var response = await new api([], handleUnauthorized).put(accessToken, `${ApiPaths.CDM}/${documentId}`, request)
        if (response && response.status === 200 && response.data.isSuccessful) {
            return true
        } else {
            return false
        }
    }

     /**
     * Retrieves the specified file data
     * @param {any} accessToken
     * @param {any} documentId
     */
     async downloadFile(accessToken, documentId) {
        let response = await new api([]).get(accessToken, `${ApiPaths.CDM}/${documentId}`)
            
        return response;
    }
}

const doxExplorerCDMServices = new DoxExplorerCDMServices();
export default doxExplorerCDMServices;