export const AcceptedFileExts = [
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.csv',
    '.txt',
    '.pdf',
    '.jpg',
    '.jpeg',
    '.tif',
    '.tiff',
    '.png',
    '.bmp',
    '.gif',
    '.rtf',
    '.numbers',
    '.pages'
]

export const AcceptedMimeTypes = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
    'text/plain',
    'application/pdf',
    'image/jpeg',
    'image/jpeg',
    'image/tiff',
    'image/tiff',
    'image/png',
    'image/bmp',
    'image/gif',
    'application/rtf',
    'application/x-iwork-numbers-sffnumbers',
    'application/x-iwork-pages-sffpages'
]

export const fileTypeWillOpen = (fileType) => {
    let ft = (fileType || '').toLowerCase()
    return ['.pdf', '.txt', '.jpg', '.jpeg', '.tif', '.tiff', '.png', '.bmp', '.gif'].includes(ft)
}

// The capture method of EAF documents is tagged as generated. However, those migrated "Customer Interview" documents are just tagged as uploaded by client. 
// Therefore, we need to check the file name in both English and French.
export const NeededFileKeys = ['customer interview', 'entrevue avec le client']

export const isNeededFile = (file) => {
    return NeededFileKeys.some(key => ((file || {}).fileName.toLowerCase() || '').includes(key))
}