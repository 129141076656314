/* eslint-disable-next-line */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AuthActions from '../../reducers/authRedux'
import LoadingPage from '../Loading/loadingPage'

const LoginCallback = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(AuthActions.handleLoginCallback())
    }, [])

    return (<LoadingPage />)
}

export default LoginCallback