import React, { Component } from 'react';
import '../../Common/shared.css'
import '../style.css';

import T from 'i18n-react';
import InterviewTitleProgress from '../../Common/interviewTitleProgress'

class InterviewPage11 extends Component {
    constructor(props) {
        super(props)

        this.state = {
            notes: this.props.notes || '',
            lang: this.props.lang
        }
    }

    onTextChange(ev) {
        let notes = {
            ...this.state.notes
        }
        notes = ev.target.value;
        this.setState({ notes });

        if (this.props.onNotesChanged) {
            this.props.onNotesChanged(notes)
        }
    }

    componentWillReceiveProps(newProps) {        
        if (this.state.notes !== newProps.notes) {
            this.setState({ notes: newProps.notes })
        }
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }
    }

    render() {
        var percentage = '90'
        if (this.props.saving === true) {
            percentage = '100'
        }

        return (
            <div>
                <div style={{ margin: 'inherit 20px' }}>
                    <div>
                        <InterviewTitleProgress taxYear={this.props.taxYear} percentage={percentage} title="Interview.Page11.headerTitle" noNextTitle="true" />
                    </div>
                    <div className= "interview-page11-top-margin">
                        <T.text tag='p' text="Interview.Page11.headerContent0" className="headerText" />
                    </div>
                </div>
                <div className="defaultStyles">
                    <textarea style={{ fontSize: '16px' }} onChange={(ev) => this.onTextChange(ev)} value={this.state.notes} />
                </div>
            </div>
        )
    }
}


export default InterviewPage11;
