import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome';
import './shared.css'
import YesNoCheckButtons from './yesNoCheckButtons'
import T from 'i18n-react'
import LearnMore from '../Controls/learnMore'

export default class YesNoQuestion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isRequired: this.props.isRequired,
            name: this.props.name,
            value: this.props.value,
            labelText: this.props.labelText,
            missingRequiredField: this.props.missingRequiredField,
            layoutType: this.props.layoutType,
            learnMoreId: this.props.learnMoreId,
            suppressSpacer: this.props.suppressSpacer
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.labelText !== this.state.labelText) {
            this.setState({ labelText: newProps.labelText });
        }
        if (newProps.value !== this.state.value) {
            this.setState({ value: newProps.value });
        }
        if (newProps.missingRequiredField !== this.state.missingRequiredField) {
            this.setState({ missingRequiredField: newProps.missingRequiredField });
        }
        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
        if (this.state.learnMoreId !== newProps.learnMoreId) {
            this.setState({ learnMoreId: newProps.learnMoreId })
        }
        if (this.state.suppressSpacer !== newProps.suppressSpacer) {
            this.setState({ suppressSpacer: newProps.suppressSpacer })
        }
    }

    render() {
        const labelClass = this.props.autoWidth ? 'inlineLabelStyle' : 'fixed inlineLabelStyle';
        var isMobile = this.state.layoutType === 'mobile'
        let yesNoStylishness = this.state.missingRequiredField ? 'validationError yesNoQuestionStyle' : 'yesNoQuestionStyle';
        yesNoStylishness += Boolean(this.props.centerAlignThis) ? ' yesNoCenterAligned' : '';
        var spacerSpanStyle = this.state.learnMoreId && !this.state.suppressSpacer ? "span_spacer_right" : "hidden"
        let validationContent = this.state.missingRequiredField  ? 
            <div className="validationErrorStyle">
                <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#E70000', align: 'center' }} />
                <span style={{ marginLeft: 10 }}>{T.translate('Common.Errors.ChooseAnOption')}</span>
            </div> : null;

        return (
            <div className="yesNoQuestionContainerStyle">
                <div className={yesNoStylishness}>
                    <div className={labelClass}>
                        <span className="checkboxTextStyle">
                            {this.state.labelText + (this.state.isRequired ? "* " : "")}
                            <span className={spacerSpanStyle} />
                            <LearnMore linkId={this.state.learnMoreId} />
                        </span>
                    </div>
                    <div className="yesNoButtonContainer">
                        <div className="yesNoButtonStyle">
                            <YesNoCheckButtons name={this.state.name} value={this.state.value}
                                               onStateChanged={(ev, value) => { this.props.onAnswerChanged(ev, value) }} />
                        </div>
                        {isMobile ? null : validationContent}
                    </div>
                </div>
                {isMobile ? validationContent : null}
            </div>
        );
    }
}