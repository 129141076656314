import React, { Component } from 'react';
import '../Common/shared.css'
import './style.css';
import T from 'i18n-react';
import PlainButton from '../Controls/plainButton';

class InactivityWarning extends Component {
    constructor(props) {
        super(props)

        this.state = {
            remainingTimeStr: props.remainingTimeString,
            layoutType: props.layoutType
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.remainingTimeStr !== newProps.remainingTimeString) {
            this.setState({remainingTimeStr: newProps.remainingTimeString})
        }

        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
    }

    closeDialog(ev) {
        if (this.props.onRequestClose) {
            this.props.onRequestClose(ev);
        }
    }

    render() {
        var defStyles = ['defaultStyles']
        if (this.state.layoutType === 'mobile') {
            defStyles.push('mobile')
        }
        return (
            <div className={defStyles.join(' ')}>
                <div className="bodyContainer">                    
                    <div className="dialogContentContainer rightSpacing leftContentAlign">
                        <h2 className="dialogTitle">
                            {T.translate("Logout.labels.TimeoutTitle", { timeRemaining: this.state.remainingTimeStr })}
                        </h2>
                        <div style={{ marginTop: '10px' }} />
                        <span>{T.translate("Logout.labels.TimeoutText1")}</span>
                        <div style={{ marginTop: '10px' }} />
                        <span>{T.translate("Logout.labels.TimeoutText2")}</span>
                        <div style={{ marginTop: '20px' }} />
                    </div>
                </div>
                <div className="inactivityButtonContainer">
                    <PlainButton inverted onClick={(ev) => this.props.onLogout(ev)}
                        labelKey='Logout.labels.SignMeOut' />
                    <div style={{width: '8px', height: '8px'}} />
                    <PlainButton onClick={(ev) => this.props.onContinue(ev)}
                        labelKey='Logout.labels.StaySignedIn' />
                </div>
            </div>
        )
    }
}


export default InactivityWarning;