import React from 'react';
import T from 'i18n-react';
import '../style.css'
import PlainButton from '../../Controls/plainButton';
import HelpCentre from '../../../sagas/Help/helpCentre';

const ReturningOwnTaxes = () => {

    const showAskExpert = () => {
        HelpCentre.openHelpLink('AskATaxExpert')
    }

    const showExpertReview = () => {
        HelpCentre.openHelpLink('ExpertReview')
    }

    return (
        <div className='reg-content rot'>
            <div className='rot-border-bottom'>
                <h3>{T.translate("AccountSetupReturning.labels.Answers")}</h3>
                <p>{T.translate("AccountSetupReturning.labels.AnswersContent")}</p>
                <div className="rot-button">
                    <PlainButton labelKey='Common.LearnMore' onClick={showAskExpert} />
                </div>
             </div>
            <div>
                <h3>{T.translate("AccountSetupReturning.labels.Review")}</h3>
                <p>{T.translate("AccountSetupReturning.labels.ReviewContent")}</p>
                <div className="rot-button">
                    <PlainButton labelKey='Common.LearnMore' onClick={showExpertReview} />
                </div>
            </div>
        </div>
    )
}

export default ReturningOwnTaxes

