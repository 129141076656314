import React, { Component } from 'react';
import './shared.css';
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';
import InputMask from 'react-input-mask';

class DateTextInput extends Component {
    constructor(props) {
        super(props)

        this.state = {
            labelText: this.props.labelText,
            birthDate: this.props.birthDate,
            validationFailedText: this.props.validationFailedText,
            hasValidated: false,
            hasErrors: false,
            errorType: null,
            name: this.props.name,
            className: this.props.className,
            value: this.props.value,
            useFrenchLocale: this.props.isFrench,
            renderErrorBorderOnly: this.props.renderErrorBorderOnly || false,
            autoComplete: this.props.autoComplete,
            missingRequiredField: this.props.missingRequiredField,
            isRequired: this.props.isRequired || false
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.labelText !== newProps.labelText) {
            this.setState({labelText: newProps.labelText})
        }
        if (this.state.value !== newProps.value) {
            this.setState({value: newProps.value})
        }
        if (this.state.useFrenchLocale !== newProps.useFrenchLocale) {
            this.setState({ useFrenchLocale: newProps.useFrenchLocale })
        }
        if (this.state.missingRequiredField !== newProps.missingRequiredField) {
            this.setState({ missingRequiredField: newProps.missingRequiredField })
            if (newProps.missingRequiredField) {
                this.handleBlur()
            }
        } else if (newProps.missingRequiredField && this.state.isRequired) {
            this.handleBlur()
        }     
        if (this.state.renderErrorBorderOnly !== newProps.renderErrorBorderOnly) {
            this.setState({ renderErrorBorderOnly: Boolean(newProps.renderErrorBorderOnly) })
        }
        if (this.state.autoComplete !== newProps.autoComplete) {
            this.setState({ autoComplete: Boolean(newProps.renderErroautoCompleterBorderOnly) })
        }
        if (this.state.isRequired !== newProps.isRequired) {
            this.setState({ isRequired: newProps.isRequired })
        }
    }

    validateText(currText) {
        return isValidBirthdate(currText, this.state.hasValidated, this.props.validateMinAge)
    }
    
    handleTextChange(ev) {
        // once we've validated, validate on every change
        var state = {
            ...this.state,
            value: ev.target.value,
            name: ev.target.name
        }

        var result = this.validateText(state.value)
        if (!this.state.hasValidated) {
            state.hasErrors = !result.valid
            state.errorType = result.errorType
        }

        this.setState(state);

        if (this.props.onChange) {
            //this.props.onChange(ev, !result.isValid);
            this.props.onChange(ev, ev.target.value);
        }
    }

    handleFocus() {
        this.setState({ hasValidated: false }, () => {
            var result = this.validateText(this.state.value)
            this.setState({ hasErrors: !result.valid, errorType: result.errorType })
        });
    }
    handleBlur() {
        this.setState({ hasValidated: true }, () => {
            if (this.state.value.length === 0 && this.state.isRequired) {
                this.setState({ hasErrors: true, errorType: 'required' })
            }
            else {
                var result = this.validateText(this.state.value)
                this.setState({ hasErrors: !result.valid, errorType: result.errorType })
            }
            
        });
        
        
    }

    render() {
        const placeholderText = T.translate('DateFormat')  //"YYYY-MM-DD"
        const mask = "9999-99-99"

        //VAI-252 hacky fix to get rid of extra space when this is in a list, rather than a standard form.
        var addValidationSpace = this.state.labelText ? true : false;
        var noMargin = { marginTop: 0, height: 0 }

        var mainDivClassName = "validatingTextInputStyle verticalStretchStyle"

        var inputContent = this.state.autoComplete ? (
            <InputMask className={this.state.hasErrors || this.state.renderErrorBorderOnly ? "phoneInputErrorStyle" : "phoneInputStyle"}
                placeholder={placeholderText} mask={mask} maskChar="" name={this.state.name} value={this.state.value}
                autoComplete={this.state.autoComplete} heap-ignore={this.props.heapIgnore}
                onChange={(ev) => { this.handleTextChange(ev) }} onBlur={() => this.handleBlur()} onFocus={() => this.handleFocus()} />
        ) : (
            <InputMask className={this.state.hasErrors || this.state.renderErrorBorderOnly ? "phoneInputErrorStyle" : "phoneInputStyle"}
                placeholder={placeholderText} mask={mask} maskChar="" name={this.state.name} value={this.state.value}
                heap-ignore={this.props.heapIgnore}
                onChange={(ev) => { this.handleTextChange(ev) }} onBlur={() => this.handleBlur()} onFocus={() => this.handleFocus()} />
       )
        if (this.state.hasErrors || this.state.renderErrorBorderOnly) {
            let styleName = "red formLabelStyle"
            var errorMessage = this.state.birthDate ? T.translate('Common.Errors.InvalidDOBFormat') : T.translate('Common.Errors.InvalidDateFormat')
            if (this.state.errorType === 'underage') {
                errorMessage = T.translate('Common.Errors.InvalidDOBUnderage')
            }
            if (this.state.errorType === 'required') {
                errorMessage = T.translate('Common.Errors.RequiredDate')
            }

            var valContent = (
                <div className="validationErrorStyle">
                    &nbsp;
                </div> 
            )
            if (this.state.hasErrors) {
                valContent = (
                    <div className="validationErrorStyle">
                        <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#E70000', align: 'center' }} />
                        <span style={{ marginLeft: 10 }}>{errorMessage}</span>
                    </div>
                )
            }
            return (
                <div className="validatingTextInputStyle">
                    <div className={styleName} style={addValidationSpace ? null : noMargin}>
                        {this.state.labelText}
                    </div>
                    {inputContent}
                    {valContent}
                </div>
            )
        } else {
            return (
                <div className={mainDivClassName}>
                    <div className="formLabelStyle" style={addValidationSpace ? null : noMargin}>
                        {this.state.labelText}
                    </div>
                    {inputContent}
                    {addValidationSpace ?
                    <div className="validationErrorStyle">
                        &nbsp;
                    </div> : null }
                </div>
            )

        }
    }
}

export const isValidBirthdate = (data, hasValidated, validateMinAge) => {
    var result = { valid: true, errorType: null }

    if (data) {
        const matches = data.match(/^(\d{4})-(\d{2})-(\d{2})$/)
        if (matches) {
            const now = new Date()

            // determine what to use as the max birthdate.  If we're validating min age it should be now - 18 years,
            // otherwise it should be now
            var maxBirthdate = new Date(now)
            if (validateMinAge) {
                maxBirthdate.setFullYear(maxBirthdate.getFullYear() - 18)
            }

            const year = Number(matches[1])
            const month = Number(matches[2])
            const day = Number(matches[3])

            var testDate = new Date()
            testDate.setTime(new Date(year, month - 1, day).getTime())
            if (year < 1880 || year > now.getFullYear()) {
                result.valid = false
                result.errorType = 'invalid'
            } else if (day !== testDate.getDate()) {
                result.valid = false
                result.errorType = 'day'
            } else if (month !== testDate.getMonth() + 1) {
                result.valid = false
                result.errorType = 'month'
            } else if (testDate > maxBirthdate) {
                result.valid = false
                if (validateMinAge) {
                    result.errorType = 'underage'
                } else {
                    result.errorType = 'invalid'
                }
            }
        } else {
            if (String(data).length < 10 && !hasValidated) {
                return result
            }
            return { valid: false, errorType: 'malformed' }
        }
    }
    else {
        if (String(data).length === 0 && hasValidated) {
            return {valid: false, errorType: 'required'}
        }
    }
    return result
}

export default DateTextInput;