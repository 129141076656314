/* eslint-disable-next-line */
import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import LayoutActions from '../../reducers/layoutRedux'
import LoadingPage from '../Loading/loadingPage'
import auth from '../../sagas/SSO/Auth'
import { getParameterByName } from '../../services/urlHelpers'

const LoginLandingPage = props => {

    const { loginComplete, replaceNav, setLanguage, lang: selectedLang } = props

    useEffect(() => {
        if (loginComplete) {
            replaceNav('/dashboard')
        } else {
            var lang = getParameterByName('lang')
            if (lang === 'en' || lang === 'fr') {
                setLanguage(lang)
            } else {
                lang = selectedLang
            }
            auth.login(lang)
        }
    }, [])

    return (<LoadingPage />)
}

const mapStateToProps = (state) => {
    return {
        loginComplete: state.auth.loginComplete,
        lang: state.layout.lang
    }
}

const mapDispatchToProps = (dispatch) => ({
    replaceNav: (route) => dispatch(replace(route)),
    setLanguage: (lang) => dispatch(LayoutActions.setDictLanguage(lang))
})


export default connect(mapStateToProps, mapDispatchToProps)(LoginLandingPage)
