import React, { Component } from 'react';
import './style.css';
import '../Interview/style.css'
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';
import TimeFromLastDate from '../Common/TimeFromLastDate'
import craFileIcon from '../../images/ca-file-icon.svg'
import qcFileIcon from '../../images/qc-file-icon.svg'

class ReturnLink extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: this.props.lang,
            layoutType: this.props.layoutType,
            titleKey: this.props.titleKey,
            firstName: this.props.firstName,
            file: this.props.file,
            viewed: this.props.file ? this.props.file.isViewed : null,
            fileId: this.props.file ? this.props.file.fileId : null,
            fileUrl: this.props.file ? this.props.file.shareLink : null,
            fileLastModified: this.props.file ? this.props.file.lastModified : null,
            taxYear: this.props.taxYear || localStorage.getItem('va_current_tax_year'),
            ticketType: this.props.ticketType,
            filerStoryType: this.props.filerStoryType
    }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }
        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
        if (this.state.titleKey !== newProps.titleKey) {
            this.setState({ titleKey: newProps.titleKey })
        }
        if (this.state.firstName !== newProps.firstName) {
            this.setState({ firstName: newProps.firstName })
        }
        if (this.state.file !== newProps.file) {
            this.setState({ viewed: newProps.file ? newProps.file.isViewed : null })
            this.setState({ fileId: newProps.file ? newProps.file.fileId : null })
            this.setState({ fileUrl: newProps.file ? newProps.file.shareLink : null })
            this.setState({ fileLastModified: newProps.file ? newProps.file.lastModified : null })
        }
    }

    onViewClicked(e) {
        if (this.props.onViewClicked)
            this.props.onViewClicked(this.state.fileId)
    }

    onDownloadClicked(e) {
        if (this.props.onDownloadClicked)
            this.props.onDownloadClicked(this.state.fileId)
    }


    render() {
        // text links for desktop only (with a px padder for mobile/tablet views)
        var textLinks = <div style={{ height: 4 }} />;

        if (this.state.layoutType === 'desktop') {
            var downloadLink = this.state.fileId ? <a onClick={(e) => this.onDownloadClicked(e)} href={this.state.fileUrl} style={{ display: 'contents' }} download>{T.translate("ReviewAndApprove.Download")}</a> :
                                                   <a href={this.state.fileUrl} disabled="disabled" className="disabled" style={{ display: 'contents' }}>{T.translate("ReviewAndApprove.Download")}</a>
            var viewLink =  this.state.fileId ? <a onClick={(e) => this.onViewClicked(e)} href={this.state.fileUrl} style={{ display: 'contents' }}>{T.translate("ReviewAndApprove.View")}</a> :
                                                <a href={this.state.fileUrl} disabled="disabled" className="disabled" style={{ display: 'contents' }}>{T.translate("ReviewAndApprove.View")}</a>                                       
            if (this.props.withoutView) {
                textLinks = <div style={{ textAlign: 'center', color: '#3F3F3F' }}>{downloadLink} </div>
            }
            else {
                if(this.state.ticketType !== 'RTE')
                    textLinks = <div style={{ textAlign: 'center', color: '#3F3F3F' }}><span className="linkLabel" onClick={(e) => this.onViewClicked(e)}>{T.translate("ReviewAndApprove.View")}</span> | {downloadLink} </div>
                else
                    textLinks = <div style={{ textAlign: 'center', color: '#3F3F3F', paddingBottom: '25px' }}> {viewLink} | {downloadLink} </div>
            }
        }

        if(this.state.ticketType ==='RTE'){
            var imageSource
            if(this.state.filerStoryType === 'primaryFederal' || this.state.filerStoryType === 'spouseFederal')
                imageSource = craFileIcon;
            else imageSource = qcFileIcon;
        }
        

        // create a clickable link for mobile.
        var previewButton = [<div key="overlay" className="previewLinkViewedOverlay" onClick={this.state.layoutType === 'desktop' ? (ev) => this.onViewClicked(ev) : null}>

                            </div>,
                            <div key="link" className="previewLink" onClick={this.state.layoutType === 'desktop' ? (ev) => this.onViewClicked(ev) : null}>
                                <div className="previewLinkLabel">
                                    <span style={this.state.ticketType ==='RTE' ? {color: '#000000'} : {color: '#666666'}}>{T.translate(this.state.titleKey, { FirstName: this.state.firstName, taxYear: this.state.taxYear })}</span>
                                </div>
                                <div className={this.state.ticketType ==='RTE' ? "previewLinkIconRTE" : "previewLinkIcon"}>
                                    {this.state.ticketType ==='RTE' ? <img src={imageSource} width='44px' height='58.58px' style={{ align: 'center', paddingBottom: '15px' }}/> : <FontAwesome className="fa-file" name="fa-file" size='4x' style={{ align: 'center' }} />}
                                </div>
                                <TimeFromLastDate lastModifiedDate={this.state.fileLastModified} ticketType={this.state.ticketType} />
            </div>];

        if (this.props.withoutView) {
            previewButton = [<a key="alink" style={{ textDecoration: 'none' }} href={this.state.fileUrl}>
                                <div key="link" className="previewLink" onClick={this.state.layoutType === 'desktop' ? (e) => this.onDownloadClicked(e) : null}>
                                    <div className="previewLinkLabel">
                                        <span style={this.state.viewed ? { color: '#005D1F' } : {color: '#000000' }}>{T.translate(this.state.titleKey, { FirstName: this.state.firstName })}</span>
                                    </div>
                                    <div className={this.state.ticketType ==='RTE' ? "previewLinkIconRTE" : "previewLinkIcon"}>
                                        {this.state.ticketType ==='RTE' ? <img src={imageSource} width='44px' height='58.58px' style={{ align: 'center', paddingBottom: '15px' }}/> : <FontAwesome className="fa-download" name="fa-file" size='4x' style={{ align: 'center' }} />}
                                    </div>
                                <TimeFromLastDate lastModifiedDate={this.state.fileLastModified} ticketType={this.state.ticketType}/>
                                </div>
                            </a>];
        }

        // if on mobile, wrap the button with a hyperlink
        if (this.state.layoutType !== 'desktop') {
            var clickHandler = (this.state.fileUrl ? (e) => this.onDownloadClicked(e) : (e) => this.onViewClicked(e))
            previewButton = <a href={this.state.fileUrl} onClick={clickHandler} style={{ fontWeight: 'normal' }} target="_blank" rel="noopener noreferrer">{previewButton}</a>
        }

        return (
            <div className={this.state.viewed ? "previewLinkOuter viewed" : "previewLinkOuter"}>
                {previewButton}
                {textLinks}
            </div>
        );
    }
}


export default ReturnLink