/* eslint-disable-next-line */
import { bool, string } from 'prop-types'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTimer } from '../../hooks/useTimer'
import AuthActions from '../../reducers/authRedux'
import auth from '../../sagas/SSO/Auth'

const AuthTimers = props => {

    const { loginComplete, lang } = props

    const interval = 30; // seconds
    const oneMinute = 60 * 1000; // ms

    const [start, stop] = useTimer(() => checkForSilentLogin(), interval, true);

    useEffect(() => {
        if (loginComplete) {
            start()
        } else {
            stop()
        }
    }, [loginComplete])

    useEffect(() => {
        start()

        return () => { stop() }
    }, [])
      
    const checkForSilentLogin = () => {
        console.log('checking for token refresh')
        if (!auth.isAuthenticated()) {
            stop()
        } else {
            if (auth.getTokenTimeToLive() < oneMinute) {
                auth.silentAuthentication(() => {
                    return {
                        language: lang
                    }
                });
            }
        }
    }

    return null
}

AuthTimers.propTypes = {
    loginComplete: bool,
    lang: string
}

const mapStateToProps = (state) => {
    return {
        loginComplete: state.auth.loginComplete,
        lang: state.layout.lang
    }
}

const mapDispatchToProps = (dispatch) => ({
    setAccessToken: (accessToken, expiryTime) => dispatch(AuthActions.setAccessToken(accessToken, expiryTime))
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthTimers)
