import { replace } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch, withRouter } from 'react-router-dom';
import MessagesActions from '../../reducers/messagesRedux';
import WorkflowActions from '../../reducers/workflowRedux';
import WorkflowAPI from '../../services/workflowApis';
import { determineIsDirectClient } from '../../utils/CaseStateUtilities';
import AssignIntercept from '../Assign/assign';
import AuthorizeComplete from '../AuthorizeHRB/authorizeComplete';
import Authorize from '../AuthorizeHRB/authorizeHrb';
import ConfirmIdentity from '../ConfirmIdentity/confirmIdentity';
import OuterContainer from '../Controls/outerContainer';
import DocumentManagementPage from '../Dox/documentManagementPage';
import DocumentUploaderPage from '../Dox/documentUploaderPage';
import Interview from '../Interview/interview';
import YearSelection from '../Interview/yearSelection';
import ReviewAlt from '../Review/reviewAlt';
import ReviewCompletedReturns from '../Review/reviewCompleted';
import ReviewDrafts from '../Review/reviewDrafts';
import LoadingPage from '../Loading/loadingPage';
import Workflow from './workflow';
import DeclinedPage from '../Payment/declinedPage';
import AcceptedPage from '../Payment/acceptedPage'
import ESignatureCallback from '../Documents/eSignatureCallback';


const WorkflowRouter = props => {
    const accessToken = useSelector(state => state.auth.accessToken)

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true)

    const match = useRouteMatch()

    const caseState = useSelector(state => state.workflow.case_state)
    const reviewAndApprovePaperSignature = useSelector(state => state.workflow.review_and_approve_paper_signature)

    const showInterviewIntercept = useSelector(state => {
        if (state.workflowNewReturn.associateId) {
            return false;
        }
        if (state.workflow && state.workflow.case_state && state.workflow.case_state.taxYears) {
            return !state.workflow.case_state.taxYears.some(t => !t.is_closed) ||
                state.workflow.case_state.taxYears.some(t => !t.is_closed && !t.assigned_tax_pro);
        }
        return true;
    });

    useEffect(() => {
        let isActive = true;
        async function fetchData() {
            if (Object.keys(caseState).length !== 0) {
                setLoading(false);
                return;
            }

            var response = await WorkflowAPI.create().getWorkflowState2(accessToken, true);

            if (response && response.ok) {
                dispatch(WorkflowActions.storeCaseState(response.data))
                dispatch(MessagesActions.setUnreadMessagesCount(response.data.unreadMessagesCount))

                let isDirect = determineIsDirectClient(response.data)
                dispatch(WorkflowActions.setIsDirectUser(isDirect))

                if (response.data && response.data.confirm_id && (response.data.confirm_id.self === "Failed" || response.data.confirm_id.spouse === "Failed")) {
                    dispatch(replace('/dashboard/confirm_id'))
                }

                if (isActive) {
                    setLoading(false);
                }
            } else {
                console.error("Unable to load case state");
                if (isActive) {
                    setLoading(false);
                }
            }
        }

        fetchData();
        return () => { isActive = false };
    },);

    return (
        loading
            ? <LoadingPage loaded={props.loaded} />
            : <OuterContainer sizeToContent loaded={props.loaded}>
                <Switch>
                    {showInterviewIntercept &&
                        <Redirect from={`${match.path}/interview`} push to={`${match.path}/assign`} />
                    }
                    <Route path={`${match.path}/interview/:taxYear/:page`}><Interview /></Route>
                    <Route path={`${match.path}/interview/year_selection`}><YearSelection /></Route>
                    <Route path={`${match.path}/interview`}><Interview /></Route>
                    <Route path={`${match.path}/assign`}><AssignIntercept /></Route>
                    <Route path={`${match.path}/confirm_id`}><ConfirmIdentity /></Route>
                    <Route path={`${match.path}/authorize/complete`}><AuthorizeComplete /></Route>
                    <Route path={`${match.path}/authorize`}><Authorize /></Route>
                    <Route path={`${match.path}/declined`}><DeclinedPage /></Route>
                    <Route path={`${match.path}/approved`}><AcceptedPage /></Route>
                    {reviewAndApprovePaperSignature ?
                        <Route path={`${match.path}/reviewalt/:taxYear/:page`}><ReviewAlt /></Route> :
                        <Route path={`${match.path}/review/:taxYear`}><ReviewDrafts /></Route>
                    }
                    <Route path={`${match.path}/completed_returns/:taxYear`}><ReviewCompletedReturns /></Route>
                    <Route path={`${match.path}/uploader/:taxYear/:comingFrom`}><DocumentUploaderPage /></Route>
                    <Route path={`${match.path}/documents/:taxYear?`}><DocumentManagementPage /></Route>
                    <Route path={`${match.path}/esignature`}><ESignatureCallback loaded={props.loaded} /></Route>
                    <Route path={match.path}><Workflow /></Route>
                </Switch>
            </OuterContainer>
    )
}

export default withRouter(WorkflowRouter)