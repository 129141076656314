import React, { Component } from 'react';
import './shared.css';
import FontAwesome from 'react-fontawesome';
import T from 'i18n-react';

class PasswordTextInput extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isForCreation: this.props.isForCreation,
            hasValidated: false,
            hasErrors: false,
            currentContent: this.props.value || "",
            passwordState: {
                showPasswordContents: false,
                validLength: false,
                validChars: false,
                validNoRepeats: false
            },
            hasFocus: false,
            showErrorStyling: this.props.showErrorStyling || false
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.showErrorStyling !== newProps.showErrorStyling) {
            this.setState({showErrorStyling: newProps.showErrorStyling})
        }
    }

    validateText(currText) {
        return this.checkChars(currText) && this.checkRepeatedChars(currText)
    }

    checkChars(currText) {
        var re1 = /[A-Z]/   // uppercase
        var re2 = /[a-z]/   // lowercase
        var re3 = /[0-9]/   // numbers

        var count = 0
        if (!currText || currText.length < 8 || currText.length > 32) {
            return false
        } 
        if (re1.test(currText)) {
            count ++
        }
        if (re2.test(currText)) {
            count ++
        }
        if (re3.test(currText)) {
            count++
        }        
        return count > 1
    }

    checkRepeatedChars(currText) {
        var re = /(.)\1{2,}/        // eslint-disable-line no-useless-escape
        return !re.test(currText) && currText && currText.length > 7;
    }

    onKeyDown(ev) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(ev);
        }
    }

    handleTextChange(ev) {
        // once we've validated, validate on every change
        var state = {
            ...this.state,
            currentContent: ev.target.value || ""
        }

        var isValid = this.validateText(state.currentContent)
        if (this.state.hasValidated) {
            state.hasErrors = !isValid
        }

        var passwordState = {
            ...state.passwordState,
            validLength: String(state.currentContent).length >= 8 && String(state.currentContent).length < 33,
            validChars: this.checkChars(state.currentContent),
            validNoRepeats: this.checkRepeatedChars(state.currentContent)
        }
        state.passwordState = passwordState;

        this.setState(state);

        if (this.props.onChange) {
            this.props.onChange(ev, this.state.isForCreation ? isValid : true);
        }
    }

    handleBlur() {
        if (!this.state.hasValidated && this.state.currentContent) {
            this.setState({ hasValidated: true, hasErrors: !this.validateText(this.state.currentContent) })
        }
    }

    togglePasswordVisibility() {
        var passwordState = {
            ...this.state.passwordState,
            showPasswordContents: !this.state.passwordState.showPasswordContents
        }
        this.setState({ passwordState })
    }

    render() {
        var iconColour = this.state.showErrorStyling ? '#E70000' : '#14AA40'
        var inputContent = []
        inputContent.push(
            <input key="pwInput" value={this.state.currentContent}
                autoComplete="new-password" heap-ignore='true'
                type={this.state.passwordState.showPasswordContents ? "text" : "password"}
                onFocus={(ev) => this.setState({ hasFocus: true })}
                onBlur={(ev) => this.setState({hasFocus: false})}
                onChange={(ev) => { this.handleTextChange(ev) }}
                onKeyDown={(ev) => { this.onKeyDown(ev) }} />
        )
        var faIconName = this.state.passwordState.showPasswordContents ? 'fa-eye-slash' : 'fa-eye'
        inputContent.push(
            <div key='eyes' onClick={(ev) => { this.togglePasswordVisibility() }}>
                <FontAwesome className={faIconName} name={faIconName} size='2x' style={{marginRight: 10, cursor: 'pointer', textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: iconColour, align: 'center' }} />
            </div>
        )

        let validationContent = []
        if (this.state.isForCreation) {
            validationContent.push(<div key="spacer" style={{marginTop: 8}} />)
            if (this.state.passwordState.validLength) {
                validationContent.push(
                    <div key="length_validation" className="genericHorizontalCenteredContainer">
                        <FontAwesome className="fa-check-square-o" name="fa-check-square-o" size='lg' style={{ marginRight: 0, marginTop: '3px', cursor: 'pointer', textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: iconColour, align: 'center' }} />
                        <span style={{ color: '#14AA40', marginLeft: 10 }}>{T.translate("Common.PasswordParameters1")}</span>
                    </div>
                )
            }
            else {
                validationContent.push(
                    <div key="length_validation" className="genericHorizontalCenteredContainer">
                        <FontAwesome className="fa-square-o" name="fa-square-o" size='lg' style={{ marginRight: 0, marginTop: '3px', cursor: 'pointer', textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#3F3F3F', align: 'center' }} />
                        <span style={{ color: '#3F3F3F', marginLeft: 7 }}>{T.translate("Common.PasswordParameters1")}</span>
                    </div>
                )
            }
            if (this.state.passwordState.validChars) {
                validationContent.push(
                    <div key="char_validation" className="genericHorizontalCenteredContainer">
                        <FontAwesome className="fa-check-square-o" name="fa-check-square-o" size='lg' style={{ marginRight: 0, marginTop: '3px', cursor: 'pointer', textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#14AA40', align: 'center' }} />
                        <span style={{ color: '#14AA40', marginLeft: 7 }}>{T.translate("Common.PasswordParameters2")}</span>
                    </div>
                )
            }
            else {
                validationContent.push(
                    <div key="char_validation" className="genericHorizontalCenteredContainer">
                        <FontAwesome className="fa-square-o" name="fa-square-o" size='lg' style={{ marginRight: 0, marginTop: '3px', cursor: 'pointer', textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#3F3F3F', align: 'center' }} />
                        <span style={{ color: '#3F3F3F', marginLeft: 7 }}>{T.translate('Common.PasswordParameters2')}</span>
                    </div>
                )
            }
            if (this.state.passwordState.validNoRepeats) {
                validationContent.push(
                    <div key="repeat_validation" className="genericHorizontalCenteredContainer">
                        <FontAwesome className="fa-check-square-o" name="fa-check-square-o" size='lg' style={{ marginRight: 0, marginTop: '3px', cursor: 'pointer', textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#14AA40', align: 'center' }} />
                        <span style={{ color: '#14AA40', marginLeft: 7 }}>{T.translate('Common.PasswordParameters3')}</span>
                    </div>
                )
            }
            else {
                validationContent.push(
                    <div key="repeat_validation" className="genericHorizontalCenteredContainer">
                        <FontAwesome className="fa-square-o" name="fa-square-o" size='lg' style={{ marginRight: 0, marginTop: '3px', cursor: 'pointer', textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#3F3F3F', align: 'center' }} />
                        <span style={{ color: '#3F3F3F', marginLeft: 7 }}>{T.translate('Common.PasswordParameters3')}</span>
                    </div>
                )
            }
        }

        var inputClassName = this.state.hasFocus ? "defaultPWFocusStyles" : "defaultPWStyles"
        if (this.state.showErrorStyling) {
            inputClassName = this.state.hasFocus ? "defaultPWErrorStyles" : "defaultPWFocusErrorStyles"
        }
        return (
            <div>
                <div className="formLabelStyle">
                    <span>{T.translate("AccountSetupCredentials.labels.Password")}</span>
                </div>
                <div className={inputClassName}>
                    {inputContent}
                </div>
                <div style={{ marginLeft: 10, marginTop: 4, fontFamily: 'Roboto', color: '#3F3F3F', fontSize: 14 }}>
                    {validationContent}
                </div>
            </div>
        )

    }
}


export default PasswordTextInput;