import React, { Component, Fragment } from 'react';
import '../../Common/shared.css'
import '../style.css';

import T from 'i18n-react';
import PersonalInfoForm from '../../Common/personalInfoForm'
import YesNoQuestion from '../../Common/yesNoQuestion'
import HelpLink from '../../Controls/helpLink'
import HighlightedSection from '../highlightedSection'
import InterviewTitleProgress from '../../Common/interviewTitleProgress'

class InterviewPage5b extends Component {
    constructor(props) {
        super(props)

        var spouse = this.props.spouse || {
            firstName: '',
            middleName: '',
            lastName: '',
            dob: '',
            sin: '',
            nameChange: ''
        }

        this.state = {
            spouse,
            missingRequiredField: this.props.missingRequiredField,
            layoutType: this.props.layoutType,
            lang: this.props.lang
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.missingRequiredField !== newProps.missingRequiredField) {
            this.setState({ missingRequiredField: newProps.missingRequiredField })
        }
        if (this.state.spouse !== newProps.spouse) {
            this.setState({ spouse: newProps.spouse })
        }
        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }
    }


    onPersonalInfoChanged(personalInfo) {
        var spouse = {
            ...this.spouse,
            firstName: personalInfo.firstName,
            middleName: personalInfo.middleName,
            lastName: personalInfo.lastName,
            dob: personalInfo.dob,
            sin: personalInfo.sin,
            nameChange: personalInfo.nameChange
        }
        this.setState({ spouse })
        if (this.props.onSpouseChanged) {
            this.props.onSpouseChanged(spouse)
        }
    }

    validateNameField(textValue) {
        return !textValue || textValue.match(/^[a-zA-Z\u00C0-\u017F\'\.\-\s]+$/)   // eslint-disable-line no-useless-escape
    }

    onNameChangeChanged(ev, value) {
        if (ev) {
            var spouse = {
                ...this.state.spouse,
                nameChange: value
            }
            this.setState({ spouse });
            if (this.props.onSpouseChanged) {
                this.props.onSpouseChanged(spouse)
            }
        }
    }


    render() {

        let missingNameChange = this.state.missingRequiredField && !this.state.spouse.nameChange
        var nextTitle = 'Interview.Page5.nextTitle1'
        var percentage = '66'
        if (this.props.dependants === 'Y') {
            nextTitle = 'Interview.Page5.nextTitle2'
            percentage = '64'
        }
        if (this.props.spouseTaxes === 'Y') {
            nextTitle = 'Interview.Page5.nextTitle3'
            percentage = '60'
        }      

        return (
            <div>
                <div>
                    <div>
                        <InterviewTitleProgress taxYear={this.props.taxYear} percentage={percentage} title="Interview.Page5.headerTitle" nextTitle={nextTitle} />
                    </div> 
                    <div style={{ height: 10 }} />
                    {Boolean(this.props.isReturningClient) &&
                        <Fragment>
                            <HighlightedSection isReturningClient={this.props.isReturningClient} margin={20}>
                                {T.translate('Interview.Page5.subTitleReturning')}
                            </HighlightedSection>
                            <div style={{ height: 20 }} />
                        </Fragment>
                    }
                </div>
                <PersonalInfoForm missingRequiredField={this.state.missingRequiredField}
                    lang={this.state.lang} allRequiredFields taxYear={this.props.taxYear}
                    personalInfo={this.state.spouse} labelKey="Interview.Page1" page={'page5b'}
                    onPersonalInfoChanged={(personalInfo) => this.onPersonalInfoChanged(personalInfo)} validateMinAge/>
                <div className="horizontalFieldContainer mailingTopSpacing">
                    <HighlightedSection isReturningClient={this.props.isReturningClient} margin={20} additionalClasses='mailingAddressSectionPadding'>
                            <YesNoQuestion name="nameChange" value={this.state.spouse.nameChange}
                                layoutType={this.state.layoutType}
                                autoWidth="true"
                                labelText={T.translate("Interview.Page5.fieldLabel0", { TaxYear: this.props.taxYear, SpouseFirstName: this.props.spouse.firstName === "" ? T.translate('Interview.Page5.yourSpouse') : this.props.spouse.firstName })}
                                onAnswerChanged={(ev, value) => { this.onNameChangeChanged(ev, value) }}
                                centerAlignThis={true} isRequired={true} missingRequiredField={missingNameChange}/>                       
                            {this.state.spouse.nameChange === 'Y' &&
                                <Fragment>
                                    <div className="mailingBottomSpacing" />
                                    <div className='mailingAddressChangeText'>
                                        { T.translate('Interview.Page5.nameChangeHelpText', {
                                            SpouseFirstName: this.props.spouse.firstName,
                                            Link: <HelpLink linkId='FAQs/WhatShouldIDoIfMyOrMySpousesNameChanged' linkKey={'Interview.Page5.nameChangeHelpLinkText'} />
                                        })}
                                    </div>
                                </Fragment>
                            }
                    </HighlightedSection>
                </div>
            </div>
        )
    }
}


export default InterviewPage5b;
