import React, { useState, useEffect } from 'react';
import T from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import '../style.css'
import { hasOnlyValidChars, isValidEmail, isValidPhone } from '../services/registrationUtils';
import AccountDataActions from '../../../reducers/accountDataRedux';
import RegisterActions from '../../../reducers/registerRedux';
import ValidatingTextInput from '../../Common/validatingTextInput';
import PhoneTextInput from '../../Common/phoneTextInput';
import PhoneTypeToggle from '../../Common/phoneTypeToggle';
import ProvinceDropDown from '../../Controls/DropDowns/provinceDropDown';
import ArrowButton from '../../Controls/arrowButton';

const CreateAccount = () => {
    const dispatch = useDispatch();

    const { firstName, lastName, email, mobilePhone, phoneType, province } = useSelector(state => state.accountData);
    const layoutType = useSelector(state => state.layout.layoutType);

    const [isSmallLayout, setIsSmallLayout] = useState(false);
    const [dataComplete, setDataComplete] = useState(false);

    useEffect(() => {
        let isSm = layoutType === 'small_tablet' || layoutType === 'mobile'
        if (isSm !== isSmallLayout) {
            setIsSmallLayout(isSm)
        }
    }, [layoutType, setIsSmallLayout, isSmallLayout])

    useEffect(() => {
        setDataComplete(hasOnlyValidChars(firstName) && hasOnlyValidChars(lastName) && isValidEmail(email)
            && isValidPhone(mobilePhone) && province)
    }, [firstName, lastName, email, mobilePhone, phoneType, province, setDataComplete])

    const handleFirstNameChange = (ev) => {
        dispatch(AccountDataActions.setFirstName(ev.target.value.trim()))
    }

    const handleLastNameChange = (ev) => {
        dispatch(AccountDataActions.setLastName(ev.target.value.trim()))
    }

    const handleEmailChange = (ev) => {
        dispatch(AccountDataActions.setEmail(ev.target.value.trim()))
    }

    const handlePhoneNumberChange = (event, value, isValid) => {
        if (value !== mobilePhone) {
            dispatch(AccountDataActions.setMobilePhone(value))
        }
    };

    const handlePhoneTypeChange = (event) => {
        dispatch(AccountDataActions.setPhoneType(parseInt(event.target.value, 10)))
    }

    const handleProvinceChanged = (ev, value) => {
        if (value !== province) {
            dispatch(AccountDataActions.setProvince(value))
        }
    }

    const handleContinue = () => {
        dispatch(RegisterActions.setStepWithMessage('lookup_user', 'AccountSetupProgress.statusMessages.Looking'))
    }

    return (
        <div className={classnames('reg-min-height', { "registerContentSmallStyle": isSmallLayout, "registerContentStyle": !isSmallLayout })}>
            <div className='reg-content'>
                <h1>{T.translate("AccountSetupStart.labels.HowCanWe")}</h1>
                <div className='rs-form-container'>
                    <form name="registration_form_1" autoComplete="on">
                        <ValidatingTextInput labelText={T.translate("AccountSetupStart.labels.FirstName")}
                            value={firstName} maxLength={30} autoComplete="given-name" heapIgnore="true"
                            placeholderText="" defaultText={firstName} validationFailedText={T.translate("AccountSetupStart.labels.NameValidationError")}
                            validator={hasOnlyValidChars} onChange={handleFirstNameChange} />
                        <ValidatingTextInput labelText={T.translate("AccountSetupStart.labels.LastName")}
                            value={lastName} maxLength={254} autoComplete="family-name" heapIgnore="true"
                            placeholderText="" defaultText={lastName} validationFailedText={T.translate("AccountSetupStart.labels.NameValidationError")}
                            validator={hasOnlyValidChars} onChange={handleLastNameChange} />
                        <ValidatingTextInput labelText={T.translate("AccountSetupStart.labels.Email")}
                            autoComplete="email" heapIgnore="true"
                            value={email} placeholderText="" defaultText={email} validationFailedText={T.translate("AccountSetupStart.labels.InvalidEmail")}
                            validator={isValidEmail} onChange={handleEmailChange} />
                        <div className='ca-phone-holder'>
                            <div className="mobilePhoneText" >
                                <PhoneTextInput labelText="AccountSetupStart.labels.MobilePhone"
                                    value={mobilePhone} autoComplete="tel-national mobile"
                                    validationFailedText="AccountSetupStart.labels.InvalidPhone"
                                    onChange={handlePhoneNumberChange}
                                    isInline={true} />
                            </div>
                            <div className='phone-type'>
                                <PhoneTypeToggle name="PhoneType" value={phoneType} onStateChanged={handlePhoneTypeChange} />
                            </div>
                        </div>
                        <div style={{ marginLeft: 0, marginTop: 10 }}>
                            <div className="formLabelStyle">
                                {T.translate("AccountSetupStart.labels.Province")}
                            </div>
                        </div>
                        <div style={{ marginLeft: 0, width: "100%" }}>
                            <ProvinceDropDown name="province" value={province} onValueChanged={handleProvinceChanged} />
                        </div>
                    </form>
                </div>
            </div>
            <div className='reg-continue'>
                <ArrowButton isEnabled={dataComplete} labelKey='Common.Continue' onClick={handleContinue} />
            </div>
        </div>

    )
}

export default CreateAccount
