import { takeLatest, takeEvery, all } from 'redux-saga/effects'
import RegisterAPI from '../services/registerApis'
import MessagesAPI from '../services/messagesApis'
import AccountAPI from '../services/accountApis'
import InterviewAPI from '../services/interviewApis'
import WorkflowAPI from '../services/workflowApis'
import PaymentAPI from '../services/paymentApis'
import AuthorizeCRAAPI from '../services/authorizeCRAApis'
import DocumentsAPI from '../services/documentsApi'
import ProductsAPI from '../services/productsApi'
import SurveyAPI from '../services/surveyApis'
import SigningAPI from '../services/signingApis'
import ConfirmIdentityAPI from '../services/confirmIdentityApis'

/* ------------- Types ------------- */
import { RegisterTypes } from '../reducers/registerRedux'
import { AuthTypes } from '../reducers/authRedux'
import { LoginTypes } from '../reducers/loginRedux'
import { MessagesTypes } from '../reducers/messagesRedux'
import { LayoutTypes } from '../reducers/layoutRedux'
import { InterviewTypes } from '../reducers/interviewRedux'
import { WorkflowTypes } from '../reducers/workflowRedux'
import { WorkflowNewReturnTypes } from '../reducers/workflowNewReturnRedux'
import { PaymentTypes } from '../reducers/paymentRedux'
import { DocumentsTypes } from '../reducers/documentsRedux'
import { ProductsTypes } from '../reducers/productsRedux'
import { SurveyTypes } from '../reducers/surveyRedux'
import { SigningTypes } from '../reducers/signingRedux'
import { ConfirmIdentityTypes } from '../reducers/confirmIdentityRedux'
import { FilesTypes } from '../reducers/filesRedux'
import { AuthorizeCRATypes } from '../reducers/authorizeCRARedux';
import { ApproveDraftsTypes } from '../reducers/approveDraftsRedux';

/* ------------- Sagas ------------- */
import {
    updateLanguageTexts,
    updateLanguageCookie,
    updateHelpCenterLanguage
} from './languageSagas'
import {
    processStepChanged,
    saveLanguagePrefs,
    resetRegistration
} from './registerSagas' 
import {
    requestLogin, handleLoginCallback, finalizeLogin,
    refreshAccessToken, handleOTCRedemption, finalizeOTCRedemption,
    initPreLogoutAction, startPreLogoutAction, checkPreLogoutComplete,
    cleanupSessionState, finalizeLogout, navigateToDashboard
} from './authSagas'
import {
    updateLastReadDate, sendCurrentMessage, queryCaseMessages,
    resetInputScreenVisibility, sendInternalNoteNoPrinter
} from './messagesSagas'
import { loadInterview, saveInterview } from './interviewSagas'
import {
    saveCaseSettings,
    getCaseState,
    deleteCase,
    setLastReviewedPage,
    notifyOnScheduleCallAttempt
} from './workflowSagas'
import { createNewReturns } from './workflowNewReturnSagas'
import { loadAuthState, saveAuthState, getAuthorizeConfig } from './authorizeCRASagas'
import { resetApprovalState, setApprovalComplete } from './approveDraftsSagas'
import { getPaymentInfo, completePayment } from './paymentSagas'
import {
    sendOneTimeSMS, renameIOSFile, loadCompletedDocuments, setCompletedFileViewed,
    setFileUploaded, setUploadStepCompleted, loadDocumentsForReview, setDocumentForReviewViewed,
    loadApprovalDocuments, sendReviewCompleteMessage, sendReviewCompleteMessageWetSignature, getSuggestions
} from './documentsSagas'
import { savePOMSelection, getAddOnProducts } from './productsSagas'
import { createSurvey } from './surveySagas'
import { getSigningUrl, logoutResetSigningState, processDocumentCallback, checkSigningState } from './signingSagas'
import { updateInterviewFromWorkingData, confirmIdentity, processImageForUpload, beginFileUpload, confirmIdentityDocs, getConfirmIdState, completeQrCodeVerification } from './confirmIdentitySagas'

/* ------------- API ------------- */
// The API we use is only used from Sagas, so we create it here and pass along to the sagas which need it.
const registerApi = RegisterAPI.create()
const messagesApi = MessagesAPI.create()
const accountApi = AccountAPI.create()
const interviewApi = InterviewAPI.create()
const workflowApi = WorkflowAPI.create()
const paymentApi = PaymentAPI.create()
const authorizeCRAApi = AuthorizeCRAAPI.create()
const documentsApi = DocumentsAPI.create()
const productsApi = ProductsAPI.create()
const surveyApi = SurveyAPI.create()
const signingApi = SigningAPI.create()
const confirmIdentityApi = ConfirmIdentityAPI.create()

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
    yield all([
        // Language
        takeEvery(LayoutTypes.SET_DICT_LANGUAGE, updateLanguageTexts),
        takeEvery(LayoutTypes.SET_LANGUAGE, updateLanguageCookie),
        takeEvery(LayoutTypes.SET_LANGUAGE, updateHelpCenterLanguage),
        takeLatest(LayoutTypes.SET_LANGUAGE, saveLanguagePrefs, registerApi),

        // Registration
        takeEvery(RegisterTypes.SET_STEP, processStepChanged, registerApi),
        takeEvery(RegisterTypes.SET_STEP_WITH_MESSAGE, processStepChanged, registerApi),
        takeEvery('@@router/LOCATION_CHANGE', resetRegistration),

        // Login
        takeLatest(LoginTypes.REQUEST_LOGIN, requestLogin),
        takeLatest(AuthTypes.HANDLE_LOGIN_CALLBACK, handleLoginCallback, true),
        takeLatest(AuthTypes.FINALIZE_LOGIN, finalizeLogin, accountApi, workflowApi),
        takeLatest(AuthTypes.SET_LOGIN_COMPLETE, navigateToDashboard),

        // silent login/token refresh
        takeLatest(AuthTypes.REFRESH_ACCESS_TOKEN, refreshAccessToken, registerApi),
        takeLatest(AuthTypes.HANDLE_REFRESH_TOKEN_CALLBACK, handleLoginCallback),

        // OTC login
        takeLatest(AuthTypes.HANDLE_OTC_REDEMPTION, handleOTCRedemption, documentsApi, accountApi),
        takeLatest(AuthTypes.HANDLE_OTC_LOGIN_CALLBACK, handleLoginCallback),
        takeLatest(AuthTypes.SET_LOGIN_COMPLETE, finalizeOTCRedemption),
       
        // Messages
        takeEvery(MessagesTypes.SET_MESSAGE_SEND_STATE, sendCurrentMessage, messagesApi),
        takeLatest(MessagesTypes.GET_MESSAGES, queryCaseMessages, messagesApi),
        takeEvery(MessagesTypes.UPDATE_LAST_READ_DATE, updateLastReadDate, messagesApi),
        takeEvery('@@router/LOCATION_CHANGE', resetInputScreenVisibility),
        takeEvery(LayoutTypes.SET_DASHBOARD_TAB, resetInputScreenVisibility),
        takeEvery(MessagesTypes.SEND_INTERNAL_NOTE_NO_PRINTER, sendInternalNoteNoPrinter, messagesApi),

        // Intervew
        takeLatest(InterviewTypes.SET_INTERVIEW_PAGE, saveInterview, interviewApi, workflowApi),
        takeLatest(InterviewTypes.LOAD_INTERVIEW, loadInterview, interviewApi),

        // Confirm Id
        takeLatest(ConfirmIdentityTypes.GET_CONFIRM_ID_STATE, getConfirmIdState, confirmIdentityApi),
        takeEvery(ConfirmIdentityTypes.SAVE_WORKING_DATA, updateInterviewFromWorkingData, interviewApi),
        takeEvery(ConfirmIdentityTypes.CONFIRM_IDENTITY, confirmIdentity, confirmIdentityApi),
        takeEvery(ConfirmIdentityTypes.PROCESS_FILE_UPLOAD, processImageForUpload),
        takeEvery(ConfirmIdentityTypes.BEGIN_FILE_UPLOAD, beginFileUpload, confirmIdentityApi),
        takeEvery(ConfirmIdentityTypes.CONFIRM_IDENTITY_DOCS, confirmIdentityDocs, confirmIdentityApi),
        takeEvery(ConfirmIdentityTypes.COMPLETE_QR_CODE_VERIFICATION , completeQrCodeVerification, confirmIdentityApi),

        // Workflow
        takeLatest(WorkflowTypes.SET_CASE_SETTING, saveCaseSettings, workflowApi),
        takeLatest(WorkflowTypes.UPDATE_CASE_STATE, getCaseState, workflowApi),
        takeLatest(WorkflowTypes.DELETE_CASE, deleteCase, workflowApi),
        takeLatest(WorkflowTypes.SET_LAST_REVIEWED_PAGE, setLastReviewedPage, workflowApi),
        takeLatest(WorkflowTypes.SCHEDULE_CALL_ATTEMPT, notifyOnScheduleCallAttempt, workflowApi),

        // Workflow New Return
        takeLatest(WorkflowNewReturnTypes.START_NEW_RETURNS, createNewReturns, workflowApi),

        // authorizeCRA / eSignature
        takeEvery('@@router/LOCATION_CHANGE', loadAuthState, authorizeCRAApi),
        takeEvery(AuthorizeCRATypes.LOAD_AUTHORIZE_STATE, loadAuthState, authorizeCRAApi),
        takeEvery(AuthorizeCRATypes.AUTHORIZE_SELF, saveAuthState, authorizeCRAApi),
        takeEvery(AuthorizeCRATypes.AUTHORIZE_SELF_RQ, saveAuthState, authorizeCRAApi),
        takeEvery(AuthorizeCRATypes.AUTHORIZE_SPOUSE, saveAuthState, authorizeCRAApi),
        takeEvery(AuthorizeCRATypes.AUTHORIZE_SPOUSE_RQ, saveAuthState, authorizeCRAApi),
        takeEvery(AuthorizeCRATypes.COMPLETE_CRA_STEP, saveAuthState, authorizeCRAApi),
        takeEvery(AuthorizeCRATypes.COMPLETE_RQ_STEP, saveAuthState, authorizeCRAApi),
        takeEvery(AuthorizeCRATypes.AUTHORIZE_COMPLETE, saveAuthState, authorizeCRAApi),
        takeEvery(AuthorizeCRATypes.LOAD_AUTHORIZATION_CONFIG, getAuthorizeConfig, authorizeCRAApi),

        // Signing
        takeLatest(SigningTypes.SIGN_FORM, getSigningUrl, signingApi),
        takeLatest(SigningTypes.SIGN_EXTERNAL_FORM, getSigningUrl, signingApi),
        takeLatest(SigningTypes.SIGN_ADDITIONAL_FORM, getSigningUrl, signingApi),
        takeLatest(SigningTypes.PROCESS_DOCUMENT_CALLBACK, processDocumentCallback, signingApi, authorizeCRAApi),
        takeLatest(SigningTypes.CHECK_SIGNING_STATE, checkSigningState, signingApi),

        takeLatest(ApproveDraftsTypes.SET_APPROVAL_COMPLETE, setApprovalComplete, documentsApi, workflowApi),

        // Payment
        takeLatest(PaymentTypes.GET_PAYMENT_INFO, getPaymentInfo, paymentApi),
        takeLatest(PaymentTypes.COMPLETE_PAYMENT, completePayment, paymentApi),

        // Documents
        takeEvery(DocumentsTypes.SET_FILE_WAS_UPLOADED, setFileUploaded, documentsApi),
        takeEvery(DocumentsTypes.SET_UPLOAD_STEP_COMPLETED, setUploadStepCompleted, documentsApi),
        takeEvery(DocumentsTypes.SEND_REVIEW_COMPLETE_MESSAGE, sendReviewCompleteMessage, documentsApi),
        takeEvery(DocumentsTypes.SEND_REVIEW_COMPLETE_MESSAGE_WET_SIGNATURE, sendReviewCompleteMessageWetSignature, documentsApi),
        takeEvery(DocumentsTypes.GET_SUGGESTIONS, getSuggestions, documentsApi),

        // Products
        takeEvery(ProductsTypes.SAVE_POM_SELECTION, savePOMSelection, productsApi, workflowApi),
        takeEvery(ProductsTypes.GET_ADD_ON_PRODUCTS, getAddOnProducts, productsApi),

        // Files
        takeLatest(FilesTypes.LOAD_COMPLETED_DOCUMENTS, loadCompletedDocuments, documentsApi),
        takeLatest(FilesTypes.SET_COMPLETED_DOCUMENT_VIEWED, setCompletedFileViewed, documentsApi),
        takeLatest(FilesTypes.LOAD_DOCUMENTS_FOR_REVIEW, loadDocumentsForReview, documentsApi),
        takeLatest(FilesTypes.SET_DOCUMENT_FOR_REVIEW_VIEWED, setDocumentForReviewViewed, documentsApi),
        takeLatest(FilesTypes.LOAD_APPROVAL_DOCUMENTS, loadApprovalDocuments, documentsApi),

        // Document viewing
        takeLatest('@@router/LOCATION_CHANGE', resetApprovalState),
        // TODO: takeEvery(DocumentsTypes.SET_FILE_VIEWED, setFileViewed, documentsApi),
        // Document uploading / one time link sending
        takeEvery(DocumentsTypes.SET_ONE_TIME_CODE, sendOneTimeSMS, documentsApi),
        takeEvery(DocumentsTypes.SET_FILE_TO_BE_RENAMED, renameIOSFile, documentsApi),

        // Survey
        takeEvery(SurveyTypes.SUBMIT_SURVEY, createSurvey, surveyApi),

        // all clean-up that should happen at logout
        takeLatest(AuthTypes.LOGOUT_USER, initPreLogoutAction, 'logoutResetSigningState'),

        takeLatest(AuthTypes.LOGOUT_USER, startPreLogoutAction, logoutResetSigningState, 'logoutResetSigningState'),

        // VAI-483 sets pre-logout to complete if all actions are finished.
        takeEvery(AuthTypes.DONE_PRE_LOGOUT_ACTION, checkPreLogoutComplete), 

        // this is the last step as it will invalidate the access token
        takeLatest(AuthTypes.FINALIZE_LOGOUT, cleanupSessionState),
        takeLatest(AuthTypes.FINALIZE_LOGOUT, finalizeLogout, registerApi)
    ])
}