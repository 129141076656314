// src/components/Common/bottommenubar.js

import React, { Component } from 'react';
import { connect } from 'react-redux'
import './footer.css';
import BaseModal from '../../Modal/BaseModal';
import Settings from '../../Settings/settings';
import T from 'i18n-react';
import AuthActions from '../../../reducers/authRedux'
import LoginActions from '../../../reducers/loginRedux'
import LayoutActions from '../../../reducers/layoutRedux'
import FooterDesktop from './footerDesktop';
import FooterTablet from './footerTablet';
import FooterMobile from './footerMobile';
import LogoutCTA from '../../Logout/LogoutCTA';

class BottomMenuBar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            layoutType: this.props.layoutType,
            loginComplete: this.props.loginComplete,
            showSaveAndQuit: true,
            settingsDialogOpen: false,
            signOutDialogOpen: false,
            lang: this.props.lang,
            linkConfig: this.props.linkConfig,
            appReady: this.props.appInitialized
        }
    }

    closeDialog(ev) {
        this.setState({ settingsDialogOpen: false })   
        this.props.setDialogOpen(false)
    }

    closeSignOutDialog(ev) {
        this.setState({ signOutDialogOpen: false })
        this.props.setDialogOpen(false)
    }

    proceedSignOut(ev) {
        this.setState({ signOutDialogOpen: false });
        this.props.setDialogOpen(false)
        window.scrollTo(0, 0);
        this.props.logoutUser(null)
    }

    getLinkConfig(lang, linkName) {
        if (this.state.linkConfig && this.state.linkConfig[lang] && this.state.linkConfig[lang][linkName]) {
            return this.state.linkConfig[lang][linkName]
        }
        return null
    }

    openLink(lang, linkName, lookupKey) {
        var link = this.getLinkConfig(lang, linkName)
        if (!link) {
            link = T.translate(lookupKey)
        }
        window.open(link, '_blank')
    }

    handleAction(action) {
        switch (action) {
            case 'login': {
                window.scrollTo(0, 0);
                this.props.requestLogin()
                break;
            }
            case 'logout': {
                this.props.setDialogOpen(true)
                this.setState({ signOutDialogOpen: true })
                break;
            }
            case 'settings': {
                this.props.setDialogOpen(true)
                this.setState({ settingsDialogOpen: true })
                break;
            }
            case 'help': {
                this.openLink(this.props.lang, 'help_centre', 'Common.HelpCentre')
                break;
            }
            case 'support': {
                this.openLink(this.props.lang, 'support_link', 'Common.SupportLink')
                break;
            }
            case 'survey': {
                this.openLink(this.props.lang, 'survey_link', 'Common.SurveyLink')
                break;
            }
            case 'terms': {
                this.openLink(this.props.lang, 'terms_and_conditions', 'Common.TermsLink')
                break;
            }
            case 'privacy': {
                this.openLink(this.props.lang, 'privacy_policy', 'Common.PrivacyLink')
                break;
            }
            default: { }
        }
    }

    render() {
        var layoutType = this.props.layoutType

        var footer = null
        if (layoutType === 'mobile') {
            footer = (<FooterMobile loginComplete={this.props.loginComplete} appInitialized={this.props.appInitialized}
                lang={this.props.lang} buttonHandler={(action) => this.handleAction(action)} showOteContent={this.props.showOteContent}  />)
        } else if (layoutType === 'tablet' || layoutType === 'small_tablet') {
            footer = (<FooterTablet loginComplete={this.props.loginComplete} appInitialized={this.props.appInitialized}
                buttonHandler={(action) => this.handleAction(action)} showOteContent={this.props.showOteContent}  />)
        } else {
            footer = (<FooterDesktop loginComplete={this.props.loginComplete} appInitialized={this.props.appInitialized}
                buttonHandler={(action) => this.handleAction(action)} showOteContent={this.props.showOteContent}  />)
        }
        return (
            <div style={{ width: '100%', margin: '0px' }}>
                {footer}
                <BaseModal show={this.state.settingsDialogOpen} onClose={() => this.closeDialog({ value: '' })} layoutType={this.props.layoutType}>
                    <Settings onCloseDialog={(ev) => this.closeDialog(ev)} />
                </BaseModal>
                <BaseModal show={this.state.signOutDialogOpen} layoutType={this.props.layoutType}
                    onClose={(event) => {this.setState({ signOutDialogOpen: false }); this.props.setDialogOpen(false) }}>
                    <LogoutCTA proceedSignOut={(ev) => this.proceedSignOut(ev)}
                        closeSignOutDialog={(ev) => this.closeSignOutDialog(ev)}
                        layoutType={this.props.layoutType}
                    />
                </BaseModal>                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        layoutType: state.layout.layoutType,
        lang: state.layout.lang,
        loginComplete: state.auth.loginComplete,
        linkConfig: state.config.linkConfig,
        appInitialized: state.layout.applicationInitialized,
        showOteContent: state.workflow.is_direct_user === false
    }
}

const mapDispatchToProps = (dispatch) => ({
    logoutUser: (signOutType) => dispatch(AuthActions.logoutUser(signOutType)),
    requestLogin: () => dispatch(LoginActions.requestLogin()),
    setDialogOpen: (dialogOpen) => dispatch(LayoutActions.setDialogOpen(dialogOpen))
})

export default connect(mapStateToProps, mapDispatchToProps)(BottomMenuBar);