import T from 'i18n-react'
import { put, select } from 'redux-saga/effects'
import HelpCentre from './Help/helpCentre'
import LayoutActions from '../reducers/layoutRedux'

export function* updateLanguageTexts(action) {
    // update the i18n translation dictionary for the selected language
    let dictionary = require(`../components/App/i18n/${action.lang.substr(0, 2)}.json`)
    T.setTexts(dictionary)
    // now, update the language selection for the app, to trigger re-render.
    // note: this should be the only place the setLanguage action is called
    yield put(LayoutActions.setLanguage(action.lang))
}

export function* updateHelpCenterLanguage(action) {
    // update the help center links for the selected language
    let linkConfig = yield select(state => state.config.linkConfig)

    if (linkConfig) {
        if (action.lang === 'fr' && linkConfig.fr.help_centre) {
            HelpCentre.configure(linkConfig.fr.help_centre)
        } else if (action.lang === 'en' && linkConfig.en.help_centre) {
            HelpCentre.configure(linkConfig.en.help_centre)
        }
    }
}

export function updateLanguageCookie(action) {
    //update cookie with language preference
    let d = new Date()
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000)
    let expires = `expires=${d.toUTCString()}`
    document.cookie = `langPref=${action.lang};${expires};path=/`
}