import React, { Component } from 'react';
import './style.css';
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';
import DocumentIconButton from './documentIconButton'

class UploaderTypeChoice extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            layoutType: this.props.layoutType,
            lang: this.props.lang,
            onDialogClose: this.props.onDialogClose,
            uploadChoiceHandler: this.props.uploadChoiceHandler
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }
        if (this.state.onDialogClose !== newProps.onDialogClose) {
            this.setState({ onDialogClose: newProps.onDialogClose })
        }

        if (this.state.uploadChoiceHandler !== newProps.uploadChoiceHandler) {
            this.setState({ uploadChoiceHandler: newProps.uploadChoiceHandler })
        }

        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
    }

    handleThisComputerClick(ev) {
        if (this.state.uploadChoiceHandler) {
            this.state.uploadChoiceHandler('desktop')
        }
    }

    handleMobileClick(ev) {
        if (this.state.uploadChoiceHandler) {
            this.state.uploadChoiceHandler('mobile')
        }
    }

    handleDialogCloseClick(ev) {
        if (this.state.onDialogClose) {
            this.state.onDialogClose(ev)
        }
    }

    render() {     
        return (
            <div className="defaultStyles">
                <div className="bodyContainer" >                    
                    <div className="dialogContentContainer leftSpacing rightSpacing maxDialogWidth">
                        <div className='dialogContentContainer greenContent'>
                            <FontAwesome style={{ marginBottom: '20px' }} className='fa-cloud-upload' name='cloud-upload' size='5x' />
                            <span className="dialogTitle uploadDialogTitle">
                                {T.translate('Uploader.Choice.Title')}
                            </span>
                        </div>
                        <div style={{marginTop: '20px'}} />
                        <DocumentIconButton isEnabled icon="fa-desktop" baseStyle='defaultDocumentsButtonStyleUploader'
                            label="Uploader.Choice.ComputerButton" layoutType='tablet'
                            onClick={(ev) => this.handleThisComputerClick(ev)} size='2x'/>
                        <DocumentIconButton isEnabled icon="fa-mobile" baseStyle='defaultDocumentsButtonStyleUploader'
                            label="Uploader.Choice.MobileButton" layoutType='tablet'
                            onClick={(ev) => this.handleMobileClick(ev)} size='3x' />
                    </div>
                </div>                
            </div>
        )
    }
}

export default UploaderTypeChoice;