import React, { useState, useEffect } from 'react';
import './style.css';
import T from 'i18n-react';
import logo from '../../images/logo_small.png'
import DOMPurify from 'dompurify';

const MessageEntry = props => {

    const { messageText, timestamp, isBlockMessage, isNew, labelText, lang } = props

    const [sanitizedContent, setSanitizedContent] = useState('')

    const months_en = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December']

    const months_fr = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet',
        'août', 'septembre', 'octobre', 'novembre', 'décembre'];

    const addBlankTargetToLinks = (message) => {
        if (message && message.includes('a href') && !message.includes('target=')) {
            return message.replace(/rel=/gi, 'target="blank" rel=');
        }
        return message;
    }

    const formatTimestamp = () => {
        var datetime = new Date(timestamp)
        if (lang === 'fr') {
            return datetime.getDate() + ' ' + months_fr[datetime.getMonth()] + ' ' + datetime.getFullYear() + ', ' +
                   datetime.getHours() + 'h' + (datetime.getMinutes() < 10 ? '0' : '') + datetime.getMinutes()
        } else {
            var h = datetime.getHours();
            var hours = h > 12 ? h - 12 : h;
            var ampm = h >= 12 ? 'pm' : 'am'
            return months_en[datetime.getMonth()] + ' ' + datetime.getDate() + ', ' + datetime.getFullYear() + ' ' 
                 + hours + ':' + (datetime.getMinutes() < 10 ? '0' : '') + datetime.getMinutes() + ampm
        }
    }

    useEffect(() => {
        // first, add any missing target='_blank' link attributes
        let tabified = addBlankTargetToLinks(messageText)
        // now, sanitize, but allow our blank targets through
        let sanitized = DOMPurify.sanitize(tabified, { ADD_ATTR: ['target'] })
        // now update the text for render
        setSanitizedContent(sanitized)
    }, [messageText])

    return (
        <div style={{ flexShrink: 0 }}>
            <div className="messageEntryHeader messagesLeftMargin messagesRightMargin">
                <span>{formatTimestamp()}</span>
                {isNew && <span className="messageItemNewStyle">{T.translate("Messages.Labels.New")}</span>}
            </div>
            <div id="pii-exclude" className="messageEntryBody messagesLeftMargin messagesRightMargin">
                <div className="messageEntryLogo">
                    {isBlockMessage ?
                        <img src={logo} alt={T.translate('Home.hrbLogoAltText')} className='message-logo' /> :
                        <div className="messageItemLogoBlock">
                            <span>{labelText}</span>
                        </div>}
                </div>
                <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
            </div>
            <div className="messagesSpacerDiv messagesLeftMargin messagesRightMargin" />
        </div>
    )
}

export default MessageEntry;