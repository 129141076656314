import React, { Component } from 'react';
import { connect } from 'react-redux'
import '../Common/shared.css'
import './style.css';

import BaseModal from '../Modal/BaseModal';
import InactivityWarning from '../Logout/inactivityWarning'

class ActivityMonitor extends Component {
    constructor(props) {
        super(props)

        this.logoutThreshold = 1000 * 60 * process.env.REACT_APP_AUTOLOGOUT_TIMER_MINUTES;
        this.warningThreshold = 1000 * 60 * process.env.REACT_APP_AUTOLOGOUT_WARNING_TIMER_MINUTES

        this.activityTimerInterval = 1000 * process.env.REACT_APP_ACTIVITY_TIMER_INTERVAL_SECONDS  // check every 1 minute for activity
        this.countdownTimerInterval = 1000  // update the countdown every 1s

        // This variable will be true if the window is currently inactive. When
        // the window comes back into focus, we'll want to continue activity checking.
        this.paused = false;

        this.activityTimer = null
        this.countdownTimer = null

        this.countdownStartTime = new Date().getTime()

        window.onload = () => { this.resetActivity() }
        window.onmousemove = () => { this.resetActivity() }
        window.onkeydown  = () => { this.resetActivity() }
        window.onmousedown = () => { this.resetActivity() }
        window.onscroll = () => { this.resetActivity() }
        window.onclick = () => { this.resetActivity() }
        
        this.state = {
            // event handlers
            remainingTimeStr: null,
            showWarningDialog: false,
            lastActivityTime: null
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.windowActive !== this.windowActive) {
            this.paused = !newProps.windowActive
            if (!this.paused) this.checkActivity()
        }

        if (this.props.loginComplete !== newProps.loginComplete) {
            this.onAuthDataChange(newProps.loginComplete)
        }
    }

    componentDidMount() {
        this.resetActivity();
        if (this.props.loginComplete) {
            this.onAuthDataChange(this.props.loginComplete)
        }
    }

    resetActivity() {
        this.setState(Object.assign(this.state, { lastActivityTime: new Date().getTime()}))
    }

    onAuthDataChange(loginComplete) {
        this.resetActivity();
        if (loginComplete) {
            this.startActivityTimer()
        } else {
            this.resetTimers()
        }
    }

    continueActivity() {
        this.resetActivity()
        this.resetCountdownTimer()
        this.setState({ showWarningDialog: false })
    }

    doLogout(isAuto) {
        if (!this.paused && this.props.loginComplete) {
            this.resetTimers()
            this.setState({ showWarningDialog: false })
            this.props.logoutHandler(isAuto)
        }
    }

    checkActivity() {
        if (!this.paused) {
            // check to see if the time since the last activity is over our thresholds
            var currentOffset = new Date().getTime() - this.state.lastActivityTime

            if (currentOffset > this.logoutThreshold + this.activityTimerInterval) {
                this.doLogout(true)
            } else if (currentOffset >= this.warningThreshold && currentOffset < this.logoutThreshold && this.activityTimer && !this.countdownTimer) {
                this.countdownStartTime = new Date().getTime()
                this.startCountdownTimer()
            } 
        }
    }

    checkCountdown() {
        var timeRemaining = (this.logoutThreshold - this.warningThreshold) - (new Date().getTime() - this.countdownStartTime)
        if (timeRemaining <= 0 && this.countdownTimer) {
            this.doLogout(true)
        } else {
            this.setState({ showWarningDialog: true, remainingTimeStr: this.formatRemainingTime(timeRemaining) })
        }        
    }

    formatRemainingTime(timeRemaining) {
        // time remaining is in ms, we want to show min:ss
        var mins = Math.floor(timeRemaining / (60 * 1000))
        var seconds = Math.floor(timeRemaining / 1000) - mins * 60
        return '' + mins + ':' + (seconds < 10 ? '0' : '') + seconds
    }

    startActivityTimer() {
        this.resetActivityTimer();
        this.activityTimer = setInterval(() => { this.checkActivity() }, this.activityTimerInterval)
        this.checkActivity()
    }

    startCountdownTimer() {
        this.resetCountdownTimer()
        this.countdownTimer = setInterval(() => { this.checkCountdown() }, this.countdownTimerInterval)
        this.checkCountdown()
    }

    render() {
        return (
            <BaseModal show={this.state.showWarningDialog } onClose={() => this.continueActivity()} layoutType={this.props.layoutType}>            
                <InactivityWarning
                    closeDialog={(ev) => this.continueActivity()}
                    remainingTimeString={this.state.remainingTimeStr}
                    onContinue={(ev) => this.continueActivity()}
                    onLogout={(ev) => this.doLogout(false)}
                    layoutType={this.props.layoutType}
                />
            </BaseModal>
        )
    }

    resetTimers() {
        this.resetActivityTimer()
        this.resetCountdownTimer()
    }

    resetActivityTimer() {
        if (this.activityTimer) {
            clearInterval(this.activityTimer)
            this.activityTimer = null
        }
    }

    resetCountdownTimer() {
        if (this.countdownTimer) {
            clearInterval(this.countdownTimer)
            this.countdownTimer = null
        }
    }
}

const mapStateToProps = (state) => {
    return {
        lastActivityTime: state.auth.lastActivityTime,
        accessToken: state.auth.accessToken,
        loginComplete: state.auth.loginComplete,
        layoutType: state.layout.layoutType,
        windowActive: state.layout.windowActive
    }
}


export default connect(mapStateToProps)(ActivityMonitor);