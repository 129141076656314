import React, { useContext } from 'react'
import { func } from 'prop-types'

// create the context
export const TranslationContext = React.createContext(key => key)

// make a hook for easy importing and usage
export const useTranslationContext = () => useContext(TranslationContext)

// export provider as a separate component
export const TranslationContextProvider = props =>  (
    <TranslationContext.Provider value={props.onTranslateString}>
        {props.children}
    </TranslationContext.Provider>
)

TranslationContextProvider.propTypes = {
    onTranslateString: func.isRequired
}

TranslationContextProvider.whyDidYouRender = false