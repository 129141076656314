import classnames from 'classnames';
import T from 'i18n-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import '../Common/shared.css';
import '../Login/style.css';

const LoadingSpinner = ({ layoutType, messageKeys }) => {
    const [messageIndex, setMessageIndex] = useState(0);
    const [addShow, setAddShow] = useState(false)

    var wrapperStyle = layoutType === 'mobile' ?
        { flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', height: 'calc(100vw)' } :
        { flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }

    var messageContent = messageKeys
        ? <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <span className={classnames('rm-text', { 'rm-show': addShow })}>{T.translate(messageKeys[messageIndex])}</span>
        </div>
        : null

    useEffect(() => {
        if (!messageKeys) {
            return;
        }

        var numberOfMessages = messageKeys.length;

        let initial = async () => {
            setAddShow(true);
            setMessageIndex(current => (current));

            await new Promise(resolve => setTimeout(() => {
                setAddShow(false);
                resolve();
            }, 2000));
         }

        initial();

        const timer = setInterval(async () => {
            setAddShow(true);

            setMessageIndex(current => current !== numberOfMessages - 1 ? (current + 1)  : current);
             

            await new Promise(resolve => setTimeout(() => {
                setAddShow(false);
                resolve();
            }, 2000));
        }, 2200)

        return () => {
            clearInterval(timer); 
        }
    }, [])

    return (
        <div className="registerRootStyle">
            <div className="defaultStyles">
                <div style={wrapperStyle}>
                    <div style={{ flex: 1 }}>
                        <div className="centerContainer">
                            <FontAwesome className='fa-spinner' name='spinner' pulse size='3x' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#14AA40', align: 'center' }} />
                            {messageContent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

LoadingSpinner.propTypes = {
    messageKeys: PropTypes.array
}

const mapStateToProps = (state) => {
    return {
        layoutType: state.layout.layoutType,
    }
}

export default connect(mapStateToProps, null)(LoadingSpinner)
