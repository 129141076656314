import React, { useState } from 'react';
import './acc.css'
import AccordianBanner from './accordianBanner';

const AccordianControl = ({ labelKey, defaultOpen, children }) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);

    const toggleOpen = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className='acc-outer'>
            <AccordianBanner labelKey={labelKey} isOpen={isOpen} toggleOpen={toggleOpen} />
            {isOpen &&
                <div className='acc-inner'>
                    {children}
                </div>
            }
        </div>
    )
}

export default AccordianControl

