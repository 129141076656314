import React, { Component } from 'react';
import {colorStyles} from './colorStyles';
import T from 'i18n-react';
import Select from 'react-select'

class MaritalStatusDropDown extends Component {
    constructor(props) {
        super(props)

        this.maritalStatusOptions = [
            T.translate('Common.MaritalStatus.Option0'),
            T.translate('Common.MaritalStatus.Option1'),
            T.translate('Common.MaritalStatus.Option2'),
            T.translate('Common.MaritalStatus.Option3'),
            T.translate('Common.MaritalStatus.Option4'),
            T.translate('Common.MaritalStatus.Option5'),
        ]

        this.maritalStatusKeys = ['S', 'M', 'C', 'D', 'SP', 'W']

        this.state = {
            selectedOption: this.props.value,
            name: this.props.name,
            missingRequiredField: this.props.missingRequiredField,
            lang: this.props.lang
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.lang !== newProps.lang) {
            this.maritalStatusOptions = [
                T.translate('Common.MaritalStatus.Option0'),
                T.translate('Common.MaritalStatus.Option1'),
                T.translate('Common.MaritalStatus.Option2'),
                T.translate('Common.MaritalStatus.Option3'),
                T.translate('Common.MaritalStatus.Option4'),
                T.translate('Common.MaritalStatus.Option5'),
            ]
            this.setState({lang: newProps.lang})
        }
       
        if (this.state.selectedOption !== newProps.value) {
            this.setState({ selectedOption: newProps.value })
        }
        if (this.state.missingRequiredField !== newProps.missingRequiredField) {
            this.setState({ missingRequiredField: newProps.missingRequiredField })
        }
    }

    handleMaritalStatusChange(evData) {        
        var state = {
            ...this.state,
            selectedOption: evData?.value
        }

        this.setState(state);
        if (this.props.onValueChanged) {
            var ev = { target: { name: this.props.name } }
            this.props.onValueChanged(ev, evData?.value)
        }
    }

    render() {
        var classNames = ['select_container_div']
        if (this.state.missingRequiredField) {
            classNames.push('validationError')
        }

        var options = []
        for (var i = 0; i < this.maritalStatusOptions.length; ++i) {
            options.push({ value: this.maritalStatusKeys[i], label: this.maritalStatusOptions[i] })
        }

        var dropdownValue = this.state.selectedOption ? {
            label: options.find(a => a.value == this.props.value)?.label,
            value: this.state.selectedOption
        } : '';

        return (
            <div className={classNames.join(' ')}>
                <Select
                    inputId="maritalstatus-select"
                    ref={(ref) => { this.select = ref; }}
                    options={options}
                    simpleValue
                    isClearable={false}
                    name="selected-maritalstatus"
                    disabled={false}
                    value={dropdownValue}
                    onChange={(ev) => this.handleMaritalStatusChange(ev)}
                    searchable={true}
                    placeholder={T.translate('Common.Choose')}
                    menuShouldScrollIntoView={false}
                    matchPos='start'
                    openMenuOnFocus={true}
                    styles={colorStyles}
                    backspaceRemovesValue={true}
                />
            </div>
        )
    }
}


export default MaritalStatusDropDown;