import React, { Component } from 'react';
import './shared.css';

class CheckButtons extends Component {
    constructor(props) {
        super(props)

        this.state = {
            buttons: this.props.buttonData,
            missingRequiredField: this.props.missingRequiredField,
            name: this.props.name,
            disabled: this.props.disabled
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.buttons !== newProps.buttonData) {
            this.setState({buttons: newProps.buttonData})
        }
        if (this.state.missingRequiredField !== newProps.missingRequiredField) {
            this.setState({ missingRequiredField: newProps.missingRequiredField })
        }
        if (this.state.disabled !== newProps.disabled) {
            this.setState({ disabled: newProps.disabled })
        }
    }

    onButtonClick(tag) {
        if (!this.state.disabled) {
            var buttonsState = []

            var buttons = {
                ...this.state.buttons
            }
            this.state.buttons.forEach((btn) => {
                if (btn.Tag === tag) {
                    btn.isChecked = !btn.isChecked;
                } else if (this.props.isRadio) {
                    btn.isChecked = false;
                }
                buttonsState.push({ Tag: btn.Tag, IsChecked: btn.isChecked });
            })
            this.setState(buttons)

            // raise the event for listeners
            if (this.props.onButtonStateChanged) {
                this.props.onButtonStateChanged({ buttonStates: buttonsState });
            }
        }
    }

    handleKeyDown(ev) {
        var BreakException = {};
        try {
            if (ev.keyCode === 32) {  // If spacebar fired the event
                var buttonsState = []

                var targetText = ev.target.textContent;

                var buttons = {
                    ...this.state.buttons
                }
                this.state.buttons.forEach((btn) => {
                    if (btn.Label === targetText) {
                        if (!btn.isChecked) {
                            btn.isChecked = true;

                            buttonsState.push({ Tag: btn.Tag, IsChecked: btn.isChecked });
                            this.setState(buttons)

                            // raise the event for listeners
                            if (this.props.onButtonStateChanged) {
                                this.props.onButtonStateChanged({ buttonStates: buttonsState });
                            }
                            throw BreakException;
                        }
                    }
                })
            }
        } catch (e) {
            if (e !== BreakException) throw e;
        }

    }

    getButtonClassName = (btn, isFirst, stateName, disabled) => {
        if (isFirst) {
            if (btn.isChecked) {
                if (stateName === "pomSelectionChange") {
                    return disabled ? "firstCheckButtonStyleChecked pomFirstYesNoButtonOverride button-disabled" : "firstCheckButtonStyleChecked pomFirstYesNoButtonOverride";
                }
                else {
                    return disabled ? "firstCheckButtonStyleChecked button-disabled" : "firstCheckButtonStyleChecked";
                }
            }
            else {
                if (stateName === "pomSelectionChange") {
                    return disabled ? "firstCheckButtonStyle pomFirstYesNoButtonOverride button-disabled" : "firstCheckButtonStyle pomFirstYesNoButtonOverride";
                }
                else {
                    return disabled ? "firstCheckButtonStyle button-disabled" : "firstCheckButtonStyle";
                }
            }
        }
        else {
            if (btn.isChecked) {
                if (stateName === "pomSelectionChange") {
                    return disabled ? "checkButtonStyleChecked pomYesNoButtonOverride button-disabled" : "checkButtonStyleChecked pomYesNoButtonOverride";
                }
                else {
                    return disabled ? "checkButtonStyleChecked button-disabled" : "checkButtonStyleChecked";
                }
            }
            else {
                if (stateName === "pomSelectionChange") {
                    return disabled ? "checkButtonStyle pomYesNoButtonOverride button-disabled" : "checkButtonStyle pomYesNoButtonOverride";
                }
                else {
                    return disabled ? "checkButtonStyle button-disabled" : "checkButtonStyle";
                }
            }
        }
    }

    render() {
        let containerClass = "genericHorizontalContainer"
        if (this.state.missingRequiredField) {
            containerClass += " validationError"
        }
        if(this.state.name === "pomSelectionChange") {
            containerClass += " pomYesNoButtonWidth"
        }
        var rowContent = this.state.buttons.map((btn) => {
            var tag = "checkBtn_" + btn.Tag;
            var isFirst = btn === this.state.buttons[0]
            return (
                <div tabIndex='0' key={tag} className={this.getButtonClassName(btn, isFirst, this.state.name, this.state.disabled)}
                    onClick={() => { this.onButtonClick(btn.Tag) }} onKeyDown={(ev) => this.handleKeyDown(ev)}>
                    <span className="unselectableLabel" unselectable="on">{btn.Label}</span>
                </div>
            )
        })
        return (
            <div className={containerClass}>
                {rowContent}
            </div>
        )
    }
}


export default CheckButtons;