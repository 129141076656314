import React, { Component } from 'react';
import {colorStyles} from './colorStyles';
import T from 'i18n-react';
import Select from 'react-select'

class SecurityQuestionDropDown extends Component {
    constructor(props) {
        super(props)

        this.securityQuestionOptions = [
            T.translate("AccountSetupCredentials.labels.Nickname"),
            T.translate("AccountSetupCredentials.labels.FavChildhoodFriend"),
            T.translate("AccountSetupCredentials.labels.StuffedAnimal"),
            T.translate("AccountSetupCredentials.labels.FavTeacher"),
            T.translate("AccountSetupCredentials.labels.FavCartoon"),
            T.translate("AccountSetupCredentials.labels.FirstPet"),
            T.translate("AccountSetupCredentials.labels.MothersMaiden"),
            T.translate("AccountSetupCredentials.labels.FavCity"),
            T.translate("AccountSetupCredentials.labels.FavSuperHero"),
            T.translate("AccountSetupCredentials.labels.FavAthlete")
        ]

        this.securityQuestionKeys = [0, 1, 2, 3, 4, 5, 6, 7, 8 , 9]

        this.state = {
            selectedOption: this.props.value,
            name: this.props.name,
            lang: this.props.lang,
            missingRequiredField: this.props.missingRequiredField,
            isRequired: this.props.isRequired
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.lang !== newProps.lang) {
            this.securityQuestionOptions = [
                T.translate("AccountSetupCredentials.labels.Nickname"),
                T.translate("AccountSetupCredentials.labels.FavChildhoodFriend"),
                T.translate("AccountSetupCredentials.labels.StuffedAnimal"),
                T.translate("AccountSetupCredentials.labels.FavTeacher"),
                T.translate("AccountSetupCredentials.labels.FavCartoon"),
                T.translate("AccountSetupCredentials.labels.FirstPet"),
                T.translate("AccountSetupCredentials.labels.MothersMaiden"),
                T.translate("AccountSetupCredentials.labels.FavCity"),
                T.translate("AccountSetupCredentials.labels.FavSuperHero"),
                T.translate("AccountSetupCredentials.labels.FavAthlete")
            ]

            this.setState({ lang: newProps.lang })
        }

        if (this.state.missingRequiredField !== newProps.missingRequiredField) {
            this.setState({ missingRequiredField: newProps.missingRequiredField })
        }
        if (this.state.isRequired !== newProps.isRequired) {
            this.setState({ isRequired: newProps.isRequired })
        }
        if (newProps.value !== this.state.selectedOption) {
            this.setState({ selectedOption: newProps.value })
        }
    }

    cleanInput(inputValue) {
        if (inputValue) {
            return inputValue.replace('‘', '\'').replace('’', '\'')
        }
        return inputValue
    }

    handleSecurityQuestionChange(evData) {
        var state = {
            ...this.state,
            selectedOption: evData?.value
        }

        this.setState(state);
        if (this.props.onValueChanged) {
            this.props.onValueChanged(evData, evData?.value)
        }
    }

    render() {
        var classNames = ['select_container_div']
        if (this.state.missingRequiredField && this.state.isRequired && !this.state.selectedOption) {
            classNames.push('validationError')
        }

        var options = []
        for (var i = 0; i < this.securityQuestionOptions.length; ++i) {
            options.push({ value: this.securityQuestionKeys[i], label: this.securityQuestionOptions[i] })
        }

        var dropdownValue = this.state.selectedOption !== -1 ? {
            label: options.find(a => a.value == this.props.value)?.label,
            value: this.state.selectedOption
        } : -1;

        return (
            <div className={classNames.join(' ')}>
                <Select
                    inputId="securityquestions-select"
                    ref={(ref) => { this.select = ref; }}
                    heap-ignore="true"
                    options={options}
                    simpleValue
                    isClearable={false}
                    name="selected-securityquestion"
                    disabled={false}
                    value={dropdownValue}
                    onChange={(ev) => this.handleSecurityQuestionChange(ev)}
                    searchable={true}
                    placeholder={T.translate('Common.Choose')}
                    menuShouldScrollIntoView={false}
                    matchPos='start'
                    openMenuOnFocus={true}
                    onInputChange={(val) => this.cleanInput(val)}
                    styles={colorStyles}
                    backspaceRemovesValue={true}
                />
            </div>
        )
    }
}


export default SecurityQuestionDropDown;