import React, { useEffect, useState } from 'react';
import { useQuill } from 'react-quilljs';
import PropTypes from 'prop-types';
import './quill.snow.css';
import './MessageInputEditor.scss';
import T from 'i18n-react';
import { useSelector, shallowEqual } from 'react-redux';

const MessageInputEditor = ({ onInputChanged, messageContent, disabled, visible }) => {

    const layoutType = useSelector(state => (state.layout.layoutType));
    
    const [quillInitialized, setQuillInitialized] = useState(false);

    const theme = 'snow';
    const limit = 10 * 1024;

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link']
        ],
    }

    const formats = [
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet', 'indent',
        'link'
    ]

    const placeholder = T.translate("Messages.Labels.WriteMessage");

    const { quill, quillRef } = useQuill({ theme, modules, formats, placeholder });

    useEffect(() => {
        // since we aren't using this as a regular input
        // we only care if the content has been cleared by the parent.
        // In this case, clear the input, but we can safely ignore all other updates.
        if (!messageContent && !!quill) {
            quill.setText('')
        }
    }, [messageContent])

    useEffect(() => {
        // this would be better if it could be css, but that's not an option here.
        if (quill) {
            if (disabled) {
                quill.disable()
            } else {
                quill.enable()
            }
        }
    }, [disabled])

    useEffect(() => {
        // set up event handlers for the quill editor
        if (quill && !quillInitialized) {
            setQuillInitialized(true)

            quill.on('text-change', () => {
                if (quill.getLength() > limit) {
                    quill.deleteText(limit, quill.getLength());
                }
                onInputChanged(quillRef.current.firstChild.innerHTML);
            });
        }
    }, [quill]);

    useEffect(() => {
        if (quillInitialized) {
            let editor_elements = document.getElementsByClassName('ql-editor')
            if (editor_elements && editor_elements[0]) {
                editor_elements[0].classList.toggle('custom-scroll');
            }
            //For POM message prefill
            if (messageContent?.length > 0) {
                quill.setContents(quill.clipboard.convert(messageContent))
            }
            else {
                quill.setText('')
            }
            if (!visible) {
                quill.setText('')
            }
        }
    }, [quillInitialized])

    return (<div className="quill mb-1">
        <div ref={quillRef} id='quill-input' className={layoutType !== 'desktop' ? layoutType + " custom-scroll" : "custom-scroll"} />
    </div>)
}

MessageInputEditor.propTypes = {
    onInputChanged: PropTypes.func.isRequired,
    messageContent: PropTypes.string,
    disabled: PropTypes.bool
}

export default MessageInputEditor