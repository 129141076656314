import React, { Component } from 'react';
import {colorStyles} from './colorStyles';
import T from 'i18n-react';
import Select from 'react-select'

class RelationDropDown extends Component {
    constructor(props) {
        super(props)

        this.relationOptions = [
            T.translate('Common.Relations.Option0'),
            T.translate('Common.Relations.Option1'),
            T.translate('Common.Relations.Option2'),
            T.translate('Common.Relations.Option3'),
            T.translate('Common.Relations.Option4'),
            T.translate('Common.Relations.Option5'),
            T.translate('Common.Relations.Option6'),
            T.translate('Common.Relations.Option7'),
        ]

        this.relationKeys = ['P', 'B', 'S', 'C', 'GC', 'GP', 'UA', 'NN']

        this.state = {
            selectedOption: this.props.value,
            name: this.props.name,
            lang: this.props.lang
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.lang !== newProps.lang) {
            this.relationOptions = [
                T.translate('Common.Relations.Option0'),
                T.translate('Common.Relations.Option1'),
                T.translate('Common.Relations.Option2'),
                T.translate('Common.Relations.Option3'),
                T.translate('Common.Relations.Option4'),
                T.translate('Common.Relations.Option5'),
                T.translate('Common.Relations.Option6'),
                T.translate('Common.Relations.Option7'),
            ]
            this.setState({ lang: newProps.lang})
        }

        if (this.props.value !== newProps.value) {
            this.setState({ selectedOption: newProps.value });
        }

    }

    handleRelationChange(evData) {
        var state = {
            ...this.state,
            selectedOption: evData?.value
        }

        this.setState(state);
        if (this.props.onValueChanged) {
            var ev = { target: { name: this.props.name } }
            this.props.onValueChanged(ev, evData?.value)
        }
    }

    render() {
        var classNames = ['select_container_div']
        if (this.state.missingRequiredField) {
            classNames.push('validationError')
        }

        var options = []
        for (var i = 0; i < this.relationOptions.length; ++i) {
            options.push({ value: this.relationKeys[i], label: this.relationOptions[i] })
        }

        var dropdownValue = this.state.selectedOption ? {
            label: options.find(a => a.value == this.props.value)?.label,
            value: this.state.selectedOption
        } : '';

        return (
            <div className={classNames.join(' ')}>
                <Select
                    inputId="relation-select"
                    ref={(ref) => { this.select = ref; }}
                    options={options}
                    simpleValue
                    isClearable={false}
                    name="selected-relation"
                    disabled={false}
                    value={dropdownValue}
                    onChange={(ev) => this.handleRelationChange(ev)}
                    searchable={true}
                    placeholder={T.translate('Common.Choose')}
                    menuShouldScrollIntoView={false}
                    matchPos='start'
                    openMenuOnFocus={true}
                    styles={colorStyles}
                    backspaceRemovesValue={true}
                />
            </div>
        )
    }
}


export default RelationDropDown;