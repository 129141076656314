import React, { Component } from 'react';
import {colorStyles} from './colorStyles';
import T from 'i18n-react';
import Select from 'react-select'

class GenderDropDown extends Component {
    constructor(props) {
        super(props)

        this.genderOptions = [
            T.translate('Common.Gender.Option0'),
            T.translate('Common.Gender.Option1')
        ]

        this.genderKeys = ['M', 'F']
       
        this.state = {
            selectedOption: this.props.value,
            name: this.props.name,
            lang: this.props.lang,
            missingRequiredField: this.props.missingRequiredField,
            isRequired: this.props.isRequired
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.lang !== newProps.lang) {
            this.genderOptions = [
                T.translate('Common.Gender.Option0'),
                T.translate('Common.Gender.Option1')
            ]
            this.setState({ lang: newProps.lang })
        }

        if (this.state.missingRequiredField !== newProps.missingRequiredField) {
            this.setState({ missingRequiredField: newProps.missingRequiredField })
        }
        if (this.state.isRequired !== newProps.isRequired) {
            this.setState({ isRequired: newProps.isRequired })
        }

        if (newProps.value !== this.state.selectedOption) {
            this.setState({ selectedOption: newProps.value })
        }
    }

    handleGenderChange(evData) {
        var state = {
            ...this.state,
            selectedOption: evData?.value
        }

        this.setState(state);
        if (this.props.onValueChanged) {
            var ev = { target: { name: this.props.name } }
            this.props.onValueChanged(ev, evData?.value)
        }
    }

    render() {
        var classNames = ['select_container_div', 'bonus_bottom_margin']
        if (this.state.missingRequiredField && this.state.isRequired && !this.state.selectedOption) {
            classNames.push('validationError')
        }

        var options = []
        for (var i = 0; i < this.genderOptions.length; ++i) {
            options.push({ value: this.genderKeys[i], label: this.genderOptions[i] })
        }

        var dropdownValue = this.state.selectedOption ? {
            label: options.find(a => a.value == this.props.value)?.label,
            value: this.state.selectedOption
        } : '';

        return (
            <div className={classNames.join(' ')}>
                <Select
                    inputId="gender-select"
                    heap-ignore="true"
                    ref={(ref) => { this.select = ref; }}
                    options={options}
                    simpleValue
                    isClearable={false}
                    name="selected-gender"
                    disabled={false}
                    value={dropdownValue}
                    onChange={(ev) => this.handleGenderChange(ev)}
                    searchable={true}
                    placeholder={T.translate('Common.Choose')}
                    menuShouldScrollIntoView={false}
                    matchPos='start'
                    openMenuOnFocus={true}
                    styles={colorStyles}
                    backspaceRemovesValue={true}
                />
            </div>
        )
    }
}


export default GenderDropDown;