import classnames from 'classnames';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslationContext } from '../hooks/useTranslationContextHook';
import './dox-buttons.css';
import FontAwesome from 'react-fontawesome';

const DoxButton = ({ elementId, labelKey, children, buttonStyles, disabled, onClick, loading, tabIndex, params }) => {

    const translateString = useTranslationContext();

    const click = e => {
        if (onClick && !disabled) {
            onClick(e)
        }
    }

    return (
        <button className={classnames("dox-button", buttonStyles, { 'is-loading': Boolean(loading) }, { 'disabled': Boolean(disabled) })}
            id={elementId} tabIndex={tabIndex}
            onClick={click}>
            {labelKey && !loading &&
                translateString(labelKey, params)
            }
            {children && !loading && children}
            {loading && 
                <FontAwesome className='fa-spinner' name='spinner' pulse />
            }
        </button>
    );
};

DoxButton.propTypes = {
    elementId: PropTypes.string.isRequired,
    labelKey: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    tabIndex: PropTypes.number,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    buttonStyles: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    params: PropTypes.object
};

export default DoxButton;