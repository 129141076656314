import React from 'react'
import '../dox_explorer.css'
import { useTranslationContext } from '../../Shared/hooks/useTranslationContextHook'

const DoxExplorerGenericErrorMessage = () => {
    const translateString = useTranslationContext()

    const refresh = () => {
        window.location.reload()
    }

    return (
        <div className='doxe-msg-outer'>
            <div className='doxe-msg with-error'>
                <p><b>{translateString('Dox.DocumentExplorer.Errors.GenericTitle')}</b></p>
                <p className='doxe-err'><b>{translateString('Dox.DocumentExplorer.Errors.GenericTitle2')}</b></p>
                <p>{translateString('Dox.DocumentExplorer.Errors.GenericContent', {
                    TryAgainLink: <a onClick={refresh}>{translateString('Common.TryAgain').toLowerCase()}</a>
                })}</p>
            </div>
        </div>
    )
}

   

export default DoxExplorerGenericErrorMessage;