import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import T from 'i18n-react';
import './workflowpanel.css'

const WelcomeBlock = ({ firstName, firstLogin }) => (
    <div className={classNames('wf-wp-welcome-container')}>
        <h1>{T.translate(firstLogin ? 'Dashboard.labels.Hi' : 'Dashboard.labels.Welcome', { FirstName: firstName })}</h1>        
    </div>
);

WelcomeBlock.propTypes = {
    firstName: PropTypes.string.isRequired,
    firstLogin: PropTypes.bool.isRequired
}

export default WelcomeBlock;
