import React, { Component, Fragment } from 'react';
import '../../Common/shared.css'
import '../style.css';
import FontAwesome from 'react-fontawesome';
import T from 'i18n-react';
import PersonalInfoForm from '../../Common/personalInfoForm'
import YesNoQuestion from '../../Common/yesNoQuestion'
import HighlightedSection from '../../Interview/highlightedSection'
import HelpCentre from '../../../sagas/Help/helpCentre'
import MaritalStatusDropDown from '../../Controls/DropDowns/maritalStatusDropDown'

import ProvinceDropDown from '../../Controls/DropDowns/provinceDropDown'
import LearnMore from '../../Controls/learnMore'

class InterviewPage0 extends Component {
    constructor(props) {
        super(props)

        var primary = this.props.primary || {
            firstName: '',
            middleName: '',
            lastName: '',
            gender: '',
            dob: '',
            sin: '',
            nameChange: ''
        }

        var spouse = this.props.spouse || {
            firstName: '',
            middleName: '',
            lastName: '',
            gender: '',
            dob: '',
            sin: '',
            nameChange: ''
        }

        this.state = {
            primary,
            spouse,
            missingRequiredField: this.props.missingRequiredField,
            layoutType: this.props.layoutType,
            lang: this.props.lang,
            taxYear: this.props.taxYear,
        }
    }

    helpClick() {
        HelpCentre.openHelpLink('FAQs/WhatShouldIDoIfMyOrMySpousesNameChanged')
    }

    onSpousePersonalInfoChanged(personalInfo) {
        var spouse = {
            ...this.state.spouse,
            firstName: personalInfo.firstName,
            middleName: personalInfo.middleName,
            lastName: personalInfo.lastName,
            gender: personalInfo.gender,
            dob: personalInfo.dob,
            sin: personalInfo.sin,
            nameChange: personalInfo.nameChange
        }
        this.setState({ spouse })
        if (this.props.onSpouseChanged) {
            this.props.onSpouseChanged(spouse)
        }
    }

    onPersonalInfoChanged(personalInfo) {
        var primary = {
            ...this.state.primary,
            firstName: personalInfo.firstName,
            middleName: personalInfo.middleName,
            lastName: personalInfo.lastName,
            gender: personalInfo.gender,
            dob: personalInfo.dob,
            sin: personalInfo.sin,
            nameChange: personalInfo.nameChange
        }
        this.setState({ primary })
        if (this.props.onPrimaryChanged) {
            this.props.onPrimaryChanged(primary)
        }
    }

    validateNameField(textValue) {
        return !textValue || textValue.match(/^[a-zA-Z\u00C0-\u017F\'\.\-\s]+$/)   // eslint-disable-line no-useless-escape
    }

    handleMaritalStatusChange(ev, value) {
        var primary = {
            ...this.state.primary,
        }
        primary[ev.target.name] = value

        if (ev.target.name === 'maritalStatus') {
            if ((value === 'S') || (value === 'D') || (value === 'SP') || (value === 'W')) {
                primary.spouseLiveApart = '' 
                primary.spouseTaxes = ''
            }
        }

        var state = {
            ...this.state,
            primary,
        }

        this.setState(state);
        if (this.props.onPrimaryChanged) {
            this.props.onPrimaryChanged(primary)
        }
    }

    checkViewportWidth() {
        return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    }

    onYesNoButtonChange(ev, value) {
        var primary = {
            ...this.state.primary
        }
        primary[ev.target.name] = value
        this.setState({ primary })
        if (this.props.onPrimaryChanged) {
            this.props.onPrimaryChanged(primary)
        }
    }

    handlePrimaryProvinceChange(ev, prov) {
        var primary = {
            ...this.state.primary
        }
        primary[ev.target.name] = prov
        this.setState({ primary })

        if (this.props.onPrimaryChanged) {
            this.props.onPrimaryChanged(primary)
        }
    }

    handleSpouseProvinceChange(ev, prov) {
        var spouse = {
            ...this.state.spouse
        }
        spouse[ev.target.name] = prov
        this.setState({ spouse })

        if (this.props.onSpouseChanged) {
            this.props.onSpouseChanged(spouse)
        }
    }

    render() {
        var translationProps = { TaxYear: this.props.taxYear }

        let validationContent = this.props.missingRequiredField && !this.state.primary.residenceProvince ? (
            <div className="validationErrorStyle">
                <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#E70000', align: 'center' }} />
                <span style={{ marginLeft: 10 }}>{T.translate('Common.Errors.MissingRequiredField')}</span>
            </div>
        ) : null;

        let spouseValidationContent = this.props.missingRequiredField && !this.state.spouse.residenceProvince ? (
            <div className="validationErrorStyle">
                <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#E70000', align: 'center' }} />
                <span style={{ marginLeft: 10 }}>{T.translate('Common.Errors.MissingRequiredField')}</span>
            </div>
        ) : null;


        var viewportWidth = this.checkViewportWidth()
        let missingMaritalStatus = this.props.missingRequiredField && !this.state.primary.maritalStatus
        let missingSpouseTaxes = this.props.missingRequiredField && !this.state.primary.spouseTaxes
        let missingPrimaryProv = this.props.missingRequiredField && !this.state.primary.residenceProvince
        let missingSpouseProv = this.props.missingRequiredField && !this.state.spouse.residenceProvince

        var maritalStatusText1 = T.translate("Interview.Page4.fieldLabel0", { TaxYear: this.props.taxYear }) + '* '
        var maritalStatusText2 = null
        if (this.state.layoutType !== 'desktop' && (this.state.lang === 'en' || (this.state.lang === 'fr' && viewportWidth > 410))) {
            maritalStatusText1 = maritalStatusText1.replace('' + this.props.taxYear + '?* ', '')
            maritalStatusText2 = '' + this.props.taxYear + '?* '
        }

        var maritalContent = []
        maritalContent.push(<span key='ms1'>{maritalStatusText1}</span>)
        if (maritalStatusText2) {
            maritalContent.push(<br key='msspacer' />)
            maritalContent.push(<span key='ms2'>{maritalStatusText2}</span>)
        }
        maritalContent.push(<span key='ms3' style={{ marginRight: '10px' }} />)
        maritalContent.push(<LearnMore key='lm' linkId="OTE_FAQ/OTEWhatWasYourMartialStatusOnDecember31" />)


        return (
            <div className="validatingTextInputStyle">
                <div>
                    <div>
                        <h1>{T.translate('Interview.Page1.headerTitle', { TaxYear: this.props.taxYear })}</h1>
                    </div>
                    <HighlightedSection additionalClasses="interview-green-header-text interview-green-overflow-margin" isReturningClient={this.props.isReturningClient} margin={20} >
                        <T.text text="Interview.Page0.directUserText" />
                    </HighlightedSection >
                </div>
                <PersonalInfoForm simpleVersion personalInfo={this.state.primary} page={'page1'}
                    lang={this.state.lang} allRequiredFields
                    validateMinAge labelKey="Interview.Page1" missingRequiredField={this.props.missingRequiredField}
                    onPersonalInfoChanged={(personalInfo) => this.onPersonalInfoChanged(personalInfo)} />

                <div className="horizontalFieldContainerTopAligned largeTopMargin">
                    <div className="horizontalFieldContainerTopAligned largeTopMargin">
                        <div className="fixed inlineLabelStyle">
                            <div className="formLabelStyleNoMargin">
                                <span>
                                    {T.translate("Interview.Page3.fieldLabel0", translationProps)}*
                                    <span style={{ marginRight: '10px' }} />
                                    <LearnMore linkId="Other/WhatProvinceDidYouLiveInOnDec31" />
                                </span>
                            </div>
                        </div>
                        <div className="defaultInputWidth">
                            <ProvinceDropDown missingRequiredField={missingPrimaryProv} name='residenceProvince' value={this.state.primary.residenceProvince} onValueChanged={(ev, value) => this.handlePrimaryProvinceChange(ev, value)} />
                            {validationContent}
                        </div>
                    </div>
                </div>
                <div className="horizontalFieldContainerTopAligned largeTopMargin">
                    <div className="fixed inlineLabelStyle">
                        <div className="formLabelStyleNoMargin">
                            <span>
                                {maritalContent}
                            </span>
                        </div>
                    </div>
                    <div className="defaultInputWidth">
                        <MaritalStatusDropDown name='maritalStatus'
                            lang={this.state.lang}
                            missingRequiredField={missingMaritalStatus}
                            value={this.state.primary.maritalStatus} onValueChanged={(ev, value) => this.handleMaritalStatusChange(ev, value)} />
                        {missingMaritalStatus ? validationContent : null}
                    </div>
                </div>
                {(this.props.primary.maritalStatus === 'M' || this.props.primary.maritalStatus === 'C') &&
                    <div className="horizontalFieldContainerTopAligned largeTopMargin">
                        <YesNoQuestion name="spouseTaxes" value={this.state.primary.spouseTaxes}
                            isRequired labelText={T.translate("Interview.Page4.fieldLabel2")}
                            learnMoreId="OTE_FAQ/OTEPreparingIndividualAndSpousalReturns" suppressSpacer={false}
                            layoutType={this.state.layoutType} missingRequiredField={missingSpouseTaxes}
                            onAnswerChanged={(ev, value) => { this.onYesNoButtonChange(ev, value) }} />
                    </div>
                }
                {this.props.primary.spouseTaxes === 'Y' &&
                    <Fragment>                        
                    <div style={{ marginTop: '40px', marginBottom: '20px' }}>
                            <h1>{T.translate('Interview.Page5.headerTitle', { TaxYear: this.props.taxYear })}</h1>
                        </div>
                        <PersonalInfoForm simpleVersion personalInfo={this.state.spouse} page={'page1'}
                            lang={this.state.lang} allRequiredFields
                            validateMinAge labelKey="Interview.Page1" missingRequiredField={this.props.missingRequiredField}
                            onPersonalInfoChanged={(personalInfo) => this.onSpousePersonalInfoChanged(personalInfo)} />

                        <div className="residence-info-margin">
                            <div className="horizontalFieldContainerTopAligned largeTopMargin">
                                <div className="fixed inlineLabelStyle">
                                    <div className="formLabelStyleNoMargin">
                                        <span>
                                        {T.translate("Interview.Page5.fieldLabel1", translationProps)}*
                                            <span style={{ marginRight: '10px' }} />
                                            <LearnMore linkId="Other/WhatProvinceDidYouLiveInOnDec31" />
                                        </span>
                                    </div>
                                </div>
                            <div className="defaultInputWidth" style={{ marginLeft: '30px' }}>
                                <ProvinceDropDown missingRequiredField={missingSpouseProv} name='residenceProvince' value={this.state.spouse.residenceProvince} onValueChanged={(ev, value) => this.handleSpouseProvinceChange(ev, value)} />
                                {spouseValidationContent}
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }
            </div>
        )
    }
}

export default InterviewPage0;