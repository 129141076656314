import React from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types'
import T from 'i18n-react';
import './sidebar.css'
import CircleIndicator from './CircleIndicator'

const CircleIndicatorSidebarButton = ({ propertyName, text, onClick, isSelected, isLast, showIndicator, indicatorValue }) => (
    <div className={classNames('wf-sb-button-container', { 'wf-sb-button-selected': isSelected, 'wf-sb-button-last': isLast })}
        onClick={() => onClick(propertyName)}>
        <div className='wf-wb-button-component-outer'/>
        <div className='wf-wb-button-component wf-wb-button-label'>
            {T.translate(text)}
        </div>
        {showIndicator && 
            <CircleIndicator indicatorValue={indicatorValue} />
        }
    </div>
);

CircleIndicatorSidebarButton.propTypes = {
    propertyName: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    isLast: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    showIndicator: PropTypes.bool.isRequired,
    indicatorValue: PropTypes.number.isRequired
}

export default CircleIndicatorSidebarButton;