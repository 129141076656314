import React, { Component } from 'react';
import '../../Common/shared.css'
import '../style.css';
import ResidenceInfoForm from '../../Common/residenceInfoForm'
import InterviewTitleProgress from '../../Common/interviewTitleProgress'

class InterviewPage7 extends Component {
    constructor(props) {
        super(props)

        var spouse = {
            ...this.props.spouse
        }

        this.state = {
            spouse,
            spouseFirstName: spouse.firstName,
            missingRequiredField: this.props.missingRequiredField,
            layoutType: this.props.layoutType,
            lang: this.props.lang,
            missingRequiredSexField: this.props.missingRequiredSexField,
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.spouse !== this.state.spouse) {
            this.setState({
                spouse: newProps.spouse
            })
        }
        if (newProps.spouse.firstName !== this.state.spouseFirstName) {
            this.setState({spouseFirstName: newProps.spouse.firstName})
        }

        if (this.state.missingRequiredField !== newProps.missingRequiredField) {
            this.setState({ missingRequiredField: newProps.missingRequiredField })
        }
        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }
        if (this.state.missingRequiredSexField !== newProps.missingRequiredSexField) {
            this.setState({ missingRequiredSexField: newProps.missingRequiredSexField })
        }
    }

    onResidenceChanged(residence) {
        var spouse = {
            residenceProvince: residence.residenceProvince,
            nisgaLands: residence.nisgaLands,
            taichoLands: residence.taichoLands,
            yukonLands: residence.yukonLands,
            nflLands: residence.nflLands,
            provinceTerritoryChange: residence.provinceTerritoryChange,
            homeAndMailingSame: residence.homeAndMailingSame,
            currentResidenceProvince: residence.currentResidenceProvince,
            immigrateCanada: residence.immigrateCanada,
            canadianCitizenship: residence.canadianCitizenship,
            authorizeCraElectionsCanada: residence.authorizeCraElectionsCanada,
            gender: residence.gender
        }
        this.setState({ spouse })
        if (this.props.onSpouseChanged) {
            this.props.onSpouseChanged(spouse)
        }
    }

    render() {

        var nextTitle = 'Interview.Page7.nextTitle1'
        if (this.props.dependants === 'Y') {
            nextTitle = 'Interview.Page7.nextTitle2'
        }
        return (
            <div>
                <div>
                    <div>
                        <InterviewTitleProgress taxYear={this.props.taxYear} percentage="72" title="Interview.Page7.headerTitle" nextTitle={nextTitle} />
                    </div> 
                </div>
                <ResidenceInfoForm labelKey="Interview.Page7" residence={this.state.spouse}
                    spouseFirstName={this.state.spouseFirstName} taxYear={this.props.taxYear}
                    missingRequiredField={this.state.missingRequiredField}
                    missingRequiredSexField={this.state.missingRequiredSexField}
                    onResidenceChanged={(residence) => this.onResidenceChanged(residence)} isReturningClient={this.props.isReturningClient} />
            </div>
        )
    }
}


export default InterviewPage7;
