import React, { Component } from 'react';
import '../../Common/shared.css'
import FontAwesome from 'react-fontawesome';

import T from 'i18n-react';

class InterviewPage12 extends Component {   

    findOffice() {
        window.open(" https://www.hrblock.ca/office-locator/", "_self")       
    }

    render() {
        var nextButtonStyle = "defaultButtonStyle buttonWidth buttonHeight"
        return (
            <div style={{ margin: '20px' }}>
                <div>
                    <T.text tag='h1' text="Interview.Page12.headerTitle" />
                    <div style={{ height: 20 }} />
                    <T.text tag='p' text="Interview.Page12.headerContent0" />
                    <T.text tag='p' text="Interview.Page12.headerContent1" />
                    <br />
                    <T.text tag='p' text="Interview.Page12.headerContent2" />
                </div>
                <div className={nextButtonStyle} onClick={(ev) => this.findOffice()} >
                    <div style={{ width: 30 }} />
                    <span className="unselectableLabel"><T.span text={{ key: 'Common.FindAnOffice' }} /></span>
                    <FontAwesome className='fa-angle-right' name='fa-angle-right' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#FFFFFF', align: 'center', marginLeft: 10, marginRight: 10 }} />
                    <div style={{ width: 30 }} />
                </div>
            </div>           
        )
    }
}


export default InterviewPage12;
