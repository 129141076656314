import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import '../dox_explorer.css'
import { FileExplorerFileTableConfig } from '../services/DoxExplorerTableConfig'
import DoxExplorerFilesTableFileCell from './doxExplorerFilesTableFileCell'
import DoxExplorerFilesTableUploaderCell from './doxExplorerFilesTableUploaderCell'
import DoxExplorerFilesTableSizeCell from './doxExplorerFilesTableSizeCell'
import DoxExplorerFilesTableModifiedCell from './doxExplorerFilesTableModifiedCell'
import DoxExplorerFilesTableManageCell from './doxExplorerFilesTableManageCell'
import DoxExplorerFilesTableMobileFileCell from './doxExplorerFilesTableMobileFileCell'

const DoxExplorerFilesTableRow = (props) => {
    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        let ism = props.layoutType === 'mobile' || props.layoutType === 'small_tablet'
        if (isMobile !== ism) {
            setIsMobile(ism)
        }
    }, [props.layoutType, isMobile])

    const handleFileOperation = (operation, documentId) => {
        props.onFileOperation(operation, documentId)
    }

    const handleSelectFile = (documentId) => {
        handleFileOperation('view', documentId)
    }    

    return (
        <div className='doxe-files-table-row hrb-columns'>
            {props.file && !isMobile &&
                <Fragment>
                    <DoxExplorerFilesTableFileCell documentId={props.file.documentId} fileType={props.file.fileType}
                        fileName={props.file.fileName} onSelectItem={handleSelectFile} errorStatus={props.errorStatus}
                        width={FileExplorerFileTableConfig.find(x => x.columnId === 'name').width} />
                    <DoxExplorerFilesTableSizeCell fileSize={props.file.fileSize}
                        width={FileExplorerFileTableConfig.find(x => x.columnId === 'size').width} />
                    <DoxExplorerFilesTableModifiedCell modifiedTs={props.file.modifiedTs}
                        width={FileExplorerFileTableConfig.find(x => x.columnId === 'modified').width} />
                    <DoxExplorerFilesTableUploaderCell uploaderIsBlock={props.file.uploaderIsBlock}
                        width={FileExplorerFileTableConfig.find(x => x.columnId === 'uploader').width} />
                    <DoxExplorerFilesTableManageCell documentId={props.file.documentId}
                        onFileOperation={handleFileOperation} isDownloading={props.isDownloading}
                        width={FileExplorerFileTableConfig.find(x => x.columnId === 'manage').width} />
                </Fragment>
            }
            {props.file && isMobile &&
                <Fragment>
                    <DoxExplorerFilesTableMobileFileCell file={props.file} onSelectItem={handleSelectFile}
                        sortColumn={props.sortColumn} width={11} errorStatus={props.errorStatus} />
                    <DoxExplorerFilesTableManageCell documentId={props.file.documentId}
                        onFileOperation={handleFileOperation} isDownloading={props.isDownloading}
                        width={FileExplorerFileTableConfig.find(x => x.columnId === 'manage').width} />
                </Fragment>
            }
        </div>
    )
}

DoxExplorerFilesTableRow.propTypes = {
    layoutType: PropTypes.string.isRequired,
    file: PropTypes.shape({
        documentId: PropTypes.string.isRequired,
        fileType: PropTypes.string.isRequired,
        fileName: PropTypes.string.isRequired,
        modifiedTs: PropTypes.string.isRequired
    }),
    sortColumn: PropTypes.string,
    onFileOperation: PropTypes.func.isRequired,
    isDownloading: PropTypes.bool.isRequired,
    errorStatus: PropTypes.string
}   

export default DoxExplorerFilesTableRow