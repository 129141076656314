import React, { Component, Fragment } from 'react';
import '../../Common/shared.css'
import '../style.css';
import T from 'i18n-react';
import MailingAddressForm from '../../Common/mailingAddressForm'
import HelpCentre from '../../../sagas/Help/helpCentre'
import HighlightedSection from '../highlightedSection'
import InterviewTitleProgress from '../../Common/interviewTitleProgress'
import { connect } from 'react-redux';

class InterviewPage2 extends Component {
    constructor(props) {
        super(props)

        var primary = this.props.primary || {
            aptNumber: '',
            streetNumber: '',
            streetName: '',
            poBox: '',
            ruralRouteNumber: '',
            city: '',
            province: '',
            postalCode: '',
            inCareOf: '',
            telephone: '',
            mailingAddressChange: ''
        }

        if (!primary.postalCode) {
            primary.postalCode = props.defaultPostalCode || ''
        }

        this.state = {
            primary,
            mobilePhone: this.props.mobilePhone,
            layoutType: this.props.layoutType,
            lang: this.props.lang,
            missingRequiredField:this.props.missingRequiredField
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.primary !== newProps.primary) {
            this.setState({ primary: newProps.primary })            
        }

        if (this.state.mobilePhone !== newProps.mobilePhone) {
            this.setState({ mobilePhone: newProps.mobilePhone })
        }

        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }

        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }

        if (this.state.missingRequiredField !== newProps.missingRequiredField) {
            this.setState({ missingRequiredField: newProps.missingRequiredField })
        }
    }   

    componentWillMount() {
        if (this.state.primary.telephone === '') {
            var primary = { ...this.state.primary }
            primary.telephone = this.state.mobilePhone
            this.setState({ primary })     
            if (this.props.onPrimaryChanged) {
                this.props.onPrimaryChanged(primary)
            }
        }
    }
    
    onAddressChanged = (address) => {
        let primary = {
            ...this.primary,
            aptNumber: address.aptNumber,
            streetNumber: address.streetNumber,
            streetName: address.streetName,
            poBox: address.poBox,
            ruralRouteNumber: address.ruralRouteNumber,
            city: address.city,
            province: address.province,
            postalCode: address.postalCode,
            inCareOf: address.inCareOf,
            telephone: address.telephone,
            mailingAddressChange: address.mailingAddressChange
        }
        this.setState({ primary });

        if (this.props.onPrimaryChanged) {
            this.props.onPrimaryChanged(primary)
        }
    }

    showAddressHelp() {
        HelpCentre.openHelpLink('OTE_FAQ/HowShouldIEnterMyAddress')
    }

    render() {
        var addressChangeLabel = T.translate('Interview.Page2.fieldLabel10') //.replace('__taxYear__', T.translate('Common.TaxYear'))
        var mainDivClassName = 'validatingTextInputStyle'
        return (
            <div className={mainDivClassName}>
                <div className="interviewAddressTitleHolder">
                    <div>
                        <InterviewTitleProgress taxYear={this.props.taxYear} percentage="18" title="Interview.Page2.headerTitle" nextTitle="Interview.Page2.nextTitle" />
                    </div>                    
                </div>
                {this.props.showLinks &&
                    <div className="interviewHelpHolder">
                        <span className="learnMoreStyle" onClick={() => this.showAddressHelp()}>{T.translate("Common.AddressHelp")}</span>
                    </div>
                }
                {Boolean(this.props.isReturningClient) &&
                    <Fragment>
                        <HighlightedSection isReturningClient={this.props.isReturningClient} margin={20}>
                            {T.translate('Interview.Page2.returningSubTitle')}
                        </HighlightedSection>
                        <div style={{ height: 20 }} />
                    </Fragment>
                }

                <MailingAddressForm address={this.state.primary} addressChangeLabel={addressChangeLabel}
                    labelKey="Interview.Page2" hideTopSet="N" layoutType={this.state.layoutType}
                    isReturningClient={this.props.isReturningClient} requirePostalCode
                    missingRequiredField={this.state.missingRequiredField} taxYear={this.props.taxYear}
                    onAddressChanged={(address) => this.onAddressChanged(address)} />
                <div style={{ height: 8 }} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.layout.lang,
        showLinks: !state.workflow.is_direct_user
    };
};

export default connect(mapStateToProps)(InterviewPage2);