import { put, select, call } from 'redux-saga/effects'
import AuthorizeCRAActions from '../reducers/authorizeCRARedux'
import WorkflowActions from '../reducers/workflowRedux'
import { trackEvent, HeapEvents } from '../utils/AnalyticsUtil'
import { getMostRecentPrimaryInterviewTaxYearState } from '../utils/CaseStateUtilities'
import { replace } from 'connected-react-router'

const getAccessToken = state => state.auth.accessToken
const getCurrentState = state => getMostRecentPrimaryInterviewTaxYearState(state.workflow.case_state)
const getAuthStateSelf = state => state.authorizeCRA.authorizeStateSelf
const getAuthStateSpouse = state => state.authorizeCRA.authorizeStateSpouse
const getAuthorizeStage = state => state.authorizeCRA.stage

export function* getAuthorizeConfig(api, action) {
    var accessToken = yield select(getAccessToken)
    var prepareForSpouse = action.coupled
    var selfProv = action.primaryProvince
    var spouseProv = action.spouseProvince

    var response = null
   
    if (selfProv && accessToken) {
        response = yield call(api.getAuthorizeConfig, accessToken, selfProv.toUpperCase())
        if (response && response.ok && response.data) {
            yield put(AuthorizeCRAActions.setAuthorizeConfig(false, response.data.cra_auth_data, response.data.rq_auth_data))
            
            if (prepareForSpouse) {
                if (spouseProv === selfProv) {
                    yield put(AuthorizeCRAActions.setAuthorizeConfig(true, response.data.cra_auth_data, response.data.rq_auth_data))
                } else if (spouseProv) {
                    response = yield call(api.getAuthorizeConfig, accessToken, spouseProv.toUpperCase())
                    if (response && response.ok && response.data) {
                        yield put(AuthorizeCRAActions.setAuthorizeConfig(true, response.data.cra_auth_data, response.data.rq_auth_data))
                    }
                }
            }
        }
    }
}


export function* loadAuthState(api, action) {
    var stage = yield select(getAuthorizeStage)
    var path = action && action.payload ? action.payload.location.pathname : null
    var currentState = yield select(getCurrentState)
    var accessToken = yield select(getAccessToken)

    if (currentState === null) {
        // we have no current interview, let's not try to authorize until we do
        if (path === '/dashboard/authorize') {
            yield put(replace('/dashboard'))
        }
        return;
    }

    if (path === null || (stage === 'init' && path === '/dashboard/authorize')) {
        var response = yield call(api.loadAuthState, accessToken)
        if (!response.ok) {
            response = yield call(api.loadAuthState, accessToken)
        }
        if (response.data.authState) {
            if (response.data.authState.self) {
                yield put(AuthorizeCRAActions.setAuthorizeState('self', response.data.authState.self))
            }
            if (response.data.authState.spouse) {
                yield put(AuthorizeCRAActions.setAuthorizeState('spouse', response.data.authState.spouse))
            }
        }
        if (response.data.authState && response.data.authState.self && (response.data.authState.self.choice
            && response.data.authState.self.choice !== 'None') &&
            (!currentState.coupled || (response.data.authState && response.data.authState.spouse
                && (response.data.authState.spouse.choice && response.data.authState.spouse.choice !== 'None')))) {
            yield put(AuthorizeCRAActions.setAuthorizeStage('checklist'))
        } else {
            yield put(AuthorizeCRAActions.setAuthorizeStage('start'))
        }
    }
}

export function* saveAuthState(api, action) {
    var authStateSelf = yield select(getAuthStateSelf)
    var authStateSpouse = yield select(getAuthStateSpouse)
    var accessToken = yield select(getAccessToken)    

    var authState = { self: authStateSelf, spouse: authStateSpouse }
    var response = yield call(api.saveAuthState, accessToken, authState, accessToken)
    if (response && response.ok) {
        if (action.type === 'COMPLETE_CRA_STEP') {
            if (action.target === 'self' && authStateSelf && authStateSelf.cra_status) {
                trackEvent(authStateSelf.choice === 'MyCRA'
                    ? HeapEvents.AUTHORIZE_PRIMARY_CRA_MYACCOUNT
                    : HeapEvents.AUTHORIZE_PRIMARY_CRA_EAF)
            } else if (action.target === 'spouse' && authStateSpouse && authStateSpouse.cra_status) {
                trackEvent(authStateSelf.choice === 'MyCRA'
                    ? HeapEvents.AUTHORIZE_SPOUSE_CRA_MYACCOUNT
                    : HeapEvents.AUTHORIZE_SPOUSE_CRA_EAF)
            }
        } else if (action.type === 'COMPLETE_RQ_STEP') {
            if (action.target === 'self' && authStateSelf && authStateSelf.rq_status) {
                trackEvent(authStateSelf.choice === 'MyRQ'
                    ? HeapEvents.AUTHORIZE_PRIMARY_RQ_MYACCOUNT
                    : HeapEvents.AUTHORIZE_PRIMARY_RQ_MR69)
            } else if (action.target === 'spouse' && authStateSpouse && authStateSpouse.rq_status) {
                trackEvent(authStateSelf.choice === 'MyRQ'
                    ? HeapEvents.AUTHORIZE_SPOUSE_RQ_MYACCOUNT
                    : HeapEvents.AUTHORIZE_SPOUSE_RQ_MR69)
            }
        } else if (action.type === 'AUTHORIZE_COMPLETE') {
            if (authStateSelf && authStateSelf.cra_status && authStateSelf.choice === 'T1013') {
                trackEvent(HeapEvents.AUTHORIZE_PRIMARY_CRA_EAF)
            } else if (authStateSpouse && authStateSpouse.cra_status && authStateSpouse.choice === 'T1013') {
                trackEvent(HeapEvents.AUTHORIZE_SPOUSE_CRA_EAF)
            }
        }
    }
    yield put(WorkflowActions.updateCaseState())
}


