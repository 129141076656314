import React, { Component } from 'react';
import './style.css';
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';

class NavButton extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isEnabled: this.props.isEnabled || true
        }
    }

    handleClick(ev) {
        if (this.props.isEnabled !== false && !this.props.inProgress && this.props.onClick) {
            this.props.onClick(ev)
        }
    }

    handleKeyDown(ev) {
        if (ev.keyCode === 13) {
            if (this.props.isEnabled && !this.props.inProgress && this.props.onClick) {
                this.props.onClick(ev)
            }
        }
    }

    render() {
        var buttonStyle = this.props.isEnabled ? this.props.isPricingEstimatePage ? this.props.layoutType === 'tablet' ? "defaultButtonStyle secondaryButtonStyleTablet" : "defaultButtonStyle secondaryButtonStyle" : "defaultButtonStyle buttonStyle" : "defaultButtonStyleDisabled buttonStyle"

        if (this.props.inProgress === true) {
            return (
                <div tabIndex="0" className={buttonStyle + ' nextButton'} onClick={(ev) => this.handleClick(ev)} onKeyDown={(ev) => this.handleKeyDown(ev)}>
                    <div style={{ width: 30 }} />
                    <FontAwesome className='fa-spinner' name='fa-spinner' size='lg' pulse style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#FFFFFF', align: 'center', marginLeft: 10, marginRight: 10 }} />
                    <div style={{ width: 30 }} />
                </div>)
        } else if (this.props.isBack === true) {
            return (
                <div tabIndex="0" className={buttonStyle + ' prevButton'} onClick={(ev) => this.handleClick(ev)} onKeyDown={(ev) => this.handleKeyDown(ev)}>
                    <FontAwesome className='fa-angle-left' name='fa-angle-left' size='2x' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#FFFFFF', align: 'center', marginLeft: 10, marginRight: 10 }} />
                    <span className="unselectableLabel"><T.span text={{ key: this.props.labelKey }} /></span>
                    <div style={{ width: 30 }} />
                </div>)
        } else if (this.props.isForward === true) {
            return (
                <div tabIndex="0" className={buttonStyle + ' nextButton'} onClick={(ev) => this.handleClick(ev)} onKeyDown={(ev) => this.handleKeyDown(ev)}>
                    <div style={{ width: 30 }} />
                    <span className="unselectableLabel"><T.span text={{ key: this.props.labelKey }} /></span>
                    <FontAwesome className='fa-angle-right' name='fa-angle-right' size='2x' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#FFFFFF', align: 'center', marginLeft: 10, marginRight: 10 }} />
                </div>)
        } else {
            return (
                <div tabIndex="0" className={buttonStyle + ' nextButton'} onClick={(ev) => this.handleClick(ev)} onKeyDown={(ev) => this.handleKeyDown(ev)}>
                    <div style={{ width: 30 }} />
                    <span className="unselectableLabel"><T.span text={{ key: this.props.labelKey }} /></span>
                    <div style={{ width: 30 }} />
                </div>)
        }
    }
}

export default NavButton;