import React, { Component } from 'react';
import './shared.css';
import FontAwesome from 'react-fontawesome';

class CheckBox extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isChecked: this.props.isChecked
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.isChecked !== newProps.isChecked) {
            this.setState({isChecked: newProps.isChecked})
        }
    }

    handleClick(ev) {
        const isChecked = !this.state.isChecked
        this.setState({isChecked: isChecked})
        if (this.props.click) {
            this.props.click(ev, isChecked);
        }
        if (this.props.onCheckChanged) {
            this.props.onCheckChanged({ checked: isChecked })
        }
    }

    handleKeyDown(ev) {
        if (ev.keyCode === 32) {  // If spacebar fired the event
            const isChecked = !this.state.isChecked
            this.setState({ isChecked: isChecked })
            if (this.props.click) {
                this.props.click(ev, isChecked);
            }
            if (this.props.onCheckChanged) {
                this.props.onCheckChanged({ checked: isChecked })
            }
        }
    }

    render() {
        let content = null
        if (this.state.isChecked) {
            content = (<FontAwesome className='fa-check' name='check' size='2x' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#14AA40', align: 'center' }} />)
        }
        return (
            <div className={this.state.isChecked ? "checkboxStyleChecked" : "checkboxStyle"} onClick={(ev) => this.handleClick(ev)} onKeyDown={(ev) => this.handleKeyDown(ev)} tabIndex="0">
                {content}
            </div>
        )
    }
}


export default CheckBox;