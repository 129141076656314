import { call, put, select } from 'redux-saga/effects'

import PaymentActions from '../reducers/paymentRedux'

export const getAccessToken = state => state.auth.accessToken

export function* getPaymentInfo(api, action) {
    var accessToken = yield select(getAccessToken);
    var response = yield call(api.getPaymentInfo, accessToken, action.caseId);
    if (response && response.ok) {
        yield put(PaymentActions.setPaymentInfo(response.data))
    }   
}

export function* completePayment(api, action) {
    var accessToken = yield select(getAccessToken);
    var response = yield call(api.completePayment, accessToken, action.url);
    if (response && response.ok) {
        yield put(PaymentActions.setPaymentInfo(response.data))
    }
}