import React from 'react';
import T from 'i18n-react';
import './style.css'

const AssignConfirm = ({ associateInfo, officeInfo, onReset, layoutType }) => {

    const onChangePostalCode = () => {
        if (onReset) {
            onReset();
        }
    }

    return (
        <div className='ai-content'>
            <h1>{T.translate('Assign.Connected')}</h1>
            <p className='ai-strong'>{T.translate('Assign.PreparedBy')}</p>
            <p className='ai-strong'><b>{associateInfo.name ?? "—"}</b></p>
            <p className='ai-addr'>
                {officeInfo.address}<br />{officeInfo.city}
            </p>
            <p>
                {layoutType !== 'desktop' &&
                    <a href={'tel:' + (officeInfo.phone || '').replace(/[^0-9.]/g, '')}>
                        {officeInfo.phone}
                    </a>
                }
                {layoutType === 'desktop' &&
                    <span>{officeInfo.phone}</span>
                }
            </p>
            <div className='ai-change-pc' onClick={onChangePostalCode} tabIndex="0">
                <span>{T.translate('Assign.ChangePostalCode')}</span>
            </div>
        </div>
    )
}

export default AssignConfirm

/*
 * "Connected": "Connected!",
    "PreparedBy": "Your taxes will be prepared by:",
    "ChangePostalCode": "Change my postal code"*/