import Api from '../../../../services/Api'
import { ApiPaths } from '../../Shared/services/ApiPaths';

/* Service class used to perform api calls for the Client Document Management service */
class DoxUploaderCDMService {

    api(handleUnauthorized) {
        return new Api([403, 404], handleUnauthorized)
    }

    /**
     * Handles uploading of the file to the backend
     * @param {Function} handleUnauthorized injected method to handle 401 errors
     * @param {string} accessToken injected accessToken
     * @param {string|number} taxYear tax year for this file
     * @param {File} file the file object to upload
     * @param {Function} onUploadProgress callback function to track upload progress
     * @param {object} cancellationToken cancellation token for cancelling the upload.
     * @param {string|null} newFileName optional parameter for renaming the file on upload.
     */
    async uploadFile(handleUnauthorized, accessToken, taxYear, file, onUploadProgress, cancellationToken, newFileName) {
      
        let formData = new FormData();

        formData.append("taxYear", taxYear);

        // only set new name if it isn't null. otherwise, this will rename the file to 'null'
        // I LEARNED THIS THE HARD WAY SO YOU DON'T HAVE TO.
        if (newFileName) {
            formData.append("file", file, newFileName)
        } else {
            formData.append("file", file)
        }
        
        let config = {
            onUploadProgress: onUploadProgress,
            cancelToken: cancellationToken
        }

        return this.api(handleUnauthorized).post(accessToken, ApiPaths.CDM, formData, config)
    }
}

const doxUploaderCDMService = new DoxUploaderCDMService();
export default doxUploaderCDMService;