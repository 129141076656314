import React, { Component } from 'react';
import '../style.css'
import '../../Controls/progressModal.css';
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome'
import PlainButton from '../../Controls/plainButton'

class CancelConfirm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: this.props.lang,
            layoutType: this.props.layoutType,
            stage: this.props.stage,
            onCancel: this.props.onCancel,
            onClose: this.props.onClose
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.lang !== this.state.lang) {
            this.setState({ lang: newProps.lang })
        }

        if (newProps.layoutType !== this.state.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }

        if (newProps.stage !== this.state.stage) {
            this.setState({ stage: newProps.stage })
        }

        if (newProps.onCancel !== this.state.onCancel) {
            this.setState({ onCancel: newProps.onCancel })
        }

        if (newProps.onClose !== this.state.onClose) {
            this.setState({ onClose: newProps.onClose })
        }
    }

    handleCancelAddress() {
        if (this.state.onCancel) {
            this.state.onCancel()
        }
    }

    handleCloseDialog() {
        if (this.state.onClose) {
            this.state.onClose()
        }
    }

    render() {
        var titleKey = null
        var faContent = null
        var bodyContent = []
        var containerClass = ["confirmRootContainer confirmModalWidth"]
        if (this.state.layoutType === 'mobile') {
            containerClass.push('mobile')
        }
        var spacerHeight = this.state.layoutType === 'mobile' ? '20px' : '40px'

        titleKey = 'ConfirmIdentity.CancelConfirm.Title'
        faContent = (<FontAwesome className='fa-exclamation' name='exclamation' size='4x' />)
        bodyContent.push(<span key='content1' className="confirmModalContainerSpan orangeContent">{T.translate('ConfirmIdentity.CancelConfirm.SubTitle')}</span>)
        bodyContent.push(<div key='space1' style={{ height: spacerHeight }} />)
        bodyContent.push(
            <div key='okbutton' className="confirmModalContainer">
                <PlainButton labelKey="ConfirmIdentity.CancelConfirm.BtnNo" inverted isWarning onClick={() => this.handleCloseDialog()} />
                <div style={{ width: '30px', height: '10px' }} />
                <PlainButton labelKey="ConfirmIdentity.CancelConfirm.BtnYes" isWarning onClick={() => this.handleCancelAddress()} />
            </div>
        )

        return (
            <div className={containerClass.join(' ')}>
                <div className="confirmProgressSpinnerContainer orangeContent">
                    {faContent}
                </div>
                <h2 className="confirmWarning">{T.translate(titleKey)}</h2>
                {bodyContent}
            </div>
        );
    }
}

export default CancelConfirm