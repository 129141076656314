import React, { useState } from 'react'
import { func } from 'prop-types'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import classnames from 'classnames'
import DoxExplorerTitleSortBar from './components/doxExplorerTitleSortBar'
import DoxExplorerTaxYearsTable from './components/doxExplorerTaxYearsTable'
import DoxExplorerFilesTable from './components/doxExplorerFilesTable'
import { ToastContainer, toast, Flip } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { TranslationContextProvider } from '../Shared/hooks/useTranslationContextHook'
import { UnauthorizedContextProvider } from '../Shared/hooks/useUnauthorizedContextHook'

const DoxExplorer = (props) => {
    const history = useHistory();
    const { taxYear } = useParams();

    const [selectedSort, setSelectedSort] = useState(null)

    const layoutType = useSelector(state => state.layout.layoutType)

    const handleSetTaxYear = (tax_year) => {
        if (tax_year !== taxYear) {
            history.push(`/dashboard/documents/${tax_year || ''}`)
        }
    }

    const handleSelectSort = (sortColumn) => {
        setSelectedSort(sortColumn)
    }

    const handleViewFileNotification = (textKey) => {
        toast.info(props.onTranslateString(textKey))
    }

    return (
        <UnauthorizedContextProvider onHandleUnauthorizedUser={props.onUnauthorizedRequest}>
            <TranslationContextProvider onTranslateString={props.onTranslateString}>
                <div className={classnames('doxe-container', layoutType)}>
                    <DoxExplorerTitleSortBar tax_year={taxYear ? Number(taxYear) : taxYear} selectedSort={selectedSort}
                        onBackToRoot={handleSetTaxYear} layoutType={layoutType} onSelectSort={handleSelectSort} isDoxCombined={props.isDoxCombined} />
                    {!taxYear &&
                        <DoxExplorerTaxYearsTable onSetTaxYear={handleSetTaxYear} />
                    }
                    {taxYear &&
                        <DoxExplorerFilesTable layoutType={layoutType} onShowNotification={handleViewFileNotification}
                            tax_year={taxYear ? Number(taxYear) : taxYear} sortOverride={selectedSort}
                            onSelectSort={handleSelectSort} isDoxCombined={props.isDoxCombined} loadExistingFiles={props.loadExistingFiles}/>
                    }
                    <ToastContainer position='bottom-left'
                        transition={Flip}
                        autoClose={2000}
                        hideProgressBar
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable />
                </div>
            </TranslationContextProvider>
        </UnauthorizedContextProvider>
    )
}

DoxExplorer.propTypes = {
    onTranslateString: func.isRequired,
    onUnauthorizedRequest: func.isRequired
}

export default DoxExplorer;