import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import 'react-contexify/dist/ReactContexify.css'
import '../dox_explorer.css'
import DotDotDotIcon from '../graphics/dotdotdotIcon'
import { Menu, Item, useContextMenu, animation } from 'react-contexify'
import { DoxFileOperations } from '../services/doxExplorerConsts'
import { useTranslationContext } from '../../Shared/hooks/useTranslationContextHook'

const DoxExplorerFileOperationsMenu = ({ onFileOperation, documentId }) => {
    const translateString = useTranslationContext();

    const MENU_ID = () => `doxe-file-menu-${documentId}`

    const { show } = useContextMenu({ id: MENU_ID() })

    const handleContextMenu = (ev) => {
        ev.preventDefault()
        show(ev, { position: { x: ev.clientX - 160, y: ev.clientY } })
    }

    const handleItemClick = (operation) => {
        onFileOperation(operation, documentId)
    }

    return (
        <Fragment>
            <div className='hrb-column hrb-is-6 doxe-centered has-cursor-pointer' onClick={handleContextMenu} >
                <DotDotDotIcon color='#007C10' />
            </div>
            <Menu id={MENU_ID()} animation={animation.fade} theme='doxe'>
                <Item onClick={() => handleItemClick(DoxFileOperations.VIEW)}>
                    {translateString('Dox.DocumentExplorer.ViewDownload')}
                </Item>
                <Item onClick={() => handleItemClick(DoxFileOperations.DELETE)}>
                    {translateString('Dox.DocumentExplorer.Delete')}
                </Item>
                <Item onClick={() => handleItemClick(DoxFileOperations.RENAME)}>
                    {translateString('Dox.DocumentExplorer.Rename')}
                </Item>
            </Menu>
        </Fragment>
    )
}

DoxExplorerFileOperationsMenu.propTypes = {
    onFileOperation: PropTypes.func.isRequired,
    documentId: PropTypes.string.isRequired
}

export default DoxExplorerFileOperationsMenu;