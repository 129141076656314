import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.css';
import T from 'i18n-react';
import PlainButton from '../Controls/plainButton'
import BaseModal from '../Modal/BaseModal';
import SaveContentPrompt from './saveContentPrompt'
import InfoDialogContent from './infoDialogContent'
import MessagesActions from '../../reducers/messagesRedux'
import CloseIcon from '@mui/icons-material/Close'
import MessageInputEditor from './MessageInputEditor'
import './MessagingWindow.scss'
import { HeapEvents, trackEvent } from '../../utils/AnalyticsUtil'

class MessageInputScreen extends Component {
    constructor(props) {
        super(props);


        this.state = {
            visible: this.props.visible,
            showInputScreenHandler: this.props.showInputScreenHandler,
            message: this.props.message,
            messageAreaTextFocus: false,
            saveContentPromptShowing: this.props.saveContentPromptShowing,
            layoutType: this.props.layoutType            
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.visible !== this.state.visible) {
            this.setState({ visible: newProps.visible })
        }

        if (newProps.showInputScreenHandler !== this.state.showInputScreenHandler) {
            this.setState({ showInputScreenHandler: newProps.showInputScreenHandler })
        }

        if (newProps.message !== this.state.message) {
            this.setState({ message: newProps.message })
        }

        if (newProps.saveContentPromptShowing !== this.state.saveContentPromptShowing) {
            this.setState({ saveContentPromptShowing: newProps.saveContentPromptShowing })
        }

        if (newProps.messageSendState !== this.state.messageSendState) {
            this.setState({ messageSendState: newProps.messageSendState })
            if (newProps.messageSendState === 'complete') {
                setTimeout(() => {
                    this.hideMessageInputScreen()
                }, 1000)
            }
        }

        if (newProps.layoutType !== this.state.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
    }

    componentWillMount() {
        this.setState({ messageAreaTextFocus: false})
    }

    componentWillUnmount() {
        this.setState({ messageAreaTextFocus: false })
    }

    hideMessageInputScreen(clearContent = true) {
        this.props.setMessageSendState('')
        if (this.props.showInputScreenHandler) {
            this.props.showInputScreenHandler(false)
        }
    }

    confirmHideMessageInputScreen() {
        if (this.state.message) {
            this.props.setSaveContentPromptShowing(true);
        } else {
            this.continueNavigation()
        }
    }

    handleMessageChange(ev) {
        this.props.setMessageInProgress(ev)
    }

    handleSendMessage() {
        var stateMessage = this.state.message.replace(/<[^>]*>?/gm, '');
        if (stateMessage && stateMessage.trim().length > 0) {
            this.props.setMessageSendState('sending')
            trackEvent(HeapEvents.BTN_SEND_MESSAGE);
        }
    }

    continueNavigation(clearContent) {
        this.props.setSaveContentPromptShowing(false)
        if (clearContent) {
            this.props.setMessageInProgress('')
        }
        this.hideMessageInputScreen(clearContent)
    }

    cancelNavigation() {
        this.props.setSaveContentPromptShowing(false);
        this.props.setMessageSendState('')
    }

    retrySend() {
        var stateMessage = this.state.message.replace(/<[^>]*>?/gm, '');
        if (stateMessage && stateMessage.trim().length > 0) {
            this.props.setMessageSendState('retry')
        }
    }

    render() {
        var inProgress = false;
        var isComplete = false;
        var showErrorDialog = false;

        var dialogState = {}

        switch (this.state.messageSendState) {
            case 'sending':
                inProgress = true;
                break;
            case 'complete':
                isComplete = true;
                break;
            case 'error':
                showErrorDialog = true;
                dialogState = {
                    titleKey: 'Common.OhNo',
                    labelKeys: ['Messages.Labels.MessageErrorContent1', 'Messages.Labels.MessageErrorContent2'],
                    okButtonKey: 'Messages.Labels.MessageTryAgain',
                    cancelButtonKey: 'Messages.Labels.CancelMessage',
                    onOK: () => this.retrySend(),
                    onCancel: () => this.continueNavigation(true),
                    onCloseDialog: () => this.cancelNavigation(),
                    isError: true,
                    inProgress: false,
                    faIconName: 'fa-exclamation'
                }
                break;
            case 'retry':
                showErrorDialog = true;
                dialogState = {
                    titleKey: 'Common.OhNo',
                    labelKeys: ['Messages.Labels.MessageErrorContent1', 'Messages.Labels.MessageErrorContent2'],
                    okButtonKey: 'Messages.Labels.MessageTryAgain',
                    cancelButtonKey: 'Messages.Labels.CancelMessage',
                    onOK: () => { },
                    onCancel: () => this.continueNavigation(true),
                    onCloseDialog: () => this.cancelNavigation(),
                    isError: true,
                    inProgress: true,
                    faIconName:'fa-exclamation'
                }
                break;
            case 'retry_success':
                showErrorDialog = true;
                dialogState = {
                    titleKey: 'Messages.Labels.MessageSent',
                    labelKeys: ['Messages.Labels.MessageSentContent1'],
                    onOK: () => this.continueNavigation(),
                    isError: false,
                    inProgress: false,
                    faIconName: 'fa-check'
                }
                break;
            case 'failed':
                showErrorDialog = true;
                dialogState = {
                    titleKey: 'Messages.Labels.MessageFail',
                    labelKeys: ['Messages.Labels.MessageFailContent1'],
                    onOK: () => this.continueNavigation(false),
                    onCancel: () => this.cancelNavigation(),
                    isError: true,
                    inProgress: false,
                    faIconName: 'fa-exclamation'
                }
                break;
            default:
                break;
        }

        var hasMessageContent = false
        if (this.state.message !== '<p><br></p>' && this.state.message !== '' && this.state.message !== null && this.state.message.replace(/\s/g, "") !== '<p></p>') {
            hasMessageContent = true
        }
        var enableSendButton = isComplete || hasMessageContent
        return (
            <div className={["messageViewMain", this.state.visible ? "showing" : "hiding"].join(' ')}>
                <div className="messageViewHeader">
                    <div className="messagesHeaderInputStyle">
                        <span className="messagesDefaultHeaderStyle">{T.translate("Messages.Labels.HeaderMessage")}</span>
                        <div className="pointerCursor" onClick={(ev) => this.confirmHideMessageInputScreen()}>
                            <CloseIcon />
                        </div>
                    </div>
                </div>
                <div id="pii-exclude" className={this.state.layoutType === 'desktop' ? "messagesMainStyleInputDesktop" : "messagesMainStyleInput"}>
                    <div className={this.state.layoutType === 'mobile' ? "messagesMainStyleMobileInputContainer" : "messagesMainStyleContainer"}>
                            <MessageInputEditor className="message-content"
                                onInputChanged={(ev) => this.handleMessageChange(ev)}
                                messageContent={this.state.message}
                                visible={this.state.visible}/>
                    </div>
                </div>
                <div className="messagesInputFooterStyle">
                    <div className="messagesInputFooterLink" onClick={(ev) => this.confirmHideMessageInputScreen()}>
                        {T.translate('Messages.Labels.CancelMessage')}
                    </div>
                    <PlainButton isEnabled={enableSendButton} onClick={(ev) => this.handleSendMessage()}
                        labelKey='Messages.Labels.SendMessage' inProgress={inProgress}
                        isComplete={isComplete} />
                </div>
                <BaseModal
                    show={this.state.saveContentPromptShowing && this.state.visible}
                    onClose={() => this.cancelNavigation()}
                    layoutType={this.props.layoutType}
                >
                    <SaveContentPrompt isBrowserBack={false} isComplete={isComplete}
                        inProgress={inProgress} layoutType={this.state.layoutType}
                        continueNavigation={() => this.continueNavigation(true)}
                        cancelNavigation={() => this.cancelNavigation()} />
                </BaseModal>
                <BaseModal
                    show={showErrorDialog && this.state.visible}
                    onClose={dialogState.onCloseDialog}
                    layoutType={this.props.layoutType}
                >
                    <InfoDialogContent
                        titleKey={dialogState.titleKey}
                        labelKeys={dialogState.labelKeys}
                        okButtonKey={dialogState.okButtonKey}
                        cancelButtonKey={dialogState.cancelButtonKey}
                        onOK={dialogState.onOK}
                        onCancel={dialogState.onCancel}
                        onCloseDialog={dialogState.onCloseDialog}
                        isError={dialogState.isError}
                        inProgress={dialogState.inProgress}
                        faIconName={dialogState.faIconName}
                        layoutType={this.state.layoutType}
                    />
                </BaseModal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {        
        layoutType: state.layout.layoutType
    }
}

const mapDispatchToProps = (dispatch) => ({
    setMessageInProgress: (messageInProgress) => dispatch(MessagesActions.setMessageInProgress(messageInProgress)),
    setMessageSendState: (messageSendState) => dispatch(MessagesActions.setMessageSendState(messageSendState)),
    setSaveContentPromptShowing: (saveContentPromptShowing) => dispatch(MessagesActions.setSaveContentPromptShowing(saveContentPromptShowing))
})

export default connect(mapStateToProps, mapDispatchToProps)(MessageInputScreen)