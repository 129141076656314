import React, { Component } from 'react';
import './style.css';
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';

class ArrowButton extends Component {
    constructor(props) {
        super(props)


        this.state = {
            labelKey: this.props.labelKey || 'Common.Continue',
            arrowDirection: this.props.arrowDirection || 'right',
            isEnabled: !(this.props.isEnabled === false),
            onClick: this.props.onClick
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.labelKey !== newProps.labelKey) {
            this.setState({ labelKey: newProps.labelKey })
        }
        if (this.state.arrowDirection !== newProps.arrowDirection) {
            this.setState({ arrowDirection: newProps.arrowDirection })
        }
        if (this.state.isEnabled !== newProps.isEnabled) {
            this.setState({ isEnabled: newProps.isEnabled })
        }
        if (this.state.onClick !== newProps.onClick) {
            this.setState({ onClick: newProps.onClick })
        }
    }

    handleClick(ev) {
        if (this.state.isEnabled && this.state.onClick) {
            this.state.onClick(ev)
        }
    }

    handleKeyDown(ev) {
        if (this.state.isEnabled && this.state.onClick && (ev.keyCode === 13)) {
            this.state.onClick(ev)
        }
    }

    render() {
        var classLeft = this.state.arrowDirection === 'left' ? "fa-angle-left chevronStyle" : "fa-angle-left chevronOffStyle"
        var classRight = this.state.arrowDirection === 'left' ? "fa-angle-right chevronOffStyle" : "fa-angle-right chevronStyle"

        return (
            <div className={this.state.isEnabled ? "arrowButtonStyle" : "arrowButtonDisabledStyle"} onClick={(ev) => this.handleClick(ev)} onKeyDown={(ev) => this.handleKeyDown(ev)} tabIndex="0" >
                <FontAwesome className={classLeft} name='chev-left' size='2x' />
                <span className="unselectableLabel">{T.translate(this.state.labelKey)}</span>
                <FontAwesome className={classRight} name='chev-right' size='2x' />
            </div>
        )
    }
}

export default ArrowButton;