import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import '../Interview/style.css';
import T from 'i18n-react';
import ConfirmIconButton from './confirmIconButton'
import PlainButton from '../Controls/plainButton'

class ConfirmIdCoupled extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lang: this.props.lang,
            layoutType: this.props.layoutType,
            stage: this.props.stage,
            confirmSelf: this.props.confirmSelf,
            confirmSpouse: this.props.confirmSpouse,
            onChangeState: this.props.onChangeState,
            onBackClicked: this.props.onBackClicked,
            firstName: this.props.firstName,
            spouseFirstName: this.props.spouseFirstName

        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }

        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }

        if (this.state.confirmSelf !== newProps.confirmSelf) {
            this.setState({ confirmSelf: newProps.confirmSelf })
        }

        if (this.state.confirmSpouse !== newProps.confirmSpouse) {
            this.setState({ confirmSpouse: newProps.confirmSpouse })
        }

        if (this.state.onChangeState !== newProps.onChangeState) {
            this.setState({ onChangeState: newProps.onChangeState })
        }

        if (this.state.onBackClicked !== newProps.onBackClicked) {
            this.setState({ onBackClicked: newProps.onBackClicked })
        }

        if (this.state.firstName !== newProps.firstName) {
            this.setState({ firstName: newProps.firstName })
        }

        if (this.state.spouseFirstName !== newProps.spouseFirstName) {
            this.setState({ spouseFirstName: newProps.spouseFirstName })
        }
    }

    onBackClicked(e) {
        if (this.state.onBackClicked) {
            this.state.onBackClicked()
        }
    }

    handleConfirm(target) {
        if (this.state.onChangeState) {
            if (target === 'self' && this.state.confirmSelf.initialState === 'failed') {
                this.state.onChangeState('self', 'failed')
            } else if (target === 'spouse' && this.state.confirmSpouse.initialState === 'failed') {
                this.state.onChangeState('spouse', 'failed')
            } else {
                this.state.onChangeState(target, 'summary')
            }
        }
    }


    render() {
        var backLabel = 'Common.ToDashboard'
        if (this.state.layoutType === 'mobile') {
            backLabel = 'Common.Back'
        }

        var titleKey = 'ConfirmIdentity.Coupled.'
        var doneButtonStyles = ['confirmSummaryFooterConsentRow', 'alignMeRight', 'rightAlignMargin']
        var selfButtonChecked = this.state.confirmSelf.identityConfirmed === true
        var spouseButtonChecked = this.state.confirmSpouse.identityConfirmed === true

        if (!selfButtonChecked && !spouseButtonChecked) {
            titleKey += 'Content2'
            doneButtonStyles.push('hidden')
        } else if (!selfButtonChecked) {
            titleKey += 'Content4'
            doneButtonStyles.push('hidden')
        } else if (!spouseButtonChecked) {
            titleKey += 'Content3'
            doneButtonStyles.push('hidden')
        } else {
            titleKey += 'Content5'
        }

        var selfButtonLabel = selfButtonChecked ? 'ConfirmIdentity.Coupled.ButtonContent1Complete' : 'ConfirmIdentity.Coupled.ButtonContent1'
        var spouseButtonLabel = spouseButtonChecked ? 'ConfirmIdentity.Coupled.ButtonContent2Complete' : 'ConfirmIdentity.Coupled.ButtonContent2'

        return (
            <div className="confirmContainerOuter confirmContainerMinHeight">
                <div className="confirmContainer">
                    <div className="confirmBackButtonStyle" onClick={(e) => this.onBackClicked(e)}>
                        <FontAwesome className='fa-angle-left' name='fa-angle-left' size='2x' />
                        <h4>{T.translate(backLabel)}</h4>
                    </div>
                    <div>
                        <h1>{T.translate("ConfirmIdentity.Coupled.Title")}</h1>
                        <div style={{ height: 20 }} />
                    </div>
                    <span className="confirmContainerSpan largeMargins">{T.translate("ConfirmIdentity.Coupled.Content1", { SpouseFirstName: this.state.spouseFirstName })}</span>

                    <div className="confirmCoupledContent">
                        <T.span text={{ key: titleKey, RirstName: this.state.firstName, SpouseFirstName: this.state.spouseFirstName }} />
                    </div>
                    <div className="confirmContentHolder smallMargins">
                        <div className="confirmButtonHolder">
                            <ConfirmIconButton icon="fa-user-secret" label={selfButtonLabel}
                                firstName={this.state.firstName} spouseFirstName={this.state.spouseFirstName}
                                isChecked={selfButtonChecked} isEnabled size='2x'
                                layoutType={this.state.layoutType}
                                onClick={(ev) => this.handleConfirm('self')} />
                        </div>
                        <div className="confirmButtonHolder">
                            <ConfirmIconButton icon="fa-user-secret" label={spouseButtonLabel}
                                firstName={this.state.firstName} spouseFirstName={this.state.spouseFirstName}
                                isChecked={spouseButtonChecked} isEnabled size='2x'
                                layoutType={this.state.layoutType}
                                onClick={(ev) => this.handleConfirm('spouse')} />
                        </div>
                    </div>
                </div>
                <div className={doneButtonStyles.join(' ')}>
                    <PlainButton labelKey="Common.Done" onClick={(ev) => this.onBackClicked(ev)} />
                </div>
            </div>
        );
    }
}

export default ConfirmIdCoupled;

