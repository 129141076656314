import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    setCaseId: ['caseId'],
    setButtonState: ['buttonName', 'buttonState'],
    setButtonStates: ['buttons'],
    setWorkflow: ['workflow'],
    setCaseState: ['caseState'],
    updateCaseState: ['routerAction', 'path'],
    resetWorkflow: null,
    setCaseSetting: ['taxYear', 'setting', 'value'],
    setCaseSettings: ['caseSettings'],
    storeCaseState: ['caseState'],
    deleteCase: ['taxYear'],
    setCaseDeletedStatus: ['status', 'errorCode'],
    setLastReviewedPage: ['caseId', 'pageNumber', 'taxYear'],
    setReviewAndApprovePaperSignature: ['signature'],
    scheduleCallAttempt: ['taxYear'],
    setIsDirectUser: ['isDirect'],
    setUploadStepToWorking: ['taxYear'],
    setVirStatus: ['virStatus']
})

export const WorkflowTypes = Types
export default Creators

/* ------------- Enums ----------------------*/
export const returnStatus = {
    new_return: 'new_return',
    in_review: 'dashboard_in_review',
    payment_due: 'payment_due',
    in_progress: 'in_progress',
    review_and_approve: 'review_and_approve',
    filing: 'filing',
    complete: 'complete'
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    caseId: 0,
    account_created: 'AnimateRemove',
    authorize: 'Hide',
    confirm_id: 'Hide',
    interview: 'Show',
    payment: 'Hide',
    review_return: 'Hide',
    t183: 'Hide',
    upload_docs: 'Hide',
    download: 'Hide',
    survey: 'Hide',
    id: 0,  // zendesk ticket id
    created_at: '',
    updated_at: '',
    status: '',
    assigned_tax_pro: '',
    return_status: '',
    tags: '',
    custom_fields: '',
    caseSettings: {},
    case_state: {},
    case_delete_status: {
        status: null,
        errorCode: null
    },
    review_and_approve_paper_signature: false,
    is_digital_rq_auth: false,
    is_direct_user: null,
    virStatus: ''
}

/* ------------- Reducers ------------- */
export const workflowSetCaseId = (state, { caseId }) =>
    Object.assign({}, state, { caseId: caseId })

export const workflowSetButtonState = (state, { buttonName, buttonState }) => {
    var s = {}
    s[buttonName] = buttonState
    return Object.assign({}, state, s)
}

export const workflowSetButtonStates = (state, { buttons }) => {
    var s = { ...buttons }
    return Object.assign({}, state, s)
}

export const workflowSetWorkflow = (state, { workflow }) =>
    Object.assign({}, state, workflow)

export const workflowSetCaseState = (state, { caseState }) => {
    var s = { ...caseState }
    return Object.assign({}, state, s)
}

export const workflowSetCaseSetting = (state, { setting, value }) => {
    var settings = {
        ...state.caseSettings
    }
    settings[setting] = value
    return Object.assign({}, state, { caseSettings: settings })
}

export const workflowSetCaseSettings = (state, { caseSettings }) =>
    Object.assign({}, state, { caseSettings: caseSettings || {} })


export const workflowResetWorkflow = (state) => INITIAL_STATE

export const casestateStoreCaseState = (state, { caseState }) =>
    Object.assign({}, state, { case_state: caseState })

export const workflowSetCaseDeletedStatus = (state, { status, errorCode }) =>
    Object.assign({}, state, { case_delete_status: { status: status, errorCode: errorCode } })

export const workflowSetUploadStepToWorking = (state, { taxYear }) => {
    var tax_year = Number(taxYear);
    var arrayIndex = state.case_state.taxYears.map(x => x.taxYear).indexOf(tax_year)

    var case_state_copy = {
        ...state.case_state,
        taxYears:
            state.case_state.taxYears.map(
                (content, i) => i === arrayIndex ? {
                    ...content, workflow_state: {
                        ...content.workflow_state,
                        upload_docs: 'ConfirmWorking'
                    }
                }
                    : content)
    }
    return Object.assign({}, state, { case_state: case_state_copy })
}


export const workflowSetLastReviewedPage = (state, { caseId, pageNumber, taxYear }) => {
    var tax_year = Number(taxYear)

    var arrayIndex = state.case_state.taxYears.map(x => x.taxYear).indexOf(tax_year)

    var last_reviewed_page = {
        ...state.case_state,
        taxYears:
            state.case_state.taxYears.map(
                (content, i) => i === arrayIndex ? {
                    ...content, workflow_state: {
                        ...content.workflow_state,
                        lastreviewedpage: pageNumber
                    }
                }
                    : content)
    }
    return Object.assign({}, state, { case_state: last_reviewed_page })
}

export const workflowSetVirStatus = (state, { virStatus }) => {
    return Object.assign({}, state, { virStatus })
}

export const worflowSetIsDirectUser = (state, { isDirect }) => 
    // hack: hackity hack... this should always be true now. 
    // We don't make "classic" OTE tickets anymore.
    // OTE-1077 tech debt ticket created to clean this up.
    Object.assign({}, state, { is_direct_user: true })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_BUTTON_STATE]: workflowSetButtonState,
    [Types.SET_BUTTON_STATES]: workflowSetButtonStates,
    [Types.SET_CASE_ID]: workflowSetCaseId,
    [Types.SET_WORKFLOW]: workflowSetWorkflow,
    [Types.SET_CASE_STATE]: workflowSetCaseState,
    [Types.RESET_WORKFLOW]: workflowResetWorkflow,
    [Types.SET_CASE_SETTING]: workflowSetCaseSetting,
    [Types.SET_CASE_SETTINGS]: workflowSetCaseSettings,
    [Types.STORE_CASE_STATE]: casestateStoreCaseState,
    [Types.SET_CASE_DELETED_STATUS]: workflowSetCaseDeletedStatus,
    [Types.SET_LAST_REVIEWED_PAGE]: workflowSetLastReviewedPage,
    [Types.SET_IS_DIRECT_USER]: worflowSetIsDirectUser,
    [Types.SET_UPLOAD_STEP_TO_WORKING]: workflowSetUploadStepToWorking,
    [Types.SET_VIR_STATUS]: workflowSetVirStatus
})