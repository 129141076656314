import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import T from 'i18n-react';
import './rm.css'
import FontAwesome from 'react-fontawesome';
import PlainButton from '../../Controls/plainButton';
import RegisterActions from '../../../reducers/registerRedux';

const RegisterErrorMessage = () => {
    const dispatch = useDispatch();
    const { tryAgainAllowed, errorMessageKey } = useSelector(state => state.register);

    const handleTryAgain = () => {
        dispatch(RegisterActions.setTryAgainAllowed(false));
        dispatch(RegisterActions.setErrorMessageKey('AccountSetupProgress.errorMessages.FailAgain'))
        dispatch(RegisterActions.setStepWithMessage('lookup_user', 'AccountSetupProgress.statusMessages.Looking'))
    }

    return (
        <div className='rm-outer'>
            <div className="rm-inner wide-margin">
                <FontAwesome className='fa-exclamation fa-default-style' name='exclamation' size='4x' />
                {errorMessageKey &&
                    <div className='rm-error-msg'>{T.translate(errorMessageKey)}</div>
                }
                {tryAgainAllowed &&
                    <PlainButton labelKey="Common.TryAgain" onClick={handleTryAgain} />
                }
            </div>
        </div>
    )
}

export default RegisterErrorMessage
