import React, { useEffect, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';
import PlainButton from '../../Controls/plainButton'
import { QRCode } from "react-qr-code";
import ConfirmIdentityActions from '../../../reducers/confirmIdentityRedux'
import WorkflowActions from '../../../reducers/workflowRedux'
import ConfirmIdentityApi from '../../../services/confirmIdentityApis';
import LoadingSpinner from '../../../../src/components/Loading/loadingSpinner'
import { useTimer } from '../../../hooks/useTimer'
import { trackEvent, HeapEvents } from '../../../utils/AnalyticsUtil' 

const ConfirmStage1FailedQRCode = (props) => {

    const accessToken = useSelector(state => state.auth.accessToken);
    const history = useHistory();
    const [backLabel, setBackLabel] = useState('Common.ToDashboard')
    const isCoupled = props.isCoupled;
    const layoutType = props.layoutType;
    const isSpouse = props.prepareForSpouse;
    const [workflowSessionURL, setWorkflowSessionURL] = useState('')
    const [verificationSessionId, setVerificationSessionId] = useState('')
    const interval = 10; // seconds

    const [start, stop] = useTimer(() => checkForVerificationComplete(), interval, true);


    const onBackToDashClicked = () => {
        history.replace('/dashboard')
    }

    const handleQrClick = () => {
        if (workflowSessionURL && workflowSessionURL.length > 0) {
            window.open(workflowSessionURL, "_blank");
        }
    }

    useEffect(() => {
        if (isSpouse) {
            setBackLabel('ConfirmIdentity.Stage2.Stage1Fail.Back')
        }

        if (layoutType === 'mobile') {
            setBackLabel('Common.Back')
            trackEvent(HeapEvents.CLIENT_ID_SECOND_CHECK)
        }
        else {
            trackEvent(HeapEvents.CLIENT_ID_QR_CODE)
        }
    }, [])

    useEffect(() => {

        // declare the async data fetching function
        const fetchUrl = async () => {
            var response = await ConfirmIdentityApi.create().getDocVUrl(isSpouse, accessToken);
            if (response && response.ok) {
                if (response.data) {
                    const data = response.data
                    setWorkflowSessionURL(data);
                    setVerificationSessionId(data?.split('=')[1]);
                }
            } else {
                console.log('Failed to retrieve verification url')
            }
        }

        // call the function
        fetchUrl()
            // make sure to catch any error
            .catch(console.error);;

    }, [])

    useEffect(() => {
        start()
    }, [verificationSessionId])

    const checkForVerificationComplete = () => {
        async function getVerificationStatusUpdate() {
            console.log('checking for verification status')
            var response = await ConfirmIdentityApi.create().getDocVStatus(verificationSessionId, accessToken);
            if (response && response.ok) {
                if (response.data) {                    
                    if (response.data === 'ACCEPTED' || response.data === 'REJECTED') {
                        console.log('Status update: ' + response.data)
                        stop()
                        props.completeQrCodeVerification(response.data, isSpouse, isCoupled)
                        trackEvent(isSpouse === true ? response.data === 'ACCEPTED' ? HeapEvents.CONFIRM_SPOUSE_SELFIE : HeapEvents.CONFIRM_SPOUSE_FAILED : response.data === 'ACCEPTED' ? HeapEvents.CONFIRM_PRIMARY_SELFIE : HeapEvents.CONFIRM_SPOUSE_FAILED)
                        props.setButtonState('confirm_id', 'Hide')
                    } else {
                        console.log('Status update: IN PROGRESS')
                    }
                }
            } else {
                console.log('Failed to retrieve verification update')
            }
        }
        if (verificationSessionId !== '') {
            getVerificationStatusUpdate();
        }


    }

    return (
        <div>
        { layoutType === 'desktop' &&
        <div className="confirmContainerOuter">
            <div>
                        <div className="confirmBackButtonStyle" onClick={onBackToDashClicked}>
                    <FontAwesome className='fa-angle-left' name='fa-angle-left' size='2x' />
                    <h4>{T.translate(backLabel)}</h4>
                </div>
            </div>
                <div className="confirmContainerFailedQR">
                    <div className='qrCodeHeaderWidth'>
                        <h1>{T.translate('ConfirmIdentity.Stage2.Stage1FailQR.Title')}</h1>
                        <div className='bufferSpace' />
                    </div>
                    <div className='qrCodeHeaderWidth'>
                        <p>{T.translate('ConfirmIdentity.Stage2.Stage1FailQR.SubTitle')}</p>
                        <div className='bufferSpace' />
                    </div>
                        <div className='qrCodeHeaderWidth'>
                        <h1>{T.translate('ConfirmIdentity.Stage2.Stage1FailQR.Title2')}</h1>
                        <div className='bufferSpace' />
                    </div>
                    <div className='qrCodeHeaderWidth'>
                        <p>{T.translate('ConfirmIdentity.Stage2.Stage1FailQR.SubTitle2')}</p>
                        <div className='bufferSpace' />
                    </div>
                    <div className='confirmContainerFailedQRRow'>
                        <div className='negativeMargin'></div>
                        <div>
                            <svg width="117" height="184" viewBox="0 0 117 184" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M91.6667 0H25C11.1667 0 0 11.1667 0 25V158.333C0 172.167 11.1667 183.333 25 183.333H91.6667C105.5 183.333 116.667 172.167 116.667 158.333V25C116.667 11.1667 105.5 0 91.6667 0ZM75 166.667H41.6667V158.333H75V166.667ZM102.083 141.667H14.5833V25H102.083V141.667Z" fill="#231F20" />
                                <path d="M78 64H70.075L65.5 59H50.5L45.925 64H38C35.25 64 33 66.25 33 69V99C33 101.75 35.25 104 38 104H78C80.75 104 83 101.75 83 99V69C83 66.25 80.75 64 78 64ZM78 99H38V69H48.125L52.7 64H63.3L67.875 69H78V99ZM58 71.5C51.1 71.5 45.5 77.1 45.5 84C45.5 90.9 51.1 96.5 58 96.5C64.9 96.5 70.5 90.9 70.5 84C70.5 77.1 64.9 71.5 58 71.5ZM58 91.5C53.875 91.5 50.5 88.125 50.5 84C50.5 79.875 53.875 76.5 58 76.5C62.125 76.5 65.5 79.875 65.5 84C65.5 88.125 62.125 91.5 58 91.5Z" fill="#231F20" />
                            </svg>
                        </div>
                        <div className='sideMargins'></div>
                        <div>
                            <svg width="30" height="35" viewBox="0 0 30 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M29.8333 17.3306L0.58325 34.2181L0.583252 0.44307L29.8333 17.3306Z" fill="#231F20" />
                            </svg>
                        </div>
                        <div className='sideMargins'></div>
                        <div>
                            {workflowSessionURL.length > 0 &&
                                <QRCode
                                    size={16}
                                    className= 'qrCodeDisplay'
                                    value={workflowSessionURL}
                                    viewBox={`0 0 16 16`} />
                            }
                            {workflowSessionURL.length === 0 &&
                                <LoadingSpinner />
                            }
                        </div>
                    </div>
                    <div className='patientMessageFormat'>
                        <p>{T.translate('ConfirmIdentity.Stage2.Stage1FailQR.PatientMessage')}</p>
                    <div className='bufferSpace' />
                    </div>
                    <div className='qrCodeHeaderWidth'>
                        <p>{T.translate('ConfirmIdentity.Stage2.Stage1FailQR.QRCodeNotWorking')}</p>
                        <div className='bufferSpace' />
                    </div>
                    <div className='qrCodeHeaderWidth'>
                        <span>{T.translate('ConfirmIdentity.Stage2.Stage1FailQR.QRCodeNotWorkingOption1')}</span>
                        <div className='bufferSpace' />
                    </div>
                    <div className='qrCodeHeaderWidth'>                            
                            <span>{T.translate('ConfirmIdentity.Stage2.Stage1FailQR.QRCodeNotWorkingOption2', { 'BlockLink': <a href={'' + T.translate("ConfirmIdentity.Failed.LocatorLink")} target='_blank' rel='noopener noreferrer'>{T.translate("ConfirmIdentity.Stage2.Stage1FailQR.BlockLinkText")}</a> })}</span>
                        <div className='bufferSpace' />
                    </div>
                    <div className='bottomMargin'></div>
                    <div className='bottomMargin'></div>
                </div>
                </div>
        }
           
        {
        (layoutType === 'mobile') &&
        <div className="confirmContainerOuter">
            <div>
                <div className="confirmBackButtonStyle" onClick={onBackToDashClicked}>
                    <FontAwesome className='fa-angle-left' name='fa-angle-left' size='2x' />
                    <h4>{T.translate(backLabel)}</h4>
                </div>
            </div>
                <div className="confirmContainer mediumMargins">
                    <div className='headerWidth'>
                        <h1>{T.translate('ConfirmIdentity.Stage2.Stage1FailQR.Title')}</h1>
                        <div className='bufferSpace' />
                    </div>
                    <div className='headerWidth'>
                        <p>{T.translate('ConfirmIdentity.Stage2.Stage1FailQR.SubTitleMobile')}</p>
                        <div className='bufferSpace' />
                    </div>
                    <div className='patientMessageFormat'>
                        <p>{T.translate('ConfirmIdentity.Stage2.Stage1FailQR.PatientMessage')}</p>
                    <div className='bufferSpace' />
                    </div>  
                    <div className="confirmSummaryFooterButtonContainer">
                        <PlainButton labelKey='ConfirmIdentity.Stage2.Stage1FailQR.CompleteVerificationButton' onClick={handleQrClick} />
                    </div>
                    <div className='headerWidth'>
                                <p><span>{T.translate('ConfirmIdentity.Stage2.Stage1FailQR.CantCompleteMobile')}</span><br />
                                    <span>{T.translate('ConfirmIdentity.Stage2.Stage1FailQR.QRCodeNotWorkingOptionMobile', { 'BlockLink': <a href={'' + T.translate("ConfirmIdentity.Failed.LocatorLink")} target='_blank' rel='noopener noreferrer'>{T.translate("ConfirmIdentity.Stage2.Stage1FailQR.BlockLinkText")}</a> })}</span>
                        </p>
                        <div className='bottomMarginMobile' />
                    </div>
            </div>
            </div>
            }
            {(layoutType === 'tablet') &&
            <div className="confirmContainerOuter">
                <div>
                    <div className="confirmBackButtonStyle" onClick={onBackToDashClicked}>
                        <FontAwesome className='fa-angle-left' name='fa-angle-left' size='2x' />
                        <h4>{T.translate(backLabel)}</h4>
                    </div>
                </div>
                <div className="confirmContainer mediumMargins">
                    <div className='headerWidth'>
                        <h1>{T.translate('ConfirmIdentity.Stage2.Stage1FailQR.Title')}</h1>
                        <div className='bufferSpace' />
                    </div>
                    <div className='headerWidth'>
                        <p>{T.translate('ConfirmIdentity.Stage2.Stage1FailQR.SubTitleMobile')}</p>
                        <div className='bufferSpace' />
                    </div>  
                        <div className='patientMessageFormat'>
                            <p>{T.translate('ConfirmIdentity.Stage2.Stage1FailQR.PatientMessage')}</p>
                        <div className='bufferSpace' />
                    </div>  
                    <div className="confirmSummaryFooterButtonContainer">
                            <PlainButton labelKey='ConfirmIdentity.Stage2.Stage1FailQR.CompleteVerificationButton' onClick={handleQrClick}/>
                    </div>
                    <div className='headerWidth'>
                        <p><span>{T.translate('ConfirmIdentity.Stage2.Stage1FailQR.CantCompleteMobile')}</span><br />
                            <span>{T.translate('ConfirmIdentity.Stage2.Stage1FailQR.QRCodeNotWorkingOptionMobile', { 'BlockLink': <a href={'' + T.translate("ConfirmIdentity.Failed.LocatorLink")} target='_blank' rel='noopener noreferrer'>{T.translate("ConfirmIdentity.Stage2.Stage1FailQR.BlockLinkText")}</a> })}</span>
                        </p>
                        <div className='bottomMarginMobile' />
                    </div>
                </div>
            </div>
            }

        </div>
        );

};


const mapDispatchToProps = (dispatch) => ({
    completeQrCodeVerification: (data, isSpouse, isCoupled) => dispatch(ConfirmIdentityActions.completeQrCodeVerification(data, isSpouse, isCoupled)),
    setButtonState: (buttonName, buttonState) => dispatch(WorkflowActions.setButtonState(buttonName, buttonState)),
});

export default connect(null, mapDispatchToProps)(ConfirmStage1FailedQRCode) 