import { combineReducers } from 'redux'
import configureStore, { history }  from './createStore'
import rootSaga from '../sagas/'
import { connectRouter } from 'connected-react-router';

import { reducer as AccountDataReducer } from './accountDataRedux'
import { reducer as AuthReducer } from './authRedux'
import { reducer as DocumentsReducer } from './documentsRedux'
import { reducer as LayoutReducer } from './layoutRedux'
import { reducer as LoginReducer } from './loginRedux'
import { reducer as MessagesReducer } from './messagesRedux'
import { reducer as RegisterReducer } from './registerRedux'
import { reducer as InterviewReducer } from './interviewRedux'
import { reducer as WorkflowReducer } from './workflowRedux'
import { reducer as PaymentReducer } from './paymentRedux'
import { reducer as AuthorizeCRAReducer } from './authorizeCRARedux'
import { reducer as SurveyReducer } from './surveyRedux'
import { reducer as SigningReducer } from './signingRedux'
import { reducer as ApproveDraftsReducer } from './approveDraftsRedux'
import { reducer as ConfirmIdentityReducer } from './confirmIdentityRedux'
import { reducer as ConfigReducer } from './configRedux'
import { reducer as FilesReducer } from './filesRedux'
import { reducer as ProductsReducer } from './productsRedux'
import { reducer as WorkflowNewReturnReducer } from './workflowNewReturnRedux'

export default () => {
  /* ------------- Assemble The Reducers ------------- */
  // this is to build all top-level states that will be available
  const rootReducer = combineReducers({
      accountData: AccountDataReducer,
      auth: AuthReducer,
      documents: DocumentsReducer,
      layout: LayoutReducer,
      login: LoginReducer,
      messages: MessagesReducer,
      register: RegisterReducer,
      interview: InterviewReducer,
      workflow: WorkflowReducer,
      workflowNewReturn: WorkflowNewReturnReducer,
      authorizeCRA: AuthorizeCRAReducer,
      payment: PaymentReducer,
      survey: SurveyReducer,
      signing: SigningReducer,
      approveDrafts: ApproveDraftsReducer,
      confirmIdentity: ConfirmIdentityReducer,
      config: ConfigReducer,
      files: FilesReducer,
      products: ProductsReducer,
      router: connectRouter(history)
  })

  return configureStore(rootReducer, rootSaga)
}