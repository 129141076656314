import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import T from 'i18n-react';
import './rm.css'
import FontAwesome from 'react-fontawesome';

const RegisterProgressMessage = () => {
    const [addShow, setAddShow] = useState(false)

    const progressMessageKey = useSelector(state => state.register.progressMessageKey);

    useEffect(() => {
        let timeout = null
        if (!addShow && !timeout) {
            timeout = setTimeout(() => setAddShow(true), 50)
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout)
                timeout = null
            }
        }
    }, [addShow, setAddShow]);

    return (
        <div className='rm-outer'>
            <div className="rm-inner">
                <FontAwesome className='fa-spinner fa-default-style' name='spinner' pulse size='3x'/>
                <div className={classnames('rm-fade-in-text', { 'rm-show': addShow })}>
                    {T.translate(progressMessageKey)}
                </div>
            </div>
        </div>
    )
}

export default RegisterProgressMessage
