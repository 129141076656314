import React, { Component } from 'react';
import './shared.css'

import T from 'i18n-react';
import CheckButtons from './checkbuttons'

class PhoneTypeToggle extends Component {
    constructor(props) {
        super(props)

        var currValue = null
        if (this.props.value === 1) {
            currValue = 1
        } else if (this.props.value === 2) {
            currValue = 2
        }

        //if (this.props.value === 'Y' || this.props.value === 'N' || this.props.value === 'y' || this.props.value === 'n') {
        //    currValue = this.props.value.toUpperCase()
        //}

        this.state = {
            value: currValue,
            name: this.props.name,
            missingRequiredField: this.props.missingRequiredField
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.value !== newProps.value) {
            this.setState({value: newProps.value})
        }
        if (this.state.missingRequiredField !== newProps.missingRequiredField) {
            this.setState({ missingRequiredField: newProps.missingRequiredField })
        }
    }

    handleChange(ev) {
        if (ev) {
            var newValue = null

            var buttonStates = ev.buttonStates || [];
            buttonStates.forEach((buttonState) => {
                if (buttonState.IsChecked) {
                    newValue = buttonState.Tag;
                }
            })

            // only set it if it has a value, otherwise, make sure
            // it keeps it's existing value (if we don't do this, it
            // won't re-render)
            newValue = newValue || this.state.value

            this.setState({ value: newValue })
            if (this.props.onStateChanged) {
                this.props.onStateChanged({ target: { name: this.state.name,value: newValue } }, newValue)
            }
        }
    }

    render() {
        const toggleOptions = [
            { Label: T.translate('Common.Mobile'), Tag: '1', isChecked: this.state.value === 1},
            { Label: T.translate('Common.Home'), Tag: '2', isChecked: this.state.value === 2 }
        ];
        return (
            <CheckButtons buttonData={toggleOptions} isRadio missingRequiredField={this.state.missingRequiredField}
                onButtonStateChanged={(ev) => this.handleChange(ev)} />
        )
    }
}


export default PhoneTypeToggle;