/* eslint-disable-next-line */
import T from 'i18n-react';
import React, { useEffect } from 'react';
import { string, bool, func } from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import AuthActions from '../../reducers/authRedux';
import LoginActions from '../../reducers/loginRedux';
import OuterContainer from '../Controls/outerContainer';
import './style.css';

const LogoutPage = props => {

    const {
        loginComplete,
        loginReset,
        requestLogin,
        logoutType,
        layoutType,
        logoutRedirectTarget,
        logoutUser,
        setLoggingOut,
        loaded } = props

    const wasAuto = logoutType === 'logout_auto'
    const titleTextKeyBase = wasAuto ? 'Logout.labels.AutoLoggedOut' : 'Logout.labels.LoggedOut'
  
    const showIcon = layoutType !== 'mobile'

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoggingOut(false)
        if (loginComplete) {
            logoutUser('logout')
        } else if (logoutRedirectTarget) {
            window.location.href = logoutRedirectTarget
        }
    }, [])

    const handleLoginClick = () => {
        loginReset()
        requestLogin()
    }

    return (
        <OuterContainer sizeToContent={true} loaded={loaded}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: '100%', justifyContent: 'left' }}>
                <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, fontSize: '24px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                    {showIcon &&
                        <FontAwesome className='fa-sign-out' name='fa-sign-out' size='2x' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#14AA40', align: 'top' }} />
                    }
                    <span style={{ marginLeft: showIcon ? 10 : 0, fontWeight: 'bold' }}>
                        <T.span text={{ key: `${titleTextKeyBase}1` }} />
                        <T.span text={{ key: `${titleTextKeyBase}2` }} />
                        <T.span text={{ key: `${titleTextKeyBase}3` }} />
                    </span>
                </div>
                <br />
                <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'stretch', marginRight: '20px' }}>
                    <p style={{ marginTop: 0, marginBottom: 0 }}>
                        {wasAuto && <T.span text={{ key: 'Logout.labels.AutoLogoutMessage' }} />}
                        {!wasAuto && <T.span text={{ key: 'Logout.labels.LogoutMessage1' }} />}
                    </p>
                    &nbsp;
                    <p style={{ marginTop: 0, marginBottom: 0, width: '100%' }}>
                        {!wasAuto && <T.span text={{ key: 'Logout.labels.LogoutMessage3' }} />}
                    </p>
                </div>
                <br />
                <br />
                <div className='defaultButtonStyle homeButtonWidth' style={{ marginLeft: 20, marginBottom: 10, alignSelf: 'flex-start' }} onClick={(ev) => handleLoginClick(ev)} >
                    <div style={{ width: 20 }} />
                    <span className="unselectableLabel"><T.span text={{ key: 'Common.SignIn' }} /></span>
                    <div style={{ width: 20 }} />
                </div>
                <div style={{ marginBottom: '10px' }} />
            </div>
        </OuterContainer>
    );
}

LogoutPage.propTypes = {
    lang: string,
    loginComplete: bool.isRequired,
    loginReset: func.isRequired,
    requestLogin: func.isRequired,
    logoutType: string,
    layoutType: string.isRequired,
    logoutRedirectTarget: string,
    logoutUser: func.isRequired,
    setLoggingOut: func.isRequired 
}

const mapStateToProps = (state) => {
    return {
        loginComplete: state.auth.loginComplete,
        lang: state.layout.lang,
        layoutType: state.layout.layoutType,
        logoutType: state.auth.logoutType,
        logoutRedirectTarget: state.auth.logoutRedirectTarget
    }
}

const mapDispatchToProps = (dispatch) => ({
    loginReset: () => dispatch(LoginActions.loginReset()),
    requestLogin: () => dispatch(LoginActions.requestLogin()),
    logoutUser: (logoutType) => dispatch(AuthActions.logoutUser(logoutType)),
    setLoggingOut: (isLoggingOut) => dispatch(AuthActions.setLoggingOut(isLoggingOut))
})

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage)
