import React, { Component } from 'react';
import '../Common/shared.css';
import '../Interview/style.css'
import '../ConfirmIdentity/style.css'
import T from 'i18n-react';
import ValidatingTextInput from '../Common/validatingTextInput'
import SINTextInput from '../Common/sinTextInput'
import DateTextInput from '../Common/dateTextInput'
import NumberInput from '../Common/numberInput'
import PostalCodeTextInput from '../Common/postalCodeTextInput'
import ProvinceDropDown from '../Controls/DropDowns/provinceDropDown'

class UpdatePersonalInfoForm extends Component
{
    constructor(props)
    {
        super(props)

        var personalInfo = this.props.personalInfo || {
            firstName: '',
            lastName: '',
            dob: '',
            sin: '',
            aptNumber: '',
            streetName: '',
            streetNumber: '',
            postalCode: '',
            poBox: '',
            ruralRouteNumber: '',
            city: '',
            province: ''
        }

        this.state = {
            personalInfo,
            lang: this.props.lang,
            validate: this.props.validate
        }
    }

    componentWillReceiveProps(newProps)
    {
        if (this.state.validate !== newProps.validate) {
            this.setState({ validate: newProps.validate })
        }
        if (this.state.personalInfo !== newProps.personalInfo) {
            this.setState({ personalInfo: newProps.personalInfo })
        }
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }
    }

    handleTextChange = (ev) => {
        if (this.props.onPersonalInfoChanged) {
            this.props.onPersonalInfoChanged(ev.target.name, ev.target.value)
        }
    }

    handleValueChange = (event, value) => {
        this.props.onPersonalInfoChanged(event.target.name, value)
    };
    
    onAddressPropertyChange = (event, value) => {
        if (this.props.onPersonalInfoChanged) {
            this.props.onPersonalInfoChanged(event.target.name, value)
        }
    }

    validateNameField(textValue) {
        return !textValue || textValue.match(/^[a-zA-Z\u00C0-\u017F\'\.\-\s]+$/)   // eslint-disable-line no-useless-escape
    }

    showError(fieldName) {
        return this.state.validate && Boolean(!this.state.personalInfo[fieldName])
    }

    showAddressError() {
        var validAddress = (Boolean(this.state.personalInfo.streetNumber) && Boolean(this.state.personalInfo.streetName))
            || Boolean(this.state.personalInfo.poBox) || Boolean(this.state.personalInfo.ruralRouteNumber)
        return this.state.validate && !validAddress
    }

    render() {
        var provHeaderStyles = ['formLabelStyle']
        if (!this.state.personalInfo.province) {
            provHeaderStyles.push('red')
        }
        return (
            <div className="confirmFullWidth">
                <form name="update_personal_info_form" className="confirmFullWidth" autoComplete="on">
                <div className="confirmHorizontalFieldContainerCenterAligned">
                    <div className='defaultInputWidth rightMargin'>
                        <ValidatingTextInput name="firstName" autoComplete="given_name"
                            labelText={T.translate('Interview.Page1.fieldLabel0')}
                            value={this.state.personalInfo.firstName} heapIgnore="true"
                            onChange={(ev) => this.handleTextChange(ev)}
                            validationFailedText={T.translate("Common.Errors.InvalidNameChars")}
                            validator={(textValue) => { return this.validateNameField(textValue) }}
                            mainDivAdditionalClass="fixedWidthTextInput"
                            renderErrorBorderOnly={this.showError('firstName')}
                        />
                    </div>     
                    <div className='defaultInputWidth leftMargin rightMargin'>
                        <ValidatingTextInput name="middleName" autoComplete="additional-name"
                            labelText={T.translate('Interview.Page1.fieldLabel1')}
                            value={this.state.personalInfo.middleName} heapIgnore="true"
                            onChange={(ev) => this.handleTextChange(ev)}
                            isRequired={Boolean(this.state.allRequiredFields)}
                            validationFailedText={T.translate("Common.Errors.InvalidNameChars")}
                            validator={(textValue) => { return this.validateNameField(textValue) }}
                            mainDivAdditionalClass="fixedWidthTextInput"
                        />
                    </div>
                    <div className='defaultInputWidth leftMargin'>
                        <ValidatingTextInput name="lastName" autoComplete="family-name"
                            labelText={T.translate('Interview.Page1.fieldLabel2')}
                            value={this.state.personalInfo.lastName} heapIgnore="true"
                            onChange={(ev) => this.handleTextChange(ev)}
                            isRequired={Boolean(this.state.allRequiredFields)}
                            missingRequiredField={this.state.missingRequiredField}
                            validationFailedText={T.translate("Common.Errors.InvalidNameChars")}
                            validator={(textValue) => { return this.validateNameField(textValue) }}
                            mainDivAdditionalClass="fixedWidthTextInput"
                            renderErrorBorderOnly={this.showError('lastName')}
                        />
                    </div>
                </div>
                <div className="confirmHorizontalFieldContainerCenterAligned">
                    <div className='defaultInputWidth rightMargin'>
                        <DateTextInput name="dob" size="lg" validateMinAge={this.props.validateMinAge}
                            labelText={T.translate('Interview.Page1.fieldLabel3')}
                            value={this.state.personalInfo.dob} heapIgnore="true"
                            birthDate={true} autoComplete="bday"
                            renderErrorBorderOnly={this.showError('dob')}
                            onChange={(ev, value) => this.handleValueChange(ev, value)} />
                    </div>                   
                    <div className='defaultInputWidth leftMargin'>
                        <SINTextInput name="sin" size="lg" value={this.state.personalInfo.sin}
                            labelText={T.translate('Interview.Page1.fieldLabel5')}
                            onChange={(ev) => this.handleTextChange(ev)} 
                            renderErrorBorderOnly={this.showError('sin')}
                            isRequired={this.state.allRequiredFields}
                        />
                    </div>
                </div>
                <div className="confirmHorizontalFieldContainerCenterAligned">
                    <div className="shortInputWidth rightMargin">
                        <ValidatingTextInput name="streetNumber" autoComplete="off" heapIgnore="true"
                            labelText={T.translate('Interview.Page2.fieldLabel1')}
                            value={this.state.personalInfo.streetNumber}
                            renderErrorBorderOnly={this.showAddressError()}
                            onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)} />
                    </div>
                    <div className="longInputWidth leftMargin rightMargin" style={{ flex: 1 }}>
                        <ValidatingTextInput name="streetName" autoComplete="off" heapIgnore="true"
                            labelText={T.translate('Interview.Page2.fieldLabel2')}
                            value={this.state.personalInfo.streetName}
                            renderErrorBorderOnly={this.showAddressError()}
                            onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)} />
                    </div>
                    <div className="shortInputWidth leftMargin">
                        <ValidatingTextInput name="aptNumber" autoComplete="off" heapIgnore="true"
                            labelText={T.translate('Interview.Page2.fieldLabel0')}
                            value={this.state.personalInfo.aptNumber}
                            renderErrorBorderOnly={this.showAddressError()}
                            onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)} />
                    </div>
                </div>
                <div className="confirmHorizontalFieldContainerCenterAligned">
                    <div className="defaultInputWidth rightMargin">
                        <NumberInput name="poBox" autoComplete="off" heapIgnore="true"
                            labelText={T.translate('Interview.Page2.fieldLabel3')}
                            value={this.state.personalInfo.poBox}
                            onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)}
                            validationFailedText={T.translate(this.state.labelKey + ".validationFailedLabel0")}
                            renderErrorBorderOnly={this.showAddressError()}
                            validator={(textValue) => { return this.validateNumberField(textValue) }} />
                    </div>
                    <div className="defaultInputWidth leftMargin">
                        <ValidatingTextInput name="ruralRouteNumber" autoComplete="off" heapIgnore="true"
                            labelText={T.translate('Interview.Page2.fieldLabel4')}
                            value={this.state.personalInfo.ruralRouteNumber}
                            onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)}
                            renderErrorBorderOnly={this.showAddressError()}
                            mainDivAdditionalClass="fixedWidthTextInput" />
                    </div>
                </div>
                <div className="confirmHorizontalFieldContainerCenterAligned">
                    <div className="defaultInputWidth rightMargin">
                        <ValidatingTextInput name="city" autoComplete="address-level2" heapIgnore="true"
                            labelText={T.translate('Interview.Page2.fieldLabel5')}
                            value={this.state.personalInfo.city}
                            onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)}
                            renderErrorBorderOnly={this.showError('city')}
                            mainDivAdditionalClass="fixedWidthTextInput" />
                    </div>
                    <div className="validatingTextInputStyle defaultInputWidth leftMargin rightMargin">
                        <div>
                            <div className={provHeaderStyles.join(' ')}>{T.translate('Interview.Page2.fieldLabel6')}</div>
                            <ProvinceDropDown name="province" options={this.provinceOptions}
                                value={this.state.personalInfo.province}
                                onValueChanged={(ev, value) => this.onAddressPropertyChange(ev, value)}
                                missingRequiredField={this.showError('province')}
                            />
                            <div className="validationErrorStyle">&nbsp;</div>
                        </div>
                    </div>
                    <div className="validatingTextInputStyle defaultInputWidth leftMargin">
                        <PostalCodeTextInput name="postalCode" autoComplete="postal-code"
                            labelText={T.translate('Interview.Page2.fieldLabel7')}
                            value={this.state.personalInfo.postalCode}
                            onChange={(ev, newValue) => this.onAddressPropertyChange(ev, newValue)}
                            validationFailedText="Common.Errors.InvalidPostalCode"
                            renderErrorBorderOnly={this.showError('postalCode')}
                            mainDivAdditionalClass="fixedWidthTextInput" />
                    </div>
                    </div>
                    </form>
            </div>
        );
    }
}

export default UpdatePersonalInfoForm;