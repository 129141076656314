export const WorkflowTasks = {
    INTERVIEW_COMPLETED: "INTERVIEW_COMPLETED",
    AUTHORIZE_CRA: "AUTHORIZE_CRA",
    AUTHORIZE_RQ: "AUTHORIZE_RQ",
    AUTHORIZE_CRA_SPOUSE: "AUTHORIZE_CRA_SPOUSE",
    AUTHORIZE_RQ_SPOUSE: "AUTHORIZE_RQ_SPOUSE",
    CONFIRM_ID: "CONFIRM_ID",
    CONFIRM_ID_SPOUSE: "CONFIRM_ID_SPOUSE",
    DOCS_UPLOADED: "DOCS_UPLOADED",
    RETURN_PREPARED: "RETURN_PREPARED",
    RETURN_REVIEWED: "RETURN_REVIEWED",
    RETURN_REVIEWED_RQ: "RETURN_REVIEWED_RQ",
    RETURN_REVIEWED_SPOUSE: "RETURN_REVIEWED_SPOUSE",
    RETURN_REVIEWED_RQ_SPOUSE: "RETURN_REVIEWED_RQ_SPOUSE",
    PAYMENT_RECEIVED: "PAYMENT_RECEIVED",
    RETURN_FILED: "RETURN_FILED",
    TAX_PRO_ASSIGNED: "TAX_PRO_ASSIGNED",
}

export const SecurityTasks = [
    WorkflowTasks.AUTHORIZE_CRA, WorkflowTasks.AUTHORIZE_CRA_SPOUSE, WorkflowTasks.AUTHORIZE_RQ,
    WorkflowTasks.AUTHORIZE_RQ_SPOUSE, WorkflowTasks.CONFIRM_ID, WorkflowTasks.CONFIRM_ID_SPOUSE
];

export const ReviewTasks = [
    WorkflowTasks.RETURN_REVIEWED, WorkflowTasks.RETURN_REVIEWED_RQ,
    WorkflowTasks.RETURN_REVIEWED_SPOUSE, WorkflowTasks.RETURN_REVIEWED_RQ_SPOUSE
]

export const CompletedStates = ["VDO_COMPLETE", "VDO_COMPLETE_C", "VDO_COMPLETE_RQC", "VDO_COMPLETE_RQ",
    "RTE_COMPLETE", "RTE_COMPLETE_C", "RTE_COMPLETE_RQC", "RTE_COMPLETE_RQ", 
    "VIR_COMPLETE", "VIR_COMPLETE_C"]

export const isSecurityTask = (identifier) => {
    return SecurityTasks.includes(identifier)
}

export const isReviewTask = (identifier) => {
    return ReviewTasks.includes(identifier);
}

export const isCustomerActionsStateOrBefore = (state) => {
    let s = state || "";
    return s.startsWith("VDO_CREATED") || s.startsWith("VDO_INTERVIEW") || s.startsWith("VDO_CUSTOMER_ACTIONS");
}

export const isVirWaitingToFile = (state) => {
    let s = state || "";
    return s.startsWith('VIR_CLIENT_CALL');
}