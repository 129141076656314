import React from 'react'
import { PropTypes } from 'prop-types'
import BaseModal from '../../Modal/BaseModal'
import './deleteReturnModal.css'
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';
import PlainButton from '../../Controls/plainButton'


const DeleteReturnModal = ({ visible, layoutType, lang, onCancel, onDelete, taxYear, inProgress }) => (
    <BaseModal show={visible} hideHeader={false} layoutType={layoutType} lang={lang} onClose={inProgress ? () => { } : onCancel}>
        <div className={'dr-modal-container ' + layoutType}>
            <div className='dr-modal-padding'>
                <div className='dr-modal-icon'>
                    <FontAwesome className='fa-exclamation' name='fa-exclamation' size='4x' />
                </div>
                <div className='dr-modal-content'>
                    <h1>{T.translate('WorkflowPanel.delete_return.title', { TaxYear: taxYear })}</h1>
                    <p>{T.translate('WorkflowPanel.delete_return.content1', { TaxYear: taxYear })}</p>
                    <p>{T.translate('WorkflowPanel.delete_return.content2', { TaxYear: taxYear })}</p>
                </div>
                <div className='dr-modal-buttons'>
                    <PlainButton labelKey='WorkflowPanel.delete_return.cancel'
                        onClick={onCancel}
                        isEnabled={!Boolean(inProgress)}
                        isWarning={true}
                        extraClassName='dr-modal-button-padding'
                    />
                    <PlainButton labelKey='WorkflowPanel.delete_return.continue'
                        inverted={true}
                        onClick={() => onDelete(taxYear)}
                        isWarning={true}
                        extraClassName='dr-modal-button-padding'
                        inProgress={Boolean(inProgress)}
                    />
                </div>
            </div>
        </div>
    </BaseModal>
)


DeleteReturnModal.propTypes = {
    lang: PropTypes.string.isRequired,
    layoutType: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    taxYear: PropTypes.number.isRequired,
    inProgress: PropTypes.bool
}

export default DeleteReturnModal;
