export const getTaxYearState = (taxYear, case_state) => {
    if (case_state && case_state.taxYears) {
        for (var i = 0; i < case_state.taxYears.length; ++i) {
            if (Number(case_state.taxYears[i].taxYear) === Number(taxYear)) {
                return case_state.taxYears[i];
            }
        }
    }
    return null;
}

export const getConfirmAuthorizeState = (case_state) => {
    var isRq = false;
    var requiresSpouse = false;
    var spouseInRq = false
    var spouseFirstName = null;

    if (!case_state || !case_state.taxYears) {
        return {}
    }

    for (var i = 0; i < case_state.taxYears.length; ++i) {
        isRq = isRq || Boolean(case_state.taxYears[i].primary_province === 'QC');
        requiresSpouse = requiresSpouse || Boolean(case_state.taxYears[i].coupled);
        spouseInRq = spouseInRq || Boolean(case_state.taxYears[i].coupled && case_state.taxYears[i].spouse_province === 'QC')
        spouseFirstName = spouseFirstName || case_state.taxYears[i].spouse_firstName
    };

    var primaryShowConfirm = case_state.confirm_id.self !== 'Confirmed'
    var spouseShowConfirm = requiresSpouse && case_state.confirm_id.spouse !== 'Confirmed'

    var primaryShowAuthorize = case_state.authorize.self.cra !== 'Authorized'
    var primaryAuthorizeAddedQC = isRq && case_state.authorize.self.rq !== 'Authorized'
    var spouseShowAuthorize = (requiresSpouse && case_state.authorize.spouse.cra !== 'Authorized') ||
        (spouseInRq && case_state.authorize.spouse.rq !== 'Authorized');

    return { primaryShowConfirm, spouseShowConfirm, primaryShowAuthorize, primaryAuthorizeAddedQC, spouseShowAuthorize, spouseFirstName }
}

export const getMostRecentPrimaryInterviewTaxYearState = (case_state) => {
    if (!case_state || !case_state.taxYears) {
        return {}
    }

    var maxTaxYear = 0;
    var taxYearState = null;
    for (var i = 0; i < case_state.taxYears.length; ++i) {
        var ty = case_state.taxYears[i]
        if (ty.interview_state.complete && ty.taxYear > maxTaxYear) {
            maxTaxYear = ty.taxYear;
            taxYearState = ty;
        }
    }
    return taxYearState
}

export const getMostRecentSpouseInterviewTaxYearState = (case_state) => {
    if (!case_state || !case_state.taxYears) {
        return {}
    }

    var maxTaxYear = 0;
    var taxYearState = null;
    for (var i = 0; i < case_state.taxYears.length; ++i) {
        var ty = case_state.taxYears[i]
        if (ty.coupled && ty.interview_state.complete && ty.taxYear > maxTaxYear) {
            maxTaxYear = ty.taxYear;
            taxYearState = ty;
        }
    }
    return taxYearState
}

export const determineIsReturningClient = (case_state) => {
    var bool = false;
    for (var i = 0; i < case_state.taxYears.length; ++i) {
        var ty = case_state.taxYears[i]
        if (ty.interview_state.complete) {
            bool = true;
        }
    }
    return bool;
}

export const determineIsVir = (case_state) => {
    var isVir = false;
    if (case_state && case_state.taxYears) {
        for (let i = 0; i < case_state.taxYears.length; i++) {
            if (case_state.taxYears[i].is_vir === true) {
                isVir = true;
                break;
            }
        }
    }   

    return isVir;
}

export const determineIsDirectClient = (case_state) => {
    var bool = false;

    if (localStorage.getItem('va_direct_code')) {
        // do an initial check for a direct code in local storage.
        // if there is one, return true
        bool = true;
    } else if (!case_state || !case_state.taxYears) {
        // if we don't have enough info to go on, set return value to null, as most functionality
        // that depends on this will want to check for its existence as well as true/false
        bool = null;
    } else {
        // look through each case, and if any are a 'direct' return, return true
        for (var i = 0; i < case_state.taxYears.length; ++i) {
            if (case_state.taxYears[i].is_direct) {
                bool = true;
                break;
            }
        }
    }
    return bool;
}

export const getAssignedTaxPros = (case_state, currentYear) => {
    if (!case_state || !case_state.taxYears) {
        return []
    }

    var taxPros = case_state.taxYears.filter(a => !a.is_closed)
        .map((ty) => ({ taxYear: ty.taxYear, assigned_tax_pro: ty.assigned_tax_pro }))
    var tp = null;
    var taxYear = null;
    for (var i = 0; i < taxPros.length; i++) {
        if (tp === null) {
            tp = taxPros[i].assigned_tax_pro
            taxYear = taxPros[i].taxYear;
        } else {
            if (taxPros[i] && tp.name !== taxPros[i].name) {
                return taxPros;
            }
        }
    }
    if (tp) {
        return [{ taxYear: taxYear, assigned_tax_pro: tp }]
    } else {
        return []
    }
}
