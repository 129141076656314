import React, { useContext } from 'react'
import { func } from 'prop-types'

// create the context
export const UnauthorizedContext = React.createContext(key => key)

// make a hook for easy importing and usage
export const useUnauthorizedContext = () => useContext(UnauthorizedContext)

// export provider as a separate component
export const UnauthorizedContextProvider = props =>  (
    <UnauthorizedContext.Provider value={props.onHandleUnauthorizedUser}>
        {props.children}
    </UnauthorizedContext.Provider>
)

UnauthorizedContextProvider.propTypes = {
    onHandleUnauthorizedUser: func.isRequired
}

UnauthorizedContextProvider.whyDidYouRender = false