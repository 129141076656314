import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome'
import './shared.css'
import './iconCheckButton.css'

class IconCheckButton extends Component {
    constructor(props) {
        super(props)
     
        this.state = {
            checked: this.props.checked,
        }
    }

    onCheck(ev) {
        const isChecked = !this.state.checked
        this.setState({ checked: isChecked })
        if (this.props.onCheckChanged) {
            const target = { name: this.props.name }
            const value = isChecked
            this.props.onCheckChanged({ target }, value)
        }
    }

    render() {
        let classes = []
        classes.push(this.state.checked ? "iconCheckButtonChecked" : "iconCheckButton")
        classes.push(this.props.additionalClasses)

        return (
            <div className="iconCheckButtonOuter">
                <div className="iconCheckButtonCheckContainer">
                    <FontAwesome className='fa-check-circle checkedMarker' name='fa-check-circle' size='2x' style={this.state.checked ? null : { display: 'none' }} />
                </div>
                <div className={classes.join(' ')} onClick={(ev) => this.onCheck(ev)}>
                    <div className="iconCheckButtonIcon">
                        <FontAwesome
                            className={this.props.icon}
                            name={this.props.name}
                            size={this.props.size || '4x'}
                            style={{ align: 'center', marginTop: this.props.imageTopSpacing || '0px' }} />
                    </div>
                    <div className="iconCheckButtonLabel">
                        <span>{this.props.label}</span>
                        {this.props.secondaryLabel && <span className="secondary">{this.props.secondaryLabel}</span>}
                    </div>
                </div>
            </div>
        );
    }
}

export default IconCheckButton;