import React, { Component } from 'react';
import '../../Common/shared.css'
import '../style.css';
import T from 'i18n-react';
import PersonalInfoForm from '../../Common/personalInfoForm'
import ProvinceDropDown from '../../Controls/DropDowns/provinceDropDown'
import InterviewTitleProgress from '../../Common/interviewTitleProgress'
import FontAwesome from 'react-fontawesome';

class InterviewPage5a extends Component {
    constructor(props) {
        super(props)

        this.state = {
            spouse: this.props.spouse || {
                firstName: '',
                middleName: '',
                lastName: '',
                gender: '',
                dob: '',
                sin: '',
                residenceProvince: ''
            }
        }
    }

    onPersonalInfoChanged(personalInfo) {
        var spouse = {
            ...this.state.spouse,
            firstName: personalInfo.firstName,
            middleName: personalInfo.middleName,
            lastName: personalInfo.lastName,
            gender: personalInfo.gender,
            dob: personalInfo.dob,
            sin: personalInfo.sin
        }
        this.setState({ spouse })
        if (this.props.onSpouseChanged) {
            this.props.onSpouseChanged(spouse)
        }
    }
        
    validateNameField(textValue) {
        return !textValue || textValue.match(/^[a-zA-Z\u00C0-\u017F\'\.\-\s]+$/)   // eslint-disable-line no-useless-escape
    }

    onProvinceChanged(ev, value) {
        if (ev) {
            var spouse = {
                ...this.state.spouse,
                residenceProvince: value
            }
            this.setState({ spouse });
            if (this.props.onSpouseChanged) {
                this.props.onSpouseChanged(spouse)
            }
        }
    }

    render() {

        var nextTitle = 'Interview.Page5.nextTitle1'
        var percentage = '66'
        if (this.props.spouseTaxes === 'Y') {
            nextTitle = 'Interview.Page5.nextTitle3'
            percentage = '60'
        }
        if (this.props.spouseTaxes === 'N') {
            nextTitle = 'Interview.Page5.nextTitle2'
            percentage = '64'
        }  
        if (this.props.dependants === 'Y') {
            nextTitle = 'Interview.Page5.nextTitle2'
            percentage = '64'
        }
        if (this.props.dependants === 'N') {
            nextTitle = 'Interview.Page5.nextTitle4'
            percentage = '66'
        }
        
        let validationContent = (this.props.missingRequiredField && !this.state.spouse.residenceProvince) ? (
            <div className="validationErrorStyle">
                <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#E70000', align: 'center' }} />
                <span style={{ marginLeft: 10 }}>{T.translate('Common.Errors.SelectAnAnswer')}</span>
            </div>
        ) : null;

        return (
            <div>
                <div>
                    <div>
                        <InterviewTitleProgress taxYear={this.props.taxYear} percentage={percentage} title="Interview.Page5.headerTitle" nextTitle={nextTitle} />
                    </div> 
                    <div style={{ height: 20 }} />
                </div>
                <PersonalInfoForm missingRequiredField={this.props.missingRequiredField} page={'page5a'}
                    lang={this.props.lang} allRequiredFields
                    labelKey="Interview.Page1" simpleVersion personalInfo={this.state.spouse}
                    onPersonalInfoChanged={(personalInfo) => this.onPersonalInfoChanged(personalInfo)} />
                <div className="horizontalFieldContainerCenterAligned" style={{ marginTop: 20 }}>
                    <div className="fixed inlineLabelStyle">
                        <span className="checkboxTextStyle">
                            {T.translate('Interview.Page5.fieldLabel1', { TaxYear: this.props.taxYear })}                            
                        </span>
                    </div>
                    <div className="defaultInputWidth">
                        <ProvinceDropDown name="provinceChange" value={this.state.spouse.residenceProvince}
                            onValueChanged={(ev, value) => { this.onProvinceChanged(ev, value) }}
                            missingRequiredField={this.props.missingRequiredField && !this.state.spouse.residenceProvince}                        />
                        {validationContent}
                    </div>
                </div>
            </div>
        )
    }
}

export default InterviewPage5a;