import React, { Component } from 'react';
import './shared.css'

import T from 'i18n-react';
import CheckButtons from './checkbuttons'

class YesNoCheckButtons extends Component {
    constructor(props) {
        super(props)

        var currValue = null
        if (this.props.value === true) {
            currValue = 'Y'
        } else if (this.props.value === false) {
            currValue = 'N'
        }

        if (this.props.value === 'Y' || this.props.value === 'N' || this.props.value === 'y' || this.props.value === 'n') {
            currValue = this.props.value.toUpperCase()
        }

        this.state = {
            value: currValue,
            name: this.props.name,
            missingRequiredField: this.props.missingRequiredField,
            disabled: this.props.disabled
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.value !== newProps.value) {
            this.setState({value: newProps.value})
        }
        if (this.state.missingRequiredField !== newProps.missingRequiredField) {
            this.setState({ missingRequiredField: newProps.missingRequiredField })
        }
        if (this.state.disabled !== newProps.disabled) {
            this.setState({disabled: newProps.disabled})
        }
    }

    handleChange(ev) {
        if (ev) {
            var newValue = null

            var buttonStates = ev.buttonStates || [];
            buttonStates.forEach((buttonState) => {
                if (buttonState.IsChecked) {
                    newValue = buttonState.Tag;
                }
            })

            // only set it if it has a value, otherwise, make sure
            // it keeps it's existing value (if we don't do this, it
            // won't re-render)
            newValue = newValue || this.state.value

            this.setState({ value: newValue })
            if (this.props.onStateChanged) {
                this.props.onStateChanged({ target: { name: this.state.name } }, newValue)
            }
        }
    }

    render() {
        const yesNoOptions = [
            { Label: T.translate('Common.Yes'), Tag: 'Y', isChecked: this.state.value === 'Y' },
            { Label: T.translate('Common.No'), Tag: 'N', isChecked: this.state.value === 'N' }
        ];
        return (            
            <CheckButtons buttonData={yesNoOptions} isRadio missingRequiredField={this.state.missingRequiredField}
                onButtonStateChanged={(ev) => this.handleChange(ev)} name={this.state.name} disabled={this.state.disabled} />            
        )
    }
}


export default YesNoCheckButtons;