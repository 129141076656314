// src/components/Interview/interview.js
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { replace, push } from 'connected-react-router'
import { withRouter } from 'react-router-dom'
import './style.css';
import T from 'i18n-react';
import * as pages from './Pages/index';
import InterviewActions, { interviewCloneInterview } from '../../reducers/interviewRedux'
import WorkflowActions from '../../reducers/workflowRedux'
import { isValidSIN } from '../Common/sinTextInput'
import { isValidBirthdate } from '../Common/dateTextInput'
import LoadingSpinner from '../Loading/loadingSpinner'
import NavButton from './navButton'
import { determineIsReturningClient } from '../../utils/CaseStateUtilities'
import Navigation from '../Common/navigation'
import { HeapEvents, trackEvent } from '../../utils/AnalyticsUtil'
import { isValidPostalCode } from '../../utils/PostalCodeUtil'
import classnames from 'classnames'

class Interview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            layoutType: this.props.layoutType,
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            lang: this.props.lang,
            interview: this.props.interview,
            page: this.props.page,
            isLoaded: this.props.isLoaded,
            missingRequiredField: this.props.missingRequiredField,
            mobilePhone: this.props.mobilePhone,
            dependantIndex: this.props.dependantIndex,
            location: this.props.location,
            taxYear: this.props.taxYear,
            saving: false,
            missingRequiredSexField: this.props.missingRequiredSexField,
            isFranchise: this.props.isFranchise,
            showPricingGlobal: this.props.showPricingGlobal,
            taxScenarios_primary: this.props.taxScenarios_primary,
            taxScenarios_spouse: this.props.taxScenarios_spouse
        }
    }

    // React Stuff
    componentWillReceiveProps(newProps) {
        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }

        if (this.state.firstName !== newProps.firstName) {
            this.setState({ firstName: newProps.firstName })
        }

        if (this.state.mobilePhone !== newProps.mobilePhone) {
            this.setState({ mobilePhone: newProps.mobilePhone })
        }

        if (this.state.lastName !== newProps.lastName) {
            this.setState({ lastName: newProps.lastName })
        }

        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }

        if (this.state.interview !== newProps.interview) {
            this.setState({ interview: newProps.interview })
        }

        if (this.state.page !== newProps.page) {
            this.setState({ page: newProps.page })
        }

        if (this.state.isLoaded !== newProps.isLoaded) {
            this.setState({ isLoaded: newProps.isLoaded })

            if (newProps.isLoaded) { this.interviewLoaded(newProps) }
        }

        if (this.state.missingRequiredField !== newProps.missingRequiredField) {
            this.setState({ missingRequiredField: newProps.missingRequiredField })
        }

        if (this.state.dependantIndex !== newProps.dependantIndex) {
            this.setState({ dependantIndex: newProps.dependantIndex })
        }

        if (this.state.location !== newProps.location) {
            this.setState({ location: newProps.location })
        }

        if (this.state.taxYear !== newProps.taxYear) {
            this.setState({ taxYear: newProps.taxYear })
            if (!newProps.isLoaded) {
                newProps.setDefaultInterview(newProps.taxYear, false)
                newProps.loadInterview(newProps.taxYear)
            }
            if (this.state.saving) {
                this.setState({ saving: false });
            }
        }

        if (this.state.missingRequiredSexField !== newProps.missingRequiredSexField) {
            this.setState({ missingRequiredSexField: newProps.missingRequiredSexField })
        }
    }

    setInterviewPage = (page, isComplete) => {
        var additionalYear = this.props.case_state.taxYears
            .find(i => i.taxYear.toString() !== this.state.taxYear.toString() && !i.interview_state.complete);
        if (additionalYear && isComplete) {
            this.setState({ saving: true })
            this.props.setInterviewPage(this.state.taxYear, page, isComplete, additionalYear.taxYear)
        } else {
            this.props.setInterviewPage(this.state.taxYear, page, isComplete)
            this.props.pushNav('/dashboard/interview/' + this.state.taxYear + '/' + page);
            if (isComplete) {
                this.setState({ saving: true })
            }
        }
    }

    componentWillMount() {
        // OTE-220: if there is no interview data for the selected tax year,
        //          populate local store with the default template, and attempt to retrieve
        //          the interview from the server.
        if (!this.props.interview || !this.props.isLoaded) {
            this.props.setDefaultInterview(this.props.taxYear, false)
            this.props.loadInterview(this.props.taxYear)
        }
    }

    componentDidMount() {
        //VAI-252
        window.onpopstate = this.onBackButtonEvent;

        if (this.props.isLoaded === true)
            this.interviewLoaded(this.props)
    }

    interviewLoaded(props) {
        // pre-populate the first and last name fields if they are blank.
        let interview = {
            ...props.interview
        }

        // VAI-419 added extra checking for missing primary and name objects
        if (!interview.primary || !interview.primary.firstName || (interview.primary.firstName === '' && interview.primary.lastName === '')) {
            var newPrimary = Object.assign(interview.primary, { firstName: props.firstName, lastName: props.lastName });
            interview.primary = newPrimary;
            props.setInterview(interview, props.taxYear, props.isLoaded);
        }

        // VAI-246 tell the app that the interview is now in progress
        // Dashboard State 1: https://guqfvp.axshare.com/#g=1&p=state_1
        props.setButtonState('account_created', 'Complete');
        props.setButtonState('interview', 'Show');
    }

    backToDashboard() {
        // head on back to the dashboard
        this.props.replaceNavigation('/dashboard')
    }

    // Local event handlers
    onPreviousClick(e) {
        var i = this.state.interview
        var page = this.state.page

        switch (this.state.page) {
            case '0':
            case '1': this.backToDashboard(); return; // TODO: go back to year selection instead.
            case '2': page = '1'; break;
            case '3': page = '2'; break;
            case '4': page = '3'; break;
            case '5a': page = '4'; break;
            case '5b': page = '4'; break;
            case '6': page = '5b'; break;
            case '7': page = '6'; break;
            case '8':
                if (this.state.dependantIndex > 0) {
                    this.props.setDependantIndex(this.state.taxYear, this.state.dependantIndex - 1)
                } else {
                    if (i.primary.spouseTaxes === 'Y') {
                        page = '7';
                    }
                    if ((i.primary.maritalStatus === 'S') ||
                        (i.primary.maritalStatus === 'D') ||
                        (i.primary.maritalStatus === 'SP') ||
                        (i.primary.maritalStatus === 'W')) {
                        if (i.primary.maritalStatusChange === 'Y') {
                            page = '5a';
                        } else {
                            page = '4';
                        }
                    }
                    if ((i.primary.maritalStatus === 'M') || (i.primary.maritalStatus === 'C')) {
                        if (i.primary.spouseTaxes === 'N') {
                            page = '5a';
                        } else {
                            page = '7';
                        }
                    }
                };
                break;
            case '9':
                if (i.primary.dependants === 'Y') {
                    page = '8';
                    this.props.setDependantIndex(this.state.taxYear, i.dependants.length - 1)
                } else if ((i.primary.maritalStatusChange === 'Y') &&
                    ((i.primary.maritalStatus === 'S') ||
                        (i.primary.maritalStatus === 'D') ||
                        (i.primary.maritalStatus === 'W') ||
                        (i.primary.maritalStatus === 'SP'))) {
                    page = '5a';
                } else if (i.primary.spouseTaxes === 'Y') {
                    page = '7';
                } else if (i.primary.spouseTaxes === 'N') {
                    page = '5a';
                } else {
                    page = '4';
                }
                break;
            case '10':
                page = '9';
                break;
            case '10a':
                if (i.primary.spouseTaxes === 'Y') {
                    page = '10';
                } else {
                    page = '9';
                }
                break;
            case '11':
                if (!this.state.isFranchise && this.state.showPricingGlobal) {
                    page = '10a';
                } else if (i.primary.spouseTaxes === 'Y') {
                    page = '10';
                } else {
                    page = '9'
                }
                break;
            default: break;
        }

        if (page !== this.state.page) {
            // page change, scroll to top
            window.scrollTo(0, 0)
        }

        this.props.setMissingRequiredField(this.state.taxYear, false)
        this.props.setMissingRequiredSexField(this.state.taxYear, false)
        this.setInterviewPage(page, false)
    }

    onContinueClick(e) {
        var i = this.state.interview
        var page = this.state.page
        var isComplete = false

        // reset the required field error
        var missingRequiredField = false;
        var missingRequiredSexField = false;

        switch (this.state.page) {
            case '0':
                var hasPrimaryData = this.validRequiredField(i.primary.firstName) && this.validRequiredField(i.primary.lastName)
                    && isValidSIN(i.primary.sin) && Boolean(i.primary.maritalStatus) && this.validRequiredField(i.primary.residenceProvince)
                var isCoupled = i.primary.maritalStatus === 'M' || i.primary.maritalStatus === 'C'
                if (hasPrimaryData && isCoupled && !i.primary.spouseTaxes) {
                    hasPrimaryData = false
                }

                var preparingForSpouse = hasPrimaryData && isCoupled && i.primary.spouseTaxes === 'Y'

                var hasSpouseData = preparingForSpouse && this.validRequiredField(i.spouse.firstName) && this.validRequiredField(i.spouse.lastName)
                    && isValidSIN(i.spouse.sin) && this.validRequiredField(i.spouse.residenceProvince)
                if (!hasPrimaryData || (preparingForSpouse && !hasSpouseData)) {
                    missingRequiredField = true;
                } else {
                    isComplete = true;
                    trackEvent(HeapEvents.INTERVIEW_DONE);
                }
                break;
            case '1':
                if (!this.validRequiredField(i.primary.firstName) || !this.validRequiredField(i.primary.lastName) ||
                    !isValidSIN(i.primary.sin) || !isValidBirthdate(i.primary.dob, true, true).valid || !i.primary.nameChange || !i.primary.sin) {
                    missingRequiredField = true;

                } else {
                    page = '2';
                }
                break;
            case '2':
                if (!i.primary.postalCode || !isValidPostalCode(i.primary.postalCode) || !i.primary.city || !this.validRequiredField(i.primary.city) || !i.primary.province || !i.primary.telephone || !this.validPhone(i.primary.telephone) || !i.primary.mailingAddressChange) {
                    missingRequiredField = true
                } else {
                    page = '3';
                }
                break;
            case '3':
                if (!this.state.interview.primary.residenceProvince || (this.state.interview.primary.residenceProvince === 'QC' && !i.primary.gender) || !i.primary.provinceTerritoryChange || !i.primary.immigrateCanada || !i.primary.canadianCitizenship || (!i.primary.authorizeCraElectionsCanada && i.primary.canadianCitizenship === 'Y')) {
                    missingRequiredField = true;
                    if (this.state.interview.primary.residenceProvince === 'QC' && !i.primary.gender) {
                        missingRequiredSexField = true;
                    }
                } else {
                    page = '4';
                }
                break;
            case '4':
                if (!i.primary.maritalStatus || !i.primary.dependants || !i.primary.maritalStatusChange ||
                    ((i.primary.maritalStatus === 'M' || i.primary.maritalStatus === 'C') && !i.primary.spouseTaxes)) {
                    missingRequiredField = true;
                } else if (i.primary.maritalStatusChange === 'Y') {
                    if (!i.primary.previousMaritalStatus || !i.primary.maritalStatusChangeDate) {
                        missingRequiredField = true;
                    } else {
                        if (i.primary.spouseTaxes === 'N') {
                            page = '5a';
                        } else {
                            page = '5b';
                        }
                    }   
                } else {
                    // Check interview answers for how to proceed. S,M,C,D,SP,W
                    if ((i.primary.maritalStatus === 'S') || (i.primary.maritalStatus === 'D') || (i.primary.maritalStatus === 'SP') || (i.primary.maritalStatus === 'W')) {
                        if (i.primary.maritalStatusChange === 'Y') { page = '5a'; }
                        else if (i.primary.dependants === 'Y') { page = '8'; }
                        else { page = '9'; }
                    }
                    else if ((i.primary.maritalStatus === 'M') || (i.primary.maritalStatus === 'C')) {
                        if (!i.primary.spouseLiveApart) {
                            missingRequiredField = true;
                        } else {
                            if (i.primary.spouseTaxes === 'N') {
                                page = '5a';
                            } else {
                                page = '5b';
                            }
                        }                        
                    }
                }
                break;
            case '5a':
                if (!this.validRequiredField(i.spouse.firstName) || !this.validRequiredField(i.spouse.lastName) || !i.spouse.dob || !i.spouse.sin || !i.spouse.residenceProvince) {
                    missingRequiredField = true;
                } else {
                    if (i.primary.dependants === 'Y') { page = '8'; }
                    else { page = '9'; }
                }
                break;
            case '5b':
                if (!this.validRequiredField(i.spouse.firstName) || !this.validRequiredField(i.spouse.lastName) ||
                    !isValidSIN(i.spouse.sin) || !isValidBirthdate(i.spouse.dob, true, true).valid || !i.spouse.nameChange) {
                    missingRequiredField = true;
                } else {
                    page = '6';
                }
                break;
            case '6':
                if (i.spouse.mailingAddressSameAsPrimary === 'Y') {
                    if (!i.spouse.telephone || !i.spouse.mailingAddressChange) {
                        missingRequiredField = true;
                    } else {
                        page = '7';
                    }
                }
                else if (i.spouse.mailingAddressSameAsPrimary === 'N') {
                    if (!i.spouse.postalCode || !isValidPostalCode(i.spouse.postalCode) || !i.spouse.city || !this.validRequiredField(i.spouse.city) || !i.spouse.province || !i.spouse.telephone || !i.spouse.mailingAddressChange) {
                        missingRequiredField = true;
                    } else {
                        page = '7';
                    }
                } else {
                    missingRequiredField = true;
                }
                
                break;
            case '7':
                if (!this.state.interview.spouse.residenceProvince || (this.state.interview.spouse.residenceProvince === 'QC' && !i.spouse.gender) || !i.spouse.provinceTerritoryChange || !i.spouse.immigrateCanada || !i.spouse.canadianCitizenship || (!i.spouse.authorizeCraElectionsCanada && i.spouse.canadianCitizenship === 'Y')) {
                    missingRequiredField = true;
                    if (this.state.interview.spouse.residenceProvince === 'QC' && !i.spouse.gender) {
                        missingRequiredSexField = true;
                    }
                } else {
                    if (i.primary.dependants === 'Y') {
                        this.props.setDependantIndex(this.state.taxYear, 0);
                        page = '8';
                    } else { page = '9'; }
                }
                break;                
            case '8':
                // go to the next dependant if one exists
                if (this.state.dependantIndex < i.dependants.length - 1) {
                    this.props.setDependantIndex(this.state.taxYear, this.state.dependantIndex + 1)
                } else {
                    page = '9';
                }
                break;
            case '9':
                //heap tracking
                const primaryHeapEvents = [
                    { condition: this.props.taxScenarios_primary?.employedChecked, event: HeapEvents.TAX_SCENARIO_EMPLOYMENT_INCOME },
                    { condition: this.props.taxScenarios_primary?.selfEmployedChecked, event: HeapEvents.TAX_SCENARIO_SELF_EMPLOYED },
                    { condition: this.props.taxScenarios_primary?.retiredChecked, event: HeapEvents.TAX_SCENARIO_RETIREMENT },
                    { condition: this.props.taxScenarios_primary?.businessOwnerChecked, event: HeapEvents.TAX_SCENARIO_BUSINESS_OWNER },
                    { condition: this.props.taxScenarios_primary?.workExpensesChecked, event: HeapEvents.TAX_SCENARIO_WORK_EXPENSES },
                    { condition: this.props.taxScenarios_primary?.disabilityChecked, event: HeapEvents.TAX_SCENARIO_DISABILITY },
                    { condition: this.props.taxScenarios_primary?.medicalExpensesChecked, event: HeapEvents.TAX_SCENARIO_MEDICAL },
                    { condition: this.props.taxScenarios_primary?.benefitsEIChecked, event: HeapEvents.TAX_SCENARIO_BENEFITS_EI },
                    { condition: this.props.taxScenarios_primary?.investmentIncomeChecked, event: HeapEvents.TAX_SCENARIO_INVESTMENTS },
                    { condition: this.props.taxScenarios_primary?.rRSPChecked, event: HeapEvents.TAX_SCENARIO_RRSP },
                    { condition: this.props.taxScenarios_primary?.rentalChecked, event: HeapEvents.TAX_SCENARIO_RENTAL_INCOME },
                    { condition: this.props.taxScenarios_primary?.foreignIncomeChecked, event: HeapEvents.TAX_SCENARIO_FOREIGN },
                    { condition: this.props.taxScenarios_primary?.dependentsChecked, event: HeapEvents.TAX_SCENARIO_DEPENDANTS },
                    { condition: this.props.taxScenarios_primary?.propertySaleChecked, event: HeapEvents.TAX_SCENARIO_PROPERTY_SALE },
                    { condition: this.props.taxScenarios_primary?.spouseSupportChecked, event: HeapEvents.TAX_SCENARIO_SPOUSE_SUPPORT },
                    { condition: this.props.taxScenarios_primary?.movingExpensesChecked, event: HeapEvents.TAX_SCENARIO_MOVING },
                    { condition: this.props.taxScenarios_primary?.tuitionChecked, event: HeapEvents.TAX_SCENARIO_TUITION },
                    { condition: this.props.taxScenarios_primary?.donationsChecked, event: HeapEvents.TAX_SCENARIO_DONATION },
                    { condition: this.props.taxScenarios_primary?.fireRescueChecked, event: HeapEvents.TAX_SCENARIO_FIRE_RESCUE },
                    { condition: this.props.taxScenarios_primary?.farmingChecked, event: HeapEvents.TAX_SCENARIO_FARMING }
                ];

                primaryHeapEvents.forEach(({ condition, event }) => {
                    if (condition) {
                        trackEvent(event);
                    }
                });

                if ((i.primary.maritalStatus === 'M' || i.primary.maritalStatus === 'C') && i.primary.spouseTaxes === 'Y') { page = '10'; }
                else { if (!this.state.isFranchise && this.state.showPricingGlobal) { page = '10a' } else { page = '11'; } }
                break;
            case '10':
                //heap tracking
                const spouseHeapEvents = [
                    { condition: this.props.taxScenarios_spouse?.employedChecked, event: HeapEvents.TAX_SCENARIO_EMPLOYMENT_INCOME_SPOUSE },
                    { condition: this.props.taxScenarios_spouse?.selfEmployedChecked, event: HeapEvents.TAX_SCENARIO_SELF_EMPLOYED_SPOUSE },
                    { condition: this.props.taxScenarios_spouse?.retiredChecked, event: HeapEvents.TAX_SCENARIO_RETIREMENT_SPOUSE },
                    { condition: this.props.taxScenarios_spouse?.businessOwnerChecked, event: HeapEvents.TAX_SCENARIO_BUSINESS_OWNER_SPOUSE },
                    { condition: this.props.taxScenarios_spouse?.workExpensesChecked, event: HeapEvents.TAX_SCENARIO_WORK_EXPENSES_SPOUSE },
                    { condition: this.props.taxScenarios_spouse?.disabilityChecked, event: HeapEvents.TAX_SCENARIO_DISABILITY_SPOUSE },
                    { condition: this.props.taxScenarios_spouse?.medicalExpensesChecked, event: HeapEvents.TAX_SCENARIO_MEDICAL_SPOUSE },
                    { condition: this.props.taxScenarios_spouse?.benefitsEIChecked, event: HeapEvents.TAX_SCENARIO_BENEFITS_EI_SPOUSE },
                    { condition: this.props.taxScenarios_spouse?.investmentIncomeChecked, event: HeapEvents.TAX_SCENARIO_INVESTMENTS_SPOUSE },
                    { condition: this.props.taxScenarios_spouse?.rRSPChecked, event: HeapEvents.TAX_SCENARIO_RRSP_SPOUSE },
                    { condition: this.props.taxScenarios_spouse?.rentalChecked, event: HeapEvents.TAX_SCENARIO_RENTAL_INCOME_SPOUSE },
                    { condition: this.props.taxScenarios_spouse?.foreignIncomeChecked, event: HeapEvents.TAX_SCENARIO_FOREIGN_SPOUSE },
                    { condition: this.props.taxScenarios_spouse?.dependentsChecked, event: HeapEvents.TAX_SCENARIO_DEPENDANTS_SPOUSE },
                    { condition: this.props.taxScenarios_spouse?.propertySaleChecked, event: HeapEvents.TAX_SCENARIO_PROPERTY_SALE_SPOUSE },
                    { condition: this.props.taxScenarios_spouse?.spouseSupportChecked, event: HeapEvents.TAX_SCENARIO_SPOUSE_SUPPORT_SPOUSE },
                    { condition: this.props.taxScenarios_spouse?.movingExpensesChecked, event: HeapEvents.TAX_SCENARIO_MOVING_SPOUSE },
                    { condition: this.props.taxScenarios_spouse?.tuitionChecked, event: HeapEvents.TAX_SCENARIO_TUITION_SPOUSE },
                    { condition: this.props.taxScenarios_spouse?.donationsChecked, event: HeapEvents.TAX_SCENARIO_DONATION_SPOUSE },
                    { condition: this.props.taxScenarios_spouse?.fireRescueChecked, event: HeapEvents.TAX_SCENARIO_FIRE_RESCUE_SPOUSE },
                    { condition: this.props.taxScenarios_spouse?.farmingChecked, event: HeapEvents.TAX_SCENARIO_FARMING_SPOUSE }
                ];

                spouseHeapEvents.forEach(({ condition, event }) => {
                    if (condition) {
                        trackEvent(event);
                    }
                });

                if (!this.state.isFranchise && this.state.showPricingGlobal) { page = '10a' }
                else { page = '11'; }
                break;
            case '10a':
                trackEvent(HeapEvents.BTN_UTP_CONTINUE);
                page = '11';
                break
            case '11':
                isComplete = true;
                trackEvent(HeapEvents.INTERVIEW_DONE)
                break;
            default:
                isComplete = true;
        }

        if (page !== this.state.page) {
            // page change, scroll to top
            window.scrollTo(0, 0)
        }

        this.props.setMissingRequiredField(this.state.taxYear, missingRequiredField);
        this.props.setMissingRequiredSexField(this.state.taxYear, missingRequiredSexField);
        this.setInterviewPage(page, isComplete)
    }

    onAddDependantClick(e) {
        const dependant = {
            firstName: '',
            lastName: '',
            dob: '',
            gender: '',
            relationship: '',
            livesWith: '',
            infirm: '',
            hasIncome: ''
        }
        var newDeps = [...this.state.interview.dependants]
        newDeps.push(dependant);
        let interview = {
            ...this.state.interview,
            dependants: newDeps
        }

        this.props.setInterview(interview, this.state.taxYear, this.state.isLoaded);
        this.props.setDependantIndex(this.state.taxYear, this.state.dependantIndex + 1)
    }

    handleKeyDown(ev) {
        if (ev.keyCode !== 13) {
            this.onAddDependantClick(ev);                    
        }
    }

    onStoreLocatorClick(e) {
        window.open("https://www.hrblock.ca/office-locator", "_self");
    }

    onComplexChanged(complex) {
        let interview = {
            ...this.state.interview
        }

        var newComplex = Object.assign(interview.complex, complex);
        interview.complex = newComplex;

        this.props.setInterview(interview, this.state.taxYear, this.state.isLoaded);
    }

    onPrimaryChanged(primary) {
        let interview = {
            ...this.state.interview
        }

        var newPrimary = Object.assign(interview.primary, primary);
        interview.primary = newPrimary;
        this.props.setInterview(interview, this.state.taxYear, this.state.isLoaded);

        //if (this.state.missingRequiredField && this.state.page === '3' && newPrimary.residenceProvince) {
        //    this.props.setMissingRequiredField(this.state.taxYear, false);
        //    this.props.setMissingRequiredSexField(this.state.taxYear, false);
        //}
    }

    onSpouseChanged(spouse) {
        let interview = {
            ...this.state.interview,
        }

        var newSpouse = Object.assign(interview.spouse, spouse);
        interview.spouse = newSpouse;
        this.props.setInterview(interview, this.state.taxYear, this.state.isLoaded);
    }

    onNotesChanged(notes) {
        let interview = {
            ...this.state.interview
        }

        var newNotes = notes;
        interview.notes = newNotes;
        this.props.setInterview(interview, this.state.taxYear, this.state.isLoaded);
    }

    onTaxYearInfoChanged(taxYearInfo) {
        let interview = {
            ...this.state.interview
        }

        var newTaxYear = Object.assign(interview.taxYear, taxYearInfo);
        interview.taxYear = newTaxYear;
        this.props.setInterview(interview, this.state.taxYear, this.state.isLoaded);
    }

    onSpouseTaxYearInfoChanged(taxYearInfo) {
        let interview = {
            ...this.state.interview
        }

        var newTaxYear = Object.assign(interview.spouseTaxYear, taxYearInfo);
        interview.spouseTaxYear = newTaxYear;
        this.props.setInterview(interview, this.state.taxYear, this.state.isLoaded);
    }

    updateElementInArray(deps, dep, index) {
        if (index === 0) {
            return [
                dep,
                ...deps.slice(1)
            ]
        } else {
            return [
                ...deps.slice(0, index),
                dep,
                ...deps.slice(index + 1)
            ]
        }
    }

    makeDependantButton(isSmallLayout) {
        if (this.state.page === '8') {
            var buttonStyle = "defaultButtonStyle buttonStyle";

            return (isSmallLayout ?
                <div className="interviewBottomContainer" style={{ alignItems: 'stretch' }}>
                    <div tabIndex="0" className={buttonStyle} onClick={(ev) => this.onAddDependantClick(ev)} onKeyDown={(ev) => this.handleKeyDown(ev)} style={{ width: '400px', maxWidth: 'calc(100vw - 40px)' }} >
                        <div style={{ width: 20 }} />
                        <span className="unselectableLabel"><T.span text={{ key: 'Interview.Page8.addAnotherButton' }} /></span>
                        <div style={{ width: 20 }} />
                    </div>
                </div>
                :
                [<div key="btnSpacer" style={{ width: 150 }}></div>,
                <div tabIndex="0" key="btnDependant" className={buttonStyle} onClick={(ev) => this.onAddDependantClick(ev)} onKeyDown={(ev) => this.handleKeyDown(ev)} style={{ maxWidth: 306 }} >
                    <div style={{ width: 20 }} />
                    <span className="unselectableLabel"><T.span text={{ key: 'Interview.Page8.addAnotherButton' }} /></span>
                    <div style={{ width: 20 }} />
                </div>]
            )
        }

        return null
    }

    onDependantChanged(dependant) {
        var dependantIndex = this.state.dependantIndex

        var mutDeps = [...this.state.interview.dependants]
        var newDeps = this.updateElementInArray(mutDeps, dependant, dependantIndex)
        let interview = {
            ...this.state.interview,
            dependants: newDeps
        }

        this.props.setInterview(interview, this.state.taxYear, this.state.isLoaded);
    }

    // Checks and validations
    validRequiredField(fieldText) {
        if (!Boolean(fieldText)) { return false; }
        var valid = fieldText.match(/^[a-zA-Z\u00C0-\u017F\'\.\-\s]+$/)   // eslint-disable-line no-useless-escape
        return valid;
    }

    validPhone(data) {
        let digits = this.extractDigits(data)
        return Array.isArray(digits) && digits.length === 10
    }

    extractDigits(data) {
        if (!data) {
            return []
        }

        try {
            const matches = data.match(/\d/g)
            if (matches != null) {
                return matches.map(Number)
            }
        } catch (ex) {
            console.log("phoneTextInput.js/ExtractDigits/ data.match fail - " + ex.message);
        }

        return []
    }

    checkViewportWidth() {
        return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    }

    // Make me pretty!
    render() {

        if (!this.props.isLoaded) {
            return (
                <div className='interviewSpinnerStyle'>
                    <LoadingSpinner />
                </div>
            )
        }

        // Set up Hairy Styles:
        var isSmallLayout = this.state.layoutType === 'tablet' || this.state.layoutType === 'small_tablet' || this.state.layoutType === 'mobile';

        if (this.state.page === '9' || this.state.page === '10' || this.state.page === '10a') {
            isSmallLayout = this.state.layoutType === 'small_tablet' || this.state.layoutType === 'mobile';
        }
        var rootStyle = isSmallLayout ? "mobile interviewContainer" : this.state.layoutType === 'tablet' ? "tablet interviewContainer" : "interviewContainer";

        var bottomContainerWrapper = 'bottom-container-wrapper';
        if (this.state.page === '10a')
        {
            if (isSmallLayout) { bottomContainerWrapper = 'sticky-container-mobile'; }
            else if (this.state.layoutType === 'tablet') { bottomContainerWrapper = 'sticky-container-tablet' }
        }

        // Determine the page to be displayed
        let commonProps = {
            taxYear: this.props.taxYear,
            isReturningClient: determineIsReturningClient(this.props.case_state),
            layoutType: this.state.layoutType,
            lang: this.props.lang
        }

        var Page = pages['interviewPage' + this.state.page];
        var pageContent = null;
        var backText = '';
        if (this.state.page === '0') {
            pageContent = (<Page primary={this.state.interview.primary} onPrimaryChanged={(primary) => this.onPrimaryChanged(primary)}
                spouse={this.state.interview.spouse} onSpouseChanged={(spouse) => this.onSpouseChanged(spouse)}
                missingRequiredField={this.state.missingRequiredField} {...commonProps} />);
            backText = 'Interview.Page1.backLink';
        } else if (this.state.page === '1') {
            pageContent = (<Page primary={this.state.interview.primary} onPrimaryChanged={(primary) => this.onPrimaryChanged(primary)} missingRequiredField={this.state.missingRequiredField} {...commonProps} />);
            backText = 'Interview.Page1.backLink';
        } else if (this.state.page === '2' || this.state.page === '3' || this.state.page === '4') {
            pageContent = (<Page primary={this.state.interview.primary} mobilePhone={this.state.mobilePhone}
                onPrimaryChanged={(primary) => this.onPrimaryChanged(primary)}
                defaultPostalCode={this.props.defaultPostalCode}
                missingRequiredField={this.state.missingRequiredField}
                missingRequiredSexField={this.state.missingRequiredSexField} {...commonProps} />);
            backText = 'Interview.Page' + this.state.page + '.backLink';
        } else if (this.state.page === '5a' || this.state.page === '5b' || this.state.page === '7') {
            backText = 'Interview.Page' + this.state.page + '.backLink';
            pageContent = (<Page spouseTaxes={this.state.interview.primary.spouseTaxes} dependants={this.state.interview.primary.dependants} spouse={this.state.interview.spouse} onSpouseChanged={(spouse) => this.onSpouseChanged(spouse)} missingRequiredField={this.state.missingRequiredField} missingRequiredSexField={this.state.missingRequiredSexField} {...commonProps} />);
        } else if (this.state.page === '6') {
            backText = 'Interview.Page' + this.state.page + '.backLink';
            pageContent = (<Page spouseTaxes={this.state.interview.primary.spouseTaxes} dependants={this.state.interview.primary.dependants} spouse={this.state.interview.spouse} onSpouseChanged={(spouse) => this.onSpouseChanged(spouse)} missingRequiredField={this.state.missingRequiredField} missingRequiredSexField={this.state.missingRequiredSexField} primary={this.state.interview.primary} {...commonProps} />);
        } else if (this.state.page === '8') {
            backText = 'Interview.Page8.backLink1';
            if (this.state.interview.primary.spouseTaxes === 'Y') { backText = 'Interview.Page8.backLink2'; }
            if (this.state.interview.primary.spouseTaxes === 'N') { backText = 'Interview.Page8.backLink3'; }
            pageContent = (<Page spouseTaxes={this.state.interview.primary.spouseTaxes} dependant={this.state.interview.dependants[this.state.dependantIndex] ? this.state.interview.dependants[this.state.dependantIndex] : ['']} onDependantChanged={(dependant) => this.onDependantChanged(dependant)} missingRequiredField={this.state.missingRequiredField} {...commonProps} />);
        } else if (this.state.page === '9') {
            backText = 'Interview.Page9.backLink1';
            if (this.state.interview.primary.spouseTaxes === 'N' && this.state.interview.primary.dependants === 'Y') { backText = 'Interview.Page9.backLink2'; }
            if (this.state.interview.primary.spouseTaxes === 'Y' && this.state.interview.primary.dependants === 'N') { backText = 'Interview.Page9.backLink3'; }
            if (this.state.interview.primary.spouseTaxes === 'N' && this.state.interview.primary.dependants === 'N') { backText = 'Interview.Page9.backLink4'; }
            if (this.state.interview.primary.spouseTaxes === 'Y' && this.state.interview.primary.dependants === 'Y') { backText = 'Interview.Page9.backLink2'; }
            if (this.state.interview.primary.spouseTaxes === '' && this.state.interview.primary.dependants === 'Y') { backText = 'Interview.Page9.backLink2'; }
            pageContent = (<Page maritalStatus={this.state.interview.primary.maritalStatus} spouseTaxes={this.state.interview.primary.spouseTaxes} dependants={this.state.interview.primary.dependants} firstName={this.state.interview.primary.firstName} taxYearInfo={this.state.interview.taxYear} isCompany={!this.state.isFranchise} showPricingGlobal={this.state.showPricingGlobal} onTaxYearInfoChanged={(taxYearInfo) => this.onTaxYearInfoChanged(taxYearInfo)} {...commonProps} />);
        } else if (this.state.page === '10') {
            backText = 'Interview.Page10.backLink';
            pageContent = (<Page spouseTaxes={this.state.interview.primary.spouseTaxes} dependants={this.state.interview.primary.dependants} firstName={this.state.interview.spouse.firstName} taxYearInfo={this.state.interview.spouseTaxYear} isCompany={!this.state.isFranchise} showPricingGlobal={this.state.showPricingGlobal} onTaxYearInfoChanged={(taxYearInfo) => this.onSpouseTaxYearInfoChanged(taxYearInfo)} {...commonProps} />);
        } else if (this.state.page === '10a') {
            backText = 'Interview.Page10a.backLink1';
            if (this.state.interview.primary.spouseTaxes === 'Y') { backText = 'Interview.Page10a.backLink2' }
            pageContent = (<Page />)
        } else if (this.state.page === '11') {
            backText = 'Interview.Page11.backLink1';
            if (!this.state.isFranchise && this.state.showPricingGlobal) {
                backText = 'Interview.Page11.backLink3';
            }
            else if (this.state.interview.primary.spouseTaxes === 'Y') {
                backText = 'Interview.Page11.backLink2'
            }
            pageContent = (<Page saving={this.state.saving} notes={this.state.interview.notes} onNotesChanged={(notes) => this.onNotesChanged(notes)} {...commonProps} />);
        } else {
            pageContent = null
        }

        var additionalYear = this.props.case_state.taxYears
            .find(i => i.taxYear.toString() !== this.state.taxYear.toString() && !i.interview_state.complete);

        return (
            <div className={classnames(rootStyle, this.state.page === '10a' && this.state.layoutType === 'tablet' ? "interviewcontainer-tablet" : "")}>
                <div className="interview-back-link">
                    <Navigation onClick={(e) => this.onPreviousClick(e)} labelKey={backText} />
                </div>
                <div className={this.state.page === '10a' ? "interviewContainerStyleSecondary" : "interviewContainerStyle"}>
                    <div className={this.state.page === '10a' ? "interviewContentStyleSecondary" : "interviewContentStyle"}>
                        {pageContent}
                    </div>
                </div>
                {this.state.page === '10a' &&
                    <div className='interviewTestimonialContainer'>
                        <T.span text="Interview.Page10a.testimonial" />
                    </div>}
                {isSmallLayout && this.makeDependantButton(isSmallLayout)}
                <div className={this.state.page === '10a' ? this.state.layoutType === 'tablet' ? "interviewBottomContainerPriceEstimateTablet" : "interviewBottomContainerPriceEstimate" : "interviewBottomContainer"}>
                    <div className={bottomContainerWrapper}>
                        { this.state.page === '10a' ? <T.span text="Interview.Page10a.disclaimer" className="pricingtierdisclaimer" /> : <div /> }
                        {!isSmallLayout && this.makeDependantButton(isSmallLayout)}
                        {this.props.layoutType === 'mobile' &&
                            <NavButton
                                isEnabled={true}
                                isForward={(this.state.page === 11 || this.state.page === 0) && !additionalYear}
                                onClick={(ev) => this.onContinueClick(ev)}
                                labelKey={((this.state.page === '11' || this.state.page === '0') && !additionalYear) ? 'Common.Done' : this.state.page === '10a' ? 'Common.Continue_PayLater' : 'Common.Continue'}
                                inProgress={this.state.saving}
                                isPricingEstimatePage={this.state.page === '10a' ? true : false}
                                layoutType={this.state.layoutType}/>
                        }
                        {this.props.layoutType !== 'mobile' &&
                            <NavButton
                                isEnabled={true}
                                isForward={(this.state.page === 11 || this.state.page === 0) && !additionalYear}
                                onClick={(ev) => this.onContinueClick(ev)}
                                labelKey={(this.state.page === '11' || this.state.page === '0') ? additionalYear ? T.translate('Interview.Page11.ContinueTo', { TaxYear: additionalYear.taxYear }) : 'Common.Done' : this.state.page === '10a' ? 'Common.Continue_PayLater' : 'Common.Continue'}
                                inProgress={this.state.saving}
                                isPricingEstimatePage={this.state.page === '10a' ? true : false}
                                layoutType={this.state.layoutType}/>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    var page = ''
    var taxYear = ''
    if (ownProps && ownProps.match && ownProps.match && ownProps.match.params) {
        page = ownProps.match.params.page
        taxYear = ownProps.match.params.taxYear
    }
    page = page || '1'
    taxYear = taxYear || state.config.appConfig.currentTaxYear.toString()

    var taxYearModel = state.interview[taxYear]
    var interviewModel = taxYearModel ? interviewCloneInterview(taxYearModel.interview) : null

    return {
        layoutType: state.layout.layoutType,
        lang: state.layout.lang,
        firstName: state.accountData.firstName,
        lastName: state.accountData.lastName,
        interview: interviewModel,
        isLoaded: taxYearModel ? taxYearModel.isLoaded : false,
        missingRequiredField: taxYearModel ? taxYearModel.missingRequiredField : true,
        mobilePhone: state.accountData.mobilePhone,
        dependantIndex: taxYearModel ? taxYearModel.dependantIndex : 0,
        location: state.router.location,
        page,
        taxYear,
        case_state: state.workflow.case_state,
        defaultPostalCode: state.interview.defaultPostalCode,
        missingRequiredSexField: taxYearModel ? taxYearModel.missingRequiredSexField : true,
        isFranchise: state.workflow?.case_state?.taxYears?.find(x => x.taxYear === Number(taxYear))?.assigned_tax_pro?.IsFranchise,
        showPricingGlobal: state.config?.featureFlags?.show_pricing_global,
        taxScenarios_primary: state.interview && state.interview[taxYear]?.interview?.taxYear || {},
        taxScenarios_spouse: state.interview && state.interview[taxYear]?.interview?.spouseTaxYear || {}
    }
}

const mapDispatchToProps = (dispatch) => ({
    loadInterview: (taxYear) => dispatch(InterviewActions.loadInterview(taxYear)),
    setDefaultInterview: (taxYear, isLoaded) => dispatch(InterviewActions.setDefaultInterview(taxYear, isLoaded)),
    setInterview: (interview, taxYear, isLoaded) => dispatch(InterviewActions.setInterview(interview, taxYear, isLoaded)),
    setInterviewPage: (taxYear, page, isComplete, additionalYear) => dispatch(InterviewActions.setInterviewPage(taxYear, page, isComplete, additionalYear)),
    setDependantIndex: (taxYear, dependantIndex) => dispatch(InterviewActions.setDependantIndex(taxYear, dependantIndex)),
    setMissingRequiredField: (taxYear, missingRequiredField) => dispatch(InterviewActions.setMissingRequiredField(taxYear, missingRequiredField)),
    setMissingRequiredSexField: (taxYear, missingRequiredSexField) => dispatch(InterviewActions.setMissingRequiredSexField(taxYear, missingRequiredSexField)),
    setButtonState: (buttonName, buttonState) => dispatch(WorkflowActions.setButtonState(buttonName, buttonState)),
    replaceNavigation: (path) => dispatch(replace(path)),
    pushNav: (path) => dispatch(push(path))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Interview))