import React, { useEffect, useState, Fragment } from 'react'
import classnames from 'classnames'
import { number, bool } from 'prop-types'
import './dox-progress-bar.css'

const DoxProgressBar = props => {

    const { completed, total, percent, hasError, showPercentage } = props

    const [percentComplete, setPercentComplete] = useState(0)

    useEffect(() => {
        if (completed > 0 && total > 0) {
            setPercentComplete(((completed / total) * 100).toFixed())
        }
    }, [completed, total])

    useEffect(() => {
        if (percent >= 0) {
            if (percent > 100) {
                setPercentComplete(100)
            } else {
                setPercentComplete(percent)
            }
        }
    }, [percent])

    return (
        <Fragment>
            <div className='dox-progress-container'>
                <div className='dox-progress'>
                    <div className={classnames('bar', { 'error': hasError })} style={{ flexBasis: `${percentComplete}%` }} />
                </div>
            </div>
            {(showPercentage && percentComplete >= 0) && <span className='percent'>{`${parseFloat(percentComplete.toFixed(0))}%`}</span>}
        </Fragment>
    )
}

DoxProgressBar.propTypes = {
    completed: number,
    total: number,
    percent: number,
    hasError: bool,
    showPercentage: bool
}

export default DoxProgressBar