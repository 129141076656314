import apisauce from 'apisauce'
import { addErrorHandler, getApiBaseUrl } from './serviceUtils'

/**
 * API for managing workflow information
 * @param {String} baseURL
 */
const create = (baseURL = getApiBaseUrl()) => {  // this will change for prod deployment
    // Create and configure an apisauce-based api object.
    const api = apisauce.create({
        baseURL,
        headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Content-Type': 'application/json' },
        timeout: 120000 // longer timeout to accommodate potential long running calls to find an associate/assign a ticket.
    })

    addErrorHandler(api);

    // Wrap api's addMonitor to allow the calling code to attach additional monitors in the future.
    // Only in __DEV__ and only if we've attached Reactotron to console (it isn't during unit tests).
    //if (__DEV__ && console.tron) {
    //  api.addMonitor(console.tron.apisauce)
    //}

    const getAuthHeader = (accessToken) => {
        return {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        }
    }

    const getExistingPostalCode = (accessToken) => {
        var payload = ''
        return api.get('assign/postal', payload, getAuthHeader(accessToken))
    }

    const setPostalCode = (accessToken, postalCode) => {
        var payload = {
            postalCode
        }
        return api.post('assign/postal', payload, getAuthHeader(accessToken))
    }

    const assignTickets = (accessToken, associateId, officeId) => {
        var payload = {
            associateId,
            officeId
        }
        return api.post('assign', payload, getAuthHeader(accessToken))
    }

    // Return back a collection of functions
    return {
        getExistingPostalCode, setPostalCode, assignTickets
    }
}

// let's return back our create method as the default.
export default {
    create    
}

