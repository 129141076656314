import React, { useEffect } from 'react';
import OuterContainer from '../Controls/outerContainer';
import '../Register/style.css';
import LoadingSpinner from './loadingSpinner';

const LoadingPage = ({ messageKeys, loaded }) => {

    useEffect(() => {
        function onBeforeUnload() {
            // VAI-453 
            // hack to force the window to scroll to the top on unload
            //  so that the spinner is always in the right place.
            window.scrollTo(0, 0);
        }

        function forceTopScroll() {
            // VAI-453
            // for the love of God and all that is holy, make 
            // the browser stop scrolling the GD page back down.
            if ('scrollRestoration' in window.history)
                window.history.scrollRestoration = 'manual'
        }

        forceTopScroll()
        // hook up our hacky listener
        window.addEventListener('onbeforeunload', onBeforeUnload)
        // now, scroll it up to the top.
        setTimeout(window.scrollTo(0, 0), 50)

        return () => window.removeEventListener('onbeforeunload', onBeforeUnload)
    }, [])

    return (
        <OuterContainer sizeToContent={false} loaded={loaded}>
            <div className="registerRootStyle">
                <div className="registerContainerStyle">
                    <LoadingSpinner messageKeys={messageKeys} />
                </div>
            </div>
        </OuterContainer>
    );
}

export default LoadingPage