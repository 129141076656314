/* /* eslint-disable-next-line react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import 'react-contexify/dist/ReactContexify.css'
import '../dox_explorer.css'
import { Menu, Item, useContextMenu, animation } from 'react-contexify'
import { useTranslationContext } from '../../Shared/hooks/useTranslationContextHook'

const DoxExplorerSortMenu = (props) => {
    const translateString = useTranslationContext();

    const menuPosition = useRef();
    const triggerRef = useRef();

    const MENU_ID = 'doxe-mobile-sort-menu'
    const { show, hideAll } = useContextMenu({ id: MENU_ID })

    const [selectedSort, setSelectedSort] = useState(null)
    const [isOpen, setIsOpen] = useState(false)

    function getMenuPosition() {
        const { left, bottom } = triggerRef.current.getBoundingClientRect();
        menuPosition.current = { x: left, y: bottom - 1 };

        return menuPosition.current;
    }

    const handleContextMenu = (ev) => {
        ev.preventDefault()
        if (isOpen) {
            setIsOpen(false)
            return;
        }
        setIsOpen(true);
        show(ev, { position: getMenuPosition() })
    }

    const handleMenuHide = () => {
        setIsOpen(false)
    }

    const handleItemClick = (sortColumn) => {
        setSelectedSort(sortColumn)
        props.onSelectSort(sortColumn)
    }

    useEffect(() => {
        if (!isOpen) {
            hideAll();
        }
    }, [isOpen, hideAll])

    useEffect(() => {
        if (props.selectedSort !== selectedSort) {
            setSelectedSort(props.selectedSort)
        }
    }, [props.selectedSort])

    return (
        <Fragment>
            <div className='hrb-column hrb-is-3 doxe-centered' >
                <div className={classnames('doxe-dropdown', { 'is-open': isOpen })} ref={triggerRef} onClick={handleContextMenu}>
                    <div>{translateString('Dox.DocumentExplorer.OrderBy')}</div>
                    <div className='doxe-arrow' />
                </div>
            </div>
            <Menu id={MENU_ID} animation={animation.slide} theme='doxe' onHidden={handleMenuHide} className='doxe-dropdown-menu' >
                <Item onClick={() => handleItemClick('name')}>
                    <div className={classnames('doxe-menu-item', { 'doxe-menu-selected': 'name' === selectedSort })}>
                        {translateString('Dox.DocumentExplorer.Name')}
                    </div>
                </Item>
                <Item onClick={() => handleItemClick('size')}>
                    <div className={classnames('doxe-menu-item', { 'doxe-menu-selected': 'size' === selectedSort })}>
                        {translateString('Dox.DocumentExplorer.Size')}
                    </div>
                </Item>
                <Item onClick={() => handleItemClick('modified')}>
                    <div className={classnames('doxe-menu-item', { 'doxe-menu-selected': !selectedSort || 'modified' === selectedSort })}>
                        {translateString('Dox.DocumentExplorer.Modified')}
                    </div>
                </Item>
                <Item onClick={() => handleItemClick('uploader')}>
                    <div className={classnames('doxe-menu-item', { 'doxe-menu-selected': 'uploader' === selectedSort })}>
                        {translateString('Dox.DocumentExplorer.Uploader')}
                    </div>
                </Item>
            </Menu>
        </Fragment>
    )
}

DoxExplorerSortMenu.propTypes = {
    onSelectSort: PropTypes.func.isRequired
}

export default DoxExplorerSortMenu;