import React from 'react';
import { PropTypes } from 'prop-types'
import T from 'i18n-react';
import WorkflowSection from '../Section/WorkflowSection'
import '../Section/section.css'
import WorkflowButton from '../Section/WorkflowButton'
import { WorkflowButtonStates } from '../Section/WorkflowButtonStates'
import EditIcon from '@mui/icons-material/Edit'

const ProfileSection = ({ confirmAuthorizeState, onWorkflowButtonAction, onFadeOut, ticketType }) => {
    var { primaryShowConfirm, spouseShowConfirm, primaryShowAuthorize, primaryAuthorizeAddedQC, spouseShowAuthorize, spouseFirstName } = confirmAuthorizeState;
   
    var showConfirm = primaryShowConfirm || spouseShowConfirm;
    var confirmText = !primaryShowConfirm && spouseShowConfirm ? 'WorkflowPanel.confirm.spouse.title' : 'WorkflowPanel.confirm.title'
    var showAuthorize = primaryShowAuthorize || primaryAuthorizeAddedQC || spouseShowAuthorize;
    var authText = 'WorkflowPanel.authorize.title'
    if (!primaryShowAuthorize && primaryAuthorizeAddedQC && !spouseShowAuthorize) {
        authText = 'WorkflowPanel.authorize.addedqc.title'
    } else if (!primaryShowAuthorize && !primaryAuthorizeAddedQC && spouseShowAuthorize) {
        authText = 'WorkflowPanel.authorize.spouse.title'
    }
   
    return showConfirm || showAuthorize ? (
        <WorkflowSection titleKey='WorkflowPanel.securityTitle' taxYear={0} currentTaxYear={0} canClose={false} ticketType={ticketType}>
            {showConfirm &&
                <WorkflowButton buttonKey="confirm" name="confirm" buttonState={WorkflowButtonStates.SHOW} icon="fa-user-secret"
                text={confirmText} textProps={{ SpouseFirstName: spouseFirstName }} ticketType={ticketType}
                    onClick={() => onWorkflowButtonAction('confirm', 0)} onFadeOut={onFadeOut} />
            }
            {showAuthorize &&
                <WorkflowButton buttonKey="authorize" name="authorize" buttonState={WorkflowButtonStates.SHOW}
                text={authText} textProps={{ SpouseFirstName: spouseFirstName }} ticketType={ticketType}
                    onClick={() => onWorkflowButtonAction('authorize', 0)} onFadeOut={onFadeOut} >
                    <EditIcon className="large_mi_icon" />
                </WorkflowButton>
            }
            <div className="wf-section-label-container">
                {T.translate('WorkflowPanel.profile.subtext')}
            </div>
        </WorkflowSection>
    ) : null
}

ProfileSection.propTypes = {
    confirmAuthorizeState: PropTypes.object.isRequired,
    onWorkflowButtonAction: PropTypes.func.isRequired,
    onFadeOut: PropTypes.func.isRequired,
    ticketType: PropTypes.string
}

export default ProfileSection;
