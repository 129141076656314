import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    setLinkConfig: ['en', 'fr'],
    setCasConfig: ['CASConfig'],
    setAppConfig: ['appConfig'],
    setFeatureFlags: ['featureFlags']
})

export const ConfigTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    linkConfig: {
        en: {},
        fr: {}
    },
    CASConfig: {},
    appConfig: {},
    featureFlags: {}
}

/* ------------- Reducers ------------- */

export const linkSetLinkConfig = (state, { en, fr }) =>
    Object.assign({}, state, { linkConfig: { en: en, fr: fr }})

export const linkSetCASConfig = (state, { CASConfig }) =>
    Object.assign({}, state, { CASConfig: CASConfig })

export const linkSetAppConfig = (state, { appConfig }) =>
    Object.assign({}, state, { appConfig: appConfig })

export const linkSetFeatureFlags = (state, { featureFlags }) =>
    Object.assign({}, state, { featureFlags: featureFlags })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_LINK_CONFIG]: linkSetLinkConfig,
    [Types.SET_CAS_CONFIG]: linkSetCASConfig,
    [Types.SET_APP_CONFIG]: linkSetAppConfig,
    [Types.SET_FEATURE_FLAGS]: linkSetFeatureFlags
})