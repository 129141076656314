import React, { useEffect } from 'react';
import '../style.css';
import T from 'i18n-react';
import SimpleUTPIcon from '../../../images/SimpleUTPIcon.svg'
import StandardUTPIcon from '../../../images/StandardUTPIcon.svg'
import AdvancedUTPIcon from '../../../images/AdvancedUTPIcon.svg'
import PremierUTPIcon from '../../../images/PremierUTPIcon.svg'
import PriceTierAccordion from './controls/PriceTierAccordion'
import { HeapEvents, trackEvent } from '../../../utils/AnalyticsUtil';

const InterviewPage10a = ({ }) => {

    useEffect(()=>{
        trackEvent(HeapEvents.VIEW_PAGE_UTP)
    }, [])

    return (
        <div>
                <T.text tag='h1' text="Interview.Page10a.headerTitle"/>
            
            <div className='price-top-margin'>
                <T.span text="Interview.Page10a.headerContent" />
            </div>
            <div>
                <ul>
                    <li><T.span text="Interview.Page10a.bulletItem1" /></li>
                    <li><T.span text="Interview.Page10a.bulletItem2" /></li>
                    <li><T.span text="Interview.Page10a.bulletItem3" /></li>
                </ul>
            </div>
            <div className='pricing-tier-section'>
                <div className='pricing-tier pricing-tier-border'>
                    <div><img src={SimpleUTPIcon} className='pricing-tier-icon' /></div>
                    <div><T.span text="Interview.Page10a.SimpleTier" className="tier-name" /></div>
                    <div className='price-top-margin'><T.span text="Interview.Page10a.SimplePrice" className="price" /></div>
                    <div className='price-bottom-margin'><T.span text="Interview.Page10a.PlusTax" className="plus-tax" /></div>
                    <div className='price-right-margin'><T.span text="Interview.Page10a.PriceTierDescSimple" className="tier-desc" /></div>
                    <PriceTierAccordion PricingDetails={["Interview.Page10a.SimpleTierItem1", "Interview.Page10a.SimpleTierItem2"]} />
                </div>
                <div className='pricing-tier pricing-tier-border'>
                    <div><img src={StandardUTPIcon} className='pricing-tier-icon' /></div>
                    <div><T.span text="Interview.Page10a.StandardTier" className="tier-name" /></div>
                    <div className='price-top-margin'><T.span text="Interview.Page10a.StandardPrice" className="price" /></div>
                    <div className='price-bottom-margin'><T.span text="Interview.Page10a.PlusTax" className="plus-tax" /></div>
                    <div className='price-right-margin'><T.span text="Interview.Page10a.PriceTierDescStandard" className="tier-desc" /></div>
                    <PriceTierAccordion PricingDetails={["Interview.Page10a.StandardTierItem1", "Interview.Page10a.StandardTierItem2", "Interview.Page10a.StandardTierItem3", "Interview.Page10a.StandardTierItem4", "Interview.Page10a.StandardTierItem5", "Interview.Page10a.StandardTierItem6", "Interview.Page10a.StandardTierItem7", "Interview.Page10a.StandardTierItem8", "Interview.Page10a.StandardTierItem9"]} />
                </div>
                <div className='pricing-tier pricing-tier-border'>
                    <div><img src={AdvancedUTPIcon} className='pricing-tier-icon' /></div>
                    <div><T.span text="Interview.Page10a.AdvancedTier" className="tier-name" /></div>
                    <div className='price-top-margin'><T.span text="Interview.Page10a.AdvancedPrice" className="price" /></div>
                    <div className='price-bottom-margin'><T.span text="Interview.Page10a.PlusTax" className="plus-tax" /></div>
                    <div className='price-right-margin'><T.span text="Interview.Page10a.PriceTierDescAdvanced" className="tier-desc" /></div>
                    <PriceTierAccordion PricingDetails={["Interview.Page10a.AdvancedTierItem1", "Interview.Page10a.AdvancedTierItem2", "Interview.Page10a.AdvancedTierItem3", "Interview.Page10a.AdvancedTierItem4", "Interview.Page10a.AdvancedTierItem5", "Interview.Page10a.AdvancedTierItem6", "Interview.Page10a.AdvancedTierItem7", "Interview.Page10a.AdvancedTierItem8", "Interview.Page10a.AdvancedTierItem9", "Interview.Page10a.AdvancedTierItem10", "Interview.Page10a.AdvancedTierItem11", "Interview.Page10a.AdvancedTierItem12", "Interview.Page10a.AdvancedTierItem13"]} />
                </div>
                <div className='pricing-tier'>
                    <div><img src={PremierUTPIcon} className='pricing-tier-icon' /></div>
                    <div><T.span text="Interview.Page10a.PremierTier" className="tier-name" /></div>
                    <div className='price-top-margin'><T.span text="Interview.Page10a.StartingAt" className="starting-at" /></div>
                    <div><T.span text="Interview.Page10a.PremierPrice" className="price" /></div>
                    <div className='price-bottom-margin'><T.span text="Interview.Page10a.PlusTax" className="plus-tax" /></div>
                    <div className='price-right-margin'><T.span text="Interview.Page10a.PriceTierDescPremier" className="tier-desc" /></div>
                    <PriceTierAccordion PricingDetails={["Interview.Page10a.PremierTierItem1", "Interview.Page10a.PremierTierItem2", "Interview.Page10a.PremierTierItem3", "Interview.Page10a.PremierTierItem4", "Interview.Page10a.PremierTierItem5", "Interview.Page10a.PremierTierItem6", "Interview.Page10a.PremierTierItem7", "Interview.Page10a.PremierTierItem8", "Interview.Page10a.PremierTierItem9", "Interview.Page10a.PremierTierItem10", "Interview.Page10a.PremierTierItem11", "Interview.Page10a.PremierTierItem12", "Interview.Page10a.PremierTierItem13", "Interview.Page10a.PremierTierItem14", "Interview.Page10a.PremierTierItem15", "Interview.Page10a.PremierTierItem16"]} />
                </div>
            </div>
        </div>
    );
};

export default InterviewPage10a;