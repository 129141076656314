import React, { Component } from 'react';
import '../../Common/shared.css'
import '../style.css';

import T from 'i18n-react';
import PersonalInfoForm from '../../Common/personalInfoForm'
import YesNoQuestion from '../../Common/yesNoQuestion'
import HighlightedSection from '../../Interview/highlightedSection'
import InterviewTitleProgress from '../../Common/interviewTitleProgress'

class InterviewPage8 extends Component
{
    constructor(props)
    {
        super(props)

        var dependant = this.props.dependant || {
            firstName: '',
            lastName: '',
            dob: '',
            gender: '',
            relationship: '',
            livesWith: '',
            infirm: '',
            hasIncome: ''
        }

        this.state = {
            dependant,
            missingRequiredField: this.props.missingRequiredField,
            layoutType: this.props.layoutType,
            lang: this.props.lang
        }
    }

    componentWillReceiveProps(newProps)
    {
        if (this.state.missingRequiredField !== newProps.missingRequiredField)
        {
            this.setState({ missingRequiredField: newProps.missingRequiredField })
        }
        if (this.state.dependant !== newProps.dependant)
        {
            this.setState({ dependant: newProps.dependant })
        }
        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }
    }

    onPersonalInfoChanged(personalInfo)
    {
        var dependant = {
            ...this.state.dependant,
            firstName: personalInfo.firstName,
            lastName: personalInfo.lastName,
            gender: personalInfo.gender,
            dob: personalInfo.dob
        }
        this.setState({ dependant })
        if (this.props.onDependantChanged)
        {
            this.props.onDependantChanged(dependant)
        }
    }

    onDependantPropertyChanged(ev, value)
    {
        var dependant = {
            ...this.state.dependant
        }

        dependant[ev.target.name] = value
        this.setState({ dependant })
        if (this.props.onDependantChanged)
        {
            this.props.onDependantChanged(dependant)
        }
    }

    onYesNoChange(ev, value)
    {
        var dependant = {
            ...this.state.dependant
        }
        dependant[ev.target.name] = value
        this.setState({ dependant })
        if (this.props.onDependantChanged)
        {
            this.props.onDependantChanged(dependant)
        }
    }

    render()
    {
        var percentage = '66'
        if (this.props.spouseTaxes === 'N') {
            percentage = '72'
        }
        if (this.props.spouseTaxes === 'Y') {
            percentage = '78'
        }

        return (
            <div style={{ marginBottom: 0 }}>
                <div>
                    <div>
                        <InterviewTitleProgress taxYear={this.props.taxYear} percentage={percentage} title="Interview.Page8.headerTitle" nextTitle="Interview.Page8.nextTitle" />
                    </div>                      
                    <T.p text="Interview.Page8.headerContent0" className="headerText" />                    
                    <p>
                        <T.span text="Interview.Page8.headerContent1" className="headerText" />
                    </p>
                </div>
                <HighlightedSection {...this.props} margin={20}>
                    <div margin={20}>
                    <PersonalInfoForm missingRequiredField={this.state.missingRequiredField} page={'page8'}
                        lang={this.state.lang}
                        dependantVersion labelKey="Interview.Page1" personalInfo={this.state.dependant}
                        onPersonalInfoChanged={(personalInfo) => this.onPersonalInfoChanged(personalInfo)}
                        onDependantPropertyChanged={(ev, value) => { this.onDependantPropertyChanged(ev, value) }} />
                    <div className="horizontalFieldContainerTopAligned largeTopMargin">
                        <YesNoQuestion name="livesWith" value={this.state.dependant.livesWith}
                            layoutType={this.state.layoutType} learnMoreId="OTE_FAQ/OTEDoesThisDependantLiveWithYou"
                            labelText={T.translate("Interview.Page8.fieldLabel1")}
                            onAnswerChanged={(ev, value) => { this.onYesNoChange(ev, value) }} />
                    </div>
                    <div className="horizontalFieldContainerTopAligned largeTopMargin">
                        <YesNoQuestion name="infirm" value={this.state.dependant.infirm}
                            layoutType={this.state.layoutType} learnMoreId="Parent_Dependants/WasThisDependantInfirm"
                            labelText={T.translate("Interview.Page8.fieldLabel2", { TaxYear: this.props.taxYear })}
                            onAnswerChanged={(ev, value) => { this.onYesNoChange(ev, value) }} />
                    </div>
                    <div className="horizontalFieldContainerTopAligned largeTopMargin">
                        <YesNoQuestion name="hasIncome" value={this.state.dependant.hasIncome}
                            layoutType={this.state.layoutType} learnMoreId="OTE_FAQ/OTEDidThisDependantHaveAnyIncome"
                            labelText={T.translate("Interview.Page8.fieldLabel3", { TaxYear: this.props.taxYear })}
                            onAnswerChanged={(ev, value) => { this.onYesNoChange(ev, value) }} />
                        </div>
                    </div>
                </HighlightedSection>
            </div>
        )
    }
}

export default InterviewPage8;