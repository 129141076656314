import React, { useState, useEffect } from 'react';
import classnames from 'classnames'
import './rsi.css'
import step1Img from '../../../images/account_step_1.png'
import step2Img from '../../../images/account_step_2.png'
import step3Img from '../../../images/account_step_3.png'
import HenryLogo from '../../Common/henryLogo'

const RegisterSideImage = ({ imageType }) => {
    const [image, setImage] = useState(null)

    useEffect(() => {
        var img = imageType === 'step3' ? step3Img : imageType === 'step2' ? step2Img : step1Img
        img = img.startsWith('.') ? img.substring(1) : img
        if (image !== img) {
            setImage(img)
        }
    }, [setImage, imageType, image])

    return (
        <div className={classnames({ 'rsi-outer': imageType !== 'step3', 'rsi-outer-alt': imageType === 'step3' })}
            style={{ backgroundImage: `url(${image})` }}>
            {imageType === 'step3' &&
                <div className='rsi-overlay'>
                    <div className="register-logo-holder">
                        <HenryLogo width="270px" />
                    </div>
                </div>
            }
        </div>
    )
}

export default RegisterSideImage
