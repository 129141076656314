import { select, put, call } from 'redux-saga/effects'
import DocumentsActions from '../reducers/documentsRedux'
import FilesActions from '../reducers/filesRedux'
import WorkflowActions from '../reducers/workflowRedux'
import { trackEvent } from '../utils/AnalyticsUtil'

export const getAccessToken = state => state.auth.accessToken
export const getFileToBeRenamed = state => state.documents.fileToBeRenamed
export const getUploaderState = state => state.documents.uploaderState


export function* setCompletedFileViewed(api, action) {
    var accessToken = yield select(getAccessToken);
    var response = yield call(api.setFileViewed, accessToken, action.fileId, action.taxYear);
    if (response && response.ok) {
        response = yield call(api.getCompletedFileInfo, accessToken, action.taxYear);
        if (response && response.ok) {
            yield put(FilesActions.setCompletedDocuments(action.taxYear, response.data))
        }
    } else {
        if (response.status === 404) {
            // TODO?!
        }
    }
}

export function* sendOneTimeSMS(api, action) {
    var accessToken = yield select(getAccessToken)
    var response = yield call(api.sendOneTimeSMS, accessToken, action.route || '/dashboard/documents', action.boxFolderId)

    var successResponse = action.uploaderState === 'sms_sending' ? 'sms_sent' : 'sms_resent'
    yield put(DocumentsActions.setUploaderState(true, response && response.ok ? successResponse : 'sms_failed'))
}

export function* renameIOSFile(api, action) {
    var accessToken = yield select(getAccessToken)
    var fileToBeRenamed = yield select(getFileToBeRenamed)

    if (accessToken && fileToBeRenamed) {
        yield call(api.renameIOSFile, accessToken, fileToBeRenamed)
    }
}

export function* loadCompletedDocuments(api, action) {
    var accessToken = yield select(getAccessToken)
    var response = yield call(api.getCompletedFileInfo, accessToken, action.taxYear);
    if (response && response.ok) {
        yield put(FilesActions.setCompletedDocuments(action.taxYear, response.data))
    }
}

export function* setFileUploaded(api, action) {
    var accessToken = yield select(getAccessToken)

    var response = yield call(api.setFileWasUploaded, accessToken, action.taxYear);
    if (response && response.ok) {
        yield put(WorkflowActions.updateCaseState())
    }
}

export function* setUploadStepCompleted(api, action) {
    var accessToken = yield select(getAccessToken)
    var response = yield call(api.uploadstepcompleted, accessToken, action.taxYear, action.isComplete);
    if (response && response.ok) {
        trackEvent('OteUploadComplete', { taxYear: action.taxYear })
        yield put(WorkflowActions.updateCaseState())
    }
}

export function* loadDocumentsForReview(api, action) {
    var accessToken = yield select(getAccessToken)
    var response = yield call(api.getDocumentsForReview, accessToken, action.taxYear);
    if (response && response.ok) {
        yield put(FilesActions.setDocumentsForReview(action.taxYear, response.data))
    }
}

export function* setDocumentForReviewViewed(api, action) {
    var accessToken = yield select(getAccessToken);
    var response = yield call(api.setFileViewed, accessToken, action.fileId, action.taxYear);
    if (response && response.ok) {
        response = yield call(api.getDocumentsForReview, accessToken, action.taxYear);
        if (response && response.ok) {
            yield put(FilesActions.setDocumentsForReview(action.taxYear, response.data))
        }
    } else {
        if (response.status === 404) {
            // TODO?!
        }
    }
}

export function* loadApprovalDocuments(api, action) {
    var accessToken = yield select(getAccessToken)
    var response = yield call(api.getApprovalDocuments, accessToken, action.taxYear);
    if (response && response.ok) {
        yield put(FilesActions.setApprovalDocuments(action.taxYear, response.data))
    }
}

export function* sendReviewCompleteMessage(api, action) {
    var accessToken = yield select(getAccessToken)
    yield call(api.sendReviewCompleteMessage, accessToken, action);    
}

export function* sendReviewCompleteMessageWetSignature(api, action) {
    var accessToken = yield select(getAccessToken)
    yield call(api.sendReviewCompleteMessageWetSignature, accessToken, action);
}

export function* getSuggestions(api, action) {
    var accessToken = yield select(getAccessToken)
    var response = yield call(api.getSuggestions, accessToken, action.taxYear);
    if (response && response.ok) {
        yield put(DocumentsActions.setSuggestions(response.data.suggestedDocs));
    }
}