import { replace } from 'connected-react-router'
import { call, put, select } from 'redux-saga/effects'
import { saveAccountInfoLight } from '../components/Settings/settingsApis'
import AccountDataActions from '../reducers/accountDataRedux'
import ApproveDraftsActions from '../reducers/approveDraftsRedux'
import AuthorizeCRAActions from '../reducers/authorizeCRARedux'
import AuthActions from '../reducers/authRedux'
import ConfirmIdentityActions from '../reducers/confirmIdentityRedux'
import DocumentsActions from '../reducers/documentsRedux'
import FilesActions from '../reducers/filesRedux'
import InterviewActions from '../reducers/interviewRedux'
import LayoutActions from '../reducers/layoutRedux'
import LoginActions from '../reducers/loginRedux'
import MessagesActions from '../reducers/messagesRedux'
import PaymentActions from '../reducers/paymentRedux'
import RegisterActions from '../reducers/registerRedux'
import SigningActions from '../reducers/signingRedux'
import storeStorage from '../reducers/storeStorage'
import SurveyActions from '../reducers/surveyRedux'
import WorkflowNewReturnActions from '../reducers/workflowNewReturnRedux'
import WorkflowActions from '../reducers/workflowRedux'
import ProductActions from '../reducers/productsRedux'
import { identifyUser } from '../utils/AnalyticsUtil'
import { parseAccountData } from './registerSagas'
import auth from './SSO/Auth'

export const getAccessToken = state => state.auth.accessToken
export const getAuth = state => state.auth
export const getPreLogoutActions = state => state.auth.preLogoutActions
export const getCASConfig = state => state.config.CASConfig
export const getLanguage = state => state.layout.lang
export const getRegisterStep = state => state.register.step
export const getAccountData = state => state.accountData
export const getRouterPath = state => state.router.location.pathname
export const getLogin = state => state.login

// Login
export function* requestLogin(action) {
    yield call(configureCASForLogin)
    var lang = yield select(getLanguage)

    if (action.redirect_url) {
        yield call(auth.loginWithReturnUrl, action.redirect_url, lang)
    } else {
        yield call(auth.login, lang)
    }
}

export function* handleLoginCallback(checkRegistrationStep) {
    try {
        yield call(configureCASForLogin)

        console.debug('handling authentication')
        var response = yield call(auth.handleAuthentication)

        var isAuthenticated = auth.isAuthenticated()
        if (isAuthenticated) {
            console.debug('authenticated, continuing...')
            if (response) {
                yield put(AccountDataActions.setAccountId(response.userAccountId))
                yield put(AccountDataActions.setFirstName(response.first_name))
                if (response.last_name) {
                    yield put(AccountDataActions.setLastName(response.last_name))
                }
                yield put(AccountDataActions.setEmail(response.email))
            }

            var idToken = sessionStorage.getItem("id_token")
            var expires_at = sessionStorage.getItem("expires_at")

            if (idToken && expires_at) {
                yield put(AuthActions.setAccessToken(idToken, new Date(expires_at)))
                if (response.userRole) {
                    yield put(AuthActions.setUserRoles(response.userRole))
                }

                // if the user is still in the registration process, handle it here
                if (checkRegistrationStep) {
                    var step = yield select(getRegisterStep)
                    if (step === 'creating_account' || step === 'create_account_with_id') {
                        yield put(RegisterActions.setStepWithMessage('create_conversation', 'AccountSetupProgress.statusMessages.CreateConversation'))
                        yield put(replace('/register'))
                    } else {
                        yield put(AuthActions.finalizeLogin())
                    }
                    yield put(AuthActions.setLoginCallbackStatus(null))
                    return
                }
            }
        } else {
            // not authenticated, show the home page 
            yield put(replace('/home'))
            yield put(AuthActions.setLoginCallbackStatus('failed'))
        }
    } catch (ex) {
        yield call(handleLoginError, ex)
    }
}

export function* finalizeLogin(accountApi, workflowApi) {
    try {
        yield put(LayoutActions.setDashboardTab('dashboard'))

        var accessToken = yield select(getAccessToken)

        var response = yield call(accountApi.finalizeSignIn, accessToken)

        if (response && response.ok) {
            identifyUser(response.data.account_id)
            
            // Check to see if this user needs to register before continuing
            if (response.data.account_fully_created === false) {
                // send the user to a new registration step, DON'T set the old personal data as
                // we want them to update it
                yield put(AccountDataActions.setEmail(response.data.email))
                yield put(AccountDataActions.setAccountId(response.data.account_id))

                var step = yield select(getRegisterStep)

                // If user has an existing OIS account, creates va account
                if (step === 'user_exists') {
                    var accountData = yield select(getAccountData)
                    var lang = yield select(getLanguage)
                    yield call(saveAccountInfoLight, accessToken, response.data.account_id, accountData, lang)
                    yield put(RegisterActions.setStepWithMessage('create_conversation', 'AccountSetupProgress.statusMessages.CreateConversation'))
                    yield put(replace('/register/working'))
                } else {
                    yield put(RegisterActions.setImageIndex(3))
                    yield put(RegisterActions.setStep('login_account_not_created'))
                    yield put(LoginActions.loginReset())
                    yield put(replace('/register/returning'))
                }
                return
            }

            // Set language prefs from data returned from server.
            if (response.data.lang_pref) {
                yield put(LayoutActions.setDictLanguage(response.data.lang_pref.substr(0, 2)))
            }

            // now parse the login info
            var data = parseAccountData(response.data)
            if (data) {
                yield put(AccountDataActions.setAccountId(data.accountId))
                yield put(AccountDataActions.setFirstName(data.firstName))
                if (data.lastName) {
                    yield put(AccountDataActions.setLastName(data.lastName))
                }
                if (data.mobilePhone) {
                    yield put(AccountDataActions.setMobilePhone(data.mobilePhone))
                }
                yield put(AccountDataActions.setEmail(data.email))

                // reset login form fields
                yield put(LoginActions.loginReset())
            } else {
                // that's not awesome
                yield put(LoginActions.setErrorMessages('Common.Errors.FailedLogin', ''))
            }
        } else {
            if (response.status === 401) {
                // TODO: We couldn't verify the token, it's gone very poorly for us.  :(         
                throw new Error('received a 401 from the service while trying to verify user token')
            }
        }
    } catch (ex) {
        yield call(handleLoginError, ex)
    } finally {
        yield put(AuthActions.setLoginComplete(true))
    }
}

// Silent token refresh
export function* refreshAccessToken(api, action) {
    var accessToken = yield select(getAccessToken)
    if (accessToken) {
        var response = yield call(api.refreshAccessToken, accessToken)
        console.debug('refresh token called')
        console.debug(response)
        if (response && response.ok) {
            var t = new Date()
            t.setSeconds(t.getSeconds() + response.data.accessTokenExpirySeconds)
            yield put(AuthActions.setAccessToken(response.data.access_token, t))
        }
    }
}

export function* handleRefreshLoginCallback(action) {
    yield call(configureCASForLogin)

    var response = yield call(auth.handleAuthentication)
    if (auth.isAuthenticated()) {
        if (response) {
            yield put(AccountDataActions.setAccountId(response.userAccountId))
            yield put(AccountDataActions.setFirstName(response.ua_first_name))
            yield put(AccountDataActions.setEmail(response.email))
        }

        var idToken = sessionStorage.getItem("id_token")
        var expires_at = sessionStorage.getItem('expires_at')
        if (idToken && expires_at) {
            yield put(AuthActions.setAccessToken(idToken, new Date(Number(expires_at))))
            if (response.userRole) {
                yield put(AuthActions.setUserRoles(response.userRole))
            }
        }
    }
}

// One Time Code
export function* handleOTCRedemption(api, accountApi) {
    // find our code
    var querystring = window.location.search.substring(1);
    if (querystring.indexOf('code') !== -1) {
        var code = getQueryStringParameter('code', querystring);
        if (code) {
            var response = yield call(api.redeemOneTimeCode, code)
            if (response) {
                if (response.ok) {
                    if (auth.isAuthenticated()) {
                        if (response.data.TargetRoute) {
                            yield put(replace(response.data.TargetRoute))
                        } else {
                            yield put(replace('/dashboard'))
                        }
                    } else {
                        try {
                            yield call(configureCASForLogin)
                            yield call(auth.otcAuthentication, response.data.OTC, response.data.Email)
                        } catch (e) {
                            console.debug('Failed to authenticate with one-time code. error:')
                            console.debug(e)
                            yield put(replace('/otc_fail'))
                        }
                        if (auth.isAuthenticated()) {
                            var idToken = sessionStorage.getItem("id_token")
                            var expires_at = sessionStorage.getItem("expires_at")
                            yield put(AuthActions.setAccessToken(idToken, new Date(expires_at)))

                            if (response.data.TargetRoute === '/dashboard/documents') {
                                yield put(AuthActions.setOneTimeRedemptionStatus(true))
                            } else {
                                yield put(AuthActions.setOneTimeTargetRoute(response.data.TargetRoute))
                            }

                            yield call(finalizeLogin, accountApi, {})
                        } else {
                            yield put(replace('/otc_fail'))
                        }
                    }
                } else if (response.status === 400) {
                    // that code has already been used
                    yield put(replace('/otc_fail'))
                }
            }
        }
    }
}

export function* finalizeOTCRedemption(action) {
    if (action.complete) {
        var auth = yield select(getAuth)

        if (auth.oneTimeCodeRedemptionStatus === true) {
            yield put(replace('/dashboard'))
            yield put(AuthActions.setOneTimeRedemptionStatus(null))
        } else if (auth.oneTimeTargetRoute) {
            yield put(replace(auth.oneTimeTargetRoute))
            yield put(AuthActions.setOneTimeTargetRoute(null))
        }
    }
}

// Logout
export function* initPreLogoutAction(name) {
    // VAI-483 wrapper for waitable pre-logout actions
    // for pre-logout we need to prime the list of actions before 
    // starting them, most run synchronously and 
    // will trigger the completed action several times otherwise
    yield put(AuthActions.startPreLogoutAction(name))
}

export function* startPreLogoutAction(action, name, api) {
    yield call(action, api)
    yield put(AuthActions.donePreLogoutAction(name))
}

export function* checkPreLogoutComplete() {
    // VAI-483 check for all registered pre-logout actions
    var actions = yield select(getPreLogoutActions)
    var is_complete = true;

    for (var index in actions) {
        if (!actions.hasOwnProperty(index)) { continue; }
        is_complete = is_complete && (actions[index] === 'completed')
    }

    // set pre-logout complete
    if (is_complete) yield put(AuthActions.finalizeLogout(true))
}

export function* cleanupSessionState() {
    yield put(LoginActions.loginReset())
    yield put(AccountDataActions.accountDataReset())
    yield put(InterviewActions.resetInterview())
    yield put(WorkflowActions.resetWorkflow())
    yield put(MessagesActions.resetMessages())
    yield put(PaymentActions.clearPaymentInfo())
    yield put(ConfirmIdentityActions.confirmIdentityReset())
    yield put(DocumentsActions.reset())
    yield put(SurveyActions.reset())
    yield put(AuthorizeCRAActions.authorizeLogoutReset())
    yield put(FilesActions.filesReset())
    yield put(ApproveDraftsActions.approveReset())
    yield put(SigningActions.signingStateLogoutReset())
    yield put(WorkflowNewReturnActions.resetNewReturnState())
    yield put(ProductActions.clearAddOnProducts())
}

export function* finalizeLogout() {
    yield put(AuthActions.authReset())
    if (localStorage.getItem('va_heap_app_id') && window.heap) {
        window.heap.resetIdentity()
    }
    localStorage.removeItem('va_direct_code');

    var persistor = storeStorage.getPersistor();
    yield call(persistor.flush);
    auth.logout()

}

export function* navigateToDashboard(action) {

    // this is called when login is set as completed
    if (action.complete) {

        // this will be set at the time of login
        // (if redirecting from a page within RTE)
        var redirectRoute = localStorage.getItem("va_login_redirect")

        if (redirectRoute) {
            console.log('redirect route was: ' + redirectRoute)
            // if something was found in storage, redirect there
            localStorage.removeItem("va_login_redirect")
            yield put(replace(redirectRoute))
        } else {
            // otherwise, head on over to the dashboard
            var path = yield select(getRouterPath)

            // if the path is '/' redirect the user to dashboard
            if (['/', '/home', '/callback', '/register', '/register/working'].includes(path)) {
                console.log(`Path is ${path}, redirecting to dashboard`);
                yield put(replace('/dashboard'))
            } else {
                console.log(`Path is ${path}, navigating...`);
                yield put(replace(path));
            }
        }
    }
}

// helpers
function getQueryStringParameter(parameter, querystring) {
    if (querystring === '') { return ''; }
    else {
        var querystringValues = querystring.split('&');
        for (var i = 0; i < querystringValues.length; i++) {
            if (querystringValues[i].split('=')[0] === parameter) {
                return querystringValues[i].split('=')[1]
            }
        }
        return ''
    }
}

function* configureCASForLogin() {
    var CASConfig = yield select(getCASConfig)
    auth.initAuthProvider(CASConfig)
}

function* handleLoginError(ex) {
    console.error(ex)
    // in the event of a failure, logout the user so they can try again,
    // otherwise, they will be stuck on the login callback page indefinitely.
    yield put(AuthActions.setLoginCallbackStatus('failed'))
    auth.logout()
}

