/* eslint-disable-next-line */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AuthActions from '../../reducers/authRedux'
import LoadingPage from '../Loading/loadingPage'

const RefreshLoginCallback = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(AuthActions.handleRefreshTokenCallback())
    }, [])

    return (<LoadingPage />)
}

export default RefreshLoginCallback