import React from 'react';
import PropTypes from 'prop-types'
import './highlightedSection.css'

const HighlightedSection = ({ isReturningClient, children, additionalClasses, margin }) => {

    let classes = ['highlightedSection']
    if (isReturningClient) classes.push('highlight')
    if (additionalClasses) classes.push(additionalClasses)

    let content = margin ? <div style={{ margin: `${margin}px` }}>{children}</div> : children

    let style = margin ? { marginLeft: `-${margin}px`, marginRight: `-${margin}px` } : { margin: 0 }

    return (
        <div className={classes.join(' ')} style={style}>
            {content}
        </div>
    );
}

HighlightedSection.propTypes = {
    isReturningClient: PropTypes.bool.isRequired,
    additionalClasses: PropTypes.string,
    margin: PropTypes.number
}

export default HighlightedSection;