export const isValidPostalCode = (postalCode) => {
    if (!postalCode) {
        return true;
    }
    if (postalCode.match(/[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/)) {
        return true
    }
    return false
}




