import React, { Component } from 'react';
import './shared.css';
import FontAwesome from 'react-fontawesome';
import InputMask from 'react-input-mask';
import T from 'i18n-react'

class PhoneTextInput extends Component {
    constructor(props) {
        super(props)

        this.state = {
            labelText: this.props.labelText,
            validationFailedText: this.props.validationFailedText,
            hasValidated: false,
            hasErrors: this.props.hasErrors || false,            
            value: this.props.value || "",
            useFrenchLocale: this.props.isFrench,
            autoComplete: this.props.autoComplete,
            isRequired: this.props.isRequired || false,
            missingRequiredField: this.props.missingRequiredField
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.value !== newProps.value) {
            this.setState({value: newProps.value})
        }
        if (this.state.autoComplete !== newProps.autoComplete) {
            this.setState({ autoComplete: newProps.autoComplete })
        }
        if (this.state.hasErrors !== newProps.hasErrors) {
            this.setState({ hasErrors: newProps.hasErrors })
        }
        if (this.state.missingRequiredField !== newProps.missingRequiredField) {
            this.setState({ missingRequiredField: newProps.missingRequiredField })
        }
    }

    validateText(currText) {
        return this.isValidPhone(currText)
    }

    extractDigits(data) {
        if (!data) 
        {
            return []
        }

        try {
            const matches = data.match(/\d/g)
            if (matches != null)
            {
                return matches.map(Number)
            }
        } catch (ex) {
            console.log("phoneTextInput.js/ExtractDigits/ data.match fail - " + ex.message);
        }

        return []
    }

    isValidPhone(data) {
        let digits = this.extractDigits(data)
        return Array.isArray(digits) && digits.length === 10
    }

    handleTextChange(ev) {
        // once we've validated, validate on every change
        var state = {
            ...this.state,
            value: ev.target.value
        }

        var isValid = this.validateText(state.value)
        if (this.state.hasValidated) {
            state.hasErrors = !isValid
        }

        if (state.hasErrors !== this.state.hasErrors && this.props.errorStateChanged) {
           this.props.errorStateChanged(state.hasErrors)
        }
        this.setState(state);

        if (this.props.onChange) {
            this.props.onChange(ev, state.value, isValid);
        }
    }

    handleBlur() {       
        var hasErrors = !this.validateText(this.state.value)
        this.setState({ hasErrors: hasErrors })
    }

    render() {
        const placeholderText = this.state.useFrenchLocale ? "NNN NNN-NNNN" : "(NNN) NNN NNNN"
        const mask = this.state.useFrenchLocale ? "999 999-9999" : "(999) 999 9999"
        const outerStyle = this.state.hasErrors ? "defaultErrorStyles validatingTextInputStyle" : null
        var innerStyle = (this.state.hasErrors && this.state.isRequired) || (this.state.missingRequiredField && this.state.isRequired) ? "phoneInputErrorStyle" : "phoneInputStyle"
        if (this.props.isInline) innerStyle += " defaultInputWidth";
        const inputStyle = (this.state.hasErrors && this.state.isRequired) || (this.state.missingRequiredField && this.state.isRequired) ? 'red formLabelStyle': 'formLabelStyle'

        var validationError = (this.state.hasErrors && this.state.isRequired) || (this.state.hasErrors && this.state.value !== '') || (this.state.missingRequiredField && this.state.isRequired) ? 
            <div className="validationErrorStyle" style={{ marginLeft: 10 }}>
                <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#E70000', align: 'center' }} />
                <span style={{ marginLeft: 10 }}>{T.translate(this.state.validationFailedText)}</span>
            </div> :
            <div className="validationErrorStyle" style={{ marginLeft: 10 }}>
                &nbsp;
            </div>
            
        
        return (
            <div className={outerStyle}>
                <div className={inputStyle}>
                    {T.translate(this.state.labelText) + (this.state.isRequired ? '*' : '')}
                </div>
                <div className="phoneInputContainer">
                    <InputMask value={this.state.value} name={this.props.name}
                        className={innerStyle} style={{ height: 40 }} autoComplete={this.state.autoComplete ? this.state.autoComplete : "off"}
                        placeholder={placeholderText} mask={mask} maskChar="" heap-ignore="true"
                        onChange={(ev) => { this.handleTextChange(ev) }} onBlur={() => this.handleBlur()} />
                    { validationError }
                </div>
            </div>
        )
    }
}

export default PhoneTextInput;