import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import '../dox_explorer.css'
import FontAwesome from 'react-fontawesome'
import { useTranslationContext } from '../../Shared/hooks/useTranslationContextHook'

const DoxExplorerFilesTableColumnHeader = (props) => {
    const translateString = useTranslationContext();
    const handleToggle = () => {
        if (!props.isStatic) {
            props.onToggle(props.columnId)
        }
    }

    return (
        <div className={classnames('doxe-files-table-cell hrb-column', `hrb-is-${props.width}`, {
            'doxe-tc-selected': props.isSelected,
            'doxe-tc-static': props.isStatic })}
            onClick={handleToggle}>
            <span>{translateString(props.labelKey)}</span>
            {props.isSelected && !props.isReversed && 
                <FontAwesome className='fa-chevron-up' name='chev-up' />
            }
            {props.isSelected && props.isReversed &&
                <FontAwesome className='fa-chevron-down' name='chev-down' />
            }
        </div>
    )
}

DoxExplorerFilesTableColumnHeader.propTypes = {    
    columnId: PropTypes.string.isRequired,
    labelKey: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    onToggle: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired,
    isReversed: PropTypes.bool.isRequired,
    isStatic: PropTypes.bool.isRequired
}   

export default DoxExplorerFilesTableColumnHeader;