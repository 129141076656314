import React, { Component } from 'react';
import '../../Common/shared.css'
import '../style.css';
import ResidenceInfoForm from '../../Common/residenceInfoForm'
import InterviewTitleProgress from '../../Common/interviewTitleProgress'

class InterviewPage3 extends Component {
    constructor(props) {
        super(props)

        var primary = this.props.primary || {
            residenceProvince: '',
            nisgaLands: '',
            taichoLands: '',
            yukonLands: '',
            provinceTerritoryChange: '',
            homeAndMailingSame: '',
            currentResidenceProvince: '',
            immigrateCanada: '',
            canadianCitizenship: '',
            authorizeCraElectionsCanada: '',
            gender: ''
        }

        this.state = {
            primary,
            missingRequiredField: this.props.missingRequiredField,
            layoutType: this.props.layoutType,
            lang: this.props.lang,
            missingRequiredSexField: this.props.missingRequiredSexField,
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.primary !== newProps.primary) {
            this.setState({ primary: newProps.primary })
        }
        if (this.state.missingRequiredField !== newProps.missingRequiredField) {
            this.setState({missingRequiredField: newProps.missingRequiredField})
        }
        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }
        if (this.state.missingRequiredSexField !== newProps.missingRequiredSexField) {
            this.setState({missingRequiredSexField: newProps.missingRequiredSexField})
        }
    }

    onResidenceChanged(residence) {
        var primary = {
            residenceProvince: residence.residenceProvince,
            nisgaLands: residence.nisgaLands,
            taichoLands: residence.taichoLands,
            yukonLands: residence.yukonLands,
            nflLands: residence.nflLands,
            provinceTerritoryChange: residence.provinceTerritoryChange,
            homeAndMailingSame: residence.homeAndMailingSame,
            currentResidenceProvince: residence.currentResidenceProvince,
            immigrateCanada: residence.immigrateCanada,
            canadianCitizenship: residence.canadianCitizenship,
            authorizeCraElectionsCanada: residence.authorizeCraElectionsCanada,
            gender: residence.gender
        }
        this.setState({ primary })
        if (this.props.onPrimaryChanged) {
            this.props.onPrimaryChanged(primary)
        }
    }

    render() {
        return (
            <div>
                <div>
                    <div>
                        <InterviewTitleProgress taxYear={this.props.taxYear} percentage="36" title="Interview.Page3.headerTitle" nextTitle="Interview.Page3.nextTitle" />
                    </div>                    
                    <div style={{ height: 20 }} />
                </div>
                <div className="interview-page3-top-margin">
                    <ResidenceInfoForm residence={this.state.primary} missingRequiredField={this.state.missingRequiredField} missingRequiredSexField={this.state.missingRequiredSexField} labelKey="Interview.Page3"
                        onResidenceChanged={(residence) => this.onResidenceChanged(residence)} taxYear={this.props.taxYear} isReturningClient={this.props.isReturningClient} />
                </div>
            </div>                    
        )
    }
}

export default InterviewPage3;