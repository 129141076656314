import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import '../dox_explorer.css'
import FolderIcon from '../graphics/folderIcon'
import DoxExplorerFilesTableHeader from './doxExplorerFilesTableHeader'
import { TaxYearTableConfig } from '../services/DoxExplorerTableConfig'
import { useSelector, shallowEqual } from 'react-redux'

const DoxExplorerTaxYearsTable = (props) => {
    const [sortColumn, setSortColumn] = useState(null)
    const [sortDirection, setSortDirection] = useState(false)
    const [taxYears, setTaxYears] = useState([])

    const tax_years = useSelector(state => (state.workflow && state.workflow.case_state && state.workflow.case_state.taxYears
        ? state.workflow.case_state.taxYears : []).map(ty => ty.taxYear), shallowEqual)

    const handleSetTaxYear = (tax_year) => {
        props.onSetTaxYear(tax_year)
    }

    const handleSort = (col, dir) => {
        if (sortColumn !== col) {
            setSortColumn(col)
        }
        if (sortDirection !== dir) {
            setSortDirection(dir)
        }
    }

    useEffect(() => {
        let tys = (tax_years || []).sort()
        if (!sortDirection) {
            tys = tys.reverse();
        }
        if (!shallowEqual(tys, taxYears)) {
            setTaxYears(tys);
        }
    }, [tax_years, sortDirection, taxYears, setTaxYears])

    return (
        <div className='doxe-files-table'>
            <DoxExplorerFilesTableHeader tableConfig={TaxYearTableConfig} sortColumn={sortColumn}
                sortDirection={sortDirection} onSort={handleSort} />
            {taxYears.map(ty =>
                <div className='doxe-files-table-row' key={`doxe-ty-${ty}`}>
                    <div className='dox-link' key={`doxe-ty-${ty}`} onClick={() => handleSetTaxYear(ty)}>
                        <FolderIcon color='#007C10' />
                        {ty}
                    </div>
                </div>
            )}
        </div>
    )
}

DoxExplorerTaxYearsTable.whyDidYouRender = true

DoxExplorerTaxYearsTable.propTypes = {
    onSetTaxYear: PropTypes.func.isRequired
}   

export default DoxExplorerTaxYearsTable;