import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import HeaderLogo from './headerLogo';
import HeaderTabControl from './headerTabControl'

class HeaderMobile extends Component {

    handleButtonClick(action) {
        if (this.props.buttonHandler && this.props.appInitialized) {
            this.props.buttonHandler(action)
        }
    }

    handleLangClick(lang) {
        if (this.props.langHandler) {
            this.props.langHandler(lang)
        }
    }

    renderMainBar() {
        var headerButtons = [];
        if (this.props.loginComplete) {
            headerButtons.push(
                <div key='signOutButton' className="headerButtonStyle headerButtonMarginMobile"
                    onClick={(ev) => { this.handleButtonClick('logout') }}>
                    <FontAwesome className='fa-sign-out' name='fa-sign-out' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#14AA40', align: 'center' }} />
                </div>
            )
        } else {
            var headerStyle = "headerButtonStyle headerButtonMarginMobile"
            if (!this.props.appInitialized) {
                headerStyle += " uninitialized"
            }
            headerButtons.push(
                <div key='signInButton' className={headerStyle}
                    onClick={(ev) => { this.handleButtonClick('login') }}>
                    <FontAwesome className='fa-user' name='fa-user' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', align: 'center' }} />
                </div>
            )
        }

        var enLangButtonStyle = 'languageButton'
        var frLangButtonStyle = 'languageButton'
        if (this.props.lang === 'fr') {
            enLangButtonStyle += ' unselected'
            frLangButtonStyle += ' selected'
        } else {
            enLangButtonStyle += ' selected'
            frLangButtonStyle += ' unselected'
        }
        return (
            <div className="headerContainer">
                <HeaderLogo height='28px' width='161px' darkText onClick={() => this.handleButtonClick('home')} />
                <div className="headerRight mobileMarginRight">
                    {this.props.loaded ? headerButtons : ""}
                    <div style={{ width: 20 }} />
                    <div className={enLangButtonStyle}
                        onClick={(ev) => this.handleLangClick('en')}>
                        <span>En</span>
                    </div>
                    <div className={frLangButtonStyle}
                        onClick={(ev) => this.handleLangClick('fr')}>
                        <span>Fr</span>
                    </div>
                </div>
            </div>    
        )
    }

    render() {
        if (this.props.loginComplete && this.props.rootPage === 'dashboard') {
            return (
                <div className="headerStyleExtended">
                    <div className="headerContainerExtended">
                        {this.renderMainBar()}
                        <HeaderTabControl isMobile={true} lang={this.props.lang}/>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="headerStyleDefault">
                    {this.renderMainBar()}
                </div>
            )
        }
    }
}
export default HeaderMobile;