import { goBack, push, replace } from 'connected-react-router';
import T from 'i18n-react';
import queryString from 'query-string';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import AuthActions from '../../reducers/authRedux';
import ConfirmIdentityActions from '../../reducers/confirmIdentityRedux';
import DocumentsActions from '../../reducers/documentsRedux';
import WorkflowActions from '../../reducers/workflowRedux';
import { getMostRecentPrimaryInterviewTaxYearState, getMostRecentSpouseInterviewTaxYearState, determineIsVir } from '../../utils/CaseStateUtilities';
import UploaderSMSContent from '../Documents/uploaderSMSContent';
import UploaderSMSFail from '../Documents/uploaderSMSFail';
import UploaderSMSSent from '../Documents/uploaderSMSSent';
import '../Interview/style.css';
import LoadingSpinner from '../Loading/loadingSpinner';
import BaseModal from '../Modal/BaseModal';
import ConfirmIdCoupled from './confirmIdCoupled';
import ConfirmIdEditPersonalInfo from './confirmIdEditPersonalInfo';
import ConfirmIdentityAddAddress from './confirmIdentityAddAddress';
import ConfirmIdFailed from './confirmIdFailed';
import ConfirmIdModal from './confirmIdModal';
import ConfirmIdUser from './confirmIdUser';
import ConfirmSelectIdType from './stage2/confirmSelectIdType';
import ConfirmStage1FailedQRCode from './stage2/confirmStage1FailedQRCode';

class ConfirmIdentity extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            stage: this.props.stage,
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.state.stage !== newProps.stage) {
            window.scrollTo(0, 0)
            this.setState({ stage: newProps.stage })
        }
    } 

    componentDidUpdate(prevProps) {
        if (prevProps.taxYearState && !this.props.taxYearState) {
            this.onBackToDashClicked();
        }
    }

    UNSAFE_componentWillMount() {
        var failureStage = null;
        if (this.props.location.search) {
            var queryParams = queryString.parse(this.props.location.search)
            failureStage = queryParams.failureState
        }

        if (!this.props.taxYearState || !this.props.taxYearState.primaryInterviewTaxYear) {
           this.onBackToDashClicked();
           return;
        }

        if (this.props.case_state.confirm_id.self === 'Stage1Failed') {
            failureStage = 'failedStage1'
        }
                
        if (failureStage && failureStage === 'failedStage1') {
            this.props.setStage('self', 'failed')
            this.setState({ stage: 'failed' })
            this.props.getConfirmIdState();
        }
        else {
            if (this.props.confirmIdFailed) {
                this.props.setStage('self', 'failed_final')
            }
            else {
                if (!this.props.confirmSelf.initialState) {
                    this.props.getConfirmIdState();
                }
                if (!this.props.taxYearState.coupled) {
                    if (this.props.confirmSelf.initialState && this.state.stage === 'start' && this.props.confirmSelf.initialState === 'failed') {
                        this.props.setStage('self', this.props.confirmSelf.initialState)
                    }
                }
            }
        }
        

        if (this.props.confirmSelf.identityConfirmed === true && (
            !this.props.taxYearState.coupled || this.props.confirmSpouse.identityConfirmed === true)) {
            // let's reset the confirm id state here so that we can come back and do it again in case of reset
            this.props.resetConfirmationResult('primary')
            this.props.resetConfirmationResult('spouse')

            this.props.pushNavigation('/dashboard')
        }
    }    

    // Local event handlers
    onBackToDashClicked(e) {
        // head on back to the dashboard
        this.props.setStage(this.props.target, 'start')
        if (this.props.history && this.props.history.action === 'PUSH') {
            this.props.popNavigation()
        } else {
            this.props.replaceNavigation('/dashboard')
        }
    }

    addressCheck() {
        this.props.setStage(this.props.target, 'addressCheck')
    }

    addAddress() {
        this.props.setStage(this.props.target, 'addAddress')
    }

    confirmIdentity(skipValidation) {
        this.props.confirmIdentity(this.props.target, skipValidation)
    }

    cancelAddAddress() {
        this.props.updateWorkingAddress()
        this.props.addExtraConfirmAddress()
        this.props.setStage(this.props.target, 'summary')
    }

    onCancelClose() {
        this.props.setStage(this.props.target, 'addAddress')
    }

    onBackClicked(ev, stage) {
        if (stage) {
            this.props.setStage(this.props.target, stage)
        } else {
            this.onBackToDashClicked(ev)
        }
    }

    handleConfirmed() {
        if (this.state.stage === 'error' || this.state.stage === 'error_docs') {
            this.props.setStage('self', 'start')
            this.props.popNavigation('/dashboard')
        } else if (this.props.taxYearState.coupled) {
            if (this.props.confirmSelf.identityConfirmed && this.props.confirmSpouse.identityConfirmed) {
                this.props.setButtonState('confirm_id', 'AnimateRemove');
                this.props.setStage(this.props.target, 'complete')
            } else {
                this.props.addExtraConfirmAddress();
                // start will send us back to selecting the target
                this.props.setStage(this.props.target, 'start')
            }
        } else {
            this.props.setStage(this.props.target, 'complete')
            this.props.setButtonState('confirm_id', 'AnimateRemove');
            this.props.resetConfirmationResult('primary')
            this.props.resetConfirmationResult('spouse')
            this.props.pushNavigation('/dashboard')
        }
    }

    signOut(redirectTarget) {
        this.props.setLogoutRedirectTarget(redirectTarget)
        this.props.logoutUser('logout')
    }

    handleSetStage(stage) {
        if (stage === 'upload_id_2') {
            this.props.setIdTypeChoice(this.props.idTypeChoice, false)
        } else if (stage === 'upload_selfie') {
            this.props.setIdTypeChoice(this.props.idTypeChoice, true)
        } else if (stage === 'confirming_docs') {
            this.props.confirmIdentityDocs()
        }
        this.handleChangeStage(this.props.target, stage)
    }

    handleChangeStage(target, stage) {
        this.props.setStage(target, stage)
    }

    onSetUploadChoice(choice) {
        this.props.setUploadChoice(choice)
    }

    onSetIdTypeChoice(choice, isFront) {
        this.props.setIdTypeChoice(choice, isFront)
    }

    onProcessFileUpload(file) {
        this.props.processFileUpload(file)
    }

    onBeginUpload(stage) {
        if (!this.props.fileDataUploaded) {
            this.props.beginFileUpload()
        } else {
            this.handleSetStage(stage)
        }
    }

    handleUploadStateChange(state) {
        this.props.setUploaderState(true, state, '/dashboard/confirm_id')
    }

    onOneTimeCode(ev) {
        this.props.setOneTimeCode('/dashboard/confirm_id?failureState=failedStage1', this.props.folderId);
    }

    onSMSComplete(ev) {
        if (this.props.uploaderShowing != null) {
            this.props.setUploaderState(false, 'start')
            this.onBackToDashClicked()
        }
    }

    onCancelSMS = (ev) => {
        if (this.props.uploaderShowing != null) {
            this.props.setUploaderState(false,'start')
            this.handleSetStage('failed')
        }
    }

    confirmIdentityWithSkip = (skipValidation) => {
        if (skipValidation && !this.props.isProduction) {
            this.confirmIdentity(skipValidation)
        } else {
            this.addressCheck()
        }
    }

    render() {        
        var outerStyle = 'confirmContainerOuter'

        switch (this.props.layoutType) {
            case 'tablet':
            case 'small_tablet':
                outerStyle += ' confirmTablet'
                break;
            case 'mobile':
                outerStyle += ' mobile'
                break;
            default:
                break;
        }

        var mainContent = null
        var smsContent = null
        var interviewTaxYear = 0;
        var locatorLink = null

        if (!this.props.taxYearState || (this.state.stage !== 'failed_final' && ((this.props.confirmSelf.initialState === null && !this.props.confirmSelf.identityConfirmed && !this.props.confirmSelf.confirmationFailed) &&
            (this.props.confirmSpouse.initialState === null && !this.props.confirmSpouse.identityConfirmed && !this.props.confirmSpouse.confirmationFailed)))) {
            mainContent = (
                <div className="confirmContainerOuter">
                    <div className="confirmCenteredContent confirmContainerMinHeight">
                        <LoadingSpinner />
                    </div>
                </div>
            )
        } else if (!this.state.stage || this.state.stage === 'start' || this.state.stage === 'confirming' || (this.state.stage === 'complete' && this.props.taxYearState.coupled)) {
            if (this.props.taxYearState.coupled) {
                mainContent = (
                    <ConfirmIdCoupled lang={this.props.lang} layoutType={this.props.layoutType}
                        confirmSelf={this.props.confirmSelf} confirmSpouse={this.props.confirmSpouse}
                        firstName={this.props.taxYearState.primaryFirstName} spouseFirstName={this.props.taxYearState.spouseFirstName}
                         onChangeState={(target, stage) => this.handleChangeStage(target, stage)}
                        onBackClicked={(ev) => this.onBackToDashClicked(ev)}
                        onBeginUpload={(stage) => this.onBeginUpload(stage)}
                    />
                )
            } else {
                interviewTaxYear = this.props.target === 'spouse'
                    ? this.props.taxYearState.spouse.InterviewTaxYear
                    : this.props.taxYearState.primaryInterviewTaxYear
                mainContent = (
                    <ConfirmIdUser lang={this.props.lang} layoutType={this.props.layoutType}
                        onConfirmIdentity={(skipValidation) => this.confirmIdentityWithSkip(skipValidation)}
                        interviewTaxYear={interviewTaxYear}
                        prepareForSpouse={this.props.taxYearState.coupled} isVir={this.props.isVir}
                    />
                )
            }
        } else if (this.state.stage === 'summary' || this.state.stage === 'error') {
            interviewTaxYear = this.props.target === 'spouse'
                ? this.props.taxYearState.spouseInterviewTaxYear
                : this.props.taxYearState.primaryInterviewTaxYear
            mainContent = (
                <ConfirmIdUser lang={this.props.lang} layoutType={this.props.layoutType}
                    stage={this.state.stage} taxYear={this.props.taxYear} interviewTaxYear={interviewTaxYear}
                    prepareForSpouse={this.props.taxYearState.coupled}
                    onConfirmIdentity={(skipValidation) => this.confirmIdentityWithSkip(skipValidation)} isVir={this.props.isVir}/>
            )
        } else if (this.state.stage === 'edit' || this.state.stage === 'saving') {
            interviewTaxYear = this.props.target === 'spouse'
                ? this.props.taxYearState.spouseInterviewTaxYear
                : this.props.taxYearState.primaryInterviewTaxYear
            mainContent = (
                <ConfirmIdEditPersonalInfo lang={this.props.lang} layoutType={this.props.layoutType}
                    prepareForSpouse={this.props.taxYearState.coupled}
                    interviewTaxYear={interviewTaxYear}
                    saving={this.state.stage === 'saving'} taxYear={this.props.taxYear} />
            )
        } else if (this.state.stage === 'addAddress' || this.state.stage === 'confirmCancel') {
            mainContent = (
                <ConfirmIdentityAddAddress onConfirmIdentity={(ev) => this.confirmIdentity(ev)} />
            )
        } else if (this.state.stage === 'failed') {            
            mainContent = (                
                <ConfirmStage1FailedQRCode lang={this.props.lang} isCoupled={this.props.taxYearState.coupled} prepareForSpouse={this.props.target === 'spouse'} layoutType={this.props.layoutType} locatorLink={locatorLink} />
            )
            if ((this.props.uploaderState === 'send_sms' || this.props.uploaderState === 'sms_sending') && this.props.uploadChoice === 'mobile') {
                smsContent = (
                    <UploaderSMSContent layoutType={this.props.layoutType} lang={this.props.lang}
                        mobilePhone={this.props.mobilePhone} uploaderState={this.props.uploaderState}
                        onOK={() => this.handleUploadStateChange('sms_sending')} onCancel={(ev) => this.onCancelSMS(ev)}
                        onDialogClose={(ev) => this.onCancelSMS(ev)} onOneTimeCode={(ev) => this.onOneTimeCode(ev)} 
                    />
                )
            } else if (this.props.uploaderState === 'sms_sent' || this.props.uploaderState === 'sms_resending'
                || this.props.uploaderState === 'sms_resent') {
                smsContent = (
                    <UploaderSMSSent layoutType={this.props.layoutType} lang={this.props.lang}
                        mobilePhone={this.props.mobilePhone} uploaderState={this.props.uploaderState}
                        onOK={(ev) => this.onSMSComplete(ev)} onCancel={(ev) => this.handleUploadStateChange('sms_resending')}
                        onDialogClose={(ev) => this.onSMSComplete(ev)}
                    />
                )
            } else if (this.props.uploaderState === 'sms_failed') {
                smsContent = (
                    <UploaderSMSFail layoutType={this.props.layoutType} lang={this.props.lang}
                        mobilePhone={this.props.mobilePhone} uploaderState={this.props.uploaderState}
                        onOK={(ev) => this.onCancelSMS(ev)}
                        onDialogClose={(ev) => this.onCancelSMS(ev)}
                    />
                )
            }
        } else if (this.state.stage === 'select_id_type' || (this.state.stage === 'failed' && this.props.layoutType !== 'desktop')) {
            var firstname = this.props.target === 'spouse' ? this.props.taxYearState.spouseFirstName : this.props.taxYearState.primaryFirstName
            mainContent = (
                <ConfirmSelectIdType layoutType={this.props.layoutType} lang={this.props.lang} firstName={firstname}
                    prepareForSpouse={this.props.taxYearState.coupled}
                    idTypeChoice={this.props.idTypeChoice} onSetIdTypeChoice={(choice, isFront) => this.onSetIdTypeChoice(choice, isFront)}
                    onSetStage={(stage) => this.handleSetStage(stage)} onBack={(ev, stage) => this.onBackClicked(ev, stage)} />
            )  
        } else if (this.state.stage === 'upload_id_1' || this.state.stage === 'upload_id_2' || this.state.stage === 'upload_selfie'
            || this.state.stage === 'confirming_docs' || this.state.stage === 'confirmed_docs' || this.state.stage === 'error_docs') {            
            mainContent = (
                <ConfirmStage1FailedQRCode lang={this.props.lang} prepareForSpouse={this.props.target === 'spouse'} layoutType={this.props.layoutType} locatorLink={locatorLink} />
            )
        } else if (this.state.stage === 'failed_final') {
            var diyStartLink = null
            var hrbLink = null

            if (this.props.linkConfig && this.props.linkConfig[this.props.lang]) {
                diyStartLink = this.props.linkConfig[this.props.lang]['diy_start']
                locatorLink = this.props.linkConfig[this.props.lang]['office_locator']
                hrbLink = this.props.linkConfig[this.props.lang]['hrb_home']
            }
            if (!diyStartLink) {
                diyStartLink = T.translate('ConfirmIdentity.Failed.DIYLink')
            }

            if (!locatorLink) {
                locatorLink = T.translate('ConfirmIdentity.Failed.LocatorLink')
            }

            if (!hrbLink) {
                hrbLink = T.translate('Common.HRBLink')
            }

            mainContent = (<ConfirmIdFailed lang={this.props.lang} layoutType={this.props.layoutType}
                onSignOut={(ev) => this.signOut(ev)} locatorLink={locatorLink}
                diyStartLink={diyStartLink} hrbLink={hrbLink} isVir={this.props.isVir} />)
        }        

        return (
            <div className={outerStyle}>       
                {mainContent}
                <ConfirmIdModal lang={this.props.lang} layoutType={this.props.layoutType}
                    stage={this.state.stage} onDialogClose={() => this.handleConfirmed()} 
                    onAddAddress={() => this.addAddress()}
                    onConfirmIdentity={() => this.confirmIdentity()}
                    onAddressCancel={() => this.cancelAddAddress()}
                    onCancelClose={() => this.onCancelClose()}
                />
                <BaseModal show={smsContent != null} onClose={this.onCancelSMS} layoutType={this.props.layoutType}>
                    <div className='confirm_id_sms_container'>
                        {smsContent}
                    </div>
                </BaseModal>
            </div>

        );
    }
}

const getFileData = (ci) =>  {
    if (ci.stage === 'upload_id_1') {
        return ci.uploadedImageData.front
    } else if (ci.stage === 'upload_id_2') {
        return ci.uploadedImageData.back
    } else if (ci.stage === 'upload_selfie') {
        return ci.uploadedImageData.selfie
    }
}

const getFileDataUploaded = (ci) => {
    if (ci.stage === 'upload_id_1') {
        return ci.uploadedImageData.front_uploaded
    } else if (ci.stage === 'upload_id_2') {
        return ci.uploadedImageData.back_uploaded
    } else if (ci.stage === 'upload_selfie') {
        return ci.uploadedImageData.selfie_uploaded
    }
}

const getCoupledStatii = (case_state) => {
    var primaryTaxYearState = getMostRecentPrimaryInterviewTaxYearState(case_state);
    var spouseTaxYearState = getMostRecentSpouseInterviewTaxYearState(case_state);

    return primaryTaxYearState ? {
        coupled: spouseTaxYearState !== null,
        primaryInterviewTaxYear: primaryTaxYearState.taxYear,
        spouseInterviewTaxYear: spouseTaxYearState ? spouseTaxYearState.taxYear : 0,
        primaryFirstName: primaryTaxYearState.primary_firstName,
        spouseFirstName: spouseTaxYearState ? spouseTaxYearState.spouse_firstName : null,
    } : null
}

const mapStateToProps = (state, ownProps) => {
    var taxYear = ''
    if (ownProps && ownProps.match && ownProps.match && ownProps.match.params) {
        taxYear = ownProps.match.params.taxYear
    }
    taxYear = Number(taxYear || state.config.appConfig.currentTaxYear.toString())

    var isVir = determineIsVir(state.workflow.case_state);

    return {
        location: state.router.location,
        layoutType: state.layout.layoutType,
        lang: state.layout.lang,
        taxYear: taxYear,
        isVir: isVir,
        confirmSelf: state.confirmIdentity.self,
        confirmSpouse: state.confirmIdentity.spouse,
        taxYearState: getCoupledStatii(state.workflow.case_state),
        stage: state.confirmIdentity.stage,
        target: state.confirmIdentity.target,
        uploadChoice: state.confirmIdentity.uploadChoice,
        uploaderShowing: state.documents.uploaderShowing,
        linkConfig: state.config.linkConfig,
        idTypeChoice: state.confirmIdentity.idTypeChoice,        
        fileUploadState: state.confirmIdentity.fileUploadState,
        fileUploadError: state.confirmIdentity.fileUploadError,
        fileData: getFileData(state.confirmIdentity),
        fileDataUploaded: getFileDataUploaded(state.confirmIdentity),
        fileToBeUploaded: state.confirmIdentity.fileToBeUploaded,
        mobilePhone: state.accountData.mobilePhone,
        uploaderState: state.documents.uploaderState,
        isProduction: state.config.appConfig.isProduction,
        confirmIdFailed:
            state.workflow !== null &&
            state.workflow.case_state !== null &&
            state.workflow.case_state.confirm_id !== null &&
            (state.workflow.case_state.confirm_id.self === "Failed" || state.workflow.case_state.confirm_id.spouse === "Failed"),
        case_state: state.workflow.case_state,
    }
}

const mapDispatchToProps = (dispatch) => ({
    setButtonState: (buttonName, buttonState) => dispatch(WorkflowActions.setButtonState(buttonName, buttonState)),
    logoutUser: (signOutType) => dispatch(AuthActions.logoutUser(signOutType)),
    setStage: (target, stage) => dispatch(ConfirmIdentityActions.setStage(target, stage)),
    setConsentChecked: (target, consented) => dispatch(ConfirmIdentityActions.setConsentChecked(target, consented)),
    confirmIdentity: (target, skipValidation) => dispatch(ConfirmIdentityActions.confirmIdentity(target, skipValidation)),
    addExtraConfirmAddress: (address) => dispatch(ConfirmIdentityActions.addExtraConfirmAddress(address)),
    updateWorkingAddress: (addr) => dispatch(ConfirmIdentityActions.updateWorkingAddress(addr)),
    replaceNavigation: (path) => dispatch(replace(path)),
    pushNavigation: (path) => dispatch(push(path)),
    popNavigation: () => dispatch(goBack()),
    setUploadChoice: (choice) => dispatch(ConfirmIdentityActions.setUploadChoice(choice)),
    setUploaderState: (uploaderShowing, state, route) => dispatch(DocumentsActions.setUploaderState(uploaderShowing, state, route)),
    setOneTimeCode: (route, boxFolderId) => dispatch(DocumentsActions.setOneTimeCode(route, boxFolderId)),
    setIdTypeChoice: (choice, isFront) => dispatch(ConfirmIdentityActions.setIdTypeChoice(choice, isFront)),
    processFileUpload: (file) => dispatch(ConfirmIdentityActions.processFileUpload(file)),
    beginFileUpload: () => dispatch(ConfirmIdentityActions.beginFileUpload()),
    confirmIdentityDocs: () => dispatch(ConfirmIdentityActions.confirmIdentityDocs()),
    setFileUploadError: (error) => dispatch(ConfirmIdentityActions.fileUploadError(error)),
    setLogoutRedirectTarget: (redirectTarget) => dispatch(AuthActions.setLogoutRedirectTarget(redirectTarget)),
    getConfirmIdState: () => dispatch(ConfirmIdentityActions.getConfirmIdState()),
    resetConfirmationResult: (target) => dispatch(ConfirmIdentityActions.resetConfirmationResult(target))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmIdentity))

