import React, { Component } from 'react';
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome';
import T from 'i18n-react';
import LoginActions from '../../reducers/loginRedux'
import PlainButton from '../Controls/plainButton'
import './style.css'
import OuterContainer from '../Controls/outerContainer';

class OTCFailurePage extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleLoginClick(ev) {
        this.props.requestLogin()
    }

    render() {
        let bodyStyle = 'defaultStyles';
        bodyStyle += (this.props.layoutType === 'tablet' || this.props.layoutType === 'small_tablet' ? ' tablet' :
            (this.props.layoutType === 'mobile' ? ' mobile' : ' desktop'));


        var loggingin = (this.props.inProgress && !this.props.errorMessageStr1 && !this.props.errorMessageStr2);
        var loginButton = (<PlainButton labelKey="Common.SignIn"
            onClick={(ev) => this.handleLoginClick(ev)}
            isEnabled
            inProgress={loggingin} />)

        var iconContent = null
        if (this.props.layoutType !== 'mobile') {
            iconContent = (<FontAwesome className='fa-user' name='fa-user' size='2x' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#81be41', align: 'top' }} />)
        }
        return (
            <OuterContainer sizeToContent={false}>
                <div className={bodyStyle} style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'stretch' }}>
                    <div style={{ margin: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'stretch' }}>
                        <div style={{ marginLeft: 0, marginRight: 0, marginTop: 0, fontSize: '24px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            {iconContent}
                            <span style={{ marginLeft: iconContent ? 10 : 0, fontWeight: 'bold' }}>
                                {T.translate('Login.onetime.Title')}
                            </span>
                        </div>
                        <p style={{ fontSize: '16px' }}>{T.translate('Login.onetime.Info1')}</p>
                        <p style={{ fontSize: '16px' }}>{T.translate('Login.onetime.Info2')}</p>
                        {((this.props.layoutType === 'desktop') || (this.props.layoutType === 'tablet') || (this.props.layoutType === 'small_tablet')) ? (
                            <div>
                                <br /><br />
                                <div>
                                    <div style={{ marginLeft: 0, marginBottom: 0 }}>
                                        {loginButton}
                                    </div >
                                </div>
                            </div>) :
                            (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '20px', marginBottom: '20px' }} >
                                {loginButton}
                            </div >)}
                    </div>
                </div >
            </OuterContainer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.layout.lang,
        layoutType: state.layout.layoutType
    }
}

const mapDispatchToProps = (dispatch) => ({
    requestLogin: () => dispatch(LoginActions.requestLogin())
})

export default connect(mapStateToProps, mapDispatchToProps)(OTCFailurePage)
