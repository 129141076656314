/* eslint-disable-next-line */
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import LayoutActions from '../../../reducers/layoutRedux';
import LoadingPage from '../../Loading/loadingPage';
import AppInitializer from "./appInitializer";

function AddLoadConfiguration(ComponentWithConfiguration) {
    return (function ConfiguredComponent(props) {

        const [loaded, setLoaded] = useState(false);

        const dispatch = useDispatch()

        useEffect(() => {
            return () => dispatch(LayoutActions.setApplicationInitialized(false))
        }, [])

        useEffect(() => {
            if (loaded) {
                dispatch(LayoutActions.setApplicationInitialized(true))
            }
        }, [loaded])

        return (
            loaded
                ? <ComponentWithConfiguration {...props} loaded={loaded} />
                : <Fragment>
                    <LoadingPage loaded={loaded} />
                    <AppInitializer {...props} onLoaded={() => setLoaded(true)} />
                </Fragment>
        );
    });
}

export default AddLoadConfiguration;