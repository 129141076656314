import { push } from 'connected-react-router';
import T from 'i18n-react';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PlainButton from '../Controls/plainButton';
import ErrorIcon from '@mui/icons-material/Error';
import './declinedPage.css';
import classnames from 'classnames';
import LayoutActions from '../../reducers/layoutRedux';
import { useEffect, useState } from 'react';
import DateTime from '../Controls/dateTime';
import { useLocation } from 'react-router-dom';

const DeclinedPage = props => {
    const { layoutType } = props

    const [transactionId, setTransactionId] = useState();
    const [transactionDate, setTransactionDate] = useState();
    const [messageText, setMessageText] = useState();
    const search = useLocation().search;

    const jumpToMessageWindow = () => {
        props.setDashboardTab('messages')
        props.pushNavigation('/dashboard')
    }

    const jumpToDashboardWindow = () => {
        props.setDashboardTab('dashboard')
        props.pushNavigation('/dashboard')
    }

    useEffect(() => {
        setTransactionId(new URLSearchParams(search).get('trnId'));
        setTransactionDate(new URLSearchParams(search).get('trnDate'));
        setMessageText(new URLSearchParams(search).get('messageText'));

        if (transactionId && transactionDate && messageText) {
            
            window.history.replaceState(null, 'H&R Block Canada', '/dashboard/declined')
        }
    })

    return (
        <div className={classnames('body', layoutType)}>
            <div className='declinedContainer'>
                <h1 className='title'>{T.translate("Payment.Declined.Title")}</h1>
                <ErrorIcon className='error-icon' />
                <div className='content-align'>
                    <h3>{T.translate("Payment.Declined.TransactionIdLabel")} {transactionId}</h3>
                    <h3>{T.translate("Payment.Declined.DeclineMessage")} {messageText}</h3>
                    <h3>{T.translate("Payment.Declined.TransactionDateLabel")} <DateTime value={transactionDate} fallbackValue='-' dateAndTime /></h3>
                    <div style={{ marginBottom: 10 }} />
                    <p className='error-message'>{T.translate("Payment.Declined.ErrorMessage")}</p>
                </div>
                <div className='error-back-btn-container'>
                    <PlainButton labelKey='Payment.Declined.MessageButtonText' inverted={true} onClick={() => jumpToMessageWindow()} />
                    <Fragment>
                        <div style={{ width: '2rem', height: '8px' }} />
                        <PlainButton labelKey='Payment.Declined.DashboardButtonText' onClick={() => jumpToDashboardWindow()} />
                    </Fragment>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        lang: state.layout.lang,
        layoutType: state.layout.layoutType
    }
}

const mapDispatchToProps = (dispatch) => ({
    pushNavigation: (path) => dispatch(push(path)),
    setDashboardTab: (tab) => dispatch(LayoutActions.setDashboardTab(tab))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeclinedPage);
