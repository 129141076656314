// React Select v5.8.0 reference documentation: 
// -- https://react-select.com/home
// -- https://react-select.com/upgrade-to-v2#upgrade-guide-for-v2
// -- https://react-select.com/styles#the-unstyled-prop
// -- https://github.com/JedWatson/react-select
// -- https://github.com/JedWatson/react-select/discussions/5148

export const colorStyles = {
    control: (styles, {selectProps}) => ({
        ...styles,
        backgroundColor: '#fff',
        borderRadius: '0px',
        border: '1px solid #14AA40',
        color: '#3F3F3F',
        cursor: 'default',
        borderSpacing: '0',
        borderCollapse: 'spacing',
        height: '36px',
        outline: 'none',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        boxSizing: 'border-box',
        boxShadow: '0 1px 0 rgba(0, 0, 0, 0.06)',
        borderBottom: selectProps.menuIsOpen ? '0px #e6e6e6' : '1px solid #14AA40',
        ':hover': {
            border: '1px solid #14AA40',
            borderBottom: selectProps.menuIsOpen ? '0px #e6e6e6' : '1px solid #14AA40',
            boxShadow: '0 1px 0 rgba(0, 0, 0, 0.06)',
        },
    }),
    placeholder: (styles) => ({
        ...styles,
        bottom: '0',
        color: '#3F3F3F',
        left: '0',
        lineHeight: '34px',
        right: '0',
        top: '0',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }),
    menu: (styles) => ({
        ...styles,
        borderBottomRightRadius: '0px',
        borderBottomLeftRadius: '0px',
        backgroundColor: '#fff',
        border: '1px solid #14AA40',
        borderTopColor: '#e6e6e6',
        boxShadow: '0 1px 0 rgba(0, 0, 0, 0.06)',
        boxSizing: 'border-box',
        marginTop: '-1px',
        maxHeight: '200px',
        position: 'absolute',
        left: '0',
        top: '100%',
        width: '100%',
        zIndex: '1',
        borderRadius: '0px',
    }),
    menuList: (styles) => ({
        ...styles,
        maxHeight: '198px',
        overflowY: 'auto',
        borderTopColor: 'transparent',
        borderTopColor: 'transparent'
    }),
    option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        boxSizing: 'border-box',
        backgroundColor: isFocused ? 'rgba(0, 124, 16, 0.25)' : isSelected ? 'rgba(0, 124, 16, 0.4)' : '#fff',
        color: isFocused ? '#3F3F3F' : isSelected ? '#3F3F3F' : '#666666',
        cursor: 'pointer',
        display: 'block',
        padding: '8px 10px',

        ':active': {
            backgroundColor: 'rgba(0, 124, 16, 0.25)'
        }
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        backgroundColor: 'transparent',
    }),
    dropdownIndicator: (styles, { selectProps }) => ({
        ...styles,
        cursor: 'pointer',
        position: 'relative',
        textAlign: 'center',
        verticalAlign: 'middle',
        color: '#14AA40',
        transform: `rotate(${selectProps.menuIsOpen ? 180 : 0}deg)`,
        "svg": {
            fontSize: '10px',
            height: 15,
            width: 15,
        },
    }),
    clearIndicator: (styles) => ({
        ...styles,
        "svg": {
            color: 'transparent',
        }
    })
}