import React from 'react';
import { PropTypes } from 'prop-types'
import T from 'i18n-react';
import './style.css';
import { useDispatch } from 'react-redux';
import LayoutActions from '../../reducers/layoutRedux';

const MessageLink = (props) => {
    const dispatch = useDispatch();
    return  (<span className='learnMoreStyle learnMoreDefaultMargin' onClick={() => dispatch(LayoutActions.setDashboardTab('messages'))}>
                <span className='unselectableLabel'>
                    {T.translate(props.linkKey)}
                </span>
            </span>)
}

MessageLink.propTypes = {
    linkKey: PropTypes.string.isRequired,
}

export default MessageLink;