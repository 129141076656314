import React, { Component } from 'react';
import './shared.css';
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';
import InputMask from 'react-input-mask';

class NumberInput extends Component {
    constructor(props) {
        super(props)

        this.state = {
            labelText: this.props.labelText,
            validationFailedText: this.props.validationFailedText,
            hasValidated: false,
            hasErrors: false,
            errorType: null,
            name: this.props.name,
            className: this.props.className,
            value: this.props.value,
            useFrenchLocale: this.props.isFrench,
            renderErrorBorderOnly: this.props.renderErrorBorderOnly || false,
            autoComplete: this.props.autoComplete
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.labelText !== newProps.labelText) {
            this.setState({ labelText: newProps.labelText })
        }
        if (this.state.value !== newProps.value) {
            this.setState({ value: newProps.value })
        }
        if (this.state.renderErrorBorderOnly !== newProps.renderErrorBorderOnly) {
            this.setState({ renderErrorBorderOnly: Boolean(newProps.renderErrorBorderOnly) })
        }
        if (this.state.autoComplete !== newProps.autoComplete) {
            this.setState({ autoComplete: newProps.autoComplete })
        }
    }

    validateText(currText) {
        return this.isValidNumber(currText)
    }

    isValidNumber(data) {
        const matches = data.match(/^\d*$/)
        var result = { valid: true, errorType: null }
        if (data) {
            if (!matches) {
                return { valid: false, errorType: 'malformed' }
            }
        }
        return result
    }

    handleTextChange(ev) {
        // once we've validated, validate on every change
        var state = {
            ...this.state,
            value: ev.target.value,
            name: ev.target.name
        }

        var result = this.validateText(state.value)
        if (this.state.hasValidated) {
            state.hasErrors = !result.valid
            state.errorType = result.errorType
        }

        this.setState(state);

        if (this.props.onChange) {
            //this.props.onChange(ev, !result.isValid);
            this.props.onChange(ev, ev.target.value);
        }
    }

    handleBlur() {
        var result = this.validateText(this.state.value)
        this.setState({ hasValidated: true, hasErrors: !result.valid, errorType: result.errorType })
    }

    render() {
        const placeholderText = ""
        const mask = "999999999999999999999999999999"

        var mainDivClassName = "validatingTextInputStyle verticalStretchStyle"

        var inputContent = (
            <InputMask className={this.state.hasErrors || this.state.renderErrorBorderOnly ? "phoneInputErrorStyle" : "phoneInputStyle"}
                placeholder={placeholderText} mask={mask} maskChar="" name={this.state.name} value={this.state.value}
                onChange={(ev) => { this.handleTextChange(ev) }} onBlur={() => this.handleBlur()}
                autoComplete={this.state.autoComplete ? this.state.autoComplete : "off"} heap-ignore={this.props.heapIgnore}
            />
        )

        var topTextStyle = null
        if (this.props.extraTopSpacing) {
            topTextStyle = {
                height: '36px'
            }
        }
        if (this.state.hasErrors || this.state.renderErrorBorderOnly) {
            let styleName = "red formLabelStyle"
            var errorMessage = T.translate('Common.Errors.InvalidDOBFormat')
            if (this.state.errorType === 'month') {
                errorMessage = T.translate('Common.Errors.InvalidDOBMonth')
            } else if (this.state.errorType === 'day') {
                errorMessage = T.translate('Common.Errors.InvalidDOBDay')
            } else if (this.state.errorType === 'underage') {
                errorMessage = T.translate('Common.Errors.InvalidDOBUnderage')
            }
            var valContent = this.state.renderErrorBorderOnly ? (
                <div className="validationErrorStyle">
                    &nbsp;
                </div>
            ): (
                <div className="validationErrorStyle">
                    <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#E70000', align: 'center' }} />
                    <span style={{ marginLeft: 10 }}> { errorMessage }</span>
                </div>
            )
            return (
                <div className="defaultErrorStyles">
                    <div className={styleName} style={topTextStyle}>
                        {this.state.labelText}
                    </div>
                    {inputContent}
                    {valContent}
                </div>
            )
        } else {
            return (
                <div className={mainDivClassName}>
                    <div className="formLabelStyle" style={topTextStyle}>
                        {this.state.labelText}
                    </div>
                    {inputContent}
                    <div className="validationErrorStyle">
                        &nbsp;
                    </div>
                </div>
            )

        }
    }
}


export default NumberInput;