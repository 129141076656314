import React, { Component } from 'react';
import '../style.css'
import './style.css'
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';
import IconCheckButton from '../../Common/iconCheckButton'
import HelpCentre from '../../../sagas/Help/helpCentre'
import ArrowButton from '../../Controls/arrowButton'
import { connect } from 'react-redux'

class ConfirmSelectIdType extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lang: this.props.lang,
            layoutType: this.props.layoutType,
            idTypeChoice: this.props.idTypeChoice,
            onSetIdTypeChoice: this.props.onSetIdTypeChoice,
            onSetStage: this.props.onSetStage,
            onBack: this.props.onBack,
            firstName: this.props.firstName,
            prepareForSpouse: this.props.prepareForSpouse
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }

        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }

        if (this.state.idTypeChoice !== newProps.idTypeChoice) {
            this.setState({ idTypeChoice: newProps.idTypeChoice })
        }

        if (this.state.onSetIdTypeChoice !== newProps.onSetIdTypeChoice) {
            this.setState({ onSetIdTypeChoice: newProps.onSetIdTypeChoice })
        }

        if (this.state.onSetStage !== newProps.onSetStage) {
            this.setState({ onSetStage: newProps.onSetStage })
        }

        if (this.state.onBack !== newProps.onBack) {
            this.setState({ onBack: newProps.onBack })
        }

        if (this.state.firstName !== newProps.firstName) {
            this.setState({ firstName: newProps.firstName })
        }

        if (this.state.prepareForSpouse !== newProps.prepareForSpouse) {
            this.setState({ prepareForSpouse: newProps.prepareForSpouse })
        }
    }

    onSetIdTypeChoice(choice,) {
        if (this.state.onSetIdTypeChoice) {
            if (this.state.idTypeChoice === choice) {
                this.state.onSetIdTypeChoice(null, true)
            } else {
                this.state.onSetIdTypeChoice(choice, true);
            }
        }
    }

    onSetStage(stage) {
        // this should eventually determine if we're continuing on mobile
        if (this.state.onSetStage) {
            this.state.onSetStage(stage)
        }
    }

    onBackClicked(ev) {
        var stage = 'failed'
        if (this.state.layoutType !== 'desktop') {
            if (this.state.prepareForSpouse) {
                stage = 'start'
            } else {
                stage = null
            }
        }

        if (this.state.onBack) {
            this.state.onBack(ev, stage)
        }
    }

    helpClick() {
        HelpCentre.openHelpLink('ConfirmingYourIdentity/WhatTypesOfIDConfirm')
    }

    helpWhyClick() {
        HelpCentre.openHelpLink('ConfirmingYourIdentity/HowDoesConfirmIDWork.htm#whyidselfie')
    }

    render() {
        var backLabel = 'ConfirmIdentity.Stage2.IdTriage.Back'
        if (this.state.layoutType === 'mobile') {
            backLabel = 'Common.Back'
        } else if (this.state.layoutType !== 'desktop') {
            backLabel = this.state.prepareForSpouse ? 'ConfirmIdentity.Single.Back' : "Common.ToDashboard"
        }

        var titleKey = 'ConfirmIdentity.Stage2.IdTriage.Title'
        if (this.state.layoutType !== 'desktop') {
            titleKey = 'ConfirmIdentity.Stage2.Stage1Fail.Title'
        }
        var mobileContent = []
        if (this.state.layoutType !== 'desktop') {
            mobileContent.push(<span key='whyLink' className="learnMoreStyle unselectableLabel" onClick={() => this.helpWhyClick()}>{T.translate(`ConfirmIdentity.Stage2.Stage1Fail.Why.${this.props.showOteContent?'Ote':'Direct'}`)}</span>)
            mobileContent.push(<span key='subtitle' className="confirmContainerSpan topMargin wide">{T.translate('ConfirmIdentity.Stage2.Stage1Fail.SubTitle')}</span>)
            mobileContent.push(<h2 key='otherTitle'>{T.translate('ConfirmIdentity.Stage2.IdTriage.Title')}</h2>)
        }
        return (
            <div className="confirmContainerOuter">
                <div className="confirmContainer">
                    <div className="confirmBackButtonStyle" onClick={(e) => this.onBackClicked(e)}>
                        <FontAwesome className='fa-angle-left' name='fa-angle-left' size='2x' />
                        <h4>{T.translate(backLabel, { FirstName: this.state.firstName })}</h4>
                    </div>
                    <div className="confirmContentHolder">
                        <div className="idTriageTitleHolder">
                            <h1>{T.translate(titleKey, { FirstName: this.state.firstName })}</h1>
                            <div style={{ height: '5px' }} />
                        </div>
                        {mobileContent}
                        <span className="confirmContainerSpan wide">{T.translate('ConfirmIdentity.Stage2.IdTriage.SubTitle')}</span>
                        <div className="confirmIdTriageButtonHolder topMargin bottomMargin">
                            <IconCheckButton label={T.translate('ConfirmIdentity.Stage2.IdTriage.Licence')}
                                name="licenseCheck" icon="fa-car" checked={this.state.idTypeChoice === 'licence'}
                                onCheckChanged={(ev, value) => this.onSetIdTypeChoice('licence')} />
                            <IconCheckButton label={T.translate('ConfirmIdentity.Stage2.IdTriage.IdCard')}
                                name="idCardCheck" icon="fa-user" checked={this.state.idTypeChoice === 'id_card'}
                                onCheckChanged={(ev, value) => this.onSetIdTypeChoice('id_card')} />
                            <IconCheckButton label={T.translate('ConfirmIdentity.Stage2.IdTriage.ResPermit')}
                                name="resPermitCheck" icon="fa-home" checked={this.state.idTypeChoice === 'res_permit'}
                                onCheckChanged={(ev, value) => this.onSetIdTypeChoice('res_permit')} />
                            <IconCheckButton label={T.translate('ConfirmIdentity.Stage2.IdTriage.Passport')}
                                name="passportCheck" icon="fa-globe" checked={this.state.idTypeChoice === 'passport'}
                                onCheckChanged={(ev, value) => this.onSetIdTypeChoice('passport')} />
                        </div>
                        <span className="learnMoreStyle lmLeftAlign unselectableLabel" onClick={() => this.helpClick()}>{T.translate(`ConfirmIdentity.Stage2.IdTriage.Help.${this.props.showOteContent ? 'Ote' : 'Direct'}`)}</span>
                    </div>
                    <div className="footerSeparator" />
                </div>

                <div className="confirmSummaryFooterConsentRow alignMeRight rightAlignMargin">
                    <ArrowButton labelKey='Common.Continue' isEnabled={Boolean(this.state.idTypeChoice)} onClick={(ev) => this.onSetStage('upload_id_1')} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        showOteContent: state.workflow.is_direct_user === false
    }
}
const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmSelectIdType)