import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    setAccountId: ['accountId'],
    setFirstName: ['firstName'],
    setLastName: ['lastName'],
    setEmail: ['email'],
    setProvince: ['province'],
    setMobilePhone: ['mobilePhone'],
    setPhoneType: ['phoneType'],
    accountDataReset: null
})

export const AccountDataTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    accountId: '',
    firstName: '',
    lastName: '',
    email: '',
    province: '',
    mobilePhone: '',
    phoneType: 1
}

/* ------------- Reducers ------------- */
export const registerSetAccountId = (state, { accountId }) =>
    Object.assign({}, state, { accountId: accountId })

export const registerSetFirstName = (state, { firstName }) =>
    Object.assign({}, state, { firstName: firstName })

export const registerSetLastName = (state, { lastName }) =>
    Object.assign({}, state, { lastName: lastName })

export const registerSetEmail = (state, { email }) =>
    Object.assign({}, state, { email: email })

export const registerSetProvince = (state, { province }) =>
    Object.assign({}, state, { province: province })

export const registerSetMobilePhone = (state, { mobilePhone }) =>
    Object.assign({}, state, { mobilePhone: mobilePhone })

export const registerSetPhoneType = (state, { phoneType }) =>
    Object.assign({}, state, { phoneType: phoneType })

export const registerReset = (state) => INITIAL_STATE

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_ACCOUNT_ID]: registerSetAccountId,
    [Types.SET_FIRST_NAME]: registerSetFirstName,
    [Types.SET_LAST_NAME]: registerSetLastName,
    [Types.SET_EMAIL]: registerSetEmail,
    [Types.SET_PROVINCE]: registerSetProvince,
    [Types.SET_MOBILE_PHONE]: registerSetMobilePhone,
    [Types.SET_PHONE_TYPE]: registerSetPhoneType,
    [Types.ACCOUNT_DATA_RESET]: registerReset
})