import React, { Component } from 'react';
import { PropTypes } from 'prop-types'
import './style.css';
import T from 'i18n-react';
import LearnMore from '../Controls/learnMore'
import PlainButton from '../Controls/plainButton';
import LoadingSpinner from '../Loading/loadingSpinner'
import { connect } from 'react-redux'
import AuthorizeCRAMyAccount_EN from '../../resources/Authorize with CRA My Account_EN.doc.pdf'
import AuthorizeCRAMyAccount_FR from '../../resources/Authorize with CRA My Account_FR.doc.pdf';
class AuthorizeMyAccount extends Component {
    componentWillMount() {
        if (this.props.craBusinessNumber == null) {
            this.props.loadAuthorizationConfig()
        }
    }

    jumpToMessage() {
        if (this.props.onJumpToMessages) {
            this.props.onJumpToMessages();
        }
    }

    onLinkClick() {
        if (this.props.craLinkClicked) {
            this.props.craLinkClicked(true)
        }
        window.open(this.props.myAccountLink, '_blank');
    }
    openCRALink(){        
        if (this.props.lang.toUpperCase() === 'EN') {
            window.open(AuthorizeCRAMyAccount_EN);
        } else {
            window.open(AuthorizeCRAMyAccount_FR);
        }
    }
    
    render() {
        var rememberAlign = ''
        if (this.props.layoutType === 'desktop') {
            rememberAlign = 'authorizeLeftAlign'
        } else {
            rememberAlign = 'authorizeRightAlign'
        }

        var title1to5content = null
        var title5content = null
        if (this.props.layoutType === 'mobile') {
            title5content = (
                <span>
                    <T.span className="newlineText" text={{ key: 'Authorize.MyAccount.InfoTitle5' }} />
                    <T.span text={{ key: 'Authorize.MyAccount.InfoTitle5b' }} />
                    <span className="authLearnMoreMargin" />
                    <span className={this.props.lang === "fr" ? "newlineText":""}/>
                    {this.props.showOteContent?(<LearnMore linkId="AuthorizingHRBlock/WhyAuthorizeHR.htm#blank" baseClassName="authorizeFinePrint" />): null}
                </span>
            )
            title1to5content = (
                <div className="authorizeListStyle">
                    <span>
                        <T.span className="newlineText" text={{ key: 'Authorize.MyAccount.InfoTitle1' }} />
                        <T.span text={{ key: 'Authorize.MyAccount.InfoTitle1b', businessNumber: this.props.craBusinessNumber }} />
                    </span>
                    <span>
                        <T.span className="newlineText" text={{ key: 'Authorize.MyAccount.InfoTitle2' }} />
                        <T.span text={{ key: 'Authorize.MyAccount.InfoTitle2b', businessName: this.props.craBusinessName }} />
                    </span>
                    <span>
                        <T.span className="newlineText" text={{ key: 'Authorize.MyAccount.InfoTitle3' }} />
                        <T.span text={{ key: 'Authorize.MyAccount.InfoTitle3b' }} />
                        <span className="authLearnMoreMargin" />
                        {this.props.showOteContent? (<LearnMore linkId="AuthorizingHRBlock/WhyAuthorizeHR.htm#level2" baseClassName="authorizeFinePrint" />):null}
                    </span>
                    <span>
                        <T.span className="newlineText" text={{ key: 'Authorize.MyAccount.InfoTitle4' }} />
                        <T.span text={{ key: 'Authorize.MyAccount.InfoTitle4b' }} />
                    </span>
                    <span>
                        {title5content}
                    </span>
                </div>
            )
        } else {
            title5content = (

                <div style={{ display: '-webkit-box', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <div><T.span text={{ key: 'Authorize.MyAccount.InfoTitle5' }} /></div>

                    <div style={{ marginLeft: '5px' }}>
                        <T.span text={{ key: 'Authorize.MyAccount.InfoTitle5b' }} />
                        <span className="authLearnMoreMargin" />
                        {this.props.showOteContent ? (<LearnMore linkId="AuthorizingHRBlock/WhyAuthorizeHR.htm#blank" baseClassName="authorizeFinePrint" />) : null}
                    </div>


                </div>
            )
            title1to5content = (
                <div>
                    <ul className="innerAuthorizeListItem">
                        <li><T.span text={{ key: 'Authorize.MyAccount.InfoTitle1' }} />
                            <span>&nbsp;</span>
                            <T.span text={{ key: 'Authorize.MyAccount.InfoTitle1b', businessNumber: this.props.craBusinessNumber }} />
                        </li>
                        <li><T.span text={{ key: 'Authorize.MyAccount.InfoTitle2' }} />
                            <span>&nbsp;</span>
                            <T.span text={{ key: 'Authorize.MyAccount.InfoTitle2b', businessName: this.props.craBusinessName }} />
                        </li>
                        <li><T.span text={{ key: 'Authorize.MyAccount.InfoTitle3' }} />
                            <span>&nbsp;</span>
                            <T.span text={{ key: 'Authorize.MyAccount.InfoTitle3b' }} />
                            <span className="authLearnMoreMargin" />
                            {this.props.showOteContent ? (<LearnMore linkId="AuthorizingHRBlock/WhyAuthorizeHR.htm#level2" baseClassName="authorizeFinePrint" />) : null}
                        </li>
                        <li><T.span text={{ key: 'Authorize.MyAccount.InfoTitle4' }} />
                            <span>&nbsp;</span>
                            <T.span text={{ key: 'Authorize.MyAccount.InfoTitle4b' }} />
                        </li>

                        <li>{title5content}</li>
                    </ul>
                </div>
            )
        }
        var spacerValue = '20px'
        if (this.props.layoutType === 'tablet' || this.props.layoutType === 'small_tablet') {
            spacerValue = '40px'
        }
        return this.props.craBusinessNumber == null ? (
            <div className="authorizeMyAccountContainerStyle">
                <div className="authorizeContentStyle">
                    <LoadingSpinner />
                </div>
            </div>
        ) : (
            <div className="authorizeMyAccountContainerStyle">
                <div className="authorizeContentStyle">
                    <h1>{T.translate('Authorize.MyAccount.Title', { FirstName: this.props.firstName })} </h1>
                    <div className="authorizeContentSquishy">
                        <T.span text={{ key: this.props.isSpouse ? 'Authorize.MyAccount.SubTitleSpouse' : 'Authorize.MyAccount.SubTitle', FirstName: this.props.firstName }} />
                    </div>
                    <div className="authorizeProgressList" style={this.props.lang === "fr" ? { marginTop: '5px' } : {}}>
                        <div className="authorizeProgressContainer">
                            <svg width="60px" height="220px">
                                <rect fill="#14AA40" x="29" y="30" width="3" height="150" />
                                <circle cx="30" cy="30" r="20" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y="37" fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">1</text>
                                <circle cx="30" cy="100" r="20" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y="106" fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">2</text>
                                <circle cx="30" cy="170" r="20" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y="176" fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">3</text>
                            </svg>
                        </div>
                        <div className="authorizeListStyle">
                            <svg className="authorizeMobileList" width="60px" height="60px">
                                <circle cx="30" cy="30" r="20" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y="37" fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">1</text>
                            </svg>
                            <div className="authorizeListItem" style={this.props.layoutType === 'desktop' ? { marginBottom: "20px" } : {}}>
                                <span>
                                    <T.span text={{ key: this.props.isSpouse ? 'Authorize.MyAccount.Step1Spouse' : 'Authorize.MyAccount.Step1', FirstName: this.props.firstName }} />
                                </span>
                            </div>
                            <svg className="authorizeMobileList" width="60px" height="60px">
                                <circle cx="30" cy="30" r="20" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y="37" fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">2</text>
                            </svg>
                            <div className="authorizeListItem" style={this.props.layoutType === 'desktop' ? { marginBottom: "20px" } : {}}>
                                <T.span text={{ key: 'Authorize.MyAccount.Step2' }} />
                            </div>
                            <svg className="authorizeMobileList" width="60px" height="60px">
                                <circle cx="30" cy="30" r="20" stroke="#14AA40" strokeWidth="3" fill="#FFFFFF" />
                                <text x="25" y="37" fill="#14AA40" fontFamily="Roboto" fontSize="18" fontWeight="700">3</text>
                            </svg>

                            <div className="authorizeListItemLast">
                                <T.span text={{ key: 'Authorize.MyAccount.Step3' }} />
                            </div>
                            {title1to5content}
                            <div style={{ marginTop: spacerValue }} />
                            <span className="rememberSpanStyle">
                                <T.span text={{ key: 'Authorize.MyAccount.InfoMore' }} />
                                <a className="authorizeLinkStyle" onClick={() => this.openCRALink()} rel="noopener noreferrer" download>{T.translate('Authorize.MyAccount.InfoMoreDoc')} </a>
                            </span>



                            <div style={{ marginTop: '10px' }} />

                            <div className="plainButton" style={this.props.layoutType === 'mobile' ? { marginTop: '15px', marginBottom: '15px' } : { marginTop:'10px' }}>
                                <PlainButton class="button" labelKey="Authorize.MyAccount.Step1Link" isEnabled={true} onClick={() => this.onLinkClick()} extraClassName={this.props.craLinkVisited ? "isVisitedStyle" :"craButtonStyle"} />
                            </div>
                            <div style={{ marginBottom: '10px' }} />

                        </div>

                    </div>
                    <div style={this.props.layoutType === 'mobile' ? { width: '94%', height: '10px', borderBottom: '1px solid #000', marginBottom: '20px' } : {}} />
                </div>

                <div className={["authBottomMargin authorizeWideStyle", rememberAlign].join(' ')}>
                    <span className="authorizeFinePrint">
                        <span className="authorizeFinePrint">{T.translate('Authorize.MyAccount.Warning1.Ote')}</span>
                        <span className="learnMoreStyle" onClick={() => this.jumpToMessage()}>{T.translate('Authorize.MyAccount.Warning2.Ote')}</span>
                    </span>
                </div>
            </div>
        );
    }
}

AuthorizeMyAccount.propTypes = {
    lang: PropTypes.string.isRequired,
    layoutType: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    isSpouse: PropTypes.bool.isRequired,
    province: PropTypes.string.isRequired,
    craGroupId: PropTypes.string,
    craRepName: PropTypes.string,
    craBusinessNumber: PropTypes.string,
    craBusinessName: PropTypes.string,
    myAccountLink: PropTypes.string,   
    loadAuthorizationConfig: PropTypes.func.isRequired
}


const mapStateToProps = (state, ownProps) => {
    return {
        showOteContent: state.workflow.is_direct_user === false
    }
}
const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizeMyAccount)