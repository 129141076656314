import React, { Component } from 'react';
import { PropTypes } from 'prop-types'
import FontAwesome from 'react-fontawesome';
import './style.css';
import T from 'i18n-react';

class DocumentIconButton extends Component {
    constructor(props) {
        super(props)
        this.state = {     
            hover: false,                                    
        }
    }
    
    onMouseOver(event) {
        if (!this.state.hover) {
            this.setState({ hover: true })
        }
    }

    onMouseLeave(event) {
        if (this.state.hover) {
            this.setState({ hover: false })
        }
    }

    onButtonClick(event) {
        // raise the event for listeners
        if (this.props.onClick) {
            this.props.onClick(event);
        }
    }   

    render() {

        var labelContext = this.props.labelContext || {};
        var size = this.props.size || '2x';
        var baseStyle = this.props.baseStyle || 'defaultDocumentsButtonStyle';
        var isEnabled = this.props.isEnabled !== false

        var iconColor = '#007C10'
        var textColor = '#3F3F3F'
        var buttonClassName = baseStyle + " defaultDocumentButtonWidth unselectableLabel"
        if (this.props.layoutType === 'mobile') {
            buttonClassName += ' defaultDocumentButtonHeightMobile'
        } else if (this.props.layoutType === 'tablet' || this.props.layoutType === 'small_tablet') {
            buttonClassName += ' defaultDocumentButtonHeightTablet'
        } else {
            buttonClassName += ' defaultDocumentButtonHeight'
        }
        if (isEnabled) {
            buttonClassName += this.state.hover ? " documentsHover" : " documentsNoHover"
            iconColor = this.state.hover ? '#FFF' : '#007C10'
            textColor = this.state.hover ? '#FFF' : '#3F3F3F'
        } else {
            buttonClassName += " documentsDisabled"
            iconColor = '#3F3F3F'
        }


        return (              
            <div onMouseOver={(event) => { this.onMouseOver(event) }}
                onMouseLeave={(event) => { this.onMouseLeave(event) }}
                className={buttonClassName}
                onClick={(event) => { this.onButtonClick(event) }} >
                <div style={{width: '50px', marginLeft: '25px'}}>
                    <FontAwesome
                        className={this.props.icon}
                        name={this.props.icon}
                        size={size}
                        style={{ paddingLeft: "0px", paddingRight: "0px", color: iconColor }}
                    />
                    </div>
                <div style={{ color: textColor, marginLeft: "15px" }} >{T.translate(this.props.label, labelContext)}</div>
            </div>
        )       
    } 
}

DocumentIconButton.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelContext: PropTypes.object,
    size: PropTypes.string,
    isEnabled: PropTypes.bool,
    layoutType: PropTypes.string.isRequired,
    baseStyle: PropTypes.string
}


export default DocumentIconButton;