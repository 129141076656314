import React, { Fragment } from 'react';
import { oneOf } from 'prop-types'
import WorkflowPanel from './WorkflowPanel/workflowPanel'
import Documents from '../Dox/documents'
import Messages from '../Messages/messages'

const WorkflowContent = ({ dashboardTab }) => (
    <Fragment>
        {
            (dashboardTab === 'documents' && <Documents />) ||
            (dashboardTab === 'messages' && <Messages />) ||
            <WorkflowPanel />
        }
    </Fragment>
)

WorkflowContent.propTypes = {
    dashboardTab: oneOf(['dashboard', 'documents', 'messages'])
};

export default WorkflowContent;