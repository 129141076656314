import React, { Component } from 'react';
import T from 'i18n-react';
import './style.css';
import HelpCentre from '../../sagas/Help/helpCentre';
import { connect } from 'react-redux';
import { VirStatus } from '../VIR/services/virServices'
import { Fragment } from 'react';
import { HeapEvents, trackEvent } from '../../utils/AnalyticsUtil'

class ConfirmIdFailed extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lang: this.props.lang,
            layoutType: this.props.layoutType,
            onSignOut: this.props.onSignOut,
            diyStartLink: this.props.diyStartLink,
            locatorLink: this.props.locatorLink,
            hrbLink: this.props.hrbLink,
            isVir: this.props.isVir
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }

        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }

        if (this.state.onSignOut !== newProps.onSignOut) {
            this.setState({ onSignOut: newProps.onSignOut })
        }

        if (this.state.diyStartLink !== newProps.diyStartLink) {
            this.setState({ diyStartLink: newProps.diyStartLink })
        }

        if (this.state.locatorLink !== newProps.locatorLink) {
            this.setState({ locatorLink: newProps.locatorLink })
        }

        if (this.state.hrbLink !== newProps.hrbLink) {
            this.setState({ hrbLink: newProps.hrbLink })
        }
    }

    componentDidMount() {
        trackEvent(HeapEvents.CLIENTID_FAIL);
    }

    handleSignOut(redirectTarget) {
        if (this.state.onSignOut) {
            this.state.onSignOut(redirectTarget);
        }
    }

    handleSignOutAndRedirect() {
        this.handleSignOut(this.state.hrbLink)
    }

    handleLocator(ev) {
        this.handleSignOut(this.state.locatorLink)
    }

    handleDIY(ev) {
        this.handleSignOut(this.state.diyStartLink)
    }

    handleHelp(ev) {
        HelpCentre.openHelpLink("ConfirmingYourIdentity/WhyCouldntMyIdentityBeConfirmed")
    }

    render() {
        var diyLinkUrl = T.translate('Common.DIYLink')
        var diyLink = <a href={diyLinkUrl} target='_blank' rel="noopener noreferrer">{T.translate('ConfirmIdentity.Failed.diyLinkContent')}</a>
        var officeLink = <a href={this.state.locatorLink} target='_blank' rel="noopener noreferrer">{T.translate('ConfirmIdentity.Failed.officeLinkContent')}</a>

        var mainContent = null

        if (this.props.showVirContentForRtnClient && this.props.isVir) {
            mainContent = (<Fragment>
                <span className="confirmContainerSpan topMargin">{T.translate(`ConfirmIdentity.Failed.Content1.VirRtnClient`)}</span>
                <span className="confirmContainerSpan topMargin bottomMargin"><b>{T.translate(`ConfirmIdentity.Failed.Content2.VirRtnClient`)}</b></span>
                <span className="confirmContainerSpan topMargin">{T.translate(`ConfirmIdentity.Failed.Content3.VirRtnClient`, { DIYLink: diyLink })}</span>
                <span className="confirmContainerSpan topMargin">{T.translate(`ConfirmIdentity.Failed.VirFindOfficeLocation`, { OfficeLink: officeLink })}</span>
            </Fragment>)
        }
        else if (!this.props.showVirContentForRtnClient && this.props.isVir) {
            mainContent = (<Fragment>
                <span className="confirmContainerSpan topMargin">{T.translate(`ConfirmIdentity.Failed.Content1.VirNewClient`)}</span>
                <span className="confirmContainerSpan topMargin bottomMargin"><b>{T.translate(`ConfirmIdentity.Failed.Content2.VirNewClient`)}</b></span>
                <span className="confirmContainerSpan topMargin">{T.translate(`ConfirmIdentity.Failed.Content3.VirNewClient`, { DIYLink: diyLink })}</span>
                <span className="confirmContainerSpan topMargin">{T.translate(`ConfirmIdentity.Failed.VirFindOfficeLocation`, { OfficeLink: officeLink })}</span>
            </Fragment>)
        }
        else {
            mainContent = (<Fragment>
                <span className="confirmContainerSpanFailed topMargin">{T.translate(`ConfirmIdentity.Failed.Content1.${this.props.showOteContent ? 'Ote' : 'Direct'}`)}</span>
                <span className="confirmContainerSpanFailed topMargin"><b>{T.translate(`ConfirmIdentity.Failed.Content2.${this.props.showOteContent ? 'Ote' : 'Direct'}`)}</b></span>
                <span className="confirmContainerSpanFailed topMargin">{T.translate(`ConfirmIdentity.Failed.DirectFindOfficeLocation`, { OfficeLink: officeLink })}</span>
                <span className="confirmContainerSpanFailed topMargin">{T.translate(`ConfirmIdentity.Failed.Or`)}</span>
                <span className="confirmContainerSpanFailed topMargin">{T.translate(`ConfirmIdentity.Failed.Content3.${this.props.showOteContent ? 'Ote' : 'Direct'}`, { DIYLink: diyLink })}</span>
            </Fragment>)
        }

        var confirmContainerClass = 'confirmContainerFailed topMargin '
        if (this.props.layoutType !== 'mobile')
            confirmContainerClass += 'bottomMargin'
        else
            confirmContainerClass += 'bottomMarginMobile'

        return (
            
            <div className="confirmContainerOuter">
                <div className={confirmContainerClass}>
                    <div className="confirmContentHolderFailed">
                        <div style={{ width: '100%' }}>
                            <h1>{T.translate("ConfirmIdentity.Failed.Title")}</h1>
                            <div style={{ height: '5px' }} />
                        </div>
                        <span className="learnMoreStyle" onClick={(ev) => this.handleHelp(ev)}>{T.translate(`ConfirmIdentity.Failed.WhyLink.${this.props.showOteContent ? 'Ote' : 'Direct'}`)}</span>
                        { mainContent }
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        showOteContent: state.workflow.is_direct_user === false,
        showVirContentForRtnClient: state.workflow.virStatus === VirStatus.FailedId
    }
}
const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmIdFailed)
