import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import T from 'i18n-react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useLayoutStyle from '../../hooks/useRootStyleHook';
import LayoutActions from '../../reducers/layoutRedux';
import DocumentActions from '../../reducers/documentsRedux';
import AuthActions from '../../reducers/layoutRedux';
import UrlParamsUtil from '../../utils/UrlParamsUtil';
import Navigation from '../Common/navigation';
import DoxUploader from './Uploader/doxUploader';
import './dox.css';

const DocumentUploaderPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { taxYear, comingFrom } = useParams()
    const accessToken = useSelector(state => state.auth.accessToken)
    const [source, setSource] = useState('documents');
    const { layoutType, rootStyle } = useLayoutStyle()
    const backLabel = comingFrom === 'documents' ? 'Dox.BackToDocs' : 'Common.ToDashboard'

    useEffect(() => {
        let src = UrlParamsUtil.getQueryParams(window.location.search)
        if (src && src.source !== source) {
            setSource(src.source)
        }
    }, [setSource, source])

    const backToSource = (didAnyUploadsSucceed) => {
        // update document upload workflow task
        if (didAnyUploadsSucceed) {
            dispatch(DocumentActions.setFileWasUploaded(taxYear))
        }

        // head on back to the dashboard or docs tab
        if (source) {
            dispatch(LayoutActions.setDashboardTab(source))
        }
        history.push('/dashboard')
    }


    const backToSourceContinue = () => {
        // update document upload workflow task 
        dispatch(DocumentActions.setFileWasUploaded(taxYear));
    }

    const translateString = (key, params) => {
        return T.translate(key, params)
    }

    const handleUnauthorizedRequest = (err) => {
        window.sessionStorage.setItem('ote.logouttype', 'error')
        dispatch(AuthActions.logoutUser())
    }

    return (
        <div className={classnames('dox-container', rootStyle)}>
            <div className="interview-back-link">
                <Navigation onClick={backToSource} labelKey={backLabel} showArrow />
            </div>
            <DoxUploader
                layoutType={layoutType}
                taxYear={taxYear}
                comingFrom={comingFrom}
                onTranslateString={translateString}
                onHandleUnauthorizedUser={handleUnauthorizedRequest}
                onUploadsContinue={backToSourceContinue}
                accessToken={accessToken} 
                source={source} />
        </div>
    );
}

DocumentUploaderPage.propTypes = {
    source: PropTypes.string,
    ticketType: PropTypes.string
}

export default DocumentUploaderPage
