import React, { Component } from 'react';
import './style.css';
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';
import PlainButton from '../Controls/plainButton'

class InfoDialogContent extends Component {
    constructor(props) {
        super(props)

        var labelKeys = null
        if (this.props.labelKeys) {
            if (Array.isArray(this.props.labelKeys) && this.props.labelKeys.length > 0 && typeof this.props.labelKeys[0] === 'string') {
                labelKeys = this.props.labelKeys
            } else if (typeof this.props.labelKeys === 'string'){
                labelKeys = [this.props.labelKeys]
            }
        }
        this.state = {
            titleKey: this.props.titleKey,
            labelKeys: labelKeys,
            faIconName: this.props.faIconName,
            okButtonKey: this.props.okButtonKey || 'Common.Okay',  // required so set a default
            cancelButtonKey: this.props.cancelButtonKey, // not required, leave it undefined if not specified
            onOK: this.props.onOK,
            onCancel: this.props.onCancel,
            onDialogClose: this.props.onCloseDialog,
            isError: this.props.isError === true,
            isWarning: this.props.isWarning === true,
            inProgress: this.props.inProgress === true,
            layoutType: this.props.layoutType
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.titleKey !== newProps.titleKey) {
            this.setState({ titleKey: newProps.titleKey })
        }

        var labelKeys = null
        if (this.props.labelKeys) {
            if (Array.isArray(newProps.labelKeys) && newProps.labelKeys.length > 0 && typeof newProps.labelKeys[0] === 'string') {
                labelKeys = newProps.labelKeys
            } else if (typeof newProps.labelKeys === 'string') {
                labelKeys = [newProps.labelKeys]
            }
        }
        if (this.state.labelKeys !== labelKeys) {
            this.setState({ labelKeys: labelKeys })
        }

        if (this.state.okButtonKey !== newProps.okButtonKey) {
            this.setState({ okButtonKey: newProps.okButtonKey || 'Common.Okay' })
        }

        if (this.state.cancelButtonKey !== newProps.cancelButtonKey) {
            this.setState({ cancelButtonKey: newProps.cancelButtonKey })
        }

        if (this.state.onOK !== newProps.onOK) {
            this.setState({ onOK: newProps.onOK })
        }

        if (this.state.onCancel !== newProps.onCancel) {
            this.setState({ onCancel: newProps.onCancel })
        }

        if (this.state.onDialogClose !== newProps.onCloseDialog) {
            this.setState({ onDialogClose: newProps.onCloseDialog })
        }

        if (this.state.isError !== newProps.isError) {
            this.setState({ isError: newProps.isError === true })
        }

        if (this.state.isWarning !== newProps.isWarning) {
            this.setState({ isWarning: newProps.isWarning === true })
        }

        if (this.state.inProgress !== newProps.inProgress) {
            this.setState({ inProgress: newProps.inProgress === true })
        }

        if (this.state.faIconName !== newProps.faIconName) {
            this.setState({ faIconName: newProps.faIconName })
        }

        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
    }

    handleOKClick(ev) {
        if (this.state.onOK) {
            this.state.onOK(ev)
        }
    }

    handleCancelClick(ev) {
        if (this.state.onCancel) {
            this.state.onCancel(ev)
        } else if (this.state.onOK) {
            this.state.onOK(ev)
        }
    }

    handleDialogCloseClick(ev) {
        if (this.state.onDialogClose) {
            this.state.onDialogClose(ev)
        } else {
            this.handleCancelClick(ev)
        }
    }

    render() {

        var buttonStyle = [this.state.isEnabled ? "plainButtonStyle" : "plainButtonDisabledStyle"]
        if (this.state.inProgress || this.state.isComplete) {
            buttonStyle.push("inProgress")
        } else {
            buttonStyle.push(this.state.inverted ? "inverted" : "normal")
        }

        var content = []
        if (this.state.labelKeys) {
            for (var i = 0; i < this.state.labelKeys.length; ++i) {
                content.push(<span key={'label' + i} className="dialogContent dialogTopSpacing">{T.translate(this.state.labelKeys[i])}</span>)
            }
        }

        var cancelButton = null
        if (this.state.cancelButtonKey) {
            cancelButton = (
                <div className="dialogButtonHolder">
                    <PlainButton labelKey={this.state.cancelButtonKey} isError={this.state.isError}
                        inverted onClick={(ev) => this.handleCancelClick(ev)} />
                </div>
            )
        }
        var buttonLayout = ["dialogBottomSpacing", "dialogTopSpacing"];
        if (this.state.layoutType === 'mobile' || this.state.layoutType === 'small_tablet') {
            buttonLayout.push("dialogButtonContainerMobile")
        } else {
            buttonLayout.push("dialogButtonContainer")
        }

        var iconContent = null
        if (this.state.faIconName) {
            iconContent = (<FontAwesome style={{ marginBottom: '20px' }} className={this.state.faIconName} name={this.state.faIconName} size='4x' />)
        }

        var contentClass = 'dialogContentContainer greenContent'
        if (this.state.isWarning) contentClass = 'dialogContentContainer orangeContent'
        else if (this.state.isError) contentClass = 'dialogContentContainer redContent'

        return (
            <div className={'defaultStyles ' + this.state.layoutType}>
                <div className="bodyContainer maxDialogWidth" >                    
                    <div className="dialogContentContainer">
                        <div className={contentClass}>
                            {iconContent}
                            <span className="dialogTitle">
                                {T.translate(this.state.titleKey)}
                            </span>
                        </div>
                        {content}
                    </div>
                </div>
                <div className={buttonLayout.join(' ')}>
                    {cancelButton}
                    <div className="dialogButtonHolder">
                        <PlainButton labelKey={this.state.okButtonKey} inProgress={this.state.inProgress}
                            isError={this.state.isError} isWarning={this.state.isWarning} isEnabled={true}
                            onClick={(ev) => { this.handleOKClick() }} />
                    </div>
                </div>
            </div>
        )
    }
}

export default InfoDialogContent;