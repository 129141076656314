import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { replace, push } from 'connected-react-router'
import FontAwesome from 'react-fontawesome';
import T from 'i18n-react';
import { getTaxYearState } from '../../utils/CaseStateUtilities'
import '../AuthorizeHRB/style.css';
import './style.css'

import ReviewStepper from './reviewStepper'
import ReviewAltPage1 from './reviewAltPage1'
import ReviewAltPage2 from './reviewAltPage2'
import ReviewAltPage3 from './reviewAltPage3'

class ReviewAlt extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stage: props.stage,
            fileInViewer: null,
            missingFiles: false,
            missingFilesErrorVisible: false,
            processing: false,
            documentsApproved: false,
            documentsReviewed: []
        }
    }
    
    // Local event handlers 
    onBackToDashClicked(e) {
        if (this.props.page === '1') {
            this.props.replaceNavigation('/dashboard')
        }
        else if (this.props.page === '2') {
            this.props.pushNavigation('/dashboard/reviewalt/' + this.props.taxYear + '/1')
        }
        else if (this.props.page === '3') {
            this.props.pushNavigation('/dashboard/reviewalt/' + this.props.taxYear + '/2')
        }
    }

    render() {
        // determine the root styling.
        let bodyStyle = 'reviewContainerAutoHeight';
        bodyStyle += (this.props.layoutType === 'tablet' || this.props.layoutType === 'small_tablet' ? ' tablet' :
            (this.props.layoutType === 'mobile' ? ' mobile' : ' desktop'));
        
        var reviewAltPage
        var backText = 'Common.ToDashboard'
        if (this.props.page === '1') {
            reviewAltPage = <ReviewAltPage1 taxYear={this.props.taxYear} />
        }
        else if (this.props.page === '2') {
            backText = 'ReviewAndApproveAlternate.BackToStep1'
            reviewAltPage = <ReviewAltPage2 taxYear={this.props.taxYear} />
        }
        else if (this.props.page === '3') {
            backText = 'ReviewAndApproveAlternate.BackToStep2'
            reviewAltPage = <ReviewAltPage3 taxYear={this.props.taxYear} />
        }
        else {
            this.props.replaceNavigation('/dashboard')
        }                       

        // spit out some stuff
        return (
             <div className={bodyStyle}>
                <div className="authorizeContainerStyle">
                    <div className="authorizeBackContainer" style={{marginBottom: '-20px'}}>
                        <div className="authorizeBackButtonStyle" onClick={(e) => this.onBackToDashClicked(e)}>
                            <FontAwesome className='fa-angle-left' name='fa-angle-left' size='2x' />
                            <h4>{T.translate(backText)}</h4>
                        </div>                        
                    </div>
                    <div>
                        <ReviewStepper page={this.props.page} language={this.props.lang} isMobile={this.props.layoutType === 'mobile' ? true : false} />
                    </div>
                    <div style={{margin: 15}}/>
                    <div>{reviewAltPage}</div>
                 </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    var page = ''
    var taxYear = ''
    if (ownProps && ownProps.match && ownProps.match && ownProps.match.params) {
        page = ownProps.match.params.page
        taxYear = ownProps.match.params.taxYear
    }
    page = page || '1'
    taxYear = taxYear || state.config.appConfig.currentTaxYear.toString()

    return {
        lang: state.layout.lang,
        layoutType: state.layout.layoutType,
        uploaderShowing: state.documents.uploaderShowing,
        viewerShowing: state.documents.viewerShowing,
        fileShowing: state.documents.fileShowing,
        taxYear,
        taxYearDocs: state.files.documentsForReview[taxYear],        
        taxYearState: getTaxYearState(taxYear, state.workflow.case_state),
        approvalDocs: state.files.approvalDocuments[taxYear],
        signedDocuments: state.signing.signedDocuments,
        signingState: state.signing.signingState,
        signingError: state.signing.signingError,
        stage: state.approveDrafts.stage,
        workflow: state.workflow,
        page
    };
}

const mapDispatchToProps = (dispatch) => ({
    replaceNavigation: (path) => dispatch(replace(path)),
    pushNavigation: (path) => dispatch(push(path)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviewAlt))