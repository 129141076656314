import React from 'react'
import PropTypes from 'prop-types'
import '../dox_explorer.css'
import DoxExplorerTitleBreadCrumb from './doxExplorerTitleBreadCrumb'
import DoxExplorerMobileSortMenu from './doxExplorerMobileSortMenu'
import DoxExplorerSortMenu from './doxExplorerSortMenu'

const DoxExplorerTitleSortBar = (props) => {

    const handleBackToRoot = () => {
        props.onBackToRoot()
    }

    return (
        <div className='doxe-titlebar hrb-columns'>
            <DoxExplorerTitleBreadCrumb tax_year={props.tax_year} onBackToRoot={handleBackToRoot}
                layoutType={props.layoutType} isDoxCombined={props.isDoxCombined}/>
            {props.tax_year && (props.layoutType === 'mobile' || props.layoutType === 'small_tablet') &&
                <DoxExplorerMobileSortMenu onSelectSort={props.onSelectSort} layoutType={props.layoutType}
                    selectedSort={props.selectedSort} />
            }
            {props.tax_year && (props.layoutType === 'desktop' || props.layoutType === 'tablet') &&
                <DoxExplorerSortMenu onSelectSort={props.onSelectSort} layoutType={props.layoutType}
                    selectedSort={props.selectedSort} />
            }
        </div>
    )
}

DoxExplorerTitleSortBar.propTypes = {
    tax_year: PropTypes.number,
    onBackToRoot: PropTypes.func.isRequired,
    onSelectSort: PropTypes.func.isRequired,
    layoutType: PropTypes.string.isRequired
}   

export default DoxExplorerTitleSortBar;