import React, { Fragment } from 'react';
import { bool, string } from 'prop-types';
import { useDoxUploaderContext } from '../doxUploader';
import DoxUploadItem from './doxUploadItem';
import DoxUploadItemMobile from './doxUploadItemMobile';

const DoxUploadList = ({ layoutType, cancelAll }) => {

    const { fileQueue } = useDoxUploaderContext()

    return (
        <div id="pii-exclude" className='dox-upload-panel'>
            <div className='dox-upload-list'>
                {['desktop', 'tablet'].includes(layoutType) ?
                    <Fragment>
                        {fileQueue && fileQueue.map((f, i) =>
                            <DoxUploadItem key={`doc-${i}`} queuedFile={f} cancel={cancelAll} layoutType={layoutType} />
                        )}
                    </Fragment> :
                    <Fragment>
                        {fileQueue && fileQueue.map((f, i) =>
                            <DoxUploadItemMobile key={`doc-${i}`} queuedFile={f} cancel={cancelAll} layoutType={layoutType} />
                        )}
                    </Fragment>}
            </div>
        </div>
    )
}

DoxUploadList.propTypes = {
    layoutType: string,
    cancelAll: bool
}

export default DoxUploadList