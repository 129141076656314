import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import '../dox_explorer.css'
import DateTime from '../../../Controls/dateTime'
import doxExplorerCDMServices from '../../Shared/services/doxExplorerCDMServices'
import { useTranslationContext } from '../../Shared/hooks/useTranslationContextHook'
import { DoxFileDownloadStatus, DoxFileOperationStatus } from '../services/doxExplorerConsts'

const DoxExplorerFilesTableMobileFileCell = ({ file, onSelectItem, width, sortColumn, errorStatus }) => {
    const translateString = useTranslationContext();

    const [fileSizeStr, setFileSizeStr] = useState('-')
    const [uploaderKey, setUploaderKey] = useState(null)
    const [errorKey, setErrorKey] = useState(null)

    useEffect(() => {
        let fs = doxExplorerCDMServices.formatFileSize((file || {}).fileSize)
        if (fs !== fileSizeStr) {
            setFileSizeStr(fs)
        }
    }, [file, fileSizeStr, setFileSizeStr])

    useEffect(() => {
        let uk = doxExplorerCDMServices.getLabelKeyForUploader(file.uploaderIsBlock)
        if (uk !== uploaderKey) {
            setUploaderKey(uk)
        }
    }, [file, uploaderKey, setUploaderKey])

    useEffect(() => {
        let ek = null
        if (errorStatus === DoxFileDownloadStatus.FAIL_TRANSIENT) {
            ek = 'Dox.DocumentExplorer.Errors.Transient'
        } else if (errorStatus === DoxFileDownloadStatus.FAIL_NO_SUCH_FILE) {
            ek = 'Dox.DocumentExplorer.Errors.Removed'
        } else if (errorStatus === DoxFileOperationStatus.FAIL_DELETE_HRB_FILE) {
            ek = 'Dox.DocumentExplorer.Errors.DeleteHRBFile'
        } else if (errorStatus === DoxFileOperationStatus.FAIL_RENAME_HRB_FILE) {
            ek = 'Dox.DocumentExplorer.Errors.RenameHRBFile'
        } 

        if (errorKey !== ek) {
            setErrorKey(ek)
        }
    }, [errorStatus, setErrorKey, errorKey]);

    const handleOpen = () => {
        onSelectItem(file.documentId)
    }

    return (
        <div className={classnames('doxe-files-table-cell hrb-column', `hrb-is-${width}`)}>
            {file && 
                <div className='doxe-mobile-files-table-cell'>
                    <div className='doxe-filescell-row'>
                        <div className={classnames('dox-link', { 'doxe-filescell-sel': sortColumn === 'name' })} onClick={handleOpen}>
                            <span>{ doxExplorerCDMServices.getTruncatedFileName(file.fileName, file.fileType, true) }</span>
                            </div>
                            <div className='doxe-horiz-spacer'>-</div>
                            <div className={classnames({ 'doxe-filescell-sel': sortColumn === 'size' })}>
                                <span>{fileSizeStr}</span>
                            </div>
                        </div>
                        <div className='doxe-filescell-row'>
                        <div className={classnames({ 'doxe-filescell-sel': !sortColumn || sortColumn === 'modified' })}>
                            <DateTime value={file.modifiedTs} fallbackValue='-' dateAndTime />
                        </div>
                            <div className='doxe-horiz-spacer'>-</div>
                        <div className={classnames({ 'doxe-filescell-sel': sortColumn === 'uploader' })}>
                            {uploaderKey ? translateString(uploaderKey) : ''}
                        </div>
                    </div>
                    {errorKey &&
                        <div className='doxe-red'>
                            <b>{translateString(errorKey)}</b>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

DoxExplorerFilesTableMobileFileCell.propTypes = {    
    file: PropTypes.shape({
        documentId: PropTypes.string.isRequired,
        fileType: PropTypes.string.isRequired,
        fileName: PropTypes.string.isRequired,
        modifiedTs: PropTypes.string.isRequired
    }),
    onSelectItem: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    sortColumn: PropTypes.string,
    errorStatus: PropTypes.string
}   

export default DoxExplorerFilesTableMobileFileCell;