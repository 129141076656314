/* eslint-disable-next-line */
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import LayoutActions from '../../../reducers/layoutRedux'
import UrlParamsUtil from '../../../utils/UrlParamsUtil'

/**
 * Used to initialize the language for the current user. This will attempt to retrieve the 
 * language parameter from the url search params first, then from the browser. Lastly, it will
 * fallback to English as a default if nothing can be determined from either the url or the browser
 * @param {any} props
 */
const LangInitializer = ({ updateState }) => {
    const dispatch = useDispatch()
    const stateLang = useSelector(state => state.layout.lang);

    const readLangFromCookies = () => {
        var myCookies = {};
        if (document.cookie) {
            var keyValuePairs = document.cookie.split(';');
            for (var i = 0; i < keyValuePairs.length; ++i) {
                var cookie = keyValuePairs[i].split('=');
                myCookies[cookie[0].trim()] = cookie[1];
            }

            // if this is undefined, return that so we can pull the browser language settings
            return myCookies['langPref'];
        }
        return null;
    }

    const getBrowserDefaultLanguage = () => {
        var lang = navigator.languages
            ? navigator.languages[0]
            : (navigator.language || navigator.userLanguage)

        if (lang) {
            if (lang.toLowerCase().indexOf('fr') !== -1) {
                lang = 'fr'
            } else if (lang.toLowerCase().indexOf('en') !== -1) {
                lang = 'en'
            }
        }
        return lang
    }

    useEffect(() => {
        var lang = null

        var params = UrlParamsUtil.getQueryParams(window.location.search);
        if (params.lang) {
            lang = params.lang.toLowerCase()
        }
        if (!lang) {
            lang = readLangFromCookies()
        }
        if (!lang) {
            lang = getBrowserDefaultLanguage()
        }

        if (!stateLang) {
            dispatch(LayoutActions.setDictLanguage(lang || 'en'))
        } else {
            dispatch(LayoutActions.setDictLanguage(stateLang))
        }
        updateState(true)
    }, [])

    return null
}

LangInitializer.propTypes = {
    updateState: PropTypes.func.isRequired
}

export default LangInitializer;