export const DoxFileOperations = {
    VIEW: 'view',
    RENAME: 'rename',
    DELETE: 'delete'
}

export const DoxFileDownloadStatus = {
    IN_PROGRESS: 'in_progress',
    COMPLETE: 'complete',
    FAIL_NO_SUCH_FILE: 'no_such_file',
    FAIL_TRANSIENT: 'transient_error'
}

export const DoxFileOperationStatus = {
    FAIL_DELETE_HRB_FILE: 'delete_hrb_file',
    FAIL_RENAME_HRB_FILE: 'rename_hrb_file'
}