import React from 'react';
import PropTypes from 'prop-types'
import WorkflowSection from '../Section/WorkflowSection'
import '../Section/section.css'
import BlankStateLabel from '../Section/BlankStateLabel'

const NewReturnBlankStateReturnSection = ({ currentYear, taxState, securityComplete, onWorkflowButtonAction, isDirectUser }) => {
    var prefix = !securityComplete ?
        'WorkflowPanel.blank.complete-security.' :
        'WorkflowPanel.blank.in-progress.';

    return (
        <WorkflowSection
            titleKey={taxState.is_vir ? "WorkflowPanel.taxReturnTitleVIR" : "WorkflowPanel.taxReturnTitle"} 
            titleKeyProps={{ TaxYear: taxState.taxYear }}
            isAlternate={(currentYear - taxState.taxYear) % 2 === 1}
            isVIR={taxState.is_vir}
            comingUpKey={`${prefix}coming-up`}
            canClose={false}
            onClose={() => onWorkflowButtonAction('delete-return', taxState.taxYear)}
            ticketType={taxState.ticket_type}>
            <BlankStateLabel
                contentKey={`${prefix}label`}
                contentProps={{ TaxYear: taxState.taxYear }} />
        </WorkflowSection>
    )
}

NewReturnBlankStateReturnSection.propTypes = {
    taxState: PropTypes.shape({
        taxYear: PropTypes.number.isRequired,
        ticketState: PropTypes.string,
        coupled: PropTypes.bool.isRequired,
        primary_province: PropTypes.string,
        primary_firstName: PropTypes.string.isRequired,
        spouse_province: PropTypes.string,
        spouse_firstName: PropTypes.string,
        subTotal: PropTypes.number,
        tenderTotal: PropTypes.number
    }),
    currentYear: PropTypes.number.isRequired,
    securityComplete: PropTypes.bool.isRequired,
    onWorkflowButtonAction: PropTypes.func.isRequired
}

export default NewReturnBlankStateReturnSection;

