import apisauce from 'apisauce'
import { getApiBaseUrl } from './serviceUtils'

/**
 * API for managing workflow information
 * @param {String} baseURL
 */
const create = (baseURL = getApiBaseUrl()) => {  // this will change for prod deployment
    // Create and configure an apisauce-based api object.
    const api = apisauce.create({
        baseURL,
        headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Content-Type': 'application/json' },
        timeout: 60000
    })

    const getAuthHeader = (accessToken) => {
        return {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        }
    }

    const getPaymentInfo = (accessToken, caseid) => {
        var payload = '';
        return api.get('case/' + caseid + '/payments', payload, getAuthHeader(accessToken))
    }

    const completePayment = (accessToken, url) => {
        var payload = {
            confirmationLink: url
        };
        return api.post('case/payment', payload, getAuthHeader(accessToken))
    }

    // Return back a collection of functions
    return {
        getPaymentInfo, completePayment
    }
}

// let's return back our create method as the default.
export default {
    create
}