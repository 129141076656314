import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import '../dox_explorer.css'
import doxExplorerCDMServices from '../../Shared/services/doxExplorerCDMServices'
import { useTranslationContext } from '../../Shared/hooks/useTranslationContextHook'

const DoxExplorerFilesTableUploaderCell = ({ width, uploaderIsBlock }) => {
    const translateString = useTranslationContext();

    const [uploaderKey, setUploaderKey] = useState(null)

    useEffect(() => {
        let uk = doxExplorerCDMServices.getLabelKeyForUploader(uploaderIsBlock)
        if (uk !== uploaderKey) {
            setUploaderKey(uk)
        }
    }, [uploaderIsBlock, uploaderKey, setUploaderKey])

    return (
        <div className={classnames('doxe-files-table-cell hrb-column', `hrb-is-${width}`)}>
            <span>{uploaderKey ? translateString(uploaderKey) : '-'} </span>
        </div>
    )
}

DoxExplorerFilesTableUploaderCell.propTypes = {    
    uploaderIsBlock: PropTypes.bool,
    width: PropTypes.number.isRequired
}   

export default DoxExplorerFilesTableUploaderCell;