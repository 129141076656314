import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import '../dox_explorer.css'
import DoxModal from '../../Shared/components/doxModal'
import { useTranslationContext } from '../../Shared/hooks/useTranslationContextHook'
import DoxButton from '../../Shared/components/DoxButton'

const DoxExplorerRenameModal = ({ file, isOpen, onCancel, onRenameFile, renameError, layoutType, fileList }) => {
    const translateString = useTranslationContext()
    const [working, setWorking] = useState(false)
    const [errorLabelKey, setErrorLabelKey] = useState(null)

    const [fileName, setFileName] = useState(file.fileName)

    const handleCancel = () => {
        onCancel()
    }

    const handleRenameFile = () => {
        setWorking(true);
        setErrorLabelKey(null)
        var name = fileName
        if (name.endsWith(file.fileType)) {
            name = fileName.substring(0, fileName.length - file.fileType.length)
        }

        // check for any name collision in a case insensitive way 
        if ((fileList || []).some(x => x.fileName.toLowerCase() === name.toLowerCase() && x.fileType.toLowerCase() === file.fileType.toLowerCase())) {
            setErrorLabelKey('Dox.DocumentExplorer.NameExists')
            setWorking(false)
        } else {
            onRenameFile(file.documentId, name)
        }
    }

    useEffect(() => {
        var lk = null
        if (renameError === 'fail') {
            lk = 'Dox.DocumentExplorer.GenericFail'
        }
        if (lk !== errorLabelKey && errorLabelKey !== 'Dox.DocumentExplorer.NameExists') {
            setErrorLabelKey(lk)
            if (lk) {
                setWorking(false)
            }
        }
    }, [renameError, errorLabelKey])

    const handleFileNameChange = (ev) => {
        setFileName(ev.target.value)
    }

    return (
        <DoxModal show={isOpen} onClose={handleCancel} layoutType={layoutType} closeOnBgClick>
            {file &&
                <div className='doxe-deleteModal'>
                <div>{translateString('Dox.DocumentExplorer.RenamePrompt', { FileName: file.fileName + file.fileType })}</div>
                    <div className='doxe-input-outer'>
                    <input onChange={handleFileNameChange} value={fileName} id='doxe-rename-input' />
                    </div>
                    {errorLabelKey && 
                        <div className='dox-error-text'>{translateString(errorLabelKey)}</div>
                    }
                    <div className='doxe-modal-btns'>
                        <div className='dox-link' onClick={handleCancel}>{translateString('Common.Cancel')}</div>
                        <DoxButton elementId='doxe-rename-btn' labelKey='Dox.DocumentExplorer.Save' onClick={handleRenameFile}
                            loading={working} disabled={!fileName || fileName === file.fileName} buttonStyles='primary'/>
                    </div>
                </div>
            }
        </DoxModal>
    )
}

DoxExplorerRenameModal.propTypes = {
    layoutType: PropTypes.string.isRequired,
    file: PropTypes.shape({
                documentId: PropTypes.string.isRequired,
        fileType: PropTypes.string.isRequired,
        fileName: PropTypes.string.isRequired,
        modifiedTs: PropTypes.string.isRequired
    }),
    onCancel: PropTypes.func.isRequired,
    onRenameFile: PropTypes.func.isRequired,
    renameError: PropTypes.string,
    fileList: PropTypes.arrayOf(PropTypes.shape({
        documentId: PropTypes.string.isRequired,
        fileType: PropTypes.string.isRequired,
        fileName: PropTypes.string.isRequired,
        modifiedTs: PropTypes.string.isRequired
    })).isRequired
}   

export default DoxExplorerRenameModal;