import { Component } from 'react';
import { connect } from 'react-redux'
import AuthActions from '../../../reducers/authRedux'

class ConnectionTimers extends Component {
    constructor(props) {
        super(props)

        this.reCheckConnection = this.reCheckConnectionInterruption.bind(this)

        this.state = {
            signedIn: this.props.signedIn,
            connectionIssue: this.props.connectionIssue
        }
        this.connectionInterruptionInterval = null
    }

    componentWillReceiveProps(newProps) {
        if (this.state.signedIn !== newProps.signedIn) {
            if (newProps.signedIn) {
                this.startConnectionInterruptionInterval(true)
            } else {
                this.clearConnectionInterruptionInterval()
            }
            this.setState({ signedIn: newProps.signedIn })
        }

        if (this.state.connectionIssue !== newProps.connectionIssue) {
            this.setState({ connectionIssue: newProps.connectionIssue })
        }

    }

    componentDidMount() {
        this.startConnectionInterruptionInterval(this.state.signedIn, this.state.connectionIssue)
    }

    startConnectionInterruptionInterval(signedIn, connectionIssue) {
        this.clearConnectionInterruptionInterval();
        if (connectionIssue) {
            this.connectionInterruptionInterval = setInterval(this.reCheckConnection, 1 * 60 * 1000)
        }
    }

    clearConnectionInterruptionInterval() {
        if (this.connectionInterruptionInterval) {
            clearInterval(this.connectionInterruptionInterval)
            this.connectionInterruptionInterval = null
        }
    }

    componentWillUnmount() {
        this.clearConnectionInterruptionInterval()
    }

    reCheckConnectionInterruption() {
        this.props.updateConnectionInterruptionTimer()
    }
    
    render() {
        return null
    }
}


const mapStateToProps = (state) => {
    return {
        signedIn: state.auth.signedIn,
        connectionIssue: state.auth.connectionIssue
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateConnectionInterruptionTimer: () => dispatch(AuthActions.updateConnectionInterruptionTimer())
})

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionTimers)
