
import React from 'react';
import { PropTypes } from 'prop-types'
import './sidebar.css';
import logo from '../../../images/logo_small.png'
import AssignedTaxProBlock from './AssignedTaxProBlock'

const TabletSidebar = ({ handleHomeClick, assigned_tax_pros, layoutType, scheduleCallClick }) => (
    <div className='workflow-tablet-sidebar-container'>
        <img src={logo} width="114px" height="114px;" onClick={handleHomeClick} alt="H&R Block Logo" />
        <AssignedTaxProBlock layoutType={layoutType} assigned_tax_pros={assigned_tax_pros}
            scheduleCallClick={scheduleCallClick} />
    </div>
);

TabletSidebar.propTypes = {
    handleHomeClick: PropTypes.func.isRequired,
    assigned_tax_pros: PropTypes.arrayOf(PropTypes.shape({
        taxYear: PropTypes.number.isRequired,
        assigned_tax_pro: PropTypes.shape({
            name: PropTypes.string.isRequired,
            calendar_link: PropTypes.string
        })
    })).isRequired,
    layoutType: PropTypes.string.isRequired,
    scheduleCallClick: PropTypes.func.isRequired
}

export default TabletSidebar
