import React, { useEffect } from 'react';
import '../Section/section.css'
import PaymentActions from '../../../reducers/paymentRedux'
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types'
import PaymentReturnSection from './PaymentReturnSection'
import { useTicketType } from '../../../hooks/useTicketTypeHook'
import WorkflowActions from '../../../reducers/workflowRedux'

const PaymentSection = ({ lang, currentYear, securityComplete, taxState, paymentInfo, onWorkflowButtonAction }) => {
    const dispatch = useDispatch();
    const { ticketType } = useTicketType(taxState);

    useEffect(() => {
        dispatch(WorkflowActions.updateCaseState())
        dispatch(PaymentActions.getPaymentInfo(taxState.workflow_state.case_id));
    }, [])

    if (taxState.taxYear === currentYear && !taxState.interview_state.complete && taxState.interview_state.current_page === '1' && taxState.interview_state.completed_date === null) {
        return null;
    }

    if (!securityComplete || ticketType.toLowerCase() !== 'rte') {
        return null;
    }

    return (
        paymentInfo?.length > 0 &&
        <PaymentReturnSection lang={lang}
            taxState={taxState} currentYear={currentYear}
            paymentInfo={paymentInfo} onWorkflowButtonAction={onWorkflowButtonAction}
        />
    )
}

PaymentSection.propTypes = {
    currentYear: PropTypes.number.isRequired,
    lang: PropTypes.string.isRequired,
    securityComplete: PropTypes.bool.isRequired,
    taxState: PropTypes.shape({
        taxYear: PropTypes.number.isRequired,
        coupled: PropTypes.bool.isRequired,
        primary_province: PropTypes.string,
        primary_firstName: PropTypes.string.isRequired,
        spouse_province: PropTypes.string,
        spouse_firstName: PropTypes.string,
        subTotal: PropTypes.number,
        tenderTotal: PropTypes.number
    })
}
export default PaymentSection;
