import React from 'react';
import T from 'i18n-react';
import PropTypes from 'prop-types'

import './section.css'

const SectionLabel = ({ titleKey, contentKey, contentProps }) => (
    <div className='wf-section-label-container'>
        <h3>{T.translate(titleKey)}</h3>
        <span>{T.translate(contentKey, contentProps)}</span>
    </div>
);

SectionLabel.propTypes = {
    titleKey: PropTypes.string.isRequired,
    contentKey: PropTypes.string.isRequired,
    contentProps: PropTypes.object
}

export default SectionLabel;
