import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    startNewReturns: ['taxYears'],
    setNextReturnAssignment: ['associateId', 'officeId'],
    resetNewReturnState: null,
})

export const WorkflowNewReturnTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    associateId: null,
    officeId: null
}

/* ------------- Reducers ------------- */
export const wnrReset = () => INITIAL_STATE


export const wnrSetNextReturnAssignment = (state, { associateId, officeId }) => 
    Object.assign({}, state, { associateId: associateId, officeId: officeId})

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_NEXT_RETURN_ASSIGNMENT]: wnrSetNextReturnAssignment,
    [Types.RESET_NEW_RETURN_STATE]: wnrReset
})