import { put, select, call } from 'redux-saga/effects'
import SigningActions from '../reducers/signingRedux'
import WorkflowActions from '../reducers/workflowRedux'
import { loadAuthState } from './authorizeCRASagas'

const getAccessToken = state => state.auth.accessToken
const getSigning = state => state.signing


export function* getSigningUrl(api, action) {
    var accessToken = yield select(getAccessToken)
    var signing = yield select(getSigning)

    var response = null;
    if (signing.isAdditional) {
        response = yield call(api.getESignatureUriForAdditionalDocs, accessToken, action.taxYear, signing.forms, signing.isSpouse)
    } else {
        response = yield call(api.getESignatureUri, accessToken, action.taxYear, signing.forms, signing.externalUrls, signing.isSpouse)
    }
    if (response && response.ok && response.data) {
        var { signingUrl, envelopeId, recipientId } = response.data
        yield put(SigningActions.setSigningUri(signingUrl, envelopeId, recipientId))
    } else {
        yield put(SigningActions.completeFormSigning('failed'))
        // VAI-476 check for specific error message indicating that the file to be signed was not found in Box.
        if (response && response.status === 404) {
            yield put(SigningActions.setSigningError('filenotfound'));
        }
    }
}

export function* logoutResetSigningState(action) {
    yield put(SigningActions.signingStateLogoutReset())
}

export function* processDocumentCallback(api, authorizeCRAApi, action) {
    if (action.status === 'in_progress') {
        var signing = yield select(getSigning)

        // TODO: what if we're not currently signing something??
        if (signing && signing.signingState === 'in_progress') {
            // set the state to this in order to hide the modal dialog, preferably before it shows
            yield put(SigningActions.completeFormSigning('processing'))
                var accessToken = yield select(getAccessToken)
                // this endpoint now does all the work, the second call was removed
                var response = yield call(api.completeForm, accessToken, signing.envelopeId, signing.recipientId)
                if (response && response.ok) {
                    if (signing.isAdditional) {
                        // update the case state here to make the buttons go away
                        yield put(WorkflowActions.updateCaseState())
                    }
                    yield call(loadAuthState, authorizeCRAApi, {})
                    yield call(getSignedDocuments, api, action)
                    signing = yield select(getSigning)
                    var signedDocs = signing.signedDocuments
                    var updatedSignedDocs = null;
                    var i = 0;
                    if (signing.isSpouse) {
                        var spouse = Object.assign({}, signedDocs.spouse)
                        for (i = 0; i < signing.forms.length; ++i) {
                            spouse[signing.forms[i]] = true
                        }
                        updatedSignedDocs = Object.assign({}, signedDocs, { spouse: spouse })
                    } else {
                        var self = Object.assign({}, signedDocs.self)
                        for (i = 0; i < signing.forms.length; ++i) {
                            self[signing.forms[i]] = true
                        }
                        updatedSignedDocs = Object.assign({}, signedDocs, { self: self })
                    }
                    yield put(SigningActions.setSignedDocuments(updatedSignedDocs))
                    yield put(SigningActions.completeFormSigning('complete'))
                    yield put(SigningActions.processDocumentCallback('complete'))
                } else {
                    yield put(SigningActions.completeFormSigning('failed'))
                    yield put(SigningActions.processDocumentCallback('failed'))
                }
        }
    }
    else if (action.status === 'complete') {
        // OTE-320 retrieve updated case state from the server.
        yield put(WorkflowActions.updateCaseState())
    }
}

export function* getSignedDocuments(api, action) {
    var accessToken = yield select(getAccessToken)

    var response = yield call(api.getSignedForms, accessToken)
    if (response && response.ok) {
        yield put(SigningActions.setSignedDocuments(response.data))
    }
}

export function* checkSigningState(api, action) {
    // we just loaded the signing page, if this was from hitting back, reset this
    var signing = yield select(getSigning)
    if (signing && signing.signingState === 'in_progress') {
        yield put(SigningActions.completeFormSigning('failed'))
    }
}
