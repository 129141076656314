import { useEffect, useState } from 'react'

 const ValidTicketTypes = {
    VDO: "vdo",
    VIR: "vir",
    RTE: "rte"
}

const useTicketType = (taxState = {}) => {
    const [ticketType, setTicketType] = useState('RTE')
    const [isVir, setIsVir] = useState(false)

    useEffect(() => {
        
        if ((taxState.ticket_type || '').toLowerCase() === ValidTicketTypes.VIR) {
            setTicketType(ValidTicketTypes.VIR)
            setIsVir(true)
        } else if ((taxState.ticket_type || '').toLowerCase() === ValidTicketTypes.RTE) {
            setTicketType(ValidTicketTypes.RTE)
            setIsVir(false)
        } else {
            setTicketType(ValidTicketTypes.VDO)
            setIsVir(false)
        }
    }, [taxState])

    return { ticketType, isVir }
}

export  { useTicketType, ValidTicketTypes }