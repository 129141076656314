import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import T from 'i18n-react';
import './acc.css'
import PlainButton from '../../Controls/plainButton';
import AccountDataActions from '../../../reducers/accountDataRedux';
import RegisterActions from '../../../reducers/registerRedux';
import ValidatingTextInput from '../../Common/validatingTextInput';
import PhoneTextInput from '../../Common/phoneTextInput';
import PhoneTypeToggle from '../../Common/phoneTypeToggle';
import ProvinceDropDown from '../../Controls/DropDowns/provinceDropDown';
import CheckBox from '../../Common/checkbox';
import { hasOnlyValidChars, isValidPhone } from '../services/registrationUtils';

const ReturningUpdate = () => {  
    const dispatch = useDispatch();

    const { firstName, lastName, mobilePhone, phoneType, province } = useSelector(state => state.accountData);
    const { layoutType, lang } = useSelector(state => state.layout);
    const { termsAccepted } = useSelector(state => state.register.credentialState);
    const consentChecked = useSelector(state => state.register.consentChecked);

    const [isSmallLayout, setIsSmallLayout] = useState(false);
    const [dataComplete, setDataComplete] = useState(false);
    const [privacyLink, setPrivacyLink] = useState(null)

    useEffect(() => {
        setPrivacyLink(<a href={T.translate('Common.PrivacyLink')} target="_blank" rel="noopener noreferrer"><b>{T.translate('Common.PrivacyPolicy')}</b></a>)
    }, [lang])

    useEffect(() => {
        let isSm = layoutType === 'small_tablet' || layoutType === 'mobile'
        if (isSm !== isSmallLayout) {
            setIsSmallLayout(isSm)
        }
    }, [layoutType, setIsSmallLayout, isSmallLayout])

    useEffect(() => {
        setDataComplete(hasOnlyValidChars(firstName) && hasOnlyValidChars(lastName) 
            && isValidPhone(mobilePhone) && province && termsAccepted && consentChecked)
    }, [firstName, lastName, mobilePhone, phoneType, province, setDataComplete, termsAccepted, consentChecked])

    const handleFirstNameChange = (ev) => {
        dispatch(AccountDataActions.setFirstName(ev.target.value.trim()))
    }

    const handleLastNameChange = (ev) => {
        dispatch(AccountDataActions.setLastName(ev.target.value.trim()))
    }

    const handlePhoneNumberChange = (event, value, isValid) => {
        if (value !== mobilePhone) {
            dispatch(AccountDataActions.setMobilePhone(value))
        }
    };

    const handlePhoneTypeChange = (event) => {
        dispatch(AccountDataActions.setPhoneType(parseInt(event.target.value, 10)))
    }

    const handleProvinceChanged = (ev, value) => {
        if (value !== province) {
            dispatch(AccountDataActions.setProvince(value))
        }
    }

    const handleTermsCheck = (ev) => {
        dispatch(RegisterActions.setCredStateTermsAccepted(ev.checked))
    }

    const handleConsentCheck = (ev) => {
        dispatch(RegisterActions.setConsentChecked(ev.checked))
    }

    const handleContinue = () => {
        dispatch(RegisterActions.setStepWithMessage('lookup_user', 'AccountSetupProgress.statusMessages.Creating'))
    }
   
    return (
        <Fragment>
        <div className='reg-content'>
                <h3>{T.translate("AccountSetupReturning.labels.FullService")}</h3>
                <form name="partial-setup-form" autoComplete="on">
                    <div style={{ display: 'flex', width: '100%', marginLeft: 0, flexDirection: isSmallLayout || layoutType === 'tablet' ? 'column' : 'row', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                        <div style={{ width: '100%' }}>
                            <ValidatingTextInput labelText={T.translate("AccountSetupStart.labels.FirstName")}
                                value={firstName} maxLength={30} autoComplete="given-name"
                                placeholderText="" defaultText={firstName} validationFailedText={T.translate("AccountSetupStart.labels.NameValidationError")}
                                validator={hasOnlyValidChars} onChange={handleFirstNameChange} />
                        </div>


                        <div style={{ marginLeft: layoutType === 'desktop' ? '10px' : '0px', width: '100%' }}>
                            <ValidatingTextInput labelText={T.translate("AccountSetupStart.labels.LastName")}
                                value={lastName} maxLength={254} autoComplete="family-name"
                                placeholderText="" defaultText={lastName} validationFailedText={T.translate("AccountSetupStart.labels.NameValidationError")}
                                validator={hasOnlyValidChars} onChange={handleLastNameChange} />
                        </div>
                    </div>

                    <div className='ca-phone-holder'>
                        <div className="mobilePhoneText" >
                            <PhoneTextInput labelText="AccountSetupStart.labels.MobilePhone"
                                        value={mobilePhone} autoComplete="tel-national mobile"
                                        validationFailedText="AccountSetupStart.labels.InvalidPhone"
                                        onChange={handlePhoneNumberChange}
                                        isInline={true} />
                        </div>
                        <div className='phone-type'>
                            <PhoneTypeToggle name="PhoneType" value={phoneType} onStateChanged={handlePhoneTypeChange} />
                        </div>                                
                    </div>

                    <div>
                        <div style={{ marginLeft: 0, marginTop: 0 }}>
                            <div className="formLabelStyle">
                                {T.translate("AccountSetupStart.labels.Province")}
                            </div>
                        </div>
                        <div style={{ marginLeft: 0, width: layoutType === 'desktop' ? '80%' : '100%' }}>
                            <ProvinceDropDown name="province" value={province} onValueChanged={handleProvinceChanged} />
                        </div>
                    </div>                    
                    <div className="consentCheckboxStyle">
                        <CheckBox isChecked={termsAccepted} onCheckChanged={handleTermsCheck} />
                        <span style={{ marginLeft: 10 }}>
                            <span>{T.translate('AccountSetupLogin.labels.AcceptTerms1')}</span>
                            <b><a href={T.translate('Common.TermsLink')} target="_blank" rel="noopener noreferrer">{T.translate('AccountSetupLogin.labels.AcceptTerms2')}</a></b>
                            <span>{T.translate('AccountSetupLogin.labels.AcceptTerms3')}</span>
                        </span>
                    </div>
                    <div className="consentCheckboxStyle">
                        <CheckBox isChecked={consentChecked} onCheckChanged={handleConsentCheck} />
                        <span style={{ marginLeft: 10 }}>
                            <span>{T.translate('AccountSetupStart.labels.PrivacyPolicy', { PrivacyLink: privacyLink })}</span>
                        </span>
                    </div>
                </form>
            </div>
            <div className='reg-continue'>
                <PlainButton isEnabled={dataComplete} labelKey='Common.Continue' onClick={handleContinue} />
            </div>
            </Fragment>
    )
}

export default ReturningUpdate

