import React, { Component } from 'react';
import { connect } from 'react-redux'
import BaseModal from '../Modal/BaseModal';
import './style.css';
import T from 'i18n-react';
import UploaderTypeChoice from './uploaderTypeChoice'
import UploaderSMSContent from './uploaderSMSContent'
import UploaderSMSSent from './uploaderSMSSent'
import UploaderSMSFail from './uploaderSMSFail'
import UploaderDocsReminder from './uploaderDocsReminder'
import LoadingSpinner from '../Loading/loadingSpinner'
import DocumentsActions from '../../reducers/documentsRedux'

class UploaderModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploaderShowing: this.props.uploaderShowing,
        }
    }

    handleDialogClose = () => {
        if (this.props.dialogCloseHandler && this.state.uploaderShowing === true) {
            this.props.dialogCloseHandler();
        } else {
            this.setState({ uploaderShowing: false })
        }
    };

    handleUploaderChoice(choice) {
        if (this.props.uploadStateChangeHandler) {
            this.props.uploadStateChangeHandler(choice === 'desktop' ? 'box_uploader' : 'sms_start')
        }
    }

    handleSendSMS(ev) {
        if (this.props.uploadStateChangeHandler) {
            this.props.uploadStateChangeHandler('sms_sending')
        }
    }

    handleResendSMS(ev) {
        if (this.props.uploadStateChangeHandler) {
            this.props.uploadStateChangeHandler('sms_resending')
        }
    }

    handleBackToOptions() {
        if (this.props.uploadStateChangeHandler) {
            this.props.uploadStateChangeHandler('start')
        }
    }

    onOneTimeCode(ev) {
        this.props.setOneTimeCode(null, this.props.folderId);
    }

    render() {
        const isTouch = this.props.layoutType !== 'desktop'

        var content = null
        if (this.state.uploaderShowing) {
            if ((this.props.layoutType !== 'desktop' || this.props.uploaderState === 'box_uploader') && !this.props.accessToken) {
                content = (
                    <div className='modal_min_size'>
                        <LoadingSpinner />
                    </div>
                )
            } else if (this.props.uploaderState === 'box_uploader' || (isTouch && this.props.uploaderState !== 'docs_reminder')) {
                content = (
                    <div className={this.props.layoutType === 'mobile' ? 'mobile boxWrapper' : 'boxWrapper'}>
                        <div style={{ marginLeft: '20px', marginTop: '20px' }}>
                            <span className="boxHeader">{T.translate('Dashboard.buttonLabels.UploadDocuments')}</span>
                        </div>
                    </div>
                )
            } else if (this.props.uploaderState === 'start') {
                content = (
                    <UploaderTypeChoice layoutType={this.props.layoutType} lang={this.props.lang}
                        onDialogClose={(ev) => this.handleDialogClose(ev)}
                        uploadChoiceHandler={(choice) => this.handleUploaderChoice(choice)} />
                )
            } else if (this.props.uploaderState === 'sms_start' || this.props.uploaderState === 'sms_sending') {
                content = (
                    <UploaderSMSContent layoutType={this.props.layoutType} lang={this.props.lang}
                        mobilePhone={this.props.mobilePhone} uploaderState={this.props.uploaderState}
                        onOK={(ev) => this.handleSendSMS(ev)} onCancel={(ev) => this.handleBackToOptions(ev)}
                        onDialogClose={(ev) => this.handleDialogClose(ev)} folderId={this.props.folderId}
                        onOneTimeCode={(ev) => this.onOneTimeCode(ev)} />
                )
            } else if (this.props.uploaderState === 'sms_sent' || this.props.uploaderState === 'sms_resending'
                || this.props.uploaderState === 'sms_resent') {
                content = (
                    <UploaderSMSSent layoutType={this.props.layoutType} lang={this.props.lang}
                        mobilePhone={this.props.mobilePhone} uploaderState={this.props.uploaderState}
                        onOK={(ev) => this.handleDialogClose(ev)} onCancel={(ev) => this.handleResendSMS(ev)}
                        onDialogClose={(ev) => this.handleDialogClose(ev)}
                    />
                )
            } else if (this.props.uploaderState === 'sms_failed') {
                content = (
                    <UploaderSMSFail layoutType={this.props.layoutType} lang={this.props.lang}
                        mobilePhone={this.props.mobilePhone} uploaderState={this.props.uploaderState}
                        onOK={(ev) => this.handleDialogClose(ev)}
                        onDialogClose={(ev) => this.handleDialogClose(ev)}
                    />
                )
            } else if (this.props.uploaderState === 'docs_reminder') {
                content = (
                    <UploaderDocsReminder layoutType={this.props.layoutType} lang={this.props.lang}
                        onOK={(ev) => this.handleDialogClose(ev)}
                        onDialogClose={(ev) => this.handleDialogClose(ev)}
                    />
                )
            }
        } 
        return (
            <BaseModal show={content !== null} onClose={() => this.handleDialogClose()} layoutType={this.props.layoutType} >
                {content}
            </BaseModal>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        userBoxFolderId: state.documents.oneTimeUserBoxFolderId
    }
}

const mapDispatchToProps = (dispatch) => ({
    setOneTimeCode: (route, boxFolderId) => dispatch(DocumentsActions.setOneTimeCode(route, boxFolderId))
})

export default connect(mapStateToProps, mapDispatchToProps)(UploaderModal)