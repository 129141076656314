import React, { Fragment, useState, useEffect } from 'react';
import './style.css';
import T from 'i18n-react';
import classnames from 'classnames'
import FontAwesome from 'react-fontawesome';

const MessagesUnavailable = ({ hasPrevious, showDivider, layoutType }) => {
    const [phoneLink, setPhoneLink] = useState('');

    useEffect(() => {
        var phone = T.translate("Messages.Errors.Phone")
        
        var link = layoutType !== 'desktop'
            ? (<a href={'tel:' + (phone || '').replace(/[^0-9.]/g, '')}>
                {T.translate("Messages.Errors.PhoneLink")}
                </a>)
            : (<span>{phone}</span>)


        setPhoneLink(link);
    }, [layoutType])

    return (
        <div className={classnames("messagesUnavailable", { 'show-divider': showDivider})}>
            <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', align: 'center' }} />
            <div className='mu-inner'>
                {!hasPrevious &&
                    <span>{T.translate('Messages.Errors.NoTaxPro')}</span>
                }
                {hasPrevious && 
                    <Fragment>
                        <span>{T.translate('Messages.Errors.PrevNoTaxPro1')}</span>
                        <br/>
                    <span>{T.translate('Messages.Errors.PrevNoTaxPro2', { 'PhoneLink': phoneLink })}</span>
                    </Fragment>
                }
            </div>
        </div>
    )    
}



export default MessagesUnavailable
