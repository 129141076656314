import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    signForm: ['taxYear', 'formNames', 'isSpouse', 'completedRoute', 'spouseFirstName'],
    signExternalForm: ['taxYear', 'formNames', 'formUrls', 'isSpouse', 'completedRoute', 'spouseFirstName', 'primaryFirstName'],
    signAdditionalForm: ['taxYear', 'formName', 'isSpouse', 'completedRoute', 'spouseFirstName', 'primaryFirstName'],
    completeFormSigning: ['signingState'],
    setSigningError: ['signingError'],
    setSigningUri: ['signingUri', 'envelopeId', 'recipientId'],
    signingStateReset: null,
    signingStateDocumentsReset: null,
    signingStateLogoutReset: null,
    setSignedDocuments: ['signedDocuments'],
    processDocumentCallback: ['status'],
    checkSigningState: null
})

export const SigningTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    signingState: null,
    signingError: null,
    forms: [],
    externalUrls: [],
    isSpouse: false,
    isAdditional: false,
    signingUri: null,
    envelopeId: null,
    recipientId: null,
    signingCompleteRoute: null,
    signedDocuments: {
        self: {},
        spouse: {}
    },
    signingCallbackStatus: null,
    spouseFirstName: '',
    primaryFirstName: ''
}

/* ------------- Reducers ------------- */

export const signingSigningStateLogoutReset = (state) => INITIAL_STATE

// don't reset the signed documents until we do a full reset
export const signingSigningStateReset = (state) => {
    return Object.assign({}, INITIAL_STATE, { signedDocuments: state.signedDocuments })
}

export const signingSigningStateDocumentsReset = (state) =>
    Object.assign({}, state, {
        signedDocuments: {
            self: {},
            spouse: {}
        }
    })

export const signingSignForm = (state, { formNames, isSpouse, completedRoute, spouseFirstName }) =>
    Object.assign({}, state, {
        signingState: 'in_progress',
        forms: formNames,
        isSpouse: isSpouse,
        signingError: null,
        signingCallbackStatus: null,
        signingCompleteRoute: completedRoute,
        spouseFirstName: spouseFirstName,
        isAdditional: false
    })

export const signingSignExternalForm = (state, { formNames, formUrls, isSpouse, completedRoute, spouseFirstName, primaryFirstName }) =>
    Object.assign({}, state, {
        signingState: 'in_progress',
        forms: formNames,
        externalUrls: formUrls,
        isSpouse: isSpouse,
        signingError: null,
        signingCallbackStatus: null,
        signingCompleteRoute: completedRoute,
        spouseFirstName: spouseFirstName,
        isAdditional: false,
        primaryFirstName: primaryFirstName
    })

export const signingSignAdditionalForm = (state, { formName, isSpouse, completedRoute, spouseFirstName, primaryFirstName }) =>
    Object.assign({}, state, {
        signingState: 'in_progress',
        forms: [formName],
        isSpouse: isSpouse,
        signingError: null,
        signingCallbackStatus: null,
        signingCompleteRoute: completedRoute,
        spouseFirstName: spouseFirstName,
        isAdditional: true,
        primaryFirstName: primaryFirstName
    })


export const signingCompleteFormSigning = (state, { signingState }) => {
    var signingUri = null
    var envelopeId = null
    var recipientId = null

    if (signingState === 'processing') {
        signingUri = state.signingUri
        envelopeId = state.envelopeId
        recipientId = state.recipientId
    }

    return Object.assign({}, state, { signingState: signingState, signingUri: signingUri, envelopeId: envelopeId, recipientId: recipientId })
}

export const signingSetSigningError = (state, { signingError }) =>
    Object.assign({}, state, { signingError: signingError })

export const signingSetSigningUri = (state, { signingUri, envelopeId, recipientId }) =>
    Object.assign({}, state, { signingUri: signingUri, envelopeId: envelopeId, recipientId: recipientId })

export const signingSetSignedDocuments = (state, { signedDocuments }) =>
    Object.assign({}, state, { signedDocuments: signedDocuments })

export const signingProcessDocumentCallback = (state, { status }) =>
    Object.assign({}, state, { signingCallbackStatus: status })



/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.SIGN_FORM]: signingSignForm,
    [Types.SIGN_EXTERNAL_FORM]: signingSignExternalForm,
    [Types.SIGN_ADDITIONAL_FORM]: signingSignAdditionalForm,
    [Types.COMPLETE_FORM_SIGNING]: signingCompleteFormSigning,
    [Types.SET_SIGNING_ERROR]: signingSetSigningError,
    [Types.SET_SIGNING_URI]: signingSetSigningUri,
    [Types.SIGNING_STATE_RESET]: signingSigningStateReset,
    [Types.SIGNING_STATE_DOCUMENTS_RESET]: signingSigningStateDocumentsReset,
    [Types.SIGNING_STATE_LOGOUT_RESET]: signingSigningStateLogoutReset,
    [Types.SET_SIGNED_DOCUMENTS]: signingSetSignedDocuments,
    [Types.PROCESS_DOCUMENT_CALLBACK]: signingProcessDocumentCallback
})

