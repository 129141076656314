import apisauce from 'apisauce'
import { addErrorHandler, getApiBaseUrl } from './serviceUtils'

/**
 * API for setting and retrieving products data
 * @param {String} baseURL
 */
const create = (baseURL = getApiBaseUrl()) => {  // this will change for prod deployment
    // Create and configure an apisauce-based api object.
    const api = apisauce.create({
        baseURL,
        headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Content-Type': 'application/json' },
        timeout: 20000
    })

    addErrorHandler(api)

    const getAuthHeader = (accessToken) => {
        return {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        }
    }

    const savePOMSelection = (accessToken, action) => {
        var payload = {
            caseId: action.caseId,
            productName: action.productName,
            filerType: action.filerType,
            selection: action.selection
        }
        return api.post('product', payload, getAuthHeader(accessToken))
    }

    const getEligibleAddOnProducts = (accessToken, action) => {
        return api.get('case/' + action.caseId + '/eligableProductAddons', '', getAuthHeader(accessToken))
    }

    // Return back a collection of functions
    return {
        savePOMSelection, getEligibleAddOnProducts
    }
}

// let's return back our create method as the default.
export default {
    create
}