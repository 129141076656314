export const TaxYearTableConfig = [
    {
        columnId: 'tax_year',
        labelKey: 'Dox.DocumentExplorer.TaxYear',
        isDefaultSort: true,
        width: 12,
        isSortable: true
    }
]

export const FileExplorerFileTableConfig = [
    {
        columnId: 'name',
        labelKey: 'Dox.DocumentExplorer.Name',
        isDefaultSort: false,
        width: 5,
        isSortable: true
    },
    {
        columnId: 'size',
        labelKey: 'Dox.DocumentExplorer.Size',
        isDefaultSort: false,
        width: 1,
        isSortable: true
    },
    {
        columnId: 'modified',
        labelKey: 'Dox.DocumentExplorer.Modified',
        isDefaultSort: true,
        width: 3,
        isSortable: true
    },
    {
        columnId: 'uploader',
        labelKey: 'Dox.DocumentExplorer.Uploader',
        isDefaultSort: false,
        width: 2,
        isSortable: true
    },
    {
        columnId: 'manage',
        labelKey: 'Dox.DocumentExplorer.Manage',
        isDefaultSort: false,
        width: 1,
        isSortable: false
    }
]




