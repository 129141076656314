import React, { Component } from 'react';
import './footer.css';
import T from 'i18n-react';
import HenryLogo from '../../Common/henryLogo'

class FooterMobile extends Component {

    componentDidMount() {
        this.forceUpdate()
    }

    handleAction(action) {
        if (this.props.buttonHandler && this.props.appInitialized) {
            this.props.buttonHandler(action)
        }
    }

    render() {
        var currentYear = new Date().getFullYear();

        var accountContent = []
        if (this.props.loginComplete) {
            accountContent.push(
                <div key="settingsLink" className="blockLinkMobile" onClick={(ev) => this.handleAction('settings')}>
                    <span>{T.translate('HeaderMenuBar.labels.AccountSettings')}</span>
                </div>
            )
            accountContent.push(
                <div key="logoutLink" className="blockLinkMobile" onClick={(ev) => this.handleAction('logout')}>
                    <span>{T.translate('BottomMenuBar.labels.SaveAndQuit')}</span>
                </div>
            )
        } else {
            var signinStyle = "blockLinkMobile"
            if (!this.props.appInitialized) {
                signinStyle += " uninitialized"
            }
            accountContent.push(
                <div key="loginLink" className={signinStyle} onClick={(ev) => this.handleAction('login')}>
                    <span>{T.translate('Common.SignIn')}</span>
                </div>
            )
        }
        return (
            <div className="footerOuter">
                {this.props.showOteContent &&
                    <div className="footerTopMobile">
                        <HenryLogo lang={this.props.lang} darkText={true} width={"350px"} />
                        <div className="footerTopSpacerMobile">
                            {accountContent}
                            <div className="blockLinkMobile" onClick={() => this.handleAction('help')}><span>{T.translate('BottomMenuBar.labels.HelpCentre')}</span></div>
                            <div className="blockLinkMobile" onClick={() => this.handleAction('support')}><span>{T.translate('BottomMenuBar.labels.Contact')}</span></div>
                            <div className="blockLinkMobile" onClick={() => this.handleAction('survey')}><span>{T.translate('BottomMenuBar.labels.SendFeedback')}</span></div>
                        </div>
                    </div>
                }
                <div className="footerBottomMobile">
                    <div className="termsBlockMobile">
                        <div className="footerBottomLink" onClick={() => this.handleAction('terms')}>
                            <span className="white">{T.translate('BottomMenuBar.labels.TermsAndConditions')}</span>
                        </div>
                        <div><span className="white">&nbsp;|&nbsp;</span></div>
                        <div className="footerBottomLink" onClick={() => this.handleAction('privacy')}>
                            <span className="white">{T.translate('BottomMenuBar.labels.Privacy')}</span>
                        </div>
                    </div>
                    <div className="copyBlockMobile">
                        <span className="tiny white">{T.translate('BottomMenuBar.labels.CopyrightHRB', { TaxYear: currentYear })}</span>
                        <span className="tiny white">{T.translate('BottomMenuBar.labels.Reserved')}</span>
                    </div>
                   
                </div>
            </div>
        );
    }
}


export default FooterMobile