import React from 'react';
import T from 'i18n-react';
import PropTypes from 'prop-types'
import WorkflowSection from '../Section/WorkflowSection'
import '../Section/section.css'
import WorkflowButton from '../Section/WorkflowButton'
import { WorkflowButtonStates } from '../Section/WorkflowButtonStates'
import { useTicketType } from '../../../hooks/useTicketTypeHook'

const ReviewAndApproveReturnSection = ({ currentYear, taxState, onWorkflowButtonAction, isDirectUser }) => {
    
    const { ticketType } = useTicketType(taxState);

    return (
        ticketType.toLowerCase() === 'rte' ?
            <WorkflowSection titleKey={"WorkflowPanel.rteTaxReturnTitle"}  titleKeyProps={{ TaxYear: taxState.taxYear }}
                isAlternate={(currentYear - taxState.taxYear) % 2 === 1} comingUpKey={`WorkflowPanel.review-and-approve.coming-up.${!isDirectUser ? 'Ote' : 'Direct'}`}
                canClose={false} ticketType={ticketType} >         
                <WorkflowButton buttonKey="review-and-approve" name="review-and-approve" buttonState={WorkflowButtonStates.SHOW}
                    text={'WorkflowPanel.review-and-approve.title.Rte'} textProps={{ TaxYear: taxState.taxYear }} icon='fa-file'
                    onClick={() => onWorkflowButtonAction('review-and-approve', taxState.taxYear)} onFadeOut={() => { }} ticketType={ticketType}/>
                    <div style={{ paddingTop: 15, marginBottom: 15 }}>
                        <span>{T.translate(`WorkflowPanel.review-and-approve.rteSummary`, { TaxYear: taxState.taxYear })}</span>
                    </div>
            </WorkflowSection> 
            :
            <WorkflowSection titleKey={taxState.is_vir ? "WorkflowPanel.taxReturnTitleVIR" : "WorkflowPanel.taxReturnTitle"}  titleKeyProps={{ TaxYear: taxState.taxYear }}
                isAlternate={(currentYear - taxState.taxYear) % 2 === 1} comingUpKey={`WorkflowPanel.review-and-approve.coming-up.${!isDirectUser ? 'Ote' : 'Direct'}`}
                canClose={false} ticketType={ticketType} >         
                <WorkflowButton buttonKey="review-and-approve" name="review-and-approve" buttonState={WorkflowButtonStates.SHOW}
                    text={!isDirectUser ? 'WorkflowPanel.review-and-approve.title.Ote' : 'WorkflowPanel.review-and-approve.title.Direct'} textProps={{ TaxYear: taxState.taxYear }} icon='fa-file'
                    onClick={() => onWorkflowButtonAction('review-and-approve', taxState.taxYear)} onFadeOut={() => { }} ticketType={ticketType}/>
                {isDirectUser &&
                    <div style={{ paddingTop: 15, marginBottom: 15 }}>
                    <span>{T.translate(`WorkflowPanel.review-and-approve.direct-summary`, { TaxYear: taxState.taxYear })}</span>
                    </div>
                }
            </WorkflowSection>
    )
}

ReviewAndApproveReturnSection.propTypes = {
    taxState: PropTypes.shape({
        taxYear: PropTypes.number.isRequired,
        coupled: PropTypes.bool.isRequired,
        primary_province: PropTypes.string,
        primary_firstName: PropTypes.string.isRequired,
        spouse_province: PropTypes.string,
        spouse_firstName: PropTypes.string,
        subTotal: PropTypes.number,
        tenderTotal: PropTypes.number
    }),
    currentYear: PropTypes.number.isRequired,
    onWorkflowButtonAction: PropTypes.func.isRequired,
    isDirectUser: PropTypes.bool
}

export default ReviewAndApproveReturnSection;

