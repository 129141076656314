import React, { Component } from 'react';
import '../../Common/shared.css'
import '../style.css';

import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';
import YesNoQuestion from '../../Common/yesNoQuestion'
import MaritalStatusDropDown from '../../Controls/DropDowns/maritalStatusDropDown'
import DateTextInput from '../../Common/dateTextInput'
import LearnMore from '../../Controls/learnMore'
import HighlightedSection from '../highlightedSection'
import InterviewTitleProgress from '../../Common/interviewTitleProgress'

class InterviewPage4 extends Component {
    constructor(props) {
        super(props)

        var primary = this.props.primary || {
            maritalStatus: '',
            maritalStatusChange: '',
            previousMaritalStatus: '',
            maritalStatusChangeDate: '',
            spouseLiveApart: '',
            spouseTaxes: '',
            dependants: ''
        }

        this.state = {
            primary,
            missingRequiredField: this.props.missingRequiredField,
            layoutType: this.props.layoutType,
            lang: this.props.lang
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.primary !== newProps.primary) {
            this.setState({ primary: newProps.primary })
        }

        if (this.state.missingRequiredField !== newProps.missingRequiredField) {
            this.setState({ missingRequiredField: newProps.missingRequiredField })
        }

        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }

        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }
    }

    handleInterviewPrimaryTextChange = (event, value) => {
        let primary = {
            ...this.state.primary
        }
        primary[event.target.name] = value;
        this.setState({ primary });

        if (this.props.onPrimaryChanged) {
            this.props.onPrimaryChanged(primary)
        }
    };
             
    validateNameField(textValue) {
        return !textValue || textValue.match(/^[a-zA-Z\u00C0-\u017F\'\.\-\s]+$/)   // eslint-disable-line no-useless-escape
    }

    onYesNoButtonChange(ev, value) {
        var primary = {
            ...this.state.primary
        }
        primary[ev.target.name] = value
        this.setState({ primary })
        if (this.props.onPrimaryChanged) {
            this.props.onPrimaryChanged(primary)
        }
    }

    handleMaritalStatusChange(ev, value) {
        var primary = {
            ...this.state.primary,
        }
        primary[ev.target.name] = value

        if (ev.target.name === 'maritalStatus') {
            if ((value === 'S') || (value === 'D') || (value === 'SP') || (value === 'W')) {
                primary.spouseLiveApart = ''
                primary.spouseTaxes = ''
            }
        }

        var state = {
            ...this.state,
            primary,
        }

        this.setState(state);
        if (this.props.onPrimaryChanged) {
            this.props.onPrimaryChanged(primary)
        }
    }

    checkViewportWidth() {
        return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    }

    render() {       

        let validationContentMarital =
            <div className="validationErrorStyle">
                <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#E70000', align: 'center' }} />
                <span style={{ marginLeft: 10 }}>{T.translate('Common.Errors.SelectAnAnswer')}</span>
            </div>

        let missingMaritalStatus = this.state.missingRequiredField && !this.state.primary.maritalStatus
        let missingPreviousMaritalStatus = this.state.missingRequiredField && (this.state.primary.maritalStatusChange === 'Y' && !this.state.primary.previousMaritalStatus)
        let missingSpouseTaxes = this.state.missingRequiredField && !this.state.primary.spouseTaxes
        let missingMaritalStatusChange = this.state.missingRequiredField && !this.state.primary.maritalStatusChange
        let missingLiveApart = this.state.missingRequiredField && (this.state.primary.maritalStatus === 'M' || this.state.primary.maritalStatus === 'C') && !this.state.primary.spouseLiveApart
        let missingDependants = this.state.missingRequiredField && !this.state.primary.dependants

        var viewportWidth = this.checkViewportWidth()
        var prepareSpouseSuppress = false
        var titleNextContent = 'Interview.Page4.nextTitle1'
        
        if (this.state.layoutType === 'mobile' && this.state.lang === 'fr' && viewportWidth > 410) {
            prepareSpouseSuppress = true
        }

        let separateContent = null
        if (this.state.primary.maritalStatus === 'M' || this.state.primary.maritalStatus === 'C') {
            titleNextContent = 'Interview.Page4.nextTitle2'
            separateContent = (
                <div className="horizontalFieldContainerTopAligned largeTopMargin">
                    <YesNoQuestion name="spouseLiveApart" value={this.state.primary.spouseLiveApart}
                        layoutType={this.state.layoutType} missingRequiredField={missingLiveApart} isRequired={true}
                        labelText={T.translate("Interview.Page4.fieldLabel1", { TaxYear: this.props.taxYear })}
                        onAnswerChanged={(ev, value) => { this.onYesNoButtonChange(ev, value) }} />
                </div>
            )
        }

        let spouseTaxesContent = null
        if (this.state.primary.maritalStatus === 'M' || this.state.primary.maritalStatus === 'C') {
            spouseTaxesContent = (
                <div className="horizontalFieldContainerTopAligned largeTopMargin">
                    <YesNoQuestion name="spouseTaxes" value={this.state.primary.spouseTaxes}
                        isRequired={true} labelText={T.translate("Interview.Page4.fieldLabel2")}
                        learnMoreId="OTE_FAQ/OTEPreparingIndividualAndSpousalReturns" suppressSpacer={prepareSpouseSuppress}
                        layoutType={this.state.layoutType} missingRequiredField={missingSpouseTaxes}
                        onAnswerChanged={(ev, value) => { this.onYesNoButtonChange(ev, value) }} />
                </div>
            );
        }
        let previousMaritalChangeContent = null
        if (this.state.primary.maritalStatusChange === 'Y') {
            previousMaritalChangeContent = []
            previousMaritalChangeContent.push(
                <div key="lPrevMaritalStatus" className="horizontalFieldContainerTopAligned largeTopMargin">
                    <div className="fixed inlineLabelStyle">
                        <div className="formLabelStyleNoMargin">{T.translate("Interview.Page4.fieldLabel4") + '* '}</div>
                    </div>
                    <div className="defaultInputWidth">
                        <MaritalStatusDropDown name='previousMaritalStatus'
                            lang={this.state.lang}
                            value={this.state.primary.previousMaritalStatus}
                            missingRequiredField={missingPreviousMaritalStatus}
                            onValueChanged={(ev, value) => this.handleMaritalStatusChange(ev, value)} />
                        {missingPreviousMaritalStatus ? validationContentMarital : null}
                    </div>
                </div>
            )
            previousMaritalChangeContent.push(
                <div key="dMaritalStatusChange" className="horizontalFieldContainerTopAligned largeTopMargin">
                    <div className="fixed inlineLabelStyle">
                        <div className="formLabelStyleNoMargin">{T.translate("Interview.Page4.fieldLabel5") + '* '}</div>
                    </div>
                    <div className="defaultInputWidth">
                        <DateTextInput size="lg" validateMinAge={this.props.validateMinAge}  
                            name="maritalStatusChangeDate" autoComplete="off"
                            value={this.state.primary.maritalStatusChangeDate}
                            birthDate={ false }
                            onChange={(ev, value) => this.handleInterviewPrimaryTextChange(ev, value)}
                            missingRequiredField={this.state.missingRequiredField}
                            isRequired={true}                        />
                    </div>
                </div>
            )
        }

        
        var labelTextDeps = T.translate("Interview.Page4.fieldLabel6", { TaxYear: this.props.taxYear })
        var depsSuppressSpacer = false
        if (this.state.layoutType === 'mobile' && this.state.lang === 'en') {
            if (viewportWidth < 410) {
                // hack to fix word wrapping
                labelTextDeps = labelTextDeps.replace('dependants', '\r\ndependants')
            } else {
                depsSuppressSpacer = true
            }
        } else if (this.state.layoutType === 'tablet' || this.state.layoutType === 'small_tablet') {
            depsSuppressSpacer = false
        } else {
            depsSuppressSpacer = true;
        }

        var maritalStatusText1 = T.translate("Interview.Page4.fieldLabel0", { TaxYear: this.props.taxYear }) + '* '
        var maritalStatusText2 = null
        if (this.state.layoutType !== 'desktop' && (this.state.lang === 'en' || (this.state.lang === 'fr' && viewportWidth > 410))) {
            maritalStatusText1 = maritalStatusText1.replace('' + this.props.taxYear + '?* ', '')
            maritalStatusText2 = '' + this.props.taxYear + '?* '
        }

        var maritalContent = []
        maritalContent.push(<span key='ms1'>{maritalStatusText1}</span>)
        if (maritalStatusText2) {
            maritalContent.push(<br key='msspacer' />)
            maritalContent.push(<span key='ms2'>{maritalStatusText2}</span>)
        }
        maritalContent.push(<span key='ms3' style={{marginRight: '10px'}} />)
        maritalContent.push(<LearnMore key='lm' linkId="OTE_FAQ/OTEWhatWasYourMartialStatusOnDecember31" />)

        if ((this.state.primary.dependants === 'Y') && (this.state.primary.maritalStatus === 'S' || this.state.primary.maritalStatus === 'W' || this.state.primary.maritalStatus === 'D' || this.state.primary.maritalStatus === 'SP')) {
            titleNextContent = 'Interview.Page4.nextTitle3'
        }

        return (
            <div>
                <div>
                    <div>
                        <InterviewTitleProgress taxYear={this.props.taxYear} percentage="54" title="Interview.Page4.headerTitle" nextTitle={titleNextContent} />
                    </div>                      
                    <div style={{ height: 20 }} />
                </div>
                <div className="interview-page4-top-margin">
                <div className="horizontalFieldContainerTopAligned">
                    <div className="fixed inlineLabelStyle">
                        <div className="formLabelStyleNoMargin">
                            <span>
                                {maritalContent}
                            </span>
                        </div>
                    </div>
                    <div className="defaultInputWidth">
                        <MaritalStatusDropDown name='maritalStatus' 
                            lang={this.state.lang}
                            missingRequiredField={missingMaritalStatus}
                            value={this.state.primary.maritalStatus} onValueChanged={(ev, value) => this.handleMaritalStatusChange(ev, value)} />
                            {missingMaritalStatus ? validationContentMarital : null}
                    </div>
                </div>
                {this.props.isReturningClient && 
                    <div style={{margin: '10px'}} />
                }
                <HighlightedSection isReturningClient={this.props.isReturningClient} margin={20}>
                    {separateContent}
                    {spouseTaxesContent}
                    <div className="horizontalFieldContainerTopAligned largeTopMargin">
                        <YesNoQuestion name="maritalStatusChange" value={this.state.primary.maritalStatusChange}
                            labelText={T.translate("Interview.Page4.fieldLabel3", { TaxYear: this.props.taxYear })}
                            learnMoreId="OTE_FAQ/OTEDidYourMartialStatusChange"
                            layoutType={this.state.layoutType} missingRequiredField={missingMaritalStatusChange}
                            isRequired={true} onAnswerChanged={(ev, value) => { this.onYesNoButtonChange(ev, value) }} />
                    </div>                
                    {previousMaritalChangeContent}
                    <div className="horizontalFieldContainerTopAligned largeTopMargin">
                        <YesNoQuestion name="dependants" value={this.state.primary.dependants}
                            labelText={labelTextDeps}
                            learnMoreId="Parent_Dependants/DoYouHaveDependants" suppressSpacer={depsSuppressSpacer}
                            layoutType={this.state.layoutType} isRequired={true} missingRequiredField={missingDependants}
                            onAnswerChanged={(ev, value) => { this.onYesNoButtonChange(ev, value) }} />
                    </div>
                    </HighlightedSection>
                </div>
            </div>
        )
    }
}

export default InterviewPage4;