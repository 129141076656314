import React from 'react';
import PropTypes from 'prop-types'
import WorkflowSection from '../Section/WorkflowSection'
import '../Section/section.css'
import WorkflowButton from '../Section/WorkflowButton'
import { WorkflowButtonStates } from '../Section/WorkflowButtonStates'
import { useTicketType } from '../../../hooks/useTicketTypeHook';

const InterviewReturnSection = ({ currentYear, taxState, onWorkflowButtonAction }) => {
    var pageNumber = taxState.use_mini_interview ? '0' : taxState.interview_state.current_page
    let { ticketType } = useTicketType(taxState)

    return (
        <WorkflowSection titleKey='WorkflowPanel.taxReturnTitle' titleKeyProps={{ TaxYear: taxState.taxYear }} canClose={false}
            isAlternate={(currentYear - taxState.taxYear) % 2 === 1} onClose={() => onWorkflowButtonAction('delete-return', taxState.taxYear)}
            ticketType={taxState.ticket_type}>
            <WorkflowButton
                buttonKey="interview"
                name="interview"
                buttonState={WorkflowButtonStates.SHOW} icon="fa-comment"
                text='WorkflowPanel.interview.title'
                textProps={{ TaxYear: taxState.taxYear }}
                ticketType={ticketType}
                onClick={() => onWorkflowButtonAction('interview', taxState.taxYear, pageNumber)}
                onFadeOut={() => { }} />                        
        </WorkflowSection>
    )
}

InterviewReturnSection.propTypes = {
    taxState: PropTypes.shape({
        taxYear: PropTypes.number.isRequired,
        state: PropTypes.string,
        coupled: PropTypes.bool.isRequired,
        primary_province: PropTypes.string,
        primary_firstName: PropTypes.string.isRequired,
        spouse_province: PropTypes.string,
        spouse_firstName: PropTypes.string,
        subTotal: PropTypes.number,
        tenderTotal: PropTypes.number,
        use_mini_interview: PropTypes.bool,
        interview_state: PropTypes.shape({
            complete: PropTypes.bool.isRequired,
            current_page: PropTypes.string.isRequired
        }).isRequired
    }),
    currentYear: PropTypes.number.isRequired,
    onWorkflowButtonAction: PropTypes.func.isRequired
}

export default InterviewReturnSection;

