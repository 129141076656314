import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import 'react-contexify/dist/ReactContexify.css'
import '../dox_explorer.css'
import DotDotDotIcon from '../graphics/dotdotdotIcon'
import { Menu, Item, useContextMenu, animation } from 'react-contexify'
import { useTranslationContext } from '../../Shared/hooks/useTranslationContextHook'

const DoxExplorerMobileSortMenu = ({ onSelectSort }) => {
    const translateString = useTranslationContext()

    const MENU_ID = 'doxe-mobile-sort-menu'    
    const { show } = useContextMenu({ id: MENU_ID })

    const [selectedSort, setSelectedSort] = useState(null)

    const handleContextMenu = (ev) => {
        ev.preventDefault()
        show(ev, { position: { x: ev.clientX - 150, y: ev.clientY } })
    }

    const handleItemClick = (sortColumn) => {
        setSelectedSort(sortColumn)
        onSelectSort(sortColumn)
    }

    return (
        <Fragment>
            <div className='hrb-column hrb-is-1 doxe-centered' onClick={handleContextMenu} >
                <DotDotDotIcon color='#007C10' />
            </div>
            <Menu id={MENU_ID} animation={animation.fade} theme='doxe'>
                <Item className='doxe-menu-header' disabled>{translateString('Dox.DocumentExplorer.OrderBy')}</Item>
                <Item onClick={() => handleItemClick('name')}>
                    <div className={classnames('doxe-menu-item', { 'doxe-menu-selected': 'name' === selectedSort })}>
                        {translateString('Dox.DocumentExplorer.Name')}
                    </div>
                </Item>
                <Item onClick={() => handleItemClick('size')}>
                    <div className={classnames('doxe-menu-item', { 'doxe-menu-selected': 'size' === selectedSort })}>
                        {translateString('Dox.DocumentExplorer.Size')}
                    </div>
                </Item>
                <Item onClick={() => handleItemClick('modified')}>
                    <div className={classnames('doxe-menu-item', { 'doxe-menu-selected': !selectedSort || 'modified' === selectedSort })}>
                        {translateString('Dox.DocumentExplorer.Modified')}
                    </div>
                </Item>
                <Item onClick={() => handleItemClick('uploader')}>
                    <div className={classnames('doxe-menu-item', { 'doxe-menu-selected': 'uploader' === selectedSort })}>
                        {translateString('Dox.DocumentExplorer.Uploader')}
                    </div>
                </Item>
            </Menu>
        </Fragment>
    )
}

DoxExplorerMobileSortMenu.propTypes = {
    onSelectSort: PropTypes.func.isRequired
}

export default DoxExplorerMobileSortMenu;