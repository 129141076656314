import React, { Component } from 'react';
import './shared.css';
import T from 'i18n-react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

class InterviewTitleProgress extends Component {
    
    render() {
        var backgroundColor = 'FFFFFF'
        var textColor = '333333'
        if (this.props.percentage === '100') {
            backgroundColor = '7FBA00'
            textColor = 'FFFFFF'
        }            
        
        var taxYearText = this.props.taxYear + ':'
        var nextTitle = T.translate('Interview.Next') + T.translate(this.props.nextTitle)
        if (this.props.noNextTitle === 'true') { nextTitle = '' }

        var title = T.translate(this.props.title, {FirstName: this.props.firstName});

        return (
            <div className="interview-title-progress" >
                <div className="interview-circular-progress-section">
                    <div className="interview-circular-progress">
                        <CircularProgressbar value={this.props.percentage} text={`${this.props.percentage}%`} background="true"
                            styles={buildStyles({
                                backgroundColor: backgroundColor,
                                pathColor: "7FBA00",
                                textColor: textColor
                            })} strokeWidth={5} />
                    </div>
                </div>
                <div className="interview-title-divider">
                    <hr width="1" size="100" style={{ backgroundColor: '#A9A9A9' }} />
                </div>
                <div className="interview-text-progress">
                    <T.text tag='h1' text={taxYearText} style={{ margin: '1px', textAlign: 'left' }} />
                    <T.text tag='h1' text={title} style={{ margin: '1px', textAlign: 'left' }} />
                    <T.text tag='h2' text={nextTitle} style={{ margin: '1px', textAlign: 'left' }} />
                </div>               
            </div>  
        )
    }
}

export default InterviewTitleProgress;