import React, { Component } from 'react';
import ConfirmIdentityButton from './confirmIdentityButton'
import './style.css';
import T from 'i18n-react';
import { HeapEvents, trackEvent } from '../../utils/AnalyticsUtil'

class ConfirmIdUserSummaryFooter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lang: this.props.lang,
            layoutType: this.props.layoutType,
            skipValidationChecked: this.props.skipValidationChecked,
            dataComplete: this.props.dataComplete,
            onContinue: this.props.onContinue,
            onSkipValidationChecked: this.props.onSkipValidationChecked,
            isVir: this.props.isVir
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }

        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }

        if (this.state.dataComplete !== newProps.dataComplete) {
            this.setState({ dataComplete: newProps.dataComplete })
        }

        if (this.state.onContinue !== newProps.onContinue) {
            this.setState({ onContinue: newProps.onContinue })
        }

        if (this.state.isVir !== newProps.isVir) {
            this.setState({ isVir: newProps.isVir })
        }
    }

    handleContinueClick(ev) {
        trackEvent(HeapEvents.BUTTON_CONFIRMID_CONTINUE);
        if (this.state.onContinue) {
            this.state.onContinue(ev);
        }
    }

    render() {
        return (
            <div className="confirmIdSummaryFooterContainer">
                <div className="confirmSummaryFooterContinueRow">
                    <div className="ConfirmIdFooterTerms">
                    <span dangerouslySetInnerHTML={{
                        __html: T.translate("ConfirmIdentity.Summary.FooterConsent", {
                        trulioPrivacyPolicy: `<a href='https://www.trulioo.com/services-privacy-policy' target='_blank'>${T.translate("ConfirmIdentity.Summary.PrivacyPolicy")}</a>`,
                        trulioFacialScanBiometric: `<a href='https://www.trulioo.com/biometric-information-policy' target='_blank'>${T.translate("ConfirmIdentity.Summary.FacialScanBioInfoPolicy")}</a>`
                        })
                    }}>
                    </span>
                    </div>
                    <div className="confirmSummaryFooterButtonContainer">
                        <ConfirmIdentityButton labelKey='Common.Continue' isEnabled={this.state.onContinue && this.state.dataComplete} onClick={(ev) => this.handleContinueClick(ev)}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfirmIdUserSummaryFooter