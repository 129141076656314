/* eslint-disable-next-line */
import { useState, useEffect } from "react"

const useDuplicateDetection = (file, existingFiles) => {

    const [hasCheckedForDupes, setHasCheckedForDupes] = useState(false)
    
    const [duplicateFileId, setDuplicateFileId] = useState(null)
    const [suffixedFileName, setSuffixedFileName] = useState(false)

    const checkForDuplicate = (fileName) => {
        let duplicateFile = null

        // check for any name collision
        existingFiles.forEach(f => {
            if (fileName.toLowerCase() === `${f.fileName}${f.fileType}`.toLowerCase()) {
                duplicateFile = f
            }
        })
        return duplicateFile
    }

    const getNewSuffixedFileName = (fileName, fileType) => {
        let newSuffix = 1
        let conflict = true
        let newName = fileName

        while (conflict) {
            newName = `${fileName} (${newSuffix})${fileType}`
            if (!checkForDuplicate(newName)) {
                conflict = false
                break;
            }
            newSuffix++;
        }

        setSuffixedFileName(newName)
    }

    useEffect(() => {
        if (existingFiles && !hasCheckedForDupes) {
            // we have preloaded the file list for this tax year
            let duplicate = checkForDuplicate(file.name)
           
            if (duplicate) {
                setDuplicateFileId(duplicate.documentId)
                getNewSuffixedFileName(duplicate.fileName, duplicate.fileType)
            } else {
                setDuplicateFileId(null)
                setSuffixedFileName(null)
            }

            setHasCheckedForDupes(true)
        }
    }, [Boolean(existingFiles), file.name])

    return { hasCheckedForDupes, duplicateFileId, suffixedFileName }
}

export default useDuplicateDetection