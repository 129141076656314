import { AcceptedFileExts, AcceptedMimeTypes } from '../../Shared/resources/DoxFileTypes'

/* Utility class used to deal with downloaded files */
class FileDownloadUtil {

    base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    getMimeType = (fileType) => {
        let idx = AcceptedFileExts.indexOf((fileType || '').toLowerCase())
        if (idx !== -1) {
            return AcceptedMimeTypes[idx]
        }
        return 'application/pdf'
    }

    // parse binary response data into fancy blob
    makeBlobLink = (base64, fileType) => {
        let arrBuffer = this.base64ToArrayBuffer(base64);

        // hacky hack: make object uri from blob (yuck, but it works)
        var mimeType = this.getMimeType(fileType);

        let blob = new Blob([arrBuffer], { type: mimeType })
        return URL.createObjectURL(blob)
    }

    downloadFile = (fileLink, fileName, openInTab) => {
        let link = document.createElement('a');

        // javascript DOM magic
        link.href = fileLink
        link.target = ('_blank')
        if (!openInTab) {
            // if we're not showing it in a new tab, just 
            // ask the browser to download it directly.
            link.download = fileName
        }
        document.body.append(link)
        link.click()
        link.remove()

        // in case the Blob uses a lot of memory
        window.addEventListener('focus', () => URL.revokeObjectURL(link.href), { once: true })
    };
}

const fileDownloadUtil = new FileDownloadUtil();
export default fileDownloadUtil;
