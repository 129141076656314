import React from 'react'
import PropTypes from 'prop-types'
import '../dox_explorer.css'
import { useTranslationContext } from '../../Shared/hooks/useTranslationContextHook'

const DoxExplorerTableMessage = (props) => {
    const translateString = useTranslationContext()
    return (
        <div className='doxe-files-table'>
            <div className='doxe-msg'>
                {translateString(props.labelKey)}
            </div>
        </div>
    )
}

DoxExplorerTableMessage.propTypes = {
    labelKey: PropTypes.string.isRequired    
}   

export default DoxExplorerTableMessage;