import queryString from 'simple-query-string';

class UrlParamsUtil {

  static getQueryParamsFromUrl = (props) => {
    if (props.location) {
      return UrlParamsUtil.getQueryParams(props.location.search);
    }
    return {};
  };

  static getQueryParams = (searchString) => {
    if (searchString) {
      let hashStr = searchString;
      hashStr = hashStr.replace(/^#?\/?/, '');

      return queryString.parse(hashStr);
    }
    return {};
  };
}

export default UrlParamsUtil;