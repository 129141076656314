import React from 'react'
import PropTypes from 'prop-types'
import './skeleton.css'

const Skeleton = props => {

    const style = {
        width: props.width || '100%',
        height: props.height || 'auto'
    }

    return (<div className='skeleton' style={style}></div>)
}

Skeleton.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Skeleton