import React, { Component } from 'react';
import { connect } from 'react-redux'
import T from 'i18n-react';
import LayoutActions from '../../../reducers/layoutRedux'

class HeaderTabControl extends Component {    

    handleTabClick(tab) {
        this.props.setDashboardTab(tab)
    }

    render() {
        var currTab = this.props.dashboardTab

        var tabContainerStyle = 'tabContainerStyle'
        var dashboardStyle = 'tabStyle' + (currTab === 'dashboard' ? ' tabSelected' : '')
        var messagesStyle = 'tabStyle' + (currTab === 'messages' ? ' tabSelected' : '')
        var documentsStyle = 'tabStyle' + (currTab === 'documents' ? ' tabSelected' : '')

        var unreadMessageIndicator = null
        if (this.props.unreadMessagesCount) {
            unreadMessageIndicator = (<span className={this.props.unreadMessagesCount > 0 ? 'messagesCircleHeader' : 'noMessagesCircle'}>{this.props.unreadMessagesCount}</span>)
        }

        return (
            <div className={tabContainerStyle}>
                <div className={dashboardStyle} onClick={(tab) => this.handleTabClick('dashboard')}>
                    <span>{T.translate('HeaderMenuBar.tabs.dashboard')}</span>
                </div>
                <div className={messagesStyle} onClick={(tab) => this.handleTabClick('messages')}>
                    <span>{T.translate('HeaderMenuBar.tabs.messages')}</span>
                    {unreadMessageIndicator}
                </div>
                <div className={documentsStyle} onClick={(tab) => this.handleTabClick('documents')}>
                    <span>{T.translate('HeaderMenuBar.tabs.documents')}</span>
                </div>
            </div>
        )        
    }
}

const mapStateToProps = (state) => {
    return {
        dashboardTab: state.layout.dashboardTab,
        unreadMessagesCount: state.messages.unreadMessagesCount
    }
}

const mapDispatchToProps = (dispatch) => ({
    setDashboardTab: (tab) => dispatch(LayoutActions.setDashboardTab(tab))
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTabControl)
