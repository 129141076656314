import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import '../dox_explorer.css'
import doxExplorerCDMServices from '../../Shared/services/doxExplorerCDMServices'
import { DoxFileDownloadStatus, DoxFileOperationStatus } from '../services/doxExplorerConsts'
import { useTranslationContext } from '../../Shared/hooks/useTranslationContextHook'

const DoxExplorerFilesTableFileCell = (props) => {
    const translateString = useTranslationContext();
    const [errorKey, setErrorKey] = useState(null)

    const handleOpen = () => {
        props.onSelectItem(props.documentId)
    }

    useEffect(() => {
        let ek = null
        if (props.errorStatus === DoxFileDownloadStatus.FAIL_TRANSIENT) {
            ek = 'Dox.DocumentExplorer.Errors.Transient'
        } else if (props.errorStatus === DoxFileDownloadStatus.FAIL_NO_SUCH_FILE) {
            ek = 'Dox.DocumentExplorer.Errors.Removed'
        } else if (props.errorStatus === DoxFileOperationStatus.FAIL_DELETE_HRB_FILE) {
            ek = 'Dox.DocumentExplorer.Errors.DeleteHRBFile'
        } else if (props.errorStatus === DoxFileOperationStatus.FAIL_RENAME_HRB_FILE) {
            ek = 'Dox.DocumentExplorer.Errors.RenameHRBFile'
        } 
        if (errorKey !== ek) {
            setErrorKey(ek)
        }
    }, [props.errorStatus, setErrorKey, errorKey]);
    
    return (
        <div className={classnames('doxe-files-table-cell hrb-column', `hrb-is-${props.width}`)}
            onClick={handleOpen}>
            {props.errorStatus !== DoxFileDownloadStatus.FAIL_NO_SUCH_FILE &&
                <div className='dox-link'>
                    <span>{doxExplorerCDMServices.getTruncatedFileName(props.fileName, props.fileType, false)}</span>
                </div>
            }
            {errorKey &&
                <div className='doxe-red'>
                    <b>{translateString(errorKey)}</b>
                </div>
            }
        </div>
    )
}

DoxExplorerFilesTableFileCell.propTypes = {    
    documentId: PropTypes.string.isRequired,
    fileType: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    onSelectItem: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    errorStatus: PropTypes.string
}   

export default DoxExplorerFilesTableFileCell;