import React from 'react';
import './style.css';
import T from 'i18n-react';
import RQAuthIcon from '../../images/RQAuthIcon.png'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'

const AuthorizeRqSelectCtrl = () => {

    return (
        <div className='auth-rq-select-wrapper'>
            <Card variant='outlined' className='auth-rq-card'>
                <CardContent className='auth-rq-card-content'>
                    <div className='auth-rq-select-icon'>
                        <img src={RQAuthIcon} />
                    </div>
                    <div className='auth-rq-select-content'>
                        <div className='auth-rq-title'>
                            {T.translate('Authorize.RQTitle')}
                        </div>
                        <div className='auth-rq-content'>
                            {T.translate('Authorize.RQSubtext')}
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

export default AuthorizeRqSelectCtrl