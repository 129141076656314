import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    setBoxDialogStates: ['uploaderShowing', 'viewerShowing', 'fileShowing'],
    setUploaderState: ['uploaderShowing', 'uploaderState', 'route',],
    setBoxCredentials: ['accessToken', 'userBoxFolderId'],
    setBoxFolder: ['userBoxFolderId'],
    setFilesForReview: ['files'],
    setApprovalForms: ['files'],
    setFileWasUploaded: ['taxYear'],
    setFinishedUploading: ['taxYear'],
    reset: null,
    processDocumentCallback: ['status'],
    setFileToBeRenamed: ['fileId'],
    loadCompletedDocs: ['taxYear'],
    setUploadStepCompleted: ['taxYear', 'isComplete'],
    sendReviewCompleteMessage: ['caseId'],
    sendReviewCompleteMessageWetSignature: ['caseId'],
    setOneTimeCode: ['route', 'boxFolderId'],
    getSuggestions: ['taxYear'],
    setSuggestions: ['suggestedDocs']
})

export const DocumentsTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    uploaderShowing: false,
    uploaderState: null,
    viewerShowing: false,
    fileShowing: false,
    accessToken: '',
    userBoxFolderId: null,
    oneTimeUserBoxFolderId: null,
    fileToBeRenamed: null,
    filesForReview: {
        primaryFederal: null,
        primaryQuebec: null,
        spouseFederal: null,
        spouseQuebec: null
    },
    approvalForms: {
        primaryFederal: null,
        primaryQuebec: null,
        spouseFederal: null,
        spouseQuebec: null
    },    
    clickedFile: null,
    fileWasUploaded: false,
    route: null,
    suggestedDocs: []
}

/* ------------- Reducers ------------- */
export const docsCloneDocs = (docs) => {
    var d = {
        ...docs,
        uploaderShowing: docs.uploaderShowing,
        viewerShowing: docs.viewerShowing,
        fileShowing: docs.fileShowing,
        accessToken: docs.accessToken,
        expiryTime: docs.expiryTime,
        boxFolderId: docs.boxFolderId,
        filesForReview: { ...docs.filesForReview }
    }
    return d
}

export const docsSetBoxDialogStates = (state, { uploaderShowing, viewerShowing, fileShowing }) => {
    var mergeObj = {
        uploaderShowing: uploaderShowing,
        viewerShowing: viewerShowing,
        fileShowing: fileShowing
    }

    // we're showing the uploader again, reset the file was uploaded flag
    if (!state.uploaderShowing && uploaderShowing) {
        mergeObj.fileWasUploaded = false
    }
    return Object.assign({}, state, mergeObj)
}

export const docsSetBoxCredentials = (state, { accessToken, userBoxFolderId }) =>
    Object.assign({}, state, { accessToken: accessToken, userBoxFolderId: userBoxFolderId })

export const docsSetBoxFolder = (state, { userBoxFolderId }) =>
    Object.assign({}, state, { oneTimeUserBoxFolderId: userBoxFolderId })

export const docsSetFilesForReview = (state, { files }) =>
    Object.assign({}, state, { filesForReview: files })

export const docsSetApprovalForms = (state, { files }) =>
    Object.assign({}, state, { approvalForms: files })

export const docsSetUploaderState = (state, { uploaderShowing, uploaderState, route }) => {
    var mergeObj = {
        uploaderShowing: uploaderShowing,
        uploaderState: uploaderState,
        route: route
    }
    if (uploaderShowing) {
        mergeObj.viewerShowing = false
        mergeObj.fileShowing = false
    }
    if (uploaderShowing && !state.uploaderShowing) {
        mergeObj.fileWasUploaded = false
    }
    return Object.assign({}, state, mergeObj);
}

export const docsSetFileWasUploaded = (state) =>
    Object.assign({}, state, { fileWasUploaded: true })

export const docsReset = (state) => INITIAL_STATE

export const docsSetFileToBeRenamed = (state, { fileId }) =>
    Object.assign({}, state, { fileToBeRenamed: fileId })

export const docsSetOneTimeCode = (state, { route, boxFolderId }) => {
    return Object.assign({}, state, { route: route, userBoxFolderId: boxFolderId })
}

export const docsSetSuggestions = (state, { suggestedDocs }) => {
    return Object.assign({}, state, {
        suggestedDocs: suggestedDocs
    })
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_BOX_DIALOG_STATES]: docsSetBoxDialogStates,  
    [Types.SET_BOX_CREDENTIALS]: docsSetBoxCredentials,
    [Types.SET_BOX_FOLDER]: docsSetBoxFolder,
    [Types.SET_FILES_FOR_REVIEW]: docsSetFilesForReview,
    [Types.SET_APPROVAL_FORMS]: docsSetApprovalForms,
    [Types.SET_UPLOADER_STATE]: docsSetUploaderState,
    [Types.SET_FILE_WAS_UPLOADED]: docsSetFileWasUploaded,
    [Types.RESET]: docsReset,
    [Types.SET_FILE_TO_BE_RENAMED]: docsSetFileToBeRenamed,
    [Types.SET_ONE_TIME_CODE]: docsSetOneTimeCode,
    [Types.SET_SUGGESTIONS]: docsSetSuggestions
})

