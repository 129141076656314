import { push, replace } from 'connected-react-router'
import { call, put, select } from 'redux-saga/effects'
import AccountDataActions from '../reducers/accountDataRedux'
import AuthActions from '../reducers/authRedux'
import LoginActions from '../reducers/loginRedux'
import RegisterActions from '../reducers/registerRedux'
import WorkflowActions from '../reducers/workflowRedux'
import { HeapEvents, identifyUser, trackEvent } from '../utils/AnalyticsUtil'
import auth from './SSO/Auth'

export const getAuth = state => state.auth
export const getRegister = state => state.register
export const getAccountData = state => state.accountData
export const getLanguage = state => state.layout.lang
export const getLogin = state => state.login
export const getCASConfig = state => state.config.CASConfig;

export function parseAccountData(data) {
    var accountData = null
    if (data.account_id) {
        var t = new Date()
        t.setSeconds(t.getSeconds() + data.accessTokenExpirySeconds)
        accountData = {
            firstName: data.first_name,
            email: data.email,
            accountId: data.account_id,
            accessToken: data.access_token,
            accessTokenExpiryDate: t
        }
        if (data.last_name) {
            accountData.lastName = data.last_name
        }
        if (data.phone_number) {
            accountData.mobilePhone = data.phone_number
        }

    }
    return accountData
}

function* lookupUser(api, isRetry = false) {
    let accountData = yield select(getAccountData)

    var response = yield call(api.checkForExistingAccount, accountData.email)
    if (response && response.ok && response.data) {
        if (response.data.userExists === true) {
            yield call(onCreateAccount, api, null, response.data.accountId)
        } else {
            yield put(push('/register/secure'))
        }
    } else {
        if (!isRetry) {
            yield call(lookupUser, api, true)
        } else {
            yield put(push('/register/error'))
            yield put(RegisterActions.setStep('lookup_failure'))
        }
    }
}

function* onAccountSignIn(api, password) {
    let accountData = yield select(getAccountData)
    let register = yield select(getRegister)
    var response = yield call(api.accountSignIn, accountData.email, register.credentialState.password)
    if (response && response.ok) {
        if (response.data.account_fully_created === false) {
            yield put(AccountDataActions.setEmail(response.data.email))
            if (response.data.phone_number) {
                yield put(AccountDataActions.setMobilePhone(response.data.phone_number))
            }
            if (response.data.phone_type) {
                yield put(AccountDataActions.setPhoneType(response.data.phone_type))
            }
            var t = new Date()
            t.setSeconds(t.getSeconds() + response.data.accessTokenExpirySeconds)
            yield put(AuthActions.setAccessToken(response.data.access_token, t))

            // login was ok but this user doesn't have the VA role, so add it now
            response = yield call(api.addvarole, response.data.access_token, response.data.email, register.credentialState.password)
            if (!response || !response.ok) {
                yield put(RegisterActions.setStep('sign_in_failure'))
                return
            }
        }

        // workflow data - we shouldn't need this, as it should be default,
        //                 but keep it just in case the session state hasn't been reset.
        if (response.data.workflow) {
            yield put(WorkflowActions.setWorkflow(response.data.workflow))
        }
        // now parse the login info
        var data = parseAccountData(response.data)
        if (data) {
            yield put(AccountDataActions.setAccountId(data.accountId))
            yield put(AccountDataActions.setFirstName(data.firstName))
            if (data.lastName) {
                yield put(AccountDataActions.setLastName(data.lastName))
            }
            if (data.mobilePhone) {
                yield put(AccountDataActions.setMobilePhone(data.mobilePhone))
            }

            yield put(AccountDataActions.setEmail(data.email))
            yield put(AuthActions.setAccessToken(data.accessToken, data.accessTokenExpiryDate))

            if (response.data.case_id) {
                // existing case, this is a simple login
                yield put(RegisterActions.resetRegisterState())
                yield put(replace('/'))
                yield put(AuthActions.userDidLogin())
            } else {
                // no existing case, let's create one
                yield put(RegisterActions.setStepWithMessage('create_account_with_id', 'AccountSetupProgress.statusMessages.Creating'))
            }
        } else {
            // no existing case, let's create one
            yield put(RegisterActions.setStep('sign_in_failure'))
        }
    } else {
        if (response.status === 401) {
            var attemptsRemaining = response.data.newAttemptsRemaining;
            yield put(LoginActions.setRemainingAttempts(attemptsRemaining, false))
            yield put(RegisterActions.setError('signin'))
            yield put(RegisterActions.setStep('login'))
        } else if (response.status === 403) { //account is locked (20 mins)                
            yield put(LoginActions.setRemainingAttempts(0, true))
            yield put(RegisterActions.setError('signin'))
            yield put(RegisterActions.setStep('login'))
        } else {
            yield put(RegisterActions.setStep('sign_in_failure'))
        }
    }
}

function* onCreateAccount(api, creds, accountId) {
    let accountData = yield select(getAccountData)
    let authData = yield select(getAuth)
    let lang = yield select(getLanguage)

    yield put(push('/register/working'))
    var response = yield call(api.createNewAccount, accountId, accountData, creds, lang, authData.accessToken)

    var CASConfig = null, idToken = null, expires_at = null
    if (response && response.ok) {
        if (response.data.account_id) {

            identifyUser(response.data.account_id)
            
            if (response.data.account_id === accountId) {
                trackEvent(HeapEvents.ACCOUNT_CREATE_MIGRATE)
            }
            if (localStorage.getItem('va_direct_code')) {
                trackEvent(HeapEvents.ACCOUNT_CREATE_DIRECT)
            } else {
                trackEvent(HeapEvents.ACCOUNT_CREATE)
            }
        }
        yield put(AuthActions.setFirstLogin(true))
        if (response.status === 200) {
            var data = parseAccountData(response.data)
            if (data) {
                if (data.firstName) {
                    yield put(AccountDataActions.setFirstName(data.firstName))
                }
                if (data.lastName) {
                    yield put(AccountDataActions.setLastName(data.lastName))
                }
                if (data.mobilePhone) {
                    yield put(AccountDataActions.setMobilePhone(data.mobilePhone))
                }
                if (data.email) {
                    yield put(AccountDataActions.setEmail(data.email))
                }

                if (response.data.case_id) {
                    yield put(RegisterActions.resetRegisterState())
                    yield put(replace('/'))
                    yield put(AuthActions.userDidLogin())
                } else {
                    if (auth.isAuthenticated()) {
                        yield put(RegisterActions.setStepWithMessage('create_conversation', 'AccountSetupProgress.statusMessages.CreateConversation'))
                    } else if (response.data.otc_code) {
                        CASConfig = yield select(getCASConfig)
                        auth.initAuthProvider(CASConfig)
                        try {
                            yield call(auth.otcAuthentication, response.data.otc_code, data.email)
                        } catch (e) { }
                        if (auth.isAuthenticated()) {
                            idToken = sessionStorage.getItem("id_token")
                            expires_at = sessionStorage.getItem('expires_at')
                            var jsonToken = parseJwt(idToken);
                            if (jsonToken['email'].toLowerCase() !== accountData.email.toLowerCase()) { //If for some reason the token email doesnt match the session email logout
                                yield put(AuthActions.logoutUser('restore'))
                                return
                            }
                            yield put(AuthActions.setAccessToken(idToken, new Date(expires_at)))
                            yield put(RegisterActions.setStepWithMessage('create_conversation', 'AccountSetupProgress.statusMessages.CreateConversation'))
                        } else {
                            yield put(LoginActions.requestLogin())
                        }
                    } else {
                        //VAI-766 TO DO: Before setting first log-in, make the "let the user know they already have account" page first
                        yield put(RegisterActions.setStep('user_exists'))
                        yield put(replace('/register/existing'))
                    }
                }
            }
        } else if (response.status === 204) {
            if (auth.isAuthenticated()) {
                yield put(RegisterActions.setStepWithMessage('create_conversation', 'AccountSetupProgress.statusMessages.CreateConversation'))
            } else if (response.data.otc_code) {
                CASConfig = yield select(getCASConfig)
                auth.initAuthProvider(CASConfig)
                yield call(auth.otcAuthentication, response.data.otc_code, data.email)
                if (auth.isAuthenticated()) {
                    idToken = sessionStorage.getItem("id_token")
                    expires_at = sessionStorage.getItem('expires_at')
                    yield put(AuthActions.setAccessToken(idToken, new Date(expires_at)))
                    yield put(RegisterActions.setStepWithMessage('create_conversation', 'AccountSetupProgress.statusMessages.CreateConversation'))
                } else {
                    yield put(LoginActions.requestLogin())
                }
            } else {
                yield put(LoginActions.requestLogin())
            }
        } else {
            yield put(RegisterActions.setStep('account_creation_failure'))
        }
    }
}

function* onCreateConversation(api) {
    let auth = yield select(getAuth)

    var response = yield call(api.createConversation, auth.accessToken)
    if (response && response.ok) {
        yield put(RegisterActions.setStepWithMessage('create_case', 'AccountSetupProgress.statusMessages.Case'))
    } else {
        yield put(RegisterActions.setStep('account_creation_failure'))
    }
}

function* createNewCase(api) {
    let auth = yield select(getAuth)
    var response = yield call(api.createCase, auth.accessToken)
    if (response && response.ok) {
        if (response.data.taxYears && response.data.taxYears.length > 0) {
            if (response.data.isDirect) {
                trackEvent(response.data.isReturningUser ? "Retail-existingclient" : "Retail-newclient", null);
            }
            response.data.taxYears.forEach(t => {
                trackEvent(HeapEvents.TICKET_CREATE, { taxYear: t })
            })
            yield put(RegisterActions.setStepWithMessage('case_finalize', 'AccountSetupProgress.statusMessages.Finalizing'))
        } else {
            yield put(RegisterActions.setStep('account_creation_failure'))
        }
    } else {
        yield put(RegisterActions.setStep('account_creation_failure'))
    }
}

function* onCaseFinalize() {
    yield put(RegisterActions.resetRegisterState())
    yield put(AuthActions.finalizeLogin())
}

function* onAddVaRole(api, email, password) {
    let auth = yield select(getAuth)
    var response = yield call(api.addvarole, auth.accessToken, email, password)
    if (response && response.ok && response.data.status) {
        // now parse the login info
        var data = parseAccountData(response.data)
        if (data) {
            yield put(AccountDataActions.setAccountId(data.accountId))
            yield put(AccountDataActions.setEmail(data.email))
            yield put(AuthActions.setAccessToken(data.accessToken, data.accessTokenExpiryDate))
            if (data.mobilePhone) {
                yield put(AccountDataActions.setMobilePhone(data.mobilePhone))
            }

            if (response.data.case_id) {
                // existing case, this is a simple login
                yield put(RegisterActions.resetRegisterState())
                yield put(replace('/'))
                yield put(AuthActions.userDidLogin())
            } else {
                // no existing case, let's create one
                yield put(RegisterActions.setStepWithMessage('create_account_with_id', 'AccountSetupProgress.statusMessages.Creating'))
            }
        } else {
            // no existing case, let's create one
            yield put(RegisterActions.setStep('sign_in_failure'))
        }
    }
}

// VAI-199 save user's language preference when changed.
export function* saveLanguagePrefs(api) {
    let lang = yield select(getLanguage)
    let auth = yield select(getAuth)
    if (auth.loginComplete && auth.accessToken) {
        yield call(api.saveLanguagePref, auth.accessToken, lang)
    }
}


export function* processStepChanged(api, action) {
    let register = yield select(getRegister)
    let accountData = yield select(getAccountData)

    window.scrollTo(0, 0)

    console.log(register.step)
    if (register.step === 'lookup_user') {
        yield put(replace('/register/lookup'))
        yield call(lookupUser, api)
    } else if (register.step === 'create_conversation') {
        yield call(onCreateConversation, api)
    } else if (register.step === 'create_case') {
        yield call(createNewCase, api)
    } else if (register.step === 'case_finalize') {
        yield call(onCaseFinalize)
    } else if (register.step === 'sign_in') {
        yield call(onAccountSignIn, api)
    } else if (register.step === 'creating_account') {
        yield call(onCreateAccount, api, register.credentialState, null)
    } else if (register.step === 'create_account_with_id') {
        yield put(replace('/register/working'))
        yield call(onCreateAccount, api, null, accountData.accountId)
    } else if (register.step === 'partial_account_complete') {
        yield put(RegisterActions.setImageIndex(3))
        // do a thing!
        let login = yield select(getLogin)
        yield call(onAddVaRole, api, accountData.email, login.password)
    }
}

export function* resetRegistration(action) {
    var path = action.payload.location.pathname
    // leaving registration should reset the content
    if (!path.startsWith('/register') && !path.startsWith('/dashboard') && !auth.authState) {
        yield put(RegisterActions.resetRegisterState())
    }
    if (path === '/home') {
        yield put(AccountDataActions.accountDataReset())
    }
}

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};
