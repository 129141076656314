// src/routes.js
import React from 'react';
import './style.css'
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';

const ConfirmMissingDataLink = (props) => (
    <div>
        <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#E70000', align: 'center' }} />
        <span className="missingLinkStyle" onClick={() => props.editSummary()}>{T.translate('ConfirmIdentity.Missing.' + props.field)}</span>    
    </div>
    
);

export default ConfirmMissingDataLink;