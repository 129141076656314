import React, { Component } from 'react';
import { connect } from 'react-redux'
import { replace, push } from 'connected-react-router'
import BaseModal from '../Modal/BaseModal';
import T from 'i18n-react';
import HelpCentre from '../../sagas/Help/helpCentre'

import { getTaxYearState } from '../../utils/CaseStateUtilities'


import '../AuthorizeHRB/style.css';
import './style.css'

import LayoutActions from '../../reducers/layoutRedux'
import WorkflowActions from '../../reducers/workflowRedux'
import DocumentActions from '../../reducers/documentsRedux'
import MessagesActions from '../../reducers/messagesRedux'
import ApproveDraftsActions from '../../reducers/approveDraftsRedux'
import SigningActions from '../../reducers/signingRedux'
import FilesActions from '../../reducers/filesRedux'
import InfoDialogContent from '../Messages/infoDialogContent'
import ReturnLink from './returnLink';
import PlainButton from '../Controls/plainButton'
import { setTimeout } from 'timers-browserify';
import CheckBox from '../../components/Common/checkbox'

// TODO Removed box wrapper
class ReviewAltPage1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stage: props.stage,
            fileInViewer: null,
            missingFiles: false,
            missingFilesErrorVisible: false,
            processing: false,
            documentsApproved: false,
            documentsReviewed: [],
            taxYear: this.props.taxYear
        }
    }

    componentWillMount() {
        if (!this.props.taxYearState) {
            this.props.replaceNavigation('/missing')
        }

        this.props.loadDocumentsForReview(this.props.taxYear);
        this.props.loadApprovalDocuments(this.props.taxYear);
    }

    componentDidMount() {

        var documents_reviewed = []
        var taxYearDocumentsForReview = this.props.documentsForReview[Number(this.state.taxYear)]
        if (taxYearDocumentsForReview) {
            if (taxYearDocumentsForReview.primaryFederal !== null)
            documents_reviewed.push({ type: 'primaryFederal', reviewed: taxYearDocumentsForReview.primaryFederal.isViewed })

            if (this.props.taxYearState.primary_province === 'QC' && taxYearDocumentsForReview.primaryQuebec !== null) {
                documents_reviewed.push({ type: 'primaryQuebec', reviewed: taxYearDocumentsForReview.primaryQuebec.isViewed })
            }
            if (this.props.taxYearState.coupled && taxYearDocumentsForReview.spouseFederal !== null) {
                documents_reviewed.push({ type: 'spouseFederal', reviewed: taxYearDocumentsForReview.spouseFederal.isViewed })
            }
            if (this.state.spouseProvince === 'QC' && taxYearDocumentsForReview.spouseQuebec !== null) {
                documents_reviewed.push({ type: 'spouseQuebec', reviewed: taxYearDocumentsForReview.spouseQuebec.isViewed })
            }
        }        
                
        this.setState({ documentsReviewed: documents_reviewed }) //eslint is wrong. It's apparently fine to setState in componentDidMount()

        var workflowState = this.props.workflow.case_state.taxYears.find(x => x.taxYear === Number(this.props.taxYear)).workflow_state
        if (workflowState.lastreviewedpage !== 1)
            this.props.setLastReviewedPage(workflowState.case_id,1, this.state.taxYear)
    }

    missingFile = (docs) => {
        if (!docs) {
            return true;
        }
        if (!docs.primaryFederal || !docs.primaryFederal.fileId) {
            return true;
        }
        if (this.props.taxYearState.coupled && (!docs.spouseFederal || !docs.spouseFederal.fileId)) {
            return true;
        }
        if (this.props.taxYearState.primary_province === 'QC' && (!docs.primaryQuebec || !docs.primaryQuebec.fileId)) {
            return true;
        }
        if (this.props.taxYearState.coupled && this.props.taxYearState.spouse_province === 'QC'
            && (!docs.spouseQuebec || !docs.spouseQuebec.fileId)) {
            return true;
        }
        return true;
    }   

    componentWillReceiveProps(newProps) {
        if (this.state.stage !== newProps.stage) {
            window.scrollTo(0, 0)
            this.setState({ stage: newProps.stage }) 
            // if we're changing the signing stage, go and do the signing
            //if (newProps.stage === 'approveSpouse') this.goSignThings(newProps)
        }
        if (this.state.signedDocuments !== newProps.signedDocuments) {
            this.setState({ signedDocuments: newProps.signedDocuments })
        }
        if (this.state.signingState !== newProps.signingState) {
            this.setState({ signingState: newProps.signingState })
        }
        if (this.state.signingError !== newProps.signingError) {
            this.setState({ signingError: newProps.signingError })
            // VAI-476 show 
            if (newProps.signingError === 'filenotfound') this.setState({ missingFilesErrorVisible: true })
        }
        if (this.state.postLoginComplete !== newProps.postLoginComplete) {
            this.setState({ postLoginComplete: newProps.postLoginComplete })
            // VAI-458 copied code from componentDidMount to here (just in case)
            setTimeout(this.goSignThings(newProps), 100)
        }          
    }    

    setApprovalComplete() {
        this.setState({ processing: true })

        this.props.setApprovalComplete(this.props.taxYear);
    }

    getLink(fileType) {
        var file = { ...this.props.approvalDocs[fileType] }
        return (file ? file.shareLink : null);
    }

    // Local event handlers 
    onBackToDashClicked(e) {
        this.props.replaceNavigation('/dashboard')
    }

    onViewClicked(fileType) {
        var file = this.props.taxYearDocs[fileType]
        if (file && file.fileId) { 
            this.setState({ fileInViewer: file.fileId })
            this.props.setBoxDialogStates(false, false, true);
            this.props.setDocumentForReviewViewed(file.fileId, this.props.taxYear);
        } else {
            // show the box folder view
            this.setState({ fileInViewer: null })
            this.props.setBoxDialogStates(false, false, true);
        }

        var documents_reviewed = this.state.documentsReviewed
        documents_reviewed.forEach((document) => {
            if (document.type === fileType) {
                document.reviewed = true
            }
        })
        this.setState({ documentsReviewed: documents_reviewed })
        this.setState({ documentsApproved: false })

    }

    onDownloadClicked(fileType) {       
        var documents_reviewed = this.state.documentsReviewed
        documents_reviewed.forEach((document) => {
            if (document.type === fileType) {
                document.reviewed = true
            }
        })
        this.setState({ documentsReviewed: documents_reviewed })
        this.setState({ documentsApproved: false })
    }

    handleApproveCheck = (ev) => {        
        this.setState({ documentsApproved: ev.checked })           
    }

    onMessageMyProClicked() {
        this.props.setInputScreenShowing(true)
        this.props.setDashboardTab('messages')
        this.props.pushNavigation('/dashboard')
    }

    handleBoxDialogClose() {
        this.props.setBoxDialogStates(false, false, false)
    }

    openChecklist() {
        HelpCentre.openHelpLink('ReviewingYourReturn/WhatShouldILookFor')
    }

    onContinueClicked(e) {
        this.props.pushNavigation('/dashboard/reviewalt/' + this.state.taxYear + '/2')
    }

    
    render() {       

        // create the preview links
        var checklistLink = (<span className="reviewSpanNoMargin learnMoreStyle" onClick={() => this.openChecklist()}>{T.translate('ReviewAndApproveAlternate.Checklist')}</span>)
        var previewLinks = [
            <ReturnLink key="primaryFederal"
                titleKey="ReviewAndApproveAlternate.Federal"
                layoutType={this.props.layoutType}
                firstName={this.props.taxYearState.primary_firstName}
                file={this.props.taxYearDocs ? this.props.taxYearDocs.primaryFederal : null}
                onViewClicked={() => this.onViewClicked('primaryFederal')}
                onDownloadClicked={() => this.onDownloadClicked('primaryFederal')}
                taxYear={this.state.taxYear}/>
        ]

        if (this.props.taxYearState.primary_province === 'QC') {
            previewLinks.push(
                <ReturnLink key="primaryQuebec"
                    titleKey="ReviewAndApproveAlternate.Quebec"
                    layoutType={this.props.layoutType}
                    firstName={this.props.taxYearState.primary_firstName}
                    file={this.props.taxYearDocs ? this.props.taxYearDocs.primaryQuebec : null}
                    onViewClicked={() => this.onViewClicked('primaryQuebec')}
                    onDownloadClicked={() => this.onDownloadClicked('primaryQuebec')}
                    taxYear={this.state.taxYear} />
            )
        }

        if (this.props.taxYearState.coupled) {
            previewLinks.push(
                <ReturnLink key="spouseFederal"
                    titleKey="ReviewAndApproveAlternate.Federal"
                    layoutType={this.props.layoutType}
                    firstName={this.props.taxYearState.spouse_firstName}
                    file={this.props.taxYearDocs ? this.props.taxYearDocs.spouseFederal : null}
                    onViewClicked={() => this.onViewClicked('spouseFederal')}
                    onDownloadClicked={() => this.onDownloadClicked('spouseFederal')}
                    taxYear={this.state.taxYear} />
            )

            if (this.props.taxYearState.coupled && this.props.taxYearState.spouse_province === 'QC')
                previewLinks.push(
                    <ReturnLink key="spouseQuebec"
                        titleKey="ReviewAndApproveAlternate.Quebec"
                        layoutType={this.props.layoutType}
                        firstName={this.props.taxYearState.spouse_firstName}
                        file={this.props.taxYearDocs ? this.props.taxYearDocs.spouseQuebec : null}
                        onViewClicked={() => this.onViewClicked('spouseQuebec')}
                        onDownloadClicked={() => this.onDownloadClicked('spouseQuebec')}
                        taxYear={this.state.taxYear} />
                )
        }

        // hack for single return on mobile view (center it!)
        var linkStyle = null;
        if (this.props.layoutType === 'mobile' && !this.props.taxYearState.coupled && this.props.taxYearState.primary_province !== 'QC') {
            linkStyle = { justifyContent: 'center', minWidth: 0 }
        }

        // spit out some stuff
        return (
             <div>                                    
                    <div className="authorizeContentStyle">
                        <h1>{T.translate('ReviewAndApproveAlternate.TitleReview.AllReturns')}</h1>
                    <T.span text={{ key: 'ReviewAndApproveAlternate.HeaderReview.All', checklist: checklistLink }}  />
                        <div style={{ height: 20 }} />
                    </div>
                    <div className="previewWrapperContent">
                        <div className="previewHorizontalContent" style={linkStyle}>
                        {previewLinks.length > 2 ? [previewLinks[0],previewLinks[1]] : previewLinks}
                        </div>
                        {previewLinks.length > 2 ?
                            <div className="previewHorizontalContent">
                                {previewLinks.length > 3 ? [previewLinks[2],previewLinks[3]] : previewLinks[2]}
                            </div> : null
                        }
                    </div>
                    <div className="authorizeContentStyle">                        
                        <div style={{ height: 20 }} />
                        <T.span text={{
                            key: 'ReviewAndApproveAlternate.Questions'}} />
                        <div style={{ height: 25 }} />
                        <T.span text={{
                            key: 'ReviewAndApproveAlternate.GoodToGo'}} />
                    </div>
                    <div className="reviewtCheckboxStyle" style={{ marginTop: this.state.layoutType === 'mobile' ? '10px' : '15px', marginLeft: this.state.layoutType === 'mobile' ? '20px' : '60px', marginRight: this.state.layoutType === 'mobile' ? '20px' : '60px' }} >
                        <CheckBox isChecked={false} onCheckChanged={(ev) => this.handleApproveCheck(ev)} />
                        <span style={{ marginLeft: 10, marginTop: 5 }}>
                            <span>{T.translate('ReviewAndApproveAlternate.ApproveReturnsForFiling')}</span>
                        </span>
                    </div>                

                <div className="reviewAltBottomContainer" style={{ marginTop: 20 }}>
                    <PlainButton inverted={true} onClick={(e) => this.onMessageMyProClicked(e)} labelKey='ReviewAndApproveAlternate.MessagePro' isEnabled={true} />
                    <div style={{width: '20px', height: '10px'}} />
                    <PlainButton inverted={false} onClick={(e) => this.onContinueClicked(e)}
                        labelKey={'ReviewAndApproveAlternate.ContinueStep2'}
                        isEnabled={Boolean(this.state.documentsApproved)}
                        inProgress={this.state.processing}
                    />
                </div>
                <BaseModal show={this.state.missingFilesErrorVisible} onClose={() => this.setState({ missingFilesErrorVisible: false })} layoutType={this.props.layoutType} >
                    <InfoDialogContent
                        titleKey='ReviewAndApprove.MissingDocsError.Title'
                        labelKeys={['ReviewAndApprove.MissingDocsError.Label','ReviewAndApprove.MissingDocsError.SubLabel']}
                        onOK={() => { this.setState({ missingFilesErrorVisible: false }) }}
                        onCancel={() => { this.setState({ missingFilesErrorVisible: false }) }}
                        onCloseDialog={() => { this.setState({ missingFilesErrorVisible: false }) }}
                        isWarning={true}
                        faIconName='fa-exclamation'
                    />
                </BaseModal>
            </div>
        ) 
    }
}

const mapStateToProps = (state, ownProps) => {    
    return {
        lang: state.layout.lang,
        layoutType: state.layout.layoutType,
        uploaderShowing: state.documents.uploaderShowing,
        viewerShowing: state.documents.viewerShowing,
        fileShowing: state.documents.fileShowing,        
        taxYearDocs: state.files.documentsForReview[Number(ownProps.taxYear)],        
        taxYearState: getTaxYearState(Number(ownProps.taxYear), state.workflow.case_state),
        approvalDocs: state.files.approvalDocuments[Number(ownProps.taxYear)],
        signedDocuments: state.signing.signedDocuments,
        signingState: state.signing.signingState,
        signingError: state.signing.signingError,
        stage: state.approveDrafts.stage,
        workflow: state.workflow,
        documentsForReview: state.files.documentsForReview
    };
}

const mapDispatchToProps = (dispatch) => ({
    loadDocumentsForReview: (taxYear) => dispatch(FilesActions.loadDocumentsForReview(taxYear)),
    loadApprovalDocuments: (taxYear) => dispatch(FilesActions.loadApprovalDocuments(taxYear)),
    replaceNavigation: (path) => dispatch(replace(path)),
    pushNavigation: (path) => dispatch(push(path)),
    setButtonState: (buttonName, buttonState) => dispatch(WorkflowActions.setButtonState(buttonName, buttonState)),
    setBoxDialogStates: (uploaderShowing, viewerShowing, fileShowing) => dispatch(DocumentActions.setBoxDialogStates(uploaderShowing, viewerShowing, fileShowing)),
    setInputScreenShowing: (showing) => dispatch(MessagesActions.setInputScreenShowing(showing)),
    setDashboardTab: (tab) => dispatch(LayoutActions.setDashboardTab(tab)),
    setDocumentForReviewViewed: (fileId, taxYear) => dispatch(FilesActions.setDocumentForReviewViewed(fileId, taxYear)),
    setButtonStates: (buttons) => dispatch(WorkflowActions.setButtonStates(buttons)),
    setCaseState: (state) => dispatch(WorkflowActions.setCaseState(state)),
    approveSelf: (isquebec) => dispatch(ApproveDraftsActions.approveSelf(isquebec)),
    approveSpouse: (isquebec) => dispatch(ApproveDraftsActions.approveSpouse(isquebec)),
    signExternalForm: (taxYear, formNames, formUrls, isSpouse, completedRoute, spouseFirstName, primaryFirstName) => dispatch(SigningActions.signExternalForm(taxYear, formNames, formUrls, isSpouse, completedRoute, spouseFirstName, primaryFirstName)),
    signingStateReset: () => dispatch(SigningActions.signingStateReset()),
    setApprovalStage: (stage) => dispatch(ApproveDraftsActions.setApprovalStage(stage)),
    setLastReviewedPage: (caseId, pageNumber, taxYear) => dispatch(WorkflowActions.setLastReviewedPage(caseId, pageNumber, taxYear))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReviewAltPage1)