import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import './pom.css'
import logo from '../../../images/POMLogo.png'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import LayoutActions from '../../../reducers/layoutRedux';
import MessagesActions from '../../../reducers/messagesRedux'
import { HeapEvents, trackEvent } from '../../../utils/AnalyticsUtil'

const POMSection = ({ firstName, layoutType, addOnProducts }) => {
    const dispatch = useDispatch();
    const onLearnMoreAboutPOMClicked = () => {
        window.open(T.translate('Common.POMMarkettingWebsite'))
        trackEvent(HeapEvents.BUTTON_POM_LEARN);
    };

    const taxYearsObj = useSelector(state => state.workflow && state.workflow.case_state && state.workflow.case_state.taxYears
        ? state.workflow.case_state.taxYears : [], shallowEqual)

    const message = T.translate('Messages.Labels.POMAskExpertMessage', { TaxProName: taxYearsObj[0]?.assigned_tax_pro?.name, FirstName: firstName })
    var isCaseEligibleForPom = addOnProducts.filter(product => product.name === 'POM').length > 0;
    const onAskExpertClicked = () => {
        dispatch(LayoutActions.setDashboardTab('messages'))
        dispatch(MessagesActions.setInputScreenShowing(true))
        dispatch(MessagesActions.setMessageInProgress(message))
        dispatch(MessagesActions.setMessageSendState(''))
        trackEvent(HeapEvents.BUTTON_POM_ASK_EXPERT);
    };

    const [shouldShowPOMAd, setShouldShowPOMAd] = useState(false);

    useEffect(() => {
        setShouldShowPOMAd(false)
        taxYearsObj.map(x => {
            if (x.ticket_type === 'RTE' && x.ticketState.includes('RTE_WORKING') && x.workflow_state.upload_docs === 'Complete' && isCaseEligibleForPom) 
            {
                setShouldShowPOMAd(true)
            }
        })
    }, [taxYearsObj, addOnProducts])

    return (
        <>
            {
                shouldShowPOMAd &&
                <div className='pom-section-container'>
                    <div className={layoutType === 'desktop' ? 'pom-section-title' : 'pom-section-title-mobile'}>
                        {T.translate('WorkflowPanel.POM.title')}
                    </div>
                    <div className={layoutType === 'desktop' ? 'pom-section-label-container' : 'pom-section-label-container-mobile'}>
                        {T.translate('WorkflowPanel.POM.subtext')}
                    </div>
                    <div className='pom-section-logo-container'>
                        <img src={logo} />
                    </div>
                    <div className='pom-learnMoreStyleDiv'>
                        <span className='pom-learnMoreStyle' onClick={() => onLearnMoreAboutPOMClicked()}>
                            {T.translate('WorkflowPanel.POM.learnAboutPOM')}
                        </span>
                    </div>
                    <div>
                        <span className={layoutType === 'desktop' ? 'pom-askExpertButtonStyleDesktop' : 'pom-askExpertButtonStyleMobile'} onClick={() => onAskExpertClicked()}>
                            {T.translate('WorkflowPanel.POM.askExpert')}
                        </span>
                    </div>
                </div>
            }
        </>
    )
}

POMSection.propTypes = {
    firstName: PropTypes.string.isRequired,
    layoutType: PropTypes.string.isRequired
}

export default POMSection;