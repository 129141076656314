// src/components/Interview/interview.js
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import { withRouter } from 'react-router-dom'
import FontAwesome from 'react-fontawesome';
import '../AuthorizeHRB/style.css';
import T from 'i18n-react';
import WorkflowActions from '../../reducers/workflowRedux'
import DocumentActions from '../../reducers/documentsRedux'
import FilesActions from '../../reducers/filesRedux'
import classnames from 'classnames'
import { getTaxYearState } from '../../utils/CaseStateUtilities'
import './style.css'

import ReturnLink from './returnLink';
import PlainButton from '../Controls/plainButton'
import LoadingSpinner from '../Loading/loadingSpinner'
import fileDownloadUtil from '../Dox/Explorer/services/FileDownloadUtil';
import { CDMFileOperationStatus, ErrorTypes } from '../Dox/Shared/resources/Enums';
import doxExplorerCDMServices from '../Dox/Shared/services/doxExplorerCDMServices';
import { fileTypeWillOpen } from '../Dox/Shared/resources/DoxFileTypes';
import { browserShouldOpenInTab } from '../Dox/Shared/services/Util';

class ReviewCompletedReturns extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fileInViewer: null,
        }
    }

    componentWillMount() {
        if (!this.props.taxYearState) {
            this.props.replaceNavigation('/missing')
        }

        if (this.missingFile()) {
            this.props.loadCompletedDocuments(this.props.taxYear);
        }
    }

    missingFile = () => {
        if (!this.props.taxYearDocs) {
            return true;
        }
        if (!this.props.taxYearDocs.primaryFederal || !this.props.taxYearDocs.primaryFederal.fileId) {
            return true;
        }
        if (this.props.taxYearState.coupled && (!this.props.taxYearDocs.spouseFederal || !this.props.taxYearDocs.spouseFederal.fileId)) {
            return true;
        }
        if (this.props.taxYearState.primary_province === 'QC' && (!this.props.taxYearDocs.primaryQuebec || !this.props.taxYearDocs.primaryQuebec.fileId)) {
            return true;
        }
        if (this.props.taxYearState.coupled && this.props.taxYearState.spouse_province === 'QC'
            && (!this.props.taxYearDocs.spouseQuebec || !this.props.taxYearDocs.spouseQuebec.fileId)) {
            return true;
        }
        return false;
    }

    // Local event handlers
    onBackToDashClicked(e) {
        this.props.replaceNavigation('/dashboard')
    }

    async handleOpenFileById(action, fileId) {

        try {
            //perform the download    
            let response = await doxExplorerCDMServices.downloadFile(this.props.accessToken, fileId);

            if (response && response.status === 200 && response.data) {
                // get the file name and clean it. Not all files have extra chars, 
                // but some might, so strip them here.
                let cleanFileName = (response.data.fileName || 'unknown').replace(/[/\\?%*:|"<>]/g, '')
                let fileNameParts = cleanFileName.split('.')
                // find file type
                let fileType = ''
                if (fileNameParts.length > 1) {
                    fileType = '.' + fileNameParts[fileNameParts.length - 1]
                }

                // parse binary response data into fancy blob
                let fileLink = fileDownloadUtil.makeBlobLink(response.data.fileContents, fileType);

                // deal with browser opening in a new tab or download file
                let fileOpens = fileTypeWillOpen(fileType);
                let openInTab = browserShouldOpenInTab();
                fileDownloadUtil.downloadFile(fileLink, cleanFileName, action === 'view' && fileOpens && openInTab)

                return CDMFileOperationStatus.DOWNLOAD_COMPLETE;
            } else if (response && response.status === 404) {
                return ErrorTypes.FAIL_NO_SUCH_FILE;
            } else {
                return ErrorTypes.FAIL_TRANSIENT;
            }
        } catch (error) {
            return ErrorTypes.FAIL_TRANSIENT;
        }
    }

    onViewClicked(fileType) {
        var file = this.props.taxYearDocs[fileType]
        if (file && file.fileId) {
            this.setState({ fileInViewer: file.fileId })
            this.props.setCompletedDocumentViewed(file.fileId, this.props.taxYear);
            this.props.setBoxDialogStates(false, false, true);
            this.handleOpenFileById('view', file.fileId);
        } else {
            this.setState({ fileInViewer: null })
            this.props.setBoxDialogStates(false, false, true);
        }
    }

    onDownloadClicked(fileType) {
        var file = this.props.taxYearDocs[fileType]
        if (file && file.fileId) {
            this.props.setCompletedDocumentViewed(file.fileId, this.props.taxYear);
            this.handleOpenFileById('download', file.fileId);
        }
    }

    handleBoxDialogClose(ev) {
        this.props.setBoxDialogStates(false, false, false)
    }

    render() {
        // determine the root styling.
        let bodyStyle = 'reviewContainer';
        if (this.props.layoutType === 'tablet' || this.props.layoutType === 'small_tablet') {
            bodyStyle += ' reviewContainerTablet'
        }
        bodyStyle += (this.props.layoutType === 'tablet' || this.props.layoutType === 'small_tablet' ? ' tablet' :
            (this.props.layoutType === 'mobile' ? ' mobile' : ' desktop'));

        // determine which strings to display
        var key = ''
        var titleKey = ''
        var contentStyleClass = ''
        if (this.props.taxYearState) {
            if (this.props.taxYearState.ticket_type !== 'RTE') {
                contentStyleClass = 'authorizeContentStyle';
                key = this.props.taxYearState.coupled ? 'Coupled' : 'Single'
                if (this.props.taxYearState.primary_province === 'QC' || this.props.taxYearState.spouse_province === 'QC') key = key + 'QC'

                titleKey = this.props.taxYearState.coupled || this.props.taxYearState.primary_province === 'QC' ? 'ReviewCompletedReturns.Title'
                    : 'ReviewCompletedReturns.TitleSingle'
            }
            else {
                key = 'Rte';
                titleKey = 'ReviewCompletedReturns.TitleRte';
                contentStyleClass = classnames('reviewCompletedContentStyleRte', this.props.layoutType);
            }

        }

        // create the preview links
        var previewLinks = []
        if (this.props.taxYearState && this.props.taxYearDocs) {
            var linkTitleKeyFederal = "ReviewAndApprove.Federal";
            var linkTitleKeyQuebec = "ReviewAndApprove.Quebec";
            if (this.props.taxYearState.ticket_type === 'RTE') {
                linkTitleKeyFederal = linkTitleKeyFederal + 'Return';
                linkTitleKeyQuebec = linkTitleKeyQuebec + 'Return';
            }
            previewLinks.push(
                <ReturnLink key="primaryFederal" layoutType={this.props.layoutType} lang={this.props.lang}
                    titleKey={linkTitleKeyFederal} firstName={this.props.taxYearState.primary_firstName} file={this.props.taxYearDocs.primaryFederal}
                    onViewClicked={(e) => this.onViewClicked('primaryFederal')}
                    onDownloadClicked={(e) => this.onDownloadClicked('primaryFederal')}
                    ticketType={this.props.taxYearState.ticket_type}
                    filerStoryType="primaryFederal" />
            )

            if (this.props.taxYearState.primary_province === 'QC') {
                previewLinks.push(
                    <ReturnLink key="primaryQuebec" layoutType={this.props.layoutType} lang={this.props.lang}
                        titleKey={linkTitleKeyQuebec} firstName={this.props.taxYearState.primary_firstName}
                        file={this.props.taxYearDocs.primaryQuebec}
                        onViewClicked={(e) => this.onViewClicked('primaryQuebec')}
                        onDownloadClicked={(e) => this.onDownloadClicked('primaryQuebec')}
                        ticketType={this.props.taxYearState.ticket_type}
                        filerStoryType="primaryQuebec" />
                )
            }

            if (this.props.taxYearState.coupled) {
                previewLinks.push(
                    <ReturnLink key="spouseFederal" layoutType={this.props.layoutType} lang={this.props.lang}
                        titleKey={linkTitleKeyFederal} firstName={this.props.taxYearState.spouse_firstName} file={this.props.taxYearDocs.spouseFederal}
                        onViewClicked={(e) => this.onViewClicked('spouseFederal')}
                        onDownloadClicked={(e) => this.onDownloadClicked('spouseFederal')}
                        ticketType={this.props.taxYearState.ticket_type}
                        filerStoryType="spouseFederal" />
                )

                if (this.props.taxYearState.spouse_province === 'QC') {
                    previewLinks.push(
                        <ReturnLink key="spouseQuebec" layoutType={this.props.layoutType} lang={this.props.lang}
                            titleKey={linkTitleKeyQuebec} firstName={this.props.taxYearState.spouse_firstName} file={this.props.taxYearDocs.spouseQuebec}
                            onViewClicked={(e) => this.onViewClicked('spouseQuebec')}
                            onDownloadClicked={(e) => this.onDownloadClicked('spouseQuebec')}
                            ticketType={this.props.taxYearState.ticket_type}
                            filerStoryType="spouseQuebec" />
                    )
                }
            }
        }

        // spit out some stuff
        return this.props.taxYearDocs ? (
            <div className={bodyStyle}>
                <div className="authorizeContainerStyle">
                    <div className="authorizeBackContainer">
                        <div className="authorizeBackButtonStyle" onClick={(e) => this.onBackToDashClicked(e)}>
                            <FontAwesome className='fa-angle-left' name='fa-angle-left' size='2x' />
                            <h4>{T.translate('Common.ToDashboard')}</h4>
                        </div>
                    </div>
                    <div className={contentStyleClass}>
                        <h1>{T.translate(titleKey)}</h1>
                        <div style={{ height: 20 }} />
                        <T.span text={{ key: 'ReviewCompletedReturns.SubTitle.' + key, TaxYear: this.props.taxYear }} />
                        <div style={{ height: 20 }} />
                    </div>
                    {this.props.taxYearState.ticket_type === 'RTE' ?
                        <div>
                            <div className={classnames('wrapperRte', this.props.layoutType)}>
                                <div className="previewWrapperContent">
                                    <div className="previewSection">
                                        {this.props.taxYearState.coupled &&
                                            <div className='filerTitle'>{this.props.taxYearState.primary_firstName}</div>}
                                        <div className="previewHorizontalContentCompleted">
                                            {previewLinks.length > 2
                                                ? [previewLinks[0], previewLinks[1]]
                                                : this.props.taxYearState.coupled ? previewLinks[0] : previewLinks}
                                        </div>
                                    </div>
                                    {this.props.taxYearState.coupled && <div className="previewSection">
                                        <div className="dotted_line"></div>
                                    </div>}

                                    { // This code snippet is for Coupled Non-Quebec returns only
                                        previewLinks.length === 2 && this.props.taxYearState.coupled &&
                                        <div className="previewSection">
                                            <div className='filerTitle'>{this.props.taxYearState.spouse_firstName}</div>
                                            <div className="previewHorizontalContentCompleted">
                                                {previewLinks[1]}
                                            </div>
                                        </div>
                                    }
                                    {previewLinks.length > 2 ?
                                        <div className="previewSection">
                                            <div className='filerTitle'>{this.props.taxYearState.spouse_firstName}</div>
                                            <div className="previewHorizontalContentCompleted">
                                                {previewLinks.length > 3 ? [previewLinks[2], previewLinks[3]] : previewLinks[2]}
                                            </div>
                                        </div> : null
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <div className="previewWrapperContent">
                                <div className="previewHorizontalContentCompleted">
                                    {previewLinks.length > 2 ? [previewLinks[0], previewLinks[1]] : previewLinks}
                                </div>
                                {previewLinks.length > 2 ?
                                    <div className="previewHorizontalContentCompleted">
                                        {previewLinks.length > 3 ? [previewLinks[2], previewLinks[3]] : previewLinks[2]}
                                    </div> : null
                                }
                            </div>
                            <div className="authorizeContentStyle">
                                <div style={{ height: 20 }} />
                            </div>
                        </div>
                    }
                </div>
                {this.props.taxYearState.ticket_type === 'RTE' ?
                    <div className="reviewBottomContainerCompletedRte">
                        <PlainButton onClick={(e) => this.onBackToDashClicked(e)} labelKey='Common.Done' isEnabled={true} />
                    </div>
                    :
                    <div className="reviewBottomContainerCompleted">
                        <PlainButton onClick={(e) => this.onBackToDashClicked(e)} labelKey='Common.Done' isEnabled={true} />
                    </div>
                }
            </div>
        ) : (
            <div className="reviewContentStyle">
                <div className="authorizeBackContainer">
                    <div className="authorizeBackButtonStyle" onClick={(e) => this.onBackToDashClicked(e)}>
                        <FontAwesome className='fa-angle-left' name='fa-angle-left' size='2x' />
                        <h4>{T.translate('Common.ToDashboard')}</h4>
                    </div>
                </div>
                <div className="review_spinner_container">
                    <LoadingSpinner />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    var taxYear = ''
    if (ownProps && ownProps.match && ownProps.match && ownProps.match.params) {
        taxYear = ownProps.match.params.taxYear
    }
    taxYear = Number(taxYear || state.config.appConfig.currentTaxYear)
    return {
        lang: state.layout.lang,
        layoutType: state.layout.layoutType,
        uploaderShowing: state.documents.uploaderShowing,
        viewerShowing: state.documents.viewerShowing,
        fileShowing: state.documents.fileShowing,
        taxYear,
        taxYearDocs: state.files.completedDocuments[taxYear],
        taxYearState: getTaxYearState(taxYear, state.workflow.case_state),
        accessToken: state.auth.accessToken
    };
}

const mapDispatchToProps = (dispatch) => ({
    replaceNavigation: (path) => dispatch(replace(path)),
    setButtonState: (buttonName, buttonState) => dispatch(WorkflowActions.setButtonState(buttonName, buttonState)),
    setBoxDialogStates: (uploaderShowing, viewerShowing, fileShowing) => dispatch(DocumentActions.setBoxDialogStates(uploaderShowing, viewerShowing, fileShowing)),
    loadCompletedDocuments: (taxYear) => dispatch(FilesActions.loadCompletedDocuments(taxYear)),
    setCompletedDocumentViewed: (fileId, taxYear) => dispatch(FilesActions.setCompletedDocumentViewed(fileId, taxYear)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviewCompletedReturns))