import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    setPaymentInfo: ['paymentDetails'],
    getPaymentInfo: ['caseId'],
    completePayment: ['url'],
    clearPaymentInfo: null
})

export const PaymentTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    paymentInfo: [],
}

/* ------------- Reducers ------------- */
export const paySetPaymentInfo = (state, { paymentDetails }) =>
    Object.assign({}, state, {
        paymentInfo: paymentDetails
    })

export const payClearPaymentInfo = (state) => INITIAL_STATE


/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_PAYMENT_INFO]: paySetPaymentInfo,
    [Types.CLEAR_PAYMENT_INFO]: payClearPaymentInfo,
})