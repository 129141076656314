import apisauce from 'apisauce'
import { addErrorHandler, getApiBaseUrl } from './serviceUtils'

/**
 * API for managing workflow information
 * @param {String} baseURL
 */
const create = (baseURL = getApiBaseUrl()) => {  // this will change for prod deployment
    // Create and configure an apisauce-based api object.
    const api = apisauce.create({
        baseURL,
        headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Content-Type': 'application/json' },
        timeout: 120000
    })

    addErrorHandler(api)

    const getAuthHeader = (accessToken) => {
        return {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        }
    }

    const getSignedForms = (accessToken) => {
        var payload = ''
        return api.get('esignature', payload, getAuthHeader(accessToken))

    }

    const getESignatureUriForAdditionalDocs = (accessToken, taxYear, forms, isSpouse = false) => {
        var formData = forms.map(f => ({
            form_type: 'RetrieveFromCdm',
            form_name: f
        }))
        
        var payload = {
            forms: formData,
            isSpouse: isSpouse,
            taxYear: taxYear
        }
        return api.post('esignature/sign', payload, getAuthHeader(accessToken))
    }

    const getESignatureUri = (accessToken, taxYear, forms, urls, isSpouse = false) => {
        var formData = []
        for (var i = 0; i < forms.length; ++i) {
            // VAI-458 certain forms will need to be signed from Box
            if (urls && (forms[i] === 'T183' || forms[i] === 'TP1000')) {
                formData.push({
                    form_type: 'RetrieveFromCdm',
                    form_name: forms[i],
                    document_id: urls[i]
                })
            } else {
                formData.push({
                    form_type: 'Fillable',
                    form_name: forms[i]
                })
            }
        }
        var payload = {
            forms: formData,
            isSpouse: isSpouse,
            taxYear: taxYear
        }
        return api.post('esignature/sign', payload, getAuthHeader(accessToken))
    }

    const completeForm = (accessToken, envelopeId, recipientId) => {
        var payload = {
            envelopeId: envelopeId,
            recipientId: recipientId
        }
        return api.post('esignature/complete', payload, getAuthHeader(accessToken))
    }

    const completeFormTransfer = (accessToken, envelopeId, recipientId) => {
        var payload = {
            envelopeId: envelopeId,
            recipientId: recipientId
        }
        api.post('esignature/completefiles', payload, getAuthHeader(accessToken))
    }

    // Return back a collection of functions
    return {
        getESignatureUri, completeForm, completeFormTransfer, getSignedForms, getESignatureUriForAdditionalDocs
    }
}

// let's return back our create method as the default.
export default {
    create
}

