import React, { Component } from 'react';
import '../../Common/shared.css'
import '../style.css';
import T from 'i18n-react';
import MailingAddressForm from '../../Common/mailingAddressForm'
import YesNoQuestion from '../../Common/yesNoQuestion'
import InterviewTitleProgress from '../../Common/interviewTitleProgress'

class InterviewPage6 extends Component {
    constructor(props) {
        super(props)

        var spouse = this.props.spouse || {
            aptNumber: '',
            streetNumber: '',
            streetName: '',
            poBox: '',
            ruralRouteNumber: '',
            city: '',
            province: '',
            postalCode: '',
            inCareOf: '',
            telephone: '',
            mailingAddressChange: '',
            mailingAddressSameAsPrimary: ''
        }
        
        if (spouse.mailingAddressSameAsPrimary !=='N') {
            spouse.aptNumber = this.props.primary.aptNumber;
            spouse.streetNumber = this.props.primary.streetNumber;
            spouse.streetName = this.props.primary.streetName;
            spouse.poBox = this.props.primary.poBox;
            spouse.ruralRouteNumber = this.props.primary.ruralRouteNumber;
            spouse.city = this.props.primary.city;
            spouse.province = this.props.primary.province;
            spouse.postalCode = this.props.primary.postalCode;
            spouse.inCareOf = this.props.primary.inCareOf;
            
            if (this.props.onSpouseChanged) {
                this.props.onSpouseChanged(spouse)
            }
        }

        this.state = {
            spouse,
            spouseFirstName: spouse.firstName,
            layoutType: this.props.layoutType,
            lang: this.props.lang
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.spouse) {
            if (newProps.spouse.mailingAddressSameAsPrimary !=='N') {
                var spouse = {
                    ...newProps.spouse,
                    aptNumber: this.props.primary.aptNumber,
                    streetNumber: this.props.primary.streetNumber,
                    streetName: this.props.primary.streetName,
                    poBox: this.props.primary.poBox,
                    ruralRouteNumber: this.props.primary.ruralRouteNumber,
                    city: this.props.primary.city,
                    province: this.props.primary.province,
                    postalCode: this.props.primary.postalCode,
                    inCareOf: this.props.primary.inCareOf
                }
                this.setState({
                    spouse: spouse,
                    spouseFirstName: newProps.spouse.firstName
                })
            }
            else {
                this.setState({
                    spouse: newProps.spouse,
                    spouseFirstName: newProps.spouse.firstName
                })
            }
        }
        if (this.state.layoutType !== newProps.layoutType) {
            this.setState({ layoutType: newProps.layoutType })
        }
        if (this.state.lang !== newProps.lang) {
            this.setState({ lang: newProps.lang })
        }
    }

    onAddressChanged(address) {
        var spouse = {
            ...this.spouse,
            aptNumber: address.aptNumber,
            streetNumber: address.streetNumber,
            streetName: address.streetName,
            poBox: address.poBox,
            ruralRouteNumber: address.ruralRouteNumber,
            city: address.city,
            province: address.province,
            postalCode: address.postalCode,
            inCareOf: address.inCareOf,
            telephone: address.telephone,
            mailingAddressChange: address.mailingAddressChange
        }
        this.setState({ spouse })
        if (this.props.onSpouseChanged) {
            this.props.onSpouseChanged(spouse)
        }
    }

    onMailingAddressSameAsPrimary(ev, value) {
        var spouse = {
            ...this.spouse,
            mailingAddressSameAsPrimary: value,
            aptNumber: value==='N' ? '' : this.props.primary.aptNumber,
            streetNumber: value==='N' ? '' : this.props.primary.streetNumber,
            streetName: value==='N' ? '' : this.props.primary.streetName,
            poBox: value==='N' ? '' : this.props.primary.poBox,
            ruralRouteNumber: value==='N' ? '' : this.props.primary.ruralRouteNumber,
            city: value==='N' ? '' : this.props.primary.city,
            province: value==='N' ? '' : this.props.primary.province,
            postalCode: value==='N' ? '' : this.props.primary.postalCode,
            inCareOf: value==='N' ? '' : this.props.primary.inCareOf
        }
        this.setState({ spouse })
        if (this.props.onSpouseChanged) {
            this.props.onSpouseChanged(spouse)
        }
    }

    render() {         

        return (
            <div className="validatingTextInputStyle">
                <div>
                    <div>
                        <InterviewTitleProgress taxYear={this.props.taxYear} percentage="66" title="Interview.Page6.headerTitle" nextTitle="Interview.Page6.nextTitle" />
                    </div> 
                </div>
                <div className="horizontalFieldContainerTopAligned largeTopMargin">
                    <YesNoQuestion name="mailingAddressSameAsPrimary" value={this.state.spouse.mailingAddressSameAsPrimary}
                        layoutType={this.state.layoutType} centerAlignThis={true} autoWidth="true"
                        labelText={T.translate('Interview.Page6.fieldLabel11', { SpouseFirstName: this.state.spouseFirstName })}
                        onAnswerChanged={(ev, value) => { this.onMailingAddressSameAsPrimary(ev, value) }} isRequired={true} missingRequiredField={this.props.missingRequiredField && this.state.spouse.mailingAddressSameAsPrimary === ''}/>                    
                </div>
                <div className="largeTopMargin" />
                {(() => {
                    switch (this.state.spouse.mailingAddressSameAsPrimary) {
                        case 'N':
                            return <MailingAddressForm labelKey="Interview.Page6" hideTopSet="N" address={this.state.spouse}
                                isReturningClient={this.props.isReturningClient} taxYear={this.props.taxYear}
                                spouseFirstName={this.state.spouseFirstName} layoutType={this.state.layoutType}
                                onAddressChanged={(address) => this.onAddressChanged(address)} requirePostalCode missingRequiredField={this.props.missingRequiredField} />                                  
                        case 'Y':
                            return <MailingAddressForm labelKey="Interview.Page6" hideTopSet="Y" address={this.state.spouse}
                                isReturningClient={this.props.isReturningClient} taxYear={this.props.taxYear}
                                spouseFirstName={this.state.spouseFirstName} layoutType={this.state.layoutType}
                                onAddressChanged={(address) => this.onAddressChanged(address)} missingRequiredField={this.props.missingRequiredField} />
                        default:
                            return <MailingAddressForm labelKey="Interview.Page6" hideTopSet="Y" address={this.state.spouse}
                                isReturningClient={this.props.isReturningClient} taxYear={this.props.taxYear}
                                spouseFirstName={this.state.spouseFirstName} layoutType={this.state.layoutType}
                                onAddressChanged={(address) => this.onAddressChanged(address)} missingRequiredField={this.props.missingRequiredField} />
                    }
                })()}                                           
            </div>
        )
    }
}

export default InterviewPage6;