import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.css';
import T from 'i18n-react';
import PlainButton from '../Controls/plainButton'
import FontAwesome from 'react-fontawesome'
import HelpCentre from '../../sagas/Help/helpCentre'
import ConfirmIdentityActions from '../../reducers/confirmIdentityRedux'
import AddAddressForm from '../Common/addAddressForm'

class ConfirmIdentityAddAddress extends Component {
    constructor(props) {
        super(props)

        this.state = {
            workingAddress: this.props.workingAddress,
            onConfirmIdentity: this.props.onConfirmIdentity,
            target: this.props.target,
            saveState: null
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.workingAddress !== newProps.workingAddress) {
            this.setState({ workingAddress: newProps.workingAddress })
        }

        if (this.state.onConfirmIdentity !== newProps.onConfirmIdentity) {
            this.setState({ onConfirmIdentity: newProps.onConfirmIdentity })
        }

        if (this.state.target !== newProps.target) {
            this.setState({ target: newProps.target })
        }
    }

    isValidPostalCode(data) {
        if (!data) {
            return true;
        }
        if (data.match(/[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/)) {
            return true
        }
        return false
    }

    isAddressComplete() {
        var validPostal = Boolean(this.state.workingAddress.postalCode && this.isValidPostalCode(this.state.workingAddress.postalCode))
        var addrComplete = (Boolean(this.state.workingAddress.streetNumber) && Boolean(this.state.workingAddress.streetName))
            || Boolean(this.state.workingAddress.poBox) || Boolean(this.state.workingAddress.ruralRouteNumber)
        return addrComplete && Boolean(this.state.workingAddress.city) && Boolean(this.state.workingAddress.province)
            && validPostal
    }

    isAddressEmpty() {
        return !this.state.workingAddress.aptNumber && !this.state.workingAddress.streetNumber && !this.state.workingAddress.streetName
            && !this.state.workingAddress.poBox && !this.state.workingAddress.ruralRouteNumber && !this.state.workingAddress.city
            && !this.state.workingAddress.province && !this.state.workingAddress.postalCode
    }

    confirmIdentity(ev) {
        // first validate that we have good data on our current form
        if (this.isAddressComplete()) {
            this.props.addExtraConfirmAddress(this.state.workingAddress)
            if (this.state.onConfirmIdentity) {
                this.state.onConfirmIdentity(ev)
            }
        } else if (this.isAddressEmpty()) {
            if (this.state.onConfirmIdentity) {
                this.state.onConfirmIdentity(ev)
            }
        } else {
            this.onAddressChanged('validated', true)
        }
    }

    handleAddAnotherAddress() {
        if (this.isAddressComplete()) {
            this.setState({ saveState: 'in_progress' })
            setTimeout(() => {
                this.handleAddAddressCompleteState()
            }, 1500)
        } else {
            this.onAddressChanged('validated', true)
        }
    }

    handleAddAddressCompleteState() {
        this.setState({ saveState: 'complete' })
        setTimeout(() => {
            this.handleAddressAddState()
        }, 1000)
    }

    handleAddressAddState() {
        this.props.addExtraConfirmAddress(this.state.workingAddress)
        this.props.updateWorkingAddress()
        this.setState({ saveState: null })
    }

    handleCancel() {
        if (this.isAddressEmpty() && (!this.props.extraAddresses || this.props.extraAddresses.length === 0)) {
            this.props.setStage(this.state.target, 'summary')
        } else {
            this.props.setStage(this.state.target, 'confirmCancel')
        }
    }

    onAddressChanged = (fieldName, value) => {
        let addr = {
            ...this.state.workingAddress
        }
        addr[fieldName] = value
        this.props.updateWorkingAddress(addr)
    }

    showAddressHelp() {
        HelpCentre.openHelpLink('OTE_FAQ/HowShouldIEnterMyAddress')
    }

    render() {
        var validationError = null
        if (this.state.workingAddress.validated && !this.isAddressComplete()) {
            validationError = (
                <div className="validationErrorStyle confirmValidateErrorStyle alignMeRight">
                    <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#E70000', align: 'center' }} />
                    <span style={{ marginLeft: '10px', marginRight: '30px' }}>{T.translate('ConfirmIdentity.Error.MissingRequiredField')}</span>
                </div>
            )
        }

        var buttonIsComplete = this.state.saveState === 'complete'
        var buttonIsInProgress = this.state.saveState === 'in_progress'
        var buttonIsDisabled = this.props.extraAddresses && this.props.extraAddresses.length > 0
        var isInverted = !buttonIsComplete && !buttonIsInProgress

        return (
            <div className="confirmContainerOuter">
                <div className="confirmContainer">
                    <div className="confirmIdHelpHolder">
                        <div style={{ height: '20px' }} />
                        <h1>{T.translate("ConfirmIdentity.ExtraAddresses.Title")}</h1>
                        <div style={{ height: '20px' }} />
                    </div>                    
                    <span className="confirmContainerSpan largeMargins">
                        <span>{T.translate("ConfirmIdentity.ExtraAddresses.SubTitle")}</span>
                    </span>
                    <div style={{ height: '40px' }} />
                    <div className="confirmMailingAddrContainer">
                        <AddAddressForm labelKey='Interview.Page2' address={this.state.workingAddress}
                            lang={this.props.lang} layoutType={this.props.layoutType}
                            validate={Boolean(this.state.workingAddress.validated)}
                            onAddressPropertyChange={(fieldName, value) => this.onAddressChanged(fieldName, value)} />
                    </div>
                    <div className="interviewLeftHelpHolder bottomMargin">
                        <span className="learnMoreStyle" onClick={() => this.showAddressHelp()}>{T.translate(`Common.AddressHelp.${this.props.showOteContent ? 'Ote' : 'Direct'}`)}</span>
                    </div>
                </div>
                
                <div className="confirmMailingAddrFooter">
                    {validationError}
                    <div className="confirmMailingAddrFooterContainer">
                        <div className="confirmMailingAddrButtonLeftContainer">
                            <PlainButton labelKey="Logout.labels.Cancel" onClick={() => this.handleCancel()} />
                        </div>
                        <div className="confirmMailingAddrButtonRightContainer">
                            <PlainButton labelKey={buttonIsDisabled ? "ConfirmIdentity.ExtraAddresses.LimitReached" : "ConfirmIdentity.ExtraAddresses.AddButtonLabel"}
                                inverted={isInverted} inProgress={buttonIsInProgress}
                                isComplete={buttonIsComplete} isEnabled={!buttonIsDisabled}
                                onClick={(ev) => this.handleAddAnotherAddress(ev)} />
                            <div style={{ width: '20px', height: '20px' }} />
                            <PlainButton labelKey="Common.Done" onClick={(ev) => this.confirmIdentity(ev)} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        layoutType: state.layout.layoutType,
        lang: state.layout.lang,
        extraAddresses: state.confirmIdentity.extraAddresses,
        workingAddress: state.confirmIdentity.workingAddress,
        target: state.confirmIdentity.target,
        showOteContent: state.workflow.is_direct_user === false
    }
}


const mapDispatchToProps = (dispatch) => ({
    addExtraConfirmAddress: (address) => dispatch(ConfirmIdentityActions.addExtraConfirmAddress(address)),
    setStage: (target, stage) => dispatch(ConfirmIdentityActions.setStage(target, stage)),
    updateWorkingAddress: (addr) => dispatch(ConfirmIdentityActions.updateWorkingAddress(addr))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmIdentityAddAddress)