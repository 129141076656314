import React from 'react';
import PropTypes from 'prop-types'
import WorkflowSection from '../Section/WorkflowSection'
import '../Section/section.css'
import WorkflowButton from '../Section/WorkflowButton'
import { WorkflowButtonStates } from '../Section/WorkflowButtonStates'
import { useTicketType, ValidTicketTypes } from '../../../hooks/useTicketTypeHook'
import WorkflowButtonMaterial from '../Section/WorkflowButtonMaterial';


const AdditionalDocumentReturnSection = ({ lang, layoutType, currentYear, taxState, onWorkflowButtonAction }) => {

    const { ticketType } = useTicketType(taxState);

    return (
        <WorkflowSection titleKey='WorkflowPanel.additional_docs_title' titleKeyProps={{ TaxYear: taxState.taxYear }} canClose={false}
            isAlternate={(currentYear - taxState.taxYear) % 2 === 1} ticketType={taxState.ticket_type}>
            {taxState.additional_docs.map((docState, index) => {
                var labelProps = {
                    FirstName: docState.is_spouse ? taxState.spouse_firstName : taxState.primary_firstName,
                    FormName: lang === "fr" ? docState.document_name_fr : docState.document_name_en
                }
                // French form names are long ... :(
                if (lang === 'fr' && layoutType === 'mobile' && !docState.document_type.startsWith("POM")) {
                    labelProps.FormName = docState.document_type
                }

                var contextData = {
                    ...docState,
                    spouseFirstName: taxState.spouse_firstName,
                    primaryFirstName: taxState.primary_firstName,
                }
                return (
                    <div>
                        {ticketType.toLowerCase() === ValidTicketTypes.RTE
                            ? <WorkflowButtonMaterial
                                buttonKey="additional_docs"
                                text='WorkflowPanel.additional_docs.title'
                                textProps={labelProps}
                                key={'additional_docs_' + index}
                                icon="fa-edit"
                                showChevron='true'
                                ticketType={ticketType}
                                onClick={() => onWorkflowButtonAction('additional_docs', taxState.taxYear, contextData)} />

                            : <WorkflowButton
                                buttonKey="additional_docs"
                                buttonState={WorkflowButtonStates.SHOW}
                                icon="fa-edit"
                                text='WorkflowPanel.additional_docs.title'
                                textProps={labelProps}
                                key={'additional_docs_' + index}
                                ticketType={ticketType}
                                onClick={() => onWorkflowButtonAction('additional_docs', taxState.taxYear, contextData)}
                                onFadeOut={() => { }} />}
                    </div>


                )
            })}
        </WorkflowSection>
    )
}

AdditionalDocumentReturnSection.propTypes = {
    currentYear: PropTypes.number.isRequired,
    lang: PropTypes.string.isRequired,
    layoutType: PropTypes.string.isRequired,
    onWorkflowButtonAction: PropTypes.func.isRequired,
    taxState: PropTypes.shape({
        taxYear: PropTypes.number.isRequired,
        coupled: PropTypes.bool.isRequired,
        primary_province: PropTypes.string,
        primary_firstName: PropTypes.string.isRequired,
        spouse_province: PropTypes.string,
        spouse_firstName: PropTypes.string,
        subTotal: PropTypes.number,
        tenderTotal: PropTypes.number
    })
}

export default AdditionalDocumentReturnSection;

