import React, { Component } from 'react';
import './shared.css';
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';
import InputMask from 'react-input-mask';
import LearnMore from '../Controls/learnMore'

class SINTextInput extends Component {
    constructor(props) {
        super(props)

        this.state = {
            labelText: this.props.labelText,
            hasValidated: false,
            hasErrors: false,
            value: this.props.value,
            name: this.props.name,
            useFrenchLocale: this.props.isFrench,
            size: this.props.size,
            isRequired: Boolean(this.props.isRequired),
            missingRequiredField: this.props.missingRequiredField,
            renderErrorBorderOnly: this.props.renderErrorBorderOnly || false,
            learnMoreId: this.props.learnMoreId
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.labelText !== newProps.labelText) {
            this.setState({ labelText: newProps.labelText })
        }
        if (this.state.isRequired !== newProps.isRequired) {
            this.setState({ isRequired: newProps.isRequired })
        }
        if (this.state.missingRequiredField !== newProps.missingRequiredField) {
            this.setState({ missingRequiredField: newProps.missingRequiredField })
        }
        if (this.state.missingRequiredField !== newProps.missingRequiredField) {
            this.setState({ missingRequiredField: newProps.missingRequiredField })
            if (newProps.missingRequiredField) {
                this.handleBlur()
            }
        }
        if (newProps.renderErrorBorderOnly !== this.state.renderErrorBorderOnly) {
            this.setState({ renderErrorBorderOnly: Boolean(newProps.renderErrorBorderOnly) })
        }
        if (this.state.learnMoreId !== newProps.learnMoreId) {
            this.setState({ learnMoreId: newProps.learnMoreId })
        }
    }

    validateText(currText) {
        return isValidSIN(currText)
    }

    

    handleTextChange(ev) {
        // once we've validated, validate on every change
        var state = {
            ...this.state,
            value: ev.target.value
        }

        var isValid = this.validateText(state.value)
        if (this.state.hasValidated) {
            state.hasErrors = !isValid
        }

        this.setState(state);

        if (this.props.onChange) {
            this.props.onChange(ev, isValid);
        }
    }

    handleBlur() {
        var validation = false;
        if (this.state.value) {
            this.setState({ hasValidated: true, hasErrors: !this.validateText(this.state.value) })
        }
        else if ((!this.state.value) && (this.state.isRequired)) {
            validation = this.validateText(this.state.value)

            this.setState({ hasValidated: true, hasErrors: !validation.status, errorType: validation.errorType })
        }
    }

    render() {
        const placeholderText = "NNN NNN NNN"
        const mask = "999 999 999"

        var mainDivClassName = "validatingTextInputStyle verticalStretchStyle"
        var spacerSpanStyle = this.state.learnMoreId ? "span_spacer_right" : "hidden"

        var missingField = this.state.isRequired && this.state.missingRequiredField && !this.state.value
        var inputContent = (
            <InputMask className={this.state.hasErrors || this.state.renderErrorBorderOnly || missingField ? "phoneInputErrorStyle" : "phoneInputStyle"}
                placeholder={placeholderText} mask={mask} maskChar="" name={this.state.name} value={this.state.value}
                onChange={(ev) => { this.handleTextChange(ev) }} onBlur={() => this.handleBlur()} autoComplete="off" heap-ignore="true" />
        )

        let validationContent = this.state.hasErrors || missingField
            ? (
                <div className="validationErrorStyle">
                <FontAwesome className='fa-exclamation-circle' name='fa-exclamation-circle' size='lg' style={{
                    textShadow: '0 1px 0 rgba(0, 0, 0, 0)',
                    color: '#E70000',
                    align: 'center'
                }} />
                    <span style={{ marginLeft: 10 }}>{missingField ? T.translate('Common.Errors.InvalidSIN') : T.translate('Common.Errors.InvalidSIN')}</span>
            </div>
            )
            : (<div className="validationErrorStyle">
                &nbsp;
                </div>);

        if (this.state.hasErrors || missingField || this.state.renderErrorBorderOnly) {
            let styleName = "red formLabelStyle"
            return (
                <div className="defaultErrorStyles">
                    <div className={styleName}>
                        <div className="labelContainerStyle">
                            {this.state.labelText}
                            <span className={spacerSpanStyle} />
                            <LearnMore linkId={this.state.learnMoreId} />
                        </div>
                    </div>
                    {inputContent}
                    {validationContent}
                </div>
            )
        } else {
            return (
                <div className={mainDivClassName}>
                    <div className="formLabelStyle">
                        <div className="labelContainerStyle">
                            {this.state.labelText}
                            <span className={spacerSpanStyle} />
                            <LearnMore linkId={this.state.learnMoreId} />
                        </div>
                    </div>
                    {inputContent}
                    {validationContent}
                </div>
            )

        }
    }
}

export const extractDigits = (data) => {
    if (data == null || !data) {
        return []
    }
    const matches = data.match(/\d/g)
    if (matches != null) {
        return matches.map(Number)
    }
    return []
}

export const isValidSIN = (data) => {
    let digits = extractDigits(data)
    if (Array.isArray(digits) && digits.length === 9) {
        var s = 0;
        for (var i = 0; i < 9; i++) {
            i % 2 ? digits[i] << 1 > 9 ? s += (digits[i] << 1) - 9 : s += digits[i] << 1 : s += digits[i];
        }
        return s % 10 === 0
    }
    return false;
}

export default SINTextInput;