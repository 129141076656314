import React from 'react';
import { PropTypes } from 'prop-types';
import T from 'i18n-react';
import classnames from 'classnames'
import './workflowbutton.css';
import { WorkflowButtonStates } from './WorkflowButtonStates'
import FontAwesome from 'react-fontawesome';

const WorkflowButton = (props) => (
    <div className={classnames('workflow-button', props.ticketType, {
        'workflow-button-hide': (props.buttonState === WorkflowButtonStates.HIDE || props.buttonState === WorkflowButtonStates.COMPLETE),
        'workflow-button-fade': props.buttonState === WorkflowButtonStates.ANIMATE_REMOVE,
        'workflow-button-complete': props.buttonState === WorkflowButtonStates.VISIBLE_COMPLETE
    })} onClick={() => props.onClick(props.buttonKey)}>
        <div className='workflow-button-icon-container'>
            {props.icon &&
                <FontAwesome className={props.icon} name={props.icon} size="2x"
                    style={props.icon === 'fa-credit-card-alt' ? { fontSize: '2em' } : null} />
            }
            { props.children }
        </div>
        <div>
            <h2 className="unselectableLabel">
                {T.translate(props.text, props.textProps)}
            </h2>
        </div>
    </div>
);


WorkflowButton.propTypes = {
    buttonKey: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    textProps: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string,
    buttonState: PropTypes.string.isRequired,
    children: PropTypes.node,
    ticketType: PropTypes.string
};

export default WorkflowButton;
