import React from 'react';
import T from 'i18n-react';
import PropTypes from 'prop-types'

import './section.css'

const BlankStateLabel = ({ contentKey, contentProps }) => (
    <div className='wf-section-blank-label-container'>
        <span>{T.translate(contentKey, contentProps)}</span>
    </div>
);

BlankStateLabel.propTypes = {
    contentKey: PropTypes.string.isRequired,
    contentProps: PropTypes.object
}

export default BlankStateLabel;
