// src/components/Interview/interview.js
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import WorkflowActions from '../../reducers/workflowRedux'
import SigningActions from '../../reducers/signingRedux'
import LoadingSpinner from '../Loading/loadingSpinner'
import { trackEvent } from '../../utils/AnalyticsUtil'
import AuthorizeCRAActions from '../../reducers/authorizeCRARedux'

class AuthorizeComplete extends Component {
    componentWillMount() {
        if (this.props.signingStatus === 'failed') {
            this.props.replaceNavigation('/dashboard/authorize')
        } else {
            this.props.signingStateReset()
            this.props.setButtonState('authorize', 'AnimateRemove')
            this.props.replaceNavigation('/dashboard')
            trackEvent('OteAuthorizeComplete', null)
            this.props.authorizeComplete('done')
        }
    }

    render() {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <LoadingSpinner />
            </div>
        )
    }
        
}

const mapStateToProps = (state) => {
    return {        
        signingStatus: state.signing.signingCallbackStatus
    }
}

const mapDispatchToProps = (dispatch) => ({
    setButtonState: (buttonName, buttonState) => dispatch(WorkflowActions.setButtonState(buttonName, buttonState)), 
    signingStateReset: () => dispatch(SigningActions.signingStateReset()),
    replaceNavigation: (path) => dispatch(replace(path)),
    authorizeComplete: (target) => dispatch(AuthorizeCRAActions.authorizeComplete(target)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizeComplete)