import React from 'react';
import PropTypes from 'prop-types'
import '../Section/section.css'

import AdditionalDocumentReturnSection from './AdditionalDocumentReturnSection'

const AdditionalDocumentsSection = ({ currentYear, taxState, onWorkflowButtonAction, securityComplete, lang, layoutType }) => {
    if (taxState.taxYear === currentYear && !taxState.interview_state.complete && taxState.interview_state.current_page === '1' && taxState.interview_state.completed_date === null) {
        return null;
    }

    if (!securityComplete) {
        return null;
    }

    if (taxState.additional_docs && Array.isArray(taxState.additional_docs) && taxState.additional_docs.length > 0) {
        return (
            <AdditionalDocumentReturnSection lang={lang} layoutType={layoutType}
                taxState={taxState} currentYear={currentYear}
                onWorkflowButtonAction={onWorkflowButtonAction}
            />
        )
    }
    return null;
}

AdditionalDocumentsSection.propTypes = {    
    currentYear: PropTypes.number.isRequired,
    lang: PropTypes.string.isRequired,
    layoutType: PropTypes.string.isRequired,
    onWorkflowButtonAction: PropTypes.func.isRequired,
    securityComplete: PropTypes.bool.isRequired,
    taxState: PropTypes.shape({
        taxYear: PropTypes.number.isRequired,
        coupled: PropTypes.bool.isRequired,
        primary_province: PropTypes.string,
        primary_firstName: PropTypes.string.isRequired,
        spouse_province: PropTypes.string,
        spouse_firstName: PropTypes.string,
        subTotal: PropTypes.number,
        tenderTotal: PropTypes.number
    })
}

export default AdditionalDocumentsSection;
