import apisauce from 'apisauce'
import { addErrorHandler, getApiBaseUrl } from './serviceUtils'

/**
 * API for retrieving direction information from google using apisauce
 * @param {String} baseURL
 */
const create = (baseURL = getApiBaseUrl()) => {  // this will change for prod deployment
    // Create and configure an apisauce-based api object.
    const api = apisauce.create({
        baseURL,
        headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Content-Type': 'application/json' },
        timeout: 20000
    })

    addErrorHandler(api)

    const getAuthHeader = (accessToken) => {
        return {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            } 
        }
    }

    const getCaseMessages = (accessToken) => {
        var url = 'messages'
        return api.get(url, '', getAuthHeader(accessToken))
    }

    const sendMessage = (accessToken, message) => {
        var payload = {comment: message}
        return api.post('messages', payload, getAuthHeader(accessToken))
    }

    const setMessagesLastReadDate = (accessToken) => {
        var payload = ''
        return api.post('messages/read', payload, getAuthHeader(accessToken))
    }

    const sendInternalNoteNoPrinter = (accessToken, action) => {
        var payload = action
        return api.post('messages/internalnotenoprinter', payload, getAuthHeader(accessToken))
    }

  // Return back a collection of functions
  return {
      getCaseMessages, sendMessage, setMessagesLastReadDate, sendInternalNoteNoPrinter
  }
}

// let's return back our create method as the default.
export default {
  create
}