import React, { Component } from 'react';
import './progressModal.css';
import T from 'i18n-react';
import FontAwesome from 'react-fontawesome';
import BaseModal from '../Modal/BaseModal';

class ProgressModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: this.props.visible,
            spouseName: this.props.spouseName || '',
            titleKey: this.props.titleKey,
            subTitleKey: this.props.subTitleKey,
            primaryName: this.props.primaryName || ''
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.visible !== this.state.visible) {
            this.setState({ visible: newProps.visible })
        }

        if (newProps.spouseName !== this.state.spouseName) {
            this.setState({ spouseName: newProps.spouseName || ''})
        }

        if (newProps.primaryName !== this.state.primaryName) {
            this.setState({ primaryName: newProps.primaryName || ''})
        }


        if (newProps.titleKey !== this.state.titleKey) {
            this.setState({ titleKey: newProps.titleKey })
        }

        if (newProps.subTitleKey !== this.state.subTitleKey) {
            this.setState({ subTitleKey: newProps.subTitleKey })
        }
    }


    render() {
        var params = {
            ...this.props.params,
            SpouseName: this.state.spouseName,
            PrimaryName: this.state.primaryName
        }

        return (
            <div style={{ width: '0px', height: '0px' }}>
                <BaseModal show={this.state.visible} onClose={() => this.setState({ signOutDialogOpen: false })} layoutType={this.props.layoutType} >
                    <div className="progressRootContainer">
                        <div className="progressSpinnerContainer">
                            <FontAwesome className='fa-spinner' name='spinner' pulse size='3x' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0)', color: '#14AA40', align: 'center' }} />
                        </div>
                        <h2>{T.translate(this.state.titleKey, params)}</h2>
                        <span className="progressRootContainerSpan">{T.translate(this.state.subTitleKey, params)}</span>
                    </div>
                </BaseModal>
            </div>
        );
    }
}

export default ProgressModal