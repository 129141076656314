/* eslint-disable-next-line */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import LayoutActions from '../../reducers/layoutRedux';
import { getParameterByName } from '../../services/urlHelpers';
import OuterContainer from '../Controls/outerContainer';
import CreateAccount from './components/createAccount';
import CreateCredentials from './components/createCredentials';
import RegisterErrorMessage from './components/registerErrorMessage';
import RegisterExistingLogin from './components/registerExistingLogin';
import RegisterProgressMessage from './components/registerProgressMessage';
import RegisterSideImage from './components/registerSideImage';
import UpdateAccount from './components/updateAccount';
import './style.css';

const RegistrationRouter = () => {

    const dispatch = useDispatch()

    const { layoutType, isSmallLayout } = useSelector(state => ({
        layoutType: state.layout.layoutType,
        isSmallLayout: ['small_tablet', 'mobile'].includes(state.layout.layoutType)
    }))

    useEffect(() => {
        var lang = getParameterByName('lang')
        if (lang === 'en' || lang === 'fr') {
            dispatch(LayoutActions.setDictLanguage(lang))
        }
    }, [])

    return (
        <OuterContainer sizeToContent>
            <div className={"registerRootStyle " + layoutType}>
                <div className={isSmallLayout ? "registerContainerSmallStyle" : "registerContainerStyle"}>
                    <Switch>
                        <Route path={'/register/lookup'} render={() => <RegisterSideImage imageType='step1' />} />
                        <Route path={'/register/secure'} render={() => <RegisterSideImage imageType='step2' />} />
                        <Route path={'/register/working'} render={() => <RegisterSideImage imageType='step2' />} />
                        <Route path={'/register/returning'} render={() => <RegisterSideImage imageType='step3' />} />
                        <Route path={'/register/existing'} render={() => <RegisterSideImage imageType='step2' />} />
                        <Route path={'/register/error'} render={() => <RegisterSideImage imageType='step1' />} />
                        <Route path={'/register'} render={() => <RegisterSideImage imageType='step1' />} />
                    </Switch>
                    <Switch>
                        <Route path={'/register/lookup'} render={() => <RegisterProgressMessage />} />
                        <Route path={'/register/working'} render={() => <RegisterProgressMessage />} />
                        <Route path={'/register/secure'} component={CreateCredentials} />
                        <Route path={'/register/returning'} component={UpdateAccount} />
                        <Route path={'/register/existing'} component={RegisterExistingLogin} />
                        <Route path={'/register/error'} render={() => <RegisterErrorMessage />} />
                        <Route path={'/register'} component={CreateAccount} />
                    </Switch>
                </div>
            </div>
        </OuterContainer>
    )
}

export default RegistrationRouter;