import storeStorage from '../reducers/storeStorage'; // get store so we can do dispatches from here
import AuthActions from '../reducers/authRedux';

export function getApiBaseUrl() {
    if (process.env.REACT_APP_ENV === 'DEV') {
        return process.env.REACT_APP_VA_API_URL
    } else {
        return document.location.protocol + '//' + document.location.host + '/api/'
    }        
}



// VAI-1085: handle any unauthorized responses and redirect to sign out page.
export function addErrorHandler(api, excludedStatii) {
    // note: we get full access to the apisauce response object, so the level of error handling
    //       that is available with this is extensive.
    // see: https://github.com/infinitered/apisauce (bottom of page for problem codes)
    api.addMonitor(
        (response) => {
            if (response && response.status) {
                if (!(excludedStatii || []).includes(response.status)) {
                    switch (response.status) {
                        case 401:
                            // Unauthorized: user not logged in, set logout message and redirect to signout page.
                            window.sessionStorage.setItem('ote.logouttype', 'error')
                            storeStorage.getStore().dispatch(AuthActions.logoutUser());
                            break;
                        default: break;
                    }
                }
            }
        }
    );
}
