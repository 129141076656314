import React, { Component } from 'react';
import { connect } from 'react-redux'
import { push, replace, goBack } from 'connected-react-router'
import FontAwesome from 'react-fontawesome';
import './style.css';
import T from 'i18n-react';
import LayoutActions from '../../reducers/layoutRedux'
import WorkflowActions from '../../reducers/workflowRedux'
import MessagesActions from '../../reducers/messagesRedux'
import AuthorizeCRAActions from '../../reducers/authorizeCRARedux'
import SigningActions from '../../reducers/signingRedux'
import ArrowButton from '../Controls/arrowButton'
import PlainButton from '../Controls/plainButton'
import AuthorizeSelect from './authorizeSelect'
import AuthorizeChecklist from './authorizeChecklist'
import AuthorizeMyAccount from './authorizeMyAccount'
import AuthorizeMyAccountRq from './authorizeMyAccountRq'
import LoadingSpinner from '../Loading/loadingSpinner'

import { getMostRecentPrimaryInterviewTaxYearState, getMostRecentSpouseInterviewTaxYearState } from '../../utils/CaseStateUtilities'
import { trackEvent } from '../../utils/AnalyticsUtil'

class AuthorizeHrb extends Component {

    UNSAFE_componentWillReceiveProps(newProps) {
        if (!newProps.taxYearState || !newProps.taxYearState.primaryInterviewTaxYear) {
            this.props.replaceNavigation('/dashboard')
        }
    }

    UNSAFE_componentWillMount() {
        this.props.checkSigningState()
    }

    // Local event handlers
    onBackToDashboardClicked() {
        // head on back to the dashboard
        if (this.props.history && this.props.history.action === 'PUSH') {
            this.props.popNavigation()
        } else {
            this.props.replaceNavigation('/dashboard')
        }
    }

    onBackClicked() {
        if (this.props.stage === 'start') {
            this.onBackToDashboardClicked();
        } else if (this.props.stage === 'checklist') {
            this.props.setAuthorizeStage('start')
        } else {
            if (this.props.taxYearState.coupled || this.props.taxYearState.primaryProvince === 'QC') {
                this.props.setAuthorizeStage('checklist')
            } else {
                this.props.setAuthorizeStage('start')
            }
        }
    }    

    selectCRALink(craLink) {
        this.props.setAuthorizeCraLinkClicked(craLink)
    }

    jumpToMessageWindow() {
        this.props.setInputScreenShowing(true)
        this.props.setDashboardTab('messages')
        this.props.pushNavigation('/dashboard')
    }

    selectOption(target, opt) {
        if (target === 'self') {
            this.props.authorizeSelf(opt)
        }
        if (target === 'spouse') {
            this.props.authorizeSpouse(opt)

        }
        if (target === 'selfRQ') {
            this.props.authorizeSelfRQ(opt)
        }
        if (target === 'spouseRQ') {
            this.props.authorizeSpouseRQ(opt)
        }
    }

    handleStartContinue() {
        if (this.props.taxYearState.coupled || this.props.taxYearState.primaryProvince === 'QC') {
            this.props.setAuthorizeStage('checklist')
        } else {
            // jump straight to things
            if (this.props.authorizeStateSelf.choice === 'T1013') {
                this.props.signForm(this.props.currentTaxYear, ['EAF'], false, '/dashboard/authorize/complete', this.props.taxYearState.spouseFirstName)
            } else {
                this.props.resetAuthorizeConfig();
                this.props.setAuthorizeStage('my_account_self')
            }
        }
    }

    executeAuthStep(target, opt) {
        // show the cra my account for the target
        if (opt === 'cra') {
            if (target === 'self') {
                if (this.props.authorizeStateSelf.choice === 'T1013') {
                    this.props.signForm(this.props.currentTaxYear, ['EAF'], false, '/dashboard/authorize', this.props.taxYearState.primaryFirstName)
                } else {
                    this.props.resetAuthorizeConfig();
                    this.props.setAuthorizeStage('my_account_self')
                }
            } else if (target === 'spouse') {
                if (this.props.authorizeStateSpouse.choice === 'T1013') {
                    this.props.signForm(this.props.currentTaxYear, ['EAF'], true, '/dashboard/authorize', this.props.taxYearState.spouseFirstName)
                } else {
                    //disable done button 
                    this.props.resetAuthorizeConfig();
                    this.props.setAuthorizeCraLinkClicked(false)
                    this.props.setAuthorizeStage('my_account_spouse')
                }
            }
        } else if (opt === 'rq') {
            if (target === 'self') {
                if (this.props.authorizeStateSelf.choice_rq === 'MR69') {
                    if (this.props.lang === 'EN') {
                        this.props.signForm(this.props.currentTaxYear, ['MR69-V'], false, '/dashboard/authorize', this.props.taxYearState.primaryFirstName)
                    }
                    else {
                        this.props.signForm(this.props.currentTaxYear, ['MR69'], false, '/dashboard/authorize', this.props.taxYearState.primaryFirstName)
                    }                    
                }
                else {
                    this.props.resetAuthorizeConfig();
                    this.props.setAuthorizeStage('rq_account_self')
                }
            }
            else if (target === 'spouse') {
                if (this.props.authorizeStateSpouse.choice_rq === 'MR69') {
                    this.props.signForm(this.props.currentTaxYear, ['MR69'], target === 'spouse', '/dashboard/authorize', this.props.taxYearState.spouseFirstName)                    
                }
                else {
                    this.props.resetAuthorizeConfig();
                    this.props.setAuthorizeStage('rq_account_spouse')
                }
            }
        }
    }

    completeSingleAuthorize() {
        this.props.completeCraStep('self')
        this.props.signingStateReset();
        this.props.updateCaseState();
        trackEvent('OteAuthorizeComplete', null)
        this.props.resetAuthorizeConfig();
        this.props.replaceNavigation('/dashboard')
    }

    completeAuthorize() {
        this.props.signingStateReset();
        this.props.updateCaseState();
        trackEvent('OteAuthorizeComplete', null)
        this.props.authorizeComplete('done')
        this.props.resetAuthorizeConfig();
        this.props.replaceNavigation('/dashboard')
    }

    completeAuthorizeStep() {
        if (this.props.stage === 'my_account_self') {
            this.props.completeCraStep('self')
            this.props.setAuthorizeStage('checklist')
        } else if (this.props.stage === 'my_account_spouse') {
            this.props.completeCraStep('spouse')
            this.props.setAuthorizeStage('checklist')
        } else if (this.props.stage === 'rq_account_self') {
            this.props.completeRqStep('self')
            this.props.setAuthorizeStage('checklist')
        } else if (this.props.stage === 'rq_account_spouse') {
            this.props.completeRqStep('spouse')
            this.props.setAuthorizeStage('checklist')
        }
    }

    loadAuthConfig = () => {
        this.props.loadAuthorizationConfig(
            this.props.taxYearState.coupled,
            this.props.taxYearState.primaryProvince,
            this.props.taxYearState.spouseProvince
        );
    }
    
    render() {
        var isVir = false;
        for (let i = 0; i < this.props.case_state.taxYears.length; i++) {
            if (this.props.case_state.taxYears[i].is_vir === true) {
                isVir = true;
                break;
            }
        }

        var canContinue = false;
        var backLabel = ''

        var myAccountLink = null        
        var rqAccountLink = null        
        if (this.props.linkConfig && this.props.linkConfig[this.props.lang]) {           
            myAccountLink = this.props.linkConfig[this.props.lang]['cra_my_account']            
        }
        if (!myAccountLink) {
            myAccountLink = T.translate('Authorize.MyAccount.Link')
        }       
        if (!rqAccountLink) {
            rqAccountLink = T.translate('Authorize.MyAccountRq.Link')
        }
        var topLevelContainer = 'authorizeContainer ';
        if (this.props.layoutType === 'mobile') {
            topLevelContainer += 'auth_mobile'
        } else {
            topLevelContainer += this.props.layoutType === 'small_tablet' ? 'tablet' : this.props.layoutType
        }

        var buttonContent = null
        if (this.props.stage === 'start') {
            // if single, then the primary's choice cannot be none. If couple, both cannot be none
            canContinue = this.props.authorizeStateSelf.choice 
                && (
                    (!this.props.taxYearState.coupled && this.props.authorizeStateSelf.choice.toLowerCase() !== "none") 
                    ||
                    (this.props.taxYearState.coupled 
                        && this.props.authorizeStateSelf.choice.toLowerCase() !== "none" 
                        && this.props.authorizeStateSpouse 
                        && this.props.authorizeStateSpouse.choice.toLowerCase() !== "none"
                    )
                )

            buttonContent = (<ArrowButton onClick={(e) => this.handleStartContinue(e)} labelKey='Common.Continue' isEnabled={Boolean(canContinue)} />)
            backLabel = 'Common.ToDashboard'
        } else if (this.props.stage === 'checklist') {
            var isComplete = this.props.authorizeStateSelf.cra_status && (this.props.taxYearState.primaryProvince !== 'QC' || this.props.authorizeStateSelf.rq_status === true)
                && (!this.props.taxYearState.coupled || (this.props.authorizeStateSpouse.cra_status && (this.props.taxYearState.spouseProvince !== 'QC'
                || this.props.authorizeStateSpouse.rq_status === true)))
            buttonContent = (<PlainButton onClick={() => this.completeAuthorize()} labelKey='Common.Done' isEnabled={Boolean(isComplete)} />)
            canContinue = false
            backLabel = 'Authorize.BackToAuth'
        } else if (this.props.stage === 'my_account_self' || this.props.stage === 'my_account_spouse' || this.props.stage === 'rq_account_self' || this.props.stage === 'rq_account_spouse') {
            if (this.props.taxYearState.coupled !== false && (!this.props.taxYearState.spouseFirstName || this.props.authorizeStateSpouse.choice === null)) {
                this.props.setAuthorizeStage('start')
            }
            canContinue = true; // OTE-268 - Don't disable this button Boolean(this.props.craLinkIsClicked)
            if (this.props.taxYearState.coupled || this.props.taxYearState.primaryProvince === 'QC') {
                backLabel = 'Authorize.BackToChecklist'
                buttonContent = (<PlainButton onClick={() => this.completeAuthorizeStep()} labelKey='Common.Done' isEnabled={canContinue} />)
            } else {
                backLabel = 'Authorize.BackToAuth'
                buttonContent = (<PlainButton onClick={() => this.completeSingleAuthorize()} labelKey='Common.Done' isEnabled={canContinue} />)                
            }
        }

        if (this.props.layoutType === 'mobile') {
            backLabel = 'Common.Back'
        }

        var content = null
        if (this.props.stage === 'start') {
            content = (
                <AuthorizeSelect lang={this.props.lang} layoutType={this.props.layoutType}
                    prepareForSpouse={this.props.taxYearState.coupled} province={this.props.taxYearState.primaryProvince}
                    spouseProvince={this.props.taxYearState.spouseProvince} firstName={this.props.taxYearState.primaryFirstName}
                    spouseFirstName={this.props.taxYearState.spouseFirstName} authChoiceSelf={this.props.authorizeStateSelf.choice}
                    authChoiceSpouse={this.props.authorizeStateSpouse.choice} authChoiceSelfRQ={this.props.authorizeStateSelf.choice_rq} authChoiceSpouseRQ={this.props.authorizeStateSpouse.choice_rq} selectOption={(target, opt) => this.selectOption(target, opt)}
                    isVir={isVir}
                />
            )
        } else if (this.props.stage === 'checklist') {
            content = (
                <AuthorizeChecklist lang={this.props.lang} layoutType={this.props.layoutType}
                    prepareForSpouse={this.props.taxYearState.coupled} province={this.props.taxYearState.primaryProvince}
                    spouseProvince={this.props.taxYearState.spouseProvince} firstName={this.props.taxYearState.primaryFirstName}
                    spouseFirstName={this.props.taxYearState.spouseFirstName} authorizeStateSelf={this.props.authorizeStateSelf}
                    authorizeStateSpouse={this.props.authorizeStateSpouse}
                    executeAuthStep={(target, opt) => this.executeAuthStep(target, opt)}
                />
            )
        } else if (this.props.stage === 'my_account_self') {
            content = (
                <AuthorizeMyAccount lang={this.props.lang} layoutType={this.props.layoutType}
                    firstName={this.props.taxYearState.primaryFirstName} province={this.props.taxYearState.primaryProvince}
                    craGroupId={this.props.authorizeConfigSelf.craConfig.groupid} craRepName={this.props.authorizeConfigSelf.craConfig.groupname}
                    craBusinessNumber={this.props.authorizeConfigSelf.craConfig.businessnumber}
                    craBusinessName={this.props.authorizeConfigSelf.craConfig.businessname}
                    onJumpToMessages={() => this.jumpToMessageWindow()}
                    myAccountLink={myAccountLink}
                    craLinkVisited={this.props.craLinkIsClicked}
                    craLinkClicked={(craLink) => { this.selectCRALink(craLink) }}                   
                    loadAuthorizationConfig={this.loadAuthConfig}
                    isSpouse={false}
                />
            )
        } else if (this.props.stage === 'my_account_spouse') {
            content = (
                <AuthorizeMyAccount lang={this.props.lang} layoutType={this.props.layoutType}
                    firstName={this.props.taxYearState.spouseFirstName} province={this.props.taxYearState.spouseProvince}
                    craGroupId={this.props.authorizeConfigSpouse.craConfig.groupid} craRepName={this.props.authorizeConfigSpouse.craConfig.groupname}
                    craBusinessNumber={this.props.authorizeConfigSpouse.craConfig.businessnumber}
                    craBusinessName={this.props.authorizeConfigSpouse.craConfig.businessname}
                    onJumpToMessages={() => this.jumpToMessageWindow()}
                    myAccountLink={myAccountLink}
                    craLinkVisited={this.props.craLinkIsClicked}
                    craLinkClicked={(craLink) => { this.selectCRALink(craLink) }}                   
                    isSpouse={true} loadAuthorizationConfig={this.loadAuthConfig}
                />
            )
        } else if (this.props.stage === 'rq_account_self') {
            content = (
                <AuthorizeMyAccountRq lang={this.props.lang} layoutType={this.props.layoutType}
                    firstName={this.props.taxYearState.primaryFirstName} province={this.props.taxYearState.primaryProvince}
                    craGroupId={this.props.authorizeConfigSelf.craConfig.groupid} craRepName={this.props.authorizeConfigSelf.craConfig.groupname}
                    craBusinessNumber={this.props.authorizeConfigSelf.rqConfig.neq}
                    craBusinessName={this.props.authorizeConfigSelf.craConfig.businessname}
                    rqConfig={this.props.authorizeConfigSelf.rqConfig}
                    onJumpToMessages={() => this.jumpToMessageWindow()}
                    myAccountLinkRq={rqAccountLink}
                    craLinkVisited={this.props.craLinkIsClicked}
                    craLinkClicked={(craLink) => { this.selectCRALink(craLink) }}                    
                    loadAuthorizationConfig={this.loadAuthConfig}
                    isSpouse={false}
                />
            )
        } else if (this.props.stage === 'rq_account_spouse') {
            content = (
                <AuthorizeMyAccountRq lang={this.props.lang} layoutType={this.props.layoutType}
                    firstName={this.props.taxYearState.spouseFirstName} province={this.props.taxYearState.spouseProvince}
                    craGroupId={this.props.authorizeConfigSpouse.craConfig.groupid} craRepName={this.props.authorizeConfigSpouse.craConfig.groupname}
                    craBusinessNumber={this.props.authorizeConfigSelf.rqConfig.neq}
                    craBusinessName={this.props.authorizeConfigSelf.rqConfig.businessname}
                    rqConfig={this.props.authorizeConfigSpouse.rqConfig}
                    onJumpToMessages={() => this.jumpToMessageWindow()}
                    myAccountLinkRq={rqAccountLink}
                    craLinkVisited={this.props.craLinkIsClicked}
                    craLinkClicked={(craLink) => { this.selectCRALink(craLink) }}                    
                    isSpouse={true} loadAuthorizationConfig={this.loadAuthConfig}
                />
            )
        }

        if (this.props.stage === 'init') {
            return (
                <div className='authorizeInitContainer authorizeMinComponentHeight'>
                    <LoadingSpinner />
                </div>
            )
        } else {
            return (
                <div className={topLevelContainer}>
                    <div className="authorizeContainerStyle">
                        <div className="authorizeBackContainer">
                            <div className="authorizeBackButtonStyle" onClick={(e) => this.onBackClicked(e)}>
                                <FontAwesome className='fa-angle-left' name='fa-angle-left' size='2x' />
                                <h4>{T.translate(backLabel)}</h4>
                            </div>
                        </div>
                        {content}
                    </div>
                    <div className="authorizeBottomContainer">
                        {buttonContent}
                    </div>
                </div>
            );
        }
    }
}

const getCoupledStatii = (case_state) => {
    var primaryTaxYearState = getMostRecentPrimaryInterviewTaxYearState(case_state);
    var spouseTaxYearState = getMostRecentSpouseInterviewTaxYearState(case_state);

    return primaryTaxYearState ? {
        coupled: spouseTaxYearState !== null,
        primaryInterviewTaxYear: primaryTaxYearState.taxYear,
        spouseInterviewTaxYear: spouseTaxYearState ? spouseTaxYearState.taxYear : 0,
        primaryFirstName: primaryTaxYearState.primary_firstName,
        spouseFirstName: spouseTaxYearState ? spouseTaxYearState.spouse_firstName : null,
        primaryProvince: primaryTaxYearState.primary_province,
        spouseProvince: spouseTaxYearState ? spouseTaxYearState.spouse_province : null
    } : {}
}

const mapStateToProps = (state) => {
    return {
        layoutType: state.layout.layoutType,
        lang: state.layout.lang,
        taxYearState: getCoupledStatii(state.workflow.case_state),
        authorizeStateSelf: state.authorizeCRA.authorizeStateSelf,
        authorizeStateSpouse: state.authorizeCRA.authorizeStateSpouse,        
        stage: state.authorizeCRA.stage,    
        authorizeConfigSelf: state.authorizeCRA.authorizeConfigSelf,
        authorizeConfigSpouse: state.authorizeCRA.authorizeConfigSpouse,
        signingState: state.signing.signingState,
        signedDocuments: state.signing.signedDocuments,
        linkConfig: state.config.linkConfig,
        craLinkIsClicked: state.authorizeCRA.craLinkIsClicked,
        currentTaxYear: state.config.appConfig.currentTaxYear,
        case_state: state.workflow.case_state,
        is_digital_rq_auth: state.config.appConfig.isdigitalrqauth
    }
}

const mapDispatchToProps = (dispatch) => ({
    setButtonState: (buttonName, buttonState) => dispatch(WorkflowActions.setButtonState(buttonName, buttonState)),
    authorizeSelf: (authType) => dispatch(AuthorizeCRAActions.authorizeSelf(authType)),
    authorizeSpouse: (authType) => dispatch(AuthorizeCRAActions.authorizeSpouse(authType)),
    authorizeSelfRQ: (authType) => dispatch(AuthorizeCRAActions.authorizeSelfRQ(authType)),
    authorizeSpouseRQ: (authType) => dispatch(AuthorizeCRAActions.authorizeSpouseRQ(authType)),
    completeCraStep: (target) => dispatch(AuthorizeCRAActions.completeCraStep(target)),
    completeRqStep: (target) => dispatch(AuthorizeCRAActions.completeRqStep(target)),
    setAuthorizeStage: (stage) => dispatch(AuthorizeCRAActions.setAuthorizeStage(stage)),
    setAuthorizeCraLinkClicked: (craLinkIsClicked) => dispatch(AuthorizeCRAActions.setAuthorizeCraLinkClicked(craLinkIsClicked)),
    setInputScreenShowing: (showing) => dispatch(MessagesActions.setInputScreenShowing(showing)),
    setDashboardTab: (tab) => dispatch(LayoutActions.setDashboardTab(tab)),
    // T1013s aren't associated with a tax year, pass null to put the document into the master case
    signForm: (taxYear, formNames, isSpouse, route, spouseFirstName) => dispatch(SigningActions.signForm(taxYear, formNames, isSpouse, route, spouseFirstName)),
    signingStateReset: () => dispatch(SigningActions.signingStateReset()),
    pushNavigation: (path) => dispatch(push(path)),
    replaceNavigation: (path) => dispatch(replace(path)),
    popNavigation: () => dispatch(goBack()),
    checkSigningState: () => dispatch(SigningActions.checkSigningState()),
    loadAuthorizationConfig: (coupled, primaryProvince, spouseProvince) => dispatch(AuthorizeCRAActions.loadAuthorizationConfig(coupled, primaryProvince, spouseProvince)),
    resetAuthorizeConfig: () => dispatch(AuthorizeCRAActions.resetAuthorizeConfig()),
    updateCaseState: () => dispatch(WorkflowActions.updateCaseState()),
    authorizeComplete: (target) => dispatch(AuthorizeCRAActions.authorizeComplete(target)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizeHrb)
