import React, { Component } from 'react';
import T from 'i18n-react';
import Step1English from '../../images/STEP 1 ENGLISH.svg'
import Step1French from '../../images/STEP 1 FRENCH.svg'
import Step2English from '../../images/STEP 2 ENGLISH.svg'
import Step2French from '../../images/STEP 2 FRENCH.svg'
import Step3English from '../../images/STEP 3 ENGLISH.svg'
import Step3French from '../../images/STEP 3 FRENCH.svg'
import Step1Mobile from '../../images/STEP 1 mobile-01.svg'
import Step2Mobile from '../../images/STEP 2 mobile-01.svg'
import Step3Mobile from '../../images/STEP 3 mobile-01.svg'

class ReviewStepper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: this.props.page,
            language: this.props.language,
            isMobile: this.props.isMobile || false
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.page !== newProps.page) {
            this.setState({ page: newProps.page })
        }
        if (this.state.language !== newProps.language) {
            this.setState({ language: newProps.language })
        }
    }

    render() {

        var imageSource
        var altMessage = ''
        if (this.state.page === '1') {
            if (this.state.isMobile) {
                imageSource = Step1Mobile
                altMessage = 'Home.hrbLogoAltText'
            }
            else {
                if (this.state.language === 'en') {
                    imageSource = Step1English
                    altMessage = 'Home.hrbLogoAltText'
                }
                else {
                    imageSource = Step1French
                    altMessage = 'Home.hrbLogoAltText'
                }
            }
        }
        else if (this.state.page === '2') {
            if (this.state.isMobile) {
                imageSource = Step2Mobile
                altMessage = 'Home.hrbLogoAltText'
            }
            else {
                if (this.state.language === 'en') {
                    imageSource = Step2English
                    altMessage = 'Home.hrbLogoAltText'
                }
                else {
                    imageSource = Step2French
                    altMessage = 'Home.hrbLogoAltText'
                }
            }
            
        }
        else if (this.state.page === '3') {
            if (this.state.isMobile) {
                imageSource = Step3Mobile
                altMessage = 'Home.hrbLogoAltText'
            }
            else {
                if (this.state.language === 'en') {
                    imageSource = Step3English
                    altMessage = 'Home.hrbLogoAltText'
                }
                else {
                    imageSource = Step3French
                    altMessage = 'Home.hrbLogoAltText'
                }
            }
            
        }

        return (<div className="authorizeContentStyle">
            <img src={imageSource} alt={T.translate(altMessage)} width={this.state.isMobile ? '85%' : '50%'} height={this.state.isMobile ? '85%' : '50%'}
                style={{ cursor: 'pointer', marginTop: this.state.isMobile ? '20px' : '0px' }} />
                </div>)
    }
}

export default ReviewStepper